import { TreeNode } from 'components/ps-chart/stores/connections-store/LinksTree/TreeNode'

export const walkOverTree = (
  treeRootNode: TreeNode | null,
  callback: (treeNode: TreeNode) => void,
) => {
  if (treeRootNode == null) {
    return null
  }

  let curNodes: TreeNode[] = [treeRootNode]
  const passedNodes = new Set<TreeNode>([treeRootNode])
  while (curNodes.length > 0) {
    const nextLevelNodes = []
    for (const curNode of curNodes) {
      callback(curNode)
      for (const treeLink of curNode.fromLinks) {
        if (!passedNodes.has(treeLink.toTreeNode)) {
          passedNodes.add(treeLink.toTreeNode)
          nextLevelNodes.push(treeLink.toTreeNode)
        }
      }
    }
    curNodes = nextLevelNodes
  }
}
