import React from 'react'
import { useTranslation } from 'react-i18next'

export const Processing = () => {
  const { t } = useTranslation()
  return (
    <div className="px-4">
      <p className="space-x-1">
        <span>{t('ra.flow.run.processing')}</span>
      </p>
    </div>
  )
}
