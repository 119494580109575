import React from 'react'
import classNames from 'classnames'

export interface TextareaProps
  extends React.DetailedHTMLProps<
    React.TextareaHTMLAttributes<HTMLTextAreaElement>,
    HTMLTextAreaElement
  > {
  containerClassName?: string
  inModal?: boolean
  children?: React.ReactNode
}

export const Textarea = React.forwardRef(
  (
    { containerClassName, className, inModal, children, ...props }: TextareaProps,
    ref: React.Ref<HTMLTextAreaElement>,
  ) => {
    return (
      <div className={classNames('relative mb-4', inModal && 'w-[320px]', containerClassName)}>
        <textarea
          {...props}
          ref={ref}
          className={classNames(
            'peer block w-full h-[32px] px-[16px] py-[6px] text-small tracking-wide bg-dark-dark1 border-0 placeholder:text-gray-normal text-white focus:ring-0 focus-visible:outline-0',
            !className && className?.includes('min-h') && 'min-h-[72px]',
            className,
          )}
        />
        <div className="absolute bottom-0 inset-x-[12px] h-px transition-colors bg-transparent peer-focus:bg-electro" />
        {children}
      </div>
    )
  },
)
