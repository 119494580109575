import { Thread } from 'components/ps-chart/models/Thread'
import {
  GlobalTimelineContent,
  GlobalTimelineContentLine,
  Segment,
} from 'components/common/models/Segment'
import { cluster } from 'components/ps-chart/flame-chart/logic/clustering'

export function calcThreadsPreviewContent(
  threads: Array<Thread>,
  timePerPx: number,
  clusterMinSliceSizePx: number,
  clusterStickSizePx: number,
): GlobalTimelineContent {
  const content: GlobalTimelineContent = []

  const clusteredThreads = threads.map((thread) => {
    const slices = thread.slices.map((slice) => {
      const s = { ...slice }
      s.children = []
      return s
    })
    const clusteredSlices = cluster(
      slices,
      clusterMinSliceSizePx * timePerPx,
      clusterStickSizePx * timePerPx,
      'red',
    )
    return new Thread(
      thread.id,
      thread.title,
      clusteredSlices,
      thread.networkRequestsRange,
      thread.maxLevel,
      thread.isAsync,
    )
  })
  clusteredThreads.forEach((thread) => {
    const contentLine: GlobalTimelineContentLine = []
    thread.slices.forEach((slice) => {
      const sliceWidth = slice.end - slice.start
      if (sliceWidth > timePerPx) {
        const segment: Segment = {
          start: slice.start,
          end: slice.end,
        }
        contentLine.push(segment)
      }
    })
    content.push(contentLine)
  })
  return content
}
