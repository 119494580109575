import React from 'react'
import { GridHeader } from 'components/flows/ra/runs/details/utils/GridHeader'
import { DataCol } from 'components/flows/ra/runs/details/utils/DataCol'
import { useTranslation } from 'react-i18next'
import { Title } from 'components/flows/ra/runs/details/utils/Title'
import { Run } from 'components/regression-analysis-mvp/Run'

interface DataProps {
  run: Run
}

export const Data = ({ run }: DataProps) => {
  const { t } = useTranslation()
  return (
    <>
      {run.userFlowData.map((userFlowData, index) => {
        const current = userFlowData.current?.measurements
        if (!current) {
          return null
        }
        const prev = userFlowData.previous?.measurements
        const curDurations = current.map((data) => data.durationMs).join(', ')
        const prevDurations = prev?.map((data) => data.durationMs).join(', ') ?? ''
        const curRxBytes = current
          .map((data) => data.rxBytesApp)
          .filter((value) => !!value)
          .join(', ')
        const prevRxBytes =
          prev
            ?.map((data) => data.rxBytesApp)
            .filter((value) => !!value)
            .join(', ') ?? ''
        const curTxBytes = current
          .map((data) => data.txBytesApp)
          .filter((value) => !!value)
          .join(', ')
        const prevTxBytes =
          prev
            ?.map((data) => data.txBytesApp)
            .filter((value) => !!value)
            .join(', ') ?? ''
        return (
          <div>
            <Title text={t('ra.flow.run.title')} name={userFlowData.flowId} />
            <div key={index}>
              <GridHeader />
              <div className="grid grid-cols-3">
                <DataCol isDescription>{t('ra.flow.run.durations')}</DataCol>
                <DataCol>{curDurations}</DataCol>
                <DataCol>{prevDurations}</DataCol>

                <DataCol isDescription>Bytes received</DataCol>
                <DataCol>{curRxBytes}</DataCol>
                <DataCol>{prevRxBytes}</DataCol>

                <DataCol isDescription>Bytes sent</DataCol>
                <DataCol>{curTxBytes}</DataCol>
                <DataCol>{prevTxBytes}</DataCol>
              </div>
            </div>
          </div>
        )
      })}
    </>
  )
}
