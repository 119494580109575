import { HTMLProps } from 'react'
import { HeaderLevel } from 'components/guide/markdown/MarkdownConfig'

export interface MdHeaderProps extends HTMLProps<HTMLHeadingElement> {
  level: HeaderLevel
}

export const MdHeader = ({ level, children, ...rest }: MdHeaderProps) => {
  const CustomHeading: keyof JSX.IntrinsicElements = `h${level}`
  return <CustomHeading {...rest}>{children}</CustomHeading>
}
