import { ConnectionType } from 'components/ps-chart/models/ConnectionType'

export interface TreeNode {
  sliceId: number
  /**
   * Outgoing (right to left) connections to the callers of the current function.
   * For all these links TreeLink.fromTreeNode is equal to the current node.
   */
  fromLinks: TreeLink[]
  /**
   * Incoming connections from the callers to the called functions.
   * For all these links TreeLink.toTreeNode is equal to the current node.
   */
  toLinks: TreeLink[]
}

export interface TreeLink {
  connectionType: ConnectionType
  /**
   * A "current" node on the "right side". This is the "called function".
   */
  fromTreeNode: TreeNode
  /**
   * A "clojureId" node on the "left side". This is the "caller function".
   */
  toTreeNode: TreeNode
}

/**
 * @param fromTreeNode A "current" node on the "right side". This is the "called function".
 * @param toTreeNode A "clojureId" node on the "left side". This is the "caller function".
 * @param connectionType
 */
export const linkTreeNodes = (
  fromTreeNode: TreeNode,
  toTreeNode: TreeNode,
  connectionType: ConnectionType,
) => {
  if (fromTreeNode.fromLinks.find((link) => link.toTreeNode === toTreeNode) != null) {
    return null
  }
  const treeLink = {
    connectionType,
    fromTreeNode,
    toTreeNode,
  }
  fromTreeNode.fromLinks.push(treeLink)
  toTreeNode.toLinks.push(treeLink)
}

export const mergeTreeNodes = (leftTreeNode: TreeNode | null, rightTreeNode: TreeNode | null) => {
  if (leftTreeNode === null) {
    return null
  }
  if (rightTreeNode === null) {
    return leftTreeNode
  }
  if (leftTreeNode.sliceId !== rightTreeNode.sliceId) {
    return null
  }
  return {
    sliceId: leftTreeNode.sliceId,
    fromLinks: [...leftTreeNode.fromLinks, ...rightTreeNode.fromLinks],
    toLinks: [...leftTreeNode.toLinks, ...rightTreeNode.toLinks],
  }
}
