import React, { useCallback, useEffect, useRef, useState } from 'react'
import { FlagsStore } from 'components/ps-chart/stores/FlagsStore'
import { useTranslation } from 'react-i18next'
import { nanoToString } from 'components/ps-chart/utils/nanoToString'
import { observer } from 'mobx-react-lite'
import { useToaster } from 'hooks/useToaster'

interface FlagTitleProps {
  flagsStore: FlagsStore
}

export const FlagTitle = observer(function FlagTitle({ flagsStore }: FlagTitleProps) {
  const titleTextAreaRef = useRef<HTMLTextAreaElement>(null)
  const { t } = useTranslation()
  const [title, setTitle] = useState('')
  const [isChanged, setIsChanged] = useState(false)
  const toaster = useToaster()

  useEffect(() => setTitle(flagsStore.selectedFlagTitle), [flagsStore.selectedFlagTitle])
  useEffect(
    () => setIsChanged(title !== flagsStore.selectedFlagTitle),
    [title, flagsStore.selectedFlagTitle],
  )

  const updateTitle = useCallback(
    (titleToUpdate: string) => {
      titleTextAreaRef.current?.blur()
      flagsStore.updateSelectedTitle(titleToUpdate).catch((reason) => {
        toaster.error(reason, 'psChart.flag.error.changeTitle')
        setTitle(titleToUpdate) // Put back typed title instead of rollback
        titleTextAreaRef.current?.focus()
      })
    },
    [toaster, flagsStore],
  )

  return (
    <div className="bg-dark-dark1 py-[8px] rounded-sm text-gray-normal text-small">
      <textarea
        className="w-full px-[16px] bg-dark-dark1 round-sm text-small text-white p-0 border-0 focus:ring-0 resize-none"
        rows={flagsStore.settings.titleMaxRows}
        maxLength={flagsStore.settings.titleMaxLength}
        placeholder={t('psChart.flag.label')}
        value={title}
        onChange={(event) => setTitle(event.target.value)}
        onKeyDown={(event) => {
          event.stopPropagation()
          if (isChanged && !event.shiftKey && event.code === 'Enter') {
            updateTitle(title)
          }
        }}
        ref={titleTextAreaRef}
      />
      {isChanged ? (
        <div className="flex justify-between px-[16px]">
          <button
            className="px-1 -ml-1 focus:outline-1"
            onClick={() => setTitle(flagsStore.selectedFlagTitle)}
          >
            {t('cancel')}
          </button>
          <button
            className="px-1 -mr-1 text-electro focus:outline-1"
            onClick={() => updateTitle(title)}
          >
            {t('save')}
          </button>
        </div>
      ) : (
        <div className="flex justify-end px-[16px]">
          {nanoToString(flagsStore.selectedFlagTime)}
        </div>
      )}
    </div>
  )
})
