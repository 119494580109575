import { Point } from 'components/ps-chart/models/helper-types'

export class Pointer {
  private _absolutePointer: Point | null = null

  private readonly mainElement: HTMLElement
  private readonly pinnedElement?: HTMLElement

  constructor(mainElement: HTMLElement, pinnedElement?: HTMLElement) {
    this.mainElement = mainElement
    this.pinnedElement = pinnedElement
  }

  isMain(): boolean {
    if (this.absolutePointer != null) {
      const absY = this.absolutePointer.y
      const rect = this.mainElement.getBoundingClientRect()
      if (absY >= rect.top && absY <= rect.top + rect.height) {
        return true
      }
    }
    return false
  }

  isPinned(): boolean {
    if (this.pinnedElement && this.absolutePointer != null) {
      const absY = this.absolutePointer.y
      const rect = this.pinnedElement.getBoundingClientRect()
      if (absY >= rect.top && absY <= rect.top + rect.height) {
        return true
      }
    }
    return false
  }

  /**
   * Returns relative to the canvas element pointer position (keep in mind Y axis includes header height)
   * @param verticalShift subtracts from Y axis - for example to remove header height from position
   */
  getRelativePoint(verticalShift = 0): Point | null {
    if (this.absolutePointer == null) {
      return null
    }

    if (this.isMain()) {
      const rect = this.mainElement.getBoundingClientRect()
      return {
        x: this.absolutePointer.x - rect.left,
        y: this.absolutePointer.y - rect.top - verticalShift,
      }
    }

    if (this.isPinned()) {
      const rect = this.pinnedElement!.getBoundingClientRect()
      return {
        x: this.absolutePointer.x - rect.left,
        y: this.absolutePointer.y - rect.top - verticalShift,
      }
    }
    return null
  }

  getRelativeX(): number | undefined {
    const relativePoint = this.getRelativePoint()
    if (relativePoint == null) {
      return undefined
    }

    return relativePoint.x
  }

  isOut() {
    return this._absolutePointer == null
  }

  onMouseMove(event: MouseEvent) {
    this._absolutePointer = { x: event.clientX, y: event.clientY }
  }

  onMouseOut() {
    this._absolutePointer = null
  }

  isEqualTo(point: Point): boolean {
    if (this.absolutePointer == null) {
      return false
    }

    return point.x === this.absolutePointer.x && point.y === this.absolutePointer.y
  }

  get absolutePointer() {
    return this._absolutePointer
  }
}
