import { observer } from 'mobx-react-lite'
import classNames from 'classnames'
import { VideoPlayerState } from 'components/ps-chart/stores/VideoPlayerStore'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components/macro'

type PropTypes = { videoState: VideoPlayerState; videoWidth: number }

export const EmptyFramePreview = observer(function EmptyFramePreview({
  videoState,
  videoWidth,
}: PropTypes) {
  const { t } = useTranslation()

  return (
    <EmptyFrameContainer
      className={classNames(
        'z-10 items-center justify-center absolute top-[-1px] bottom-[44px] bg-dark-dark3 border-[2px] rounded-[18px] border-dark-default',
        videoState.frameExist ? 'hidden' : 'flex',
      )}
      style={{
        width: '100%',
        maxWidth: videoWidth,
      }}
    >
      <div className={`flex flex-wrap text-small text-gray-normal text-center break-words`}>
        {t('psChart.video.noFramePreview')}
      </div>
    </EmptyFrameContainer>
  )
})

const EmptyFrameContainer = styled.div`
  @media (min-height: 0px) and (max-height: 400px) {
    border-radius: 4px;
  }
  @media (min-height: 400px) and (max-height: 600px) {
    border-radius: 8px;
  }
  @media (min-height: 600px) and (max-height: 670px) {
    border-radius: 12px;
  }
`
