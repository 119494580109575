import React, { useMemo } from 'react'
import { QUICK_START_PART_3_DESCRIPTION, QUICK_START_PART_3_GUIDE } from 'pages/MdToHtmlConstants'
import { MarkdownConfig, MD_STYLES_DEFAULT } from 'components/guide/markdown/MarkdownConfig'
import { Markdown } from 'components/guide/markdown/Markdown'
import { EMPTY_TEMPLATE_VALUES } from 'components/guide/markdown/TemplateEngine'

export const QUICK_START_PROTOTYPE_PAGE_PATH = '/quick-start-part-3-prototype'

/**
 * Temporary
 * todo: delete when guides integrated
 */
export const QuickStartPrototypePage = () => {
  const configBase = useMemo(() => {
    return {
      headerLevelsToCollapse: [],
      styles: MD_STYLES_DEFAULT,
    } as MarkdownConfig
  }, [])

  const configGuide = useMemo(() => {
    const guide = { ...configBase }
    guide.styles.header.h3.otherClassName =
      'text-header-tiny font-medium text-state-attention break-all'
    return guide
  }, [configBase])

  return (
    <div className="bg-dark-dark5 min-h-full">
      <div className="pt-8 pb-20 mx-10 max-w-[66ch]">
        <h1 className="text-header-big mb-5">
          Quick Start Guide Prototype With Markdown Rendering
        </h1>
        <div className="text-normal text-gray-normal space-y-8">
          <div>
            <Markdown
              markdown={QUICK_START_PART_3_DESCRIPTION}
              templateValues={EMPTY_TEMPLATE_VALUES}
              config={configBase}
            />
          </div>
          <div className="bg-dark-dark1 rounded py-1 px-4">
            <Markdown
              markdown={QUICK_START_PART_3_GUIDE}
              templateValues={EMPTY_TEMPLATE_VALUES}
              config={configGuide}
            />
          </div>
        </div>
      </div>
    </div>
  )
}
