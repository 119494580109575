// Time units in nanoseconds
const timeUnits = new Map<string, number>([
  ['minute', 1_000_000_000 * 60],
  ['second', 1_000_000_000],
  ['millisecond', 1_000_000],
  ['microsecond', 1_000],
  ['nanosecond', 1],
])

const smallUnitScales = [500, 250, 100, 50, 25, 10, 5, 3, 2, 1]
const baseUnitScales = [60, 30, 15, 10, 5, 3, 2, 1]

// roundScalePeriod waits time in nanoseconds
export const roundScalePeriod = (time: number): number => {
  let result = time

  for (const [unitName, nanoInUnit] of timeUnits) {
    if (result > nanoInUnit) {
      const scales = ['minute', 'second'].includes(unitName) ? baseUnitScales : smallUnitScales

      for (const scale of scales) {
        const scaleTime = scale * nanoInUnit

        if (time > scaleTime) {
          result = scaleTime
          break
        }
      }
      break
    }
  }

  return result
}
