import { createBrowserRouter } from 'react-router-dom'

import { Root } from 'components/Root'
import { RequireAuth } from 'components/auth/RequireAuth'
import { SkipAuth } from 'components/auth/SkipAuth'

import { ErrorPage } from 'pages/ErrorPage'
import { ErrorPageAuthorized } from 'pages/ErrorPageAuthorized'

import { PATH_LITE_PROMO, LitePromoPage } from 'pages/lite/LitePromoPage'
import { PATH_LITE_SIGN_UP, LiteSignUpPage } from 'pages/lite/LiteSignUpPage'
import { PATH_LITE_TRACES, LiteTracesPage } from 'pages/lite/LiteTracesPage'
import { PATH_LITE_PS_CHART, LitePsChartPage } from 'pages/lite/LitePsChartPage'

import { PATH_LITE_TARGET_1, LiteTarget1Page } from 'pages/lite/LiteTarget1Page'
import { PATH_LITE_TARGET_2, LiteTarget2Page } from 'pages/lite/LiteTarget2Page'
import { PATH_LITE_TARGET_3, LiteTarget3Page } from 'pages/lite/LiteTarget3Page'

export const liteRouter = createBrowserRouter([
  {
    element: <Root />,
    errorElement: <ErrorPage />,
    children: [
      {
        element: <RequireAuth />,
        children: [
          {
            path: '*',
            element: <ErrorPageAuthorized />,
          },
          {
            path: PATH_LITE_TRACES,
            element: <LiteTracesPage />,
          },
          {
            path: PATH_LITE_PS_CHART,
            element: <LitePsChartPage />,
          },
        ],
      },
      {
        element: <SkipAuth />,
        children: [
          // TODO: Password recovery flow for Lite
          // {
          //   path: PATH_RECOVERY_TOKEN,
          //   element: <RecoveryTokenPage />,
          // },
          // {
          //   path: PATH_RESET_PASSWORD,
          //   element: <ResetPasswordPage />,
          // },
          {
            path: PATH_LITE_SIGN_UP,
            element: <LiteSignUpPage />,
          },
        ],
      },
      {
        path: PATH_LITE_PROMO,
        element: <LitePromoPage />,
      },
      {
        path: PATH_LITE_TARGET_1,
        element: <LiteTarget1Page />,
      },
      {
        path: PATH_LITE_TARGET_2,
        element: <LiteTarget2Page />,
      },
      {
        path: PATH_LITE_TARGET_3,
        element: <LiteTarget3Page />,
      },
    ],
  },
])
