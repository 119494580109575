import {
  commonTimelineSettings,
  CommonTimelineSettings,
} from 'components/global-timeline/models/CommonTimelineSettings'
import { colors } from 'utils/styles/colors'
import {
  ghostIndicatorSettings,
  GhostIndicatorSettings,
} from 'components/global-timeline/models/GhostIndicatorSettings'

export class GlobalTimelineSettings {
  common: CommonTimelineSettings = commonTimelineSettings
  ghostIndicator: GhostIndicatorSettings = ghostIndicatorSettings

  readonly scalePoints = 10
  readonly scaleHeight = 24

  readonly previewDataHeight = 56
  readonly previewDataLineCount = 8

  readonly topOffset = 25

  readonly searchHighlightColor = colors.state.attention
}
