import React, { useState } from 'react'
import classNames from 'classnames'
import { generatePath, Link, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { Button, ButtonVariantEnum } from 'components/Button'
import { useTeamsQuery, useUserQuery } from 'hooks/useApiQuery'
import { PATH_TEAM } from 'pages/TeamPage'
import { AuthenticatedUserDto, TeamsArray } from 'api/models'
import { TeamFormModal } from './TeamFormModal'

export const showTeamList = (
  isUserSuccess: boolean,
  isTeamsSuccess: boolean,
  user?: AuthenticatedUserDto,
  teams?: TeamsArray,
) => isUserSuccess && isTeamsSuccess && (user?.roles.isSuperAdmin || (teams && teams.length > 1))

export const TeamsList = ({ activeTeamUrlName }: { activeTeamUrlName?: string }) => {
  const { t } = useTranslation()
  const { isSuccess: isUserSuccess, data: user } = useUserQuery()
  const { isSuccess: isTeamsSuccess, data: teams } = useTeamsQuery()
  const { teamUrlName } = useParams() as { teamUrlName: string }

  const [addTeamModalOpened, setAddTeamModalOpened] = useState(false)
  const handleAddTeamClick = () => setAddTeamModalOpened(true)
  const handleAddTeamModalClose = () => setAddTeamModalOpened(false)

  if (!showTeamList(isUserSuccess, isTeamsSuccess, user, teams)) {
    return null
  }

  return (
    <div>
      <aside className="sticky top-0 w-[240px] h-teams-list bg-dark-dark3 border-r border-r-gray-strokeLight">
        <div className="h-full flex flex-col justify-between px-[24px] pb-[24px] overflow-y-auto scrolling-touch">
          <div className="tracking-wide text-gray-normal font-medium">
            <h2 className="my-[20px] pb-[18px] border-b border-b-white/10 text-small">
              {t('teams.teamsList.title')}
            </h2>
            <ul>
              {teams?.map((item) => (
                <li
                  className={classNames('mb-[16px] text-normal', {
                    'text-white':
                      teamUrlName === item.urlName || activeTeamUrlName === item.urlName,
                  })}
                  key={item.id}
                >
                  <Link
                    to={generatePath(PATH_TEAM, { teamUrlName: item.urlName })}
                    className="break-words"
                  >
                    {item.name}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
          {user?.roles.isSuperAdmin && (
            <Button
              className="shrink-0"
              onClick={handleAddTeamClick}
              variant={ButtonVariantEnum.Outlined}
              isSmall
            >
              {t('teams.teamsList.addButton')}
            </Button>
          )}
        </div>
      </aside>
      <TeamFormModal isOpen={addTeamModalOpened} onClose={handleAddTeamModalClose} />
    </div>
  )
}
