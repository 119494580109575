import { useTranslation } from 'react-i18next'
import { generatePath, useNavigate } from 'react-router-dom'
import { PATH_TRACE_ADD } from 'pages/TraceAddPage'
import { Table } from 'components/traces/Table'
import { Empty } from 'components/Empty'
import PhoneSVG from 'assets/img/phone.svg'
import React from 'react'
import { Trace } from 'api/models'

interface TraceTableProps {
  flowTracesData: Trace[] | undefined
  canWorkWithFlow: boolean
  projectUrlName: string
  flowProjectLocalId: number
  isHiddenFlow?: boolean
}

export const TraceTable = ({
  flowTracesData,
  canWorkWithFlow,
  projectUrlName,
  flowProjectLocalId,
  isHiddenFlow,
}: TraceTableProps) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const handleAddNewTraceClick = () =>
    navigate(
      generatePath(PATH_TRACE_ADD, {
        projectUrlName,
        flowProjectLocalId: String(flowProjectLocalId),
      }),
    )

  if (flowTracesData == null) {
    // a loader is shown in the ancestor component
    return <></>
  }

  if (flowTracesData.length > 0) {
    return (
      <Table
        traces={flowTracesData}
        canWorkWithFlow={canWorkWithFlow}
        isHiddenFlow={isHiddenFlow}
      />
    )
  }

  return (
    <Empty
      image={PhoneSVG}
      title={t('flows.flowPage.empty.title')}
      text={t('flows.flowPage.empty.text')}
      buttons={
        canWorkWithFlow
          ? [
              // {
              //   name: flowsText("flowPage.empty.buttons.annotateFlow"),
              //   variant: ButtonVariantEnum.Outlined
              // },
              {
                name: t('flows.flowPage.empty.buttons.addNewTrace'),
                onClick: handleAddNewTraceClick,
              },
            ]
          : []
      }
    />
  )
}
