import React, { useLayoutEffect, useMemo, useRef, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'

import type { PsChartStore } from 'components/ps-chart/PsChartStore'
import { LoadingSpinner } from 'components/LoadingSpinner'
import { VideoDataStoreStatus } from 'components/ps-chart/stores/VideoDataStore'

export const VideoStatus = observer(function VideoStatus({
  psChartStore,
}: {
  psChartStore: PsChartStore
}) {
  const ref = useRef<HTMLDivElement>(null)
  const [divWidth, setDivWidth] = useState(0)
  const { status } = psChartStore.videoPlayerStore
  const { isExtractionInProgress } = psChartStore.videoTimelineStore
  const { t } = useTranslation()

  useLayoutEffect(() => {
    if (ref.current) {
      setDivWidth(ref.current.offsetWidth)
    }
  }, [status])

  const divStyle = useMemo(() => ({ marginLeft: `${-(divWidth / 2)}px` }), [divWidth])

  if (!isExtractionInProgress && status === VideoDataStoreStatus.HAS_VIDEO_AND_READY) {
    return null
  }

  const showLoader =
    [VideoDataStoreStatus.PROCESSING, VideoDataStoreStatus.DOWNLOADING].includes(status) ||
    isExtractionInProgress

  return (
    <div
      ref={ref}
      className="absolute flex items-center left-1/2 bottom-[16px] px-[12px] py-[3.5px] bg-dark-default text-gray-normal text-small rounded-sm"
      style={divStyle}
    >
      {showLoader ? (
        <>
          {t('psChart.video.statusProcessing')}
          <LoadingSpinner size={12} className="ml-[12px] mr-[-6px]" />
        </>
      ) : (
        t('psChart.video.statusEmpty')
      )}
    </div>
  )
})
