import { TeamRoleApi, TeamRoleApiValue } from 'api/models'
import { TFunction } from 'react-i18next'

export const getTeamRoleName = (
  role?: TeamRoleApiValue | null,
  t?: TFunction<'translation', undefined>,
) => {
  if (t) {
    switch (role) {
      case TeamRoleApi.ADMIN:
        return t('user.teamRole.admin')
      case TeamRoleApi.CONTRIBUTOR:
        return t('user.teamRole.contributor')
      case TeamRoleApi.NONE:
        return t('user.none')
      default:
        return ''
    }
  }
  return ''
}
