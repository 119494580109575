import { Fragment } from 'react'
import { Menu as HeadlessMenu, Transition } from '@headlessui/react'
import classNames from 'classnames'
import { Icon } from 'components/Icon'
import { transitionProps } from './ListBox'
import { OptionsVariant, OptionType, SectionType } from './models'

interface MenuProps<T> {
  disabled?: boolean
  onSelect?: (index: number) => void
  name?: string
  className?: string

  // Menu parent HTML element
  as?: keyof Pick<HTMLElementTagNameMap, 'ul' | 'select' | 'div'>

  // Button props
  buttonClass?: (open?: boolean, value?: T, disabled?: boolean) => string
  buttonChildren?: (open?: boolean, value?: T, disabled?: boolean) => JSX.Element | string
  buttonLabel?: (open?: boolean, value?: T, disabled?: boolean) => string

  // Options Menu props
  menuClass?: string // style the options
  optionsVariant?: OptionsVariant // style the options
  menuSections: SectionType[]
  position?: { clientX: number; clientY: number }
  show?: (open?: boolean) => boolean | undefined | boolean

  // Option List Item props
  optionAs?: keyof Pick<HTMLElementTagNameMap, 'a' | 'div' | 'li'>
  withSelect?: boolean
}

/**
 * Use menu's when a list item opens a modal.
 * This is because unlike the <Listbox /> component, the <Menu /> component
 * will yeild the focus to the modal. This is a11y best practice.
 */

export const Menu = ({
  disabled,
  onSelect,
  name,
  className,
  as,

  // Button
  buttonClass,
  buttonChildren,
  buttonLabel,

  // Options Menu
  menuClass,
  optionsVariant,
  menuSections,

  // Option List Items
  optionAs,
  withSelect,
}: MenuProps<string | number>) => {
  return (
    <div className="relative">
      <Transition as={Fragment} {...transitionProps} show={true}>
        <HeadlessMenu as={as || 'div'} className={`"relative inline-block text-left" ${className}`}>
          {({ open }) => (
            <>
              <HeadlessMenu.Button
                as="button"
                disabled={disabled}
                className={classNames(buttonClass && buttonClass(open), 'focus:outline-electro')}
                aria-label={buttonLabel && buttonLabel(open)}
                tabIndex={1}
              >
                {buttonChildren && buttonChildren(open)}
              </HeadlessMenu.Button>
              <HeadlessMenu.Items
                className={classNames(
                  'absolute max-h-[500px] z-10 w-[200px] first:rounded-b-sm rounded-sm py-[8px] focus:outline-none',
                  optionsVariant === OptionsVariant.DARK ? 'bg-dark-dark1' : 'bg-dark-dark5',
                  menuClass,
                )}
              >
                {menuSections &&
                  menuSections.map((section: SectionType, sectionIndex: number) => (
                    <div key={sectionIndex}>
                      {section.name ? (
                        <div className="px-[16px]">
                          <span className="text-gray-faded text-small">{section.name}</span>
                        </div>
                      ) : null}
                      {section.options
                        ? section.options.map((option: OptionType, optionIndex: number) => (
                            <HeadlessMenu.Item
                              key={optionIndex}
                              as={optionAs || 'div'}
                              disabled={option.isDisabled}
                              data-testid={`${name}-${optionIndex}`}
                              className={({ active }) =>
                                `${
                                  active && 'bg-dark-dark2'
                                } hover:bg-dark-dark2 select-auto cursor-pointer relative transition-colors`
                              }
                              onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
                                e.preventDefault()
                                e.stopPropagation()

                                return typeof option.onClick === 'function'
                                  ? option.onClick()
                                  : onSelect && onSelect(optionIndex)
                              }}
                            >
                              <>
                                {option.customRender ? (
                                  option.customRender
                                ) : (
                                  <div
                                    className={classNames(
                                      'inline-block px-[16px] py-[6px] text-small group-disabled:text-gray-dark cursor-pointer disabled:cursor-not-allowed',
                                      option.isDisabled ? 'text-gray-faded cursor-not-allowed' : '',
                                    )}
                                  >
                                    {option.image && (
                                      <img
                                        src={option.image.tq.url}
                                        className="w-[18px] h-[18px] inline-block mr-[6px]"
                                        aria-hidden="true"
                                      />
                                    )}
                                    {option.name}
                                  </div>
                                )}
                                {(withSelect || section.withSelect) && (
                                  <div
                                    className={classNames(
                                      'w-[20px] h-[20px] mr-[-6px] absolute top-0 right-[18px] transition-opacity',
                                      option.isSelect ? 'opacity-100' : 'opacity-0',
                                    )}
                                  >
                                    <Icon
                                      icon="check"
                                      className="absolute text-icon text-electro"
                                    />
                                  </div>
                                )}
                              </>
                            </HeadlessMenu.Item>
                          ))
                        : null}
                    </div>
                  ))}
              </HeadlessMenu.Items>
            </>
          )}
        </HeadlessMenu>
      </Transition>
    </div>
  )
}
