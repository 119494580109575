import { makeAutoObservable } from 'mobx'
import { MetricsDto, MetricsMethodTag, MetricsName } from 'api/models'

class ConnectionMetrics {
  private isBeConnectionsEnabled: boolean | null = null
  private timeToInteractive = 0
  private isConnectionsParsed = false
  private isAppInteractive = false
  private connectionsHandlingTime = {
    [MetricsMethodTag.frontendConnections]: 0,
    [MetricsMethodTag.backendConnections]: 0,
  }

  get isAllCollected() {
    return this.isConnectionsParsed && this.isAppInteractive && this.isBeConnectionsEnabled != null
  }

  get metricsObjects(): MetricsDto[] {
    const tag = this.isBeConnectionsEnabled
      ? MetricsMethodTag.backendConnections
      : MetricsMethodTag.frontendConnections
    return [
      {
        name: MetricsName.traceConnectionsTime,
        tags: { method: tag },
        value: Math.round(this.connectionsHandlingTime[tag]),
      },
      {
        name: MetricsName.totalTraceTimeToReady,
        tags: { method: tag },
        value: Math.round(this.timeToInteractive),
      },
    ]
  }

  constructor() {
    makeAutoObservable(this)
  }

  setIsBeConnectionsEnabled(value: boolean | undefined | null) {
    if (value != null) {
      this.isBeConnectionsEnabled = value
    }
  }

  addConnectionsHandlingTime(time: number, methodTag: MetricsMethodTag, contributor?: string) {
    if (this.isConnectionsParsed) {
      return
    }
    console.debug(
      '#CM ConnectionMetrics->addConnectionsHandlingTime [time, methodTag, contributor]',
      time,
      methodTag,
      contributor,
    )
    this.connectionsHandlingTime[methodTag] += time
  }

  setTimeToInteractive(time: number) {
    if (this.isAppInteractive) {
      return
    }
    console.debug('#CM ConnectionMetrics->setTimeToInteractive [time]', time)
    this.timeToInteractive = time
    this.setAppInteractive()
  }

  setConnectionsParsed() {
    console.debug(
      '#CM ConnectionMetrics->setConnectionsParsed [performance.now]',
      performance.now(),
    )
    this.isConnectionsParsed = true
  }

  private setAppInteractive() {
    console.debug('#CM ConnectionMetrics->setAppInteractive [performance.now]', performance.now())
    this.isAppInteractive = true
  }
}

export const connectionMetrics = new ConnectionMetrics()
