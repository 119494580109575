import React, { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useLocation, useSearchParams } from 'react-router-dom'
import { useMutation } from 'react-query'
import NProgress from 'nprogress'
import { useToaster } from 'hooks/useToaster'

import { useAuth } from 'contexts/auth-context'
import { Input } from 'components/Input'
import { AuthButton } from 'components/auth/AuthButton'
import { AuthLayout } from 'components/auth/AuthLayout'
import { SigninDto, TokenDest } from 'api/models'
import { PATH_SIGN_UP_BETA } from 'pages/auth/SignUpEarlyBirdPage'
import { GoogleButton } from 'components/auth/GoogleButton'
import { isPasswordStrong } from 'utils/validation'
import { AxiosError } from 'axios'
import { OktaButton } from 'components/auth/OktaButton'
import { PATH_ROOT } from 'utils/links'
import { PATH_RECOVERY_TOKEN } from './RecoveryTokenPage'

export const PATH_SIGN_IN = '/sign-in'

export const SignInPage = () => {
  const { t } = useTranslation()
  const auth = useAuth()
  const location = useLocation()
  const [searchParams] = useSearchParams()
  const toaster = useToaster()

  const [email, setEmail] = useState(searchParams.get('email') || location.state?.email || '')
  const [password, setPassword] = useState('')

  const [failedAttempt, setFailedAttempt] = useState(1)
  const [strongPasswordError, setStrongPasswordError] = useState(false)
  const [capsLockError, setCapsLockError] = useState(false)
  const isPasswordValid = useMemo(() => isPasswordStrong(password), [password])

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    setEmail(event.target.value)
  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value)
    if (strongPasswordError && failedAttempt > 1) {
      setStrongPasswordError(!isPasswordValid)
    }
  }
  const handlePasswordBlur = () => {
    if (failedAttempt > 1) {
      setStrongPasswordError(!isPasswordValid)
    }
  }

  const signInMutation = useMutation(
    (variables: SigninDto) => auth.signIn(variables, location.state?.from?.pathname ?? PATH_ROOT),
    {
      onSuccess: () => {
        NProgress.done()
      },
      onError: (error: AxiosError) => {
        NProgress.done()
        toaster.error(error)
        setFailedAttempt((prev) => prev + 1)
      },
    },
  )

  const signInSSOMutation = useMutation(
    (variables: SigninDto) =>
      auth.signInSSO({
        data: variables,
        redirectPath: location.state?.from?.pathname ?? PATH_ROOT,
      }),
    {
      onSuccess: () => {
        NProgress.done()
      },
      onError: (error: AxiosError) => {
        NProgress.done()
        toaster.error(error)
        setFailedAttempt((prev) => prev + 1)
      },
    },
  )

  const handleSignInViaFirebase = useCallback(
    (ssoIdToken) => {
      signInSSOMutation.mutate({
        ssoData: { ssoIdToken },
        rememberMe: true,
        tokenDest: TokenDest.COOKIE,
      })
    },
    [signInSSOMutation],
  )

  const handleSignInViaOkta = useCallback(
    (ssoIdToken) => {
      signInSSOMutation.mutate({
        ssoData: { ssoIdToken },
        rememberMe: true,
        tokenDest: TokenDest.BODY,
      })
    },
    [signInSSOMutation],
  )

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault()
    NProgress.start()
    signInMutation.mutate({
      emailData: { email, password },
      rememberMe: true,
      tokenDest: TokenDest.COOKIE,
    })
  }

  const checkCapsLock = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (failedAttempt > 1) {
      setCapsLockError(event.getModifierState('CapsLock'))
    }
  }

  return (
    <AuthLayout
      bottomInfo={[
        <Link to={PATH_RECOVERY_TOKEN} state={{ email }}>
          {t('auth.signInPage.forgotPassword')}
        </Link>,
        <span>
          <span className="text-gray-normal">{t('auth.common.signUpText')}</span>{' '}
          <Link to={PATH_SIGN_UP_BETA} state={{ email }}>
            {t('auth.common.register')}
          </Link>
        </span>,
      ]}
    >
      <h1 className="mt-6 text-center text-normal tracking-wide">{t('auth.common.welcome')}</h1>
      <form className="mt-6" onSubmit={handleSubmit}>
        <input type="hidden" name="remember" defaultValue="true" />
        <div>
          <label htmlFor="email-address" className="sr-only">
            {t('auth.common.email')}
          </label>
          <Input
            value={email}
            onChange={handleEmailChange}
            id="email-address"
            name="email"
            type="email"
            autoComplete="email"
            placeholder={t('auth.common.email')}
            required
          />
        </div>
        <div>
          <label htmlFor="password" className="sr-only">
            {t('auth.common.password')}
          </label>
          <Input
            value={password}
            onChange={handlePasswordChange}
            onKeyUp={checkCapsLock}
            onBlur={handlePasswordBlur}
            id="password"
            name="password"
            type="password"
            autoComplete="current-password"
            placeholder={t('auth.common.password')}
            required
          />
        </div>
        {capsLockError && (
          <p className="mb-4 text-center text-small tracking-wide text-state-bad">
            {t('auth.common.capsWarning')}
          </p>
        )}
        {strongPasswordError && (
          <p className="mb-4 text-center text-small tracking-wide text-state-bad">
            {t('auth.common.strongPassword')}
          </p>
        )}
        <AuthButton label={t('auth.signInPage.button')} isDisabled={signInMutation.isLoading} />
      </form>
      <GoogleButton onSuccess={handleSignInViaFirebase} />
      <OktaButton onSuccess={handleSignInViaOkta} />
    </AuthLayout>
  )
}
