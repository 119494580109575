import { makeAutoObservable, runInAction } from 'mobx'
import { Api } from 'api/Api'
import {
  AnnotationDelay,
  AnnotationDelayValue,
  AnnotationDto,
  AnnotationBindingDto,
  ChartPageParams,
} from 'api/models'
import { VideoPlayerState } from 'components/ps-chart/stores/VideoPlayerStore'
import { AnnotationsFeatureState } from 'components/ps-chart/PsChartStore'
import { AnnotationsDataStore } from 'components/ps-chart/stores/AnnotationsDataStore'
import { AnnotationsSettings } from 'components/ps-chart/local-timeline/annotations/AnnotationsSettings'

export type ReadonlyAnnotations = ReadonlyArray<AnnotationDto>

export interface AnnotationsState {
  annotations: ReadonlyAnnotations
  placedOnTimelineAnnotations: ReadonlyAnnotations
  notPlacedOnTimelineAnnotations: ReadonlyAnnotations

  selectedId: AnnotationIdAndType | null
  selectedPinBinding: AnnotationBindingDto | null

  hoveredId: AnnotationIdAndType | null
  hoveredPinBinding: AnnotationBindingDto | null

  editedId: AnnotationIdAndType | null

  annotationsWithConnectedSlices: ReadonlyAnnotations

  featureState: AnnotationsFeatureState

  isLoaded: boolean
}

interface AnnotationStoreApi {
  createLocally(): AnnotationDto

  create(id: number): Promise<AnnotationDto>

  delete(id: number): Promise<void>

  update(
    id: number,
    actionTag: string,
    actionDescription: string,
    reactionTag: string,
    reactionDescription: string,
    delay: AnnotationDelayValue,
  ): Promise<AnnotationDto>

  updateDelayLocally(id: number, delay: AnnotationDelayValue): AnnotationDto

  updateDelay(id: number, delay: AnnotationDelayValue): Promise<AnnotationDto>

  updateTitleAndDescriptionLocally(
    idAndType: AnnotationIdAndType,
    title: string,
    description: string,
  ): AnnotationDto

  updateTitleAndDescription(
    idAndType: AnnotationIdAndType,
    title: string,
    description: string,
  ): Promise<AnnotationDto>

  updateTimeLocally(time: number, idAndType: AnnotationIdAndType): AnnotationDto

  updateTime(time: number, idAndType: AnnotationIdAndType): Promise<AnnotationDto>

  connectToSlice(
    sliceId: number,
    sliceStartTime: number,
    idAndType: AnnotationIdAndType,
  ): Promise<AnnotationDto>

  disconnectFromSlice(idAndType: AnnotationIdAndType): Promise<AnnotationDto>
}

export class AnnotationsStore implements AnnotationsState, AnnotationStoreApi {
  private readonly api: Api
  private readonly chartPageParams: ChartPageParams
  private readonly videoState: VideoPlayerState
  readonly annotationsDataStore: AnnotationsDataStore
  readonly featureState: AnnotationsFeatureState
  readonly settings: AnnotationsSettings

  private _lastGeneratedCid = 0
  private _hoveredId: AnnotationIdAndType | null = null
  private _selectedId: AnnotationIdAndType | null = null
  private _editedId: AnnotationIdAndType | null = null

  constructor(
    api: Api,
    chartPageParams: ChartPageParams,
    videoState: VideoPlayerState,
    feature: AnnotationsFeatureState,
    annotationsDataStore: AnnotationsDataStore,
    settings: AnnotationsSettings,
  ) {
    makeAutoObservable<AnnotationsStore, 'api' | 'chartPageParams' | 'settings'>(this, {
      api: false,
      chartPageParams: false,
      settings: false,
    })
    this.settings = settings
    this.annotationsDataStore = annotationsDataStore
    this.api = api
    this.chartPageParams = chartPageParams
    this.featureState = feature
    this.videoState = videoState
  }

  get annotations(): AnnotationDto[] {
    return this.annotationsDataStore.annotations
  }

  get selectedAnnotation(): AnnotationDto | null {
    return this.selectedId ? this.getById(this.selectedId.id)! : null
  }

  get placedOnTimelineAnnotations(): ReadonlyAnnotations {
    return this.annotations.filter(
      (annotation) =>
        !AnnotationsStore.isAnnotationLocal(annotation) &&
        annotation.action.binding &&
        annotation.reaction.binding,
    )
  }

  get notPlacedOnTimelineAnnotations(): ReadonlyAnnotations {
    return this.annotations.filter((annotation) => {
      return (
        !AnnotationsStore.isAnnotationLocal(annotation) &&
        (annotation.action.binding === undefined || annotation.reaction.binding === undefined)
      )
    })
  }

  get pinIdsSortedByTime(): AnnotationIdAndType[] {
    return this.getPinIds(this.placedOnTimelineAnnotations, true)
  }

  get pinIdsNotPlacedOnTimelineByCreatedDate(): AnnotationIdAndType[] {
    return this.getPinIds(this.notPlacedOnTimelineAnnotations, false)
  }

  private getPinIds(annotations: ReadonlyAnnotations, sortByTime: boolean): AnnotationIdAndType[] {
    const pins: AnnotationIdAndType[] = []
    const timeByIdAndType = new Map<AnnotationIdAndType, number>()
    annotations.forEach((annotation) => {
      const actionId = { id: annotation.id, type: PinType.ACTION }
      const reactionId = { id: annotation.id, type: PinType.REACTION }
      pins.push(actionId)
      pins.push(reactionId)
      timeByIdAndType.set(
        actionId,
        sortByTime ? annotation.action.binding!.time : Date.parse(annotation.dateCreated),
      )
      timeByIdAndType.set(
        reactionId,
        sortByTime ? annotation.reaction.binding!.time : Date.parse(annotation.dateCreated),
      )
    })
    return pins.sort((a, b) => timeByIdAndType.get(a)! - timeByIdAndType.get(b)!)
  }

  get annotationsWithConnectedSlices(): ReadonlyAnnotations {
    return this.annotations.filter((annotation) =>
      AnnotationsStore.isAtLeastOnePinConnectedToSlices(annotation),
    )
  }

  createLocally(): AnnotationDto {
    const date = new Date()
    let time = date.getTime()
    if (this._lastGeneratedCid === time) {
      time = time + 1
    }
    this._lastGeneratedCid = time
    const cid = time
    const id = -cid
    const annotation: AnnotationDto = {
      id: id,
      cid: cid,
      delay: AnnotationDelay.NO_DELAY,
      action: {
        title: '',
        description: '',
      },
      reaction: {
        title: '',
        description: '',
      },
      dateCreated: date.toISOString(),
      dateUpdated: date.toISOString(),
    }
    this.annotationsDataStore.annotations.push(annotation)
    return annotation
  }

  create(id: number): Promise<AnnotationDto> {
    const annotation = this.getById(id)!
    return (
      this.api
        // @ts-expect-error FIXME: annotation.ci can't be null or undefined
        .postAnnotation(this.chartPageParams, annotation)
        .then((annotationFromServer) => this.runInActionUpdateFromServer(id, annotationFromServer))
        .catch((reason) => Promise.reject(reason))
    )
  }

  delete(id: number): Promise<void> {
    const annotation = this.getById(id)!
    const index = this.annotationsDataStore.annotations.indexOf(annotation)
    this.annotationsDataStore.annotations.splice(index, 1)
    if (this.selectedId && this.selectedId.id === id) {
      this.setSelectedId(null)
    }
    if (AnnotationsStore.isAnnotationLocal(annotation)) {
      return Promise.resolve()
    } else {
      // send API request only if annotation is not local
      return this.api.deleteAnnotation(this.chartPageParams, annotation).catch((reason) => {
        runInAction(() => {
          this.annotationsDataStore.annotations.push(annotation)
        })
        return Promise.reject(reason)
      })
    }
  }

  updateDelayLocally(id: number, delay: AnnotationDelayValue): AnnotationDto {
    const oldAnnotation = this.getById(id)!
    const updatedAnnotation = { ...oldAnnotation }
    updatedAnnotation.delay = delay
    this.replaceOldWithUpdated(oldAnnotation, updatedAnnotation)
    return updatedAnnotation
  }

  updateDelay(id: number, delay: AnnotationDelayValue): Promise<AnnotationDto> {
    const oldAnnotation = this.getById(id)!
    const updatedAnnotation = { ...oldAnnotation }
    updatedAnnotation.delay = delay
    this.replaceOldWithUpdated(oldAnnotation, updatedAnnotation)

    // We should send request only if annotation was created on the server
    if (AnnotationsStore.isAnnotationLocal(updatedAnnotation)) {
      return Promise.resolve(updatedAnnotation)
    } else {
      return this.api
        .putAnnotation(this.chartPageParams, updatedAnnotation)
        .then((fromServer) => this.runInActionUpdateFromServer(id, fromServer))
        .catch((reason) => {
          this.runInActionRollback(id, (tempAnnotation) => {
            tempAnnotation.delay = oldAnnotation.delay
          })
          return Promise.reject(reason)
        })
    }
  }

  updateTimeLocally(time: number, idAndType: AnnotationIdAndType): AnnotationDto {
    const oldAnnotation = this.getById(idAndType.id)!
    const updatedAnnotation = { ...oldAnnotation }

    const oldPinTime = AnnotationsStore.getPinTime(oldAnnotation, idAndType.type)
    if (oldPinTime === null) {
      if (idAndType.type === PinType.ACTION) {
        const oldReactionTime = AnnotationsStore.getPinTime(oldAnnotation, PinType.REACTION)
        if (oldReactionTime !== null && time > oldReactionTime) {
          time = oldReactionTime
        }
      } else {
        const oldActionTime = AnnotationsStore.getPinTime(oldAnnotation, PinType.ACTION)
        if (oldActionTime !== null && time < oldActionTime) {
          time = oldActionTime
        }
      }
    }

    AnnotationsStore.setPinTime(updatedAnnotation, idAndType.type, time)
    this.replaceOldWithUpdated(oldAnnotation, updatedAnnotation)

    if (this.featureState.enabled && this.selectedId === null) {
      this.setSelectedId(idAndType)
    }

    return updatedAnnotation
  }

  updateTime(time: number, idAndType: AnnotationIdAndType): Promise<AnnotationDto> {
    const oldAnnotation = this.getById(idAndType.id)!
    const oldTime = AnnotationsStore.getPinTime(oldAnnotation, idAndType.type)

    const updatedAnnotation =
      time !== oldTime ? { ...this.updateTimeLocally(time, idAndType) } : { ...oldAnnotation }
    AnnotationsStore.setPinTime(updatedAnnotation, idAndType.type, time)

    // We should send request only if annotation was created on the server
    if (AnnotationsStore.isAnnotationLocal(updatedAnnotation)) {
      return Promise.resolve(updatedAnnotation)
    } else {
      return this.api
        .putAnnotation(this.chartPageParams, updatedAnnotation)
        .then((fromServer) => this.runInActionUpdateFromServer(idAndType.id, fromServer))
        .catch((reason) => {
          this.runInActionRollback(idAndType.id, (tempAnnotation) => {
            AnnotationsStore.setPinTime(tempAnnotation, idAndType.type, oldTime)
          })
          return Promise.reject(reason)
        })
    }
  }

  update(
    id: number,
    actionTag: string,
    actionDescription: string,
    reactionTag: string,
    reactionDescription: string,
    delay: AnnotationDelayValue,
  ): Promise<AnnotationDto> {
    const oldAnnotation = this.getById(id)!
    const updatedAnnotation = { ...oldAnnotation }
    updatedAnnotation.action.title = actionTag
    updatedAnnotation.action.description = actionDescription
    updatedAnnotation.reaction.title = reactionTag
    updatedAnnotation.reaction.description = reactionDescription
    updatedAnnotation.delay = delay
    this.replaceOldWithUpdated(oldAnnotation, updatedAnnotation)

    if (AnnotationsStore.isAnnotationLocal(oldAnnotation)) {
      return Promise.resolve(updatedAnnotation)
    } else {
      return this.api
        .putAnnotation(this.chartPageParams, updatedAnnotation)
        .then((annotationFromServer) => this.runInActionUpdateFromServer(id, annotationFromServer))
        .catch((reason) => {
          this.runInActionRollback(id, (tempAnnotation) => {
            tempAnnotation.action.title = oldAnnotation.action.title
            tempAnnotation.action.description = oldAnnotation.action.description
            tempAnnotation.reaction.title = oldAnnotation.reaction.title
            tempAnnotation.reaction.description = oldAnnotation.reaction.description
            tempAnnotation.delay = oldAnnotation.delay
          })
          return Promise.reject(reason)
        })
    }
  }

  updateTitleAndDescriptionLocally(
    idAndType: AnnotationIdAndType,
    title: string,
    description: string,
  ): AnnotationDto {
    const oldAnnotation = this.getById(idAndType.id)!
    const updatedAnnotation = { ...oldAnnotation }
    const pin = idAndType.type === PinType.ACTION ? oldAnnotation.action : oldAnnotation.reaction
    pin.title = title
    pin.description = description

    this.replaceOldWithUpdated(oldAnnotation, updatedAnnotation)
    return updatedAnnotation
  }

  updateTitleAndDescription(
    idAndType: AnnotationIdAndType,
    title: string,
    description: string,
  ): Promise<AnnotationDto> {
    const oldAnnotation = this.getById(idAndType.id)!
    const updatedAnnotation = { ...oldAnnotation }
    const pin = idAndType.type === PinType.ACTION ? oldAnnotation.action : oldAnnotation.reaction
    pin.title = title
    pin.description = description

    this.replaceOldWithUpdated(oldAnnotation, updatedAnnotation)

    if (AnnotationsStore.isAnnotationLocal(oldAnnotation)) {
      return Promise.resolve(updatedAnnotation)
    } else {
      return this.api
        .putAnnotation(this.chartPageParams, updatedAnnotation)
        .then((annotationFromServer) =>
          this.runInActionUpdateFromServer(idAndType.id, annotationFromServer),
        )
        .catch((reason) => {
          this.runInActionRollback(idAndType.id, (tempAnnotation) => {
            const oldPin =
              idAndType.type === PinType.ACTION ? oldAnnotation.action : oldAnnotation.reaction
            const newPin =
              idAndType.type === PinType.ACTION ? tempAnnotation.action : tempAnnotation.reaction
            newPin.title = oldPin.title
            newPin.description = oldPin.description
          })
          return Promise.reject(reason)
        })
    }
  }

  connectToSlice(
    sliceId: number,
    sliceTime: number,
    idAndType: AnnotationIdAndType,
  ): Promise<AnnotationDto> {
    const oldAnnotation = this.getById(idAndType.id)!
    const updatedAnnotation = { ...oldAnnotation }

    if (
      idAndType.type === PinType.ACTION &&
      sliceTime > (oldAnnotation.reaction.binding?.time || -1)
    ) {
      return Promise.reject('psChart.annotation.error.actionAfterReaction')
    } else if (
      idAndType.type === PinType.REACTION &&
      sliceTime < (oldAnnotation.action.binding?.time || -1)
    ) {
      return Promise.reject('psChart.annotation.error.reactionBeforeAction')
    }

    AnnotationsStore.setPinTime(updatedAnnotation, idAndType.type, sliceTime, sliceId)
    this.replaceOldWithUpdated(oldAnnotation, updatedAnnotation)

    if (AnnotationsStore.isAnnotationLocal(oldAnnotation)) {
      return Promise.resolve(updatedAnnotation)
    } else {
      return this.api
        .putAnnotation(this.chartPageParams, updatedAnnotation)
        .then((annotationFromServer) =>
          this.runInActionUpdateFromServer(idAndType.id, annotationFromServer),
        )
        .catch((reason) => {
          this.runInActionRollback(idAndType.id, (tempAnnotation) => {
            if (idAndType.type === PinType.ACTION) {
              tempAnnotation.action.binding = oldAnnotation.action.binding
            } else {
              tempAnnotation.reaction.binding = oldAnnotation.reaction.binding
            }
          })
          return Promise.reject(reason)
        })
    }
  }

  disconnectFromSlice(idAndType: AnnotationIdAndType): Promise<AnnotationDto> {
    const oldAnnotation = this.getById(idAndType.id)!
    const oldTime = AnnotationsStore.getPinTime(oldAnnotation, idAndType.type)
    const oldSliceId =
      idAndType.type === PinType.ACTION
        ? oldAnnotation.action.binding?.sliceId ?? null
        : oldAnnotation.reaction.binding?.sliceId ?? null
    const updatedAnnotation = { ...oldAnnotation }

    AnnotationsStore.setPinTime(updatedAnnotation, idAndType.type, oldTime, null)
    this.replaceOldWithUpdated(oldAnnotation, updatedAnnotation)

    if (AnnotationsStore.isAnnotationLocal(oldAnnotation)) {
      return Promise.resolve(updatedAnnotation)
    } else {
      return this.api
        .putAnnotation(this.chartPageParams, updatedAnnotation)
        .then((annotationFromServer) =>
          this.runInActionUpdateFromServer(idAndType.id, annotationFromServer),
        )
        .catch((reason) => {
          this.runInActionRollback(idAndType.id, (tempAnnotation) => {
            AnnotationsStore.setPinTime(tempAnnotation, idAndType.type, oldTime, oldSliceId)
          })
          return Promise.reject(reason)
        })
    }
  }

  get hoveredId(): AnnotationIdAndType | null {
    return this._hoveredId
  }

  setHoveredId(idAndType: AnnotationIdAndType | null) {
    this._hoveredId = idAndType
  }

  get selectedId(): AnnotationIdAndType | null {
    if (!this.featureState.draggable) {
      return null
    }
    if (!this._selectedId) {
      this.setDefaultSelectedAnnotation(this.annotationsDataStore.annotations.slice())
    }
    return this._selectedId
  }

  setSelectedId(idAndType: AnnotationIdAndType | null) {
    this._selectedId = idAndType
  }

  get selectedPinBinding(): AnnotationBindingDto | null {
    const annotation = this.selectedAnnotation
    if (!annotation) {
      return null
    }
    return this._selectedId ? AnnotationsStore.getBinding(annotation, this._selectedId.type) : null
  }

  get hoveredPinBinding(): AnnotationBindingDto | null {
    if (!this._hoveredId) {
      return null
    }
    const annotation = this.getById(this._hoveredId.id)!
    if (this._hoveredId.type === PinType.ACTION) {
      return annotation.action.binding ?? null
    } else {
      return annotation.reaction.binding ?? null
    }
  }

  get editedId(): AnnotationIdAndType | null {
    return this._editedId
  }

  setEditedId(idAndType: AnnotationIdAndType | null) {
    this._editedId = idAndType
  }

  getById(id: number): AnnotationDto | undefined {
    return this.annotationsDataStore.annotations.find((annotation) =>
      id < 0 ? -id === annotation.cid : id === annotation.id,
    )
  }

  getBindingByIdAndPinType(idAndType: AnnotationIdAndType): AnnotationBindingDto | null {
    const annotation = this.getById(idAndType.id)!
    if (idAndType.type === PinType.ACTION) {
      return annotation.action.binding ?? null
    } else {
      return annotation.reaction.binding ?? null
    }
  }

  get isLoaded(): boolean {
    return this.annotationsDataStore.isLoaded
  }

  static isBoundToTimeline(annotation: AnnotationDto, type?: PinType): boolean {
    const hasBoundAction = annotation.action.binding !== undefined
    const hasBoundReaction = annotation.reaction.binding !== undefined
    if (type) {
      return type === PinType.ACTION ? hasBoundAction : hasBoundReaction
    } else {
      return hasBoundAction && hasBoundReaction
    }
  }

  static hasDelay(delay: AnnotationDelayValue) {
    return delay !== AnnotationDelay.NO_DELAY
  }

  static delay(hasDelay: boolean): AnnotationDelayValue {
    return hasDelay ? AnnotationDelay.MODERATE_DELAY : AnnotationDelay.NO_DELAY
  }

  static getPinTime(annotation: AnnotationDto, type: PinType): number | null {
    const binding = AnnotationsStore.getBinding(annotation, type)
    return binding?.time ?? null
  }

  static getPinTimeString(annotation: AnnotationDto, type: PinType, defaultValue?: string): string {
    const time = AnnotationsStore.getPinTime(annotation, type)
    if (time) {
      return new Date(time / 1_000_000).toISOString().slice(14, -2)
    } else {
      return defaultValue ?? ''
    }
  }

  static setPinTime(
    annotation: AnnotationDto,
    type: PinType,
    time: number | null,
    sliceId: number | null = null,
  ) {
    const annotationPin = type === PinType.ACTION ? annotation.action : annotation.reaction
    if (time === null) {
      annotationPin.binding = undefined
    } else {
      if (!annotationPin.binding) {
        annotationPin.binding = { time: time }
      } else {
        annotationPin.binding.time = time
      }
      if (sliceId !== null) {
        annotationPin.binding.sliceId = sliceId
      } else {
        annotationPin.binding.sliceId = undefined
      }
    }
  }

  static getBinding(annotation: AnnotationDto, type: PinType): AnnotationBindingDto | null {
    const annotationPin = type === PinType.ACTION ? annotation.action : annotation.reaction
    return annotationPin.binding ?? null
  }

  static getPinTitle(annotation: AnnotationDto, type: PinType): string {
    return type === PinType.ACTION ? annotation.action.title : annotation.reaction.title
  }

  static getPinDescription(annotation: AnnotationDto, type: PinType): string {
    return type === PinType.ACTION ? annotation.action.description : annotation.reaction.description
  }

  static isAnnotationLocal(annotation: AnnotationDto): boolean {
    return annotation.id < 0
  }

  static isAtLeastOnePinConnectedToSlices(annotation: AnnotationDto): boolean {
    return (
      AnnotationsStore.isPinConnectedToSlices(annotation, PinType.ACTION) ||
      AnnotationsStore.isPinConnectedToSlices(annotation, PinType.REACTION)
    )
  }

  static isFullyConnectedToSlices(annotation: AnnotationDto): boolean {
    return (
      AnnotationsStore.isPinConnectedToSlices(annotation, PinType.ACTION) &&
      AnnotationsStore.isPinConnectedToSlices(annotation, PinType.REACTION)
    )
  }

  static isPinConnectedToSlices(annotation: AnnotationDto, type: PinType): boolean {
    const binding = AnnotationsStore.getBinding(annotation, type)
    return binding !== null && binding.sliceId !== undefined
  }

  private runInActionUpdateFromServer(
    id: number,
    annotationFromServer: AnnotationDto,
  ): AnnotationDto {
    runInAction(() => {
      const annotation = this.getById(id)!
      const index = this.annotationsDataStore.annotations.indexOf(annotation)
      this.annotationsDataStore.annotations.splice(index, 1, annotationFromServer)
    })
    return annotationFromServer
  }

  private runInActionRollback(id: number, rollbackAction: (item: AnnotationDto) => void) {
    runInAction(() => {
      const item = this.getById(id)!
      const index = this.annotationsDataStore.annotations.indexOf(item)
      rollbackAction(item)
      this.annotationsDataStore.annotations.splice(index, 1, item)
    })
  }

  private setDefaultSelectedAnnotation(annotations: AnnotationDto[]) {
    if (!this.featureState.draggable) {
      return
    }
    if (annotations.length > 0) {
      annotations.sort((a, b) => (a.action.binding?.time ?? 0) - (b.action.binding?.time ?? 0))
      for (let i = 0; i < annotations.length; i++) {
        const annotation = annotations[i]
        if (annotation.action.binding !== undefined) {
          this.setSelectedId({ id: annotation.id, type: PinType.ACTION })
          break
        }
        if (annotation.reaction.binding !== undefined) {
          this.setSelectedId({ id: annotation.id, type: PinType.REACTION })
          break
        }
      }
    }
  }

  private replaceOldWithUpdated(old: AnnotationDto, updated: AnnotationDto) {
    const index = this.annotationsDataStore.annotations.indexOf(old)
    this.annotationsDataStore.annotations.splice(index, 1, updated)
  }
}

export interface AnnotationIdAndType {
  id: number
  type: PinType
}

export enum PinType {
  ACTION = 'ACTION',
  REACTION = 'REACTION',
}
