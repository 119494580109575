import Konva from 'konva'
import { VideoPlayerStore } from 'components/ps-chart/stores/VideoPlayerStore'
import { GhostIndicatorSettings } from 'components/global-timeline/models/GhostIndicatorSettings'

export class GhostIndicator {
  private width: number
  private startTime: number
  private readonly height: number
  private readonly interactionAreaHeight: number
  private timePerPx: number
  private readonly settings: GhostIndicatorSettings
  private readonly videoStore: VideoPlayerStore
  private readonly text: Konva.Text
  private readonly rect: Konva.Rect
  private readonly indicatorGroup: Konva.Group
  private readonly showLineInChart: boolean
  private hovered = false

  public readonly layer: Konva.Layer
  public readonly hoverBgRect: Konva.Rect

  constructor(
    width: number,
    height: number,
    interactionAreaHeight: number,
    timePerPx: number,
    settings: GhostIndicatorSettings,
    videoStore: VideoPlayerStore,
    showLineInChart?: boolean,
    startTime?: number,
  ) {
    this.width = width
    this.height = height
    this.interactionAreaHeight = interactionAreaHeight
    this.timePerPx = timePerPx
    this.settings = settings
    this.videoStore = videoStore
    this.showLineInChart = showLineInChart || false
    this.startTime = startTime || 0

    this.layer = new Konva.Layer()

    const textRect = new Konva.Rect({
      width: this.settings.textRectWidth,
      height: this.settings.textRectHeight,
      fill: this.settings.color,
    })

    this.text = new Konva.Text({
      padding: 4,
      text: '00.00.00',
      fontSize: this.settings.fontSize,
      fontFamily: this.settings.fontFamily,
      fill: this.settings.textColor,
    })

    this.indicatorGroup = new Konva.Group({ visible: false })
    this.indicatorGroup.add(
      new Konva.Line({
        points: [0, 0, 0, this.height],
        stroke: settings.color,
        strokeWidth: settings.strokeWidth,
      }),
    )
    this.indicatorGroup.add(textRect)
    this.indicatorGroup.add(this.text)
    this.layer.add(this.indicatorGroup)

    this.rect = new Konva.Rect({
      width: this.width,
      height: this.interactionAreaHeight,
    })
    this.layer.add(this.rect)

    this.hoverBgRect = new Konva.Rect({
      width: this.width,
      height: this.interactionAreaHeight,
      fill: this.settings.hoverColor,
      visible: false,
    })
  }

  addEventListeners(): void {
    this.rect.on('mouseout', this.handleMouseLeave)
    this.rect.on('mouseover', this.handleMouseEnter)
    this.rect.on('mousemove', this.handleMouseMove)
    this.rect.on('click', this.handleClick)
  }

  removeEventListeners(): void {
    this.rect.off('mouseout')
    this.rect.off('mouseover')
    this.rect.off('mousemove')
    this.rect.off('click')
  }

  private handleMouseEnter = (): void => {
    if (this.videoStore.isVideoPlaying) {
      return
    }

    this.indicatorGroup.visible(true)
    this.hoverBgRect.visible(true)
    this.hovered = true
  }

  handleMouseLeave = (): void => {
    this.indicatorGroup.visible(false)
    this.hoverBgRect.visible(false)
    this.hovered = false
    if (this.showLineInChart) {
      this.videoStore.setShowHover(null)
    }

    if (this.videoStore.isVideoPlaying) {
      return
    }

    this.setTimeForVideoPreview(this.videoStore.traceVideoPointerTimeNanos)
  }

  private handleMouseMove = (): void => {
    if (this.videoStore.isVideoPlaying) {
      return
    }
    if (!this.hovered) {
      this.handleMouseEnter()
    }

    const { x } = this.rect.getRelativePointerPosition()
    this.text.text(this.xToTime(x))
    this.indicatorGroup.x(x)
    const time = this.startTime + x * this.timePerPx
    this.setTimeForVideoPreview(time)
    if (this.showLineInChart) {
      this.videoStore.setShowHover(time)
    }
  }

  private handleClick = (): void => {
    if (this.videoStore.isVideoPlaying) {
      return
    }

    const { x } = this.rect.getRelativePointerPosition()
    const currentTime = this.startTime + x * this.timePerPx
    if (this.videoStore.checkTimeInBounds(currentTime)) {
      this.videoStore.setVideoPointerTimeNanos(currentTime)
    }
  }

  private setTimeForVideoPreview(time: number): void {
    const newTime = this.videoStore.getVideoPointerTimeSecByTraceTimeNanos(time)
    if (newTime !== null) {
      this.videoStore.setVideoCurrentTime(newTime)
    }
  }

  private xToTime(x: number): string {
    const currentTime = (this.startTime + x * this.timePerPx) / 1_000_000_000
    const time = new Date(currentTime * 1000)
    return String(time?.toISOString()?.slice(14, -2).replace('.', ':') || '00:00:00')
  }

  update(width: number, timePerPx: number, startTime: number): void {
    this.width = width
    this.timePerPx = timePerPx
    this.startTime = startTime

    this.hoverBgRect.width(width)
    this.rect.width(width)

    if (this.hovered) {
      this.handleMouseMove()
    }
  }
}
