import { throttle } from 'throttle-debounce'
import { useLayoutEffect, useRef, useState } from 'react'

/**
 * @description Observe video size and return video width.
 * This is used to orchestrate the scaling of the video and empty frame preview elements
 */
export const useObserveVideoSize = (videoBox: React.RefObject<HTMLDivElement>) => {
  const resizeObserverRef = useRef<ResizeObserver | null>(null)

  const [videoWidth, setVideoWidth] = useState(0)

  useLayoutEffect(() => {
    if (videoBox.current !== null && resizeObserverRef.current === null) {
      resizeObserverRef.current = new ResizeObserver(
        throttle(10, (entries) => {
          const videoElWidth = entries[0].contentRect.width
          setVideoWidth(videoElWidth)
        }),
      )

      resizeObserverRef.current.observe(videoBox.current)
    }

    return () => {
      if (resizeObserverRef.current !== null) {
        resizeObserverRef.current.disconnect()
        resizeObserverRef.current = null
      }
    }
  }, [videoBox, resizeObserverRef])

  return {
    videoWidth,
  }
}
