import React, { useMemo } from 'react'

import { Layout } from 'components/Layout'
import { FreeTrialQuestionnaire } from 'components/free-trial/FreeTrialQuestionnaire'
import { ShareLinkContextProvider } from 'components/share-with-team/ShareLinkContext'
import { useTeamsQuery } from 'hooks/useApiQuery'
import { GuideStore } from 'components/guide/GuideStore'
import { useFreeTrialShareLink } from 'components/share-with-team/useFreeTrialShareLink'
import { BaseHeader } from 'components/header/BaseHeader'
import { SwitchButton } from 'components/header/SwitchButton'
import { ShareLink } from 'components/share-with-team/ShareLink'
import { Footer } from 'components/Footer'

export const PATH_FREE_TRIAL_QUESTIONNAIRE = '/free-trial-questionnaire'

export const FreeTrialQuestionnairePage = () => {
  const { data: teams } = useTeamsQuery()
  const defaultFreeTrialTeam = GuideStore.getDefaultFreeTrialTeam(teams ?? [])

  const { getShareLink, sendShareLink, isShareLinkReady } = useFreeTrialShareLink(
    defaultFreeTrialTeam?.urlName,
  )

  const header = useMemo(() => {
    return (
      <BaseHeader
        rightSideContent={
          <>
            <SwitchButton isLiveDemo={false} />
            <ShareLink />
          </>
        }
      />
    )
  }, [])

  const footer = useMemo(() => <Footer />, [])

  return (
    <ShareLinkContextProvider
      getShareLink={getShareLink}
      sendShareLink={sendShareLink}
      isShareLinkReady={isShareLinkReady}
    >
      <Layout headerComponent={header} footerComponent={footer}>
        <FreeTrialQuestionnaire />
      </Layout>
    </ShareLinkContextProvider>
  )
}
