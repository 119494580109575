import React, { useCallback, useEffect } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'

import {
  Button,
  ButtonProps,
  ButtonTextColorVariantEnum,
  ButtonVariantEnum,
} from 'components/Button'
import { addTopLevelClickHandler, removeTopLevelClickHandler } from 'utils/top-level-handler'

export enum ModalSizeEnum {
  SMALL,
  NORMAL,
}

type ModalButtonType = ButtonProps & {
  children?: React.ReactNode
  onClick?: () => void
  disabled?: boolean
}

export interface ModalProps {
  size?: ModalSizeEnum
  children: React.ReactNode
  isOpen: boolean
  onClose: () => void
  title: string
  secondaryTitle?: string
  additionalButton?: ModalButtonType
  actionButton?: ModalButtonType
  hiddenCloseButton?: boolean
  onRendered?: () => void
  afterClose?: () => void

  /**
   * In commit 6de3763bfac106bd8ce13a70fc61703a09512c5f Ilsur added code to
   * prevent key events from being propagated for the CustomerSupportModal.
   * But this breaks `Esc` and `Enters` key events for all modals.
   * As well as it adds focusable `div` that stops focus to be set to the first input in the modal.
   *
   * This field is an attempt to keep the current behavior for the CustomerSupportModal,
   * but to let other components behave normally.
   */
  preventKeyEvents?: boolean
}

export const Modal = ({
  children,
  onClose,
  title,
  isOpen,
  secondaryTitle,
  size = ModalSizeEnum.NORMAL,
  additionalButton,
  actionButton,
  hiddenCloseButton,
  onRendered,
  afterClose,
  preventKeyEvents,
}: ModalProps) => {
  const { t } = useTranslation()

  const handleStopPropagation = useCallback((event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation()
  }, [])

  /**
   * This is a workaround to prevent Modal from being closed when clicking on a toast.
   */
  useEffect(() => {
    const toastRootEl = document.querySelector<Element>('[data-testid="toast-root"]')
    const onTopLevelClick = (event: Event) => {
      if (event.target != null && toastRootEl?.contains(event.target as Node)) {
        /**
         * This will prevent the Headlessui Dialog from being closed when clicking "outside".
         * This is based on the implementation of useOutsideClick hook from Headlessui,
         * which checks `if (event.defaultPrevented) return`:
         * https://github.com/tailwindlabs/headlessui/blob/main/packages/%40headlessui-react/src/hooks/use-outside-click.ts#L40
         */
        event.preventDefault()
      }
    }
    addTopLevelClickHandler(onTopLevelClick)
    return () => {
      removeTopLevelClickHandler(onTopLevelClick)
    }
  }, [])

  return (
    <Transition
      appear
      show={isOpen}
      as={React.Fragment}
      beforeEnter={onRendered}
      afterLeave={afterClose}
    >
      <Dialog as="div" className="relative z-modal" onClose={onClose}>
        <Transition.Child
          as={React.Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Dialog.Overlay className="fixed inset-0 bg-dark-default/60" />
        </Transition.Child>
        <Transition.Child
          as={React.Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          <div
            className="fixed inset-0 p-6 overflow-y-auto scrolling-touch flex flex-col"
            onClick={handleStopPropagation}
          >
            <div className="flex flex-1 my-0 mx-auto">
              <div className="self-center justify-self-center">
                <Dialog.Panel
                  className={classNames(
                    { 'w-[480px]': size === ModalSizeEnum.SMALL },
                    { 'w-[560px]': size === ModalSizeEnum.NORMAL },
                  )}
                >
                  <div
                    className="px-[24px] py-[16px] rounded-[8px] transform bg-dark-dark5"
                    tabIndex={preventKeyEvents && isOpen ? 0 : undefined}
                    onKeyDown={(event) => {
                      if (preventKeyEvents) {
                        event.stopPropagation()
                      }
                    }}
                  >
                    <Dialog.Title
                      className="text-normal tracking-wide font-medium min-h-[40px] pb-[10px]"
                      as="h3"
                    >
                      {title}{' '}
                      {secondaryTitle && (
                        <span className="text-gray-normal break-all">{secondaryTitle}</span>
                      )}
                    </Dialog.Title>
                    {children}
                    <div className="flex justify-between min-h-[41px] items-end">
                      <div>
                        {additionalButton?.children && (
                          <Button
                            className="outline-offset-8"
                            variant={ButtonVariantEnum.Text}
                            textColorVariant={ButtonTextColorVariantEnum.Muted}
                            {...additionalButton}
                          />
                        )}
                      </div>
                      <div>
                        {!hiddenCloseButton && (
                          <Button
                            className="outline-offset-8 mr-[24px]"
                            variant={ButtonVariantEnum.Text}
                            textColorVariant={ButtonTextColorVariantEnum.Muted}
                            onClick={onClose}
                          >
                            {t('cancel')}
                          </Button>
                        )}
                        <Button
                          className="outline-offset-8 text-state-bad"
                          variant={ButtonVariantEnum.Text}
                          textColorVariant={ButtonTextColorVariantEnum.Primary}
                          {...actionButton}
                        />
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </div>
            </div>
          </div>
        </Transition.Child>
      </Dialog>
    </Transition>
  )
}
