import { SliceLink } from 'components/ps-chart/models/SliceLink'
import { Slice } from 'components/ps-chart/models/Slice'
import { ReadonlySliceById } from 'components/ps-chart/stores/TraceDataStore'
import { NamedLinkType, NamedLinkTypeValue } from 'api/models'
import { ConnectionDirection } from 'components/ps-chart/models/ConnectionDirection'
import { getAncestorsOccupiedSliceIds } from './mergeSameTargetStackChains'
import { getStackChainsLinks } from './getStackChainsLinks'

export enum ConnectionErrorCode {
  CONNECT_SLICES_WITH_SAME_NAME = 'psChart.error.connection.slicesWithSameName',
  CONNECT_SLICE_TO_ITSELF = 'psChart.error.connection.connectSliceToItself',
  DUPLICATE_LINK = 'psChart.error.connection.duplicateLink',
  SLICE_START_TIME_BIGGER_THAN_END_TIME_OF_CURRENT = 'psChart.error.connection.sliceStartTimeBiggerThanEndTimeCurrent',
  SLICE_START_TIME_BIGGER_THAN_START_TIME_OF_CURRENT = 'psChart.error.connection.sliceStartTimeBiggerThanStartTimeCurrent',
  CANNOT_BE_SHOWN = 'psChart.error.connection.cannotBeShown',
  CANNOT_CONNECT_ANCESTOR = 'psChart.error.connection.cannotConnectAncestor',
}

export const getConnectionError = (
  fromSlice: Slice,
  toSlice: Slice,
  sliceById: ReadonlySliceById,
  sliceLinksBySliceId: Map<number, ReadonlyArray<SliceLink>>,
  type: NamedLinkTypeValue,
): ConnectionErrorCode | null => {
  const isAsync = type === NamedLinkType.ASYNC
  if (fromSlice.title === toSlice.title && type === NamedLinkType.SYNC) {
    return ConnectionErrorCode.CONNECT_SLICES_WITH_SAME_NAME
  }
  if (fromSlice.id === toSlice.id) {
    return ConnectionErrorCode.CONNECT_SLICE_TO_ITSELF
  }
  const positionError = getConnectedSlicesPositionError(fromSlice, toSlice, isAsync)
  if (positionError != null) {
    return positionError
  }
  const fromSliceLinks = sliceLinksBySliceId.get(fromSlice.id)
  const duplicateLink = fromSliceLinks?.find((link) => link.toSliceId === toSlice.id)
  if (duplicateLink != null) {
    return ConnectionErrorCode.DUPLICATE_LINK
  }

  const nonVisibleError = getNonVisibleConnectionError(
    fromSlice,
    toSlice,
    sliceById,
    sliceLinksBySliceId,
  )
  if (nonVisibleError != null) {
    return nonVisibleError
  }

  const ancestorsConnectionError = getAncestorsConnectionError(fromSlice, toSlice)
  if (ancestorsConnectionError != null) {
    return ancestorsConnectionError
  }

  return null
}

export const getConnectedSlicesPositionError = (
  fromSlice: Slice,
  toSlice: Slice,
  isAsync = false,
): ConnectionErrorCode | null => {
  if (isAsync) {
    if (toSlice.start > fromSlice.end) {
      return ConnectionErrorCode.SLICE_START_TIME_BIGGER_THAN_END_TIME_OF_CURRENT
    }
  } else {
    if (toSlice.start > fromSlice.start) {
      return ConnectionErrorCode.SLICE_START_TIME_BIGGER_THAN_START_TIME_OF_CURRENT
    }
  }
  return null
}

export const getAncestorsConnectionError = (
  fromSlice: Slice,
  toSlice: Slice,
): ConnectionErrorCode | null => {
  let curCheckSlice: Slice | null = fromSlice
  while (curCheckSlice != null) {
    if (curCheckSlice === toSlice) {
      return ConnectionErrorCode.CANNOT_CONNECT_ANCESTOR
    }
    curCheckSlice = curCheckSlice.parent
  }
  return null
}

export const getNonVisibleConnectionError = (
  fromSlice: Slice,
  toSlice: Slice,
  sliceById: ReadonlySliceById,
  sliceLinksBySliceId: Map<number, ReadonlyArray<SliceLink>>,
  connectionDirection: ConnectionDirection = ConnectionDirection.BACKWARD,
) => {
  const stackChainLinks = getStackChainsLinks(
    fromSlice,
    sliceLinksBySliceId,
    connectionDirection,
  ).filter((link) => link.fromSliceId !== fromSlice.id || link.toSliceId !== toSlice.id)
  const ancestorsOccupiedSliceIds = getAncestorsOccupiedSliceIds(sliceById, stackChainLinks)

  if (ancestorsOccupiedSliceIds.has(toSlice.id)) {
    return ConnectionErrorCode.CANNOT_BE_SHOWN
  }

  return null
}
