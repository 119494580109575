import { UseFormReturn } from 'react-hook-form'

export interface MenuProps {
  formContext: UseFormReturn<CopyTraceFormFields>
}

export interface ListItem {
  label: string
  value: string
}

export const FIELDS = {
  TEAM: 'team',
  PROJECT: 'project',
  FLOW: 'flow',
} as const

export interface CopyTraceFormFields {
  team: string
  project: string
  flow: string
}
