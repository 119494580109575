import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { twMerge } from 'tailwind-merge'

import GoogleSVG from 'assets/img/google.svg'
import { useGoogleLogin } from '@react-oauth/google'
import NProgress from 'nprogress'
import { useToaster } from 'hooks/useToaster'
import { useFirebaseAuth } from 'contexts/di-context'

export type GoogleButtonSuccessHandler = (fbIdToken: string) => void

interface GoogleButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  onSuccess: GoogleButtonSuccessHandler
}

export const GoogleButton = (props: GoogleButtonProps) => {
  const { onSuccess, children, className, ...otherProps } = props
  const { signInWithGoogle } = useFirebaseAuth()
  const toaster = useToaster()
  const { t } = useTranslation()

  const signInViaGoogle = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      NProgress.start()
      try {
        const fbIdToken = await signInWithGoogle(tokenResponse.access_token)
        onSuccess(fbIdToken)
      } catch (error) {
        toaster.error('errorMessage')
      }
      NProgress.done()
    },
    onError: (errorResponse) => {
      toaster.error(errorResponse.error_description)
    },
  })

  const handleButtonClick = useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.preventDefault()
      signInViaGoogle()
    },
    [signInViaGoogle],
  )

  return (
    <button
      className={twMerge(
        'h-[32px] flex justify-center items-center w-full mt-[52px] border-[1px] border-gray-strokeLight text-small font-medium transition-colors',
        !otherProps.disabled && 'hover:border-white',
        className,
      )}
      onClick={handleButtonClick}
      {...otherProps}
    >
      <img src={GoogleSVG} alt="" aria-hidden="true" className="w-[32px] h-[32px] mr-[6px]" />
      {children || t('auth.common.signInWithGoogle')}
    </button>
  )
}
