import { RegressionIcon } from 'components/flows/ra/runs/RegressionIcon'
import React from 'react'
import { Run } from 'components/regression-analysis-mvp/Run'

interface RunSummaryProps {
  run: Run
}

export const RunSummary = ({ run }: RunSummaryProps) => {
  return (
    <p className="flex items-center space-x-1">
      <RegressionIcon run={run} />
      <span>{run.dateCreated}</span>
      {run.dateFinished && (
        <>
          <span>—</span>
          <span>{run.dateFinished}</span>
        </>
      )}
    </p>
  )
}
