import React from 'react'
import { Link } from 'react-router-dom'
import classNames from 'classnames'

interface BreadcrumbsLinkProps {
  path: string
  name: string
  className?: string
}

export const BreadcrumbsLink = ({ path, name, className }: BreadcrumbsLinkProps) => (
  <Link
    to={path}
    className={classNames([
      'text-small text-gray-service hover:text-white cursor-pointer line-clamp-1',
      className,
    ])}
  >
    {name}
  </Link>
)
