import React, { useEffect, useMemo, useState } from 'react'
import NProgress from 'nprogress'
import { generatePath, Navigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { Layout } from 'components/Layout'
import { ButtonVariantEnum } from 'components/Button'
import { useTeamsQuery, useUserQuery } from 'hooks/useApiQuery'
import { Empty } from 'components/Empty'
import { PATH_TEAM } from 'pages/TeamPage'
import { FreeTrialStage, TeamsArray } from 'api/models'
import { psLocalStorage } from 'utils/localStorage/PsLocalStorage'

import { TeamFormModal } from 'components/teams/TeamFormModal'
import { PATH_FREE_TRIAL_QUESTIONNAIRE } from 'pages/FreeTrialQuestionnairePage'
import { PATH_GUIDE_ROUTER } from 'pages/guide/GuideRootPage'
import { FEATURE_FLAGS, useFeatureFlag } from 'utils/feature-flags'
import { PATH_WELCOME_PAGE } from 'pages/WelcomePage'
import { PATH_LITE_TRACES } from 'pages/lite/LiteTracesPage'
import { useIoSingleProjectRedirect } from 'components/guide-io/useIoSingleProjectRedirect'
import { BaseHeader } from 'components/header/BaseHeader'
import { PageTitle } from 'components/header/PageTitle'
import { useIsLiteUser } from 'components/lite/useIsLiteUser'

export const MainPage = () => {
  const { t } = useTranslation()

  const [addTeamModalOpened, setAddTeamModalOpened] = useState(false)
  const handleAddTeamClick = () => setAddTeamModalOpened(true)
  const handleAddTeamModalClose = () => setAddTeamModalOpened(false)

  const { isSuccess: isUserSuccess, data: user } = useUserQuery()
  const { isSuccess: isTeamsSuccess, data: teams } = useTeamsQuery()
  const { isLoaded: isIoSingleProjectLoaded, navigateComponent: navigateToIoGide } =
    useIoSingleProjectRedirect()

  const isSelfServeEnabled = useFeatureFlag(FEATURE_FLAGS.SELF_SERVE)
  const isInteractiveOnboardingEnabled = useFeatureFlag(FEATURE_FLAGS.INTERACTIVE_ONBOARDING)
  const isLiveDemoEnabled = useFeatureFlag(FEATURE_FLAGS.LIVE_DEMO)

  const isPsToolLite = useIsLiteUser()

  const hasTeams = teams && teams?.length > 0
  const freeTrialTeam = teams?.find((team) => team.freeTrial)
  const isOnFreeTrialGuides =
    isSelfServeEnabled &&
    teams?.length === 1 &&
    freeTrialTeam &&
    freeTrialTeam.freeTrial!.stage !== FreeTrialStage.FLOW_LIBRARY
  const needToFulfillQuestionnaire = isSelfServeEnabled && user?.askQuestionnaire

  const isLoaded =
    isTeamsSuccess &&
    isUserSuccess &&
    isSelfServeEnabled != null &&
    isIoSingleProjectLoaded &&
    isInteractiveOnboardingEnabled != null &&
    isPsToolLite != null

  useEffect(() => {
    if (isLoaded) {
      NProgress.done()
    } else {
      NProgress.start()
    }
  }, [isLoaded])

  const getFirstTeamUrlName = (getTeams: TeamsArray) => {
    let teamUrlNameStorage: string | null = null
    try {
      teamUrlNameStorage = psLocalStorage.getLastTeam() || null
    } catch {}
    const hasTeam = getTeams?.find((team) => team.urlName === teamUrlNameStorage)
    if (teamUrlNameStorage && !hasTeam) {
      try {
        psLocalStorage.removeLastTeam()
      } catch {}
    }
    return hasTeam ? teamUrlNameStorage : getTeams[0].urlName
  }

  const header = useMemo(() => <BaseHeader centerContent={<PageTitle titleKey="teams" />} />, [])

  return (
    <Layout headerComponent={header} pageConfig={{ withoutStyledContent: true }}>
      {isLoaded && (
        <main className="flex flex-1">
          {isPsToolLite ? (
            <Navigate to={PATH_LITE_TRACES} replace={true} />
          ) : needToFulfillQuestionnaire ? (
            <Navigate
              to={generatePath(
                isLiveDemoEnabled ? PATH_WELCOME_PAGE : PATH_FREE_TRIAL_QUESTIONNAIRE,
              )}
              replace={true}
            />
          ) : (
            navigateToIoGide ||
            (isOnFreeTrialGuides ? (
              <Navigate to={generatePath(PATH_GUIDE_ROUTER)} replace={true} />
            ) : hasTeams ? (
              <Navigate
                to={generatePath(PATH_TEAM, { teamUrlName: getFirstTeamUrlName(teams) })}
                replace={true}
              />
            ) : user?.roles.isSuperAdmin ? (
              <Empty
                title={t('teams.mainPage.createTeam.title')}
                text={t('teams.mainPage.createTeam.text')}
                buttons={[
                  {
                    name: t('teams.mainPage.createTeam.button'),
                    variant: ButtonVariantEnum.Outlined,
                    onClick: handleAddTeamClick,
                  },
                ]}
              />
            ) : (
              <Empty
                title={t('teams.mainPage.userWithoutTeams.title')}
                text={t('teams.mainPage.userWithoutTeams.text')}
              />
            ))
          )}
        </main>
      )}
      <TeamFormModal isOpen={addTeamModalOpened} onClose={handleAddTeamModalClose} />
    </Layout>
  )
}
