import React, { useCallback, useRef, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'

import { VideoPlayerStore } from 'components/ps-chart/stores/VideoPlayerStore'
import { useToaster } from 'hooks/useToaster'
import { Button, ButtonVariantEnum } from 'components/Button'
import { generatePath, Link, useParams } from 'react-router-dom'
import { PATH_CHART } from 'pages/PsChartPage'

export interface ChartVideoUploaderProps {
  videoPlayerStore: VideoPlayerStore
  isFullScreen: boolean
}

export const ChartVideoUploader = observer(function ChartVideoUploader(
  props: ChartVideoUploaderProps,
) {
  const { videoPlayerStore, isFullScreen } = props
  const [isDragOver, setDragOver] = useState(false)
  const lastDragEnterEl = useRef<EventTarget | null>(null)
  const toaster = useToaster()
  const { t } = useTranslation()

  const onVideoProcessingError = useCallback(
    (processingError: string | null) => {
      toaster.error(processingError, 'psChart.error.video.processingFiled')
    },
    [toaster],
  )
  const onVideoSuccessfullyUploaded = useCallback(() => {
    toaster.success('psChart.video.readyToast')
  }, [toaster])

  const uploadVideo = useCallback(
    (file: File) => {
      videoPlayerStore
        .uploadVideo(file, onVideoProcessingError, onVideoSuccessfullyUploaded)
        .catch((reason) => {
          if (!videoPlayerStore.isCancelled(reason)) {
            toaster.error(reason, 'psChart.error.video.cantUploadVideo')
          }
        })
    },
    [videoPlayerStore, onVideoProcessingError, onVideoSuccessfullyUploaded, toaster],
  )

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const [file] = event.target.files ?? []
    if (file != null) {
      uploadVideo(file)
    }
  }

  const onDragOver = (event: React.DragEvent<HTMLElement>) => {
    event.preventDefault()
    event.stopPropagation()
  }

  const onDragEnter = (event: React.DragEvent<HTMLElement>) => {
    event.preventDefault()
    event.stopPropagation()
    setDragOver(true)
    lastDragEnterEl.current = event.target
  }

  const onDragLeave = (event: React.DragEvent<HTMLElement>) => {
    event.preventDefault()
    event.stopPropagation()
    if (event.target === lastDragEnterEl.current) {
      setDragOver(false)
    }
  }

  const onDrop = (event: React.DragEvent<HTMLElement>) => {
    event.preventDefault()
    event.stopPropagation()
    const file = event.dataTransfer.files[0]
    if (file != null) {
      const isVideo = file.type.startsWith('video/')
      if (isVideo) {
        uploadVideo(file)
      } else {
        toaster.error(t('psChart.error.video.isNotVideo'), 'psChart.error.video.cantUseFile')
      }
    } else {
      toaster.error(t('psChart.error.video.wasNotFound'), 'psChart.error.video.cantUseFile')
    }
  }

  const { projectUrlName, flowProjectLocalId, traceProjectLocalId } = useParams() as {
    projectUrlName: string
    flowProjectLocalId: string
    traceProjectLocalId: string
  }

  if (isFullScreen) {
    const linkToTraceViewer = generatePath(PATH_CHART, {
      projectUrlName,
      flowProjectLocalId,
      traceProjectLocalId: String(traceProjectLocalId),
    })
    return (
      <div className="h-[100%] flex items-center">
        <div
          className="flex-grow"
          onDragOver={onDragOver}
          onDragLeave={onDragLeave}
          onDragEnter={onDragEnter}
          onDrop={onDrop}
        >
          <div className="flex flex-col text-center items-center h-[100%]">
            <span className="text-center text-gray-normal text-small">
              <p>
                {t('psChart.video.callToAction')} {t('psChart.video.callToAction2')}
              </p>
            </span>
            <div className="flex items-center justify-between w-[180px] pt-3 m-auto">
              <Link
                className="text-gray-normal text-small"
                to={linkToTraceViewer}
                aria-label={t('skip')}
              >
                {t('skip')}
              </Link>
              <label
                className="relative flex items-center border border-sky hover:cursor-pointer"
                style={{ borderStyle: isDragOver ? 'solid' : 'none' }}
              >
                <Button variant={ButtonVariantEnum.Outlined} isSmall className="">
                  {t('psChart.video.uploadVideo')}
                </Button>
                <input
                  type="file"
                  name="videoFile"
                  accept="video/mp4,video/quicktime"
                  className="zIndex-5 opacity-0 absolute top-0 left-0 bottom-0 right-0"
                  onChange={onChange}
                />
              </label>
            </div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div
      className="flex-grow relative"
      onDragOver={onDragOver}
      onDragLeave={onDragLeave}
      onDragEnter={onDragEnter}
      onDrop={onDrop}
    >
      <div className="text-center absolute top-[45%] left-[15%]">
        <span className="text-center text-gray-normal text-small">
          <p>{t('psChart.video.callToAction')}</p>
          <p>{t('psChart.video.callToAction2')}</p>
        </span>
        <label
          className="relative flex items-center justify-center cursor-pointer border border-sky pt-3"
          style={{ borderStyle: isDragOver ? 'solid' : 'none' }}
        >
          <Button variant={ButtonVariantEnum.Outlined} isSmall>
            {t('psChart.video.uploadVideo')}
          </Button>
          <input
            type="file"
            name="videoFile"
            accept="video/mp4,video/quicktime"
            className="zIndex-5 opacity-0 absolute top-0 left-0 bottom-0 right-0 cursor-pointer"
            onChange={onChange}
          />
        </label>
      </div>
    </div>
  )
})
