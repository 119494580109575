import { Api } from 'api/Api'
import { QueryClient } from 'react-query'
import { FirebaseAuth } from 'contexts/FirebaseAuth'
import { AuthStore } from 'contexts/AuthStore'
import { Okta } from 'contexts/Okta'
import { Analytics } from 'utils/analytics'
import axios, { Axios, AxiosRequestHeaders } from 'axios'
import { ExceptionHandler } from 'utils/ExceptionHandler'
import { getServerHost } from 'utils/getServerHost'
import { getServerProtocol } from 'utils/getServerProtocol'
import { ContentfulClientApi, createClient } from 'contentful'

export interface CompositionRoot {
  api: Api
  queryClient: QueryClient
  axios: Axios
  contentfulClient: ContentfulClientApi
  analytics: Analytics
  exceptionHandler: ExceptionHandler
  subscribeToAxiosError: (listener: (error: unknown) => void) => void
  firebaseAuth: FirebaseAuth
  oktaAuth: Okta
  authStore: AuthStore
}

export class CompositionRootImpl implements CompositionRoot {
  private _axios?: Axios

  private _api?: Api

  private _queryClient?: QueryClient
  private _contentfulClient?: ContentfulClientApi

  private _analytics?: Analytics

  private onAxiosError: (error: unknown) => void

  readonly exceptionHandler: ExceptionHandler
  readonly firebaseAuth: FirebaseAuth
  readonly oktaAuth: Okta
  readonly authStore: AuthStore

  constructor() {
    this.onAxiosError = () => {}
    this.exceptionHandler = new ExceptionHandler()
    this.authStore = new AuthStore()
    this.oktaAuth = new Okta()
    this.firebaseAuth = new FirebaseAuth(this.api)
  }

  subscribeToAxiosError = (listener: (error: unknown) => void) => {
    this.onAxiosError = listener
  }

  get axios(): Axios {
    if (!this._axios) {
      this._axios = axios.create({
        withCredentials: true,
      })
      this._axios.interceptors.response.use(
        (response) => response,
        (error) => {
          this.onAxiosError(error)
          return Promise.reject(error)
        },
      )
      this._axios.interceptors.request.use((config) => {
        if (
          this.authStore.isSignedIn &&
          this.oktaAuth.accessToken &&
          config.headers.Authorization !== null
        ) {
          config.headers = {
            ...config.headers,
            Authorization: `Bearer ${this.oktaAuth.accessToken}`,
          } as AxiosRequestHeaders
        }
        return config
      })
      this._axios.interceptors.request.use((config) => {
        if (config.url != null) {
          const url = new URL(config.url)
          url.pathname = url.pathname
            .split('/')
            .map((part) => encodeURIComponent(part))
            .join('/')
          config.url = url.toString()
        }
        return config
      })
    }
    return this._axios
  }

  get api(): Api {
    if (!this._api) {
      const apiHostWithScheme = `${getServerProtocol()}//${getServerHost()}`
      this._api = new Api(apiHostWithScheme, this.axios)
    }
    return this._api
  }

  get queryClient(): QueryClient {
    if (!this._queryClient) {
      this._queryClient = new QueryClient()
    }
    return this._queryClient
  }

  get contentfulClient() {
    if (!this._contentfulClient) {
      this._contentfulClient = createClient({
        space: process.env.REACT_APP_CONTENTFUL_SPACE_ID!,
        accessToken: process.env.REACT_APP_CONTENTFUL_TOKEN!,
      })
    }

    return this._contentfulClient
  }

  get analytics(): Analytics {
    if (!this._analytics) {
      this._analytics = new Analytics()
    }
    return this._analytics
  }
}
