import { Select } from 'components/Select'
import { useToaster } from 'hooks/useToaster'
import { useTranslation } from 'react-i18next'
import { ListItem } from 'components/traces/copyTrace/models'

export interface SelectFieldProps {
  isDisabled: boolean
  listItems: ListItem[]
  placeholder: string
  label: string
  isError: boolean
  value: string
  onChange: (value: string | string[]) => void
}

export const SelectField = ({
  isDisabled,
  listItems,
  placeholder,
  label,
  isError,
  value,
  onChange,
}: SelectFieldProps) => {
  const { t } = useTranslation()
  const toast = useToaster()
  if (isError) {
    toast.error(t('traces.copyTraceModal.errorLoading', label))
  }

  return (
    <fieldset disabled={isDisabled} className="disabled:text-dark-dark6">
      <label htmlFor={label}>
        <p className="font-medium text-[14px] mb-1 capitalize">{label}</p>
      </label>
      <span className="flex items-center">
        <Select
          className="mb-[15px] w-[100%]"
          buttonClassName="bg-gray-inputBG"
          options={listItems}
          placeholder={placeholder}
          disabled={isDisabled}
          onChange={onChange}
          value={value}
          optionsClassName="max-h-[250px] overflow-y-auto"
        />
      </span>
    </fieldset>
  )
}
