import { Slice } from 'components/ps-chart/models/Slice'
import { PsChartStore } from 'components/ps-chart/PsChartStore'
import { useTranslation } from 'react-i18next'
import { CardTitleBar } from 'components/ps-chart/details-view/CardTitleBar'
import { CardLine } from 'components/ps-chart/details-view/CardLine'
import { nanoToString } from 'components/ps-chart/utils/nanoToString'
import { ConnectIcon } from 'components/ps-chart/details-view/ConnectIcon'

interface SliceBoxProps {
  slice: Slice
  psChartStore: PsChartStore
  full?: boolean
  onConnect?: () => void
  onClick?: () => void
}

export const SliceBox = ({ slice, psChartStore, full, onConnect, onClick }: SliceBoxProps) => {
  const { t } = useTranslation()
  const thread = psChartStore.traceDataState.threadsById.get(slice.threadId)!

  return (
    <div className="px-[8px] pt-[10px] pb-[6px] bg-dark-dark1 relative mb-[8px] rounded-sm">
      <CardTitleBar color={slice.color} onClick={onClick} title={slice.title} />

      {full && (
        <div className="pt-[10px] pb-[6px]">
          {thread && <CardLine label={t('psChart.details.thread')}>{thread.title}</CardLine>}
          <CardLine label={t('psChart.details.sliceId')}>{slice.id}</CardLine>
          {slice.objectId !== -1 && (
            <CardLine label={t('psChart.details.objectId')}>{slice.objectId}</CardLine>
          )}
          <CardLine label={t('psChart.details.startTime')}>{nanoToString(slice.start)}</CardLine>
          <CardLine label={t('psChart.details.duration')}>
            {getSliceDurationString(slice.start, slice.end)}
          </CardLine>
          {slice.args.length > 0 && (
            <CardLine label={t('psChart.details.arguments')}>
              <>
                {slice.args.map((arg, index) => (
                  <div key={index}>{arg.value}</div>
                ))}
              </>
            </CardLine>
          )}
        </div>
      )}

      {onConnect && (
        <div className="absolute top-[2px] right-[6px]">
          <ConnectIcon
            isActive={false}
            isConnected={false}
            isDisabled={false}
            isInProgress={false}
            onClick={onConnect}
          />
        </div>
      )}
    </div>
  )
}

const getSliceDurationString = (start: number, end: number) => {
  return nanoToString(end - start)
}
