import { useEffect, useRef } from 'react'

import { PsChartStore } from 'components/ps-chart/PsChartStore'
import { WheelBlocker } from 'utils/WheelBlocker'

export const useScrollListener = (psChartStore: PsChartStore) => {
  const scrolledContainerRef = useRef<HTMLDivElement>(null)
  useEffect(() => {
    const scrolledEl = scrolledContainerRef.current
    if (scrolledEl == null) {
      throw new Error('The scrolledContainerRef is not set!')
    }

    const wheelBlockerX = new WheelBlocker('x')

    const onWheel = (event: WheelEvent) => {
      event.preventDefault()
      const deltaY = Math.round(event.deltaY)
      if (!psChartStore.isLoaded || deltaY === 0) {
        return null
      }
      if (!event.ctrlKey) {
        wheelBlockerX.handleEvent(event, () => {
          psChartStore.vState.scrollY(deltaY)
        })
      }
    }

    scrolledEl.addEventListener('wheel', onWheel)

    return () => {
      scrolledEl.removeEventListener('wheel', onWheel)
    }
  }, [psChartStore, scrolledContainerRef])

  return scrolledContainerRef
}
