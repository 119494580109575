export function arraysAreEqual(first: number[], second: number[]): boolean {
  if (first.length !== second.length) {
    return false
  }

  const sortedFirst = [...first].sort((a, b) => a - b)
  const sortedSecond = [...second].sort((a, b) => a - b)

  for (let i = 0; i < sortedFirst.length; i++) {
    if (sortedFirst[i] !== sortedSecond[i]) {
      return false
    }
  }
  return true
}
