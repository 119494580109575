export const asc = (arr: number[]) => arr.sort((a, b) => a - b)

export const getQuantile = (arr: number[], q: number): number => {
  if (arr.length === 0) {
    return 0
  }

  const sorted = asc(arr)
  const pos = (sorted.length - 1) * q
  const base = Math.floor(pos)
  const rest = pos - base
  if (sorted[base + 1] !== undefined) {
    return sorted[base] + rest * (sorted[base + 1] - sorted[base])
  } else {
    return sorted[base]
  }
}
