import React, { useEffect, useMemo } from 'react'
import styled from 'styled-components/macro'
import tw from 'twin.macro'
import { generatePath, useParams, Navigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import NProgress from 'nprogress'

import { Layout } from 'components/Layout'
import { PageHead } from 'components/PageHead'
import { Uploader } from 'components/traces/Uploader'
import { Table } from 'components/traces/Table'

import {
  useFlowQuery,
  useProjectQuery,
  useUnassignedTracesQuery,
  useUserQuery,
} from 'hooks/useApiQuery'
import { PATH_FLOW } from 'pages/FlowPage'
import { TeamRoleApi } from 'api/models'
import { BaseHeader } from 'components/header/BaseHeader'
import { PageTitle } from 'components/header/PageTitle'
import { Navbar } from 'components/Navbar'
import { FlowTracesPageBreadcrumbs } from 'components/breadcrumbs/FlowTracesPageBreadcrumbs'

export const PATH_TRACE_ADD = '/projects/:projectUrlName/flows/:flowProjectLocalId/traces/add'

export const TraceAddPage = () => {
  const { t } = useTranslation()

  const { projectUrlName, flowProjectLocalId } = useParams() as {
    projectUrlName: string
    flowProjectLocalId: string
  }

  const { isSuccess: isUserSuccess, data: user } = useUserQuery()
  const { isSuccess: isProjectSuccess, data: project } = useProjectQuery({ projectUrlName })
  const { isSuccess: isFlowSuccess, data: flowData } = useFlowQuery({
    projectUrlName,
    flowProjectLocalId,
  })
  const { isSuccess: isUnassignedTracesSuccess, data: unassignedTracesData } =
    useUnassignedTracesQuery({
      projectUrlName,
    })

  const isLoaded = isFlowSuccess && isUnassignedTracesSuccess && isUserSuccess && isProjectSuccess

  const teamRole = user?.roles.teams[project?.team.id as number]
  const projectRole = user?.roles.projects[project?.id as number]
  const canAddTrace =
    !user?.roles.isSuperAdmin || (teamRole && teamRole !== TeamRoleApi.NONE) || projectRole

  useEffect(() => {
    if (isLoaded) {
      NProgress.done()
    } else {
      NProgress.start()
    }
  }, [isLoaded])

  const header = useMemo(
    () => (
      <BaseHeader
        leftSideContent={<FlowTracesPageBreadcrumbs />}
        centerContent={<PageTitle titleKey="library" />}
      />
    ),
    [],
  )
  const footer = useMemo(() => <Navbar />, [])

  return (
    <Layout footerComponent={footer} headerComponent={header}>
      {isLoaded &&
        (!canAddTrace ? (
          <Navigate
            to={generatePath(PATH_FLOW, { projectUrlName, flowProjectLocalId })}
            replace={true}
          />
        ) : (
          <>
            <PageHead
              title={t('traceAdd.title')}
              secondaryTitle={flowData?.name as string}
              backLink={generatePath(PATH_FLOW, { projectUrlName, flowProjectLocalId })}
            />
            <SectionTitle>{t('traceAdd.uploadNewTraceTitle')}</SectionTitle>
            <Uploader />
            {isUnassignedTracesSuccess && unassignedTracesData && unassignedTracesData?.length > 0 && (
              <>
                <SectionTitle>{t('traceAdd.selectUnassignedTraceTitle')}</SectionTitle>
                <Table traces={unassignedTracesData} withSelect withAssign />
              </>
            )}
          </>
        ))}
    </Layout>
  )
}

const SectionTitle = styled.div`
  ${tw`text-header-small font-medium`}
  margin-top: 13px;
  margin-right: 16px;
  margin-bottom: 13px;
  margin-left: 109px;
  color: ${({ theme }) => theme.colors.gray.normal};
`
