import { useFlowsQuery } from 'hooks/useApiQuery'
import { useTranslation } from 'react-i18next'
import { SelectField } from 'components/traces/copyTrace/SelectField'
import { getFlowMenuOptions } from 'components/traces/copyTrace/logic'
import { FIELDS, MenuProps } from 'components/traces/copyTrace/models'
import { useCallback } from 'react'

export const FlowSelect = ({ formContext }: MenuProps) => {
  const { t } = useTranslation()
  const { getValues, setValue, watch } = formContext
  const project = watch(FIELDS.PROJECT)

  const { data, isError } = useFlowsQuery({ projectUrlName: project })
  const flows = getFlowMenuOptions(data)
  const isDisabled = !project || flows.length === 0 || !flows

  const flowFormValue = getValues(FIELDS.FLOW)
  const flowOnChange = useCallback(
    (value: string | string[]) => {
      setValue(FIELDS.FLOW, value as string)
    },
    [setValue],
  )

  return (
    <SelectField
      isDisabled={isDisabled}
      listItems={flows}
      placeholder={t('traces.copyTraceModal.selectFlow')}
      label={t('traces.copyTraceModal.flow')}
      isError={isError}
      value={flowFormValue}
      onChange={flowOnChange}
    />
  )
}
