import { useTranslation } from 'react-i18next'

import { twMerge } from 'tailwind-merge'

import { LiveDemo } from 'components/lite/LiveDemo'

import { SignInModal } from 'components/lite/SignInModal'
import { LiteLayout, LiteLayoutContainer } from 'components/lite/LiteLayout'
import { LiteHeader } from 'components/lite/LiteHeader'
import { LiteFooter } from 'components/lite/LiteFooter'

import { Link } from 'react-router-dom'

export const PATH_LITE_PROMO = !!process.env.REACT_APP_IS_LITE ? '/' : '/lite'

export const LitePromoPage = () => {
  const { t } = useTranslation()
  return (
    <LiteLayout header={<LiteHeader isRootPage />} footer={<LiteFooter />}>
      <LiteLayoutContainer>
        <div className="md:grid md:grid-cols-3 gap-5">
          <div className="z-20 relative">
            <h1
              className={twMerge(
                'tracking-[-.05em] font-semibold leading-tighter',
                'md:mt-16 text-[42px]',
                '1280:text-[52px]',
              )}
            >
              Mobile app profiler with synchronized UI&nbsp;video
            </h1>
            <div className="my-8">
              <SignInModal buttonLabel={t('lite.uploadTraceButton')} />
            </div>
            <p className="my-3 text-gray-faded md:max-w-xs">
              Optimize your mobile application for faster performance and enhanced responsiveness.
              Identify and address performance bottlenecks in your mobile app.
            </p>
            <p className="my-3 text-gray-faded md:max-w-xs">
              Only Android native apps are currently supported.
            </p>
            <p className="mb-5">
              <Link
                to="https://docs.productscience.app/integration/android/gradle/"
                className="text-electro"
                target="_blank"
                rel="noopener noreferrer"
              >
                View documentation
              </Link>
            </p>
          </div>
          <div className="mb-[80px] relative">
            <div className="absolute top-[268px] left-[230px] md:block hidden">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="133"
                height="336"
                viewBox="0 0 133 336"
                fill="none"
              >
                <path
                  d="M0.666667 330C0.666667 332.946 3.05448 335.333 6 335.333C8.94552 335.333 11.3333 332.946 11.3333 330C11.3333 327.054 8.94552 324.667 6 324.667C3.05448 324.667 0.666667 327.054 0.666667 330ZM132.707 8.70711C133.098 8.31658 133.098 7.68342 132.707 7.29289L126.343 0.928932C125.953 0.538408 125.319 0.538408 124.929 0.928932C124.538 1.31946 124.538 1.95262 124.929 2.34315L130.586 8L124.929 13.6569C124.538 14.0474 124.538 14.6805 124.929 15.0711C125.319 15.4616 125.953 15.4616 126.343 15.0711L132.707 8.70711ZM116.975 9H132V7H116.975V9ZM6 331H76.9747V329H6V331ZM97.9747 310V28H95.9747V310H97.9747ZM76.9747 331C88.5727 331 97.9747 321.598 97.9747 310H95.9747C95.9747 320.493 87.4681 329 76.9747 329V331ZM116.975 7C105.377 7 95.9747 16.402 95.9747 28H97.9747C97.9747 17.5066 106.481 9 116.975 9V7Z"
                  fill="#F2C94C"
                />
              </svg>
            </div>
            <img
              className={twMerge('rounded-2xl', 'md:ml-4 md:max-w-none md:h-[500px]')}
              src="https://images.ctfassets.net/tab8wfn9nvlu/4fGp7n8uDPyrpJhKwTenb5/ec4f5437a68e0ff567efbfe87c2bd163/crpath-ux.webp"
              height="500"
              alt="Profiler user interface"
            />
          </div>
          <div></div>
        </div>
        <div className="md:grid md:grid-cols-3 gap-5">
          <div className="mb-16 relative">
            <div className="absolute left-[230px] max-w-[90px] bg-white h-[30px] right-[30px] z-10 down-1280:max-w-[20px]">
              <div className="absolute right-0 w-[300px] bg-white h-[30px]"></div>
            </div>
            <div className="absolute top-[-317px] left-[230px] max-w-[90px] overflow-hidden lg:block hidden z-20 down-1280:max-w-[20px]">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="278"
                height="341"
                viewBox="0 0 278 341"
                fill="none"
              >
                <path
                  d="M0.666667 335C0.666667 337.946 3.05448 340.333 6 340.333C8.94552 340.333 11.3333 337.946 11.3333 335C11.3333 332.054 8.94552 329.667 6 329.667C3.05448 329.667 0.666667 332.054 0.666667 335ZM277.707 8.70711C278.098 8.31658 278.098 7.68342 277.707 7.29289L271.343 0.928932C270.953 0.538408 270.319 0.538408 269.929 0.928932C269.538 1.31946 269.538 1.95262 269.929 2.34315L275.586 8L269.929 13.6569C269.538 14.0474 269.538 14.6805 269.929 15.0711C270.319 15.4616 270.953 15.4616 271.343 15.0711L277.707 8.70711ZM201.668 9H277V7H201.668V9ZM6 336H161.668V334H6V336ZM182.668 315V28H180.668V315H182.668ZM161.668 336C173.266 336 182.668 326.598 182.668 315H180.668C180.668 325.493 172.161 334 161.668 334V336ZM201.668 7C190.07 7 180.668 16.402 180.668 28H182.668C182.668 17.5066 191.174 9 201.668 9V7Z"
                  fill="#F2219F"
                />
              </svg>
            </div>
            <div className="absolute top-[-317px] right-[-70px] w-[100%] lg:flex hidden h-[336px] overflow-hidden">
              <div className="absolute right-0">
                <svg
                  width="1346"
                  height="336"
                  viewBox="0 0 1346 336"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1345.71 8.70711C1346.1 8.31658 1346.1 7.68342 1345.71 7.29289L1339.34 0.928932C1338.95 0.538408 1338.32 0.538408 1337.93 0.928932C1337.54 1.31946 1337.54 1.95262 1337.93 2.34315L1343.59 8L1337.93 13.6569C1337.54 14.0474 1337.54 14.6805 1337.93 15.0711C1338.32 15.4616 1338.95 15.4616 1339.34 15.0711L1345.71 8.70711ZM1269.67 9H1345V7H1269.67V9ZM-1777 336H1229.67V334H-1777V336ZM1250.67 315V28H1248.67V315H1250.67ZM1229.67 336C1241.27 336 1250.67 326.598 1250.67 315H1248.67C1248.67 325.493 1240.16 334 1229.67 334V336ZM1269.67 7C1258.07 7 1248.67 16.402 1248.67 28H1250.67C1250.67 17.5066 1259.17 9 1269.67 9V7Z"
                    fill="#F2219F"
                  />
                </svg>
              </div>
            </div>
            <h2 className="text-header-big font-semibold mb-4 z-20 relative">Video Preview</h2>
            <p className="text-header-small">
              Context is key! Understand what’s happening in your mobile app and precisely when it
              occurs. With CriticalPath, you can record a trace file synchronized with a screen
              recording of your app’s UI, eliminating the need for tedious searches to pinpoint
              issues.
            </p>
          </div>
          <div className="mb-10 relative gap-3">
            <h2 className="text-header-big font-semibold mb-4">Critical Path</h2>
            <p className="text-header-small">
              The critical path highlights the dependencies between functions to identify
              performance bottlenecks in multi-threaded environments. It allows you to see the order
              of execution of functions as a result of a user action and identify what is slowing
              down the response.
            </p>
          </div>
          <div className="mb-10 relative gap-3">
            <h2 className="text-header-big font-semibold mb-4">Automatic Instrumentation</h2>
            <p className="text-header-small">
              You don’t need to manually instrument your code. Our plugin will take care of the hard
              work for you. Instrumentation has minimal impact on performance. Our intelligent
              filtering will eliminate non-essential data, enabling you to pinpoint critical
              performance insights.
            </p>
          </div>
        </div>
      </LiteLayoutContainer>
      <LiteLayoutContainer>
        <h1 className="text-center tracking-[-.05em] font-semibold leading-tighter text-[52px] mb-16">
          Live demo
          <br />↓
        </h1>
      </LiteLayoutContainer>
      <div className="w-full bg-black py-10 md:h-screen ">
        <LiteLayoutContainer className="text-white hidden md:block">
          <div className="grid grid-cols-3 gap-5">
            <div></div>
            <div>
              Click on a slice to view the critical path. Each slice represents a function call; the
              longer the slice, the more time it took to execute this function. The critical path
              connects these slices in the order they were executed.
            </div>
            <div></div>
          </div>
        </LiteLayoutContainer>
        <div className="m-3 hidden md:block">
          <LiveDemo />
        </div>
        <LiteLayoutContainer className="text-white hidden md:block">
          <div className="grid grid-cols-3 gap-5 mt-5">
            <div>
              Click the play button to see exactly what the user sees in the app and to view which
              functions are called at any given moment.
            </div>
            <div></div>
            <div className="flex">
              <div className="flex flex-col items-center gap-1 font-semibold pr-5">
                <div className="flex justify-center">
                  <div className="w-8 h-8 bg-gray-strokeLight flex items-center justify-center rounded">
                    W
                  </div>
                </div>
                <div className="flex gap-1">
                  <div className="w-8 h-8 bg-gray-strokeLight flex items-center justify-center rounded">
                    A
                  </div>
                  <div className="w-8 h-8 bg-gray-strokeLight flex items-center justify-center rounded">
                    S
                  </div>
                  <div className="w-8 h-8 bg-gray-strokeLight flex items-center justify-center rounded">
                    D
                  </div>
                </div>
              </div>
              <div>
                Use your keyboard to navigate the trace viewer. Press W and S to zoom in and out,
                respectively. Use A and D to move left and right.
              </div>
            </div>
          </div>
        </LiteLayoutContainer>
        <LiteLayoutContainer className="md:hidden block text-white">
          <p>Please open this page in your desktop browser to view the live demo.</p>
        </LiteLayoutContainer>
      </div>
      <div className="w-full bg-black py-5 text-white">
        <LiteLayoutContainer>
          <p className="mt-3 mb-5 text-center">Ready to test it with your app?</p>
          <div className="flex items-center justify-center mb-10">
            <SignInModal
              buttonLabel={t('lite.uploadTraceButton')}
              buttonClassName="bg-white text-black"
            />
          </div>
        </LiteLayoutContainer>
      </div>
      <LiteLayoutContainer className="pt-10 pb-10">
        <div className="md:grid md:grid-cols-3 gap-5">
          <div className="relative gap-3">
            <h2 className="text-header-big font-semibold mb-4 z-20 relative">
              Instrumenting Your App
            </h2>
            <div className="w-full pt-[56.25%] box-border relative">
              <iframe
                className="w-full h-full absolute top-0"
                width="560"
                height="315"
                src="https://www.youtube.com/embed/I26jyFE4ggc?si=lcbCJwc7iV6fKhzp"
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
              ></iframe>
            </div>
          </div>
          <div className="relative gap-3">
            <h2 className="text-header-big font-semibold mb-4 z-20 relative">Recording a Trace</h2>
            <div className="w-full pt-[56.25%] box-border relative">
              <iframe
                className="w-full h-full absolute top-0"
                width="560"
                height="315"
                src="https://www.youtube.com/embed/q_uYS3xy7_A?si=cRam4wFSqkxl5_tA"
                title="CriticalPath Quickstart Guide: Recording a Trace"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
              ></iframe>
            </div>
          </div>
          <div className="relative gap-3">
            <h2 className="text-header-big font-semibold mb-4 z-20 relative">Features Overview</h2>
            <div className="w-full pt-[56.25%] box-border relative">
              <iframe
                className="w-full h-full absolute top-0"
                width="560"
                height="315"
                src="https://www.youtube.com/embed/g8drNsHLJ4A?si=PHvv1dBrv2WPAQcq"
                title="CriticalPath Quickstart Guide: Features Overview"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
              ></iframe>
            </div>
          </div>
        </div>
        <div className="md:grid md:grid-cols-3 gap-5 mt-10">
          <div className="relative gap-3">
            <h2 className="text-header-big font-semibold mb-4 z-20 relative">Execution Path</h2>
            <div className="w-full pt-[56.25%] box-border relative">
              <iframe
                className="w-full h-full absolute top-0"
                width="560"
                height="315"
                src="https://www.youtube.com/embed/HTbZBrZty2s?si=gpYScpnN59DigYHc"
                title="Execution Path"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
              ></iframe>
            </div>
          </div>
          <div className="relative gap-3">
            <h2 className="text-header-big font-semibold mb-4 z-20 relative">
              Video Synchronization
            </h2>
            <div className="w-full pt-[56.25%] box-border relative">
              <iframe
                className="w-full h-full absolute top-0"
                width="560"
                height="315"
                src="https://www.youtube.com/embed/mGnF4WS7hqE?si=g6f0KnjNxFATElDZ"
                title="Video Synchronization"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
              ></iframe>
            </div>
          </div>
          <div className="relative gap-3">
            <h2 className="text-header-big font-semibold mb-4 z-20 relative">
              Tips For Finding Insights
            </h2>
            <div className="w-full pt-[56.25%] box-border relative">
              <iframe
                className="w-full h-full absolute top-0"
                width="560"
                height="315"
                src="https://www.youtube.com/embed/wcGpvEXMCVg?si=fTUINloGYrm-pirb"
                title="Tips For Finding Insights"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
              ></iframe>
            </div>
          </div>
        </div>
      </LiteLayoutContainer>
      <LiteLayoutContainer className="pt-10 pb-14">
        <h2 className="text-header-big font-semibold mb-10">Comparison With Other Tools</h2>
        <div className="overflow-y-scroll">
          <table className="lg:text-header-small text-normal min-w-[600px]">
            <thead>
              <tr className="text-left">
                <th></th>
                <th className="px-4 py-2 text-center">CriticalPath</th>
                <th className="px-4 py-2 text-center">Android Studio Profiler</th>
                <th className="px-4 py-2 text-center">Perfetto</th>
              </tr>
            </thead>
            <tbody>
              <tr className="border-t">
                <td className="py-2 font-semibold">Video-trace synchronization</td>
                <td className="text-center">✅</td>
                <td className="text-center">❌</td>
                <td className="text-center">❌</td>
              </tr>
              <tr className="border-t">
                <td className="py-2 font-semibold">Automatic instrumentation</td>
                <td className="text-center">✅</td>
                <td className="text-center">✅</td>
                <td className="text-center">❌</td>
              </tr>
              <tr className="border-t">
                <td className="py-2 font-semibold">Smart trace filtering</td>
                <td className="text-center">✅</td>
                <td className="text-center">❌</td>
                <td className="text-center">❌</td>
              </tr>
              <tr className="border-t">
                <td className="py-2 font-semibold">Execution path</td>
                <td className="text-center">✅</td>
                <td className="text-center">❌</td>
                <td className="text-center">❌</td>
              </tr>
              <tr className="border-t">
                <td className="py-2 font-semibold">Learning curve</td>
                <td className="text-center py-2">
                  Ready for entry-level familiarity with code and instrumentation
                </td>
                <td className="text-center py-2">
                  Requires expert-level coding experience and extensive familiarity with the tool
                </td>
                <td className="text-center py-2">
                  Requires mid-level coding experience, but requires instrumentation expertise
                </td>
              </tr>
              <tr className="border-t">
                <td className="py-2 font-semibold">Instrumentation overhead</td>
                <td className="text-center py-2">Minimal</td>
                <td className="text-center py-2">Intensive</td>
                <td className="text-center py-2">Varies</td>
              </tr>
              <tr className="border-t">
                <td className="py-2 font-semibold">Instrumentation results</td>
                <td className="text-center py-2">Smart filters</td>
                <td className="text-center py-2">Overwhelm</td>
                <td className="text-center py-2">Underwhelm</td>
              </tr>
            </tbody>
          </table>
        </div>
      </LiteLayoutContainer>
    </LiteLayout>
  )
}
