import { useTranslation } from 'react-i18next'
import { SettingsField } from 'components/flows/ra/settings/SettingsField'
import React from 'react'
import { FlowAutomationConfigDtoScriptDto } from 'api/__generated__/api-types'
import { extractFileName } from 'utils/stringUtils'

interface AutomationScriptProps {
  script: FlowAutomationConfigDtoScriptDto | null | undefined
}

export const AutomationScript = ({ script }: AutomationScriptProps) => {
  const { t } = useTranslation()

  if (!script) {
    return <p className="text-white">{t('ra.flow.settings.notSet')}</p>
  }

  const notSet = t('ra.flow.settings.notSet')
  return (
    <>
      <SettingsField label={t('ra.flow.settings.automationScript.file.label')} inline>
        {extractFileName(script?.build?.name || notSet)}
      </SettingsField>
      <SettingsField label={t('ra.flow.settings.automationScript.appId.label')} inline>
        {script.appId || notSet}
      </SettingsField>
      <SettingsField label={t('ra.flow.settings.automationScript.class.label')} inline>
        {script.instrumentOptionsClass || notSet}
      </SettingsField>
    </>
  )
}
