import { NamedLinkDto, NamedLinkType } from 'api/models'
import { OBJECT_ID_EMPTY_VALUE, Slice } from 'components/ps-chart/models/Slice'
import { SliceLink } from 'components/ps-chart/models/SliceLink'

const getNamedLinkType = (fromSlice: Slice, toSlice: Slice) => {
  if (fromSlice.threadId === toSlice.threadId && fromSlice.level === toSlice.level) {
    return NamedLinkType.DIRECT
  } else if (
    fromSlice.objectId !== OBJECT_ID_EMPTY_VALUE &&
    fromSlice.objectId === toSlice.objectId
  ) {
    return NamedLinkType.OBJECT
  }
  return NamedLinkType.SYNC
}

const TMP_ID_MARK = '#tmp#'
let newNamedLinkIdCounter = 0

export const createNamedLink = (
  fromSlice: Slice,
  toSlice: Slice,
  isEditable: boolean,
): NamedLinkDto => ({
  id: `${TMP_ID_MARK}${newNamedLinkIdCounter++}`,
  fromName: fromSlice.title,
  toName: toSlice.title,
  type: getNamedLinkType(fromSlice, toSlice),
  isEditable,
})

export const removeLinksBySourceId = (
  sliceLinksBySliceId: Map<number, ReadonlyArray<SliceLink>>,
  sourceId: string,
): Map<number, SliceLink[]> => {
  const removedLinks = new Map<number, SliceLink[]>()
  sliceLinksBySliceId.forEach((links, fromSliceId) => {
    const prevLinks = links.filter((link) => link.sourceId !== sourceId)
    const linksToRemove = links.filter((link) => link.sourceId === sourceId)
    if (prevLinks.length !== links.length) {
      sliceLinksBySliceId.set(fromSliceId, prevLinks)
      removedLinks.set(fromSliceId, linksToRemove)
    }
  })
  return removedLinks
}

export const isLinkIdTemp = (linkId: string) => linkId?.indexOf(TMP_ID_MARK) === 0
