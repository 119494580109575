import { colors } from 'utils/styles/colors'

import { TextMeasurer } from 'components/ps-chart/flame-chart/TextMeasurer'
import {
  ConnectionCoordinates,
  PathSteps,
} from 'components/ps-chart/connections-render/ConnectionCurves'
import { BasicRendererSettings } from '../models/settings'

export interface RenderTextParams {
  text: string
  x: number
  y: number
  width: number
  textMeasurer: TextMeasurer
  active: boolean
  isUtility: boolean
}

/**
 * Used custom icon hex codes
 **/
export enum RenderLabelTypes {
  NETWORK_REQUEST = 'E857',
}

export interface RenderLabelParams {
  x: number
  y: number
  width: number
  type: RenderLabelTypes
}

export class BasicRenderer {
  private readonly context: CanvasRenderingContext2D

  private readonly settings: BasicRendererSettings

  constructor(context: CanvasRenderingContext2D, settings: BasicRendererSettings) {
    this.context = context
    this.settings = settings
  }

  fillBackground(x: number, y: number, width: number, height: number) {
    this.context.fillStyle = colors.dark.default
    this.context.fillRect(x, y, width, height)
  }

  fillPath(path: Path2D, fillStyle: string) {
    this.context.fillStyle = fillStyle
    this.context.fill(path)
  }

  fillRect(left: number, top: number, width: number, height: number, fillStyle: string) {
    this.context.fillStyle = fillStyle
    this.context.fillRect(left, top, width, height)
  }

  strokePath(path: Path2D, strokeStyle: string, lineWidth: number, dash?: number[]) {
    if (dash != null) {
      this.context.setLineDash(dash)
    }
    this.context.strokeStyle = strokeStyle
    this.context.fillStyle = strokeStyle
    this.context.lineWidth = lineWidth
    this.context.stroke(path)
    if (dash != null) {
      this.context.setLineDash([])
    }
  }

  strokePathSteps(path: PathSteps, strokeStyle: string, lineWidth: number, dash?: number[]) {
    if (dash != null) {
      this.context.setLineDash(dash)
    }
    this.context.strokeStyle = strokeStyle
    this.context.fillStyle = strokeStyle
    this.context.lineWidth = lineWidth
    this.context.beginPath()
    for (const connection of path) {
      this.strokeConnection(connection)
    }
    this.context.stroke()
    if (dash != null) {
      this.context.setLineDash([])
    }
  }

  strokeConnection(connection: ConnectionCoordinates) {
    for (let index = 0; index < connection.length; index++) {
      const x = Math.floor(connection[index][0])
      const y = Math.floor(connection[index][1])
      if (index === 0) {
        this.context.moveTo(x, y)
      } else {
        this.context.lineTo(x, y)
      }
    }
  }

  setSliceTextDrawingSettings(color: string, fontStyle: string) {
    this.context.font = fontStyle
    this.context.fillStyle = color
    this.context.textBaseline = 'middle'
    this.context.textAlign = 'center'
  }

  drawEllipsizedText({ text, x, y, width, textMeasurer }: RenderTextParams) {
    const maxWidth = width - this.settings.blockPaddingX * 2
    const textToDraw = textMeasurer.getEllipsizedText(text, maxWidth)
    if (textToDraw.length > 0) {
      const xCenter = x + Math.round(width / 2)
      const yMiddleBaseline = y + Math.round(this.settings.fontSize / 10)
      this.context.fillText(textToDraw, xCenter, yMiddleBaseline)
    }
  }

  drawLabel({ x, y, width, type }: RenderLabelParams, bgColor: string, labelColor: string) {
    const { labelSideLength } = this.settings
    const xCenter = x + Math.round(labelSideLength / 2)
    const yMiddleBaseline = y + Math.round(labelSideLength / 2)
    this.context.fillStyle = bgColor
    const labelWidth = Math.min(width, labelSideLength)
    this.fillRect(x, y, labelWidth, labelSideLength, bgColor)
    if (width >= labelSideLength) {
      this.context.fillStyle = labelColor
      const stringFromHex = String.fromCharCode(parseInt(type, 16))
      this.context.fillText(stringFromHex, xCenter, yMiddleBaseline)
    }
  }
}
