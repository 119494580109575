import React, { DragEvent, useRef, useState } from 'react'
import classNames from 'classnames'
import { Icon } from 'components/Icon'
import { Trans } from 'react-i18next'

// todo: consider using src/components/FileField.tsx
export function FileField({
  disabled = false,
  onUpload,
  acceptType = 'application/*',
}: {
  disabled?: boolean
  onUpload: (file: File) => void
  acceptType?: string
}) {
  const [drag, setDrag] = useState(false)
  const dragCounterRef = useRef<number>(0)

  const handleDragEnter = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault()
    event.stopPropagation()
    dragCounterRef.current++
    if (event.dataTransfer.items.length > 0) {
      setDrag(true)
    }
  }

  const handleDragLeave = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault()
    event.stopPropagation()
    dragCounterRef.current--
    if (dragCounterRef.current === 0) {
      setDrag(false)
    }
  }

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault()
    event.stopPropagation()
  }

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault()
    event.stopPropagation()
    const droppedFile = event.dataTransfer.files[0]
    if (droppedFile && droppedFile.type === acceptType) {
      onUpload(droppedFile)
    }
    setDrag(false)
    dragCounterRef.current = 0
    event.dataTransfer.clearData()
  }

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = (event.target.files ?? [])[0]
    if (file) {
      onUpload(file)
    }
  }

  return (
    <div className="flex">
      <div
        onDragOver={handleDragOver}
        onDragEnter={handleDragEnter}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
        className={classNames(
          'w-full bg-dark-dark1 h-[32px] flex items-center justify-center rounded-sm border-dashed border',
          drag ? 'border-electro bg-dark-default' : 'border-gray-faded',
        )}
      >
        <div className="flex items-center justify-center pl-1 pr-2">
          <Icon className="text-icon text-gray-normal mr-1" icon="upload" />
          <div className="text-mini tracking-wide text-gray-normal">
            <Trans
              i18nKey="uploader.dropHereOr"
              components={{
                choose: (
                  <label className="cursor-pointer transition-colors text-electro hover:text-sky" />
                ),
                input: (
                  <input
                    type="file"
                    accept={acceptType}
                    hidden={true}
                    onChange={onChange}
                    disabled={disabled}
                  />
                ),
              }}
            />
          </div>
        </div>
      </div>
    </div>
  )
}
