import React, { HTMLInputTypeAttribute } from 'react'
import { Input } from 'components/Input'
import classNames from 'classnames'

type ValueTypes = string | number

interface SettingsInputProps<Value extends string | number> {
  label: string
  value: Value
  onChange: (value: Value) => void
  type?: HTMLInputTypeAttribute | undefined
  disabled?: boolean
}

export const SettingsInput = <Type extends ValueTypes>({
  label,
  value,
  onChange,
  type,
  disabled,
}: SettingsInputProps<Type>) => {
  return (
    <div>
      <p className="mb-1 text-small text-gray-normal">{label}</p>
      <Input
        value={value}
        onChange={(event) => {
          if (typeof value === 'number') {
            onChange(Number(event.target.value) as Type)
          } else {
            onChange(event.target.value as Type)
          }
        }}
        type={type}
        disabled={disabled}
        className={classNames(
          type == 'number' &&
            '[appearance:textfield] ' +
              '[&::-webkit-outer-spin-button]:appearance-none ' +
              '[&::-webkit-inner-spin-button]:appearance-none', // Remove the up and down arrows from number input
        )}
      />
    </div>
  )
}
