import React, { useCallback, useRef, useState } from 'react'
import { useDisablePinchZoom } from 'hooks/useDisablePinchZoom'
import { Icon } from 'components/Icon'
import { VideoTab } from 'components/ps-chart/details-view/video/VideoTab'
import { PsChartStore } from 'components/ps-chart/PsChartStore'
import { useTranslation } from 'react-i18next'
import { DevMonitor } from 'components/ps-chart/dev-monitor/DevMonitor'
import { ActionTooltip } from 'components/ActionTooltip'
import { useHotKeys } from 'components/ps-chart/hooks/useHotKeys'
import { observer } from 'mobx-react-lite'
import { ELEMENTS_IDS } from 'components/ps-chart/elementsIds'

export const VideoLeftView = observer(function VideoLeftView({
  psChartStore,
}: {
  psChartStore: PsChartStore
}) {
  const { uiState } = psChartStore

  const [showContent, setShowContent] = useState(true)

  const viewRef = useRef<HTMLDivElement>(null)
  useDisablePinchZoom(viewRef)
  const { t } = useTranslation()

  const onCollapse = useCallback(() => {
    uiState.setLeftCollapsed(true)
    setShowContent(false)
  }, [uiState])

  const onExpand = useCallback(() => {
    uiState.setLeftCollapsed(false)
  }, [uiState])

  useHotKeys(
    ['BracketLeft'],
    () => {
      if (uiState.isLeftCollapsed) {
        onExpand()
      } else {
        onCollapse()
      }
    },
    psChartStore.isEnabledListeners,
  )

  return (
    <>
      {uiState.isLeftCollapsed ? (
        <div
          id={ELEMENTS_IDS.CHART_VIDEO_VIEW}
          ref={viewRef}
          className="w-[32px] bg-dark-dark3 transition-all cursor-pointer flex justify-center"
          onClick={onExpand}
        >
          <ActionTooltip tooltipId="showLeftPanel" place="right">
            <div className="flex items-center">
              <Icon icon="arrow-drop-r" className="text-gray-normal text-icon" />
            </div>
          </ActionTooltip>
        </div>
      ) : (
        <div
          id={ELEMENTS_IDS.CHART_VIDEO_VIEW}
          ref={viewRef}
          className="w-[320px] bg-dark-dark3 transition-all flex flex-col relative"
          onTransitionEnd={() => setShowContent(true)}
        >
          {showContent && (
            <>
              {!uiState.isRightHidden && (
                <>
                  <ActionTooltip tooltipId="hideLeftPanel" place="bottom" offsetFromTarget={8}>
                    <div
                      className="w-[32px] h-[32px] absolute right-0 mt-[4px] mr-[4px] cursor-pointer"
                      onClick={onCollapse}
                    >
                      <Icon icon="arrow-drop-l" className="text-gray-normal text-icon" />
                    </div>
                  </ActionTooltip>
                  <div className={`pl-[24px] pr-[24px] pt-[10px] pb-[11px] text-small font-normal`}>
                    {t('psChart.videoLeftView.title')}
                  </div>
                  <div className="bg-dark-dark2 relative">
                    <DevMonitor psChartStore={psChartStore} />
                  </div>
                </>
              )}
              <VideoTab
                videoStore={psChartStore.videoPlayerStore}
                isFullScreen={uiState.isRightHidden}
                videoContainerClassName="[&>video]:h-panelVideo"
              />
            </>
          )}
        </div>
      )}
    </>
  )
})
