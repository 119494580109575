import { useCallback, useMemo, useState } from 'react'
import { generatePath, useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-hot-toast'
import classNames from 'classnames'
import { useMutation, useQueryClient } from 'react-query'
import NProgress from 'nprogress'
import { useTranslation } from 'react-i18next'
import { AxiosError } from 'axios'

import { ButtonIcon, ButtonTextColorVariantEnum } from 'components/Button'
import {
  ApiError,
  AuthenticatedUserDto,
  ProjectRoleApi,
  ProjectUserDto,
  ProjectUsersResponse,
  TeamRoleApi,
  TeamRoleApiValue,
  ProjectRoleApiValue,
} from 'api/models'
import { getTeamRoleName } from 'utils/getTeamRoleName'
import { getProjectRoleName } from 'utils/getProjectRoleName'
import { queryKeys } from 'hooks/useApiQuery'
import { ActionModal } from 'components/ActionModal'
import { PATH_TEAM } from 'pages/TeamPage'
import { useApi } from 'contexts/di-context'
import { ListBox } from 'components/dropdowns/ListBox'

interface ProjectUserRoleProps extends Omit<ProjectUserDto, 'lastActive'> {
  isSuperAdmin?: boolean
  isSuperOrTeamOrProjectAdmin: boolean
  authUserId?: AuthenticatedUserDto['id']
  teamUrlName: string
  isLastTeamOrProjectAdmin: boolean
}

enum WarningPermissionModalType {
  TEAM,
  PROJECT,
}

export const ProjectUserRole = ({
  id,
  email,
  user,
  teamRole,
  projectRole,
  isSuperAdmin,
  isSuperOrTeamOrProjectAdmin,
  authUserId,
  teamUrlName,
  isLastTeamOrProjectAdmin,
}: ProjectUserRoleProps) => {
  const { t } = useTranslation()
  const api = useApi()
  const queryClient = useQueryClient()
  const { projectUrlName } = useParams() as { projectUrlName: string }
  const navigate = useNavigate()

  const [warningPermissionModal, setWarningPermissionModal] = useState<{
    isOpen: boolean
    type?: WarningPermissionModalType
    nextRole?: TeamRoleApiValue | ProjectRoleApiValue
  }>({
    isOpen: false,
  })
  const handleWarningPermissionModalClose = () => setWarningPermissionModal({ isOpen: false })

  const putProjectUserRoleMutation = useMutation(
    ({ role }: { role: ProjectRoleApiValue }) => {
      NProgress.start()
      return api.putProjectUserRole({ projectUrlName, userId: id }, { role })
    },
    {
      onSuccess: (_, variables) => {
        NProgress.done()
        queryClient.setQueryData<ProjectUsersResponse | undefined>(
          queryKeys.projectUsers({ projectUrlName }),
          (oldData) => {
            if (oldData) {
              return {
                ...oldData,
                users: oldData.users.map((item) =>
                  item.id === id ? { ...item, projectRole: variables.role } : item,
                ),
              }
            }
          },
        )
        if (id === authUserId && variables.role !== ProjectRoleApi.ADMIN && !isSuperAdmin) {
          navigate(generatePath(PATH_TEAM, { teamUrlName }), { replace: true })
        }
        toast.success(
          t('projects.projectUser.roleChanged', {
            user: user ? `${user.name} ${user.lastName}` : email,
          }),
        )
      },
      onError: (err: AxiosError<ApiError>) => {
        NProgress.done()
        toast.error(err.response?.data.message ?? t('errorMessage'))
      },
    },
  )

  const handleRoleOptionClick = useCallback(
    (role: ProjectRoleApiValue) => () => {
      if (id === authUserId && role === ProjectRoleApi.CONTRIBUTOR) {
        setWarningPermissionModal({
          isOpen: true,
          type: WarningPermissionModalType.PROJECT,
          nextRole: ProjectRoleApi.CONTRIBUTOR,
        })
        return
      }
      putProjectUserRoleMutation.mutate({ role })
    },
    [putProjectUserRoleMutation, id, authUserId],
  )

  const teamContributorMutation = useMutation(
    () => {
      NProgress.start()
      return api.deleteProjectUser({ projectUrlName, userId: id })
    },
    {
      onSuccess: () => {
        NProgress.done()
        queryClient.setQueryData<ProjectUsersResponse | undefined>(
          queryKeys.projectUsers({ projectUrlName }),
          (oldData) => {
            if (oldData) {
              return {
                ...oldData,
                users: oldData.users.map((item) =>
                  item.id === id ? { ...item, projectRole: null } : item,
                ),
              }
            }
          },
        )
        if (id === authUserId && !isSuperAdmin) {
          navigate(generatePath(PATH_TEAM, { teamUrlName }), { replace: true })
        }
        toast.success(
          t('projects.projectUser.roleChanged', {
            user: user ? `${user.name} ${user.lastName}` : email,
          }),
        )
      },
      onError: (err: AxiosError<ApiError>) => {
        NProgress.done()
        toast.error(err.response?.data.message ?? t('errorMessage'))
      },
    },
  )

  const handleTeamContributorClick = useCallback(() => {
    if (id === authUserId) {
      setWarningPermissionModal({
        isOpen: true,
        type: WarningPermissionModalType.TEAM,
        nextRole: TeamRoleApi.CONTRIBUTOR,
      })
      return
    }
    teamContributorMutation.mutate()
  }, [teamContributorMutation, id, authUserId])

  const handleWarningPermissionModalActionClick = () => {
    if (
      warningPermissionModal.type === WarningPermissionModalType.TEAM &&
      warningPermissionModal.nextRole === TeamRoleApi.CONTRIBUTOR
    ) {
      teamContributorMutation.mutate()
    }
    if (
      warningPermissionModal.type === WarningPermissionModalType.PROJECT &&
      warningPermissionModal.nextRole === ProjectRoleApi.CONTRIBUTOR
    ) {
      putProjectUserRoleMutation.mutate({ role: ProjectRoleApi.CONTRIBUTOR })
    }
  }

  const getUserRoleOptions = useMemo(() => {
    const options = [
      {
        name: getProjectRoleName(ProjectRoleApi.ADMIN, t),
        isSelect: projectRole === ProjectRoleApi.ADMIN,
        onClick: handleRoleOptionClick(ProjectRoleApi.ADMIN),
      },
    ]
    if (teamRole === TeamRoleApi.CONTRIBUTOR) {
      options.push({
        name: getTeamRoleName(TeamRoleApi.CONTRIBUTOR, t),
        isSelect: teamRole === TeamRoleApi.CONTRIBUTOR && !projectRole,
        onClick: handleTeamContributorClick,
      })
    } else {
      options.push({
        name: getProjectRoleName(ProjectRoleApi.CONTRIBUTOR, t),
        isSelect: projectRole === ProjectRoleApi.CONTRIBUTOR,
        onClick: handleRoleOptionClick(ProjectRoleApi.CONTRIBUTOR),
      })
    }
    return options
  }, [projectRole, handleRoleOptionClick, t, teamRole, handleTeamContributorClick])

  return (
    <>
      <div className="group ml-[-8px] relative inline-block">
        <ListBox
          menuSections={[{ options: getUserRoleOptions }]}
          disabled={Boolean(teamRole === TeamRoleApi.ADMIN) || !isSuperOrTeamOrProjectAdmin}
          onSelect={(idx) => getUserRoleOptions[idx].onClick}
          menuClass="right-[4px]"
          buttonClass={(open) =>
            classNames(
              'flex items-center pl-[8px] text-small tracking-wide font-medium transition text-left disabled:text-gray-normal/[.5]',
              open ? 'text-white' : 'text-gray-normal',
            )
          }
          buttonChildren={(open) => (
            <>
              {projectRole ? getProjectRoleName(projectRole, t) : getTeamRoleName(teamRole, t)}
              <ButtonIcon
                icon="arrow-drop-d"
                className={classNames(
                  'opacity-0 transition-opacity',
                  open && 'opacity-100',
                  !Boolean(teamRole === TeamRoleApi.ADMIN) &&
                    isSuperOrTeamOrProjectAdmin &&
                    'group-hover:opacity-100',
                )}
              />
            </>
          )}
          withSelect
        />
      </div>
      <ActionModal
        isOpen={warningPermissionModal.isOpen}
        title={
          isLastTeamOrProjectAdmin
            ? t('projects.warningPermissionModal_last.title')
            : t('projects.warningPermissionModal.title')
        }
        text={
          isLastTeamOrProjectAdmin
            ? t('projects.warningPermissionModal_last.text')
            : t('projects.warningPermissionModal.text')
        }
        onClose={handleWarningPermissionModalClose}
        hiddenCloseButton={isLastTeamOrProjectAdmin}
        actionButton={
          isLastTeamOrProjectAdmin
            ? {
                onClick: handleWarningPermissionModalClose,
                children: t('projects.warningPermissionModal_last.button'),
                textColorVariant: ButtonTextColorVariantEnum.Primary,
              }
            : {
                onClick: handleWarningPermissionModalActionClick,
                children: t('projects.warningPermissionModal.button'),
                disabled: teamContributorMutation.isLoading || putProjectUserRoleMutation.isLoading,
              }
        }
      />
    </>
  )
}
