import { TemplateValues } from 'components/guide/markdown/TemplateEngine'
import React, { useCallback, useMemo } from 'react'
import { unified } from 'unified'
import remarkParse from 'remark-parse'
import remarkGfm from 'remark-gfm'
import { Root } from 'mdast'
import { IdGenerator } from 'components/guide/markdown/IdGenerator'
import { MarkdownProcessor } from 'components/guide/markdown/MarkdownProcessor'
import { MarkdownConfig } from 'components/guide/markdown/MarkdownConfig'
import { CodeTabsLanguageProvider } from 'components/guide/markdown/CodeTabsLanguageProvider'

export interface MarkdownProps {
  markdown: string
  templateValues: TemplateValues
  config: MarkdownConfig
}

export type GenIdFunction = () => number

/**
 * Markdown https://commonmark.org/
 * RemarkGfm needed for links parsing
 */
export const Markdown = ({ markdown, templateValues, config }: MarkdownProps) => {
  const ast: Root = useMemo(() => {
    const processor = unified().use(remarkParse).use(remarkGfm)
    return processor.parse(markdown)
  }, [markdown])

  const idGenerator = useMemo(() => new IdGenerator(), [])
  const genId: GenIdFunction = useCallback((): number => idGenerator.incAndGet(), [idGenerator])

  const nodes = useMemo(() => {
    return MarkdownProcessor.processContent(genId, ast.children, templateValues, config)
  }, [ast.children, config, genId, templateValues])

  return <CodeTabsLanguageProvider>{nodes}</CodeTabsLanguageProvider>
}
