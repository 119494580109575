import React, { useCallback, useState } from 'react'
import { useNavigate, useOutletContext } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'

import { CompleteStepWarning } from 'components/guide/CompleteStepWarning'
import { GuidePageOutletContext } from 'pages/guide/GuidePageOutletContext'
import { GuideStepType } from 'components/guide/models'
import { NextActionButton } from 'components/guide/NextActionButton'
import { HavingTroubles } from 'components/support/HavingTroubles'
import { FreeTrialStage, FreeTrialStageValue, TraceProcessingState } from 'api/models'
import { ProgressCircle } from 'components/common/util/ProgressCircle'
import { useApi } from 'contexts/di-context'
import { useToaster } from 'hooks/useToaster'
import { ViewTraceError } from 'components/guide/ViewTraceError'

const TraceWarningIssues = () => {
  const { t } = useTranslation()
  const { guideStore } = useOutletContext<GuidePageOutletContext>()
  const videoProcessErrorCode = guideStore.traceVideoMetadataDto?.processingErrorCode
  return (
    <>
      <p className="font-[400] text-[12px] leading-[21px]">
        {t('guidePage.viewTrace.warnings.title')}
      </p>
      <ul className="font-[400] text-[12px] leading-[21px] list-disc pl-4">
        {guideStore.isVideoProcessing! && (
          <li data-testid="videoIsProcessing">
            {t('guidePage.viewTrace.warnings.trace.video.isProcessing')}
          </li>
        )}
        {guideStore.isVideoConditionFailed && (
          <li data-testid={videoProcessErrorCode}>
            {t(`guidePage.viewTrace.warnings.${videoProcessErrorCode}`)}
          </li>
        )}
        {guideStore.isTraceQualityWarningUnderFiltered && (
          <li data-testid="traceUnderFilteredWarning">
            {t('guidePage.viewTrace.warnings.trace.underFiltered')}
          </li>
        )}
        {guideStore.isTraceQualityWarningOverFiltered && (
          <li data-testid="traceOverFilteredWarning">
            {t('guidePage.viewTrace.warnings.trace.overFiltered')}
          </li>
        )}
      </ul>
      <p className="font-[400] text-[12px] leading-[21px]">
        {t('guidePage.viewTrace.warnings.questions')}
      </p>
    </>
  )
}

export const ViewTrace = observer(function ViewTrace() {
  const { t } = useTranslation()
  const { guideStore } = useOutletContext<GuidePageOutletContext>()
  const isRecordTraceCompleted = Boolean(
    guideStore.steps.find((step) => step.type === GuideStepType.RecordTrace)?.completed,
  )
  const traceState = guideStore.bestTrace?.processingState
  const isRecordTraceStepInProcessing =
    traceState === TraceProcessingState.IN_PROGRESS || traceState === TraceProcessingState.UPLOADING

  return (
    <div className="pt-[40px]">
      {!isRecordTraceCompleted && (
        <CompleteStepWarning>{t('guidePage.completeAllStepsWarning')}</CompleteStepWarning>
      )}

      {guideStore.traceUrlPath != null ? (
        <>
          <h1 className="font-[500] text-[26px] leading-[39px] mb-[32px]">
            {t('guidePage.congratulations')}
          </h1>
          <p className="font-[400] text-[12px] leading-[21px]">{t('guidePage.viewTrace1')}</p>
          {guideStore.isTraceHasWarnings && <TraceWarningIssues />}
          <p className="font-[400] text-[12px] leading-[21px]">
            {t(
              !guideStore.isTraceHasWarnings
                ? 'guidePage.viewTrace2'
                : 'guidePage.viewTrace2Warning',
            )}
          </p>

          <OpenTraceButton
            traceUrlPath={guideStore.traceUrlPath}
            projectIdOrUrlName={guideStore.freeTrialProjectSummary!.project.urlName}
            freeTrialStage={guideStore.freeTrialTeam?.freeTrial?.stage}
            data-testid="openTraceButton"
          />
        </>
      ) : (
        <>
          {guideStore.bestTrace != null && (
            <div className="flex flex-col justify-center items-center bg-[#191919]">
              <div className="text-[14px] text-gray-normal font-[500] leading-[21px] tracking-[2%] pt-[8.5px] pb-[7.5px] flex justify-center">
                {isRecordTraceStepInProcessing ? (
                  <>
                    <div className="h-[16px] w-[16px] mr-[8px]">
                      <ProgressCircle />
                    </div>
                    <div>{t('guidePage.viewTrace.traceInProcessing')}</div>
                  </>
                ) : (
                  <ViewTraceError guideStore={guideStore} />
                )}
              </div>
            </div>
          )}

          <div className="mt-[56px]">
            <HavingTroubles>
              <p className="text-[14px] text-gray-normal font-[400] leading-[21px] tracking-[2%] mt-[24px]">
                {t('guidePage.submitRequests')}
              </p>
            </HavingTroubles>
          </div>
        </>
      )}
    </div>
  )
})

const OpenTraceButton = ({
  traceUrlPath,
  projectIdOrUrlName,
  freeTrialStage,
}: {
  traceUrlPath: string
  projectIdOrUrlName: string
  freeTrialStage?: FreeTrialStageValue
}) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const api = useApi()
  const toaster = useToaster()
  const [isLoading, setIsLoading] = useState(false)

  const openTrace = useCallback(() => {
    if (freeTrialStage === FreeTrialStage.FLOW_LIBRARY) {
      return navigate(traceUrlPath)
    }
    setIsLoading(true)
    api
      .postFinishInstructions(projectIdOrUrlName)
      .then(() => {
        navigate(traceUrlPath)
      })
      .catch((error) => toaster.error(error))
      .finally(() => {
        setIsLoading(false)
      })
  }, [freeTrialStage, api, projectIdOrUrlName, navigate, traceUrlPath, toaster])

  return (
    <NextActionButton
      onClick={openTrace}
      label={t('guidePage.openTraceViewer')}
      disabled={isLoading}
      className="min-w-[186px]"
    >
      {isLoading && <ProgressCircle className="inline mr-[8px]" />}
      {t('guidePage.openTraceViewer')}
    </NextActionButton>
  )
}
