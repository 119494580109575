import { FEATURE_FLAGS, useFeatureFlag } from 'utils/feature-flags'
import { useParams } from 'react-router-dom'

export function useIsReadOnlyProject(): boolean | undefined {
  const { projectUrlName } = useParams()
  const readOnlyProjects = useFeatureFlag(FEATURE_FLAGS.READ_ONLY_PROJECTS)
  if (readOnlyProjects !== undefined && projectUrlName) {
    return Array.isArray(readOnlyProjects) && readOnlyProjects.includes(projectUrlName)
  }
  return undefined
}
