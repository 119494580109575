import { makeAutoObservable, observable, runInAction } from 'mobx'
import { ContentfulClientApi, Entry } from 'contentful'
import { QueryClient } from 'react-query'
import dayjs from 'dayjs'
import { t } from 'i18next'

import { Api } from 'api/Api'
import {
  CONTENTFUL_ORDER,
  CONTENTFUL_TYPES,
  GUIDE_IO_FAQ_ID,
  GUIDE_IO_PAGE_KEYS,
} from 'api/contentful'
import { TypeGuideIoSectionFields } from 'hooks/__generated__/contentful/TypeGuideIoSection'
import { OsTypeValue, ProjectDto } from 'api/__generated__/api-types'
import { TypeGuideIoPageFields } from 'hooks/__generated__/contentful/TypeGuideIoPage'
import { fetchAllProjects } from 'hooks/useApiQuery'
import { ProjectRoleApi } from 'api/__generated__/api-constants'

const EARLY_ACCESS_ERROR_MSG = t('guideIoPage.errors.earlyAccessErrorMsg')

const TOKEN_PAGES: ReadonlyArray<string> = [
  GUIDE_IO_PAGE_KEYS.androidBuild,
  GUIDE_IO_PAGE_KEYS.iosXCode,
  GUIDE_IO_PAGE_KEYS.iosBazel,
]

export class GuideIoStore {
  private readonly api: Api
  private readonly abortController: AbortController
  private readonly contentfulClient: ContentfulClientApi
  private readonly queryClient: QueryClient
  private projectsByUrlName: ReadonlyMap<string, ProjectDto> = new Map()
  private contentfulSectionByOsKey: ReadonlyMap<string, Entry<TypeGuideIoSectionFields>> = new Map()
  faqPage: TypeGuideIoPageFields | null = null
  private buildProperties?: string

  isUserDataReady = false
  isProjectDataReady = false

  projectUrlName?: string
  stepKey?: string
  error?: string

  constructor(api: Api, queryClient: QueryClient, contentfulClient: ContentfulClientApi) {
    makeAutoObservable<
      GuideIoStore,
      'api' | 'queryClient' | 'abortController' | 'projectsByUrlName' | 'contentfulEntryByOsKey'
    >(this, {
      api: false,
      queryClient: false,
      abortController: false,
      projectsByUrlName: observable.ref,
      contentfulEntryByOsKey: observable.ref,
    })
    this.api = api
    this.queryClient = queryClient
    this.contentfulClient = contentfulClient
    this.abortController = new AbortController()
  }

  async fetchUserData() {
    try {
      return await Promise.allSettled([
        this.contentfulClient
          .getEntries<TypeGuideIoSectionFields>({
            content_type: CONTENTFUL_TYPES.guideIoSection,
            order: CONTENTFUL_ORDER.createdAt,
          })
          .then((response) => {
            this.setContentfulEntriesMap(response.items)
          }),
        this.contentfulClient.getEntry<TypeGuideIoPageFields>(GUIDE_IO_FAQ_ID).then((response) =>
          runInAction(() => {
            this.faqPage = response.fields
          }),
        ),
        fetchAllProjects(this.queryClient, this.api).then((projects) => {
          this.setProjectsByUrlName(new Map(projects.map((project) => [project.urlName, project])))
        }),
      ])
    } catch (error) {
      console.error(error)
      this.error = t('guideIoPage.errors.fetchFailed')
    } finally {
      this.setUserDataReady()
    }
  }

  async fetchProjectData() {
    if (this.projectUrlName == null) {
      throw new Error(EARLY_ACCESS_ERROR_MSG)
    }
    try {
      this.setBuildProperties(await this.api.postBuildProperties(this.projectUrlName))
    } catch (error) {
      console.error(error)
      this.error = t('guideIoPage.errors.fetchFailed')
    } finally {
      this.setProjectDataReady()
    }
  }

  private setBuildProperties(buildProperties: string) {
    this.buildProperties = buildProperties
  }

  setUserDataReady() {
    if (this.abortController.signal.aborted) {
      return
    }
    this.isUserDataReady = true
  }

  setProjectDataReady() {
    if (this.abortController.signal.aborted) {
      return
    }
    this.isProjectDataReady = true
  }

  get isReady(): boolean {
    return this.isUserDataReady && this.isNavigationSet && this.isProjectDataReady
  }

  getProjectByProjectUrlName(projectUrlName: string) {
    return this.projectsByUrlName.get(projectUrlName)
  }

  getDefaultProjectStepKey(projectDto: ProjectDto): string | null {
    const contentfulEntry = this.contentfulSectionByOsKey.get(projectDto.os)
    if (contentfulEntry == null) {
      return null
    }
    const defaultStep = contentfulEntry.fields.steps[0]
    return defaultStep?.fields.urlKey ?? null
  }

  get defaultIoProject(): ProjectDto | null {
    return (
      [...this.projectsByUrlName.entries()]
        .map(([_, projectDto]: [string, ProjectDto]) => projectDto)
        .filter((projectDto) => projectDto.interactiveOnboarding)
        .sort(
          (projectA, projectB) =>
            dayjs(projectB.dateCreated).millisecond() - dayjs(projectA.dateCreated).millisecond(),
        )[0] ?? null
    )
  }

  get osKey(): OsTypeValue {
    if (this.projectUrlName == null) {
      throw new Error(EARLY_ACCESS_ERROR_MSG)
    }
    const projectDto = this.getProjectByProjectUrlName(this.projectUrlName)
    if (projectDto == null) {
      throw new Error(EARLY_ACCESS_ERROR_MSG)
    }
    return projectDto.os
  }

  get section(): TypeGuideIoSectionFields {
    const section = this.contentfulSectionByOsKey.get(this.osKey)
    if (section == null) {
      throw new Error(EARLY_ACCESS_ERROR_MSG)
    }
    return section.fields
  }

  private get stepsPages(): TypeGuideIoPageFields[] {
    return this.getAllStepsPages(this.section)
  }

  private getAllStepsPages(section: TypeGuideIoSectionFields): TypeGuideIoPageFields[] {
    if (this.faqPage == null) {
      throw new Error(EARLY_ACCESS_ERROR_MSG)
    }
    return [...section.steps.map((step) => step.fields), this.faqPage]
  }

  get page(): TypeGuideIoPageFields {
    const stepPage = this.stepsPages.find((page) => page.urlKey === this.stepKey)
    if (stepPage == null) {
      throw new Error(EARLY_ACCESS_ERROR_MSG)
    }
    return stepPage
  }

  get shouldShowGetHelp(): boolean {
    return Boolean(this.page.shouldShowGetHelp)
  }

  get templateValuesMap(): Map<string, string> {
    if (this.buildProperties == null) {
      throw new Error(EARLY_ACCESS_ERROR_MSG)
    }
    const templateValuesMap = new Map()
    if (TOKEN_PAGES.includes(this.page.id)) {
      templateValuesMap.set('readToken', this.buildProperties)
      templateValuesMap.set(
        'downloadFileLink',
        URL.createObjectURL(
          new Blob([this.buildProperties], {
            type: 'text/plain;charset=UTF-8',
          }),
        ),
      )
    }
    return templateValuesMap
  }

  get project(): ProjectDto {
    if (this.projectUrlName == null) {
      throw new Error(EARLY_ACCESS_ERROR_MSG)
    }
    const project = this.getProjectByProjectUrlName(this.projectUrlName)
    if (project == null) {
      throw new Error(EARLY_ACCESS_ERROR_MSG)
    }
    return project
  }

  get isNavigationSet(): boolean {
    return this.projectUrlName != null && this.stepKey != null
  }

  private setContentfulEntriesMap(contentfulEntries: Entry<TypeGuideIoSectionFields>[]) {
    this.contentfulSectionByOsKey = new Map(
      contentfulEntries.map((entry) => [entry.fields.projectOsKey, entry]),
    )
  }

  private setProjectsByUrlName(projectsByUrlName: Map<string, ProjectDto>) {
    this.projectsByUrlName = projectsByUrlName
  }

  setProjectStep(projectUrlName: string, stepKey: string) {
    const projectDto = this.projectsByUrlName.get(projectUrlName)
    if (projectDto == null) {
      this.error = t('guideIoPage.errors.projectWasNotFound', { projectUrlName })
      return
    }
    const contentfulEntry = this.contentfulSectionByOsKey.get(projectDto.os)
    if (contentfulEntry == null) {
      this.error = t('guideIoPage.errors.entryForOsWasNotFound', { os: projectDto.os })
      return
    }
    const stepsKeys = this.getAllStepsPages(contentfulEntry.fields).map((step) => step.urlKey)
    if (!stepsKeys.includes(stepKey)) {
      this.error = t('guideIoPage.errors.stepWasNotFound', { stepKey })
      return
    }

    this.projectUrlName = projectUrlName
    this.stepKey = stepKey
  }

  async getShareUrl(): Promise<string> {
    if (this.projectUrlName == null) {
      throw new Error(EARLY_ACCESS_ERROR_MSG)
    }
    const urlDto = await this.api.getProjectShareUrl(this.projectUrlName)
    return urlDto.url
  }

  get isShareLinkReady(): boolean {
    return this.projectUrlName != null
  }

  sendShareUrl(emails: string[]) {
    if (this.projectUrlName == null) {
      throw new Error(EARLY_ACCESS_ERROR_MSG)
    }
    return Promise.all(
      emails.map((email: string) =>
        this.api.postProjectUser(
          { projectUrlName: this.projectUrlName },
          { email, role: ProjectRoleApi.CONTRIBUTOR },
        ),
      ),
    )
  }

  destroy(): void {
    this.abortController.abort()
    this.setProjectsByUrlName(new Map())
    this.setContentfulEntriesMap([])
    this.isUserDataReady = false
    this.isProjectDataReady = false
    this.projectUrlName = undefined
    this.stepKey = undefined
  }
}
