import { PsChartStore } from 'components/ps-chart/PsChartStore'
import { observer } from 'mobx-react-lite'
import { useCallback } from 'react'
import { ActionTooltip } from 'components/ActionTooltip'
import { IconColored } from 'components/common/IconColored'
import { useToaster } from 'hooks/useToaster'
import { Slice } from 'components/ps-chart/models/Slice'

interface VariantsPathControlsProps {
  slice: Slice
  psChartStore: PsChartStore
}

type OutpathIconType = 'unselectPath' | 'updateSelectedPath' | 'selectThisPath'

export const VariantsPathControls = observer(function VariantsPathControls({
  slice,
  psChartStore,
}: VariantsPathControlsProps) {
  const toaster = useToaster()
  const variants = psChartStore.traceAnalyzeStore.cycleProcessedVariantsBySliceId.get(slice.id)!
  const totalCount = variants.length

  const currentIndex =
    totalCount > 0 ? psChartStore.traceAnalyzeStore.getCycleSliceCurrentVariantIndex(slice) + 1 : 0
  const isDisabled = totalCount < 2
  const isPinnedSelected = psChartStore.traceAnalyzeStore.isCurrentCycleSliceVariantPinned(slice)

  const handlePrevPress = useCallback(() => {
    psChartStore.traceAnalyzeStore.selectCycleSlicePreviousVariant(slice)
  }, [slice, psChartStore])

  const handleNextPress = useCallback(() => {
    psChartStore.traceAnalyzeStore.selectCycleSliceNextVariant(slice)
  }, [slice, psChartStore])

  const handleTogglePress = useCallback(() => {
    psChartStore.traceAnalyzeStore
      .togglePinCycleSliceVariantIndex(slice)
      .catch(() => toaster.error('psChart.error.traceViewer.failedToUpdateChoreographerPaths'))
  }, [slice, psChartStore, toaster])

  const pinState = psChartStore.traceAnalyzeStore.cycleSlicePinState(slice)

  return (
    <div className="flex font-medium text-small text-gray-normal tracking-widest mb-[15px]">
      <div className="flex">
        <span className="min-w-[75px]">
          <p>
            Path {currentIndex}/{totalCount}
          </p>
        </span>
        <ActionTooltip tooltipId={pinState.tooltip as OutpathIconType} place="top">
          <IconColored
            className="mr-[5px]"
            icon={pinState.icon}
            size={16}
            isActive={isPinnedSelected}
            isDisabled={false}
            onClick={handleTogglePress}
          />
        </ActionTooltip>
      </div>
      <div className="ml-[10px]">
        <ActionTooltip tooltipId="prevPath" place="top">
          <IconColored
            icon="arrow-drop-left"
            size={12}
            isDisabled={isDisabled}
            onClick={handlePrevPress}
          />
        </ActionTooltip>
        <ActionTooltip tooltipId="nextPath" place="top">
          <IconColored
            className="ml-[15px] scale-x-[-1]"
            icon="arrow-drop-left"
            size={12}
            isDisabled={isDisabled}
            onClick={handleNextPress}
          />
        </ActionTooltip>
      </div>
    </div>
  )
})
