const SearchIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M15 15L19 19" stroke="#B3B3B3" strokeLinejoin="round" />
      <path
        d="M11 17C14.3137 17 17 14.3137 17 11C17 7.68629 14.3137 5 11 5C7.68629 5 5 7.68629 5 11C5 14.3137 7.68629 17 11 17Z"
        stroke="#B3B3B3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export const EndpointsSearch = ({
  value,
  onChange,
}: {
  value: string
  onChange: (input: string) => void
}) => {
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    onChange(event.target.value)

  return (
    <div className="flex items-center justify-start w-[320px] h-[32px] bg-[#363636] rounded-[4px] overflow-hidden">
      <div className="flex items-center justify-center w-12 h-12">
        <SearchIcon />
      </div>
      <input
        type="search"
        placeholder="Search"
        className="peer w-full h-[32px] px-0 py-0 text-small tracking-wide bg-[#363636] border-0 placeholder:text-gray-normal text-white focus:ring-0 focus-visible:outline-0"
        value={value}
        onChange={handleInputChange}
      />
    </div>
  )
}
