import { SliceLink } from 'components/ps-chart/models/SliceLink'
import { Slice } from 'components/ps-chart/models/Slice'
import { ConnectionDirection } from 'components/ps-chart/models/ConnectionDirection'

/**
 * Get all connections from stored map of all slices starting
 * from start slice through all it's ancestor parents until root slice
 **/
export const getStackChainsLinks = (
  startSlice: Slice,
  sliceLinksById: Map<number, ReadonlyArray<SliceLink>>,
  linkDirection: ConnectionDirection,
  isGoUpDisabled = false,
): SliceLink[] => {
  const chainsRoots: SliceLink[] = []

  const queue: Slice[] = [startSlice]

  while (queue.length > 0) {
    let curSlice = queue.shift()

    while (curSlice !== null && curSlice !== undefined) {
      const curLinks = sliceLinksById
        .get(curSlice!.id)
        ?.filter(({ connectionDirection }) => connectionDirection === linkDirection)

      if (curLinks != null && curLinks.length > 0 && !curSlice!.title.startsWith('UnityPlayer')) {
        chainsRoots.push(...curLinks)
      }

      if (
        (linkDirection === ConnectionDirection.BACKWARD && curSlice.parent === null) ||
        isGoUpDisabled
      ) {
        break
      }

      if (linkDirection === ConnectionDirection.BACKWARD && curSlice.parent !== null) {
        curSlice = curSlice.parent
      } else {
        if (curSlice.children && curSlice.children.length > 0) {
          // Add children to the queue for processing
          queue.push(...curSlice.children)
        }
        break // Exit the inner while loop to process next item from queue
      }
    }
  }

  return chainsRoots
}
