import React, { useCallback, useEffect, useMemo } from 'react'
import { useQueryClient } from 'react-query'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react-lite'
import { when } from 'mobx'

import { useApi, useContentfulClient } from 'contexts/di-context'
import { GuideIoStore } from 'components/guide-io/GuideIoStore'
import { useToaster } from 'hooks/useToaster'
import { Layout } from 'components/Layout'
import { useGuideIoNavigationRules } from 'components/guide-io/UseGuideIoNavigationRules'
import { GuideIoPageContent } from 'components/guide-io/GuideIoPageContent'
import { ShareLinkContextProvider } from 'components/share-with-team/ShareLinkContext'
import { useUserQuery } from 'hooks/useApiQuery'
import { canUserInviteToProject } from 'permissions/canUserInviteToProject'
import { useIoSingleProject } from 'components/guide-io/useIoSingleProject'
import { BaseHeader } from 'components/header/BaseHeader'
import { PATH_ROOT } from 'utils/links'
import { Icon } from 'components/Icon'
import { ShareLink } from 'components/share-with-team/ShareLink'
import { Footer } from 'components/Footer'

const GuideIoSubHeader = () => {
  const { t } = useTranslation()

  return (
    <div className="h-[100px] bg-dark-dark3 flex justify-center items-end">
      <div className="w-[988px] mb-[20px] text-header-normal-v2 font-medium">
        {t('guideIoPage.topHeader')}
      </div>
    </div>
  )
}

export const GuideIoPage = observer(function GuideIoPage() {
  const toaster = useToaster()
  const api = useApi()
  const queryClient = useQueryClient()
  const contentfulClient = useContentfulClient()
  const { data: user } = useUserQuery()
  const guideIoStore: GuideIoStore = useMemo(
    () => new GuideIoStore(api, queryClient, contentfulClient),
    [api, queryClient, contentfulClient],
  )
  useGuideIoNavigationRules(guideIoStore)

  useEffect(() => {
    guideIoStore.fetchUserData().catch((error) => {
      toaster.error(error, 'guideIoPage.errors.fetchFailed')
    })

    return () => {
      guideIoStore.destroy()
    }
  }, [guideIoStore, toaster])

  useEffect(
    () =>
      when(
        () => guideIoStore.isNavigationSet,
        () => {
          guideIoStore.fetchProjectData().catch((error) => {
            toaster.error(error, 'guideIoPage.errors.fetchFailed')
          })
        },
      ),
    [guideIoStore, toaster],
  )

  const getShareLink = useCallback(() => guideIoStore.getShareUrl(), [guideIoStore])

  const sendShareLink = useCallback(
    (emailsList: string[]) => guideIoStore.sendShareUrl(emailsList),
    [guideIoStore],
  )

  const canInvite = Boolean(
    user != null && guideIoStore.isReady && canUserInviteToProject(user, guideIoStore.project),
  )
  const { isLoaded: isIoSingleProjectCheckLoaded, ioSingleProject } = useIoSingleProject()

  const header = useMemo(
    () => (
      <BaseHeader
        leftSideContent={
          isIoSingleProjectCheckLoaded && ioSingleProject == null ? (
            <Link to={PATH_ROOT}>
              <Icon icon="arrow-drop-left" className="mr-[11px] align-middle text-micro" />
              <span className="text-small">Back</span>
            </Link>
          ) : undefined
        }
        rightSideContent={<ShareLink />}
        subHeader={<GuideIoSubHeader />}
      />
    ),
    [isIoSingleProjectCheckLoaded, ioSingleProject],
  )

  const footer = useMemo(() => <Footer />, [])

  return (
    <ShareLinkContextProvider
      getShareLink={getShareLink}
      sendShareLink={sendShareLink}
      isShareLinkReady={guideIoStore.isShareLinkReady}
      isHidden={!canInvite}
    >
      <Layout headerComponent={header} footerComponent={footer}>
        <GuideIoPageContent guideIoStore={guideIoStore} />
      </Layout>
    </ShareLinkContextProvider>
  )
})
