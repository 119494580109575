import { ConnectionType } from 'components/ps-chart/models/ConnectionType'
import { useTranslation } from 'react-i18next'

export const useConnectionHeader = (isLast: boolean, connectionType?: ConnectionType) => {
  const { t } = useTranslation()

  if (isLast) {
    return null
  }

  switch (connectionType) {
    case ConnectionType.CLOSURE:
      return { title: t('psChart.details.connectedAutoHeader'), className: 'border-lime' }
    case ConnectionType.MANUAL:
      return { title: t('psChart.details.connectedManuallyHeader'), className: 'border-electro' }
    default:
      return { title: t('psChart.details.descendantHeader'), className: 'border-dark-dark6' }
  }
}
