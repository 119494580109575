import React, { createContext, useCallback, useContext, useState } from 'react'
import { CustomerSupportModal } from 'components/CustomerSupportModal'

interface ModalContextType {
  isModalOpen: boolean
  openModal: () => void
  closeModal: () => void
}

const CustomerSupportModalContext = createContext<ModalContextType>({
  isModalOpen: false,
  openModal: () => {},
  closeModal: () => {},
})

export const CustomerSupportModalContextProvider: React.FC = ({ children }) => {
  const [isModalOpen, setIsModalOpen] = useState(false)

  const openModal = useCallback(() => setIsModalOpen(true), [])
  const closeModal = useCallback(() => setIsModalOpen(false), [])

  return (
    <CustomerSupportModalContext.Provider value={{ isModalOpen, openModal, closeModal }}>
      {children}
      <CustomerSupportModal isVisible={isModalOpen} onClose={closeModal} />
    </CustomerSupportModalContext.Provider>
  )
}

export function useCustomerSupportModal(): ModalContextType {
  return useContext(CustomerSupportModalContext)
}
