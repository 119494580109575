import Konva from 'konva'
import { createRect } from 'utils/konva'

export interface ActiveWindowSettings {
  initialWidth: number
  height: number
  cursorDefault: string
  cursorDragging: string
  cursorMove: string
}

export class ActiveWindow {
  private readonly settings: ActiveWindowSettings

  rect: Konva.Rect

  private isDragging = false

  constructor(settings: ActiveWindowSettings) {
    this.settings = settings
    this.rect = createRect(0, 0, settings.initialWidth, settings.height)
  }

  addEventListeners() {
    this.rect.on('mouseover', this.setCursorResize)
    this.rect.on('mouseout', this.setDefaultCursor)
    this.rect.on('dragstart', this.setCursorResize)
    this.rect.on('dragend', this.updateCursor)
  }

  removeEventListeners() {
    this.rect.off('mouseover')
    this.rect.off('mouseout')
    this.rect.off('dragstart')
    this.rect.off('dragend')
  }

  private setDefaultCursor = () => (document.body.style.cursor = this.settings.cursorDefault)

  private setCursorResize = () => {
    document.body.style.cursor = this.isDraggable
      ? this.settings.cursorMove
      : this.settings.cursorDefault
  }

  private updateCursor = () => {
    this.isDragging = false
    document.body.style.cursor = this.isPointerOutOfDragRegion
      ? this.settings.cursorDefault
      : this.isDraggable
      ? this.settings.cursorMove
      : this.settings.cursorDefault
  }

  updatePos(x: number, width: number) {
    this.rect.x(x)
    this.rect.width(width)
    this.rect.draggable(this.isDraggable)
  }

  get isPointerOutOfDragRegion(): boolean {
    const pos = this.rect.getRelativePointerPosition()
    const isOutOfHeight = pos.y > this.settings.height - 1 || pos.y < 0
    const isOutOfWidth = pos.x < 0 || pos.x + 1 > this.rect.width()
    return isOutOfHeight || isOutOfWidth
  }

  get isDraggable(): boolean {
    return this.rect.width() + 1 < this.settings.initialWidth
  }

  get startX(): number {
    return this.rect.x()
  }

  get width(): number {
    return this.rect.width()
  }

  get endX(): number {
    return this.startX + this.width
  }

  set startY(y: number) {
    this.rect.y(y)
  }
}
