import { useParams } from 'react-router-dom'
import { ChartPageParams } from 'api/models'
import { useMemo } from 'react'

export const useChartPageParams = (): ChartPageParams => {
  const { projectUrlName, traceProjectLocalId, flowProjectLocalId } = useParams<ChartPageParams>()
  if (projectUrlName == null || traceProjectLocalId == null || flowProjectLocalId == null) {
    throw new Error(
      'One of the required chart page params (projectUrlName, traceProjectLocalId) was not set',
    )
  }

  // useParams can change the returned object without changing the inner value
  return useMemo(
    () => ({ projectUrlName, traceProjectLocalId, flowProjectLocalId }),
    [projectUrlName, traceProjectLocalId, flowProjectLocalId],
  )
}
