import React, { useCallback, useMemo } from 'react'
import { useLocation, useSearchParams } from 'react-router-dom'
import NProgress from 'nprogress'

import { PasswordForm } from 'components/free-trial/PasswordForm'
import { RequestFreeTrialForm } from 'components/free-trial/RequestFreeTrialForm'
import { useToaster } from 'hooks/useToaster'
import { useAuth } from 'contexts/auth-context'
import { Layout } from 'components/Layout'
import { TokenDest } from 'api/models'
import { PATH_ROOT } from 'utils/links'
import { BaseHeader } from 'components/header/BaseHeader'
import { Footer } from 'components/Footer'

export const PATH_SIGN_UP_TRIAL = '/sign-up-trial'

export const SignUpTrialPage = () => {
  const [searchParams] = useSearchParams()
  const { signInSSO } = useAuth()
  const verificationToken = searchParams.get('ver-token')
  const teamToken = searchParams.get('team-token') ?? undefined
  const location = useLocation()
  const toaster = useToaster()

  const handleFirebaseSuccessAuth = useCallback(
    (ssoToken: string) => {
      NProgress.start()
      signInSSO({
        data: { ssoData: { ssoIdToken: ssoToken, allowFreeTrial: true } },
        redirectPath: location.state?.from?.pathname ?? PATH_ROOT,
        stateFields: { teamToken },
      })
        .catch((error) => {
          toaster.error(error)
        })
        .finally(() => {
          NProgress.done()
        })
    },
    [location, signInSSO, toaster, teamToken],
  )

  const handleOktaSuccessAuth = useCallback(
    (ssoToken: string) => {
      NProgress.start()
      signInSSO({
        data: {
          ssoData: { ssoIdToken: ssoToken, allowFreeTrial: true },
          tokenDest: TokenDest.BODY,
        },
        redirectPath: location.state?.from?.pathname ?? PATH_ROOT,
        stateFields: { teamToken },
      })
        .catch((error) => {
          toaster.error(error)
        })
        .finally(() => {
          NProgress.done()
        })
    },
    [location, signInSSO, toaster, teamToken],
  )

  const header = useMemo(() => <BaseHeader />, [])
  const footer = useMemo(() => <Footer />, [])

  return (
    <Layout headerComponent={header} footerComponent={footer}>
      <div className="flex min-h-full items-center justify-center bg-dark-dark3">
        <div className="w-full flex justify-center">
          {verificationToken ? (
            <PasswordForm verificationToken={verificationToken} />
          ) : (
            <RequestFreeTrialForm
              onFirebaseSuccess={handleFirebaseSuccessAuth}
              onOktaSuccess={handleOktaSuccessAuth}
              teamToken={teamToken}
              withForm={false}
            />
          )}
        </div>
      </div>
    </Layout>
  )
}
