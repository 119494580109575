import { SliceLink } from 'components/ps-chart/models/SliceLink'
import { ConnectionDirection } from 'components/ps-chart/models/ConnectionDirection'

export const filterPreExitStackChains =
  (linkDirection = ConnectionDirection.BACKWARD) =>
  (links: SliceLink[]) => {
    if (linkDirection === ConnectionDirection.BACKWARD) {
      return links
    }

    const fromIdKey = 'toSliceId'
    const toIdKey = 'fromSliceId'

    // Build a set of all toSliceId values
    const toSliceIds = new Set<number>()
    for (const link of links) {
      toSliceIds.add(link[toIdKey])
    }

    // Filter the links, keeping only those where fromSliceId is not in toSliceIds
    const filteredLinks = links.filter((link) => !toSliceIds.has(link[fromIdKey]))

    return filteredLinks
  }
