import React from 'react'
import { Button, ButtonVariantEnum } from 'components/Button'

interface Props {
  label: string
  onClick?: () => void
  disabled?: boolean
  type?: 'button' | 'submit' | 'reset'
  children?: React.ReactNode
  className?: string
}

export const NextActionButton = ({
  disabled,
  label,
  onClick,
  type,
  children,
  className = '',
}: Props) => {
  return (
    <div className="mb-[100px]">
      <hr className="w-full border-1 border-dark-dark6 my-[40px]" />
      <div className="w-full flex justify-end">
        <Button
          disabled={disabled}
          aria-label={label}
          onClick={onClick}
          variant={ButtonVariantEnum.Outlined}
          className={`mr-1 disabled:cursor-not-allowed ${className}`}
          type={type}
        >
          {children ?? label}
        </Button>
      </div>
    </div>
  )
}
