import React, { useEffect, useMemo, useState } from 'react'
import classNames from 'classnames'
import { useMutation, useQueryClient } from 'react-query'
import { useParams } from 'react-router-dom'
import NProgress from 'nprogress'
import { useTranslation } from 'react-i18next'

import { Modal, ModalProps } from 'components/Modal'
import { Input } from 'components/Input'
import { Icon } from 'components/Icon'
import { getProjectRoleName } from 'utils/getProjectRoleName'
import { ApiError, ProjectRoleApi, ProjectRoleApiValue, ProjectUsersResponse } from 'api/models'
import { queryKeys } from 'hooks/useApiQuery'
import { AxiosError } from 'axios'
import { useToaster } from 'hooks/useToaster'
import { isEmailValid } from 'utils/validation'
import { useApi } from 'contexts/di-context'
import { ListBox } from 'components/dropdowns/ListBox'

type ProjectInviteModalProps = Pick<ModalProps, 'isOpen' | 'onClose'>

export const ProjectInviteModal = ({ isOpen, onClose }: ProjectInviteModalProps) => {
  const { t } = useTranslation()
  const toaster = useToaster()
  const api = useApi()
  const { projectUrlName } = useParams() as { projectUrlName: string }
  const queryClient = useQueryClient()

  const [email, setEmail] = useState('')
  const [isEmailValidState, setIsEmailValidState] = useState(false)

  useEffect(() => {
    if (email) {
      setIsEmailValidState(isEmailValid(email))
    }
  }, [email])

  const [role, setRole] = useState<ProjectRoleApiValue>(ProjectRoleApi.CONTRIBUTOR)

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    setEmail(event.target.value)
  const handleRoleClick = (newRole: ProjectRoleApiValue) => () => setRole(newRole)

  const getRoleOptions = useMemo(() => {
    return [
      {
        name: getProjectRoleName(ProjectRoleApi.CONTRIBUTOR, t),
        onClick: handleRoleClick(ProjectRoleApi.CONTRIBUTOR),
        isSelect: role === ProjectRoleApi.CONTRIBUTOR,
      },
      {
        name: getProjectRoleName(ProjectRoleApi.ADMIN, t),
        onClick: handleRoleClick(ProjectRoleApi.ADMIN),
        isSelect: role === ProjectRoleApi.ADMIN,
      },
    ]
  }, [role, t])

  const resetData = () => {
    setEmail('')
    setRole(ProjectRoleApi.CONTRIBUTOR)
  }

  const postProjectUserMutation = useMutation(
    () => api.postProjectUser({ projectUrlName }, { email, role }),
    {
      onSuccess: (data) => {
        NProgress.done()
        queryClient.setQueryData<ProjectUsersResponse | undefined>(
          queryKeys.projectUsers({ projectUrlName }),
          (oldData) => {
            if (oldData) {
              return { ...oldData, users: oldData.users.concat(data) }
            }
          },
        )
        resetData()
        onClose()
      },
      onError: (err: AxiosError<ApiError>) => {
        NProgress.done()
        toaster.error(err.response?.data.message ?? t('errorMessage'))
      },
    },
  )

  const handleInviteClick = () => {
    NProgress.start()
    postProjectUserMutation.mutate()
  }

  return (
    <Modal
      title={t('projects.projectInviteModal.title')}
      isOpen={isOpen}
      onClose={onClose}
      actionButton={{
        children: t('projects.projectInviteModal.button'),
        onClick: handleInviteClick,
        disabled: postProjectUserMutation.isLoading || !isEmailValidState,
      }}
    >
      <div className="mt-[16px] text-small tracking-wide text-gray-normal">
        {t('projects.projectInviteModal.text')}
      </div>
      <div className="my-[16px] flex item-center">
        <Input
          value={email}
          onChange={handleEmailChange}
          type="email"
          placeholder={t('projects.projectInviteModal.placeholder')}
          containerClassName="mb-0"
          inModal
        />
        <ListBox
          className="ml-[7px]"
          menuClass="right-[10px] border-[1px] border-dark-dark2 py-[2px]"
          onSelect={(index) => getRoleOptions[index].onClick()}
          menuSections={[{ options: getRoleOptions }]}
          buttonClass={(open) =>
            classNames(
              'flex items-center text-small tracking-wide transition pl-[8px]',
              open ? 'text-white' : 'text-gray-normal',
            )
          }
          buttonChildren={() => (
            <>
              <p className="mr-1">{getRoleOptions.find((item) => item.isSelect)?.name}</p>
              <Icon icon="arrow-drop-d" className="text-icon relative left-[-6px]" />
            </>
          )}
          withSelect
        />
      </div>
    </Modal>
  )
}
