import styled from 'styled-components/macro'
import tw from 'twin.macro'

interface ArrowProps {
  setWidth: number
  setHeight: number
  setColor: string
  isActive: boolean
}

export const Arrow = (props: ArrowProps) => {
  return (
    <View {...props}>
      <Svg viewBox="0 0 4.2 7.4" height="100%" width="100%" xmlns="http://www.w3.org/2000/svg">
        <path
          opacity="1"
          fill={props.setColor}
          d="m.1 3.3c-.1.2-.1.5 0 .7l3.2 3.2c.2.2.5.2.7 0s.2-.5 0-.7l-2.8-2.8 2.8-2.8c.2-.2.2-.5 0-.7-.2-.2-.5-.2-.7-.1zm4.1-.1h-3.7v1h3.7zm0 0v1"
        />
      </Svg>
    </View>
  )
}

const View = styled.div<ArrowProps>`
  ${tw`transition`}
  position: absolute;
  top: 50%;
  left: 100%;
  width: ${({ setWidth }) => setWidth * 4 + 7}px;
  height: ${({ setHeight }) => setHeight}px;
  margin-left: 2px;
  opacity: 1;
  border: 1px solid ${({ setColor }) => setColor};
  border-left: 0;
  border-radius: 0 2px 2px 0;
  .bar-list-dragged &,
  .bar-list-item-dragged & {
    opacity: 0;
  }
`

const Svg = styled.svg`
  position: absolute;
  z-index: 1;
  bottom: 0;
  left: 0;
  width: 4px;
  height: 7px;
  transform: translateY(57%);
`
