import React, { ReactNode, useCallback, useState } from 'react'
import classNames from 'classnames'

import { Icon } from 'components/Icon'

import { BreadcrumbContext } from './BreadcrumbContext'

interface BreadcrumbProps {
  children: ReactNode
}

export const Breadcrumbs = ({ children }: BreadcrumbProps) => {
  const [loadingSections, setLoadingSections] = useState(0)
  const registerLoading = useCallback((isLoading: boolean) => {
    if (isLoading) {
      setLoadingSections((prev) => prev + 1)
    } else {
      setLoadingSections((prev) => (prev > 1 ? prev - 1 : 0))
    }
  }, [])

  const childrenArray = React.Children.toArray(children)
  const childrenWithDelimiters: ReactNode[] = []

  childrenArray.forEach((child, index) => {
    childrenWithDelimiters.push(
      React.cloneElement(child as React.ReactElement, { key: `section-${index}` }),
    )
    if (index < childrenArray.length - 1) {
      childrenWithDelimiters.push(
        <Icon
          icon="arrow-drop-r"
          className="text-[30px] text-gray-faded mx-[-3px]"
          key={`delimiter-${index}`}
        />,
      )
    }
  })

  return (
    <div
      className={classNames([loadingSections === 0 ? 'flex' : 'hidden', 'break-all items-center'])}
    >
      <BreadcrumbContext.Provider value={{ registerLoading, loadingSections }}>
        {childrenWithDelimiters}
      </BreadcrumbContext.Provider>
    </div>
  )
}
