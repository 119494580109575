import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { Icon } from 'components/Icon'
import { QuickstartPages } from 'components/quickstart/models'
import { QuickstartModal } from 'components/quickstart/modal/Modal'
import { useModal } from 'hooks/useModal'
import { QUICKSTART } from 'components/quickstart/constants'
import { useQuickstartContent } from '../hooks/useQuickstartContent'

export const QuickstartButton = () => {
  const { t } = useTranslation()
  const { pageToContentMap, linksToPages, video, videoThumbnail } = useQuickstartContent()
  const { openModal } = useModal(QUICKSTART)

  const handleClick = useCallback(() => {
    const progressFromLocalStorage = localStorage.getItem(QUICKSTART)
    openModal(progressFromLocalStorage || QuickstartPages.Contents)
  }, [openModal])

  const isDataLoaded = pageToContentMap && linksToPages
  return (
    <>
      <div className="h-[100%] absolute flex items-center min-w-[140px] ml-[10px]">
        <button
          onClick={handleClick}
          className="h-[30px] hover:text-white font-[500] text-[12px] text-gray-normal flex items-center absolute"
        >
          <Icon icon="arrow-round-l" className="mr-[10px] text-[28px] rotate-180" />
          {t('quickstart.modalOpenButton')}
        </button>
      </div>
      {isDataLoaded && (
        <QuickstartModal
          pageToContentMap={pageToContentMap}
          linksToPages={linksToPages}
          video={video}
          videoThumbnail={videoThumbnail}
        />
      )}
    </>
  )
}
