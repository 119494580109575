import { useController } from 'react-hook-form'
import { Input, InputProps } from 'components/Input'
import { InputPassword, InputPasswordProps } from 'components/InputPassword'
import { HTMLInputTypeAttribute } from 'react'

type OmitFields = 'value' | 'error' | 'onChange' | 'onBlur'
type InputOmittedProps = Omit<InputProps, OmitFields>
type InputPasswordOmittedProps = { type: 'password' } & Omit<InputPasswordProps, OmitFields>

export type FormInputProps<FormFields> = {
  type?: HTMLInputTypeAttribute
  name: keyof FormFields
} & (InputOmittedProps | InputPasswordOmittedProps)

/**
 * TODO: please remove this "proxy component" and use `register` or `Controller` instead https://react-hook-form.com/api/useform/register/
 * @deprecated
 * @param props
 * @constructor
 */
export function FormInput<FormFields>(props: FormInputProps<FormFields>) {
  const { name, ...otherProps } = props
  const { field } = useController({ name })

  const InputComponent = props.type === 'password' ? InputPassword : Input

  return (
    <InputComponent
      value={field.value || ''}
      onChange={field.onChange}
      onBlur={field.onBlur}
      {...otherProps}
    />
  )
}
