import { ReactHTML, ReactNode } from 'react'

export interface MdListProps {
  ordered: boolean
  className?: string
  children: ReactNode
}

export const MdList = ({ ordered, className, children }: MdListProps) => {
  const ListNode: keyof ReactHTML = ordered ? 'ol' : 'ul'
  return (
    <ListNode
      className={`${ordered ? 'list-decimal' : 'list-disc'} list-inside ${className ?? ''}`}
    >
      {children}
    </ListNode>
  )
}
