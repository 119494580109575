import { observer } from 'mobx-react-lite'
import { PsChartStore } from 'components/ps-chart/PsChartStore'
import { ActionsPanel } from 'components/ps-chart/actions-panel/ActionsPanel'
import { ChartScroll } from 'components/ps-chart/ChartScroll'
import { VideoLeftView } from 'components/ps-chart/VideoLeftView'
import { useHotKeys } from 'components/ps-chart/hooks/useHotKeys'
import { VideoDataStoreStatus } from 'components/ps-chart/stores/VideoDataStore'
import { ChartExtensions } from './PsChartExtensions'
import { DetailsView } from './details-view/DetailsView'
import { useScrollListener } from './hooks/useScrollListener'
import { FlameChart } from './flame-chart/FlameChart'

export const PsChart = observer(function PsChart(props: { psChartStore: PsChartStore }) {
  const { psChartStore } = props

  useHotKeys(['KeyG'], () => psChartStore.goToSelectedSlice(), psChartStore.isEnabledListeners)
  useHotKeys(
    ['KeyH'],
    () => psChartStore.flagsStore.toggleShowLabels(),
    psChartStore.isEnabledListeners,
  )

  useHotKeys(
    ['Backslash'],
    () => psChartStore.setIsVideoModeEnabled(!psChartStore.isVideoPreviewInGlobalTimelineEnabled),
    psChartStore.isEnabledListeners,
  )

  const isVideoNavigationHotKeysEnabled =
    psChartStore.isEnabledListeners &&
    psChartStore.videoPlayerStore.status === VideoDataStoreStatus.HAS_VIDEO_AND_READY &&
    !psChartStore.videoPlayerStore.isVideoPlaying

  useHotKeys(
    ['shiftKey', 'ArrowLeft'],
    () => psChartStore.videoPlayerStore.jumpBackwardByTime(),
    isVideoNavigationHotKeysEnabled,
  )

  useHotKeys(
    ['shiftKey', 'ArrowRight'],
    () => psChartStore.videoPlayerStore.jumpForwardByTime(),
    isVideoNavigationHotKeysEnabled,
  )

  useHotKeys(
    ['ArrowLeft'],
    () => psChartStore.videoPlayerStore.jumpToSiblingFrame(true),
    isVideoNavigationHotKeysEnabled,
  )

  useHotKeys(
    ['ArrowRight'],
    () => psChartStore.videoPlayerStore.jumpToSiblingFrame(false),
    isVideoNavigationHotKeysEnabled,
  )

  useHotKeys(['KeyJ'], () => psChartStore.toggleRenderTypeMode(), psChartStore.isEnabledListeners)

  const { uiState } = psChartStore

  return (
    <main className="h-full flex-grow flex alwaysShowScrollbar">
      <div className="flex relative flex-grow overflow-hidden">
        <VideoLeftView psChartStore={psChartStore} />
        <div className="flex flex-grow flex-col overflow-hidden">
          <ChartExtensions
            psChartStore={psChartStore}
            tracePageParams={psChartStore.chartPageParams}
          />
          <ScrolledChart psChartStore={psChartStore} />
        </div>
        <ActionsPanel psChartStore={psChartStore} />
      </div>
      {!uiState.isRightHidden && <DetailsView psChartStore={psChartStore} />}
    </main>
  )
})

// This component is detached to be NOT observable
export const ScrolledChart = ({ psChartStore }: { psChartStore: PsChartStore }) => {
  const scrolledContainerRef = useScrollListener(psChartStore)

  return (
    <div className="flex flex-grow">
      <div className="flex flex-grow relative" ref={scrolledContainerRef}>
        <FlameChart psChartStore={psChartStore} />
        <ChartScroll psChartStore={psChartStore} />
      </div>
    </div>
  )
}
