import { ResendEmailVerificationTokenRequest } from 'api/models'
import { useApi } from 'contexts/di-context'
import { useToaster } from 'hooks/useToaster'
import { useCallback, useState } from 'react'

export const useResendLink = (request: ResendEmailVerificationTokenRequest) => {
  const api = useApi()
  const toaster = useToaster()
  const [isResendInactive, setIsResendInactive] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const handleResendLink = useCallback(() => {
    setIsLoading(true)
    return api
      .postResendVerToken(request)
      .then(() => {
        setIsResendInactive(true)
        setTimeout(() => {
          setIsResendInactive(false)
        }, 10_000)
      })
      .catch((error) => toaster.error(error))
      .finally(() => {
        setIsLoading(false)
      })
  }, [api, toaster, request])

  return {
    isResendInactive,
    isLoading,
    handleResendLink,
  }
}
