import { CanvasRect } from 'components/ps-chart/models/helper-types'

export enum BorderType {
  PRIMARY = 'primary',
  UNFOCUSED = 'native',
  SECONDARY = 'secondary',
  ACTIVE = 'active',
}

export enum LineType {
  PRIMARY = 'primary',
  UNFOCUSED = 'native',
  SECONDARY = 'secondary',
  ACTIVE = 'active',
  DISABLED = 'disabled',
}

/**
 * We used these types in render cycle in exact order so latest in list should have the highest visibility priority
 **/

export const AllBorderTypes = [
  BorderType.SECONDARY,
  BorderType.UNFOCUSED,
  BorderType.PRIMARY,
  BorderType.ACTIVE,
]

export const AllLineTypes = [
  LineType.SECONDARY,
  LineType.DISABLED,
  LineType.UNFOCUSED,
  LineType.PRIMARY,
  LineType.ACTIVE,
]

export interface NodeRenderData {
  rect: CanvasRect
  isPinned: boolean
  borderType: BorderType
}
