import React, { ReactNode } from 'react'

interface SettingsFieldProps {
  label: string
  children: ReactNode
  inline?: boolean
}

export const SettingsField = ({ label, children, inline }: SettingsFieldProps) => {
  return inline ? (
    <div className="flex space-x-1.5">
      <span className="text-gray-normal">{label}</span>
      <div className="text-white">{children}</div>
    </div>
  ) : (
    <div>
      <p className="text-gray-normal">{label}</p>
      <div className="text-white">{children}</div>
    </div>
  )
}
