import { useChartPageParams } from 'hooks/useChartPageParams'
import { LiveDemoDataLoader } from 'pages/LiveDemoPage'

export const PATH_FLOW_TRACE_LIVE_DEMO = `/projects/:projectUrlName/flows/:flowProjectLocalId/traces/:traceProjectLocalId/live-demo`

export const FlowTraceLiveDemo = () => {
  const chartPageParams = useChartPageParams()

  return <LiveDemoDataLoader chartPageParams={chartPageParams} />
}
