import { useCallback, useEffect, useRef, useState } from 'react'
import { observer } from 'mobx-react-lite'
import styled from 'styled-components/macro'

import { PlaybackRate, VideoPlayerStore } from 'components/ps-chart/stores/VideoPlayerStore'
import { LoopVideoButton } from 'components/ps-chart/details-view/video/LoopVideoButton'
import { PlayVideoButton } from 'components/ps-chart/details-view/video/PlayVideoButton'
import { useIsReadOnlyProject } from 'utils/feature-flags'
import { Button, ButtonTextColorVariantEnum, ButtonVariantEnum } from 'components/Button'
import { ELEMENTS_IDS } from 'components/ps-chart/elementsIds'
import { EmptyFramePreview } from './EmptyFramePreview'
import { useObserveVideoSize } from './VideoSizeObserver'
import { DeleteVideoModal } from './DeleteVideoModal'
import { VideoContextMenu } from './VideoContextMenu'

export interface VideoPlayerProps {
  videoStore: VideoPlayerStore
  isFullScreen?: boolean
  videoContainerClassName?: string
}

export const VideoPlayer = observer(function VideoPlayer({
  videoStore,
  isFullScreen,
  videoContainerClassName,
}: VideoPlayerProps) {
  const videoBox = useRef<HTMLDivElement>(null)
  const { videoWidth } = useObserveVideoSize(videoBox)
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false)
  const isReadOnlyProject = useIsReadOnlyProject()

  useEffect(() => {
    const videoBoxEl: HTMLDivElement | null = videoBox.current
    if (videoBoxEl === null) {
      return
    }
    videoBoxEl.innerHTML = ''
    if (videoStore.tag !== null) {
      videoBoxEl.appendChild(videoStore.tag)
    }
  }, [videoStore.tag])

  const handlePlayButtonPress = useCallback(() => {
    if (videoStore.isVideoPlaying) {
      videoStore.pauseVideo()
    } else {
      videoStore.playVideo()
    }
  }, [videoStore])

  return (
    <>
      <div className={`w-full relative px-1 ${isFullScreen ? 'h-[100%]' : 'h-details-panel'}`}>
        <div
          className={`flex flex-col items-center justify-center ${
            isFullScreen ? 'h-[100%]' : 'h-details-panel'
          }`}
        >
          <div className="relative w-[100%] flex flex-col items-center justify-center h-[100%] pb-2">
            <VideoContainer
              id={ELEMENTS_IDS.CHART_VIDEO_PLAYER}
              className={`${
                isFullScreen ? '[&>video]:h-annotationsVideo' : videoContainerClassName
              } `}
              ref={videoBox}
              isFullScreen={isFullScreen}
            />
            <EmptyFramePreview videoState={videoStore} videoWidth={videoWidth} />
            <div className="mt-4 flex justify-center space-x-2 pl-8">
              <SlowdownVideoButton videoStore={videoStore} />
              <PlayVideoButton
                isPlaying={videoStore.isVideoPlaying}
                onPress={handlePlayButtonPress}
              />
              <LoopVideoButton
                mode={videoStore.loopMode}
                onPress={videoStore.toggleVideoLoopMode}
              />
              {!isReadOnlyProject && (
                <VideoContextMenu
                  isDeleteModalOpen={isDeleteModalOpen}
                  setIsDeleteModalOpen={setIsDeleteModalOpen}
                />
              )}
            </div>
          </div>
        </div>
      </div>
      <DeleteVideoModal
        videoStore={videoStore}
        isDeleteModalOpen={isDeleteModalOpen}
        setIsDeleteModalOpen={setIsDeleteModalOpen}
      />
    </>
  )
})

const VideoContainer = styled.div<{ isFullScreen?: boolean }>`
  overflow: hidden;
  border-radius: 20px;

  @media (min-height: 0px) and (max-height: 400px) {
    border-radius: 4px;
  }
  @media (min-height: 400px) and (max-height: 600px) {
    border-radius: 10px;
  }
  @media (min-height: 600px) and (max-height: 670px) {
    border-radius: 12px;
  }

  video {
    min-height: 124px;
    max-height: ${(props) => (props.isFullScreen ? 'unset' : '645px')};
  }
`

export const SlowdownVideoButton = observer(function SlowdownVideoButton({
  videoStore,
}: {
  videoStore: VideoPlayerStore
}) {
  const videoButtonSlowdownTextVariant = (
    playbackRate: PlaybackRate,
  ): ButtonTextColorVariantEnum => {
    return playbackRate === PlaybackRate.NORMAL
      ? ButtonTextColorVariantEnum.Muted
      : ButtonTextColorVariantEnum.Primary
  }
  return (
    <Button
      className="w-16 rounded-sm"
      textColorVariant={videoButtonSlowdownTextVariant(videoStore.playbackRate)}
      variant={ButtonVariantEnum.Text}
      isSmall
      onClick={() => videoStore.togglePlaybackRate()}
    >
      0.5x
    </Button>
  )
})
