import { SliceLink } from 'components/ps-chart/models/SliceLink'
import { ReadonlySliceById } from 'components/ps-chart/stores/TraceDataStore'
import { ConnectionDirection } from 'components/ps-chart/models/ConnectionDirection'
import { Slice } from 'components/ps-chart/models/Slice'

const recursiveImpasseChainCheck = (
  rootSlice: Slice,
  linkSlice: Slice,
  sliceLinksById: Map<number, ReadonlyArray<SliceLink>>,
  sliceById: ReadonlySliceById,
): boolean => {
  const linkRoot = linkSlice.root !== null ? linkSlice.root : linkSlice
  if (linkRoot.id === rootSlice.id) {
    let nextLinks = sliceLinksById.get(linkRoot.id)

    if (nextLinks !== undefined) {
      nextLinks = nextLinks.filter(
        (link) => link.connectionDirection === ConnectionDirection.FORWARD,
      )
      if (nextLinks.length === 0) {
        return true
      }
      if (nextLinks.length === 1) {
        const nextLinkSlice = sliceById.get(nextLinks[0].fromSliceId)
        if (nextLinkSlice !== undefined) {
          return recursiveImpasseChainCheck(rootSlice, nextLinkSlice, sliceLinksById, sliceById)
        }
      }
      return false
    }
  }
  return false
}

export const removeSameImpasseStackChains =
  (
    sliceLinksById: Map<number, ReadonlyArray<SliceLink>>,
    sliceById: ReadonlySliceById,
    linkDirection = ConnectionDirection.BACKWARD,
  ) =>
  (chainsRoots: SliceLink[]) => {
    if (linkDirection === ConnectionDirection.BACKWARD) {
      return chainsRoots
    }
    const filteredChainsRoots = new Set<SliceLink>()
    for (const curChainRoot of chainsRoots) {
      const { toSliceId, fromSliceId } = curChainRoot
      const toSlice = sliceById.get(toSliceId)
      const fromSlice = sliceById.get(fromSliceId)
      if (toSlice !== undefined && fromSlice !== undefined) {
        const root = toSlice.root !== null ? toSlice.root : toSlice
        if (recursiveImpasseChainCheck(root, fromSlice, sliceLinksById, sliceById)) {
          filteredChainsRoots.add(curChainRoot)
        }
      }
    }
    return chainsRoots.filter((root) => !filteredChainsRoots.has(root))
  }
