import { useState, useEffect, useCallback } from 'react'
import { useLocation, useNavigationType, NavigationType } from 'react-router-dom'

const CONTENT_ID = '#__CONTENT__'

export const ContentScrollRestoration = () => {
  const location = useLocation()
  const navigationType = useNavigationType()
  const [scrollHistory, setScrollHistory] = useState<{ [key: string]: number }>({})

  const handleScroll = useCallback(() => {
    const contentEl = document.querySelector(CONTENT_ID)
    if (contentEl) {
      setScrollHistory((prev) => {
        const newValues = { ...prev }
        newValues[location.key] = contentEl.scrollTop || 0
        return newValues
      })
    }
  }, [location.key])

  useEffect(() => {
    const contentEl = document.querySelector(CONTENT_ID)

    if (!contentEl) {
      return
    }

    contentEl.addEventListener('scroll', handleScroll)

    return () => {
      contentEl.removeEventListener('scroll', handleScroll)
    }
  }, [handleScroll])

  useEffect(() => {
    const contentEl = document.querySelector(CONTENT_ID)
    if (navigationType === NavigationType.Pop && scrollHistory[location.key] && contentEl) {
      contentEl.scrollTop = scrollHistory[location.key]
    }
  }, [navigationType, location.key, scrollHistory])

  return null
}
