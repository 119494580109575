import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useSearchParams } from 'react-router-dom'
import { useMutation } from 'react-query'
import NProgress from 'nprogress'
import { toast } from 'react-hot-toast'

import { useAuth } from 'contexts/auth-context'
import { AuthLayout } from 'components/auth/AuthLayout'
import { Button } from 'components/Button'
import { ApiError, ResetPasswordRequest } from 'api/models'
import { isPasswordStrong } from 'utils/validation'
import { AxiosError } from 'axios'
import { useApi } from 'contexts/di-context'
import { InputPassword } from 'components/InputPassword'
import { PATH_SIGN_IN } from './SignInPage'

export const PATH_RESET_PASSWORD = '/reset-password'

export const ResetPasswordPage = () => {
  const { t } = useTranslation()
  const [searchParams] = useSearchParams()
  const auth = useAuth()
  const api = useApi()

  const [password, setPassword] = useState('')
  const [repeatPassword, setRepeatPassword] = useState('')
  const [notSamePasswordsError, setNotSamePasswordsError] = useState(false)
  const [strongPasswordError, setStrongPasswordError] = useState(false)
  const [capsLockError, setCapsLockError] = useState(false)
  const isSamePasswords =
    password.length > 0 && repeatPassword.length > 0 && password === repeatPassword
  const isPasswordValid = useMemo(() => isPasswordStrong(password), [password])
  const [passwordVisible, setPasswordVisible] = useState(false)

  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value)
    setNotSamePasswordsError(false)
    if (strongPasswordError) {
      setStrongPasswordError(!isPasswordStrong(event.target.value))
    }
  }
  const handleRepeatPasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRepeatPassword(event.target.value)
    setNotSamePasswordsError(false)
  }
  const handleInputBlur = (key: 'password' | 'repeatPassword') => () => {
    if (key === 'password') {
      setStrongPasswordError(!isPasswordValid)
    }
    setNotSamePasswordsError(
      (key === 'password' && repeatPassword.length > 0 && !isSamePasswords) ||
        (key === 'repeatPassword' && password.length > 0 && !isSamePasswords),
    )
  }
  const checkCapsLock = (event: React.KeyboardEvent<HTMLInputElement>) => {
    setCapsLockError(event.getModifierState('CapsLock'))
  }

  const recoveryToken = searchParams.get('reset-token')

  const { mutate: postResetPasswordCheckTokenMutate } = useMutation(
    () => api.postResetPasswordCheckToken({ recoveryToken: String(recoveryToken) }),
    {
      useErrorBoundary: true,
      onSuccess: () => {
        NProgress.done()
      },
      onError: () => {
        NProgress.done()
      },
    },
  )

  useEffect(() => {
    NProgress.start()
    postResetPasswordCheckTokenMutate()
  }, [postResetPasswordCheckTokenMutate])

  const resetPasswordMutation = useMutation(
    (variables: ResetPasswordRequest) => auth.resetPassword(variables),
    {
      onSuccess: () => {
        NProgress.done()
        toast.success(t('auth.resetPasswordPage.success'))
      },
      onError: (error: AxiosError<ApiError>) => {
        NProgress.done()
        toast.error(error.response?.data.message ?? t('errorMessage'))
      },
    },
  )

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault()

    if (!isSamePasswords) {
      setNotSamePasswordsError(true)
    }

    if (recoveryToken && isSamePasswords) {
      NProgress.start()
      resetPasswordMutation.mutate({ newPassword: password, recoveryToken: String(recoveryToken) })
    }
  }

  const handleVisibleClick = () => {
    setPasswordVisible(!passwordVisible)
  }

  return (
    <AuthLayout
      bottomInfo={[
        <Link to={PATH_SIGN_IN}>{t('auth.common.iRememberMyPassword')}</Link>,
        <span>
          <span className="text-gray-normal">{t('auth.common.haveQuestions')}</span>{' '}
          <a href="https://www.productscience.ai/contact" target="_blank" rel="noopener noreferrer">
            {t('auth.common.contactUs')}
          </a>
        </span>,
      ]}
    >
      <h2 className="text-center text-small tracking-wide text-gray-normal">
        {t('auth.resetPasswordPage.header')}
      </h2>
      <form className="mt-6" onSubmit={handleSubmit}>
        <div>
          <label htmlFor="password" className="sr-only">
            {t('auth.common.password')}
          </label>
          <InputPassword
            value={password}
            visible={passwordVisible}
            onMakeVisibleButtonClick={handleVisibleClick}
            checked={isSamePasswords && isPasswordValid}
            onChange={handlePasswordChange}
            onKeyUp={checkCapsLock}
            id="password"
            name="password"
            autoComplete="new-password"
            placeholder={t('auth.common.password')}
            className="pr-[40px]"
            onBlur={handleInputBlur('password')}
            required
            autoFocus
          />
        </div>
        <div>
          <label htmlFor="repeat-password" className="sr-only">
            {t('auth.common.confirmPassword')}
          </label>
          <InputPassword
            value={repeatPassword}
            visible={passwordVisible}
            onMakeVisibleButtonClick={handleVisibleClick}
            checked={isSamePasswords && isPasswordValid}
            onChange={handleRepeatPasswordChange}
            onKeyUp={checkCapsLock}
            id="repeat-password"
            name="repeat-password"
            autoComplete="new-password"
            placeholder={t('auth.common.confirmPassword')}
            className="pr-[40px]"
            onBlur={handleInputBlur('repeatPassword')}
            required
          />
        </div>
        {capsLockError && (
          <p className="mb-4 text-center text-small tracking-wide text-state-bad">
            {t('auth.common.capsWarning')}
          </p>
        )}
        {strongPasswordError && (
          <p className="mb-4 text-center text-small tracking-wide text-state-bad">
            {t('auth.common.strongPassword')}
          </p>
        )}
        {notSamePasswordsError && (
          <p className="mb-4 text-center text-small tracking-wide text-state-bad">
            {t('auth.common.isNotSamePasswords')}
          </p>
        )}
        <Button
          className="w-full"
          disabled={resetPasswordMutation.isLoading || !isPasswordValid || !isSamePasswords}
          isSmall
        >
          {t('auth.resetPasswordPage.button')}
        </Button>
      </form>
    </AuthLayout>
  )
}
