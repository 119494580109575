import { memo } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components/macro'
import tw from 'twin.macro'

import { Icon } from 'components/Icon'
import { VideoLoopMode } from 'components/ps-chart/stores/VideoPlayerStore'

type LoopVideoButtonProps = {
  mode: VideoLoopMode
  onPress: () => void
}

export const LoopVideoButton = memo(function LoopVideoButton({
  mode,
  onPress,
}: LoopVideoButtonProps) {
  const { t } = useTranslation()

  return (
    <LoopButton
      as="button"
      mode={mode}
      onClick={onPress}
      icon={getLoopModeName(mode)}
      aria-label={t(`psChart.video.loop.${getLoopModeName(mode)}`)}
    />
  )
})

const getLoopModeName = (mode: VideoLoopMode) => {
  switch (mode) {
    case VideoLoopMode.LOCAL: {
      return 'local'
    }
    case VideoLoopMode.GLOBAL: {
      return 'global'
    }
    default: {
      return 'off'
    }
  }
}

const LoopButton = styled(Icon)<{ mode: VideoLoopMode }>`
  ${tw`transition w-16 h-8 rounded-sm`}
  color: ${({ theme, mode }) =>
    mode === VideoLoopMode.OFF ? theme.colors.gray.normal : theme.colors.electro};

  ${({ theme }) => theme.notTouchScreen} {
    &:hover {
      color: ${({ theme, mode }) =>
        mode === VideoLoopMode.OFF ? theme.colors.white : theme.colors.sky};
    }
  }
`
