import { ArrayStorageItem } from './ArrayStorageItem'

export class StringsArrayStorageItem extends ArrayStorageItem<string> {
  addItem(newItem: string) {
    if (!this.value.includes(newItem)) {
      this.value = [...this.value, newItem]
    }
  }

  removeItem(removedItem: string) {
    this.value = this.value.filter((item) => item !== removedItem)
  }
}
