import { BorderType, LineType } from 'components/ps-chart/connections-render/NodeRenderData'

type Coordinates = [x: number, y: number]
export type ConnectionCoordinates = Array<Coordinates>
export type PathSteps = Array<ConnectionCoordinates>

export interface ConnectionPaths {
  [LineType.PRIMARY]: PathSteps
  [LineType.UNFOCUSED]: PathSteps
  [LineType.SECONDARY]: PathSteps
  [LineType.ACTIVE]: PathSteps
  [LineType.DISABLED]: PathSteps
}

export interface SliceBordersPaths {
  [BorderType.PRIMARY]: Path2D[]
  [BorderType.UNFOCUSED]: Path2D[]
  [BorderType.SECONDARY]: Path2D[]
  [BorderType.ACTIVE]: Path2D[]
}

export interface ConnectionCurves {
  localCurvePaths: ConnectionPaths
  crossCurvePaths: ConnectionPaths
  sliceBordersPaths: SliceBordersPaths
}

export interface CanvasesCurvesMap {
  main: ConnectionCurves
  pinned: ConnectionCurves
}

export const getEmptyConnectionCurves = (): ConnectionCurves => ({
  localCurvePaths: getEmptyConnectionPaths(),
  crossCurvePaths: getEmptyConnectionPaths(),
  sliceBordersPaths: {
    [BorderType.PRIMARY]: [new Path2D(), new Path2D()],
    [BorderType.UNFOCUSED]: [new Path2D(), new Path2D()],
    [BorderType.SECONDARY]: [new Path2D(), new Path2D()],
    [BorderType.ACTIVE]: [new Path2D(), new Path2D()],
  },
})

export const getEmptyCanvasesCurvesMap = (): CanvasesCurvesMap => ({
  main: getEmptyConnectionCurves(),
  pinned: getEmptyConnectionCurves(),
})

export const getEmptyConnectionPaths = (): ConnectionPaths => ({
  [LineType.PRIMARY]: [],
  [LineType.UNFOCUSED]: [],
  [LineType.SECONDARY]: [],
  [LineType.ACTIVE]: [],
  [LineType.DISABLED]: [],
})
