import { Icon } from 'components/Icon'
import { LIVE_DEMO_TABS, LiveDemoController } from 'components/live-demo/LiveDemoController'
import { observer } from 'mobx-react-lite'
import classNames from 'classnames'
import React from 'react'
import { Link } from 'react-router-dom'

interface LiveDemoFooterPropTypes {
  liveDemoController: LiveDemoController
}

const items = [
  {
    icon: 'nav-annotation',
    ariaLabel: 'annotation',
    tab: LIVE_DEMO_TABS.annotation,
  },
  {
    icon: 'nav-processes',
    ariaLabel: 'processes',
    tab: LIVE_DEMO_TABS.process,
  },
  {
    icon: 'nav-connections',
    ariaLabel: 'connections',
    tab: LIVE_DEMO_TABS.trace,
  },
]

export const LiveDemoFooter = observer(function LiveDemoFooter({
  liveDemoController,
}: LiveDemoFooterPropTypes) {
  return (
    <div className="h-[var(--bottomBarHeight)] bg-dark-dark5 border-t border-t-gray-strokeLight flex items-center justify-center relative">
      <nav className="flex items-center justify-center h-full">
        {items.map((item, index) => {
          const renderContent = () => {
            const isCurrent = liveDemoController.currentTab === item.tab
            const isModalActive = liveDemoController.enabled
            const isFilledIcon = false
            return (
              <span
                className={classNames(
                  'flex items-center justify-center w-[120px] h-full pt-[3px] border-b-[3px] text-icon transition relative',
                  isCurrent
                    ? 'border-b-electro text-electro'
                    : 'border-b-transparent text-gray-dark',
                  !isCurrent && !isModalActive && 'hover:text-white',
                )}
              >
                <Icon icon={isFilledIcon ? `${item.icon}-active` : item.icon} />
              </span>
            )
          }
          const handleClick = (e: React.MouseEvent) => {
            e.preventDefault()

            if (!liveDemoController.enabled) {
              liveDemoController.setCurrentTab(item.tab)
            }
          }
          return (
            <Link
              className="h-full"
              aria-label={item.ariaLabel}
              key={String(index)}
              onClick={handleClick}
              to={'#'}
            >
              {renderContent()}
            </Link>
          )
        })}
      </nav>
    </div>
  )
})
