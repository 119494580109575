import React from 'react'

import { Breadcrumbs } from './Breadcrumbs'
import { BreadcrumbsTeamSection } from './BreadcrumbsTeamSection'
import { BreadcrumbsProjectSection } from './BreadcrumbsProjectSection'

export const ProjectFlowsPageBreadcrumbs = () => (
  <Breadcrumbs>
    <BreadcrumbsTeamSection />
    <BreadcrumbsProjectSection />
  </Breadcrumbs>
)
