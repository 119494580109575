/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useRef, useState } from 'react'
import { XYChart, LineSeries } from '@visx/xychart'
import { scaleLinear } from '@visx/scale'
import { AxisBottom, AxisLeft, TickFormatter } from '@visx/axis'
import { GridRows } from '@visx/grid'
import { NumberValue } from 'd3-scale'

import { LoadingSpinner } from 'components/LoadingSpinner'

import type { ChartData } from 'components/cco/types'

interface StatsChartProps {
  data: ChartData[]
  dataKey: string
  dataXKey: string
  dataYKey: string
  dataXFormat?: TickFormatter<NumberValue>
  dataYFormat: TickFormatter<NumberValue>
  lowColor: string
  highColor: string
  height?: number
  margin?: {
    top: number
    bottom: number
    left: number
    right: number
  }
}

const EmptyOrLoading = ({ isEmpty, isLoading }: { isEmpty: boolean; isLoading: boolean }) => {
  if (isEmpty) {
    return <div className="mb-[46px] text-[12px] leading-[16px] text-[#616161]">no data yet</div>
  }
  if (isLoading) {
    return <LoadingSpinner className="mb-[46px]" size={30} />
  }
  return null
}

export const LineGraph = ({
  data,
  dataKey,
  dataXKey,
  dataYKey,
  dataYFormat,
  lowColor,
  highColor,
  height = 234,
  margin = { top: 0, bottom: 27, left: 80, right: 0 },
}: StatsChartProps) => {
  const containerRef = useRef<HTMLDivElement>(null) // Ref to the container element
  const [width, setWidth] = useState(100)

  useEffect(() => {
    const updateDimensions = () => {
      if (containerRef.current) {
        setWidth(containerRef.current.offsetWidth)
      }
    }

    updateDimensions() // Call initially
    window.addEventListener('resize', updateDimensions)

    // Cleanup on unmount
    return () => window.removeEventListener('resize', updateDimensions)
  }, [])

  // Accessors
  const getX = (d: any) => {
    return d?.[dataXKey]
  }
  const getY = (d: any) => {
    return d?.[dataYKey]
  }
  const xDomain = [Math.min(...data.map(getX)), Math.max(...data.map(getX))]
  const yDomain = [Math.min(...data.map(getY)), Math.max(...data.map(getY))]

  // Scales
  const xScale = scaleLinear({
    range: [0, width - margin.left - margin.right],
    domain: xDomain,
  })
  const yScale = scaleLinear({
    range: [height - margin.bottom - margin.top, 0],
    domain: yDomain,
  })

  // For y-axis (order is crucial)
  const y0 = yDomain[1]
  const y35 = yDomain[1] - 0.333 * (yDomain[1] - yDomain[0])
  const y65 = yDomain[1] - 0.666 * (yDomain[1] - yDomain[0])
  const y100 = yDomain[0]

  const noData = yDomain[0] === 0 && yDomain[1] === 0
  const yTickValues = !noData ? [y0, y35, y65, y100] : undefined

  const xConfig = { type: 'band', paddingInner: 0.9 } as const
  const yConfig = { type: 'linear' } as const

  return (
    <div className="h-full flex justify-center items-center" ref={containerRef}>
      {width > 0 && data.length && !noData ? (
        <XYChart margin={margin} width={width} height={height} xScale={xConfig} yScale={yConfig}>
          <defs>
            <linearGradient id={`gradient-${dataKey}`} gradientTransform="rotate(90)">
              <stop offset="0%" stopColor={`${lowColor}`} />
              <stop offset="100%" stopColor={`${highColor}`} />
            </linearGradient>
          </defs>
          <GridRows
            scale={yScale}
            width={width - margin.left - margin.right}
            height={height - margin.top - margin.bottom}
            left={margin.left}
            stroke="#292929"
            tickValues={yTickValues}
          />
          <AxisBottom
            scale={xScale}
            top={height - margin.bottom}
            left={margin.left}
            numTicks={3}
            stroke="#292929"
            tickLength={0}
          />
          <AxisLeft
            left={margin.left}
            top={0}
            scale={yScale}
            stroke="#292929"
            tickStroke="#292929"
            tickLength={margin.left}
            tickFormat={dataYFormat}
            tickLabelProps={{
              dy: '1em',
              dx: '16px',
              textAnchor: 'start',
              fontSize: 12,
              fontFamily: 'Manrope',
              fill: '#616161',
            }}
            tickValues={yTickValues}
          />

          <LineSeries
            dataKey={dataKey}
            data={data}
            width={width}
            xAccessor={getX}
            yAccessor={getY}
            stroke={`url(#gradient-${dataKey})`}
          />
        </XYChart>
      ) : (
        <EmptyOrLoading isLoading={!data.length} isEmpty={noData} />
      )}
    </div>
  )
}
