import React, { HTMLProps } from 'react'

export type MdThematicBreakProps = HTMLProps<HTMLHRElement>

export const MdThematicBreak = ({ ...rest }: MdThematicBreakProps) => {
  return (
    <div>
      <hr {...rest} />
    </div>
  )
}
