import { PsChartStore } from 'components/ps-chart/PsChartStore'
import { HorizontalState } from 'components/ps-chart/models/HorizontalState'

const getTimelineXStartPx = (hState: HorizontalState) => {
  const globalTimePerPx = (hState.xMax - hState.xMin) / hState.width
  return Math.round((hState.xStart - hState.xMin) / globalTimePerPx)
}

export abstract class Action {
  protected _isFinished = false
  protected readonly psChartStore: PsChartStore

  constructor(psChartStore: PsChartStore) {
    this.psChartStore = psChartStore
  }

  reset() {
    this._isFinished = false
  }

  perform(): void {
    if (this.isFinished) {
      return undefined
    }
  }

  get isFinished() {
    return this._isFinished
  }
}

abstract class ZoomAction extends Action {
  protected static readonly zoomDeltaY = 30

  protected abstract zoom(): void

  perform() {
    super.perform()
    const prevTimelineXStartPx = getTimelineXStartPx(this.psChartStore.hState)
    this.zoom()
    if (prevTimelineXStartPx === getTimelineXStartPx(this.psChartStore.hState)) {
      this._isFinished = true
    }
  }
}

export class ZoomInAction extends ZoomAction {
  protected zoom() {
    this.psChartStore.hState.increaseZoom(undefined, ZoomAction.zoomDeltaY)
  }
}

export class ZoomOutAction extends ZoomAction {
  protected zoom() {
    this.psChartStore.hState.decreaseZoom(undefined, ZoomAction.zoomDeltaY)
  }
}

const scrollStepsCount = 15

export class ScrollLeft extends Action {
  protected deltaX = 0

  reset() {
    super.reset()
    this.deltaX = Math.round(
      (this.psChartStore.hState.xStart - this.psChartStore.hState.xMin) / scrollStepsCount,
    )
  }

  perform() {
    super.perform()
    const prevXStart = this.psChartStore.hState.xStart
    this.psChartStore.hState.changeXStart(-this.deltaX)
    if (prevXStart === this.psChartStore.hState.xStart) {
      this._isFinished = true
    }
  }
}

export class ScrollRight extends Action {
  protected deltaX = 0
  protected finishX = 0

  reset() {
    super.reset()
    this.finishX = Math.round((this.psChartStore.hState.xMax - this.psChartStore.hState.xMin) / 2)
    this.deltaX = Math.round((this.finishX - this.psChartStore.hState.xMin) / scrollStepsCount)
  }

  perform() {
    super.perform()
    this.psChartStore.hState.changeXStart(this.deltaX)
    if (this.psChartStore.hState.xStart >= this.finishX) {
      this._isFinished = true
    }
  }
}
