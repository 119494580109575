import {
  FieldErrors,
  FormProvider,
  useForm as useHookForm,
  UseFormProps as UseHookFormProps,
  UseFormReturn,
} from 'react-hook-form'
import { PropsWithChildren } from 'react'
import { SubmitErrorHandler, SubmitHandler } from 'react-hook-form/dist/types/form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'

export type ValidationSchema<T> = Partial<Record<keyof T, yup.AnySchema>>

interface FormProps<FormFields> {
  formContext: UseFormReturn<FormFields>
  onSubmit: SubmitHandler<FormFields>
  onSubmitInvalid?: SubmitErrorHandler<FormFields>
}

export function Form<Fields>(props: PropsWithChildren<FormProps<Fields>>) {
  const { onSubmit, onSubmitInvalid, children, formContext, ...otherProps } = props

  return (
    <FormProvider {...formContext}>
      <form {...otherProps} onSubmit={formContext.handleSubmit(onSubmit, onSubmitInvalid)}>
        {children}
      </form>
    </FormProvider>
  )
}

interface UseFormProps<FormFields> {
  formProps?: UseHookFormProps<FormFields>
  validationSchema: yup.Schema<ValidationSchema<FormFields>>
}

export function useForm<Fields>(props: UseFormProps<Fields>): UseFormReturn<Fields> {
  const { validationSchema, formProps } = props
  return useHookForm({
    resolver: yupResolver(validationSchema),
    ...formProps,
  })
}

export const getFormErrorsArrayFromObject = (errors: FieldErrors): string[] => {
  const messages: string[] = []
  let fieldNameKey: keyof typeof errors
  for (fieldNameKey in errors) {
    const message = errors[fieldNameKey]?.message
    if (typeof message === 'string') {
      messages.push(message)
    }
  }
  return messages
}
