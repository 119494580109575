import React from 'react'

import { DetailsView } from 'components/ps-chart/details-view/DetailsView'
import { SessionsLeftBar } from 'components/cco/SessionsLeftBar'
import { ChartExtensions } from 'components/ps-chart/PsChartExtensions'
import { ScrolledChart } from 'components/ps-chart/PsChart'
import { PsChartStore } from 'components/ps-chart/PsChartStore'
import { SearchAction } from 'components/ps-chart/actions-panel/SearchAction'
import { RenderTypeModeAction } from 'components/ps-chart/actions-panel/RenderTypeModeAction'

import { SessionData } from 'components/cco/types'
import { ConnectionDirectionAction } from 'components/ps-chart/actions-panel/ConnectionDirectionAction'
import { SaveSnapshotAction } from 'components/cco/SaveSnapshotAction'
import { ShareViewAction } from 'components/cco/ShareViewAction'

export const TimelineView = ({
  sessionData: selectedSession,
  sessions,
  psChartStore,
  traceData,
  summaryData,
}: {
  sessions: SessionData[]
  sessionData: SessionData | null
  psChartStore: PsChartStore
  traceData: object
  summaryData: object
}) => {
  return (
    <main className="flex flex-grow h-full alwaysShowScrollbar">
      <div className="flex relative flex-grow overflow-hidden">
        <SessionsLeftBar
          selectedSession={selectedSession}
          sessions={sessions}
          psChartStore={psChartStore}
        />
        <div className="flex flex-grow flex-col overflow-hidden">
          <ChartExtensions
            psChartStore={psChartStore}
            tracePageParams={psChartStore.chartPageParams}
          />
          <ScrolledChart psChartStore={psChartStore} />
        </div>
        <div className="absolute right-[8px] bottom-[8px] flex flex-row">
          <div className="mx-[1px]">
            <SearchAction psChartStore={psChartStore} />
          </div>
          <div className="mx-[1px]">
            <RenderTypeModeAction psChartStore={psChartStore} />
          </div>
          <div className="mx-[1px]">
            <ConnectionDirectionAction psChartStore={psChartStore} />
          </div>
          <div className="mx-[1px]">
            <SaveSnapshotAction traceData={traceData} summaryData={summaryData} />
          </div>
          <div className="mx-[1px]">
            <ShareViewAction psChartStore={psChartStore} />
          </div>
        </div>
      </div>
      <DetailsView psChartStore={psChartStore} />
    </main>
  )
}
