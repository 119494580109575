import { ReactNode, useRef } from 'react'
import ReactDom from 'react-dom'
import { useDisablePinchZoom } from 'hooks/useDisablePinchZoom'

export const BODY_PORTAL_ID = 'tooltips-portal'

export const RootBodyPortal = () => <div id={BODY_PORTAL_ID} />

export const BodyPortal = (props: { children: ReactNode }) => {
  const portalElement = useRef(document.getElementById(BODY_PORTAL_ID))
  const { children } = props
  useDisablePinchZoom(portalElement)

  if (portalElement.current) {
    return ReactDom.createPortal(children, portalElement.current)
  } else {
    return null
  }
}
