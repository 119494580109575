import { makeAutoObservable } from 'mobx'

export class PsChartUIState {
  isLeftCollapsed = false
  isRightCollapsed = false
  isRightHidden = false
  selectedTabIndex = 0

  constructor() {
    makeAutoObservable(this)
  }

  setLeftCollapsed = (isCollapsed: boolean) => {
    this.isLeftCollapsed = isCollapsed
  }

  setRightCollapsed = (isCollapsed: boolean) => {
    this.isRightCollapsed = isCollapsed
  }

  setRightHidden = (isHidden: boolean) => {
    this.isRightHidden = isHidden
  }

  setSelectedTabIndex = (index: number) => {
    this.selectedTabIndex = index
  }
}
