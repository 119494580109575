import { colors } from 'utils/styles/colors'

export class VideoPointerSettings {
  readonly lineWidth = 1
  readonly rectWidth = 56
  readonly rectHeight = 18

  readonly timeTextPadding = 5

  readonly cursorDefault = 'default'
  readonly cursorResize = 'ew-resize'

  readonly fontSize = 10
  readonly fontFamily = 'Manrope'

  readonly lineColor = colors.electro
  readonly titleColor = colors.white
  readonly hoverColor = colors.gray.iconStatus
  readonly rectColor = colors.sky
}

export const videoPointerSettings = new VideoPointerSettings()
