import {
  commonTimelineSettings,
  CommonTimelineSettings,
} from 'components/global-timeline/models/CommonTimelineSettings'
import { flagsSettings } from 'components/ps-chart/local-timeline/flags/FlagsSettings'
import { videoPointerSettings } from 'components/ps-chart/local-timeline/video-pointer/VideoPointerSettings'
import {
  ghostIndicatorSettings,
  GhostIndicatorSettings,
} from 'components/global-timeline/models/GhostIndicatorSettings'
import { annotationsSettings } from 'components/ps-chart/local-timeline/annotations/AnnotationsSettings'

export class LocalTimelineSettings {
  common: CommonTimelineSettings = commonTimelineSettings
  ghostIndicator: GhostIndicatorSettings = ghostIndicatorSettings

  readonly scaleHeight = 56

  readonly ghostIndicatorHeightCoefficient = 24 / 56

  readonly contentHeightCoefficient = 24 / 56

  readonly flags = flagsSettings

  readonly videoPointer = videoPointerSettings

  readonly annotations = annotationsSettings
}
