import React, { useContext } from 'react'

export const ShareLinkContext = React.createContext<ShareLinkContextInterface | null>(null)

export interface ShareLinkContextInterface {
  getShareLink: () => Promise<string>
  sendShareLink: (emailsList: string[]) => Promise<unknown>
  isShareLinkReady: boolean
  isHidden?: boolean
}

export function useShareLinkContext(): ShareLinkContextInterface {
  const context = useContext(ShareLinkContext)
  if (context == null) {
    throw new Error('ShareLinkContext is not initialized')
  }
  return context
}

interface ShareLinkContextProviderProps extends ShareLinkContextInterface {
  children: React.ReactNode
}

export const ShareLinkContextProvider = ({
  children,
  getShareLink,
  sendShareLink,
  isShareLinkReady,
  isHidden,
}: ShareLinkContextProviderProps) => (
  <ShareLinkContext.Provider value={{ getShareLink, sendShareLink, isShareLinkReady, isHidden }}>
    {children}
  </ShareLinkContext.Provider>
)
