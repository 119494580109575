import { useTranslation } from 'react-i18next'
import { Icon } from 'components/Icon'
import { Menu } from 'components/dropdowns/Menu'
import type { OptionType } from 'components/dropdowns/models'

interface ContextMenuProps {
  isTableRowHovered: boolean
  contextMenuOptions: OptionType[]
  name: string
  isPopoverShowing: boolean
  contextMenuRef: React.RefObject<HTMLDivElement>
}

export const ContextMenu = ({
  isTableRowHovered,
  contextMenuOptions,
  name,
  isPopoverShowing,
  contextMenuRef,
}: ContextMenuProps) => {
  const { t } = useTranslation()

  return (
    <div ref={contextMenuRef} data-testid={`${name}-menu`} onClick={(e) => e.preventDefault()}>
      {isTableRowHovered && !isPopoverShowing && (
        <div className="w-[42px] z-[10]">
          <div className="mr-[26px] flex flex-col items-center relative">
            <Menu
              menuClass="left-[-172px] top-[28px] z-[100] text-white"
              menuSections={[
                {
                  name: t('traces.trace.contextMenuTitle'),
                  options: contextMenuOptions,
                },
              ]}
              buttonChildren={() => (
                <div
                  tabIndex={0}
                  className="focus:outline-electro focus:outline focus:outline-1 group border-[1px] border-gray-faded rounded-sm w-[24px] h-[24px] hover:cursor-pointer hover:border-white outline-2"
                >
                  <Icon icon="kebab" className="group-hover:text-white" />
                </div>
              )}
            />
          </div>
        </div>
      )}
    </div>
  )
}
