import { useRef, useEffect } from 'react'
import { QUICKSTART } from 'components/quickstart/constants'
import { QuickstartPages } from 'components/quickstart/models'
import { useModal } from 'hooks/useModal'

const TOP_CONTENT_OFFSET = 390
const INDEX_PAGE_OFFSET = 0

export const useResetScrollPosition = () => {
  const { paramValue } = useModal(QUICKSTART)
  const scrollContainerRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const element = scrollContainerRef?.current

    if (element) {
      element.scrollTop =
        paramValue === QuickstartPages.Contents ? INDEX_PAGE_OFFSET : TOP_CONTENT_OFFSET
    }
  }, [paramValue])

  return scrollContainerRef
}
