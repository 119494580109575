import React, { useMemo, useRef } from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { UserMenu } from 'components/UserMenu'
import { PATH_ROOT } from 'utils/links'
import { Icon } from 'components/Icon'
import { useDisablePinchZoom } from 'hooks/useDisablePinchZoom'
import { useAuth } from 'contexts/auth-context'
import { useTeamsQuery } from 'hooks/useApiQuery'
import { ShareLink } from 'components/share-with-team/ShareLink'
import { useFreeTrialShareLink } from 'components/share-with-team/useFreeTrialShareLink'
import { ShareLinkContextProvider } from 'components/share-with-team/ShareLinkContext'
import { Button, ButtonVariantEnum } from 'components/Button'
import { colors } from 'utils/styles/colors'
import { EARLY_BIRD_FORM_LINK } from 'components/live-demo/LiveDemo'

interface BaseHeaderProps {
  leftSideContent?: React.ReactNode
  rightSideContent?: React.ReactNode
  centerContent?: React.ReactNode
  subHeader?: React.ReactNode
}

const FeedbackButton = () => {
  const { t } = useTranslation()

  return (
    <Button
      borderColor={colors.electro}
      variant={ButtonVariantEnum.Outlined}
      className="mr-[16px] border-2 border-lime max-h-[32px] max-w-[120px] text-small text-gray-normal hover:text-white"
      onClick={() => {
        window.location.href = EARLY_BIRD_FORM_LINK
      }}
      isSmall
    >
      {t('components.header.earlyBirdFeedback')}
    </Button>
  )
}

const TrialShareLink = () => {
  const { data: teams } = useTeamsQuery()
  const isFreeTrial = useMemo(() => {
    return teams?.length === 1 && teams?.findIndex((team) => team.freeTrial) !== -1
  }, [teams])
  const teamUrlName = useMemo(() => {
    return teams?.length ? teams[0].urlName : ''
  }, [teams])

  const { getShareLink, sendShareLink, isShareLinkReady } = useFreeTrialShareLink(teamUrlName)
  return isFreeTrial ? (
    <>
      <FeedbackButton />
      <ShareLinkContextProvider
        getShareLink={getShareLink}
        sendShareLink={sendShareLink}
        isShareLinkReady={isShareLinkReady}
      >
        <ShareLink />
      </ShareLinkContextProvider>
    </>
  ) : null
}

export const BaseHeader = ({
  leftSideContent,
  rightSideContent,
  centerContent,
  subHeader,
}: BaseHeaderProps) => {
  const { t } = useTranslation()

  const headerRef = useRef<HTMLHeadingElement>(null)
  useDisablePinchZoom(headerRef)

  const { isSignedIn } = useAuth()

  return (
    <header ref={headerRef}>
      <div className="bg-dark-dark3 h-[48px] border-b-[1px] border-solid border-gray-inputBG grid grid-cols-[minmax(0,_1fr)_200px_minmax(0,_1fr)]">
        <div className="flex items-center">
          <Link
            to={PATH_ROOT}
            aria-label={t('appName')}
            data-tid="logo"
            className="px-[16px] mr-[16px] border-r-[1px] border-solid border-gray-inputBG flex items-center h-full"
          >
            <Icon icon="logo-creature" className="text-[20px] leading-none text-gray-service" />
          </Link>
          {leftSideContent}
        </div>
        <div className="flex justify-center items-center">{centerContent}</div>
        <div className="flex justify-end items-center">
          {rightSideContent}
          {isSignedIn && (
            <>
              <TrialShareLink />
              <div className="px-[16px]">
                <UserMenu />
              </div>
            </>
          )}
        </div>
      </div>
      {subHeader}
    </header>
  )
}
