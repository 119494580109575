import { useEffect } from 'react'

export const useHotKeys = (hotKeys: string[], callback: () => void, enabled = true) => {
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      const pressedKeys = [event.code]
      if (event.repeat) {
        return
      }

      if (event.altKey) {
        pressedKeys.push('altKey')
      }
      if (event.ctrlKey) {
        pressedKeys.push('ctrlKey')
      }
      if (event.shiftKey) {
        pressedKeys.push('shiftKey')
      }
      if (event.metaKey) {
        pressedKeys.push('metaKey')
      }

      if (isShortCutActive(hotKeys, pressedKeys)) {
        event.stopPropagation()
        callback()
      }
    }

    if (enabled) {
      window.addEventListener('keydown', handleKeyDown)
    }

    return () => {
      if (enabled) {
        window.removeEventListener('keydown', handleKeyDown)
      }
    }
  }, [callback, hotKeys, enabled])
}

function isShortCutActive(buttons: string[], pressed: string[]) {
  if (buttons.length !== pressed.length) {
    return false
  }

  const hotKeys = buttons.slice()
  pressed.forEach((pressedButton) => {
    const hotKeyIndex = hotKeys.findIndex((item) => item === pressedButton)
    if (hotKeyIndex !== -1) {
      hotKeys.splice(hotKeyIndex, 1)
    }
  })
  return hotKeys.length === 0
}
