import { useTranslation } from 'react-i18next'
import { Link, useLocation } from 'react-router-dom'
import classNames from 'classnames'
import { observer } from 'mobx-react-lite'
import { GuideStore } from 'components/guide/GuideStore'

import { Icon } from 'components/Icon'
import { useGuideStep } from 'components/guide/hooks/useGuideStep'
import { useGuidePlatform } from 'components/guide/hooks/useGuidePlatform'
import { capitalizeFirstLetter } from 'utils/stringUtils'
import { GuideStepType, PlatformType, Step } from './models'

export const Sidebar = observer(function Sidebar({ guideStore }: { guideStore: GuideStore }) {
  const { freeTrialProjectSummary } = guideStore
  const currentStep = useGuideStep()
  const currentPlatformFromUrl = useGuidePlatform()
  const currentPlatform =
    currentPlatformFromUrl || (freeTrialProjectSummary?.project.os.toLowerCase() as PlatformType)

  return (
    <nav>
      <ul>
        {guideStore.steps.map((step, index) => (
          <SideBarNavLink
            guideStore={guideStore}
            isActive={currentStep === step.type}
            step={step}
            platform={currentPlatform!}
            index={index + 1}
            key={index}
          />
        ))}
      </ul>
    </nav>
  )
})

interface SideBarNavLinkProps {
  isActive: boolean
  step: Step
  platform: PlatformType | null
  index: number
  guideStore: GuideStore
}

export const SideBarNavLink = ({ step, platform, index, isActive }: SideBarNavLinkProps) => {
  const { t } = useTranslation()
  useLocation()

  const disabled = !step.available
  const isComplete = step.completed
  const stepName = step.type.split('-').join(' ')
  const capitalizedStepName = capitalizeFirstLetter(stepName)

  return (
    <li className="min-h-[70px]">
      <Link
        to={platform ? GuideStore.getPageUrl(step.type, platform) : '#'}
        className={classNames(
          disabled && `pointer-events-none`,
          disabled && step.type !== GuideStepType.SelectPlatform && 'opacity-20',
        )}
      >
        <p className={'text-[11px] text-gray-normal uppercase leading-[16.5px]'}>
          {t('guidePage.step')} {index}
        </p>
        <div
          className={classNames(
            'w-full flex justify-between',
            isActive && 'border-b-2 border-electro',
          )}
        >
          <p
            className={classNames(
              `text-[14px] text-normal-gray leading-[21px] flex flex-grow`,
              isActive && 'text-white font-[14px] leading-[21px] pb-[5px] font-[500]',
            )}
          >
            {capitalizedStepName}
          </p>
          {isComplete ? (
            <Icon
              icon="step-complete"
              className="max-h-[60px] text-state-good text-[10px] mt-[3px]"
            />
          ) : null}
        </div>
      </Link>
    </li>
  )
}
