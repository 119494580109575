import { useUserQuery } from 'hooks/useApiQuery'
import { AnnotationDto, FlowDto, ProjectDto, TraceDto } from 'api/__generated__/api-types'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useApi } from 'contexts/di-context'
import { generatePath, Link, Navigate } from 'react-router-dom'
import { PATH_FLOWS } from 'pages/FlowsPage'
import { isUserProductScience } from 'utils/hiddenFlow'
import { PATH_ROOT } from 'utils/links'
import { PATH_CHART_ROUTER } from 'pages/PsChartRouter'
import { TraceProcessingState, VideoProcessingStateDto } from 'api/__generated__/api-constants'
import { PATH_FLOW } from 'pages/FlowPage'

export const DEV_PAGE_URL = '/dev'

const ST_DEV_PREFIX = 'ps-dev-data::'
const PROJECTS_KEY = `${ST_DEV_PREFIX}projects`
const FLOWS_KEY = `${ST_DEV_PREFIX}flows`
const TRACES_KEY = `${ST_DEV_PREFIX}traces`

interface DevFlowDto extends FlowDto {
  projectUrlName: string
}

interface DevTraceDto extends TraceDto {
  projectUrlName: string
  flowProjectLocalId: string
  annotations: AnnotationDto[]
}

export const DevPage = () => {
  const api = useApi()
  const [projects, setProjects] = useState<ProjectDto[]>([])
  const [flows, setFlows] = useState<Record<string, DevFlowDto[]>>({})
  const [traces, setTraces] = useState<Record<string, DevTraceDto[]>>({})
  const [isLoading, setIsLoading] = useState(false)

  const { data: user } = useUserQuery()
  const canShow = useMemo(() => user == null || isUserProductScience(user.email), [user])

  const refetchProjects = useCallback(async () => {
    setIsLoading(true)
    try {
      const allProjects = await api.getAllProjects()
      allProjects.sort((a, b) => b.dateUpdated.localeCompare(a.dateUpdated))
      setProjects(allProjects)
      localStorage.setItem(PROJECTS_KEY, JSON.stringify(allProjects))
    } catch (error) {
      console.error(error)
    } finally {
      setIsLoading(false)
    }
  }, [api])

  const fetchFlows = useCallback(
    async (projectUrlName: string, flowsMap: Record<string, DevFlowDto[]>) => {
      const flowDtoList = await api.getFlows({ projectUrlName })
      flowsMap[projectUrlName] = flowDtoList.map((flow) => ({
        projectUrlName,
        ...flow,
      }))
    },
    [api],
  )

  const fetchTracesExtra = useCallback(
    async (
      projectUrlName: string,
      flowProjectLocalId: string,
      tracesDtoList: TraceDto[],
    ): Promise<DevTraceDto[]> => {
      const devTracesList = []
      for (const traceDto of tracesDtoList) {
        const annotations =
          traceDto.processingState === TraceProcessingState.FINISHED &&
          traceDto.videoState === VideoProcessingStateDto.FINISHED
            ? await api.getAnnotations({
                projectUrlName,
                flowProjectLocalId,
                traceProjectLocalId: traceDto.projectLocalId,
              })
            : []
        devTracesList.push({
          projectUrlName,
          flowProjectLocalId,
          annotations,
          ...traceDto,
        })
      }
      return devTracesList
    },
    [api],
  )

  const fetchTraces = useCallback(
    async (
      projectUrlName: string,
      flowProjectLocalId: string,
      tracesMap: Record<string, DevTraceDto[]>,
    ) => {
      const tracesDtoList = await api.getFlowTraces({
        projectUrlName,
        flowProjectLocalId,
      })
      tracesMap[`${projectUrlName}::${flowProjectLocalId}`] = await fetchTracesExtra(
        projectUrlName,
        flowProjectLocalId,
        tracesDtoList,
      )
    },
    [api, fetchTracesExtra],
  )

  const refetchFlows = useCallback(async () => {
    setIsLoading(true)
    let projectPointer = 0
    const flowsMap = {}
    try {
      while (projectPointer < projects.length) {
        await fetchFlows(projects[projectPointer].urlName, flowsMap)
        projectPointer++
      }
    } catch (error) {
      console.error(error)
    } finally {
      setIsLoading(false)
    }
    setFlows(flowsMap)
    localStorage.setItem(FLOWS_KEY, JSON.stringify(flowsMap))
  }, [projects, fetchFlows])

  useEffect(() => {
    const savedProjectsData = localStorage.getItem(PROJECTS_KEY)
    if (savedProjectsData == null) {
      refetchProjects()
    } else {
      setProjects(JSON.parse(savedProjectsData))
    }
    const savedFlowsData = localStorage.getItem(FLOWS_KEY)
    if (savedFlowsData != null) {
      setFlows(JSON.parse(savedFlowsData))
    }
    const savedTracesData = localStorage.getItem(TRACES_KEY)
    if (savedTracesData != null) {
      setTraces(JSON.parse(savedTracesData))
    }
  }, [refetchProjects])

  const flowsList = useMemo(() => {
    return Object.values(flows)
      .flatMap((projectFlows) => projectFlows)
      .sort((a, b) => b.dateUpdated.localeCompare(a.dateUpdated))
  }, [flows])

  const flowsListTable = useMemo(() => flowsList.slice(0, 10), [flowsList])

  const refetchTraces = useCallback(async () => {
    setIsLoading(true)
    let flowPointer = 0
    const tracesMap = {}
    try {
      while (flowPointer < flowsList.length) {
        const curFlow = flowsList[flowPointer]
        await fetchTraces(curFlow.projectUrlName, String(curFlow.projectLocalId), tracesMap)
        flowPointer++
      }
    } catch (error) {
      console.error(error)
    } finally {
      setIsLoading(false)
    }
    setTraces(tracesMap)
    localStorage.setItem(TRACES_KEY, JSON.stringify(tracesMap))
  }, [flowsList, fetchTraces])

  const tracesList = useMemo(() => {
    return Object.values(traces)
      .flatMap((flowTraces) => flowTraces)
      .sort((a, b) => b.dateUpdated.localeCompare(a.dateUpdated))
      .filter(
        (trace) =>
          trace.processingState === TraceProcessingState.FINISHED &&
          trace.videoState === VideoProcessingStateDto.FINISHED &&
          trace.annotations.length,
      )
  }, [traces])

  const tracesTableList = useMemo(() => tracesList.slice(0, 10), [tracesList])

  if (user == null) {
    return null
  }

  if (!canShow) {
    return <Navigate to={PATH_ROOT} />
  }

  return (
    <div>
      <div className="bg-gray-900">
        <div className="mx-auto max-w-7xl">
          <div className="bg-gray-900 py-10">
            <div className="px-4 sm:px-6 lg:px-8">
              <div className="sm:flex sm:items-center">
                <div className="sm:flex-auto">
                  <h2 className="text-base font-semibold leading-6 text-white">Traces</h2>
                  <p className="mt-2 text-sm text-gray-300">
                    10 Filtered Traces (processingState === FINISHED && videoState === FINISHED &&
                    annotations.length {'>'} 0)
                  </p>
                </div>
                <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
                  {isLoading && <span>Loading...</span>}
                </div>
                <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
                  <button
                    type="button"
                    className="block rounded-md bg-electro px-3 py-2 text-center text-sm font-semibold text-white hover:bg-sky focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500 disabled:cursor-not-allowed disabled:bg-gray-service"
                    onClick={refetchTraces}
                    disabled={isLoading || flowsList.length === 0}
                  >
                    Refetch Traces
                  </button>
                </div>
              </div>
              <div className="mt-8 flow-root">
                <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                  <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                    <table className="min-w-full divide-y divide-gray-700">
                      <thead>
                        <tr>
                          <th className="px-3 py-3.5 text-left text-sm font-semibold text-white">
                            Name
                          </th>
                          <th className="px-3 py-3.5 text-left text-sm font-semibold text-white">
                            dateUpdated
                          </th>
                          <th className="px-3 py-3.5 text-left text-sm font-semibold text-white">
                            createdBy
                          </th>
                        </tr>
                      </thead>
                      <tbody className="divide-y divide-gray-800">
                        {tracesTableList.map((trace) => (
                          <tr key={`${trace.projectUrlName}::${trace.projectLocalId}`}>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-300">
                              <Link
                                to={generatePath(PATH_CHART_ROUTER, {
                                  projectUrlName: trace.projectUrlName,
                                  flowProjectLocalId: trace.flowProjectLocalId,
                                  traceProjectLocalId: trace.projectLocalId,
                                })}
                                className="underline"
                                target="_blank"
                              >
                                {trace.name}
                              </Link>
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-300">
                              {trace.dateUpdated}
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-300">
                              {trace.createdBy}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-gray-900">
        <div className="mx-auto max-w-7xl">
          <div className="bg-gray-900 py-10">
            <div className="px-4 sm:px-6 lg:px-8">
              <div className="sm:flex sm:items-center">
                <div className="sm:flex-auto">
                  <h2 className="text-base font-semibold leading-6 text-white">Flows</h2>
                  <p className="mt-2 text-sm text-gray-300">10 recent Flows</p>
                </div>
                <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
                  {isLoading && <span>Loading...</span>}
                </div>
                <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
                  <button
                    type="button"
                    className="block rounded-md bg-electro px-3 py-2 text-center text-sm font-semibold text-white hover:bg-sky focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500 disabled:cursor-not-allowed disabled:bg-gray-service"
                    onClick={refetchFlows}
                    disabled={isLoading}
                  >
                    Refetch Flows
                  </button>
                </div>
              </div>
              <div className="mt-8 flow-root">
                <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                  <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                    <table className="min-w-full divide-y divide-gray-700">
                      <thead>
                        <tr>
                          <th className="px-3 py-3.5 text-left text-sm font-semibold text-white">
                            Name
                          </th>
                          <th className="px-3 py-3.5 text-left text-sm font-semibold text-white">
                            dateUpdated
                          </th>
                          <th className="px-3 py-3.5 text-left text-sm font-semibold text-white">
                            Author
                          </th>
                        </tr>
                      </thead>
                      <tbody className="divide-y divide-gray-800">
                        {flowsListTable.map((flow) => (
                          <tr key={`${flow.projectUrlName}::${flow.projectLocalId}`}>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-300">
                              <Link
                                to={generatePath(PATH_FLOW, {
                                  projectUrlName: flow.projectUrlName,
                                  flowProjectLocalId: flow.projectLocalId,
                                })}
                                className="underline"
                                target="_blank"
                              >
                                {flow.name}
                              </Link>
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-300">
                              {flow.dateUpdated}
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-300">
                              {flow.author.name} {flow.author.lastName}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-gray-900">
        <div className="mx-auto max-w-7xl">
          <div className="bg-gray-900 py-10">
            <div className="px-4 sm:px-6 lg:px-8">
              <div className="sm:flex sm:items-center">
                <div className="sm:flex-auto">
                  <h2 className="text-base font-semibold leading-6 text-white">Projects</h2>
                  <p className="mt-2 text-sm text-gray-300">
                    All the projects sorted by dateUpdated (recent first)
                  </p>
                </div>
                <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
                  {isLoading && <span>Loading...</span>}
                </div>
                <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
                  <button
                    type="button"
                    className="block rounded-md bg-electro px-3 py-2 text-center text-sm font-semibold text-white hover:bg-sky focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500 disabled:cursor-not-allowed disabled:bg-gray-service"
                    onClick={refetchProjects}
                    disabled={isLoading}
                  >
                    Refetch Projects
                  </button>
                </div>
              </div>
              <div className="mt-8 flow-root">
                <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                  <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                    <table className="min-w-full divide-y divide-gray-700">
                      <thead>
                        <tr>
                          <th className="px-3 py-3.5 text-left text-sm font-semibold text-white">
                            Name
                          </th>
                          <th className="px-3 py-3.5 text-left text-sm font-semibold text-white">
                            URL Name
                          </th>
                          <th className="px-3 py-3.5 text-left text-sm font-semibold text-white">
                            dateUpdated
                          </th>
                        </tr>
                      </thead>
                      <tbody className="divide-y divide-gray-800">
                        {projects.map((project) => (
                          <tr key={project.id}>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-300">
                              {project.name}
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-300">
                              <Link
                                to={generatePath(PATH_FLOWS, { projectUrlName: project.urlName })}
                                className="underline"
                                target="_blank"
                              >
                                {project.urlName}
                              </Link>
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-300">
                              {project.dateUpdated}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
