import React from 'react'
import { observer } from 'mobx-react-lite'
import { PsChartStore } from 'components/ps-chart/PsChartStore'
import { nanoToMeasurementString } from 'components/ps-chart/utils/nanoToString'
import { useToaster } from 'hooks/useToaster'
import { useTranslation } from 'react-i18next'
import { getSliceTitleWithInvisibleSpaces } from 'components/ps-chart/utils/slice'
import { PlainActionsTooltip } from 'components/ActionTooltip'
import { Tooltip } from 'components/tooltip/Tooltip'
import { CardLine } from 'components/ps-chart/details-view/CardLine'
import { SliceStats } from 'components/ps-chart/models/Slice'

interface SlicesMeasureProps {
  psChartStore: PsChartStore
}

const SliceStatsSummary = ({
  summaryOccurrences,
  summaryDuration,
}: {
  summaryOccurrences: number
  summaryDuration: number
}) => {
  const { t } = useTranslation()
  const summaryDurationUnits = nanoToMeasurementString(summaryDuration)

  return (
    <div className="pl-[16px] pr-[10px] pb-[16px]">
      <div className="text-small font-semibold text-gray-normal tracking-wide leading-[140%] pb-[16px]">
        {t('psChart.details.measureSummary')}
      </div>
      <CardLine label={`${t('psChart.details.measureOccurrences')}:`}>
        {summaryOccurrences}
      </CardLine>
      <CardLine label={`${t('psChart.details.measureDuration')}:`}>{summaryDurationUnits}</CardLine>
    </div>
  )
}

const SliceStatsCard = ({ title, total, average, occurrences }: SliceStats) => {
  const toaster = useToaster()
  const { t } = useTranslation()
  const averageUnits = nanoToMeasurementString(average)
  const totalUnits = nanoToMeasurementString(total)
  const breakableTitle = getSliceTitleWithInvisibleSpaces(title)

  const handleCopyClick = (event: React.MouseEvent) => {
    event.preventDefault()
    navigator.clipboard.writeText(title).then(() => {
      toaster.info('psChart.details.sliceTitleCopiedToClipboard')
    })
  }

  return (
    <div className="relative text-small rounded bg-dark-dark1 px-[8px] pt-[8px] pb-[11px] mb-[10px]">
      <div className="text-small font-semibold tracking-wide leading-[140%] pb-[8px]">
        <Tooltip
          content={<PlainActionsTooltip title={t('psChart.details.sliceTitleHoverTip')} />}
          place="top"
        >
          <div className="flex font-bold cursor-pointer break-anywhere" onClick={handleCopyClick}>
            {breakableTitle}
          </div>
        </Tooltip>
      </div>
      <CardLine label={t('psChart.details.measureOccurrences')}>{occurrences}</CardLine>
      <CardLine label={t('psChart.details.measureAverage')}>{averageUnits}</CardLine>
      <CardLine label={t('psChart.details.measureTotal')}>{totalUnits}</CardLine>
    </div>
  )
}

export const SlicesMeasure = observer(function SliceConnections({
  psChartStore,
}: SlicesMeasureProps) {
  const { t } = useTranslation()

  const hasMeasureSlices = psChartStore.measurementSlices.length > 0

  const summaryOccurrences = hasMeasureSlices
    ? psChartStore.measurementSlices
        .map(({ occurrences }) => occurrences)
        .reduce((totalOccurrences, next) => totalOccurrences + next, 0)
    : 0

  const summaryDuration = hasMeasureSlices
    ? psChartStore.measurementSlices
        .map(({ total }) => total)
        .reduce((totalDuration, next) => totalDuration + next, 0)
    : 0

  return hasMeasureSlices ? (
    <div className="h-full w-full pt-[16px] overflow-y-scroll">
      <SliceStatsSummary
        summaryDuration={summaryDuration}
        summaryOccurrences={summaryOccurrences}
      />
      <div className="border-t border-gray-dark pl-[16px] pr-[10px] pt-[16px]">
        <div className="text-small font-semibold text-gray-normal tracking-wide leading-[140%] pb-[16px]">
          {t('psChart.details.measureSlices')}
        </div>
        {psChartStore.measurementSlices.map((slice, index) => (
          <SliceStatsCard key={index} {...slice} />
        ))}
      </div>
    </div>
  ) : (
    <div className="p-[24px] text-small font-medium text-gray-normal">
      {t('psChart.details.emptyMeasureSelect')}&nbsp;
      <span className="text-[11px] h-[16px] px-[4px] mx-[1px] text-dark-dark5 bg-gray-normal rounded-sm">
        {t('shortcuts.shift')}
      </span>
    </div>
  )
})
