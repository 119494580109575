import React, { useMemo } from 'react'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'

import { Icon } from 'components/Icon'
import { Input } from 'components/Input'
import { getTeamDomain } from 'utils/getTeamDomain'
import { ListBox } from 'components/dropdowns/ListBox'
import { OptionType } from 'components/dropdowns/models'

interface EmailDomainProps {
  domainValue?: string | null
  onDomainValueChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
  onDomainValueBlur?: () => void
  domainValueError?: string
  doWhitelistValue: boolean
  onDoWhitelistValueChange: (bool: boolean) => void
  readonly?: boolean
}

export const EmailDomain = ({
  domainValue,
  onDomainValueChange,
  onDomainValueBlur,
  domainValueError,
  doWhitelistValue,
  onDoWhitelistValueChange,
  readonly,
}: EmailDomainProps) => {
  const { t } = useTranslation()

  const hasValue = domainValue && domainValue.length > 0

  const doWhitelistOptions = useMemo<OptionType[]>(() => {
    return [
      {
        name: t('domainJoin.doWhitelist_true'),
        isSelect: doWhitelistValue,
      },
      {
        name: t('domainJoin.doWhitelist_false'),
        isSelect: !doWhitelistValue,
      },
    ]
  }, [doWhitelistValue, t])

  return (
    <>
      <div className={classNames('flex', !readonly && 'mb-[4px]', readonly && 'items-center')}>
        {readonly ? (
          domainValue ? (
            <span className="text-small tracking-wide mr-[12px]">{getTeamDomain(domainValue)}</span>
          ) : null
        ) : (
          <Input
            placeholder={t('teams.emailDomain.placeholder')}
            containerClassName="mb-0"
            className={classNames(hasValue && 'pl-[28px]')}
            value={domainValue || ''}
            onChange={onDomainValueChange}
            onBlur={onDomainValueBlur}
            error={domainValueError}
            inModal
          >
            {hasValue && (
              <span className="absolute z-[1] left-[16px] top-1/2 -translate-y-1/2 text-small tracking-wide text-gray-normal">
                @
              </span>
            )}
          </Input>
        )}
        <ListBox
          onSelect={(index: number) => onDoWhitelistValueChange(index === 0)}
          disabled={!domainValue}
          menuSections={[{ options: doWhitelistOptions }]}
          menuClass="border-[1px] border-dark-dark2 py-[2px] right-[10px]"
          buttonChildren={() => (
            <div className="flex items-center justify-between">
              {t('domainJoin.doWhitelist', { context: String(doWhitelistValue || false) })}
              <Icon icon="arrow-drop-d" className="text-icon relative left-[-4px]" />
            </div>
          )}
          buttonClass={(open) =>
            classNames(
              'flex items-center text-small tracking-wide transition font-medium disabled:text-gray-faded',
              open ? 'text-white' : 'text-gray-normal',
              !readonly && 'pl-[18px]',
            )
          }
          withSelect
        />
      </div>
      <div className="text-small tracking-wide text-gray-normal">{t('teams.emailDomain.text')}</div>
    </>
  )
}
