import React from 'react'

interface MeasureButtonProps {
  text: string
  onClick: () => void
}

export const MeasureButton = ({ text, onClick }: MeasureButtonProps) => {
  return (
    <button className="pr-1 underline" onClick={onClick}>
      {text}
    </button>
  )
}
