import React, { useCallback } from 'react'
import { ActionTooltip } from 'components/ActionTooltip'
import { observer } from 'mobx-react-lite'
import { Button, ButtonVariantEnum } from 'components/Button'

import { PsChartStore } from 'components/ps-chart/PsChartStore'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import { useToaster } from 'hooks/useToaster'

export const ShareViewAction = observer(function ShareViewAction({
  psChartStore,
}: {
  psChartStore: PsChartStore
}) {
  const location = useLocation()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const toaster = useToaster()

  const handleShareButton = useCallback(async () => {
    const { hash, pathname } = location
    const isForward = psChartStore.traceAnalyzeStore.isForwardConnectionsEnabled
    const { xStart, xEnd } = psChartStore.hState
    const { yStart } = psChartStore.vState

    // Add or update the parameter
    searchParams.set('linkType', isForward ? 'FRWD' : 'BKWD')
    searchParams.set('zoom', `${xStart}-${xEnd}-${yStart}`)

    // Use navigate to update the URL with the new search and preserve the current hash
    navigate({
      pathname,
      search: searchParams.toString(),
      hash,
    })

    const shareLink = `${window.location.origin}${location.pathname}${location.search}${location.hash}`

    try {
      await navigator.clipboard.writeText(shareLink)
      toaster.success('components.shareViewLink.onCopy')
    } catch (error) {
      toaster.error(error, 'components.shareViewLink.onCopyError')
    }
  }, [
    location,
    psChartStore.traceAnalyzeStore.isForwardConnectionsEnabled,
    psChartStore.hState,
    psChartStore.vState,
    searchParams,
    navigate,
    toaster,
  ])

  return (
    <ActionTooltip place="top" tooltipId="shareViewLink">
      <div className="flex items-center justify-center align-center px-3 h-[32px] bg-dark-dark3">
        <Button variant={ButtonVariantEnum.Text} onClick={handleShareButton}>
          Share
        </Button>
      </div>
    </ActionTooltip>
  )
})
