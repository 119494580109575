import { makeAutoObservable } from 'mobx'

export class SearchState {
  searchResults: ReadonlyArray<number> = []
  searchResultsSet: ReadonlySet<number> = new Set<number>()
  searchContextThreadId: number | null = null
  searchCurrentSliceId: number | null = null

  constructor() {
    makeAutoObservable(this)
  }

  setSearchResults = (results: number[]) => {
    this.searchResults = [...results]
    this.searchResultsSet = new Set<number>(results)
  }

  setSearchContextThreadId(threadId: number | null) {
    this.searchContextThreadId = threadId
  }

  setSearchCurrentSliceId(sliceId: number | null) {
    this.searchCurrentSliceId = sliceId
  }
}
