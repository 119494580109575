import { useTranslation } from 'react-i18next'

import { HavingTroubles } from './HavingTroubles'

export const GetHelpBanner = () => {
  const { t } = useTranslation()

  return (
    <HavingTroubles>
      <p className="mb-[5px] mt-[24px] text-[14px] text-white font-[400] leading-[21px] tracking-[2%]">
        {t('guidePage.exampleProblems')}
      </p>
      <p className="text-[14px] text-gray-normal font-[400] leading-[21px] tracking-[2%] mb-[5px]">
        {t('guidePage.submitRequests')}
      </p>
    </HavingTroubles>
  )
}
