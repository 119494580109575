import React from 'react'
import { RegressionStatus } from 'components/flows/ra/runs/runUtils'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'
import { Run } from 'components/regression-analysis-mvp/Run'

interface HeaderProps {
  run: Run
}

export const Header = ({ run }: HeaderProps) => {
  const { t } = useTranslation()
  return (
    <div>
      <p className="space-x-1">
        <span>{t('ra.flow.run.id')}</span>
        <span className="text-white">{run.id}</span>
      </p>
      <p className="space-x-1">
        <span>{t('ra.flow.settings.devices.label')}</span>
        <span className="text-white">{run.deviceName}</span>
      </p>
      <p className="space-x-1">
        <span>{t('ra.flow.run.status')}</span>
        <span className="text-white">{run.statusText}</span>
      </p>
      {run.hasComparison && (
        <p className="space-x-1">
          <span>{t('ra.flow.run.regression')}</span>
          <span
            className={classNames(
              run.regressionStatus === RegressionStatus.NOT_DETECTED
                ? 'text-white'
                : run.regressionStatus === RegressionStatus.SLOWDOWN
                ? 'text-state-bad'
                : 'text-state-good',
            )}
          >
            {run.regressionStatus === RegressionStatus.NOT_DETECTED
              ? t('ra.flow.run.notDetected')
              : t('ra.flow.run.detected')}
          </span>
        </p>
      )}
    </div>
  )
}
