import { useCallback, useState } from 'react'
import { generatePath, useNavigate } from 'react-router-dom'
import { useQueryClient } from 'react-query'
import { FieldErrors } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import * as yup from 'yup'
import { queryKeys } from 'hooks/useApiQuery'
import { useToaster } from 'hooks/useToaster'
import { Form, ValidationSchema, getFormErrorsArrayFromObject, useForm } from 'components/Form/Form'
import { Modal } from 'components/Modal'
import { ButtonTextColorVariantEnum } from 'components/Button'
import { useApi } from 'contexts/di-context'
import { LoadingSpinner } from 'components/LoadingSpinner'
import { showGotItToaster } from 'components/ToastGotIt'
import { TeamSelect } from 'components/traces/copyTrace/TeamSelect'
import { ProjectSelect } from 'components/traces/copyTrace/ProjectSelect'
import { FlowSelect } from 'components/traces/copyTrace/FlowSelect'
import { CopyTraceFormFields, FIELDS } from 'components/traces/copyTrace/models'

interface ModalProps {
  visible: boolean
  sourceTraceProjectLocalId: string
  traceName: string
  onClose: () => void
  flowProjectLocalId: string
  sourceProjectUrlName: string
}

export const CopyTrace = ({
  visible,
  onClose,
  sourceTraceProjectLocalId,
  traceName,
  flowProjectLocalId,
  sourceProjectUrlName,
}: ModalProps) => {
  const queryClient = useQueryClient()
  const api = useApi()
  const navigate = useNavigate()
  const toast = useToaster()
  const { t } = useTranslation()

  const [isLoading, setIsLoading] = useState(false)

  const formContext = useForm<CopyTraceFormFields>({
    validationSchema: copyTraceFormSchema(),
    formProps: {
      mode: 'all',
    },
  })

  const { reset, getValues, clearErrors } = formContext

  const handleClose = useCallback(() => {
    clearErrors()
    reset()
    onClose()
  }, [clearErrors, onClose, reset])

  const handleSubmit = useCallback(async () => {
    setIsLoading(true)

    const { project, flow } = getValues()

    await api
      .postCopyTrace(sourceProjectUrlName, sourceTraceProjectLocalId, {
        destinationProjectIdOrUrlName: project,
        destinationFlowProjectLocalId: Number.parseInt(flow),
        sourceFlowProjectLocalId: Number.parseInt(flowProjectLocalId),
      })
      .then(() => {
        queryClient.invalidateQueries(
          queryKeys.flowTraces({ projectUrlName: project, flowProjectLocalId: flow }),
        )

        const copiedTraceUrl = generatePath('/projects/:projectUrlName/flows/:flowId/traces', {
          projectUrlName: project,
          flowId: flow,
        })

        const navigateToTrace = () => navigate(copiedTraceUrl)

        showGotItToaster(
          t('traces.copyTraceModal.success'),
          undefined,
          navigateToTrace,
          t('traces.copyTraceModal.goToCopiedTrace'),
        )
      })
      .catch((error) => {
        setIsLoading(false)
        toast.error(t('traces.copyTraceModal.error', { traceName, flow }), error?.message)
      })
      .finally(() => {
        clearErrors()
        reset()
        setIsLoading(false)
        onClose()
      })
  }, [
    getValues,
    api,
    sourceProjectUrlName,
    sourceTraceProjectLocalId,
    flowProjectLocalId,
    queryClient,
    t,
    traceName,
    navigate,
    toast,
    clearErrors,
    reset,
    onClose,
  ])

  const handleSubmitInvalid = useCallback(
    (errors: FieldErrors<CopyTraceFormFields>) => {
      const errorMessages = getFormErrorsArrayFromObject(errors)
      errorMessages.forEach((errorMsg) => {
        toast.error(errorMsg)
      })
    },
    [toast],
  )

  const isFormDisabled = !formContext.watch(FIELDS.FLOW)

  return (
    <Modal
      title={t('traces.copyTraceModal.title')}
      isOpen={visible}
      onClose={handleClose}
      actionButton={{
        children: (
          <div className="flex items-center">
            {t('traces.copyTraceModal.title')}
            {isLoading && <LoadingSpinner size={12} className="mx-2" />}
          </div>
        ),
        textColorVariant: ButtonTextColorVariantEnum.Primary,
        disabled: isFormDisabled || isLoading,
        onClick: handleSubmit,
      }}
    >
      <h2 className="text-gray-normal text-[14px] mb-[20px]">
        {t('traces.copyTraceModal.selectDestination', { traceName })}
      </h2>
      <Form<CopyTraceFormFields>
        formContext={formContext}
        onSubmit={handleSubmit}
        onSubmitInvalid={handleSubmitInvalid}
      >
        <TeamSelect formContext={formContext} />
        <ProjectSelect formContext={formContext} />
        <FlowSelect formContext={formContext} />
      </Form>
    </Modal>
  )
}

export function copyTraceFormSchema() {
  return yup.object<ValidationSchema<CopyTraceFormFields>>({
    team: yup.string().required('traces.copyTraceModal.teamRequired'),
    project: yup.string().required('traces.copyTraceModal.projectRequired'),
    flow: yup.string().required('traces.copyTraceModal.flowRequired'),
  })
}
