/**
 * The maximum is exclusive and the minimum is inclusive
 * @param min
 * @param max
 */
export function getRandomInt(min: number, max: number): number {
  min = Math.ceil(min)
  max = Math.floor(max)
  return Math.floor(Math.random() * (max - min) + min)
}

/**
 * Getting a random number between two values
 * @param min
 * @param max
 */
export function getRandomArbitrary(min: number, max: number) {
  return Math.random() * (max - min) + min
}

export function getRandomString(
  length: number,
  characters = 'abcdefghijklmnopqrstuvwxyz0123456789. ',
): string {
  let result = ''
  const charactersLength = characters.length
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength))
  }
  return result
}
