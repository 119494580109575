import { AnnotationDto } from 'api/models'
import {
  AnnotationIdAndType,
  AnnotationsStore,
  PinType,
} from 'components/ps-chart/stores/AnnotationsStore'
import { useToaster } from 'hooks/useToaster'
import { useTranslation } from 'react-i18next'
import { ChangeEvent, FormEvent, useCallback, useEffect, useMemo, useState } from 'react'
import { Input } from 'components/annotations/details-view/Input'
import { CheckboxV2 } from 'components/Checkbox'
import { AnnotationFormButtons } from 'components/annotations/details-view/AnnotationFormButtons'
import { annotationsSettings } from 'components/ps-chart/local-timeline/annotations/AnnotationsSettings'

interface EditAnnotationProps {
  annotation: AnnotationDto
  type: PinType
  annotationStore: AnnotationsStore
}

/**
 * @description Edit a mapped annotation
 */
export const AnnotationListItemEdit = ({
  annotation,
  type,
  annotationStore,
}: EditAnnotationProps) => {
  const toaster = useToaster()
  const { t } = useTranslation()

  const idAndType: AnnotationIdAndType = useMemo(
    () => ({ id: annotation.id, type }),
    [annotation.id, type],
  )
  const timeString = AnnotationsStore.getPinTimeString(annotation, type)

  const [title, setTitle] = useState('')
  const [description, setDescription] = useState('')

  const [oldTitle, setOldTitle] = useState('')
  const [oldDescription, setOldDescription] = useState('')

  const [hasDelay, setHasDelay] = useState(false)
  const [oldDelay, setOldDelay] = useState(false)

  useEffect(
    () => {
      const tempTitle = AnnotationsStore.getPinTitle(annotation, type)
      setTitle(tempTitle)
      setOldTitle(tempTitle)
      const tempDescr = AnnotationsStore.getPinDescription(annotation, type)
      setDescription(tempDescr)
      setOldDescription(tempDescr)
      const tempDelay = AnnotationsStore.hasDelay(annotation.delay)
      setHasDelay(tempDelay)
      setOldDelay(tempDelay)
    },
    // eslint-disable-next-line
    [],
  )

  const preventDefaultSubmit = useCallback(
    (e: FormEvent<HTMLFormElement>) => e.preventDefault(),
    [],
  )

  const changeTitle = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setTitle(event.target.value)
      annotationStore.updateTitleAndDescriptionLocally(
        { id: annotation.id, type },
        event.target.value,
        description,
      )
    },
    [annotation.id, annotationStore, description, type],
  )

  const changeDescription = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setDescription(event.target.value)
      annotationStore.updateTitleAndDescriptionLocally(idAndType, title, event.target.value)
    },
    [annotationStore, idAndType, title],
  )

  const changeDelay = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const newDelay = event.target.checked
      setHasDelay(newDelay)
      annotationStore.updateDelayLocally(annotation.id, AnnotationsStore.delay(newDelay))
    },
    [annotation.id, annotationStore],
  )

  const save = useCallback(() => {
    if (!title) {
      const errorString =
        type === PinType.ACTION
          ? 'annotations.validation.specifyActionTitle'
          : 'annotations.validation.specifyReactionTitle'
      toaster.error(t(errorString), 'annotation.error.update')
      return
    }

    if (type === PinType.REACTION) {
      annotationStore.updateDelayLocally(idAndType.id, AnnotationsStore.delay(hasDelay))
    }
    annotationStore
      .updateTitleAndDescription(idAndType, title, description)
      .catch((reason) => toaster.error(reason, 'annotation.error.update'))
    annotationStore.setEditedId(null)
  }, [annotationStore, description, hasDelay, idAndType, t, title, toaster, type])

  const cancel = useCallback(() => {
    annotationStore.setEditedId(null)
    annotationStore
      .updateTitleAndDescription(idAndType, oldTitle, oldDescription)
      .then((updatedAnnotation) => {
        if (type === PinType.REACTION) {
          return annotationStore.updateDelay(idAndType.id, AnnotationsStore.delay(oldDelay))
        } else {
          return Promise.resolve(updatedAnnotation)
        }
      })
      .catch((reason) => toaster.error(reason, 'annotation.error.update'))
  }, [annotationStore, idAndType, oldDelay, oldDescription, oldTitle, toaster, type])

  const listItemTitle =
    PinType.REACTION === type ? t('annotations.edit.reaction') : t('annotations.edit.action')

  return (
    <form onSubmit={preventDefaultSubmit}>
      <div className="px-[16px] pt-[12px]">
        <h3 className="text-normal font-medium tracking-widec text-[14px] h-[19x]">
          {listItemTitle}
        </h3>
        <div className="text-small tracking-wide text-gray-normal mt-[12px]">
          <Input
            placeholder={t('annotations.edit.title')}
            value={title}
            onChange={changeTitle}
            autoFocus
            maxLength={annotationsSettings.titleTextLength}
          />
        </div>
        <div className="text-small tracking-wide text-gray-normal mt-[16px]">
          <Input
            placeholder={t('annotations.edit.description')}
            value={description}
            onChange={changeDescription}
            maxLength={annotationsSettings.descriptionTextLength}
          />
        </div>
        {type === PinType.REACTION && (
          <CheckboxV2
            name={'reacted-with-hasDelay'}
            className="border-b-gray-normal"
            checked={hasDelay}
            onChange={changeDelay}
          >
            {t('annotations.create.reactedWithDelay')}
          </CheckboxV2>
        )}
        <div className="my-[16px] text-right text-gray-faded">{timeString}</div>
      </div>
      <AnnotationFormButtons onSave={save} onCancel={cancel} />
    </form>
  )
}
