import { useFlowComputation } from 'hooks/useApiQuery'
import { FlowComputationReq } from 'api/models'
import { Button } from 'components/Button'
import { LoadingSpinner } from 'components/LoadingSpinner'
import { useState } from 'react'
import { useApi } from 'contexts/di-context'
import { Trans, useTranslation } from 'react-i18next'
import { useToaster } from 'hooks/useToaster'
import dayjs from 'dayjs'

interface ComputationStatusProps {
  request: FlowComputationReq
}

export const FlowComputation = (props: ComputationStatusProps) => {
  const [pending, setPending] = useState(false)
  const { request } = props
  const { data, refetch, isLoading } = useFlowComputation(request)
  const { postSubmitFlowToProcessing } = useApi()
  const { t } = useTranslation()
  const toaster = useToaster()

  // const showComputationButton = !data || data.computationStatus !== ComputationStatus.IN_PROGRESS
  // Keep it always true for testing purpose
  const showComputationButton = true

  const handleStartComputation = () => {
    setPending(true)
    postSubmitFlowToProcessing(request)
      .then(() => refetch())
      .catch((error) => {
        toaster.error(error)
      })
      .finally(() => {
        setPending(false)
      })
  }

  if (isLoading) {
    return null
  }

  return (
    <div className="bg-dark-dark1 rounded-sm mb-[24px] p-[12px]">
      <div className="mb-2 text-normal">{t('flowComputation.title')}</div>
      {data ? (
        <>
          <div className="text-small text-gray-normal">
            <Trans
              i18nKey="flowComputation.status"
              values={{ status: data.computationStatus }}
              components={{
                comp: <span className="text-white" />,
              }}
            />
          </div>
          <div className="text-small text-gray-normal">
            <Trans
              i18nKey="flowComputation.id"
              values={{ id: data.id }}
              components={{
                comp: <span className="text-white" />,
              }}
            />
          </div>
          <div className="text-small text-gray-normal">
            <Trans
              i18nKey="flowComputation.startTime"
              values={{ time: dayjs(data.timeStart).format('MM-DD-YYYY HH:mm') }}
              components={{
                comp: <span className="text-white" />,
              }}
            />
          </div>
          {data.timeEnd && (
            <div className="text-small text-gray-normal">
              <Trans
                i18nKey="flowComputation.endTime"
                values={{ time: dayjs(data.timeEnd).format('MM-DD-YYYY HH:mm') }}
                components={{
                  comp: <span className="text-white" />,
                }}
              />
            </div>
          )}
        </>
      ) : (
        <div className="text-small text-gray-normal">{t('flowComputation.noComputations')}</div>
      )}
      {showComputationButton && (
        <Button
          className="mt-4 w-full"
          onClick={handleStartComputation}
          disabled={pending}
          isSmall={true}
        >
          {pending ? (
            <span className="flex w-full justify-center">
              <LoadingSpinner size={18} />
            </span>
          ) : (
            t('flowComputation.runButton')
          )}
        </Button>
      )}
    </div>
  )
}
