import { useState, useRef } from 'react'
import { observer } from 'mobx-react-lite'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { queryKeys, useFlowTracesQuery } from 'hooks/useApiQuery'

import { TraceProcessingState, type Trace, type Traces } from 'api/models'

import { useApi, useAnalytics } from 'contexts/di-context'
import { useQueryClient } from 'react-query'
import { getTraceUrl } from 'pages/lite/LitePsChartPage'
import cn from 'classnames'
import { LITE_EVENTS } from 'components/lite/config'

import { LoadingSpinner } from 'components/LoadingSpinner'

type Props = {
  projectUrlName: string
  flowProjectLocalId: string
}

export const LiteUploader = observer(function LiteUploader({
  projectUrlName,
  flowProjectLocalId,
}: Props) {
  const { t } = useTranslation()
  const analytics = useAnalytics()

  const { data: traces } = useFlowTracesQuery({ projectUrlName, flowProjectLocalId })

  const [uploadedTrace, setUploadedTrace] = useState<Trace>()
  const [videoUploaded, setVideoUploaded] = useState(false)
  const [videoUploading, setVideoUploading] = useState(false)

  const api = useApi()
  const queryClient = useQueryClient()

  const uploadTrace = async (file: File) => {
    const { trace, uploadRequest } = await api.postTraceEntity(
      {
        projectIdOrUrlName: projectUrlName,
        flowProjectLocalId: flowProjectLocalId,
      },
      { traceName: file.name, sizeBytes: file.size },
      {},
    )
    setUploadedTrace(trace)
    await api.uploadTraceToObjectStorage(
      file,
      uploadRequest,
      // TODO: handleUploadProgress,
    )

    analytics.track(LITE_EVENTS.UPLOAD_TRACE)

    await api.postTraceStartProcessing({
      projectIdOrUrlName: projectUrlName,
      flowProjectLocalId: flowProjectLocalId,
      traceProjectLocalId: trace.projectLocalId,
    })

    queryClient.setQueryData<Traces | undefined>(
      queryKeys.flowTraces({ projectUrlName, flowProjectLocalId }),
      (existingTraces = []) => [
        ...existingTraces,
        {
          ...trace,
          processingState: TraceProcessingState.IN_PROGRESS,
        } as Trace,
      ],
    )

    // TODO: handle errors
  }

  const uploadVideo = async (file: File) => {
    if (!uploadedTrace) {
      return
    }
    const { projectLocalId: traceProjectLocalId } = uploadedTrace

    setVideoUploading(true)

    const formData = new FormData()
    formData.append('file', file)

    await api.postTraceVideo({ projectUrlName, traceProjectLocalId }, formData, () => {})
    setVideoUploaded(true)

    analytics.track(LITE_EVENTS.UPLOAD_VIDEO)

    // TODO: handle errors
  }

  const trace =
    uploadedTrace && traces
      ? traces.find(({ projectLocalId }) => projectLocalId === uploadedTrace.projectLocalId)
      : undefined

  return (
    <div className="block leading-tighter text-header-small">
      <div className="mb-16">
        <h2 className="text-header-big font-semibold mb-4 z-20 relative">
          {t('lite.steps.step2')}
        </h2>
        {uploadedTrace ? (
          <div>
            <div className="my-2">
              {trace?.processingState === 'FINISHED' ? (
                <>
                  <p>{t('lite.uploader.traceIsReady')}</p>
                </>
              ) : trace?.name ? (
                <div className="flex">
                  <LoadingSpinner size={22} className="inline-block mr-3" />
                  <div>
                    <p>{t('lite.uploader.traceIsProcessing')}</p>
                    {!videoUploaded && (
                      <p className="absolute">{t('lite.uploader.canUploadVideo')}</p>
                    )}
                  </div>
                </div>
              ) : (
                <div className="flex">
                  <LoadingSpinner size={22} className="inline-block mr-3" />
                  <p>{t('lite.uploader.traceIsUploading')}</p>
                </div>
              )}
            </div>
          </div>
        ) : (
          <FileField onUpload={uploadTrace}>{t('lite.uploader.uploadTrace')}</FileField>
        )}
      </div>
      <div className="mb-16">
        <h2 className="text-header-big font-semibold mb-4 z-20 relative">
          {t('lite.steps.step3')}
        </h2>
        <div>
          {videoUploaded ? (
            <div>{t('lite.uploader.videoUploaded')}</div>
          ) : videoUploading ? (
            <div className="flex">
              <LoadingSpinner size={22} className="inline-block mt-1 mr-3" />
              <p>{t('lite.uploader.videoUploading')}</p>
            </div>
          ) : (
            <FileField disabled={!trace} onUpload={uploadVideo}>
              {t('lite.uploader.uploadVideo')}
            </FileField>
          )}
        </div>
      </div>
      {trace?.processingState === 'FINISHED' && (
        <>
          <p className="mb-2">
            <span className="sm:block hidden">{t('lite.uploader.doneMobile')}</span>
            <span className="sm:hidden block">{t('lite.uploader.done')}</span>
          </p>
          <Link
            to={getTraceUrl(trace.projectLocalId)}
            className="cursor-pointer underline hover:text-electro text-header-small"
          >
            {trace.name}
          </Link>
        </>
      )}
    </div>
  )
})

export function FileField({
  disabled = false,
  onUpload,
  acceptType = '*/*',
  children,
}: {
  disabled?: boolean
  onUpload: (file: File) => void
  acceptType?: string
  children: React.ReactNode
}) {
  const { t } = useTranslation()
  const [drag, setDrag] = useState(false)
  const dragCounterRef = useRef<number>(0)

  const handleDragEnter = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault()
    event.stopPropagation()
    dragCounterRef.current++
    if (event.dataTransfer.items.length > 0) {
      setDrag(true)
    }
  }

  const handleDragLeave = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault()
    event.stopPropagation()
    dragCounterRef.current--
    if (dragCounterRef.current === 0) {
      setDrag(false)
    }
  }

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault()
    event.stopPropagation()
  }

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault()
    event.stopPropagation()
    const droppedFile = event.dataTransfer.files[0]

    let acceptFile = false
    if (acceptType === '*/*') {
      acceptFile = true
    } else if (droppedFile.type) {
      const [type, subtype] = acceptType.split('/')
      const [fileType, fileSubtype] = droppedFile.type.split('/')
      if (type === fileType && (subtype === fileSubtype || subtype === '*')) {
        acceptFile = true
      }
    }
    if (droppedFile && acceptFile) {
      onUpload(droppedFile)
    }
    setDrag(false)
    dragCounterRef.current = 0
    event.dataTransfer.clearData()
  }

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = (event.target.files ?? [])[0]
    if (file) {
      onUpload((event.target.files ?? [])[0])
    }
  }

  return (
    <div className="flex">
      <div
        onDragOver={handleDragOver}
        onDragEnter={handleDragEnter}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
        className="flex items-center"
      >
        <label
          tabIndex={disabled ? undefined : 0}
          className={cn(
            'rounded-full text-white px-6 py-2 text-header-small-v2 block',
            'focus-visible:border-white focus-visible:outline-electro border',
            disabled
              ? 'bg-gray-normal border-gray-normal'
              : 'bg-black border-black cursor-pointer hover:opacity-80',
            drag && 'border-white outline-electro outline',
          )}
        >
          {children}
          <input
            type="file"
            accept={acceptType}
            hidden={true}
            onChange={onChange}
            disabled={disabled}
          />
        </label>
        {!disabled && (
          <span className="text-gray-normal text-big pl-3 hidden sm:block">
            {t('lite.uploader.drop')}
          </span>
        )}
      </div>
    </div>
  )
}
