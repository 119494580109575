import { useMemo, useEffect } from 'react'
import { observer } from 'mobx-react-lite'

import { ChartPageParams } from 'api/models'
import { useChartStores } from 'hooks/useChartStores'
import { ModuleProgressCircle } from 'components/ps-chart/ModuleProgressCircle'
import { useGlobalLiveDemoQuery } from 'hooks/useApiQuery'

import { PsChart } from 'components/ps-chart/PsChart'

export const PATH_LIVE_DEMO = '/live-demo'

export const LiveDemo = () => {
  const { data, isLoading } = useGlobalLiveDemoQuery()

  const chartPageParams = useMemo<ChartPageParams | null>(() => {
    if (data) {
      return {
        projectUrlName: data.projectUrlName,
        flowProjectLocalId: String(data.flowId),
        traceProjectLocalId: String(data.traceId),
      }
    }
    return null
  }, [data])

  if (chartPageParams) {
    return <LiveDemoPageContent chartPageParams={chartPageParams} />
  }

  if (isLoading) {
    return <PageLoader />
  }

  // TODO: handle errors
  return <div>Error</div>
}

export const LiveDemoPageContent = observer(function LiveDemoPageWithChartParams({
  chartPageParams,
}: {
  chartPageParams: ChartPageParams
}) {
  const { psChartStore, chartDataStore, error } = useChartStores({
    isReadOnlyProject: true,
    chartPageParams,
    isRightHidden: true,
  })
  useEffect(() => {
    psChartStore.traceAnalyzeStore.showOnlyActiveLevelsMode = true
  }, [psChartStore])

  if (error) {
    // TODO: handle errors
    return <div>Error</div>
  }

  if (chartDataStore.isLoading) {
    return <PageLoader />
  }

  return (
    <div className="h-[100%] flex flex-col">
      <PsChart psChartStore={psChartStore} />
    </div>
  )
})

const PageLoader = () => (
  <main className="flex flex-grow relative">
    <ModuleProgressCircle />
  </main>
)
