import { createContext, useContext } from 'react'
import { makeAutoObservable } from 'mobx'

class CodeTabsLanguage {
  public language?: string | null

  constructor() {
    makeAutoObservable(this)
  }

  public setLanguage(newLanguage?: string | null) {
    this.language = newLanguage
  }
}

const CodeTabsLanguageContext = createContext<CodeTabsLanguage>(new CodeTabsLanguage())

export const CodeTabsLanguageProvider = ({ children }: { children: React.ReactNode }) => (
  <CodeTabsLanguageContext.Provider value={new CodeTabsLanguage()}>
    {children}
  </CodeTabsLanguageContext.Provider>
)

export const useCodeTabsLanguage = () => useContext(CodeTabsLanguageContext)
