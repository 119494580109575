import { useTranslation } from 'react-i18next'

import {
  AnnotationIdAndType,
  AnnotationsStore,
  PinType,
} from 'components/ps-chart/stores/AnnotationsStore'
import { AnnotationDto } from 'api/models'
import { Input } from 'components/annotations/details-view/Input'
import { Button, ButtonTextColorVariantEnum, ButtonVariantEnum } from 'components/Button'
import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react'
import { CheckboxV2 } from 'components/Checkbox'
import { ActionTooltip } from 'components/ActionTooltip'
import { annotationsSettings } from 'components/ps-chart/local-timeline/annotations/AnnotationsSettings'

interface AnnotationPinProps {
  annotation: AnnotationDto
  type: PinType
  onUpdateTitleAndDescription: (
    idAndType: AnnotationIdAndType,
    title: string,
    description: string,
  ) => void
  onPlaceOnTimeline: (idAndType: AnnotationIdAndType) => void
  onChangeDelay: (annotationId: number, delay: boolean) => void
}

export const AnnotationPin = (props: AnnotationPinProps) => {
  const { annotation, type, onUpdateTitleAndDescription, onPlaceOnTimeline, onChangeDelay } = props
  const { t } = useTranslation()

  const idAndType: AnnotationIdAndType = useMemo(
    () => ({ id: annotation.id, type: type }),
    [annotation.id, type],
  )
  const isBoundToTimeline = AnnotationsStore.isBoundToTimeline(annotation, type)

  const [title, setTitle] = useState('')
  const [description, setDescription] = useState('')

  useEffect(() => {
    setTitle(AnnotationsStore.getPinTitle(annotation, type))
    setDescription(AnnotationsStore.getPinDescription(annotation, type))
  }, [annotation, type])

  const changeTitle = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      onUpdateTitleAndDescription(idAndType, event.target.value, description)
    },
    [description, idAndType, onUpdateTitleAndDescription],
  )

  const changeDescription = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      onUpdateTitleAndDescription(idAndType, title, event.target.value)
    },
    [idAndType, onUpdateTitleAndDescription, title],
  )

  const changeDelay = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const newDelay = event.target.checked
      onChangeDelay(idAndType.id, newDelay)
    },
    [idAndType, onChangeDelay],
  )

  const handleSubmit = useCallback((event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
  }, [])

  const createAnnotationTitle =
    type === PinType.ACTION
      ? t('annotations.create.createActionTitle')
      : t('annotations.create.createReactionTitle')

  const createAnnotationDescription =
    type === PinType.ACTION
      ? t('annotations.create.createActionCTA')
      : t('annotations.create.createReactionCTA')

  const topBorder = type === PinType.REACTION ? 'border-t-gray-iconStatus border-t-[1px]' : ''

  return (
    <form onSubmit={handleSubmit}>
      <fieldset className={`${topBorder} p-[16px] bg-dark-default`}>
        <h3 className="text-normal font-medium tracking-widec text-[14px]">
          {createAnnotationTitle}
        </h3>
        <p className="text-small tracking-wide text-gray-normal mt-[4px] text-[12px] ">
          {createAnnotationDescription}
        </p>
        <div className="text-small tracking-wide text-gray-normal mt-[12px]">
          <Input
            placeholder={t('annotations.create.tag')}
            value={title}
            onChange={changeTitle}
            autoFocus={type === PinType.ACTION}
            maxLength={annotationsSettings.titleTextLength}
          />
          <legend className="text-mini tracking-wide text-gray-normal mt-[4px]">
            {t('annotations.create.addReactionExample')}
          </legend>
        </div>
        <div className="text-small tracking-wide text-gray-normal mt-[12px]">
          <Input
            placeholder={t('annotations.create.description')}
            value={description}
            onChange={changeDescription}
            maxLength={annotationsSettings.descriptionTextLength}
          />
        </div>

        {idAndType.type === PinType.REACTION && (
          <div>
            <ActionTooltip place="bottom" text={`${t('annotations.create.checkboxHint')}`}>
              <div>
                <CheckboxV2
                  name={'reacted-with-delay'}
                  className="border-b-gray-normal"
                  checked={AnnotationsStore.hasDelay(annotation.delay)}
                  onChange={changeDelay}
                >
                  {t('annotations.create.reactedWithDelay')}
                </CheckboxV2>
              </div>
            </ActionTooltip>
          </div>
        )}

        {!isBoundToTimeline ? (
          <div className="flex w-full items-center justify-center h-[20px] flex-col mt-[24px]">
            <Button
              variant={ButtonVariantEnum.Text}
              textColorVariant={ButtonTextColorVariantEnum.Primary}
              isSmall={false}
              withIcon={false}
              setFontSize={12}
              onClick={() => onPlaceOnTimeline(idAndType)}
            >
              {t('annotations.create.placeOnTimeline')}
            </Button>
          </div>
        ) : (
          <div className="mt-[16px] text-gray-faded text-[12px] text-right">
            {AnnotationsStore.getPinTimeString(annotation, type)}
          </div>
        )}
      </fieldset>
    </form>
  )
}
