import { useProjectsSummaryQuery } from 'hooks/useApiQuery'
import { useTranslation } from 'react-i18next'
import { SelectField } from 'components/traces/copyTrace/SelectField'
import { getProjectMenuOptions } from 'components/traces/copyTrace/logic'
import { FIELDS, MenuProps } from 'components/traces/copyTrace/models'
import { useCallback } from 'react'

export const ProjectSelect = ({ formContext }: MenuProps) => {
  const { t } = useTranslation()
  const team = formContext.watch(FIELDS.TEAM)
  const { getValues, setValue } = formContext

  const { data, isError } = useProjectsSummaryQuery({ teamUrlName: team })
  const projectsListData = getProjectMenuOptions(data)
  const isDisabled = !projectsListData || projectsListData.length === 0

  const projectFormValue = getValues('project')
  const projectOnChange = useCallback(
    (value: string | string[]) => {
      setValue('project', value as string)
      setValue('flow', '')
    },
    [setValue],
  )

  return (
    <SelectField
      isDisabled={isDisabled}
      listItems={projectsListData}
      placeholder={t('traces.copyTraceModal.selectProject')}
      label={t('traces.copyTraceModal.project')}
      isError={isError}
      value={projectFormValue}
      onChange={projectOnChange}
    />
  )
}
