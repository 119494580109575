type Direction = 'x' | 'y'

export class WheelBlocker {
  private readonly direction: Direction
  private unblockedFromStart = false
  private changeOtherDirectionFromStart = 0
  private changeCurrentDirectionFromStart = 0
  private wheelEndTimeout?: ReturnType<typeof setTimeout>
  private blocked = false

  constructor(direction: Direction) {
    this.direction = direction
  }

  public handleEvent = (event: WheelEvent, action: () => void) => {
    if (this.wheelEndTimeout) {
      clearTimeout(this.wheelEndTimeout)
    }
    const absDeltaY = Math.round(Math.abs(event.deltaY) / 2)
    const absDeltaX = Math.round(Math.abs(event.deltaX))

    this.changeOtherDirectionFromStart += this.direction === 'x' ? event.deltaY : event.deltaX
    this.changeCurrentDirectionFromStart += this.direction === 'x' ? event.deltaX : event.deltaY

    if (
      !this.unblockedFromStart &&
      (this.direction === 'x' ? absDeltaX > absDeltaY : absDeltaY > absDeltaX)
    ) {
      this.blocked = true
    } else if (
      Math.abs(this.changeOtherDirectionFromStart) > 100 ||
      Math.abs(this.changeCurrentDirectionFromStart) > 100
    ) {
      this.unblockedFromStart = true
      this.blocked = false
    }

    if (!this.blocked && action) {
      action()
    }

    this.wheelEndTimeout = setTimeout(() => {
      this.blocked = false
      this.unblockedFromStart = false
      this.changeOtherDirectionFromStart = 0
      this.changeCurrentDirectionFromStart = 0
    }, 50)
  }
}
