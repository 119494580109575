import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useToaster } from 'hooks/useToaster'
import * as yup from 'yup'
import { Form, useForm, ValidationSchema } from 'components/Form/Form'
import { useAuth } from 'contexts/auth-context'
import { useAnalytics } from 'contexts/di-context'
import { usePostHog } from 'posthog-js/react'
import { LITE_EVENTS } from 'components/lite/config'

export const RequestPsLiteForm = ({}) => {
  const [completed, setCompleted] = useState(false)
  const { t } = useTranslation()
  const toaster = useToaster()
  const { requestPsLite } = useAuth()
  const formContext = useForm<SignUpFormValues>({ validationSchema: signUpSchema })
  const analytics = useAnalytics()
  const posthog = usePostHog()

  const handleSubmit = useCallback(
    (values: SignUpFormValues) => {
      requestPsLite(values)
        .then(() => {
          setCompleted(true)
          analytics?.track(LITE_EVENTS.REQUEST_LITE, { email: values.email })
          posthog?.identify(values.email, {
            email: values.email,
            isLite: true,
          })
        })
        .catch((error) => toaster.error(error))
    },
    [requestPsLite, toaster, analytics, posthog],
  )

  const handleSubmitInvalid = useCallback(() => {
    toaster.error('auth.freeTrial.signUp.errors.checkFields')
  }, [toaster])

  if (completed) {
    return (
      <div>
        <p className="text-normal">{t('lite.signUp.checkEmail')}</p>
      </div>
    )
  }

  return (
    <div>
      <div className="mx-auto">
        <Form<SignUpFormValues>
          formContext={formContext}
          onSubmit={handleSubmit}
          onSubmitInvalid={handleSubmitInvalid}
        >
          <input
            {...formContext.register('email', { required: true })}
            className="rounded-full w-full px-5"
            type="email"
            autoComplete="email"
            placeholder={t('auth.common.email')}
          />
          <button
            type="submit"
            className="rounded-full my-3 bg-black text-white px-6 py-2 focus-visible:outline-electro hover:opacity-80 disabled:opacity-20 focus-visible:border-white border border-black"
            disabled={formContext.formState.isValidating || !formContext.formState.isValid}
          >
            <span className="text-header-small-v2">{t('lite.signUp.createAccount')}</span>
          </button>
        </Form>
      </div>
    </div>
  )
}

interface SignUpFormValues {
  email: string
}

export const signUpSchema = yup.object<ValidationSchema<SignUpFormValues>>({
  email: yup.string().email().required(),
})
