import React, { useContext, useMemo } from 'react'

import { Layout } from 'components/Layout'
import { PsChart } from 'components/ps-chart/PsChart'
import {
  AnnotationsFeatureState,
  PsChartFeatures,
  psChartStoreContext,
} from 'components/ps-chart/PsChartStore'
import { useNavigationRules } from 'hooks/useNavigationRules'
import { BaseHeader } from 'components/header/BaseHeader'
import { PageTitle } from 'components/header/PageTitle'
import { Navbar } from 'components/Navbar'
import { TracePageBreadcrumbs } from 'components/breadcrumbs/TracePageBreadcrumbs'

export const PATH_PROCESSES_FOLDER = 'processes-x-annotations'
export const PATH_PROCESSES = `/projects/:projectUrlName/flows/:flowProjectLocalId/traces/:traceProjectLocalId/${PATH_PROCESSES_FOLDER}`

const pxaChartFeatures: PsChartFeatures = {
  flags: false,
  measurementTool: false,
  annotations: { enabled: true, draggable: true } as AnnotationsFeatureState,
}

export const PxAPage = () => {
  const psChartStore = useContext(psChartStoreContext)!
  psChartStore.setChartFeatures(pxaChartFeatures)

  useNavigationRules()

  const header = useMemo(
    () => (
      <BaseHeader
        leftSideContent={<TracePageBreadcrumbs />}
        centerContent={<PageTitle titleKey="pxa" />}
      />
    ),
    [],
  )
  const footer = useMemo(() => <Navbar />, [])

  return (
    <Layout
      headerComponent={header}
      footerComponent={footer}
      pageConfig={{ withoutStyledContent: true }}
    >
      <PsChart psChartStore={psChartStore} />
    </Layout>
  )
}
