import { ProgressCircle } from 'components/common/util/ProgressCircle'
import { IconColored } from 'components/common/IconColored'
import { ActionTooltip } from 'components/ActionTooltip'
import React from 'react'

export const ConnectIcon = ({
  isConnected,
  isInProgress,
  isActive,
  isDisabled,
  onClick,
}: {
  isConnected: boolean
  isInProgress: boolean
  isActive: boolean
  isDisabled: boolean
  onClick: () => void
}) => {
  if (isConnected) {
    if (isInProgress) {
      return <ProgressCircle />
    } else {
      return (
        <IconColored
          onClick={onClick}
          isActive={isActive}
          isDisabled={isDisabled}
          icon="un-linked-icon"
          size={14}
        />
      )
    }
  }
  return (
    <ActionTooltip offsetY={0} tooltipId="connectionCreate">
      <IconColored
        onClick={onClick}
        isActive={isActive}
        isDisabled={isDisabled}
        icon="linked-Icon"
        size={14}
        data-tid="create-new-connection-btn"
      />
    </ActionTooltip>
  )
}
