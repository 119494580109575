import React, { useCallback } from 'react'
import { ActionTooltip } from 'components/ActionTooltip'
import { IconColored } from 'components/common/IconColored'
import { PsChartStore } from 'components/ps-chart/PsChartStore'
import { observer } from 'mobx-react-lite'
import classNames from 'classnames'

interface ThreadActionsProps {
  psChartStore: PsChartStore
}

export const ThreadActions = observer(function ThreadActions(props: ThreadActionsProps) {
  const { psChartStore } = props
  const { hoveredThread } = psChartStore
  const isPinned = hoveredThread
    ? psChartStore.traceAnalyzeStore.pinnedIdsSet.has(hoveredThread.id)
    : false
  const isFavorite = hoveredThread
    ? psChartStore.traceAnalyzeStore.favIdSet.has(hoveredThread.id)
    : false
  const threadY = hoveredThread
    ? psChartStore.getThreadY(hoveredThread.id) -
      (isPinned ? 0 : psChartStore.vState.yStart - psChartStore.vState.pinnedCanvasHeight)
    : 0

  const visible =
    hoveredThread &&
    (psChartStore.vState.pinnedCanvasHeight > 0 && !isFavorite
      ? threadY > psChartStore.vState.pinnedCanvasHeight
      : true)

  const onFavoriteClick = useCallback(() => {
    if (hoveredThread) {
      psChartStore.traceAnalyzeStore.toggleFavoriteThread(hoveredThread)
    }
  }, [psChartStore, hoveredThread])

  const onSearchClick = useCallback(() => {
    if (hoveredThread) {
      psChartStore.searchState.setSearchContextThreadId(hoveredThread.id)
    }
  }, [psChartStore, hoveredThread])

  const onArrowDownClick = useCallback(() => {
    if (!isFavorite && hoveredThread) {
      psChartStore.traceAnalyzeStore.deprioritizeThread(hoveredThread.id)
    }
  }, [psChartStore, hoveredThread, isFavorite])

  const handleKeepActiveSelection = () => {
    psChartStore.lockSelection()
  }

  return (
    <div
      style={{ top: `${threadY}px` }}
      className={classNames(
        'group-hover:flex group-hover:bg-dark-dark2 rounded pl-[3px] absolute top-[32px] right-[8px]',
        !visible && 'hidden',
      )}
    >
      <ActionTooltip tooltipId="threadSearch">
        <IconColored
          size={13}
          icon="small-search-icon"
          onClick={onSearchClick}
          onMouseDown={handleKeepActiveSelection}
          className="px-[7px]"
        />
      </ActionTooltip>
      <ActionTooltip tooltipId={!isFavorite ? 'threadLockBottom' : undefined}>
        <IconColored
          size={12}
          icon="arrow-small-deprioritize-icon"
          onClick={onArrowDownClick}
          onMouseDown={handleKeepActiveSelection}
          isDisabled={isFavorite}
          className="px-[7px]"
          data-tid="thread-deprioritize"
        />
      </ActionTooltip>
      <ActionTooltip tooltipId={!isFavorite ? 'threadLockTop' : undefined}>
        <IconColored
          size={13}
          icon="small-favorites-icon"
          onClick={onFavoriteClick}
          onMouseDown={handleKeepActiveSelection}
          isActive={isFavorite}
          className="px-[7px]"
          data-tid="thread-favorite"
        />
      </ActionTooltip>
    </div>
  )
})
