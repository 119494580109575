import { useCallback, useEffect, useRef } from 'react'
import { reaction } from 'mobx'
import { observer } from 'mobx-react-lite'

import { PsChartStore } from 'components/ps-chart/PsChartStore'

export const ChartScroll = observer(function ChartScroll({
  psChartStore,
}: {
  psChartStore: PsChartStore
}) {
  const scrollableRef = useRef<HTMLDivElement>(null)
  const scrollableContentRef = useRef<HTMLDivElement>(null)
  const { headerHeight } = psChartStore.chartSettings.renderEngine

  /**
   * Faster approach then passing new vState stats directly inside div style better to pass it via ref's style.
   * For example when next reaction fires up scrollableContentRef has old totalHeight and didn't scroll on updated yStart
   * because it was out of total height limit.
   **/
  useEffect(() =>
    reaction(
      () => [
        psChartStore.vState.scrollableHeight,
        psChartStore.vState.pinnedCanvasHeight,
        psChartStore.vState.mainTotalHeight,
      ],
      ([scrollableHeight, pinnedCanvasHeight, totalHeight]) => {
        if (scrollableRef.current) {
          scrollableRef.current.style.top = `${pinnedCanvasHeight + headerHeight}px`
          scrollableRef.current.style.height = `${scrollableHeight}px`
        }
        if (scrollableContentRef.current) {
          scrollableContentRef.current.style.height = `${totalHeight}px`
        }
      },
      {
        name: 'reaction @ ChartScroll & vState update -> style update',
      },
    ),
  )

  useEffect(() =>
    reaction(
      () => psChartStore.vState.yStart,
      (yStart: number) => {
        if (scrollableRef.current) {
          scrollableRef.current.scrollTop = yStart
        }
      },
      { name: 'reaction @ ChartScroll & vState.yStart -> scrollableRef.current' },
    ),
  )

  const onScroll = useCallback(() => {
    if (scrollableRef.current) {
      psChartStore.vState.setYStart(scrollableRef.current.scrollTop)
    }
  }, [psChartStore])

  // If the scroll container width less than 12px with the "scrollbar-width: thin;" it will not be visible;
  return (
    <div
      className="w-[12px] absolute right-0 overflow-y-scroll"
      ref={scrollableRef}
      onScroll={onScroll}
    >
      <div ref={scrollableContentRef}></div>
    </div>
  )
})
