import { useEffect } from 'react'
import { when } from 'mobx'
import { generatePath, useNavigate, useParams } from 'react-router-dom'

import { GuideIoStore } from 'components/guide-io/GuideIoStore'
import { GUIDE_IO_PROJECT_PATH } from 'components/guide-io/constants'
import { PATH_ROOT } from 'utils/links'

export const useGuideIoNavigationRules = (guideIoStore: GuideIoStore) => {
  const { projectUrlName, stepKey } = useParams()
  const navigate = useNavigate()

  useEffect(
    () =>
      when(
        () => guideIoStore.isUserDataReady,
        () => {
          if (projectUrlName == null) {
            const { defaultIoProject } = guideIoStore
            if (defaultIoProject == null) {
              return navigate(PATH_ROOT)
            }
            const defaultStepKey = guideIoStore.getDefaultProjectStepKey(defaultIoProject)
            if (defaultStepKey == null) {
              return navigate(PATH_ROOT)
            }
            return navigate(
              generatePath(GUIDE_IO_PROJECT_PATH, {
                projectUrlName: defaultIoProject.urlName,
                stepKey: defaultStepKey,
              }),
            )
          } else if (stepKey == null) {
            const projectDto = guideIoStore.getProjectByProjectUrlName(projectUrlName)
            if (projectDto == null) {
              return navigate(PATH_ROOT)
            }
            const defaultStepKey = guideIoStore.getDefaultProjectStepKey(projectDto)
            if (defaultStepKey == null) {
              return navigate(PATH_ROOT)
            }
            return navigate(
              generatePath(GUIDE_IO_PROJECT_PATH, {
                projectUrlName,
                stepKey: defaultStepKey,
              }),
            )
          }
          guideIoStore.setProjectStep(projectUrlName, stepKey)
        },
      ),
    [guideIoStore, navigate, projectUrlName, stepKey],
  )
}
