import { PsChartStore } from 'components/ps-chart/PsChartStore'
import React, { useCallback, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { isHostTypeProd } from 'utils/getHostType'
import { useDi } from 'contexts/di-context'
import { ChartStateInfo } from 'components/ps-chart/dev-monitor/ChartStateInfo'
import { useHotKeys } from '../hooks/useHotKeys'
import { FpsMeter } from './FpsMeter'
import { PerfMeasurer } from './PerfMeasurer'

export const DevMonitor = observer(function DevMonitor({
  psChartStore,
}: {
  psChartStore: PsChartStore
}) {
  const di = useDi()
  const { exceptionHandler } = di.compositionRoot
  const [isOpen, setIsOpen] = useState(false)
  const [isShowingFPS, setIsShowingFPS] = useState(!isHostTypeProd())

  const toggleFPSMeter = () => {
    setIsShowingFPS(!isShowingFPS)
  }
  useHotKeys(['ctrlKey', 'altKey', 'shiftKey', 'KeyA'], toggleFPSMeter)

  const onResolutionWidthClick = useCallback(() => {
    exceptionHandler.handleMessage(`Test message ${Date.now()}`)
  }, [exceptionHandler])

  const onResolutionHeightClick = useCallback(() => {
    const err = new Error(`Test exception ${Date.now()}`)
    exceptionHandler.handleException(err)
  }, [exceptionHandler])

  if (!isShowingFPS) {
    return null
  }

  return (
    <>
      {isOpen && (
        <div className="fixed z-10 bg-dark-dark4 w-[320px] pb-2 top-18 left-0">
          <FpsMeter />
          <div className="text-right text-small p-2">
            <span onDoubleClick={onResolutionWidthClick}>{psChartStore.hState.width}</span>
            <span>x</span>
            <span onDoubleClick={onResolutionHeightClick}>{psChartStore.vState.height}</span>
          </div>
          <PerfMeasurer psChartStore={psChartStore} />
          <ChartStateInfo psChartStore={psChartStore} />
        </div>
      )}
      <div
        onClick={() => setIsOpen(!isOpen)}
        className="fixed z-10 w-[40px] pb-2 bottom-1 right-14 text-[24px] flex flex-col justify-center items-center h-[40px] hover:cursor-pointer"
      >
        🛠
      </div>
    </>
  )
})
