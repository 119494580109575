import { Icon } from 'components/Icon'
import { useTranslation } from 'react-i18next'
import { Button, ButtonVariantEnum } from 'components/Button'
import { useModal } from 'hooks/useModal'
import { LIVE_DEMO_GUIDE_MODAL } from 'components/live-demo/LiveDemoModal'
import { LiveDemoController } from 'components/live-demo/LiveDemoController'
import { observer } from 'mobx-react-lite'
import styled from 'styled-components/macro'
import { useToaster } from 'hooks/useToaster'
import { PsChartStore } from 'components/ps-chart/PsChartStore'
import { LIVE_DEMO_TOAST_ID } from 'components/live-demo/LiveDemoStepHint'

interface LiveDemoModalControlPropTypes {
  liveDemoController: LiveDemoController
  psChartStore: PsChartStore
}

export const LiveDemoModalControl = observer(function LiveDemoModalControl({
  liveDemoController,
  psChartStore,
}: LiveDemoModalControlPropTypes) {
  const { t } = useTranslation()
  const toaster = useToaster()
  const { openModal, isModalOpen } = useModal(LIVE_DEMO_GUIDE_MODAL)
  const { enabled, enable, withFooter } = liveDemoController
  const { isOpened: isSearchOpened } = psChartStore.searchStore

  return (
    <ButtonWrapper
      className="h-[36px] w-[156px] bottom-[8px] bg-dark-dark3 flex items-center justify-center"
      withFooter={withFooter}
      isSearchOpened={isSearchOpened}
    >
      {!isModalOpen && (
        <>
          <div className="absolute top-0 left-[16px] bottom-0 flex items-center">
            {enabled ? (
              <Button
                data-testid="liveDemoFooterOpenModalButton"
                className="flex items-center group"
                variant={ButtonVariantEnum.Text}
                onClick={() => {
                  toaster.dismiss(LIVE_DEMO_TOAST_ID)
                  openModal(String(liveDemoController.currentStep.partNumber))
                }}
              >
                <Icon
                  icon="video-play"
                  className="text-gray-normal text-[32px] group-hover:text-white transition-colors"
                />
                <span className="text-small font-medium text-gray-normal group-hover:text-white transition-colors">
                  {t('components.liveDemo.tourOverview')}
                </span>
              </Button>
            ) : (
              <Button
                data-testid="liveDemoFooterContinueButton"
                className="flex items-center group"
                variant={ButtonVariantEnum.Text}
                onClick={() => {
                  toaster.dismiss(LIVE_DEMO_TOAST_ID)
                  enable()
                }}
              >
                <Icon
                  icon="arrow-small-r"
                  className="text-gray-normal text-[32px] group-hover:text-white transition-colors"
                />
                <span className="text-small font-medium text-gray-normal group-hover:text-white transition-colors">
                  {t('components.liveDemo.continueTour')}
                </span>
              </Button>
            )}
          </div>
        </>
      )}
    </ButtonWrapper>
  )
})

const ButtonWrapper = styled.div<{ withFooter: boolean; isSearchOpened: boolean }>`
  position: absolute;
  right: 50%;
  bottom: ${({ withFooter, isSearchOpened }) => {
    let offset = 8
    if (withFooter) {
      offset += 54
    }
    if (isSearchOpened) {
      offset += 40
    }
    return `${offset}px`
  }};
  left: 50%;
  transform: translate(-50%, 0);
`
