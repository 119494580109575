import React, { useMemo } from 'react'
import { Outlet, useParams } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import { Helmet } from 'react-helmet'

import { ModuleProgressCircle } from 'components/ps-chart/ModuleProgressCircle'
import { Layout } from 'components/Layout'
import { ErrorPageAuthorized } from 'pages/ErrorPageAuthorized'
import { chartDataStoreContext } from 'components/ps-chart/stores/ChartDataStore'
import { psChartStoreContext } from 'components/ps-chart/PsChartStore'
import { useIsReadOnlyProject } from 'utils/feature-flags'
import { useProjectQuery, useTraceQuery, useUserQuery } from 'hooks/useApiQuery'
import { useChartStores } from 'hooks/useChartStores'
import { useChartPageParams } from 'hooks/useChartPageParams'
import { useIoGuidesRedirect } from 'components/guide-io/useIoGuidesRedirect'
import { BaseHeader } from 'components/header/BaseHeader'
import { Navbar } from 'components/Navbar'

const ChartRoot = observer(function ChartRoot({
  isReadOnlyProject,
}: {
  isReadOnlyProject: boolean
}) {
  const chartPageParams = useChartPageParams()

  const { psChartStore, chartDataStore, error } = useChartStores({
    isReadOnlyProject,
    chartPageParams,
  })
  const { projectUrlName, traceProjectLocalId } = chartPageParams

  const { data: trace } = useTraceQuery({
    projectUrlName,
    traceProjectLocalId,
  })
  const traceName = trace?.name
  const pageTitle = traceName && (
    <Helmet>
      <title>{traceName}</title>
    </Helmet>
  )

  const header = useMemo(() => <BaseHeader />, [])
  const footer = useMemo(() => <Navbar />, [])

  if (error) {
    return <ErrorPageAuthorized fullReload={true} />
  }

  if (chartDataStore.isLoading) {
    return (
      <Layout
        headerComponent={header}
        footerComponent={footer}
        pageConfig={{ withoutStyledContent: true }}
      >
        {pageTitle}
        <main className="flex flex-grow relative">
          <ModuleProgressCircle />
        </main>
      </Layout>
    )
  }

  return (
    <chartDataStoreContext.Provider value={chartDataStore}>
      <psChartStoreContext.Provider value={psChartStore}>
        {pageTitle}
        <Outlet />
      </psChartStoreContext.Provider>
    </chartDataStoreContext.Provider>
  )
})

export const ChartRootPage = () => {
  const isReadOnlyProject = useIsReadOnlyProject()
  const { projectUrlName } = useParams()
  const { data: project } = useProjectQuery({ projectUrlName })
  const { data: user } = useUserQuery()
  useIoGuidesRedirect({ user, project })

  if (isReadOnlyProject === undefined) {
    return null
  }
  return <ChartRoot isReadOnlyProject={isReadOnlyProject} />
}
