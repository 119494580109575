import { useEffect } from 'react'
import { ActionTooltip } from 'components/ActionTooltip'
import { IconColored } from 'components/common/IconColored'
import { observer } from 'mobx-react-lite'
import { PsChartStore } from '../PsChartStore'

export const TransparentModeAction = observer(function TransparentModeAction({
  psChartStore,
}: {
  psChartStore: PsChartStore
}) {
  useEffect(() => {
    const handleMouseUp = () => psChartStore.disableTransparentConnection()
    window.addEventListener('mouseup', handleMouseUp)

    return () => {
      window.removeEventListener('mouseup', handleMouseUp)
    }
  }, [psChartStore])

  return (
    <ActionTooltip place="top" tooltipId="transparentMode">
      <div
        onMouseDown={() => psChartStore.enableTransparentConnection()}
        className="flex items-center justify-center align-center h-[32px] w-[64px] bg-dark-dark3"
      >
        <IconColored
          className="p-[8px]"
          icon="transparent-connection"
          size={16}
          isActive={psChartStore.isTransparentConnectionEnabled}
          isDisabled={!psChartStore.traceAnalyzeStore.chainExists}
          isHold={psChartStore.isTransparentConnectionEnabled}
        />
      </div>
    </ActionTooltip>
  )
})
