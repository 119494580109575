import React from 'react'
import { generatePath, useParams } from 'react-router-dom'

import { useProjectsSummaryQuery } from 'hooks/useApiQuery'
import { PATH_TEAM } from 'pages/TeamPage'

import { Breadcrumbs } from './Breadcrumbs'
import { BreadcrumbSection } from './BreadcrumbSection'
import { BreadcrumbsLink } from './BreadcrumbsLink'

export const TeamPageBreadcrumbs = () => {
  const { teamUrlName } = useParams()
  const { data: projectsSummaryData } = useProjectsSummaryQuery({
    teamUrlName,
  })

  return (
    <Breadcrumbs>
      <BreadcrumbSection isLoading={projectsSummaryData == null}>
        {projectsSummaryData != null && (
          <BreadcrumbsLink
            path={generatePath(PATH_TEAM, { teamUrlName })}
            name={projectsSummaryData.team.name}
          />
        )}
      </BreadcrumbSection>
    </Breadcrumbs>
  )
}
