import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components/macro'
import { Trans, useTranslation } from 'react-i18next'
import { useParams, Link, generatePath } from 'react-router-dom'
import { observer, useLocalObservable } from 'mobx-react-lite'
import classNames from 'classnames'
import { useMutation, useQueryClient } from 'react-query'
import { AxiosProgressEvent, AxiosError } from 'axios'

import { Icon } from 'components/Icon'
import { ProgressBar } from 'components/ProgressBar'
import { LoadingSpinner } from 'components/LoadingSpinner'
import { UploaderViewEnum, UploadingController } from 'components/traces/UploadingController'

import { useApi } from 'contexts/di-context'
import { queryKeys } from 'hooks/useApiQuery'

import { FEATURE_FLAGS, useFeatureFlag } from 'utils/feature-flags'
import { getFileSize } from 'utils/getFileSize'

import { TraceProcessingState, type Trace, type Traces, ApiError } from 'api/models'
import { useToaster } from 'hooks/useToaster'
import { PATH_CHART_ROUTER } from 'pages/PsChartRouter'

let dragCounter = 0
let abortController: AbortController | null = null

export const Uploader = observer(function Uploader() {
  const { t } = useTranslation()
  const toaster = useToaster()

  const api = useApi()
  const { projectUrlName, flowProjectLocalId } = useParams() as {
    projectUrlName: string
    flowProjectLocalId: string
  }

  const queryClient = useQueryClient()
  const isUploadToCloudEnabled = useFeatureFlag(FEATURE_FLAGS.TRACE_UPLOAD)

  const inputRef = useRef<HTMLInputElement | null>(null)
  const [isDrag, setDrag] = useState(false)
  const [filesize, setFilesize] = useState({ loaded: 0, total: 0 })
  const uploadingController = useLocalObservable<UploadingController>(
    () => new UploadingController(),
  )

  const dropEnabled = uploadingController.view !== UploaderViewEnum.UPLOADING

  const postUploadTraceMutation = useMutation(
    ({ formData }: { formData: FormData }) => {
      return api.postUploadTrace({ projectUrlName, flowProjectLocalId }, formData, {
        onUploadProgress: handleUploadProgress,
        signal: abortController?.signal,
      })
    },
    {
      onSuccess: (newTrace) => {
        queryClient.setQueryData<Traces | undefined>(
          queryKeys.flowTraces({ projectUrlName, flowProjectLocalId }),
          (existingTraces = []) => {
            return [...existingTraces, newTrace]
          },
        )
        uploadingController.completeCurrentUploading({
          projectUrlName,
          flowProjectLocalId,
          traceProjectLocalId: newTrace.projectLocalId,
        })
        if (uploadingController.uploadingFiles.length > 0) {
          uploadTrace(uploadingController.uploadingFiles[0].file)
        }
      },
      onError: () => {
        uploadingController.setError()
      },
    },
  )

  const postTraceEntityMutation = useMutation(
    (file: File) => {
      return api.postTraceEntity(
        {
          projectIdOrUrlName: projectUrlName,
          flowProjectLocalId: flowProjectLocalId,
        },
        { traceName: file.name, sizeBytes: file.size },
        {
          signal: abortController?.signal,
        },
      )
    },
    {
      onSuccess: async ({ uploadRequest, trace }) => {
        await api.uploadTraceToObjectStorage(
          uploadingController.uploadingFiles[0].file,
          uploadRequest,
          handleUploadProgress,
        )

        await api.postTraceStartProcessing({
          projectIdOrUrlName: projectUrlName,
          flowProjectLocalId: flowProjectLocalId,
          traceProjectLocalId: trace.projectLocalId,
        })

        uploadingController.completeCurrentUploading({
          projectUrlName,
          flowProjectLocalId,
          traceProjectLocalId: trace.projectLocalId,
        })

        queryClient.setQueryData<Traces | undefined>(
          queryKeys.flowTraces({ projectUrlName, flowProjectLocalId }),
          (existingTraces = []) => [
            ...existingTraces,
            {
              ...trace,
              processingState: TraceProcessingState.IN_PROGRESS,
            } as Trace,
          ],
        )

        if (uploadingController.uploadingFiles.length > 0) {
          uploadTrace(uploadingController.uploadingFiles[0].file)
        }
      },
      onError: (err: AxiosError<ApiError>) => {
        toaster.error(err)
        uploadingController.setError()
      },
    },
  )

  const handleUploadProgress = (progressEvent: AxiosProgressEvent | ProgressEvent) => {
    setFilesize({ loaded: progressEvent.loaded, total: progressEvent.total! })
  }

  const uploadTrace = (file: File) => {
    abortController = new AbortController()
    setFilesize({ loaded: 0, total: file.size })

    if (isUploadToCloudEnabled) {
      return postTraceEntityMutation.mutate(file)
    } else {
      const formData = new FormData()
      formData.append('file', file)
      return postUploadTraceMutation.mutate({ formData })
    }
  }

  const handleFileSelect = (files: FileList | null) => {
    uploadingController.clear()
    if (files && files.length > 0) {
      uploadingController.setFiles(Array.from(files))
      uploadTrace(files[0])
    }
  }

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    handleFileSelect(event.target.files)
    if (inputRef.current) {
      inputRef.current.value = ''
    }
  }

  const handleDragEnter = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault()
    event.stopPropagation()
    if (dropEnabled) {
      dragCounter++
      if (event.dataTransfer.items.length > 0) {
        setDrag(true)
      }
    }
  }

  const handleDragLeave = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault()
    event.stopPropagation()
    if (dropEnabled) {
      dragCounter--
      if (dragCounter === 0) {
        setDrag(false)
      }
    }
  }

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault()
    event.stopPropagation()
  }

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault()
    event.stopPropagation()
    if (dropEnabled) {
      setDrag(false)
      if (event.dataTransfer.files.length > 0) {
        handleFileSelect(event.dataTransfer.files)
        event.dataTransfer.clearData()
        dragCounter = 0
      }
    }
  }

  const handleOffline = () => {
    abortController?.abort()
    uploadingController.setError()
  }

  useEffect(() => {
    window.addEventListener('offline', handleOffline)

    return () => {
      window.removeEventListener('offline', handleOffline)
    }
  })

  const renderChooseFile = () => (
    <label
      className="cursor-pointer transition-colors px-[12px] py-[7.5px] text-gray-service hover:text-white border-solid border-[1px] border-gray-service hover:border-white rounded-[4px] ml-[8px]"
      htmlFor="file-input"
    >
      {t('traces.uploader.browseComputer')}
    </label>
  )

  const allFilesNum = uploadingController.uploadingFiles.length
  const filesToRender = uploadingController.uploadingFiles.slice(0, 3)
  const isSingleFile = allFilesNum === 1
  const moreLength = allFilesNum - filesToRender.length

  const renderState = () => {
    switch (uploadingController.view) {
      case UploaderViewEnum.ERROR:
        return (
          <div className="flex items-center justify-center">
            <Icon icon="upload_error" className="text-accentRed text-[26px] mr-[10px]" />
            <h3 className="text-small tracking-wide text-gray-service flex items-center">
              <span className="mr-[3px] flex">
                <Trans
                  i18nKey={
                    isSingleFile
                      ? 'traces.uploader.failedToUpload'
                      : 'traces.uploader.failedToUploadFiles'
                  }
                  values={{
                    traceName: uploadingController.files[0].file.name,
                  }}
                  components={{
                    bold: <TraceName className="text-white mr-[3px]" total={1} />,
                  }}
                />
              </span>
              {!isSingleFile &&
                filesToRender.map((item, index, all) => (
                  <TraceName total={all.length}>
                    <span className="text-white">
                      {' '}
                      {index !== 0 && ','} {item.file.name}
                    </span>
                  </TraceName>
                ))}
              {moreLength > 0 && (
                <span className="ml-[5px]">
                  <Trans
                    i18nKey="traces.uploader.andMore"
                    values={{
                      moreNumber: moreLength,
                    }}
                    components={{
                      bold: <span className="font-bold" />,
                    }}
                  />
                </span>
              )}
            </h3>
            <div className="text-small tracking-wide text-gray-normal mx-[32px]">
              {t('traces.uploader.dropMoreHereOr')}
              {renderChooseFile()}
            </div>
          </div>
        )
      // case UploaderViewEnum.MAIN:
      case UploaderViewEnum.UPLOADING:
        return (
          <div className="flex items-center justify-center">
            <h3
              className={classNames(
                'text-small tracking-wide text-gray-service',
                uploadingController.isSingleFile ? 'mr-[4px]' : 'mr-[11px]',
              )}
            >
              {t(
                uploadingController.isSingleFile
                  ? 'traces.uploader.uploading'
                  : 'traces.uploader.uploadingQueue',
              )}
            </h3>
            <div className="flex items-center">
              {filesToRender.map((item, index, all) => (
                <div
                  key={index + item.file.name}
                  className={classNames(
                    'text-small tracking-wide px-[10px] py-[3.5px] rounded-[4px] mx-[4px] border-solid border-[1px] flex items-center',
                    index === 0
                      ? 'text-white bg-gray-dark border-gray-dark'
                      : 'text-gray-service border-gray-faded',
                  )}
                >
                  <TraceName total={all.length}>{item.file.name}</TraceName>
                  {index === 0 ? (
                    <LoadingSpinner size={14} className="ml-[5px]" />
                  ) : (
                    <Icon
                      as="button"
                      icon="cross-icon"
                      className="text-[11px] text-gray-service ml-[8px]"
                      onClick={() => {
                        uploadingController.removeFile(item)
                        if (index === 0 && abortController) {
                          abortController.abort()
                          const nextFileToUpload = uploadingController.uploadingFiles[0]
                          if (nextFileToUpload) {
                            uploadTrace(nextFileToUpload.file)
                          }
                        }
                      }}
                    />
                  )}
                </div>
              ))}
              {moreLength > 0 && (
                <span className="ml-[8px] text-small tracking-wide text-gray-service">
                  <Trans
                    i18nKey="traces.uploader.andMore"
                    values={{
                      moreNumber: moreLength,
                    }}
                    components={{
                      bold: <span className="font-bold" />,
                    }}
                  />
                </span>
              )}
            </div>
            <div className="absolute left-0 right-0 bottom-0">
              <ProgressBar setWidth={Math.round((filesize.loaded * 100) / filesize.total!)} />
            </div>
            <div className="text-micro tracking-widest text-gray-normal absolute left-[8px] bottom-[8px]">
              <span>
                {getFileSize(filesize.loaded)}&nbsp;of&nbsp;{getFileSize(filesize.total)}
              </span>
            </div>
          </div>
        )
      case UploaderViewEnum.SUCCESS:
      default:
        const isNotDefault = uploadingController.view !== UploaderViewEnum.MAIN
        return (
          <div className="flex items-center justify-center">
            {uploadingController.view === UploaderViewEnum.SUCCESS && (
              <div className="flex items-center">
                <Icon icon="check" className="text-icon text-state-good" />
                <h3 className="text-small tracking-wide text-gray-normal flex">
                  <Trans
                    i18nKey={
                      uploadingController.isSingleFile
                        ? 'traces.uploader.successfullyUploaded'
                        : 'traces.uploader.allTracesSuccessfullyUploaded'
                    }
                    values={{
                      traceName: uploadingController.files[0].file.name,
                    }}
                    components={{
                      bold: <span className="text-white mr-[3px]" />,
                      fileName: (
                        <TraceName
                          total={1}
                          className="mr-[3px] text-white"
                          link={
                            uploadingController.isSingleFile
                              ? generatePath(
                                  PATH_CHART_ROUTER,
                                  uploadingController.files[0].traceParams,
                                )
                              : undefined
                          }
                        />
                      ),
                    }}
                  />
                </h3>
              </div>
            )}
            <div className="text-small tracking-wide text-gray-normal mx-[32px]">
              {t(isNotDefault ? 'traces.uploader.dropMoreHereOr' : 'traces.uploader.dropHereOr')}
              {renderChooseFile()}
            </div>
          </div>
        )
    }
  }

  return (
    <div>
      <input
        ref={inputRef}
        type="file"
        multiple={true}
        id="file-input"
        onChange={handleFileChange}
        className="hidden"
      />
      <View
        onDragEnter={handleDragEnter}
        onDragLeave={handleDragLeave}
        onDragOver={handleDragOver}
        onDrop={handleDrop}
        isDrag={isDrag}
      >
        {renderState()}
      </View>
    </div>
  )
})

interface TraceNameProps {
  children?: React.ReactNode
  className?: string
  total: number
  link?: string
}

const TraceName = (props: TraceNameProps) => {
  const { children, total, className, link, ...otherProps } = props
  return (
    <div
      {...otherProps}
      className={classNames(
        'whitespace-nowrap overflow-hidden text-ellipsis',
        total >= 3 && 'max-w-[80px] 1280:max-w-[120px] 1680:max-w-[160px]',
        total === 2 && 'max-w-[120px] 1280:max-w-[160px] 1680:max-w-[210px]',
        total === 1 && 'max-w-[240px] 1280:max-w-[360px]',
        className,
      )}
    >
      {link ? (
        <Link to={link}>
          <span className="underline">{children}</span>
        </Link>
      ) : (
        children
      )}
    </div>
  )
}

const View = styled.div<{ isDrag: boolean }>`
  position: relative;
  display: flex;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  min-height: 88px;
  margin-right: 61px;
  margin-bottom: 20px;
  margin-left: 93px;
  border: 1px solid ${({ theme, isDrag }) => (isDrag ? theme.colors.sky : theme.colors.dark.dark2)};
  border-radius: 4px;
  background-color: ${({ theme }) => theme.colors.dark.dark2};
`
