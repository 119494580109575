import { useParams } from 'react-router-dom'
import { PlatformType } from 'components/guide/models'
import { GuideStore } from 'components/guide/GuideStore'

export function useGuidePlatform(): PlatformType | null {
  const { platform } = useParams()
  if (!platform) {
    return null
  }
  const parsedPlatform = GuideStore.getPlatformByString(platform)

  if (!parsedPlatform) {
    throw new Error('Provided platform value is incorrect')
  }

  return parsedPlatform
}
