import { useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { psLocalStorage } from 'utils/localStorage/PsLocalStorage'

export interface OrderSortingType {
  columnOrder: string
  order?: string
}

export interface OrderStorageType {
  [key: string]: {
    [key: string]: OrderSortingType
  }
}

export enum OrderStorageKey {
  TEAM_CONTRIBUTORS_SORTING_KEY = 'team-contributors-sorting',
  TRACE_SORTING_KEY = 'trace-sorting',
}

export const useOrderStorage = (key: OrderStorageKey) => {
  const { teamUrlName, projectUrlName, flowProjectLocalId } = useParams()

  const getCurrentSortingKey = useMemo(() => {
    switch (key) {
      case OrderStorageKey.TEAM_CONTRIBUTORS_SORTING_KEY:
        return String(teamUrlName)
      case OrderStorageKey.TRACE_SORTING_KEY:
        return `${projectUrlName}-${flowProjectLocalId || 'unassigned'}`
      default:
        return 'sorting'
    }
  }, [key, teamUrlName, projectUrlName, flowProjectLocalId])

  const orderInStorage = psLocalStorage.getTracesOrder()
  const allSortingTypeInStorage = orderInStorage && orderInStorage[key]
  const currentSortingType =
    allSortingTypeInStorage && allSortingTypeInStorage[getCurrentSortingKey]

  const writeSortingToStorage = (newSorting: OrderSortingType) => {
    psLocalStorage.setTracesOrder({
      ...psLocalStorage.getTracesOrder(),
      [key]: {
        ...allSortingTypeInStorage,
        [getCurrentSortingKey]: newSorting,
      },
    })
  }

  return {
    currentSortingType,
    currentSortingKey: getCurrentSortingKey,
    writeSortingToStorage,
  }
}
