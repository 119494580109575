import { useTranslation } from 'react-i18next'

import commonTexts from 'locale/en/en-common.json'

export type TitleKey = keyof typeof commonTexts.components.header.screenTitle

interface PageTitleProps {
  titleKey: TitleKey
}

export const PageTitle = ({ titleKey }: PageTitleProps) => {
  const { t } = useTranslation()

  return (
    <div className="text-normal tracking-wide text-center font-medium">
      {t(`components.header.screenTitle.${titleKey}`)}
    </div>
  )
}
