import { makeAutoObservable, runInAction } from 'mobx'
import { Api } from 'api/Api'

import { AnnotationDto, ChartPageParams } from 'api/models'
import { AnnotationsStore } from 'components/ps-chart/stores/AnnotationsStore'

export class AnnotationsDataStore {
  private readonly api: Api
  private readonly chartPageParams: ChartPageParams

  private _annotations: AnnotationDto[] = []
  private _isLoaded = false

  constructor(api: Api, chartPageParams: ChartPageParams) {
    makeAutoObservable<AnnotationsDataStore, 'api' | 'tracePageParams'>(this, {
      api: false,
      tracePageParams: false,
    })
    this.api = api
    this.chartPageParams = chartPageParams
  }

  load(shouldUpdateData: boolean): Promise<AnnotationDto[]> {
    return this.api.getAnnotations(this.chartPageParams).then((annotations) => {
      const fetchAnnotations = () => {
        this._isLoaded = true
        if (shouldUpdateData) {
          this._annotations = [...this.localAnnotations, ...annotations]
          // filter not to delete locally added before fetch request came
        }
      }
      runInAction(fetchAnnotations)
      return annotations
    })
  }

  get annotations(): AnnotationDto[] {
    return this._annotations
  }

  get localAnnotations(): AnnotationDto[] {
    return this.annotations.filter((annotation) => AnnotationsStore.isAnnotationLocal(annotation))
  }

  get nonLocalAnnotations(): AnnotationDto[] {
    return this.annotations.filter((annotation) => !AnnotationsStore.isAnnotationLocal(annotation))
  }

  get isAllAnnotationsConnected(): boolean {
    return this.nonLocalAnnotations.every((annotation) =>
      AnnotationsStore.isFullyConnectedToSlices(annotation),
    )
  }

  get hasRemoteMappedAnnotations(): boolean {
    return Boolean(
      this.nonLocalAnnotations.find((item) => AnnotationsStore.isBoundToTimeline(item)),
    )
  }

  get isLoaded(): boolean {
    return this._isLoaded
  }
}
