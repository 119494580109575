import { useSearchParams } from 'react-router-dom'

import { RequestPsLiteForm } from 'components/lite/RequestPsLiteForm'
import { LitePasswordForm } from 'components/lite/LitePasswordForm'
import { LiteLayout } from 'components/lite/LiteLayout'
import { LiteModal } from 'components/lite/LiteModal'
import { LiteHeader } from 'components/lite/LiteHeader'

export const PATH_LITE_SIGN_UP = !!process.env.REACT_APP_IS_LITE ? '/sign-up' : 'lite/sign-up'

export const LiteSignUpPage = () => {
  const [searchParams] = useSearchParams()
  const verificationToken = searchParams.get('ver-token')

  return (
    <LiteLayout header={<LiteHeader />}>
      <LiteModal isOpen>
        {verificationToken ? (
          <LitePasswordForm verificationToken={verificationToken} />
        ) : (
          <RequestPsLiteForm />
        )}
      </LiteModal>
    </LiteLayout>
  )
}
