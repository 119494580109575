import React, { useContext, useEffect, useRef, useState } from 'react'
import styled, { css } from 'styled-components/macro'
import tw from 'twin.macro'
import { useTranslation } from 'react-i18next'

import { LayoutContext } from 'contexts/layout-context'

interface DescriptionCollapseProps {
  value: string
}

const COLLAPSE_BUTTON_INDENT = 40

export const DescriptionCollapse = ({ value }: DescriptionCollapseProps) => {
  const { t } = useTranslation()

  const descriptionRef = useRef<HTMLDivElement>(null)
  const spanRef = useRef<HTMLElement>(null)

  const [isCollapsed, setCollapsed] = useState(false)
  const [withCollapse, setWithCollapse] = useState(false)
  const [descriptionWidth, setDescriptionWidth] = useState(0)

  const { hintsIsOpened } = useContext(LayoutContext)

  const isEmpty = value.length === 0

  const handleCollapseButtonClick = () => setCollapsed(!isCollapsed)

  const handleChangeDescriptionWidth = () => {
    const descriptionEl = descriptionRef?.current
    const spanEl = spanRef?.current

    if (descriptionEl && spanEl) {
      setWithCollapse(spanEl.offsetWidth >= descriptionEl.offsetWidth - COLLAPSE_BUTTON_INDENT)
      setDescriptionWidth(descriptionEl.offsetWidth - COLLAPSE_BUTTON_INDENT)
    }
  }

  useEffect(() => {
    setTimeout(() => {
      handleChangeDescriptionWidth()
    }, 250)
  }, [hintsIsOpened])

  useEffect(() => {
    handleChangeDescriptionWidth()
  }, [value])

  useEffect(() => {
    handleChangeDescriptionWidth()
    window.addEventListener('resize', handleChangeDescriptionWidth)
    window.addEventListener('orientationchange', handleChangeDescriptionWidth)

    return () => {
      window.removeEventListener('resize', handleChangeDescriptionWidth)
      window.removeEventListener('orientationchange', handleChangeDescriptionWidth)
    }
  }, [])

  return (
    <Description
      isCollapsed={isCollapsed}
      descriptionWidth={descriptionWidth}
      ref={descriptionRef}
      isEmpty={isEmpty}
    >
      <span ref={spanRef}>{value}</span>
      {!isEmpty && withCollapse && (
        <CollapseButton onClick={handleCollapseButtonClick}>
          {isCollapsed ? t('flows.descriptionCollapse.less') : t('flows.descriptionCollapse.more')}
        </CollapseButton>
      )}
    </Description>
  )
}

export const descriptionStyles = css`
  ${tw`text-normal tracking-wide`}
  word-break: break-word;
  color: ${({ theme }) => theme.colors.gray.normal};
`

const Description = styled.div<{
  isCollapsed: boolean
  isEmpty: boolean
  descriptionWidth: number
}>`
  ${descriptionStyles}
  display: ${({ isCollapsed }) => (isCollapsed ? 'inline-block' : 'flex')};
  overflow: hidden;
  height: auto;

  span {
    overflow: hidden;
    max-width: ${({ descriptionWidth }) => descriptionWidth}px;
    white-space: ${({ isCollapsed }) => (isCollapsed ? 'normal' : 'nowrap')};
    text-overflow: ellipsis;
  }
`

const CollapseButton = styled.button`
  margin-left: 4px;
  white-space: nowrap;
  color: ${({ theme }) => theme.colors.white};
`
