import { StorageItem } from './StorageItem'

export class ArrayStorageItem<T> extends StorageItem<T[]> {
  get value(): T[] {
    const item = this.localStorageValue
    if (!item) {
      return []
    }
    try {
      return JSON.parse(item!)
    } catch (err) {
      this.showParsingErrorAndResetData(err)
      return []
    }
  }

  set value(newValue: T[]) {
    super.localStorageValue = JSON.stringify(newValue)
  }
}
