import { RegressionStatus } from 'components/flows/ra/runs/runUtils'
import { Icon } from 'components/Icon'
import React from 'react'
import { Run } from 'components/regression-analysis-mvp/Run'

interface RegressionIconProps {
  run: Run
}

export const RegressionIcon = ({ run }: RegressionIconProps) => {
  if (!run.isFinished) {
    return <Icon icon="more" className="text-icon" />
  } else {
    switch (run.regressionStatus) {
      case RegressionStatus.NOT_DETECTED:
        return <span className="w-8"></span>
      case RegressionStatus.SLOWDOWN:
        return <Icon icon="arrow-small-d" className="text-icon text-state-bad" />
      case RegressionStatus.SPEEDUP:
        return <Icon icon="arrow-small-u" className="text-icon text-state-good" />
    }
  }
}
