import type { Properties, PostHog } from 'posthog-js'

export class Analytics {
  private posthog?: PostHog

  track(name: string, props?: Properties): void {
    pendo.track(name, props)
    this.posthog?.capture(name, props)
  }

  initPostHog(newPosthog: PostHog): void {
    this.posthog = newPosthog
  }
}
