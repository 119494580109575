import { useCallback, useMemo } from 'react'
import { useParams, generatePath } from 'react-router-dom'

// we could potentially make this path a bit less user-friendly to prevent crawling or accidental discovery
export const PATH_CCO_CHART = '/projects/:projectUrlName/cco/:sessionId?/:showTrace?'
export const PATH_CCO_SNAPSHOT =
  '/projects/:projectUrlName/cco/snapshots/:snapshotId/:sessionId?/:showTrace?'
export const PATH_CCO_DEMO = '/cco/demo/:sessionId?/:showTrace?'

const DEMO_PROJECT_PATH = 'DEMO'
const SHOW_TRACE_PATH = 'traces'

export const getCcoPath = (projectUrlName: string, snapshotId?: string) => {
  if (snapshotId) {
    return generatePath(PATH_CCO_SNAPSHOT, {
      projectUrlName,
      snapshotId,
      sessionId: null,
      showTrace: null,
    })
  }
  return generatePath(projectUrlName === DEMO_PROJECT_PATH ? PATH_CCO_DEMO : PATH_CCO_CHART, {
    projectUrlName,
    sessionId: null,
    showTrace: null,
  })
}

export const useCcoParams = () => {
  const { projectUrlName = DEMO_PROJECT_PATH, snapshotId, sessionId, showTrace } = useParams()

  const isDemo = projectUrlName === DEMO_PROJECT_PATH

  const apiUrl = useCallback(
    (path: string) => {
      if (isDemo) {
        return `/api/v1/control-panel/v1/${path}`
      } else {
        return `/api/v1/projects/${projectUrlName}/control-panel-proxy/v1/${path}`
      }
    },
    [projectUrlName, isDemo],
  )

  const ccoPath = useMemo(
    () => getCcoPath(projectUrlName, snapshotId),
    [projectUrlName, snapshotId],
  )

  const ccoSessionPath = useCallback(
    (sId: string) =>
      generatePath(snapshotId ? PATH_CCO_SNAPSHOT : isDemo ? PATH_CCO_DEMO : PATH_CCO_CHART, {
        snapshotId: snapshotId || null,
        projectUrlName,
        sessionId: sId,
        showTrace: null,
      }),
    [projectUrlName, isDemo, snapshotId],
  )

  const ccoSessionTracePath = useCallback(
    (sId: string) =>
      generatePath(snapshotId ? PATH_CCO_SNAPSHOT : isDemo ? PATH_CCO_DEMO : PATH_CCO_CHART, {
        snapshotId: snapshotId || null,
        projectUrlName,
        sessionId: sId,
        showTrace: SHOW_TRACE_PATH,
      }),
    [projectUrlName, isDemo, snapshotId],
  )

  return {
    projectUrlName,
    sessionId,
    showTrace: showTrace === SHOW_TRACE_PATH,
    ccoPath,
    ccoSessionPath,
    ccoSessionTracePath,
    apiUrl,
    apiMode: isDemo ? 'static' : 'last',
    isDemo,
    snapshotId,
  }
}
