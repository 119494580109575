import { Tab } from '@headlessui/react'
import React, { Fragment, ReactNode, useCallback } from 'react'
import { FlowDto, ProjectDto, Trace } from 'api/models'
import { useTranslation } from 'react-i18next'
import { SettingsTab } from 'components/flows/ra/settings/SettingsTab'
import { RaMvpStore } from 'components/regression-analysis-mvp/RaMvpStore'
import { BuildsTab } from 'components/flows/ra/builds/BuildsTab'
import { RunsTab } from 'components/flows/ra/runs/RunsTab'
import { TraceTable } from 'components/flows/TraceTable'

const enum TabType {
  RUNS,
  BUILDS,
  SETTINGS,
  TRACES,
}

const TABS: TabType[] = [TabType.RUNS, TabType.BUILDS, TabType.TRACES, TabType.SETTINGS]

interface FlowTabsProps {
  flowTracesData: Trace[] | undefined
  canWorkWithFlow: boolean
  project: ProjectDto
  flowDto: FlowDto
  raStore: RaMvpStore
  isAdmin: boolean
}

export const FlowTabs = (props: FlowTabsProps) => {
  const { project, flowDto, raStore, isAdmin } = props
  const { t } = useTranslation()
  const tabContentByType = useCallback(
    (type: TabType): ReactNode => {
      switch (type) {
        case TabType.RUNS:
          return <RunsTab raStore={raStore} />
        case TabType.BUILDS:
          return <BuildsTab raStore={raStore} />
        case TabType.SETTINGS:
          return (
            <SettingsTab
              projectDto={project}
              flowDto={flowDto}
              raStore={raStore}
              isAdmin={isAdmin}
            />
          )
        case TabType.TRACES:
          return <Traces {...props} />
        default:
          throw new Error(`Unknown RA tab code: ${type}`)
      }
    },
    [flowDto, project, props, raStore, isAdmin],
  )

  const tabTitleByType = useCallback(
    (type: TabType): string => {
      switch (type) {
        case TabType.RUNS:
          return t('ra.tabs.dashboard')
        case TabType.BUILDS:
          return t('ra.tabs.builds')
        case TabType.SETTINGS:
          return t('ra.tabs.settings')
        case TabType.TRACES:
          return t('ra.tabs.traces')
        default:
          throw new Error(`Unknown RA tab code: ${type}`)
      }
    },
    [t],
  )

  return (
    <Tab.Group defaultIndex={0}>
      <Tab.List className="pl-[107px] text-xs text-gray-normal mb-[24px]">
        {TABS.map((tabType) => (
          <Tab
            key={`tab-key-${tabType}`}
            className={({ selected }) =>
              `mr-[16px] p-[3px] pb-[3px] ${
                selected
                  ? 'text-white border-b-[2px] border-electro'
                  : 'hover:text-white hover:border-gray-normal'
              }`
            }
          >
            {tabTitleByType(tabType)}
          </Tab>
        ))}
      </Tab.List>
      <Tab.Panels as={Fragment}>
        {TABS.map((tabType) => (
          <Tab.Panel key={`tab-panel-${tabType}`} tabIndex={-1} className="flex flex-grow">
            {tabContentByType(tabType)}
          </Tab.Panel>
        ))}
      </Tab.Panels>
    </Tab.Group>
  )
}

const Traces = (props: FlowTabsProps) => {
  const { flowTracesData, canWorkWithFlow, project, flowDto } = props
  return (
    <TraceTable
      flowTracesData={flowTracesData}
      canWorkWithFlow={canWorkWithFlow}
      projectUrlName={project.urlName}
      flowProjectLocalId={flowDto.projectLocalId}
      isHiddenFlow={flowDto.hidden ?? undefined}
    />
  )
}
