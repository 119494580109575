import React from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate, useRouteError } from 'react-router-dom'

import { Button, ButtonVariantEnum } from 'components/Button'
import { i18n, TFunction } from 'i18next'
import { AxiosError } from 'axios'
import { isAxiosError } from 'utils/axios'
import { ApiError } from 'api/models'
import { PATH_ROOT } from 'utils/links'

interface ErrorComponentProps {
  fullReload?: boolean
}

export const ErrorComponent = ({ fullReload = false }: ErrorComponentProps) => {
  const { t, i18n: i18 } = useTranslation()
  const navigate = useNavigate()
  const location = useLocation()
  const error = useRouteError()

  const hasBack = history.length > 2

  const handleActionButtonClick = () => (hasBack ? navigate(-1) : navigate(PATH_ROOT))
  const handleReloadButtonClick = () => {
    if (fullReload) {
      document.location.reload()
    } else {
      navigate(location.pathname, { state: location.state, replace: true })
    }
  }
  const { title, message } = prepareTitleAndMessage(error, t, i18)

  return (
    <div className="text-center text-normal pb-[30px]">
      <h1 className="text-normal tracking-wide text-state-bad">{title}</h1>
      {message && <p className="text-small tracking-wide text-gray-normal mt-[12px]">{message}</p>}
      <div className="flex justify-center gap-[11px] mt-[16px]">
        <Button variant={ButtonVariantEnum.Text} onClick={handleActionButtonClick}>
          {hasBack ? t('errorPage.back') : t('errorPage.toMain')}
        </Button>
        <Button variant={ButtonVariantEnum.Text} onClick={handleReloadButtonClick}>
          {t('errorPage.retry')}
        </Button>
      </div>
    </div>
  )
}

interface TitleAndMessage {
  title: string
  message: string
}

function prepareTitleAndMessage(error: unknown, t: TFunction, i18: i18n): TitleAndMessage {
  let title = ''
  let message: string | undefined = ''

  if (!error) {
    title = t('errorPage.notFound.title')
    message = t('errorPage.notFound.message') ?? ''
    return { title, message }
  }

  if (isAxiosError(error)) {
    const axiosError = error as AxiosError<ApiError>
    const status = axiosError.response?.status
    if (!status) {
      title = t('errorPage.network.noConnection')
      message = t('errorPage.network.noConnectionMessage')
    } else {
      if (i18.exists(`apiError.${axiosError.response?.data.code}`)) {
        title = t(`apiError.${axiosError.response?.data.code}`)
      }
      message = axiosError.response?.data.message
      if (status === 404) {
        if (!title) {
          title = t('errorPage.notFound.title')
        }
        if (!message) {
          message = t('errorPage.notFound.message')
        }
      } else if (status === 400) {
        title = t('apiError.badRequest')
      } else if (status === 500) {
        title = t('apiError.internalServerError')
      } else if (status === 502) {
        title = t('apiError.badGateway')
      } else if (status === 503) {
        title = t('apiError.serviceUnavailable')
      }
    }
  }

  if (!title) {
    title = t('errorTitle')
  }
  if (!message) {
    message = t('errorMessage') ?? ''
  }

  return { title, message }
}
