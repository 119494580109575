import React, { ReactNode } from 'react'
import classNames from 'classnames'

interface DataColProps {
  children: ReactNode
  isDescription?: boolean
}

export const DataCol = ({ children, isDescription }: DataColProps) => {
  return (
    <div
      className={classNames(
        'flex items-center px-2 py-1 break-words leading-5',
        isDescription && 'text-small',
      )}
    >
      {children}
    </div>
  )
}
