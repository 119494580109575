import React from 'react'
import { RaMvpStore } from 'components/regression-analysis-mvp/RaMvpStore'
import { RunError } from 'components/flows/ra/runs/details/RunError'
import { Run } from 'components/regression-analysis-mvp/Run'
import { Header } from './details/Header'
import { Builds } from './details/Builds'
import { Statistics } from './details/Statistics'
import { Processing } from './details/Processing'
import { Data } from './details/Data'
import { InstrumentedResults } from './details/InstrumentedResults'

interface RunDetailsProps {
  raStore: RaMvpStore
  run: Run
}

export const RunDetails = ({ raStore, run }: RunDetailsProps) => {
  if (!run.isFinished) {
    return <Processing />
  }
  return (
    <div className="px-4 space-y-4">
      <Header run={run} />

      <Builds run={run.runDto} />
      <Statistics run={run} />
      <Data run={run} />
      <InstrumentedResults raStore={raStore} run={run} />
      <RunError run={run.runDto} />
    </div>
  )
}
