import { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'
import { Tooltip, TooltipChildrenRef } from 'components/tooltip/Tooltip'
import { TooltipPositionProps } from 'components/tooltip/types'
import commonTexts from 'locale/en/en-common.json'
import styled from 'styled-components/macro'

export type TooltipId = keyof typeof commonTexts.tooltips
export type ShortcutId = keyof typeof commonTexts.shortcuts

export enum ActionTooltipType {
  SHORTCUT = 'shortcut_tip',
  HINT = 'suggest_hint',
}

type ActionsTooltipProps = {
  text?: string
  tooltipType?: ActionTooltipType
  tooltipId?: TooltipId
  children: ReactElement
  childrenRef?: TooltipChildrenRef
  disabled?: boolean
} & TooltipPositionProps

export const ActionTooltip = ({
  tooltipType = ActionTooltipType.SHORTCUT,
  ...props
}: ActionsTooltipProps) => {
  const { t } = useTranslation()
  const { disabled, text, children, tooltipId, ...otherProps } = props
  const title = tooltipId
    ? t(
        `${tooltipType === ActionTooltipType.SHORTCUT ? 'tooltips' : 'tips'}.${tooltipId}`,
        tooltipId,
      )
    : text
  let shortcuts: ShortcutId[] | undefined
  if (tooltipId) {
    shortcuts = globalShortcuts.get(tooltipId)
  }

  return disabled || title === undefined ? (
    <>{children}</>
  ) : (
    <Tooltip
      animated={true}
      place="top"
      showAnimationTime={400}
      showDelay={500}
      offsetFromTarget={tooltipType === ActionTooltipType.HINT ? 2 : 4}
      visible={tooltipType === ActionTooltipType.HINT ? true : undefined}
      content={
        <>
          {tooltipType === ActionTooltipType.SHORTCUT && (
            <PlainActionsTooltip
              title={title}
              actions={shortcuts && shortcuts.map((shortcut) => t(`shortcuts.${shortcut}`))}
            />
          )}
          {tooltipType === ActionTooltipType.HINT && <SuggestActionsTooltip title={title} />}
        </>
      }
      {...otherProps}
    >
      {children}
    </Tooltip>
  )
}

const globalShortcuts = new Map<TooltipId, ShortcutId[]>([
  ['measurementTool', ['shift', 'moveCursor']],
  ['flagDetailsHide', ['h']],
  ['flagDetailsShow', ['h']],
  ['search', ['f']],
  ['flagDelete', ['delete']],
  ['connectionCreate', ['c']],
  ['filter', ['p']],
  ['searchExit', ['esc']],
  ['transparentMode', ['t']],
  ['dimDisconnectedSlices', ['o']],
  ['prevPath', ['<']],
  ['nextPath', ['>']],
  ['renderType', ['j']],
  ['hideLeftPanel', ['[']],
  ['hideRightPanel', [']']],
  ['showLeftPanel', ['[']],
  ['showRightPanel', [']']],
])

export const PlainActionsTooltip = (props: { title: string; actions?: string[] }) => {
  const { title, actions } = props

  return (
    <div
      className={classNames(
        'flex items-center h-[24px] px-[8px] bg-dark-dark5 rounded-sm',
        actions && 'pl-[12px]',
      )}
    >
      <p className="text-small text-gray-normal">{title}</p>
      {actions && (
        <span className="flex pl-[10px] mr-[-4px]">
          {actions.map((action, index) => (
            <span
              className="text-[11px] h-[16px] px-[4px] mx-[1px] text-dark-dark5 bg-gray-normal rounded-sm"
              key={String(index)}
            >
              {action}
            </span>
          ))}
        </span>
      )}
    </div>
  )
}

export const SuggestActionsTooltip = (props: { title: string }) => {
  const { title } = props

  return (
    <ArrowBubble className="flex items-center px-[16px] py-[12px] bg-sky rounded-[4px] mb-[8px]">
      <div className="flex text-small text-white">{title}</div>
    </ArrowBubble>
  )
}

const ArrowBubble = styled.div`
  &::after {
    position: absolute;
    bottom: 0px;
    left: 50%;
    width: 0;
    height: 0;
    content: '';
    transform: translateX(-50%);
    border-top: ${({ theme }) => `8px solid ${theme.colors.sky}`};
    border-right: 8px solid transparent;
    border-left: 8px solid transparent;
  }
`
