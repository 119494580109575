import { useLayoutEffect } from 'react'

/**
 * @description Accepts a ref to a DOM element and disables pinch-zoom gestures on that element.
 */
export function useDisablePinchZoom(ref: React.RefObject<HTMLElement>): void {
  const onWheel = (event: WheelEvent) => {
    if (event.deltaY !== 0 && event.ctrlKey) {
      event.preventDefault()
    }
  }

  useLayoutEffect(() => {
    const targetRef = ref?.current
    if (targetRef) {
      targetRef.addEventListener('wheel', onWheel, { passive: false })
    }
    return () => {
      if (targetRef) {
        targetRef.removeEventListener('wheel', onWheel)
      }
    }
  }, [ref])
}
