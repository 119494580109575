import { TraceDto } from 'api/__generated__/api-types'
import { generatePath, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import React, { useCallback } from 'react'
import { useUserQuery } from 'hooks/useApiQuery'
import { isUserProductScience } from 'utils/hiddenFlow'
import { Button, ButtonVariantEnum } from 'components/Button'
import { PATH_FLOW_TRACE_LIVE_DEMO } from 'pages/FlowTraceLiveDemo'
import { usePromise } from 'components/live-demo/LiveDemoPerFlowAssignModal'
import { useApi } from 'contexts/di-context'
import { LoadingSpinner } from 'components/LoadingSpinner'
import { useToaster } from 'hooks/useToaster'

export const FlowPageLiveDemoActions = ({
  trace,
  projectUrlName,
  flowProjectLocalId,
  refetch,
}: {
  trace: TraceDto
  projectUrlName: string
  flowProjectLocalId: string
  refetch: () => Promise<unknown>
}) => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const api = useApi()
  const toaster = useToaster()
  const detachLiveDemo = useCallback(() => {
    return api
      .removeLiveDemoAssignment({ projectUrlName, flowProjectLocalId })
      .then(() => refetch())
      .catch((err) => {
        toaster.error(err)
      })
  }, [api, toaster, flowProjectLocalId, projectUrlName, refetch])

  const { isPending, execute } = usePromise(detachLiveDemo)

  const { data: user } = useUserQuery()
  const isInternalUser = user ? isUserProductScience(user.email) : false

  return (
    <div className="pl-flowLeftPadding mb-[20px] flex">
      <Button
        className="mr-[20px]"
        onClick={() =>
          navigate(
            generatePath(PATH_FLOW_TRACE_LIVE_DEMO, {
              projectUrlName,
              flowProjectLocalId,
              traceProjectLocalId: trace.projectLocalId,
            }),
          )
        }
      >
        {t('components.header.liveDemo')}
      </Button>
      {isInternalUser && (
        <>
          <Button variant={ButtonVariantEnum.Outlined} onClick={execute} disabled={isPending}>
            {isPending ? <LoadingSpinner size={12} /> : t('components.header.detachLiveDemo')}
          </Button>
        </>
      )}
    </div>
  )
}
