import { useCallback } from 'react'
import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'
import { PsChartStore } from 'components/ps-chart/PsChartStore'
import { ConnectIcon } from 'components/ps-chart/details-view/ConnectIcon'
import {
  AnnotationIdAndType,
  AnnotationsStore,
  PinType,
} from 'components/ps-chart/stores/AnnotationsStore'
import { showGotItToaster } from 'components/ToastGotIt'
import { useToaster } from 'hooks/useToaster'
import { psLocalStorage } from 'utils/localStorage/PsLocalStorage'
import { FEATURE_FLAGS, useFeatureFlag } from 'utils/feature-flags'
import { SliceBox } from 'components/ps-chart/details-view/annotations/SliceBox'
import { SuitableSlices } from 'components/ps-chart/details-view/annotations/SuitableSlices'
import { Slice } from 'components/ps-chart/models/Slice'
import { FlowComputation } from 'components/ps-chart/details-view/annotations/FlowComputation'
import { SliceConnections } from 'components/ps-chart/details-view/SliceConnections'

export const AnnotationsTab = observer(function AnnotationsTab({
  psChartStore,
}: {
  psChartStore: PsChartStore
}) {
  const { t } = useTranslation()
  const toaster = useToaster()
  const selectedPin = psChartStore.annotationsStore.selectedId
  const { selectedSlice } = psChartStore.traceAnalyzeStore
  const hasFlowAccess = psChartStore.chartFeatures.annotations.flowAccess
  const suitableSlicesEnabled = useFeatureFlag(FEATURE_FLAGS.SUITABLE_SLICES) && hasFlowAccess

  const connectSlice = useCallback(
    (slice: Slice, type: PinType) => {
      if (selectedPin) {
        const wasAllConnected =
          psChartStore.annotationsStore.annotationsDataStore.isAllAnnotationsConnected
        return psChartStore.annotationsStore
          .connectToSlice(slice.id, type === PinType.ACTION ? slice.start : slice.end, selectedPin)
          .catch((reason) => {
            toaster.error(reason, 'psChart.annotation.error.connectToSlice')
          })
          .then(() => {
            if (
              !wasAllConnected &&
              psChartStore.annotationsStore.annotationsDataStore.isAllAnnotationsConnected
            ) {
              const messageId = 'annotations.create.connectSuccess'
              const alreadyGotIt = psLocalStorage.hasGotIt(messageId)
              if (alreadyGotIt) {
                toaster.info(messageId)
              } else {
                showGotItToaster(t(messageId), messageId)
              }
            }
          })
      }
    },
    [psChartStore, selectedPin, t, toaster],
  )

  if (!selectedPin || psChartStore.isEmpty) {
    return (
      <div className="p-[24px] text-small font-medium text-gray-normal">
        {t('psChart.details.emptyActionSelect')}
      </div>
    )
  }

  const annotation = psChartStore.annotationsStore.getById(selectedPin.id)!
  const currentTime = AnnotationsStore.getPinTime(annotation, selectedPin.type) ?? 0
  const time = new Date(currentTime / 1_000_000)
  const timeStr = String(time?.toISOString()?.slice(14, -2).replace('.', ':') || '00:00:00')

  const tag = AnnotationsStore.getPinTitle(annotation, selectedPin.type)
  const description = AnnotationsStore.getPinDescription(annotation, selectedPin.type)
  const pinBinding = psChartStore.annotationsStore.selectedPinBinding
  const hasConnectedSlice: boolean = pinBinding !== null && pinBinding.sliceId !== undefined

  return (
    <div className="h-details-panel overflow-y-scroll">
      <div className="p-[24px]">
        {suitableSlicesEnabled && <FlowComputation request={psChartStore.chartPageParams} />}

        <div className="mb-[16px]">
          <div className="text-small text-gray-normal mb-[16px]">
            {t('psChart.details.annotation')}
          </div>

          <div className="px-[16px] pt-[12px] pb-[8px] bg-dark-dark1 rounded-sm mb-[24px]">
            <div className="text-normal text-white opacity-90 break-words">{tag}</div>
            <div className="mb-[8px] text-small text-gray-normal mb-[16px] break-words">
              {description}
            </div>
            <div className=" text-small text-gray-faded text-right">{timeStr}</div>
          </div>
          {pinBinding && hasConnectedSlice && (
            <ConnectedSlice
              psChartStore={psChartStore}
              sliceId={pinBinding.sliceId!}
              pin={selectedPin}
            />
          )}
          {selectedSlice && selectedPin && pinBinding?.sliceId !== selectedSlice.id && (
            <>
              <div className="text-small text-gray-normal mb-[16px]">
                {t('psChart.details.assignToSlice')}
              </div>
              <SliceBox
                psChartStore={psChartStore}
                slice={selectedSlice}
                full={true}
                onConnect={() => connectSlice(selectedSlice, selectedPin.type)}
              />
            </>
          )}
        </div>

        {suitableSlicesEnabled && (
          <SuitableSlices
            assignedSliceId={pinBinding?.sliceId}
            selectedSliceId={selectedSlice?.id}
            psChartStore={psChartStore}
            annotationId={annotation.id}
            type={selectedPin.type}
            connectSlice={connectSlice}
          />
        )}

        <SliceConnections psChartStore={psChartStore} />
      </div>
    </div>
  )
})

export interface ConntectedSliceProps {
  psChartStore: PsChartStore
  sliceId: number
  pin: AnnotationIdAndType
}

const ConnectedSlice = observer(function ConnectedSlice({
  psChartStore,
  sliceId,
  pin,
}: ConntectedSliceProps) {
  const toaster = useToaster()
  const slice = psChartStore.sliceById.get(sliceId)!
  return (
    <>
      <Connection
        onDisconnect={() => {
          psChartStore.annotationsStore
            .disconnectFromSlice(pin)
            .catch((reason) => toaster.error(reason, 'psChart.annotation.error.disconnectToSlice'))
        }}
      />
      <SliceBox psChartStore={psChartStore} slice={slice} full={true} />
    </>
  )
})

const Connection = ({ onDisconnect }: { onDisconnect: () => void }) => {
  const { t } = useTranslation()

  return (
    <div className="flex flex-col items-center min-h-[48px] relative mt-[-24px]">
      <div className="h-[12px] w-[1px] bg-gray-faded" />
      <div
        className={
          'px-[18px] h-[24px] border-[1px] rounded-sm text-small text-gray-normal flex justify-center items-center border-gray-faded'
        }
      >
        {t('psChart.details.assignedTo')}
      </div>
      <div className="h-[12px] w-[1px] bg-gray-faded" />
      <div className="absolute top-0 right-0 bottom-0 flex items-center">
        <ConnectIcon
          isActive={false}
          onClick={onDisconnect}
          isConnected={true}
          isInProgress={false}
          isDisabled={false}
        />
      </div>
    </div>
  )
}
