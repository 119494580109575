import React from 'react'

export const openSecondModal = (
  setFirstModal: React.Dispatch<React.SetStateAction<boolean>>,
  setSecondModal: React.Dispatch<React.SetStateAction<boolean>>,
) => {
  setFirstModal(false)
  // headlessui doesn't support multiple dialogs (https://github.com/tailwindlabs/headlessui/discussions/1564)
  setTimeout(() => setSecondModal(true), 250)
}
