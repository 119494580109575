import axios from 'axios'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function isAxiosRequestCancelled(reason: any): boolean {
  return axios.isCancel(reason)
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function isAxiosError(reason: any): boolean {
  return axios.isAxiosError(reason)
}
