import { twMerge } from 'tailwind-merge'

import { ArkanoidLogo } from './ArkanoidLogo'
import { ArkanoidHeader } from './ArkanoidHeader'

export const ArkanoidStartScreen = ({
  onStart,
  name,
  setName,
  email,
  setEmail,
}: {
  onStart: () => void
  name?: string
  setName: (newName: string) => void
  email?: string
  setEmail: (newEmail: string) => void
}) => {
  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault()
    onStart()
  }

  return (
    <div className="h-full w-full font-turret-road bg-black">
      <ArkanoidHeader />
      <div className="h-full w-full flex justify-center items-center">
        <div className="mx-[120px]">
          <div className="flex items-center justify-center">
            <ArkanoidLogo className="w-[595px] translate-y-[20px]" />
          </div>

          <form onSubmit={handleSubmit}>
            <div className="flex w-full font-bold">
              <input
                autoComplete="off"
                data-lpignore="true"
                data-1p-ignore="true"
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value)}
                placeholder="Name"
                className={twMerge(
                  'flex-1 mr-2 bg-dark-dark2 text-white text-[80px] border-0 w-[50%] text-center leading-[140%]',
                  'focus:outline-none focus:shadow-none focus:border-0 focus:ring-0',
                )}
              />
              <input
                autoComplete="off"
                data-lpignore="true"
                data-1p-ignore="true"
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Email"
                className={twMerge(
                  'flex-1 mr-2 bg-dark-dark2 text-white text-[36px] border-0 w-[50%] text-center leading-[140%] font-normal',
                  'placeholder:text-[80px] placeholder:leading-[140%] placeholder:translate-y-[14px] placeholder:font-bold focus:placeholder:opacity-0',
                  'focus:outline-none focus:shadow-none focus:border-0 focus:ring-0',
                )}
              />
            </div>
            <p className="max-w-[1152px] text-center mx-auto my-[32px] text-normal text-gray-service font-default">
              Product Science, Inc. requires this information to contact you about our products and
              services. You may unsubscribe from these communications at any time. For information
              on how to unsubscribe, as well as our privacy practices and commitment to protecting
              your privacy, please review our Privacy Policy.
            </p>
            <button
              type="submit"
              className={twMerge(
                'mx-auto bg-[#D4FF5B] text-black text-[100px] px-[72px] block font-bold',
                'disabled:opacity-50',
              )}
              disabled={!name || !email}
            >
              Go
            </button>
          </form>
        </div>
      </div>
    </div>
  )
}
