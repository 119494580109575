import React, { FocusEvent, useCallback, useEffect, useRef, useState } from 'react'
import { TagsInput } from 'react-tag-input-component'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'

import { Icon } from 'components/Icon'
import { Modal } from 'components/Modal'
import { isEmailValid } from 'utils/validation'
import { useToaster } from 'hooks/useToaster'

import './tag-select.css'
import { useShareLinkContext } from './ShareLinkContext'

export const ShareLinkComponent = () => {
  const { t } = useTranslation()
  const [emailsList, setEmailsList] = useState<string[]>([])
  const [isModalOpened, setIsModalOpened] = useState(false)
  const [isInputFocused, setIsInputFocused] = useState(false)
  const [shareLink, setShareLink] = useState<string | null>(null)
  const [isSendInProgress, setIsSendInProgress] = useState(false)
  const mailFormRef = useRef<HTMLDivElement>(null)
  const toaster = useToaster()
  const { getShareLink, sendShareLink, isShareLinkReady } = useShareLinkContext()

  const beforeAddValidate = useCallback(
    (tag: string, existingTags: string[]) => isEmailValid(tag) && !existingTags.includes(tag),
    [],
  )

  const onBlur = useCallback(() => {
    setIsInputFocused(false)
  }, [])

  const onFocus = useCallback((e: FocusEvent) => {
    if (e.target.tagName.toLowerCase() === 'input') {
      setIsInputFocused(true)
    }
  }, [])

  const onRendered = useCallback(() => {
    if (isModalOpened && mailFormRef != null) {
      mailFormRef.current?.getElementsByTagName('input')?.item(0)?.focus()
    }
  }, [isModalOpened, mailFormRef])

  const onSend = useCallback(() => {
    if (!isShareLinkReady) {
      return
    }

    const input = mailFormRef?.current?.getElementsByTagName('input')?.item(0)
    if (input == null) {
      throw new Error('The input can not be found')
    }

    const emailsToSend: string[] = [...emailsList]

    const lastValue = input.value.trim()
    if (lastValue.length > 0) {
      if (!beforeAddValidate(lastValue, emailsList)) {
        toaster.error('components.shareWithTeam.incorrectInput')
        input.focus()
        return
      }
      emailsToSend.push(lastValue)
    }

    if (emailsToSend.length === 0) {
      toaster.info('components.shareWithTeam.emptyInput')
      input.focus()
      return
    }

    setIsSendInProgress(true)
    sendShareLink(emailsToSend)
      .then(() => {
        setIsModalOpened(false)
        setEmailsList([])
        input.value = ''
      })
      .catch((error) => {
        console.error(error)
        toaster.error(error, 'components.shareWithTeam.cantSendEmails')
      })
      .finally(() => {
        setIsSendInProgress(false)
      })
  }, [isShareLinkReady, emailsList, sendShareLink, beforeAddValidate, toaster])

  useEffect(() => {
    if (!isShareLinkReady) {
      return
    }
    getShareLink()
      .then((receivedShareUrl) => setShareLink(receivedShareUrl))
      .catch((error) => {
        console.error(error)
        toaster.error(error, 'components.shareWithTeam.cantFetchShareUrl')
      })
  }, [isShareLinkReady, getShareLink, toaster])

  const onCopyLink = useCallback(async () => {
    if (shareLink == null) {
      return null
    }
    try {
      await navigator.clipboard.writeText(shareLink)
      toaster.success('components.shareWithTeam.onCopy')
    } catch (error) {
      toaster.error(error, 'components.shareWithTeam.onCopyError')
    }
  }, [shareLink, toaster])

  return (
    <>
      <button
        className="text-gray-normal flex items-center mr-[16px] hover:text-white hover:cursor-pointer"
        onClick={() => setIsModalOpened(true)}
      >
        <Icon icon="share" className="mr-[9px]" />
        <div className="text-[11px]">{t('components.header.shareLink')}</div>
      </button>
      <Modal
        title={t('components.shareWithTeam.shareModalTitle')}
        isOpen={isModalOpened}
        onClose={() => setIsModalOpened(false)}
        onRendered={onRendered}
        additionalButton={{
          children: t('components.shareWithTeam.copyLinkBtn'),
          onClick: onCopyLink,
          disabled: shareLink == null,
        }}
        actionButton={{
          children: t('components.shareWithTeam.sendInviteBtn'),
          onClick: onSend,
          disabled: !isShareLinkReady || isSendInProgress,
        }}
      >
        <div className="text-small text-gray-normal pb-[16px] pt-[15px]">
          {t('components.shareWithTeam.actionText')}
        </div>
        <div
          onFocus={onFocus}
          onBlur={onBlur}
          ref={mailFormRef}
          className="ps-tags-input pb-[12px] text-small"
        >
          <TagsInput
            value={emailsList}
            onChange={setEmailsList}
            name="emails"
            placeHolder={t('components.shareWithTeam.emailInputPlaceholder')}
            beforeAddValidate={beforeAddValidate}
            disabled={!isShareLinkReady || isSendInProgress}
            separators={['Enter', ',', ' ', ';']}
          />
          <div
            className={classNames(
              'mx-[12px] mt-[-1px] h-[1px] transition-colors',
              isInputFocused ? 'bg-electro' : 'bg-transparent',
            )}
          />
        </div>
      </Modal>
    </>
  )
}

export const ShareLink = () => {
  const { isHidden } = useShareLinkContext()

  if (isHidden) {
    return null
  }

  return <ShareLinkComponent />
}
