import { computed, makeAutoObservable, when } from 'mobx'

import { walkSlices } from 'components/ps-chart/utils/slice'
import { TraceDataState } from 'components/ps-chart/stores/TraceDataStore'
import { VideoDataStore, VideoDataStoreStatus } from 'components/ps-chart/stores/VideoDataStore'
import { exhaustiveGuard } from 'utils/misc'
import { HorizontalStateStore } from 'components/ps-chart/stores/HorizontalStateStore'

const NANOS_IN_SEC = 1_000_000_000
const NANOS_IN_MICROSEC = 1_000
const MICROS_IN_SEC = 1_000_000

const VIDEO_PROCESSING_TIMEOUT = 1000 * 60 * 20

const CHOREOGRAPHER_FRAME = 'Frame #'
const UTILITY_THREAD_FRAME = 'F'
const RIGHT_PANEL_WIDTH = 288

const JUMP_TIME_IN_SECONDS = 0.2

export interface VideoPlayerState {
  get traceVideoPointerTimeNanos(): number

  get hoverTime(): number | null

  get videoAndTraceDeltaNanos(): number | null

  get videoLengthMicros(): number

  get status(): VideoDataStoreStatus

  get hasFullData(): boolean

  get frameExist(): boolean

  cancelVideoUpload(): void
}

export class VideoPlayerStore implements VideoPlayerState {
  private readonly threadsStore: TraceDataState
  private readonly hState: HorizontalStateStore
  private readonly videoDataStore: VideoDataStore

  private _frameExist = true
  private videoUploadAbortController: AbortController | null = null
  private wasVideoPlayingOnPointerDrag = false
  private _playbackRate = PlaybackRate.NORMAL

  loopMode = VideoLoopMode.OFF
  traceVideoPointerTimeNanos = 0
  isVideoPlaying = false
  hoverTime: number | null = null
  updateTimeout?: number

  constructor({
    threadsStore,
    hState,
    videoDataStore,
  }: {
    threadsStore: TraceDataState
    hState: HorizontalStateStore
    videoDataStore: VideoDataStore
  }) {
    makeAutoObservable<VideoPlayerStore, 'videoDataStore' | 'videoUploadAbortController'>(this, {
      videoDataStore: false,
      updateTimeout: false,
      startUpdateTimer: false,
      androidTraceTimeNanosByFrameId: computed({
        requiresReaction: true,
      }),
      iosTraceTimeNanosByFrameId: computed({
        requiresReaction: true,
      }),
      videoUploadAbortController: false,
    })
    this.threadsStore = threadsStore
    this.hState = hState
    this.videoDataStore = videoDataStore
  }

  load() {
    this.waitForVideoDataAndSetListeners()
  }

  dispose() {
    this.clearListeners()
    this.dropData()
  }

  get status() {
    return this.videoDataStore.status
  }

  get tag() {
    return this.videoDataStore.tag
  }

  get fileSize() {
    return this.videoDataStore.fileSize
  }

  get videoTimeMicrosByFrameId() {
    return this.videoDataStore.videoTimeMicrosByFrameId
  }

  get videoLengthMicros() {
    return this.videoDataStore.videoLengthMicros
  }

  get progress() {
    return this.videoDataStore.progress
  }

  get errorReason() {
    return this.videoDataStore.errorReason
  }

  get isDeletable() {
    return this.videoDataStore.isDeletable
  }

  get srcBlobUrl() {
    return this.videoDataStore.srcBlobUrl
  }

  private onLoadedmetadata = () => {
    const { tag } = this.videoDataStore
    if (tag != null) {
      const aspectRatio = tag.videoHeight / tag.videoWidth
      const maxHeight = RIGHT_PANEL_WIDTH * aspectRatio

      tag.style.setProperty('max-height', `${maxHeight}px`)
    }
  }

  clearListeners() {
    const { tag } = this.videoDataStore
    if (tag != null) {
      tag.removeEventListener('pause', this.onPauseVideo)
      tag.removeEventListener('play', this.onPlayVideo)
      tag.removeEventListener('loadedmetadata', this.onLoadedmetadata)
    }
  }

  private waitForVideoDataAndSetListeners() {
    // To avoid memory leaks setting timeout to dispose subscription if condition is never true
    when(() => this.isVideoProcessed, { timeout: VIDEO_PROCESSING_TIMEOUT })
      .then(() => {
        this.clearListeners()
        this.setVideoCurrentTime(this.videoLeftLimitSec!)
        this.setTraceVideoPointerTimeNanos(
          this.calcTraceTimeNanosByVideoTimeSec(this.videoLeftLimitSec!),
        )
        const { tag } = this.videoDataStore
        if (tag != null) {
          tag.playbackRate = this.playbackRateValue()
          tag.addEventListener('pause', this.onPauseVideo)
          tag.addEventListener('play', this.onPlayVideo)
          tag.addEventListener('loadedmetadata', this.onLoadedmetadata)
        }
      })
      .catch(() =>
        console.error('Video processing finished with an exceptional state', this.status),
      )
  }

  uploadVideo(
    file: File,
    onVideoProcessingError?: (error: string | null) => void,
    onVideoSuccessfullyUploaded?: () => void,
  ): Promise<void> {
    this.cancelVideoUpload() // abort previous uploading
    this.videoUploadAbortController = new AbortController()
    return this.videoDataStore
      .uploadVideo(
        file,
        onVideoProcessingError,
        onVideoSuccessfullyUploaded,
        this.videoUploadAbortController.signal,
      )
      .then(() => this.waitForVideoDataAndSetListeners())
  }

  cancelVideoUpload() {
    if (this.videoUploadAbortController !== null) {
      this.videoUploadAbortController.abort()
      this.videoUploadAbortController = null
    }
  }

  deleteVideo() {
    this.clearListeners()
    return this.videoDataStore.deleteVideo().then(() => {
      this.dropData()
    })
  }

  get hasFullData(): boolean {
    return (
      this.status === VideoDataStoreStatus.HAS_VIDEO_AND_READY && this.videoLeftLimitSec != null
    )
  }

  get isVideoProcessed(): boolean {
    return (
      this.status === VideoDataStoreStatus.EMPTY ||
      this.status === VideoDataStoreStatus.HAS_VIDEO_AND_READY
    )
  }

  private dropData() {
    this.hoverTime = null
    this.setTraceVideoPointerTimeNanos(0)
  }

  private updateTime() {
    if (
      this.tag == null ||
      this.videoAndTraceDeltaNanos == null ||
      this.videoRightLimitSec == null
    ) {
      return
    }
    const videoPositionWithTraceTimeDelta = this.calcTraceTimeNanosByVideoTimeSec(
      this.tag.currentTime,
    )
    const xRightLimit = this.loopMode === VideoLoopMode.GLOBAL ? this.hState.xMax : this.hState.xEnd
    const xLeftLimit =
      this.loopMode === VideoLoopMode.GLOBAL ? this.hState.xMin : this.hState.xStart
    const rightVideoLimit = Math.min(
      this.videoRightLimitSec,
      (this.videoAndTraceDeltaNanos + xRightLimit) / NANOS_IN_SEC,
    )
    const roundedRightVideoLimit = Math.floor(rightVideoLimit * 10) / 10
    const leftVideoLimit = Math.max(
      this.videoLeftLimitSec ?? 0,
      (this.videoAndTraceDeltaNanos - xLeftLimit) / NANOS_IN_SEC,
    )

    if (this.tag.currentTime >= roundedRightVideoLimit) {
      if (this.loopMode === VideoLoopMode.OFF) {
        this.pauseVideo()
        this.setVideoCurrentTime(rightVideoLimit)
        this.setVideoPointerTimeNanos(videoPositionWithTraceTimeDelta)
      } else {
        this.setVideoCurrentTime(leftVideoLimit)
        this.setVideoPointerTimeNanos(this.calcTraceTimeNanosByVideoTimeSec(leftVideoLimit))
        this.onPlayVideo()
      }
    }
    // can't put it in a previous section because we rely on changes of video playing inside
    this.setVideoPointerTimeNanos(videoPositionWithTraceTimeDelta)
  }

  private calcTraceTimeNanosByVideoTimeSec(videoTimeSec: number) {
    return videoTimeSec * NANOS_IN_SEC - (this.videoAndTraceDeltaNanos ?? 0)
  }

  get videoRightLimitSec(): number | null {
    if (this.videoAndTraceDeltaNanos == null || this.videoLeftLimitSec == null) {
      return null
    }

    const traceRightLimitInVideoTimeSec =
      (this.hState.xMax + this.videoAndTraceDeltaNanos) / NANOS_IN_SEC
    return Math.min(this.videoLengthMicros / MICROS_IN_SEC, traceRightLimitInVideoTimeSec)
  }

  /**
   * Return a double-precision floating-point playback time in seconds.
   */
  get videoLeftLimitSec(): number | null {
    if (this.videoAndTraceDeltaNanos == null) {
      return null
    }

    // Keep in mind that hState.xMin can be not zero
    const traceLeftLimitInVideoTimeSec =
      (this.hState.xMin + this.videoAndTraceDeltaNanos) / NANOS_IN_SEC
    return Math.max(0, traceLeftLimitInVideoTimeSec)
  }

  get hasTraceFrames(): boolean {
    return this.hasAndroidTraceFrames || this.hasIosTraceFrames
  }

  get hasAndroidTraceFrames(): boolean {
    return !!this.androidTraceTimeNanosByFrameId && this.androidTraceTimeNanosByFrameId.size > 0
  }

  get hasIosTraceFrames(): boolean {
    return !!this.iosTraceTimeNanosByFrameId && this.iosTraceTimeNanosByFrameId.size > 0
  }

  get androidTraceTimeNanosByFrameId(): Map<number, number> | null {
    if (this.threadsStore.mainThread === null) {
      return null
    }

    const traceTimeNanosByFrameId = new Map()
    walkSlices(this.threadsStore.mainThread.slices, (slice) => {
      if (slice.title.startsWith(CHOREOGRAPHER_FRAME)) {
        if (slice.root !== null) {
          const frameNum = +slice.title.replace(CHOREOGRAPHER_FRAME, '')
          traceTimeNanosByFrameId.set(frameNum, slice.root!.end)
        }
      }
    })

    return traceTimeNanosByFrameId
  }

  get iosTraceTimeNanosByFrameId(): Map<number, number> | null {
    if (this.threadsStore.threads.length === 0) {
      return null
    }

    const regex = new RegExp(`${UTILITY_THREAD_FRAME}(\\d+)`)

    const traceTimeNanosByFrameId = new Map()
    this.threadsStore.utilityThreads.forEach((thread) => {
      walkSlices(thread.slices, (slice) => {
        if (slice.title.startsWith(UTILITY_THREAD_FRAME)) {
          const match = slice.title.match(regex)
          if (match?.length) {
            const number = Number(match[1])
            traceTimeNanosByFrameId.set(number, slice.start)
          }
        }
      })
    })

    return traceTimeNanosByFrameId
  }

  checkTimeInBounds(timeNanos: number): boolean {
    if (
      this.videoAndTraceDeltaNanos == null ||
      this.videoRightLimitSec == null ||
      this.videoLeftLimitSec == null
    ) {
      return false
    }
    const timeInSec = (timeNanos + this.videoAndTraceDeltaNanos) / NANOS_IN_SEC

    return !(timeInSec > this.videoRightLimitSec || timeInSec < this.videoLeftLimitSec)
  }

  setVideoPointerTimeNanos(time: number) {
    if (this.checkTimeInBounds(time)) {
      this.setTraceVideoPointerTimeNanos(Math.min(time, this.hState.xEnd))
      if (this.videoPointerTimeSec != null) {
        this.checkFrameExist(this.videoPointerTimeSec)
      }
      if (!this.isVideoPlaying && this.tag != null && this.videoPointerTimeSec != null) {
        this.setVideoCurrentTime(this.videoPointerTimeSec)
      }
    } else {
      if (this.videoRightLimitSec && this.videoAndTraceDeltaNanos) {
        const rightVideoLimitNanos =
          this.videoRightLimitSec * NANOS_IN_SEC - this.videoAndTraceDeltaNanos
        if (time > rightVideoLimitNanos) {
          this.setTraceVideoPointerTimeNanos(time)
          this.setVideoCurrentTime(this.videoRightLimitSec)
        }
      }
    }
  }

  setVideoPointerTimeSeconds(time: number) {
    this.setVideoPointerTimeNanos(Math.round(time * NANOS_IN_SEC))
  }

  get videoPointerTimeSec(): number | null {
    return this.getVideoPointerTimeSecByTraceTimeNanos(this.traceVideoPointerTimeNanos)
  }

  get frameExist() {
    return this._frameExist
  }

  getVideoPointerTimeSecByTraceTimeNanos(traceTimeInNanos: number): number | null {
    if (this.videoAndTraceDeltaNanos == null) {
      return null
    }

    const findResult = this.findTraceFrameByTime(traceTimeInNanos)
    if (findResult) {
      return (findResult.foundFrameTimeMicros + 1) / MICROS_IN_SEC
    } else {
      return (this.videoAndTraceDeltaNanos + traceTimeInNanos) / NANOS_IN_SEC
    }
  }

  checkFrameExist = (videoTimeInSec: number) => {
    this._frameExist =
      videoTimeInSec >= (this.videoLeftLimitSec ?? 0) &&
      videoTimeInSec <= (this.videoRightLimitSec ?? 0)
  }

  private findTraceFrameByTime(timeNanos: number): FindTraceTimeResult | null {
    if (this.hasAndroidTraceFrames) {
      return this.findAndroidVideoTimeByTraceTime(timeNanos)
    }

    if (this.hasIosTraceFrames) {
      return this.findIosVideoTimeByTraceTime(timeNanos)
    }

    return null
  }

  /**
   * todo: could be optimized with binary search, check time and decide
   * measured: for a small video it takes about ≈ 0.3 ms to find on a M1 Macbook Pro
   */
  private findAndroidVideoTimeByTraceTime(timeNanos: number): FindTraceTimeResult | null {
    if (!this.androidTraceTimeNanosByFrameId || this.videoTimeMicrosByFrameId == null) {
      return null
    }

    let prevTime = 0
    for (const [frameId, time] of this.androidTraceTimeNanosByFrameId) {
      if (prevTime < timeNanos && timeNanos <= time) {
        const resultFrameId = prevTime > 0 ? frameId - 1 : frameId
        if (this.videoTimeMicrosByFrameId.has(resultFrameId)) {
          const currentFrameTime: number = this.videoTimeMicrosByFrameId.get(resultFrameId)!
          const nextApproximateValue = currentFrameTime + NANOS_IN_MICROSEC
          const nextFrameTime: number =
            this.videoTimeMicrosByFrameId.get(resultFrameId + 1) ?? nextApproximateValue
          const middleBetweenCurrentAndNext: number =
            currentFrameTime + (nextFrameTime - currentFrameTime) / 2
          return {
            foundFrameId: resultFrameId,
            middleBetweenFoundAndNextFrameTimeMicros: middleBetweenCurrentAndNext,
            foundFrameTimeMicros: currentFrameTime,
            nextFoundFrameTimeMicros: nextFrameTime,
          }
        } else {
          return null
        }
      }
      prevTime = time
    }

    return null
  }

  /**
   * todo: could be optimized with binary search, check time and decide
   * measured: for a small video it takes about ≈ 0.3 ms to find on a M1 Macbook Pro
   */
  private findIosVideoTimeByTraceTime(timeNanos: number): FindTraceTimeResult | null {
    if (this.iosTraceTimeNanosByFrameId == null || this.videoTimeMicrosByFrameId == null) {
      return null
    }

    for (const [frameId, frameTime] of this.iosTraceTimeNanosByFrameId) {
      const nextTraceTimeNanos = this.iosTraceTimeNanosByFrameId.get(frameId + 1)
      if (nextTraceTimeNanos) {
        if (frameTime < timeNanos && timeNanos <= nextTraceTimeNanos) {
          const currentVideoFrameTimeMicros = this.videoTimeMicrosByFrameId.get(frameId)
          if (currentVideoFrameTimeMicros) {
            const nextVideoFrameTimeMicros =
              this.videoTimeMicrosByFrameId.get(frameId + 1) ??
              currentVideoFrameTimeMicros + NANOS_IN_MICROSEC * 16
            const middle = nextVideoFrameTimeMicros
              ? currentVideoFrameTimeMicros +
                (nextVideoFrameTimeMicros - currentVideoFrameTimeMicros) / 2
              : currentVideoFrameTimeMicros + NANOS_IN_MICROSEC * 8
            return {
              foundFrameId: frameId,
              foundFrameTimeMicros: currentVideoFrameTimeMicros,
              nextFoundFrameTimeMicros: nextVideoFrameTimeMicros,
              middleBetweenFoundAndNextFrameTimeMicros: middle,
            }
          } else {
            return null
          }
        } // no else intentionally to continue for loop
      }
    }
    return null
  }

  /**
   * There are two "time values" in our app:
   *  1. The time inside the video. It always starts from zero.
   *    In the context of this time, zero is the beginning of the video file.
   *  2. The time inside the trace. In general, this time starts from the mobile-app start.
   *    Technically in the trace we have in our app the minimal values of this time can be not zero.
   *    The minimal (leftmost) value of this time is represented by the HorizontalState::xMin.
   *
   * To understand how we can transform one time into another we have to understand the shift between them.
   * The function below returns exactly this difference.
   * Mathematically it returns: VideoTraceDiff = VideoTime - TraceTime
   */
  get videoAndTraceDeltaNanos(): number | null {
    if (!this.hasTraceFrames || this.videoTimeMicrosByFrameId == null) {
      return null
    }

    if (this.videoTimeMicrosByFrameId.size > 0 && this.firstSyncedFrameId != null) {
      const firstSyncedFrameVideoTimeNanos = this.firstFrameVideoTimeMicros * NANOS_IN_MICROSEC
      const firstSyncedFrameTraceTimeNanos = this.traceTimeNanosByFrameId.get(
        this.firstSyncedFrameId,
      )!

      return firstSyncedFrameVideoTimeNanos - firstSyncedFrameTraceTimeNanos
    }

    return 0
  }

  private get traceTimeNanosByFrameId(): Map<number, number> {
    return this.hasAndroidTraceFrames
      ? this.androidTraceTimeNanosByFrameId!
      : this.iosTraceTimeNanosByFrameId!
  }

  get isTooSmallToPlayVideo(): boolean {
    return this.hState.xWidth < 500 * MICROS_IN_SEC
  }

  /**
   * @param timeSec A double-precision floating-point value indicating the current playback time in seconds.
   */
  setVideoCurrentTime(timeSec: number) {
    if (!this.tag) {
      return
    }
    this.tag.currentTime = timeSec
    this.checkFrameExist(timeSec)
  }

  playVideo() {
    this.tag?.play()
  }

  private onPlayVideo = () => {
    if (
      this.tag == null ||
      this.videoAndTraceDeltaNanos == null ||
      this.videoRightLimitSec == null ||
      this.videoLeftLimitSec == null
    ) {
      return
    }

    if (this.isTooSmallToPlayVideo) {
      this.tag.pause()
      return
    }

    this.isVideoPlaying = true

    const roundedRightVideoLimitSec =
      Math.floor(this.videoRightLimitSec * MICROS_IN_SEC) / MICROS_IN_SEC
    const roundedXStart = Math.ceil(this.hState.xStart / MICROS_IN_SEC) * MICROS_IN_SEC
    const roundedXEnd = Math.floor(this.hState.xEnd / MICROS_IN_SEC) * MICROS_IN_SEC
    const isCurrentTimeZero = this.tag.currentTime === 0
    const isAtRightVideoLimit = this.tag.currentTime >= roundedRightVideoLimitSec
    const roundedCurrentTimeToDecimal = Math.round(this.tag.currentTime * 10) / 10
    const roundedVideoLengthMicros = Math.round((this.videoLengthMicros / MICROS_IN_SEC) * 10) / 10
    const isAtVideoEnd = roundedCurrentTimeToDecimal >= roundedVideoLengthMicros
    const isOutsideOfCurrentTimeFrame =
      this.traceVideoPointerTimeNanos <= roundedXStart ||
      this.traceVideoPointerTimeNanos >= roundedXEnd

    if (
      this.loopMode !== VideoLoopMode.GLOBAL &&
      (isCurrentTimeZero || isAtRightVideoLimit || isAtVideoEnd || isOutsideOfCurrentTimeFrame)
    ) {
      const leftTimeFrame = (this.videoAndTraceDeltaNanos + this.hState.xStart) / NANOS_IN_SEC
      this.setVideoCurrentTime(
        leftTimeFrame < 0 ? 0 : Math.max(this.videoLeftLimitSec, leftTimeFrame),
      )
    }
    this.tag.play()
    this.startUpdateTimer()
  }

  startUpdateTimer() {
    if (this.updateTimeout) {
      clearTimeout(this.updateTimeout)
    }

    this.updateTimeout = window.setTimeout(() => {
      this.updateTime()
      if (this.isVideoPlaying) {
        this.startUpdateTimer()
      }
    }, 1000 / 60)
  }

  pauseVideo() {
    this.tag?.pause()
  }

  private onPauseVideo = () => {
    if (this.tag == null || this.videoAndTraceDeltaNanos == null) {
      return
    }

    if (this.isTooSmallToPlayVideo) {
      this.setVideoCurrentTime((this.videoAndTraceDeltaNanos + this.hState.xEnd) / NANOS_IN_SEC)
      this.setTraceVideoPointerTimeNanos(this.hState.xEnd)
      return
    }

    this.isVideoPlaying = false
  }

  setShowHover(time: number | null) {
    this.hoverTime = time
  }

  toggleVideoLoopMode = () => {
    const newVideoLoop = this.loopMode + 1
    this.loopMode = newVideoLoop > VideoLoopMode.GLOBAL ? VideoLoopMode.OFF : newVideoLoop
  }

  get firstSyncedFrameId(): number | null {
    if (!this.hasTraceFrames || this.videoTimeMicrosByFrameId == null) {
      return null
    }

    const { traceTimeNanosByFrameId, videoTimeMicrosByFrameId } = this
    if (this.videoTimeMicrosByFrameId.size > 0 && traceTimeNanosByFrameId.size > 0) {
      const videoFrameIds = VideoPlayerStore.safeAndSortedVideoFrameIds(
        traceTimeNanosByFrameId,
        videoTimeMicrosByFrameId,
      )
      const firstVideoFrameId = videoFrameIds[0]
      const firstTraceFrameId = [...traceTimeNanosByFrameId.keys()].reduce((min, cur) =>
        Math.min(min, cur),
      )
      const startFrameId = Math.max(firstVideoFrameId, firstTraceFrameId)
      for (const frameId of videoFrameIds) {
        if (frameId >= startFrameId) {
          return frameId
        }
      }
      throw new Error("Can't find trace/video sync delta")
    }

    return null
  }

  get lastSyncedFrameId(): number | null {
    if (!this.hasTraceFrames || this.videoTimeMicrosByFrameId == null) {
      return null
    }

    const { traceTimeNanosByFrameId } = this
    if (this.videoTimeMicrosByFrameId.size > 0 && traceTimeNanosByFrameId.size > 0) {
      const videoFrameIds = VideoPlayerStore.safeAndSortedVideoFrameIds(
        traceTimeNanosByFrameId,
        this.videoTimeMicrosByFrameId,
      )
      const lastVideoFrameId = videoFrameIds[videoFrameIds.length - 1]
      const lastTraceFrameId = [...traceTimeNanosByFrameId.keys()].reduce((max, cur) =>
        Math.max(max, cur),
      )
      return Math.min(lastVideoFrameId, lastTraceFrameId)
    }

    return null
  }

  private static safeAndSortedVideoFrameIds(
    traceTimeNanosByFrameId: ReadonlyMap<number, number>,
    videoTimeMicrosByFrameId: ReadonlyMap<number, number>,
  ): number[] {
    return [...videoTimeMicrosByFrameId.keys()]
      .filter((id) => traceTimeNanosByFrameId.has(id)) // https://linear.app/productscience/issue/PST-1471/video-not-parsed-bug
      .sort()
  }

  get firstFrameVideoTimeMicros(): number {
    if (this.firstSyncedFrameId == null || this.videoTimeMicrosByFrameId == null) {
      return 0
    }
    return this.videoTimeMicrosByFrameId.get(this.firstSyncedFrameId) || 0
  }

  get lastFrameVideoTimeMicros(): number {
    if (this.lastSyncedFrameId == null || this.videoTimeMicrosByFrameId == null) {
      return 0
    }
    return this.videoTimeMicrosByFrameId.get(this.lastSyncedFrameId) || 0
  }

  get videoLeftLimitPx(): number {
    if (this.videoLeftLimitSec == null || this.videoAndTraceDeltaNanos == null) {
      return 0
    }

    const videoLeftLimitInTraceTimeNanos =
      this.videoLeftLimitSec * NANOS_IN_SEC - this.videoAndTraceDeltaNanos
    return this.getXPxByTraceTimeNanos(videoLeftLimitInTraceTimeNanos)
  }

  get videoRightLimitPx(): number {
    if (this.videoRightLimitSec == null || this.videoAndTraceDeltaNanos == null) {
      return this.hState.width
    }

    const videoRightLimitInTraceTimeNanos =
      this.videoRightLimitSec * NANOS_IN_SEC - this.videoAndTraceDeltaNanos
    return this.getXPxByTraceTimeNanos(videoRightLimitInTraceTimeNanos)
  }

  private getFrameXPxById(syncedFrameId: number | null): number {
    if (!this.hasAndroidTraceFrames) {
      return 0
    }

    if (syncedFrameId != null) {
      const frameTraceTime = this.androidTraceTimeNanosByFrameId!.get(syncedFrameId)!
      return Math.floor((frameTraceTime * this.hState.width) / this.hState.xWidthTotal)
    }
    return 0
  }

  private getXPxByTraceTimeNanos(traceTimeNanos: number): number {
    return Math.floor((traceTimeNanos * this.hState.width) / this.hState.xWidthTotal)
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  isCancelled(reason: any): boolean {
    return this.videoDataStore.isCancelled(reason)
  }

  setTraceVideoPointerTimeNanos(timeNanos: number) {
    this.traceVideoPointerTimeNanos = timeNanos
  }

  rememberVideoPointerDragPlayingStatus() {
    this.wasVideoPlayingOnPointerDrag = this.isVideoPlaying
    if (this.isVideoPlaying) {
      this.pauseVideo()
    }
  }

  clearVideoPointerDragPlayingStatus() {
    if (this.wasVideoPlayingOnPointerDrag) {
      this.wasVideoPlayingOnPointerDrag = false
      this.playVideo()
    }
  }

  get playbackRate(): PlaybackRate {
    return this._playbackRate
  }

  private playbackRateValue(): number {
    switch (this._playbackRate) {
      case PlaybackRate.NORMAL:
        return 1.0
      case PlaybackRate.SLOWER_2X:
        return 0.5
      default:
        exhaustiveGuard(this._playbackRate)
    }
  }

  togglePlaybackRate() {
    this._playbackRate = this.switchPlaybackRate()
    if (this.tag) {
      this.tag.playbackRate = this.playbackRateValue()
    }
  }

  private switchPlaybackRate(): PlaybackRate {
    switch (this._playbackRate) {
      case PlaybackRate.NORMAL:
        return PlaybackRate.SLOWER_2X
      case PlaybackRate.SLOWER_2X:
        return PlaybackRate.NORMAL
      default:
        exhaustiveGuard(this._playbackRate)
    }
  }

  private jumpToTime(traceTimeInNanos: number) {
    if (
      this.videoLeftLimitSec === null ||
      this.videoRightLimitSec === null ||
      this.videoAndTraceDeltaNanos === null
    ) {
      return
    }

    let newTime = traceTimeInNanos

    const leftVideoLimit = Math.max(
      this.videoLeftLimitSec * NANOS_IN_SEC - this.videoAndTraceDeltaNanos,
      this.hState.xMin,
    )
    const rightVideoLimit = Math.min(
      this.videoRightLimitSec * NANOS_IN_SEC - this.videoAndTraceDeltaNanos,
      this.hState.xMax,
    )

    if (newTime < leftVideoLimit) {
      newTime = leftVideoLimit
    } else if (newTime > rightVideoLimit) {
      // Subtract 1 from the right video limit to ensure the video pointer remains visible at the right edge.
      newTime = rightVideoLimit - 1
    }

    this.setTraceVideoPointerTimeNanos(newTime)
    const newVideoTimeInSec = (newTime + this.videoAndTraceDeltaNanos) / NANOS_IN_SEC
    this.setVideoCurrentTime(newVideoTimeInSec)

    // Move selected area to ensure that video pointer is visible
    if (newTime < this.hState.xStart || newTime > this.hState.xEnd) {
      const isBackward = newTime < this.hState.xStart
      const newEnd = Math.min(
        isBackward ? newTime + this.hState.xWidth : newTime + 1,
        this.hState.xMax,
      )
      const newStart = Math.max(newEnd - this.hState.xWidth, this.hState.xMin)
      this.hState.setXStartAndZoom(newStart)
    }
  }

  private get videoNavigationFrames(): VideoNavigationFrame[] {
    return Array.from(this.traceTimeNanosByFrameId.entries()).map((frame, index) => ({
      id: frame[0],
      time: frame[1],
      index,
    }))
  }

  private findNearestVideoNavigationFrame(
    timeInNanos: number,
  ): FindNearestVideoNavigationFrameResult | null {
    let foundFrame: VideoNavigationFrame | undefined
    let nextFrame: VideoNavigationFrame | undefined
    let previousFrame: VideoNavigationFrame | undefined
    const frames = this.videoNavigationFrames

    const firstFrame = frames[0]
    const lastFrame = frames[frames.length - 1]

    if (timeInNanos < firstFrame.time) {
      foundFrame = firstFrame
    } else if (timeInNanos > lastFrame.time) {
      foundFrame = lastFrame
    } else {
      foundFrame = frames.find((currentFrame, index, allFrames) => {
        const next = allFrames[index + 1]
        if (next) {
          return timeInNanos >= currentFrame.time && timeInNanos < next.time
        }
        return true
      })
    }

    if (foundFrame) {
      previousFrame = frames[foundFrame.index - 1]
      nextFrame = frames[foundFrame.index + 1]

      return {
        nextFoundFrameTimeNanos: nextFrame?.time ?? foundFrame.time,
        previousFoundFrameTimeNanos: previousFrame?.time ?? foundFrame.time,
      }
    }
    return null
  }

  jumpToSiblingFrame(backward: boolean) {
    const currentFrame = this.findNearestVideoNavigationFrame(this.traceVideoPointerTimeNanos)
    if (currentFrame) {
      this.jumpToTime(
        backward ? currentFrame.previousFoundFrameTimeNanos : currentFrame.nextFoundFrameTimeNanos,
      )
    }
  }

  jumpForwardByTime() {
    this.jumpToTime(this.traceVideoPointerTimeNanos + JUMP_TIME_IN_SECONDS * NANOS_IN_SEC)
  }

  jumpBackwardByTime() {
    this.jumpToTime(this.traceVideoPointerTimeNanos - JUMP_TIME_IN_SECONDS * NANOS_IN_SEC)
  }
}

interface FindTraceTimeResult {
  foundFrameId: number
  foundFrameTimeMicros: number
  nextFoundFrameTimeMicros: number
  middleBetweenFoundAndNextFrameTimeMicros: number
}

interface VideoNavigationFrame {
  id: number
  time: number
  index: number
}

interface FindNearestVideoNavigationFrameResult {
  previousFoundFrameTimeNanos: number
  nextFoundFrameTimeNanos: number
}

export enum VideoLoopMode {
  OFF,
  LOCAL,
  GLOBAL,
}

export enum PlaybackRate {
  NORMAL = '1.0',
  SLOWER_2X = '0.5',
}
