import { ArkanoidLogo } from './ArkanoidLogo'
import { ArkanoidHeader } from './ArkanoidHeader'

export const ArkanoidSplashScreen = ({ onClick }: { onClick: () => void }) => {
  return (
    <div className="h-full w-full bg-black">
      <ArkanoidHeader />
      <div className="h-full w-full flex justify-center items-center">
        <button onClick={onClick} title="Start">
          <ArkanoidLogo />
        </button>
      </div>
    </div>
  )
}
