import { useTeamsQuery } from 'hooks/useApiQuery'

import { PATH_ROOT } from 'utils/links'
import { useNavigate } from 'react-router-dom'

export const useIsLiteUser = (navigateToRoot = false) => {
  const navigate = useNavigate()
  const { data } = useTeamsQuery()
  const isLite = data?.[0]?.psLite ?? null

  if (isLite === false && navigateToRoot) {
    navigate(PATH_ROOT, { replace: true })
  }

  return isLite
}
