import React from 'react'
import styled from 'styled-components/macro'
import tw from 'twin.macro'

import { Button, ButtonVariantEnum } from 'components/Button'
import { Icon } from 'components/Icon'

interface FilterTagProps {
  name: string
  onClick?: () => void
}

export const FilterTag = ({ name, onClick }: FilterTagProps) => {
  return (
    <View variant={ButtonVariantEnum.Text} onClick={onClick}>
      {name}
      <CloseIconView>
        <CloseIcon icon="cross" />
      </CloseIconView>
    </View>
  )
}

const View = styled(Button)`
  ${tw`border border-solid border-white/10`}
  display: flex;
  align-items: center;
  margin-left: 8px;
  padding-left: 7px;
  color: ${({ theme }) => theme.colors.white};
`

const CloseIconView = styled.span`
  position: relative;
  width: 24px;
  height: 24px;
  margin-left: -1px;
`

const CloseIcon = styled(Icon)`
  ${tw`text-icon`}
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  vertical-align: middle;
  color: ${({ theme }) => theme.colors.gray.normal};
`
