import { VerticalState } from 'components/ps-chart/models/VerticalState'
import { PsChartSettings } from 'components/ps-chart/models/settings'
import { ThreadsTopMap } from 'components/ps-chart/flame-chart/RenderEngine'
import {
  TopBottom,
  TopBottomByThreadId,
  TraceAnalyzeStore,
} from 'components/ps-chart/stores/TraceAnalyzeStore'
import { makeAutoObservable } from 'mobx'
import { Thread } from 'components/ps-chart/models/Thread'

export class VerticalStateStore implements VerticalState {
  readonly chartSettings: PsChartSettings

  readonly traceAnalyzeStore: TraceAnalyzeStore

  height = 0
  yStart = 0
  yStartPinned = 0

  constructor(traceAnalyzeStore: TraceAnalyzeStore, chartSettings: PsChartSettings) {
    makeAutoObservable(this, {
      chartSettings: false,
    })
    this.traceAnalyzeStore = traceAnalyzeStore
    this.chartSettings = chartSettings
  }

  get yEndMain(): number {
    return this.yStart + Math.min(this.mainTotalHeight, this.mainScrollableHeight)
  }

  get mainScrollableHeight(): number {
    const { headerHeight } = this.chartSettings.renderEngine
    return this.mainCanvasHeight - headerHeight
  }

  get scrollableHeight(): number {
    return this.mainScrollableHeight
  }

  get yEndPinned(): number {
    return this.yStartPinned + this.pinnedTotalHeight
  }

  get yStartFav(): number {
    const { headerHeight } = this.chartSettings.renderEngine
    return this.yEndPinned + headerHeight
  }

  get yEndFav(): number {
    return this.yStartFav + this.favTotalHeight
  }

  private threadsTotalHeight(threads: Thread[]): number {
    return threads.reduce((sum, thread) => {
      const height = this.traceAnalyzeStore.heightByThreadId.get(thread.id)!
      return sum + height
    }, 0)
  }

  get mainTotalHeight(): number {
    return this.threadsTotalHeight(this.traceAnalyzeStore.mainThreads)
  }

  get pinnedTotalHeight(): number {
    return this.threadsTotalHeight(this.traceAnalyzeStore.pinnedThreads)
  }

  get utilTotalHeight(): number {
    return this.threadsTotalHeight(this.traceAnalyzeStore.utilThreads)
  }

  get favTotalHeight(): number {
    return this.threadsTotalHeight(this.traceAnalyzeStore.favThreads)
  }

  get favCanvasHeight(): number {
    const { headerHeight } = this.chartSettings.renderEngine
    return this.favTotalHeight > 0 ? this.favTotalHeight + headerHeight : 0
  }

  get pinnedCanvasHeight(): number {
    return this.utilTotalHeight + this.favCanvasHeight
  }

  get mainCanvasHeight(): number {
    return this.height - this.pinnedCanvasHeight
  }

  get mainThreadsTopMap(): ThreadsTopMap {
    return this.threadsTopMap(this.traceAnalyzeStore.mainThreads, false)
  }

  get pinnedThreadsTopMap(): ThreadsTopMap {
    return this.threadsTopMap(this.traceAnalyzeStore.pinnedThreads, true)
  }

  get mainThreadsTopBottomMap(): TopBottomByThreadId {
    return this.threadsTopBottomMap(this.traceAnalyzeStore.mainThreads, this.mainThreadsTopMap)
  }

  get pinnedThreadsTopBottomMap(): TopBottomByThreadId {
    return this.threadsTopBottomMap(this.traceAnalyzeStore.pinnedThreads, this.pinnedThreadsTopMap)
  }

  private threadsTopBottomMap(threads: Thread[], topMap: ThreadsTopMap): TopBottomByThreadId {
    const result: TopBottomByThreadId = new Map()
    for (const thread of threads) {
      const threadTop = topMap.get(thread.id)!
      const height = this.traceAnalyzeStore.heightByThreadId.get(thread.id)!
      const threadBottom = threadTop + height
      const topBottom: TopBottom = [threadTop, threadBottom]
      result.set(thread.id, topBottom)
    }
    return result
  }

  private threadsTopMap(threads: Thread[], isPinned: boolean): ThreadsTopMap {
    const threadsTopTable: ThreadsTopMap = new Map()

    const { headerHeight } = this.chartSettings.renderEngine
    const { utilThreads } = this.traceAnalyzeStore

    let currentTop = 0
    const hasFrames = threads[0]?.isUtility
    if (!hasFrames) {
      currentTop += headerHeight
    }
    for (let index = 0; index < threads.length; index++) {
      const thread = threads[index]
      threadsTopTable.set(thread.id, currentTop)
      const height = this.traceAnalyzeStore.heightByThreadId.get(thread.id)!
      if (isPinned && index === utilThreads.length - 1) {
        currentTop += headerHeight
      }
      currentTop += height
    }

    return threadsTopTable
  }

  setHeight(height: number) {
    this.height = height
  }

  scrollY(deltaY: number) {
    const yStart = VerticalStateStore.getScrolled(
      deltaY,
      this.yStart,
      this.mainTotalHeight,
      this.scrollableHeight,
    )
    return this.setYStart(yStart)
  }

  setYStart(yStart: number) {
    this.yStart = yStart
  }

  static getScrolled(
    delta: number,
    prevValue: number,
    totalDist: number,
    visibleDist: number,
  ): number {
    let newValue = Math.max(0, prevValue + delta)

    const maxScroll = Math.max(0, totalDist - visibleDist)
    if (newValue > maxScroll) {
      newValue = maxScroll
    }

    return newValue
  }
}
