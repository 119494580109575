import { Asset } from 'contentful'
import { ContentfulEntry } from 'hooks/__generated__/contentful'
import { UseQueryResultResponse } from 'hooks/contentful/useContentfulEntry'

export enum QuickstartPages {
  Contents = 'Contents',
  FlowLibrary = 'FlowLibrary',
  Annotations = 'Annotations',
  PxA = 'PxA',
  TraceViewer = 'TraceViewer',
  Insights = 'Insights',
}

export type PageToContentMap = Map<QuickstartPages, ContentfulEntry>

export interface QuickstartPageLink {
  urlQueryParam: QuickstartPages
  linkText: string
}

export interface QuickstartContent {
  pageToContentMap: PageToContentMap
  linksToPages: QuickstartPageLink[]
  video: UseQueryResultResponse<Asset>
  videoThumbnail: UseQueryResultResponse<Asset>
}

export type QuickstartContentWithoutVideo = Omit<QuickstartContent, 'video' | 'videoThumbnail'>
