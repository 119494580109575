import { useTranslation } from 'react-i18next'
import React, { useCallback } from 'react'
import { Button, ButtonVariantEnum } from 'components/Button'
import { usePromise } from 'components/live-demo/LiveDemoPerFlowAssignModal'
import { useApi } from 'contexts/di-context'
import { LoadingSpinner } from 'components/LoadingSpinner'
import { useToaster } from 'hooks/useToaster'
import type { FlowComputationReq } from 'api/models'

export const UpdateFilterButton = ({ projectUrlName, flowProjectLocalId }: FlowComputationReq) => {
  const { t } = useTranslation()
  const { postSubmitFilterTraces } = useApi()
  const toaster = useToaster()
  const updateFilter = useCallback(() => {
    return postSubmitFilterTraces({ projectUrlName, flowProjectLocalId }).catch((err) => {
      toaster.error(err)
    })
  }, [postSubmitFilterTraces, toaster, flowProjectLocalId, projectUrlName])

  const { isPending, execute } = usePromise(updateFilter)

  return (
    <div className="ml-flowLeftPadding mb-[1px] flex bg-dark-dark3 px-4 py-2 text-small text-gray-normal">
      {t('components.header.smartFilter.smartFilter')}
      <Button
        variant={ButtonVariantEnum.Text}
        onClick={execute}
        disabled={isPending}
        className="ml-4"
      >
        {isPending ? <LoadingSpinner size={12} /> : t('components.header.smartFilter.updateFilter')}
      </Button>
    </div>
  )
}
