import React, { ReactNode, useMemo } from 'react'
import { observer } from 'mobx-react-lite'
import { useNavigate } from 'react-router-dom'
import { Layout } from 'components/Layout'
import { LiveDemo } from 'components/live-demo/LiveDemo'
import { ChartPageParams, ContentfulEntryDto } from 'api/models'
import { useChartStores } from 'hooks/useChartStores'
import { ErrorPageAuthorized } from 'pages/ErrorPageAuthorized'
import { ModuleProgressCircle } from 'components/ps-chart/ModuleProgressCircle'
import { useContentfulLiveDemoData } from 'hooks/contentful/useContentfulLiveDemoData'
import { useGlobalLiveDemoQuery, useTraceQuery } from 'hooks/useApiQuery'
import { BaseHeader } from 'components/header/BaseHeader'
import { PageTitle } from 'components/header/PageTitle'
import { SwitchButton } from 'components/header/SwitchButton'
import { useAuth } from 'contexts/auth-context'
import { Button, ButtonVariantEnum } from 'components/Button'
import { colors } from 'utils/styles/colors'
import { PATH_SIGN_UP_BETA } from 'pages/auth/SignUpEarlyBirdPage'

export const PATH_LIVE_DEMO = '/live-demo'

export const LiveDemoPage = () => {
  const { data, isLoading } = useGlobalLiveDemoQuery()

  const liveDemoChartParams = useMemo<ChartPageParams | null>(() => {
    if (data) {
      return {
        projectUrlName: data.projectUrlName,
        flowProjectLocalId: String(data.flowId),
        traceProjectLocalId: String(data.traceId),
      }
    }
    return null
  }, [data])

  if (liveDemoChartParams) {
    return <LiveDemoDataLoader chartPageParams={liveDemoChartParams} />
  }

  if (isLoading) {
    return (
      <PageLayout>
        <PageLoader />
      </PageLayout>
    )
  }

  return <PageError />
}

export const LiveDemoDataLoader = ({ chartPageParams }: { chartPageParams: ChartPageParams }) => {
  const { data, isLoading } = useTraceQuery(chartPageParams)
  const contentful = data?.liveDemo?.content.contentful

  if (contentful) {
    return <LiveDemoPageContent chartPageParams={chartPageParams} contentfulData={contentful} />
  }

  if (isLoading) {
    return (
      <PageLayout>
        <PageLoader />
      </PageLayout>
    )
  }

  return <PageError />
}

export const LiveDemoPageContent = observer(function LiveDemoPageWithChartParams({
  chartPageParams,
  contentfulData,
}: {
  chartPageParams: ChartPageParams
  contentfulData: ContentfulEntryDto
}) {
  const { psChartStore, chartDataStore, error } = useChartStores({
    isReadOnlyProject: true,
    chartPageParams,
  })

  const {
    entry: liveDemoData,
    isError: contentfulError,
    isLoading: contentfulIsLoading,
  } = useContentfulLiveDemoData(contentfulData)

  if (error || contentfulError) {
    return <PageError />
  }

  return (
    <PageLayout>
      {chartDataStore.isLoading || contentfulIsLoading ? (
        <PageLoader />
      ) : (
        <LiveDemo psChartStore={psChartStore} liveDemoData={liveDemoData!} />
      )}
    </PageLayout>
  )
})

const SignUpTrialButton = () => {
  const navigate = useNavigate()
  return (
    <Button
      borderColor={colors.state.good}
      variant={ButtonVariantEnum.Outlined}
      className="mr-[16px] border-2 border-lime max-h-[32px] max-w-[120px] text-small text-gray-normal hover:text-white"
      onClick={() => {
        navigate({ pathname: PATH_SIGN_UP_BETA })
      }}
    >
      Sign Up
    </Button>
  )
}

const ForUserOnlySwitchButton = () => {
  const { isSignedIn } = useAuth()
  return isSignedIn ? <SwitchButton isLiveDemo={true} /> : <SignUpTrialButton />
}

const PageLayout = ({ children }: { children: ReactNode }) => {
  return (
    <Layout
      pageConfig={{ withoutStyledContent: true }}
      headerComponent={
        <BaseHeader
          centerContent={<PageTitle titleKey="liveDemo" />}
          rightSideContent={<ForUserOnlySwitchButton />}
        />
      }
    >
      {children}
    </Layout>
  )
}

const PageLoader = () => (
  <main className="flex flex-grow relative">
    <ModuleProgressCircle />
  </main>
)

const PageError = () => <ErrorPageAuthorized fullReload={true} />
