import { Slice } from 'components/ps-chart/models/Slice'

/**
 * <p>Merger method is based on logic that time resolution is truncated to the closest power of 2 (in nanosecond space).
 * So by simple magical "floor" function we can merge all slices with same "floored" value into one slice.</p>
 *
 * @param slicesArray slices to merge
 * @param mergerNs resolution value
 */
export function merger(slicesArray: Slice[], mergerNs: number): Slice[] {
  const merged: { [key: number]: Slice } = {}
  return Object.values(
    slicesArray.reduce((processed, slice) => {
      const mergerStart = Math.floor((slice.start + mergerNs / 2) / mergerNs) * mergerNs
      const mergerEnd = Math.floor((slice.end + mergerNs / 2 - 1) / mergerNs) * mergerNs
      processed[mergerStart] = {
        ...slice,
        start: mergerStart,
        end: Math.max(mergerEnd, mergerStart + mergerNs),
      }
      return processed
    }, merged),
  )
}
