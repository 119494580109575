export enum FlagSource {
  USER = 'USER',
  INSTRUMENTATION = 'INSTRUMENTATION',
}

/**
 * For now, we don't have direct link as we have it for annotation flags,
 * so we need to find it manually.
 **/
export const INSTRUMENTATION_FLAG_SOURCE_SLICE_SEARCH_TERM = '@FLOW'

export interface Flag {
  id: number
  cid?: number
  time: number
  color: number | string
  title: string
  source?: FlagSource
}

export const getColor = (flag: Flag): string | number => {
  const hexRegex = /#(?:[0-9a-fA-F]{3}){1,2}\b/
  if (flag.source === FlagSource.INSTRUMENTATION) {
    const hexMatch = flag.title.match(hexRegex)
    return hexMatch !== null && hexMatch.length ? hexMatch[0] : 0
  }

  return flag.color
}
