import React from 'react'
import { useTranslation } from 'react-i18next'

import { Modal, ModalProps } from 'components/Modal'
import { Checkbox, CheckboxProps } from 'components/Checkbox'
import { ButtonTextColorVariantEnum } from 'components/Button'

interface ActionModalProps extends Omit<ModalProps, 'children'> {
  text: string | React.ReactNode
  checkboxProps?: CheckboxProps
}

export const ActionModal = ({
  isOpen,
  title,
  secondaryTitle,
  text,
  checkboxProps,
  onClose,
  size,
  actionButton,
  hiddenCloseButton,
}: ActionModalProps) => {
  const { t } = useTranslation()
  return (
    <Modal
      title={title}
      secondaryTitle={secondaryTitle}
      isOpen={isOpen}
      size={size}
      onClose={onClose}
      actionButton={{
        children: t('delete'),
        textColorVariant: ButtonTextColorVariantEnum.Bad,
        ...actionButton,
      }}
      hiddenCloseButton={hiddenCloseButton}
    >
      <div className="flex min-h-[51px] items-center">
        <p className="text-small text-gray-normal">{text}</p>
      </div>
      {checkboxProps && <Checkbox {...checkboxProps} className="mt-[16px]" />}
    </Modal>
  )
}
