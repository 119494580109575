import React from 'react'
import classNames from 'classnames'

interface FlagColorItemProps {
  backgroundColor: string
  className?: string
  noPadding?: boolean
}

export const FlagColorItem = ({
  backgroundColor,
  className,
  noPadding = false,
}: FlagColorItemProps) => {
  return (
    <div
      className={classNames(
        'inline-block items-center align-middle justify-between',
        !noPadding && 'px-[16px] py-[4px]',
        className,
      )}
    >
      <div className="w-[32px] h-[4px] align-middle inline-block" style={{ backgroundColor }} />
    </div>
  )
}
