import React from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { Icon } from 'components/Icon'

interface PageHeadProps {
  title: string
  secondaryTitle?: string
  backLink: string
}

export const PageHead = ({ title, secondaryTitle, backLink }: PageHeadProps) => {
  const { t } = useTranslation()
  return (
    <div className="flex items-end py-[27px] px-[24px]">
      <Icon
        as={Link}
        to={backLink}
        icon="arrow-round-l"
        className="transition-colors text-icon text-gray-normal hover:text-white"
        aria-label={t('goToBack')}
      />
      <div className="flex-1 pl-[53px]">
        <h1 className="text-header-small font-medium">
          {title} {secondaryTitle && <span className="text-gray-normal">{secondaryTitle}</span>}
        </h1>
      </div>
    </div>
  )
}
