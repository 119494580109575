import { makeAutoObservable } from 'mobx'
import { getQuantile } from 'components/ps-chart/dev-monitor/utils'

const MEASURE_DEFAULT_MAX_STORE = 30

const RENDERING_DEFAULT_SLOW_TIME = 30

export class RenderingMeasuringStore {
  isStarted = false
  private readonly measures: number[] = []

  constructor() {
    makeAutoObservable(this)
  }

  get renderTime(): number {
    const quantile = getQuantile(Array.from(this.measures), 0.9)

    return Math.round(quantile * Math.pow(10, 2)) / Math.pow(10, 2)
  }

  get renderTimeIsSlow(): boolean {
    return (
      this.measuresCount === MEASURE_DEFAULT_MAX_STORE &&
      this.renderTime > RENDERING_DEFAULT_SLOW_TIME
    )
  }

  get measuresCount(): number {
    return this.measures.length
  }

  start() {
    this.clear()
    this.isStarted = true
  }

  stop() {
    this.isStarted = false
  }

  clear() {
    this.measures.splice(0, this.measures.length)
  }

  addMeasure(measure: number) {
    if (!this.isStarted && this.measuresCount > MEASURE_DEFAULT_MAX_STORE) {
      this.clear()
    }
    this.measures.push(measure)
  }
}
