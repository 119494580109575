import React, { useCallback, useMemo } from 'react'
import { useLocation } from 'react-router-dom'
import NProgress from 'nprogress'

import { RequestFreeTrialForm } from 'components/free-trial/RequestFreeTrialForm'
import { useToaster } from 'hooks/useToaster'
import { useAuth } from 'contexts/auth-context'
import { Layout } from 'components/Layout'
import { PATH_ROOT } from 'utils/links'
import { Footer } from 'components/Footer'
import { JavaKotlinOnly } from 'components/free-trial/JavaKotlinOnly'
import { EARLY_BIRD_FORM_LINK } from 'components/live-demo/LiveDemo'
import { VisitWebsite } from 'components/free-trial/VisitWebsite'

export const PATH_SIGN_UP_EARLY_BIRD = '/sign-up-early-bird'
export const PATH_SIGN_UP_BETA = '/sign-up-beta'

const SimpleHeader = () => {
  return (
    <div className="h-[48px] px-[16px] bg-[#191919] border-b-[1px] border-solid border-[#383838] flex items-center">
      <svg
        width="201"
        height="20"
        viewBox="0 0 201 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0 20V4.69945H3.08892V6.12022H3.19924C3.43459 5.81421 3.72142 5.52277 4.05973 5.2459C4.85402 4.66302 5.74393 4.37158 6.72944 4.37158C8.20035 4.37158 9.42857 4.91803 10.4141 6.01093C11.4143 7.08925 11.9144 8.51002 11.9144 10.2732C11.9144 12.0364 11.4143 13.4645 10.4141 14.5574C9.42857 15.6357 8.20035 16.1749 6.72944 16.1749C5.74393 16.1749 4.85402 15.8834 4.05973 15.3005C3.72142 15.0237 3.43459 14.7322 3.19924 14.4262H3.08892V20H0ZM3.88322 7.86885C3.35369 8.43716 3.08892 9.23862 3.08892 10.2732C3.08892 11.3078 3.35369 12.1166 3.88322 12.6995C4.41275 13.2678 5.10408 13.5519 5.95721 13.5519C6.76621 13.5519 7.42812 13.2605 7.94295 12.6776C8.45777 12.0947 8.71517 11.2933 8.71517 10.2732C8.71517 9.25319 8.45777 8.45173 7.94295 7.86885C7.42812 7.28597 6.76621 6.99453 5.95721 6.99453C5.10408 6.99453 4.41275 7.28597 3.88322 7.86885Z"
          fill="#B3B3B3"
        />
        <path
          d="M13.5692 15.847V4.69945H16.6581V6.3388H16.7684C16.9008 5.98907 17.0994 5.68306 17.3642 5.42076C17.9672 4.79417 18.7248 4.48087 19.6367 4.48087H20.9615V7.43169H19.3058C18.4673 7.43169 17.8128 7.65756 17.3421 8.10929C16.8861 8.54645 16.6581 9.15847 16.6581 9.94535V15.847H13.5692Z"
          fill="#B3B3B3"
        />
        <path
          d="M23.174 14.4699C21.9972 13.3188 21.4089 11.9199 21.4089 10.2732C21.4089 8.62659 21.9972 7.23497 23.174 6.09836C24.3507 4.94718 25.8216 4.37158 27.5867 4.37158C29.3371 4.37158 30.8006 4.94718 31.9774 6.09836C33.1688 7.23497 33.7645 8.62659 33.7645 10.2732C33.7645 11.9199 33.1688 13.3188 31.9774 14.4699C30.8006 15.6066 29.3371 16.1749 27.5867 16.1749C25.8216 16.1749 24.3507 15.6066 23.174 14.4699ZM25.4686 7.91257C24.8949 8.52459 24.6081 9.31147 24.6081 10.2732C24.6081 11.235 24.8949 12.0219 25.4686 12.6339C26.0422 13.2459 26.7483 13.5519 27.5867 13.5519C28.4251 13.5519 29.1312 13.2459 29.7048 12.6339C30.2785 12.0219 30.5653 11.235 30.5653 10.2732C30.5653 9.31147 30.2785 8.52459 29.7048 7.91257C29.1312 7.30055 28.4251 6.99453 27.5867 6.99453C26.7483 6.99453 26.0422 7.30055 25.4686 7.91257Z"
          fill="#B3B3B3"
        />
        <path
          d="M36.2434 14.5574C35.2579 13.4645 34.7652 12.0364 34.7652 10.2732C34.7652 8.51002 35.2579 7.08925 36.2434 6.01093C37.2437 4.91803 38.4792 4.37158 39.9501 4.37158C40.9357 4.37158 41.8256 4.66302 42.6199 5.2459C42.9582 5.52277 43.245 5.81421 43.4803 6.12022H43.5907V0H46.6796V15.847H43.5907V14.4262H43.4803C43.245 14.7322 42.9582 15.0237 42.6199 15.3005C41.8256 15.8834 40.9357 16.1749 39.9501 16.1749C38.4792 16.1749 37.2437 15.6357 36.2434 14.5574ZM38.7366 7.86885C38.2218 8.45173 37.9644 9.25319 37.9644 10.2732C37.9644 11.2933 38.2218 12.0947 38.7366 12.6776C39.2515 13.2605 39.9134 13.5519 40.7224 13.5519C41.5755 13.5519 42.2668 13.2678 42.7964 12.6995C43.3259 12.1166 43.5907 11.3078 43.5907 10.2732C43.5907 9.23862 43.3259 8.43716 42.7964 7.86885C42.2668 7.28597 41.5755 6.99453 40.7224 6.99453C39.9134 6.99453 39.2515 7.28597 38.7366 7.86885Z"
          fill="#B3B3B3"
        />
        <path
          d="M48.886 11.4754V4.69945H51.9749V11.0383C51.9749 11.8543 52.1735 12.4809 52.5706 12.918C52.9825 13.3406 53.5561 13.5519 54.2916 13.5519C55.027 13.5519 55.6522 13.275 56.167 12.7213C56.6818 12.1676 56.9392 11.4608 56.9392 10.6011V4.69945H60.0281V15.847H56.9392V14.3169H56.8289C56.6524 14.6521 56.3803 14.9654 56.0125 15.2568C55.2182 15.8689 54.3136 16.1749 53.2987 16.1749C51.9013 16.1749 50.8129 15.7741 50.0333 14.9727C49.2684 14.1712 48.886 13.0055 48.886 11.4754Z"
          fill="#B3B3B3"
        />
        <path
          d="M63.3208 14.4699C62.1588 13.3188 61.5778 11.9199 61.5778 10.2732C61.5778 8.62659 62.1588 7.23497 63.3208 6.09836C64.4975 4.94718 65.939 4.37158 67.6453 4.37158C69.6899 4.37158 71.249 5.10018 72.3228 6.55738C72.8082 7.18397 73.1612 7.91257 73.3819 8.74317H70.1826C70.0503 8.42259 69.8737 8.13115 69.6531 7.86885C69.0942 7.28597 68.4249 6.99453 67.6453 6.99453C66.8657 6.99453 66.1891 7.30055 65.6154 7.91257C65.0565 8.52459 64.777 9.31147 64.777 10.2732C64.777 11.235 65.0565 12.0219 65.6154 12.6339C66.1891 13.2459 66.8657 13.5519 67.6453 13.5519C68.4249 13.5519 69.0942 13.2605 69.6531 12.6776C69.8737 12.4153 70.0503 12.1239 70.1826 11.8033H73.3819C73.1759 12.6485 72.8376 13.377 72.3669 13.9891C71.2932 15.4463 69.7193 16.1749 67.6453 16.1749C65.939 16.1749 64.4975 15.6066 63.3208 14.4699Z"
          fill="#B3B3B3"
        />
        <path
          d="M74.0429 7.75719V4.69945H75.4771V0H78.566V4.69945H90.702V7.75719H78.566V12.7893H90.702V15.847H75.4771V7.75719H74.0429Z"
          fill="#B3B3B3"
        />
        <path
          d="M91.4639 12.2404H94.6632C94.7661 12.5027 94.9279 12.7432 95.1486 12.9617C95.6193 13.4281 96.2665 13.6612 97.0902 13.6612C98.3405 13.6612 98.9656 13.2969 98.9656 12.5683C98.9656 12.4517 98.9362 12.3497 98.8773 12.2623C98.8185 12.1603 98.7155 12.0729 98.5685 12C98.4214 11.9126 98.2816 11.8397 98.1492 11.7814C98.0169 11.7231 97.8183 11.6648 97.5535 11.6066C97.3035 11.5337 97.0975 11.4827 96.9357 11.4536C96.7886 11.4244 96.5459 11.3734 96.2076 11.3005C95.884 11.2277 95.6487 11.1767 95.5016 11.1475C93.1775 10.6521 92.0155 9.52277 92.0155 7.75956C92.0155 6.82696 92.4347 6.03279 93.2732 5.37705C94.1263 4.70674 95.2883 4.37158 96.7592 4.37158C98.7303 4.37158 100.209 4.97632 101.194 6.18579C101.621 6.71038 101.907 7.30783 102.055 7.97814H98.9656C98.8626 7.75956 98.7303 7.57741 98.5685 7.43169C98.1125 7.06739 97.5094 6.88524 96.7592 6.88524C96.2297 6.88524 95.8399 6.97268 95.5899 7.14754C95.3398 7.30783 95.2148 7.51184 95.2148 7.75956C95.2148 7.84699 95.2295 7.93443 95.2589 8.02186C95.303 8.09472 95.3692 8.16758 95.4575 8.24044C95.5457 8.29872 95.634 8.35701 95.7222 8.4153C95.8105 8.45902 95.9355 8.51002 96.0973 8.56831C96.2591 8.61202 96.3988 8.65574 96.5165 8.69945C96.6489 8.74317 96.8181 8.78689 97.024 8.8306C97.2299 8.87432 97.4064 8.91075 97.5535 8.93989C97.7006 8.96903 97.8918 9.01275 98.1272 9.07104C98.3625 9.11475 98.5537 9.15118 98.7008 9.18033C101.01 9.69035 102.165 10.8197 102.165 12.5683C102.165 13.5446 101.709 14.3898 100.797 15.1038C99.8849 15.8178 98.6493 16.1749 97.0902 16.1749C95.0015 16.1749 93.457 15.5191 92.4568 14.2076C92.0008 13.6102 91.6699 12.9545 91.4639 12.2404Z"
          fill="#B3B3B3"
        />
        <path
          d="M104.796 14.4699C103.634 13.3188 103.053 11.9199 103.053 10.2732C103.053 8.62659 103.634 7.23497 104.796 6.09836C105.973 4.94718 107.415 4.37158 109.121 4.37158C111.166 4.37158 112.725 5.10018 113.798 6.55738C114.284 7.18397 114.637 7.91257 114.858 8.74317H111.658C111.526 8.42259 111.349 8.13115 111.129 7.86885C110.57 7.28597 109.901 6.99453 109.121 6.99453C108.341 6.99453 107.665 7.30055 107.091 7.91257C106.532 8.52459 106.253 9.31147 106.253 10.2732C106.253 11.235 106.532 12.0219 107.091 12.6339C107.665 13.2459 108.341 13.5519 109.121 13.5519C109.901 13.5519 110.57 13.2605 111.129 12.6776C111.349 12.4153 111.526 12.1239 111.658 11.8033H114.858C114.652 12.6485 114.313 13.377 113.843 13.9891C112.769 15.4463 111.195 16.1749 109.121 16.1749C107.415 16.1749 105.973 15.6066 104.796 14.4699Z"
          fill="#B3B3B3"
        />
        <path
          d="M116.688 2.79781C116.35 2.46266 116.18 2.0765 116.18 1.63934C116.18 1.20219 116.35 0.823315 116.688 0.502732C117.041 0.167577 117.46 0 117.946 0C118.431 0 118.843 0.167577 119.181 0.502732C119.534 0.823315 119.711 1.20219 119.711 1.63934C119.711 2.0765 119.534 2.46266 119.181 2.79781C118.843 3.1184 118.431 3.27869 117.946 3.27869C117.46 3.27869 117.041 3.1184 116.688 2.79781ZM116.401 15.847V4.69945H119.49V15.847H116.401Z"
          fill="#B3B3B3"
        />
        <path
          d="M122.778 14.4699C121.616 13.3188 121.035 11.9199 121.035 10.2732C121.035 8.62659 121.616 7.23497 122.778 6.09836C123.954 4.94718 125.396 4.37158 127.102 4.37158C128.838 4.37158 130.264 4.93989 131.382 6.0765C132.5 7.21311 133.059 8.61202 133.059 10.2732V10.929H124.234C124.351 11.6576 124.682 12.2769 125.227 12.7869C125.771 13.2969 126.396 13.5519 127.102 13.5519C127.882 13.5519 128.551 13.3333 129.11 12.8962C129.33 12.7067 129.507 12.4882 129.639 12.2404H132.949C132.699 12.9836 132.331 13.6393 131.846 14.2076C130.669 15.5191 129.088 16.1749 127.102 16.1749C125.396 16.1749 123.954 15.6066 122.778 14.4699ZM124.344 9.07104H129.75C129.632 8.48816 129.33 8 128.845 7.60656C128.374 7.19854 127.793 6.99453 127.102 6.99453C126.411 6.99453 125.822 7.19126 125.337 7.5847C124.852 7.97814 124.521 8.47359 124.344 9.07104Z"
          fill="#B3B3B3"
        />
        <path
          d="M134.705 15.847V4.69945H137.794V6.22951H137.905C138.096 5.89435 138.368 5.58834 138.721 5.31148C139.53 4.68488 140.435 4.37158 141.435 4.37158C142.832 4.37158 143.913 4.77231 144.678 5.57377C145.458 6.37523 145.848 7.54098 145.848 9.07104V15.847H142.759V9.5082C142.759 8.69217 142.553 8.07286 142.141 7.65027C141.744 7.21311 141.177 6.99453 140.442 6.99453C139.707 6.99453 139.081 7.2714 138.567 7.82514C138.052 8.37887 137.794 9.08561 137.794 9.94535V15.847H134.705Z"
          fill="#B3B3B3"
        />
        <path
          d="M149.033 14.4699C147.87 13.3188 147.289 11.9199 147.289 10.2732C147.289 8.62659 147.87 7.23497 149.033 6.09836C150.209 4.94718 151.651 4.37158 153.357 4.37158C155.402 4.37158 156.961 5.10018 158.035 6.55738C158.52 7.18397 158.873 7.91257 159.094 8.74317H155.894C155.762 8.42259 155.585 8.13115 155.365 7.86885C154.806 7.28597 154.137 6.99453 153.357 6.99453C152.577 6.99453 151.901 7.30055 151.327 7.91257C150.768 8.52459 150.489 9.31147 150.489 10.2732C150.489 11.235 150.768 12.0219 151.327 12.6339C151.901 13.2459 152.577 13.5519 153.357 13.5519C154.137 13.5519 154.806 13.2605 155.365 12.6776C155.585 12.4153 155.762 12.1239 155.894 11.8033H159.094C158.888 12.6485 158.549 13.377 158.079 13.9891C157.005 15.4463 155.431 16.1749 153.357 16.1749C151.651 16.1749 150.209 15.6066 149.033 14.4699Z"
          fill="#B3B3B3"
        />
        <path
          d="M161.718 14.4699C160.556 13.3188 159.975 11.9199 159.975 10.2732C159.975 8.62659 160.556 7.23497 161.718 6.09836C162.895 4.94718 164.337 4.37158 166.043 4.37158C167.778 4.37158 169.205 4.93989 170.323 6.0765C171.441 7.21311 172 8.61202 172 10.2732V10.929H163.175C163.292 11.6576 163.623 12.2769 164.167 12.7869C164.712 13.2969 165.337 13.5519 166.043 13.5519C166.822 13.5519 167.492 13.3333 168.051 12.8962C168.271 12.7067 168.448 12.4882 168.58 12.2404H171.89C171.64 12.9836 171.272 13.6393 170.787 14.2076C169.61 15.5191 168.029 16.1749 166.043 16.1749C164.337 16.1749 162.895 15.6066 161.718 14.4699ZM163.285 9.07104H168.69C168.573 8.48816 168.271 8 167.786 7.60656C167.315 7.19854 166.734 6.99453 166.043 6.99453C165.351 6.99453 164.763 7.19126 164.278 7.5847C163.792 7.97814 163.461 8.47359 163.285 9.07104Z"
          fill="#B3B3B3"
        />
        <path
          d="M200.547 7.76401H200.895V8.54401C200.735 8.56801 200.559 8.58001 200.367 8.58001C199.719 8.58001 199.371 8.28401 199.323 7.69201C198.891 8.35601 198.219 8.68801 197.307 8.68801C196.627 8.68801 196.091 8.53201 195.699 8.22001C195.315 7.90001 195.123 7.45201 195.123 6.87601C195.123 5.75601 195.887 5.11601 197.415 4.95601L198.303 4.86001C198.951 4.78801 199.275 4.50401 199.275 4.00801C199.275 3.66401 199.167 3.41601 198.951 3.26401C198.743 3.11201 198.387 3.03601 197.883 3.03601C197.347 3.03601 196.959 3.12801 196.719 3.31201C196.487 3.48801 196.355 3.80401 196.323 4.26001H195.363C195.443 2.89201 196.283 2.20801 197.883 2.20801C199.435 2.20801 200.211 2.80801 200.211 4.00801V7.46401C200.211 7.66401 200.323 7.76401 200.547 7.76401ZM197.355 7.87201C197.867 7.87201 198.315 7.71601 198.699 7.40401C199.083 7.10001 199.275 6.66401 199.275 6.09601V5.36401C199.099 5.50001 198.827 5.58801 198.459 5.62801L197.559 5.73601C197.039 5.80001 196.667 5.92001 196.443 6.09601C196.227 6.27201 196.119 6.52401 196.119 6.85201C196.119 7.17201 196.223 7.42401 196.431 7.60801C196.639 7.78401 196.947 7.87201 197.355 7.87201Z"
          fill="#B3B3B3"
        />
        <path
          d="M194.525 2.3521V3.1921H193.253V7.2121C193.253 7.5641 193.489 7.7401 193.961 7.7401H194.525V8.5441C194.261 8.5681 194.033 8.5801 193.841 8.5801C192.809 8.5801 192.293 8.1521 192.293 7.2961V3.1921H191.273V2.3521H192.293V0.600098H193.253V2.3521H194.525Z"
          fill="#B3B3B3"
        />
        <path
          d="M191.04 5.44801V5.78401H186.336C186.384 6.45601 186.572 6.96401 186.9 7.30801C187.228 7.64401 187.676 7.81201 188.244 7.81201C189.1 7.81201 189.648 7.42801 189.888 6.66001H190.884C190.716 7.28401 190.404 7.78001 189.948 8.14801C189.492 8.50801 188.916 8.68801 188.22 8.68801C187.364 8.68801 186.672 8.39601 186.144 7.81201C185.608 7.22801 185.34 6.44001 185.34 5.44801C185.34 4.47201 185.604 3.68801 186.132 3.09601C186.652 2.50401 187.34 2.20801 188.196 2.20801C189.084 2.20801 189.78 2.51601 190.284 3.13201C190.788 3.74801 191.04 4.52001 191.04 5.44801ZM188.196 3.08401C187.692 3.08401 187.28 3.24401 186.96 3.56401C186.64 3.87601 186.44 4.32801 186.36 4.92001H190.008C189.96 4.37601 189.776 3.93601 189.456 3.60001C189.136 3.25601 188.716 3.08401 188.196 3.08401Z"
          fill="#B3B3B3"
        />
        <path
          d="M183.028 4.104C184.012 4.48 184.504 5.16 184.504 6.144C184.504 6.888 184.26 7.476 183.772 7.908C183.292 8.332 182.612 8.544 181.732 8.544H178V0H181.552C182.376 0 183.016 0.208 183.472 0.624C183.928 1.032 184.156 1.568 184.156 2.232C184.156 3.088 183.78 3.712 183.028 4.104ZM181.48 0.912H179.008V3.732H181.48C182.032 3.732 182.452 3.604 182.74 3.348C183.028 3.092 183.172 2.732 183.172 2.268C183.172 1.844 183.024 1.512 182.728 1.272C182.44 1.032 182.024 0.912 181.48 0.912ZM179.008 7.632H181.684C182.276 7.632 182.724 7.504 183.028 7.248C183.34 6.992 183.496 6.624 183.496 6.144C183.496 5.136 182.888 4.632 181.672 4.632H179.008V7.632Z"
          fill="#B3B3B3"
        />
      </svg>
    </div>
  )
}

export const SignUpEarlyBirdPage = () => {
  const { signInSSO } = useAuth()
  const location = useLocation()
  const toaster = useToaster()

  const handleFirebaseSuccessAuth = useCallback(
    (ssoToken: string) => {
      NProgress.start()
      signInSSO({
        data: { ssoData: { ssoIdToken: ssoToken, allowFreeTrial: true } },
        redirectPath: location.state?.from?.pathname ?? PATH_ROOT,
      })
        .catch((error) => {
          toaster.error(error)
        })
        .finally(() => {
          NProgress.done()
        })
    },
    [location, signInSSO, toaster],
  )

  const header = useMemo(() => <SimpleHeader />, [])
  const footer = useMemo(() => <Footer />, [])

  return (
    <Layout headerComponent={header} footerComponent={footer}>
      <div className="flex min-h-full items-center justify-center bg-dark-dark3 relative">
        <div className="w-full py-[20px]">
          <div className="flex flex-col sm:flex-none justify-center md:grid md:grid-cols-[440px,320px] gap-[80px] mx-5 sm:mx-[10%]">
            <div className="flex flex-col justify-between max-w-[440px]">
              <div>
                <h1 className="text-[32px] leading-[38px] font-bold mb-[16px]">
                  Android self-serve beta
                </h1>
                <div className="pt-[16px] sm:pb-[40px] border-t border-[#383838]">
                  <div className="text-[14px] leading-[16px] text-gray-normal">
                    When you're done, we'd love to hear your feedback! We've made it easy with{' '}
                    <a
                      className="cursor-pointer text-white font-semibold underline"
                      href={EARLY_BIRD_FORM_LINK}
                    >
                      this brief form
                    </a>
                    .
                  </div>
                  <VisitWebsite />
                </div>
              </div>
              <div>
                <JavaKotlinOnly />
              </div>
            </div>
            <div className="min-w-[320px]">
              <RequestFreeTrialForm onFirebaseSuccess={handleFirebaseSuccessAuth} withForm={true} />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}
