export const isVisible = (positionFlag: number) => (positionFlag ^ VISIBLE_FLAG) === 0

export const isConnectionVisible = (positionFlagA: number, positionFlagB: number) => {
  if (isVisible(positionFlagA) || isVisible(positionFlagB)) {
    return true
  }
  // If they have a side intersection it means that they are in the same side quadrant
  // and as a result the line is invisible for sure
  return !(positionFlagA & positionFlagB & SIDE_INTERSECTION)
}

export const getHorizontalFlag = (x: number, w: number, rightBorder: number) =>
  getFlag(
    [CANVAS_POSITIONS.X_LEFT, CANVAS_POSITIONS.X_CENTER, CANVAS_POSITIONS.X_RIGHT],
    x,
    w,
    rightBorder,
  )

export const getVerticalFlag = (y: number, h: number, bottomBorder: number) =>
  getFlag(
    [CANVAS_POSITIONS.Y_UP, CANVAS_POSITIONS.Y_CENTER, CANVAS_POSITIONS.Y_DOWN],
    y,
    h,
    bottomBorder,
  )

const getFlag = (flags: number[], startValue: number, valueSize: number, valueBorder: number) => {
  const [BEFORE, CENTER, AFTER] = flags
  if (startValue < 0) {
    if (startValue + valueSize < 0) {
      return BEFORE
    } else {
      return CENTER
    }
  } else if (startValue > valueBorder) {
    return AFTER
  } else {
    return CENTER
  }
}

// @formatter:off
// prettier-ignore
export const CANVAS_POSITIONS = {
  X_LEFT:   0b000100,
  X_CENTER: 0b000010,
  X_RIGHT:  0b000001,
  Y_UP:     0b001000,
  Y_CENTER: 0b010000,
  Y_DOWN:   0b100000
}
// @formatter:on

const SIDE_INTERSECTION =
  CANVAS_POSITIONS.X_LEFT |
  CANVAS_POSITIONS.X_RIGHT |
  CANVAS_POSITIONS.Y_UP |
  CANVAS_POSITIONS.Y_DOWN

export const VISIBLE_FLAG = CANVAS_POSITIONS.X_CENTER | CANVAS_POSITIONS.Y_CENTER
