import { ReadonlySliceById } from 'components/ps-chart/stores/TraceDataStore'

export const isAncestor = (
  fromSliceId: number,
  toSliceId: number,
  sliceById: ReadonlySliceById,
) => {
  let curSlice = sliceById.get(fromSliceId)
  while (curSlice != null && curSlice.parent !== null) {
    if (curSlice.parent.id === toSliceId) {
      return true
    }
    curSlice = curSlice.parent
  }
  return false
}
