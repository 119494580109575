import { SliceLink } from 'components/ps-chart/models/SliceLink'
import { ReadonlySliceById } from 'components/ps-chart/stores/TraceDataStore'
import { ConnectionDirection } from 'components/ps-chart/models/ConnectionDirection'

export const mergeSameTargetSliceChains =
  (sliceById: ReadonlySliceById, linkDirection = ConnectionDirection.BACKWARD) =>
  (chainsRoots: SliceLink[]) => {
    const isBackward = linkDirection === ConnectionDirection.BACKWARD
    const chainsRootsByTarget = new Map<number, SliceLink>()
    for (const chainRoot of chainsRoots) {
      const { toSliceId, fromSliceId } = chainRoot
      const savedRoot = chainsRootsByTarget.get(toSliceId)
      if (savedRoot == null) {
        chainsRootsByTarget.set(isBackward ? toSliceId : fromSliceId, chainRoot)
      } else {
        const { toSliceId: savedToSliceId, fromSliceId: savedFromSliceId } = savedRoot
        const curSlice = sliceById.get(isBackward ? fromSliceId : toSliceId)!
        const savedSlice = sliceById.get(isBackward ? savedFromSliceId : savedToSliceId)!
        const backwardLevel = curSlice.level < savedSlice.level
        const forwardLevel = curSlice.level > savedSlice.level
        if (isBackward ? backwardLevel : forwardLevel) {
          chainsRootsByTarget.set(isBackward ? toSliceId : fromSliceId, chainRoot)
        }
      }
    }
    return chainsRoots.filter((link) => {
      const linkId = isBackward ? link.toSliceId : link.fromSliceId
      return chainsRootsByTarget.get(linkId) === link
    })
  }
