import React from 'react'
import styled from 'styled-components/macro'
import tw from 'twin.macro'

const WEBSITE_IO = 'https://productscience.ai'

export const VisitWebsite = () => {
  return (
    <>
      <div>
        <a href={WEBSITE_IO}>
          <WithHover className="flex sm:py-[32px] py-[16px] items-center overflow-hidden">
            <CircleWithArrow />
            <div className="text-[14px] leading-[16px] pl-[8px]">Visit our website</div>
          </WithHover>
        </a>
      </div>
    </>
  )
}
const WithHover = styled.div`
  ${tw`transition-colors`}
  color: #b3b3b3;
  svg rect {
    fill: #b3b3b3;
  }
  &:hover {
    color: #fff;
    svg rect {
      fill: #fff;
    }
  }
`

const CircleWithArrow = () => {
  return (
    <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect y="0.5" width="20" height="20" rx="10" fill="#B3B3B3" />
      <path
        d="M6 10.5L14 10.5"
        stroke="#0F0F0F"
        strokeWidth="1.5"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
      <path d="M11 14.5L15 10.5L11 6.5" stroke="#0F0F0F" strokeWidth="1.5" strokeLinecap="square" />
    </svg>
  )
}
