import React from 'react'
import { generatePath } from 'react-router-dom'

import { PATH_FLOWS } from 'pages/FlowsPage'

import { useBreadcrumbsProjectData } from './useBreadcrumbsProjectData'
import { BreadcrumbSection } from './BreadcrumbSection'
import { BreadcrumbsLink } from './BreadcrumbsLink'

export const BreadcrumbsProjectSection = () => {
  const projectData = useBreadcrumbsProjectData()

  return (
    <BreadcrumbSection isLoading={projectData == null}>
      {projectData != null && (
        <BreadcrumbsLink
          path={generatePath(PATH_FLOWS, { projectUrlName: projectData.urlName })}
          name={projectData.name}
        />
      )}
    </BreadcrumbSection>
  )
}
