import Konva from 'konva'
import {
  GlobalTimelineBaseRenderer,
  type GlobalTimelineListener,
} from 'components/global-timeline/GlobalTimelineBaseRenderer'
import { type RefObject } from 'react'
import { GlobalTimelineSettings } from 'components/global-timeline/models/GlobalTimelineSettings'
import { VideoPlayerStore } from 'components/ps-chart/stores/VideoPlayerStore'
import { GhostIndicator } from './GhostIndicator'

export class VideoTimelineRender extends GlobalTimelineBaseRenderer {
  private readonly ghostIndicator: GhostIndicator

  constructor(
    containerId: string,
    globalTimelineContainerRef: RefObject<HTMLDivElement>,
    xMin: number,
    xMax: number,
    videoStore: VideoPlayerStore,
    listener: GlobalTimelineListener,
  ) {
    const settings = new GlobalTimelineSettings()
    super(containerId, globalTimelineContainerRef, xMin, xMax, settings, listener)
    this.ghostIndicator = new GhostIndicator(
      this.width,
      this.height,
      this.settings.topOffset,
      this.timePerPx,
      this.settings.ghostIndicator,
      videoStore,
    )
    this.stage.add(this.ghostIndicator.layer)
  }

  private renderOverviewImg(x: number, width: number, canvas: HTMLCanvasElement) {
    this.contentLayer.add(
      new Konva.Image({
        x,
        y: 32,
        width,
        height: 40,
        image: canvas,
      }),
    )
  }

  renderContent(
    isExtractionInProgress: boolean,
    x: number,
    width: number,
    canvas: HTMLCanvasElement | null,
  ) {
    this.contentLayer.destroyChildren()
    if (!isExtractionInProgress && canvas != null) {
      this.renderOverviewImg(x, width, canvas)
    }
  }

  addEventListeners() {
    super.addEventListeners()
    this.ghostIndicator.addEventListeners()
  }

  removeEventListeners() {
    super.removeEventListeners()
    this.ghostIndicator.removeEventListeners()
  }
}
