import React, { useState } from 'react'
import styled from 'styled-components/macro'
import tw from 'twin.macro'
import { Popover } from '@headlessui/react'
import { useSearchParams } from 'react-router-dom'
import { Icon } from 'components/Icon'
import { Button, ButtonTextColorVariantEnum, ButtonVariantEnum } from 'components/Button'
import { useTranslation } from 'react-i18next'

export interface FilterProps {
  param: string
  name?: string
  options?: string[]
  isContext?: boolean
}

type OptionType = {
  option: string
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  isChecked: boolean
}

export const Filter = ({ param, name, options }: FilterProps) => {
  const { t } = useTranslation()
  const [searchParams, setSearchParams] = useSearchParams()
  const [activeOptions, setActiveOptions] = useState(
    Array.from(new Set(searchParams.get(param)?.split(','))).filter(Boolean),
  )

  const handleOptionChange = (option: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
    const newActiveOptions = new Set(activeOptions)
    if (event.target.checked) {
      newActiveOptions.add(option)
    } else {
      newActiveOptions.delete(option)
    }
    setActiveOptions([...Array.from(newActiveOptions)].filter(Boolean))
  }

  const handleApplyClick = (close: () => void) => () => {
    if (activeOptions.length > 0) {
      searchParams.set(param, activeOptions.join(','))
    } else {
      searchParams.delete(param)
    }
    setSearchParams(searchParams)
    close()
  }

  return (
    <Panel>
      {({ close }: { close: () => void }) => (
        <>
          <SectionTitle>{name}</SectionTitle>
          {options &&
            options.map((option, index) => (
              <Option
                option={option}
                isChecked={activeOptions.indexOf(option) !== -1 || false}
                onChange={handleOptionChange(option)}
                key={String(index)}
              />
            ))}
          <Bottom>
            <Button
              variant={ButtonVariantEnum.Text}
              textColorVariant={ButtonTextColorVariantEnum.Muted}
              onClick={close}
            >
              {t('traces.filters.cancel')}
            </Button>
            <Button
              variant={ButtonVariantEnum.Text}
              onClick={handleApplyClick(close)}
              data-tid={`flowlib-filterby-${param.toLowerCase()}`}
            >
              {t('traces.filters.apply')}
            </Button>
          </Bottom>
        </>
      )}
    </Panel>
  )
}

const Option = ({ option, onChange, isChecked }: OptionType) => {
  return (
    <OptionView>
      <OptionInput type="checkbox" onChange={onChange} checked={isChecked} />
      <OptionName>{option}</OptionName>
      <CheckIcon icon="check" />
    </OptionView>
  )
}

const Panel = styled(Popover.Panel)`
  ${tw`shadow-base`}
  padding-top: 11px;
  border-radius: 2px;
  background-color: ${({ theme }) => theme.colors.dark.dark5};
`

const SectionTitle = styled.div`
  ${tw`text-small tracking-wide`}
  padding: 0 16px 8px;
  color: ${({ theme }) => theme.colors.gray.faded};
`

const OptionView = styled.label`
  ${tw`text-small tracking-wide`}
  display: flex;
  align-items: center;
  width: 100%;
  padding: 4px 15px;
  cursor: pointer;
  text-align: left;

  & + & {
    margin-top: 5px;
  }

  ${({ theme }) => theme.notTouchScreen} {
    &:hover {
      background: ${({ theme }) => theme.colors.rollover.light};
    }
  }
`

const OptionInput = styled.input`
  position: absolute;
  z-index: 1;
  width: 0;
  height: 0;
  cursor: default;
  opacity: 0;
`

const OptionName = styled.div`
  flex: 1;
  color: white;
`

const CheckIcon = styled(Icon)`
  margin-top: 1px;
  margin-right: -10px;
  margin-left: 5px;
  opacity: 0;
  color: ${({ theme }) => theme.colors.electro};
  font-size: 32px;

  ${OptionInput}:checked ~ & {
    opacity: 1;
  }
`

const Bottom = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 56px;
  margin-top: 8px;
  padding: 0 16px;
  background: ${({ theme }) => theme.colors.dark.dark4};
`
