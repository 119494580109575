import styled from 'styled-components/macro'
import tw from 'twin.macro'

export const Count = styled.div`
  ${tw`text-micro font-medium tracking-wide`}
  display: inline-block;
  height: 15px;
  padding: 0 5px;
  color: ${({ theme }) => theme.colors.white};
  border-radius: 2px;
  background: ${({ theme }) => theme.colors.state.good};
`
