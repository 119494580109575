import { useAllProjectsQuery } from 'hooks/useApiQuery'

export const useIoSingleProject = () => {
  const { isSuccess: isProjectsSuccess, data: projects } = useAllProjectsQuery()
  const ioSingleProject =
    projects?.length === 1 && projects[0].interactiveOnboarding ? projects[0] : undefined
  return {
    isLoaded: isProjectsSuccess,
    ioSingleProject,
  }
}
