export const getPart = (nanos: { value: number }, type: 'h' | 'm' | 's' | 'ms' | 'µs' | 'ns') => {
  const unitNanos = {
    h: 3600e9,
    m: 60e9,
    s: 1e9,
    ms: 1e6,
    µs: 1e3,
    ns: 1,
  }
  const value = Math.floor(nanos.value / unitNanos[type])
  nanos.value -= value * unitNanos[type]
  return value
}

export const nanoToString = (nanos: number): string => {
  const nanosObj = { value: nanos }
  const parts = []

  // Use limited units if nanos is less than 150 seconds
  const units =
    nanos < 150e9
      ? (['s', 'ms', 'µs', 'ns'] as const)
      : (['h', 'm', 's', 'ms', 'µs', 'ns'] as const)

  // Ensure that 's', 'ms', and 'µs' always appear
  for (const unit of units) {
    const value = getPart(nanosObj, unit)
    if (value > 0 || ['s', 'ms', 'µs', 'ns'].includes(unit)) {
      parts.push(`${value}${unit}`)
    }
  }

  return parts.length > 0 ? parts.join(' ') : '0ns'
}

export const nanoToMeasurementString = (nanos: number): string => {
  const units = [
    { unit: 'h', nanos: 3600e9 },
    { unit: 'm', nanos: 60e9 },
    { unit: 's', nanos: 1e9 },
    { unit: 'ms', nanos: 1e6 },
    { unit: 'µs', nanos: 1e3 },
    { unit: 'ns', nanos: 1 },
  ]

  for (const { unit, nanos: unitNanos } of units) {
    if (nanos >= unitNanos) {
      const value = nanos / unitNanos
      const roundedValue = value >= 20 ? Math.round(value) : Math.round(value * 100) / 100
      return roundedValue + unit
    }
  }
  return '0ns'
}
