import React from 'react'

export type TemplateValues = Map<string, string | React.ReactNode>
export const EMPTY_TEMPLATE_VALUES = new Map()

const TEMPLATE_REGEX = /({{.*?}})/g
const TEMPLATE_KEY_REGEX = /^{{ ?([a-zA-Z-_]*) ?}}$/

export class TemplateEngine {
  static processToString(template: string, values: TemplateValues): string {
    const result = this.process(template, values)
    if (Array.isArray(result)) {
      return result.map((r) => (typeof r === 'string' ? r : '')).join('')
    }
    return result?.toString() || ''
  }

  static process(template: string, values: TemplateValues): React.ReactNode {
    // Split template to the strings parts and `{{ template }}` parts.
    // If this is a `{{ template }}`, then replace it with template value.
    return template.split(TEMPLATE_REGEX).map((part, index) => {
      const key = part?.match(TEMPLATE_KEY_REGEX)?.[1]?.trim()
      const replaceWith = key && values.has(key) ? values.get(key) : part

      // key found in template but no value found in map
      if (key && !values.has(key)) {
        console.warn(`Markdown template value not found for key: "${key}"`)
      }

      // If we have React element, we need to give it a key in case there are multiple elements
      if (React.isValidElement(replaceWith)) {
        return React.cloneElement(replaceWith, { key: index })
      } else {
        return replaceWith
      }
    })
  }
}
