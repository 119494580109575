import React, { useMemo } from 'react'

import { Layout } from 'components/Layout'
import { SignUpIo } from 'components/guide-io/SignUpIo'
import { BaseHeader } from 'components/header/BaseHeader'
import { Footer } from 'components/Footer'

export const PATH_SIGN_UP_IO = '/project-sign-up'

export const SignUpIoPage = () => {
  const header = useMemo(() => <BaseHeader />, [])
  const footer = useMemo(() => <Footer />, [])

  return (
    <Layout headerComponent={header} footerComponent={footer}>
      <div className="flex min-h-full items-center justify-center bg-dark-dark3">
        <div className="w-full">
          <SignUpIo />
        </div>
      </div>
    </Layout>
  )
}
