import { DashboardRunV2Dto } from 'api/models'

export const isFinished = (run: DashboardRunV2Dto): boolean => {
  switch (run.status) {
    case 'CANCELLED':
    case 'FAILED':
    case 'FINISHED':
      return true
    case 'ANALYZING':
    case 'UNDEFINED':
    case 'CANCEL_REQUESTED':
    case 'AWAITING_UPLOAD':
    case 'STARTED':
    case 'READY_TO_START':
      return false
  }
}

export enum RegressionStatus {
  NOT_DETECTED,
  SLOWDOWN,
  SPEEDUP,
}

export const prettifyDate = (date: string | null | undefined): string => {
  if (!date || date == 'null') {
    return ''
  } else {
    return new Date(date).toLocaleString()
  }
}
