import React from 'react'
import { generatePath } from 'react-router-dom'

import { PATH_TEAM } from 'pages/TeamPage'

import { useBreadcrumbsProjectData } from './useBreadcrumbsProjectData'
import { BreadcrumbSection } from './BreadcrumbSection'
import { BreadcrumbsLink } from './BreadcrumbsLink'

export const BreadcrumbsTeamSection = () => {
  const projectData = useBreadcrumbsProjectData()

  return (
    <BreadcrumbSection isLoading={projectData == null}>
      {projectData != null && (
        <BreadcrumbsLink
          path={generatePath(PATH_TEAM, { teamUrlName: projectData.team.urlName })}
          name={projectData.team.name}
        />
      )}
    </BreadcrumbSection>
  )
}
