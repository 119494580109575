import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useFlowQuery } from 'hooks/useApiQuery'
import { ChartPageParams } from 'api/models'

const HIDE_LENGTH = 100

export interface FlowInfoProps {
  chartPageParams: ChartPageParams
}

export const FlowInfo = ({ chartPageParams }: FlowInfoProps) => {
  const [shown, setShown] = useState(false)

  const { projectUrlName, flowProjectLocalId } = chartPageParams
  const { isSuccess: isFlowSuccess, data: flowData } = useFlowQuery({
    projectUrlName,
    flowProjectLocalId,
  })

  const title = flowData?.name !== undefined ? flowData?.name : ''
  const description = flowData?.description !== undefined ? flowData?.description : ''

  const showSwitcher = description.length > HIDE_LENGTH
  const isHidden = showSwitcher && !shown
  const { t } = useTranslation()

  return isFlowSuccess ? (
    <>
      <div className="text-small text-white mb-[16px]">{title}</div>
      {description.length > 0 && (
        <div
          className={`text-small text-gray-normal pb-[24px] mb-[24px] border-b-gray-strokeLight border-b-[1px] ${
            isHidden ? 'flex' : ''
          }`}
        >
          <span className={`${isHidden ? 'line-clamp-1' : ''}`}>{description}</span>
          {showSwitcher && (
            <>
              {shown && <br />}
              <span
                className={`text-white cursor-pointer ${isHidden ? 'pr-[55px]' : ''}`}
                onClick={() => setShown(!shown)}
              >
                {!shown ? t('annotations.bar.more') : t('annotations.bar.hide')}
              </span>
            </>
          )}
        </div>
      )}
    </>
  ) : (
    <></>
  )
}
