import React from 'react'
import { generatePath, Navigate } from 'react-router-dom'

import { FEATURE_FLAGS, useFeatureFlag } from 'utils/feature-flags'
import { useIoSingleProject } from 'components/guide-io/useIoSingleProject'
import { GUIDE_IO_PROJECT_PATH } from 'components/guide-io/constants'
import { AuthenticatedUserDto } from 'api/__generated__/api-types'

export const useIoSingleProjectRedirect = (user?: AuthenticatedUserDto) => {
  const isInteractiveOnboardingEnabled = useFeatureFlag(FEATURE_FLAGS.INTERACTIVE_ONBOARDING)
  const { isLoaded, ioSingleProject } = useIoSingleProject()

  const navigateComponent =
    isInteractiveOnboardingEnabled && ioSingleProject != null && !user?.roles.isSuperAdmin ? (
      <Navigate
        to={generatePath(GUIDE_IO_PROJECT_PATH, {
          projectUrlName: ioSingleProject.urlName,
        })}
        replace={true}
      />
    ) : null

  return { isLoaded, navigateComponent }
}
