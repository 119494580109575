import { Slice, SliceRange } from './Slice'
import { walkSlices } from '../utils/slice'

interface FunctionStats {
  start: number
  end: number
  duration: number
}

export class Thread {
  readonly id: number

  readonly title: string

  readonly isAsync: boolean

  readonly isUtility: boolean

  readonly networkRequestsRange

  /**
   * Map of thread's slices per thread level. Level starts from 0.
   * Useful when you know exact thread level from which you need to find a slice.
   */
  readonly slicesByLevel: Map<number, Slice[]>

  /**
   * Map of thread's stats (occurrences, total and average duration) of functions with same name.
   */
  readonly functionStatsByName: Map<string, FunctionStats[]>

  /**
   * Array of thread's slices stored in tree structure.
   */
  readonly slices: ReadonlyArray<Slice>

  private _state = ThreadViewState.Expanded

  private readonly _maxLevel: number

  private readonly _minSlicesValue

  private readonly _maxSlicesValue

  private _totalSlicesCount?: number

  constructor(
    id: number,
    title: string,
    slices: Slice[],
    networkRequestsRange: SliceRange[],
    maxLevel: number,
    isAsync: boolean,
    isUtility = false,
    slicesByLevel: Map<number, Slice[]> | null = null,
    functionStatsByName: Map<string, FunctionStats[]> | null = null,
    state: ThreadViewState = ThreadViewState.Expanded,
  ) {
    this.id = id
    this.title = title
    this.isAsync = isAsync
    this.slices = slices
    this.isUtility = isUtility
    this._maxLevel = maxLevel
    this.slicesByLevel = slicesByLevel ?? this.slicesTreeToLevelMap(slices)
    this.functionStatsByName =
      functionStatsByName ?? this.slicesToFunctionStatsMap(this.slicesByLevel)
    this.networkRequestsRange = networkRequestsRange
    if (slices.length > 0) {
      this._minSlicesValue = slices[0].start
      this._maxSlicesValue = slices[slices.length - 1].end
    } else {
      this._minSlicesValue = 0
      this._maxSlicesValue = 0
    }
    this._state = state
  }

  slicesTreeToLevelMap(slices: Slice[]): Map<number, Slice[]> {
    const slicesByLevel = new Map<number, Slice[]>()
    walkSlices(slices, (slice, threadLevel, sliceIndex) => {
      const levelSlices = slicesByLevel.get(threadLevel) ?? []
      const levelLastSlice = levelSlices.length ? levelSlices[levelSlices.length - 1] : undefined
      const lastSliceExists = levelLastSlice !== undefined

      const levelPreviousIndex = lastSliceExists ? levelLastSlice.levelPositionIndex! : 0
      slice.levelPositionIndex = threadLevel === 0 ? sliceIndex : levelPreviousIndex + 1

      levelSlices.push(slice)
      slicesByLevel.set(threadLevel, levelSlices)
    })
    return slicesByLevel
  }

  slicesToFunctionStatsMap(levelMap: Map<number, Slice[]>): Map<string, FunctionStats[]> {
    const functionStatsByTitle = new Map<string, FunctionStats[]>()

    for (let level = 0; level <= this._maxLevel; level++) {
      const slices = levelMap.get(level) ?? []
      if (slices.length) {
        for (const slice of slices) {
          const { start, end, title } = slice
          const functionStats = functionStatsByTitle.get(title) ?? []
          const duration = end - start
          const stats: FunctionStats = { start, end, duration }
          functionStats.push(stats)
          functionStatsByTitle.set(title, functionStats)
        }
      }
    }
    return functionStatsByTitle
  }

  cloneAndSubstituteSlices(slices: Slice[]): Thread {
    return new Thread(
      this.id,
      this.title,
      slices,
      this.networkRequestsRange,
      this.maxLevel,
      this.isAsync,
      this.isUtility,
      null,
      null,
      this.state,
    )
  }

  cloneAndSubstituteSlicesByLevel(slicesByLevel: Map<number, Slice[]>): Thread {
    return new Thread(
      this.id,
      this.title,
      [...this.slices],
      this.networkRequestsRange,
      this.maxLevel,
      this.isAsync,
      this.isUtility,
      slicesByLevel,
      this.functionStatsByName,
      this.state,
    )
  }

  get state(): ThreadViewState {
    return this._state
  }

  /**
   * Thread maxLevel is equal to the deepest level of slices, where slice levels start from 0
   **/
  get maxLevel(): number {
    return this._maxLevel
  }

  get totalSlicesCount(): number {
    if (this._totalSlicesCount == null) {
      let totalSlicesCount = 0
      walkSlices(this.slices, () => totalSlicesCount++)
      this._totalSlicesCount = totalSlicesCount
    }
    return this._totalSlicesCount
  }

  get isCollapsed(): boolean {
    return this._state === ThreadViewState.Collapsed
  }

  get isExpanded(): boolean {
    return this._state === ThreadViewState.Expanded
  }

  switchState() {
    if (this.state === ThreadViewState.Expanded) {
      this._state = ThreadViewState.Collapsed
    } else {
      this._state = ThreadViewState.Expanded
    }
  }
}

enum ThreadViewState {
  Expanded,
  Collapsed,
}
