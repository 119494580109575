import React, { useCallback } from 'react'
import { useMutation, useQueryClient } from 'react-query'
import NProgress from 'nprogress'
import { toast } from 'react-hot-toast'
import { AxiosError } from 'axios'

import { ApiError, FlowDto, Flows } from 'api/models'
import { useApi } from 'contexts/di-context'
import { useTranslation } from 'react-i18next'
import { useNProgressOverlay } from 'utils/NProgressOverlay'
import { queryKeys, useFlowsQuery } from 'hooks/useApiQuery'

interface UseDeleteFlowProps {
  projectUrlName: string
  flowProjectLocalId: number
  shouldDeleteWithTraces: boolean
  onDelete?: () => void
  onRestore?: () => void
}

export const useDeleteFlow = ({
  projectUrlName,
  flowProjectLocalId,
  shouldDeleteWithTraces,
  onDelete,
  onRestore,
}: UseDeleteFlowProps) => {
  const api = useApi()
  const queryClient = useQueryClient()
  const { t } = useTranslation()
  const { data: flowsData } = useFlowsQuery({ projectUrlName })

  const curFlowCashIndex = (flowsData ?? []).findIndex(
    (curFlow) => curFlow.projectLocalId === flowProjectLocalId,
  )
  const hasFlowInCache = curFlowCashIndex !== -1

  useNProgressOverlay()

  const onFlowsRestore = useCallback(
    (data: FlowDto[]) => {
      if (hasFlowInCache) {
        queryClient.setQueryData<FlowDto[] | undefined>(
          queryKeys.flows({ projectUrlName }),
          (oldData) => {
            if (oldData == null) {
              return undefined
            }
            const newData = [...oldData]
            newData.splice(curFlowCashIndex, 0, data[0])
            return newData
          },
        )
      }
      if (onRestore != null) {
        onRestore()
      }
    },
    [curFlowCashIndex, hasFlowInCache, onRestore, projectUrlName, queryClient],
  )

  const postFlowsRestoreMutation = useMutation(
    () => api.postFlowsRestore({ projectUrlName, flowProjectLocalId }),
    {
      onMutate: () => {
        NProgress.start()
      },
      onSuccess: onFlowsRestore,
      onError: (err: AxiosError<ApiError>) => {
        toast.error(err.response?.data.message ?? t('errorMessage'))
      },
      onSettled: () => {
        NProgress.done()
      },
    },
  )

  const undoDeleteFlow = useCallback(() => {
    toast.dismiss()
    postFlowsRestoreMutation.mutate()
  }, [postFlowsRestoreMutation])

  const onDeleteFlow = useCallback(() => {
    if (hasFlowInCache) {
      queryClient.setQueryData<Flows | undefined>(queryKeys.flows({ projectUrlName }), (oldData) =>
        oldData?.filter((e) => e?.projectLocalId !== flowProjectLocalId),
      )
    }
    if (onDelete != null) {
      onDelete()
    }
    toast.success(
      <>
        {t('flows.deleteFlow.success')}
        <button className="ml-4" onClick={undoDeleteFlow}>
          {t('undo')}
        </button>
      </>,
    )
  }, [hasFlowInCache, onDelete, t, undoDeleteFlow, queryClient, projectUrlName, flowProjectLocalId])

  const deleteFlowMutation = useMutation(
    () => api.deleteFlow({ projectUrlName, flowProjectLocalId, shouldDeleteWithTraces }),
    {
      onMutate: () => {
        NProgress.start()
      },
      onSuccess: onDeleteFlow,
      onError: (err: AxiosError<ApiError>) => {
        toast.error(err.response?.data.message ?? t('errorMessage'))
      },
      onSettled: () => {
        NProgress.done()
      },
    },
  )

  return {
    deleteFlowMutation,
  }
}
