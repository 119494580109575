import { hexToHSL, HSLToHex } from 'utils/color-converter/colorConverter'
import {
  CLUSTER_COLOR,
  CLUSTER_COLOR_DIMMED,
  SlicePalette,
} from 'components/ps-chart/models/settings'

export const getSliceDimmedColor = (hex: string, slicePalette: SlicePalette): string => {
  if (hex === CLUSTER_COLOR) {
    // custom dimmed color for the clusters
    return CLUSTER_COLOR_DIMMED
  } else {
    if (slicePalette.dimmedColors.has(hex)) {
      return slicePalette.dimmedColors.get(hex)!
    } else {
      const [h, s, l] = hexToHSL(hex)
      return HSLToHex(h, s - 20, l - 12)
    }
  }
}

export const getAsyncSliceColor = (hex: string, level: number) => {
  const [h, s, l] = hexToHSL(hex)
  const even = level % 2 === 0
  return even ? hex : HSLToHex(h, s - 5, l - 5)
}
