import React, { useContext, useEffect } from 'react'
import { generatePath, Link, matchPath, useLocation, useParams } from 'react-router-dom'
import classNames from 'classnames'

import { PATH_ANNOTATIONS } from 'pages/AnnotationsPage'
import { PATH_CHART, PATH_CHART_DIRECT } from 'pages/PsChartPage'
import { PATH_PROCESSES } from 'pages/PxAPage'
import { PATH_CHART_ROUTER } from 'pages/PsChartRouter'

import { useDisablePinchZoom } from 'hooks/useDisablePinchZoom'
import { PsChartPageEnum, useAvailablePages } from 'hooks/useAvailablePages'

import { ActionTooltip } from 'components/ActionTooltip'
import { useCustomerSupportModal } from 'contexts/customer-support-modal-context'

import { Icon } from 'components/Icon'

import { useFeatureFlag, FEATURE_FLAGS } from 'utils/feature-flags'

import { Button, ButtonVariantEnum } from 'components/Button'
import { QuickstartButton } from 'components/quickstart/modal/Button'
import { PATH_ROOT } from 'utils/links'
import { LayoutContext } from 'contexts/layout-context'

export const Navbar = () => {
  const location = useLocation()
  const { projectUrlName, flowProjectLocalId, traceProjectLocalId } = useParams()
  const { availablePages, isDataLoaded, hasVideo } = useAvailablePages()
  const { openModal } = useCustomerSupportModal()

  const isAnnotationsEnabled = useFeatureFlag(FEATURE_FLAGS.ANNOTATIONS)
  const isSelfServeEnabled = useFeatureFlag(FEATURE_FLAGS.SELF_SERVE)

  const items = [
    ...(isAnnotationsEnabled
      ? [
          {
            link:
              projectUrlName &&
              flowProjectLocalId &&
              traceProjectLocalId &&
              generatePath(PATH_ANNOTATIONS, {
                projectUrlName,
                flowProjectLocalId,
                traceProjectLocalId,
              }),
            icon: 'nav-annotation',
            ariaLabel: 'annotation',
            activePaths: [PATH_ANNOTATIONS, PATH_CHART_ROUTER],
            chartPage: PsChartPageEnum.ANNOTATIONS,
          },
          {
            link:
              projectUrlName &&
              flowProjectLocalId &&
              traceProjectLocalId &&
              generatePath(PATH_PROCESSES, {
                projectUrlName,
                flowProjectLocalId,
                traceProjectLocalId,
              }),
            icon: 'nav-processes',
            ariaLabel: 'processes',
            activePaths: [PATH_PROCESSES],
            chartPage: PsChartPageEnum.PROCESS_X_ANNOTATIONS,
          },
        ]
      : []),
    {
      link:
        projectUrlName &&
        flowProjectLocalId &&
        traceProjectLocalId &&
        generatePath(PATH_CHART, { projectUrlName, flowProjectLocalId, traceProjectLocalId }),
      icon: 'nav-connections',
      ariaLabel: 'connections',
      activePaths: [PATH_CHART, PATH_CHART_DIRECT],
      chartPage: PsChartPageEnum.TRACE_VIEWER,
    },
  ]

  const currentPageIndex = items.findIndex(
    (item) => !!item.activePaths.find((path) => matchPath(path, location.pathname)),
  )
  const currentPage = items[currentPageIndex]

  const navRef = React.useRef<HTMLElement>(null)
  useDisablePinchZoom(navRef)

  const { setIsNavbarShown } = useContext(LayoutContext)

  useEffect(() => {
    setIsNavbarShown(true)
    return () => {
      setIsNavbarShown(false)
    }
  }, [setIsNavbarShown])

  return (
    <div className="relative">
      <QuickstartButton />
      <nav
        ref={navRef}
        className="flex items-center justify-center h-[var(--bottomBarHeight)] bg-dark-dark5 border-t border-t-gray-strokeLight"
      >
        {items.map((item, index) => {
          let isDisabled = Boolean(!currentPage?.chartPage && item.chartPage)
          if (isDataLoaded && item.chartPage && !availablePages?.has(item.chartPage)) {
            isDisabled = true
          }
          const isCurrent = index === currentPageIndex
          const isFilledIcon = currentPageIndex >= index && !isDisabled
          const hasStatus =
            isDataLoaded && item.chartPage === PsChartPageEnum.PROCESS_X_ANNOTATIONS && isDisabled
          const statusMessage = hasStatus
            ? hasVideo
              ? 'pageStatusNoAnnotations'
              : 'pageStatusNoVideo'
            : undefined

          const renderContent = () => {
            return (
              <ActionTooltip place="top" tooltipId={statusMessage} offsetY={2}>
                <span
                  className={classNames(
                    'flex items-center justify-center w-[120px] h-full pt-[3px] border-b-[3px] text-icon transition relative',
                    isCurrent
                      ? 'border-b-electro text-electro'
                      : 'border-b-transparent text-gray-dark',
                    !isCurrent && !isDisabled && 'hover:text-white',
                  )}
                >
                  <Icon icon={isFilledIcon ? `${item.icon}-active` : item.icon} />
                  {hasStatus && (
                    <span className="absolute bg-state-bad top-1/2 right-1/2 h-[4px] w-[4px] rounded-sm mt-[-14px] mr-[-12px]" />
                  )}
                </span>
              </ActionTooltip>
            )
          }

          return isDisabled ? (
            <React.Fragment key={String(index)}>{renderContent()}</React.Fragment>
          ) : (
            <Link
              className="h-full"
              to={item.link || PATH_ROOT}
              aria-label={item.ariaLabel}
              key={String(index)}
            >
              {renderContent()}
            </Link>
          )
        })}
      </nav>
      {isSelfServeEnabled && (
        <Button
          onClick={openModal}
          variant={ButtonVariantEnum.Text}
          className="group absolute right-[22px] top-1/2 mt-[-15px]"
        >
          <Icon className="text-gray-normal group-hover:text-white text-[20px]" icon="support" />
        </Button>
      )}
    </div>
  )
}
