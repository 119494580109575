import { useCallback } from 'react'
import { useTeamsQuery } from 'hooks/useApiQuery'
import { useTranslation } from 'react-i18next'
import { SelectField } from 'components/traces/copyTrace/SelectField'
import { getTeamListItems } from 'components/traces/copyTrace/logic'
import { FIELDS, MenuProps } from 'components/traces/copyTrace/models'

export const TeamSelect = ({ formContext }: MenuProps) => {
  const { t } = useTranslation()
  const { data, isError } = useTeamsQuery()
  const { getValues, setValue } = formContext

  const teamsListdata = getTeamListItems(data)
  const teamFormValue = getValues(FIELDS.TEAM)

  const teamOnChange = useCallback(
    (value: string | string[]) => {
      setValue(FIELDS.TEAM, value as string)
      setValue(FIELDS.PROJECT, '')
      setValue(FIELDS.FLOW, '')
    },
    [setValue],
  )

  return (
    <SelectField
      isDisabled={false}
      listItems={teamsListdata}
      placeholder={t('traces.copyTraceModal.selectTeam')}
      label={t('traces.copyTraceModal.team')}
      isError={isError}
      value={teamFormValue}
      onChange={teamOnChange}
    />
  )
}
