import React, { ChangeEvent, useRef } from 'react'
import { Link } from 'react-router-dom'

export const VIDEO_NAVIGATION_PATH = '/video-nav'

/**
 * Page to position any video file on the particular time.
 * Created for video sync debugging purposes.
 */
export const VideoNavPage = () => {
  const videoRef = useRef<HTMLVideoElement>(null)

  const handleFile = (fileEvent: ChangeEvent<HTMLInputElement>): void => {
    if (fileEvent.target.files) {
      const file = fileEvent.target.files[0]
      const fileUrl = URL.createObjectURL(file)
      if (videoRef.current) {
        videoRef.current.src = fileUrl
      }
    }
  }

  const handleTimeChange = (event: ChangeEvent<HTMLInputElement>): void => {
    const value = Number(event.target.value)
    if (videoRef.current) {
      videoRef.current.currentTime = value / 1_000_000
    }
  }

  return (
    <div className="mt-5 pb-20 mx-10 max-w-[66ch]">
      <h1 className="text-header-big mb-5">Video navigation</h1>
      <div className="space-y-4">
        <Link className="text-electro" to="/">
          Main
        </Link>
        <div className="space-y-4">
          <input
            id="time"
            className="block rounded"
            type="file"
            accept="video/*"
            onChange={handleFile}
          />
          <div className="flex items-center">
            <label htmlFor="time" className="mr-2">
              Time, micros
            </label>
            <input
              className="text-black h-8 mr-2 rounded"
              type="number"
              onChange={handleTimeChange}
            />
          </div>

          <video className="w-1/2" ref={videoRef} controls />
        </div>
      </div>
    </div>
  )
}
