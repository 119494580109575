import { useEffect } from 'react'
import { VideoDataStoreStatus } from 'components/ps-chart/stores/VideoDataStore'

export const usePreventAccidentalDelete = (status: VideoDataStoreStatus) => {
  useEffect(() => {
    // prevent accidental page refresh / close
    const beforeUnloadHandler = (event: BeforeUnloadEvent) => {
      event.preventDefault()
      event.returnValue = ''
      return
    }

    if (status === VideoDataStoreStatus.UPLOADING) {
      window.addEventListener('beforeunload', beforeUnloadHandler)
    }

    return () => {
      if (status === VideoDataStoreStatus.UPLOADING) {
        window.removeEventListener('beforeunload', beforeUnloadHandler)
      }
    }
  }, [status])
}
