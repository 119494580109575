import axios from 'axios'
import { UseQueryResult, useQuery } from 'react-query'
import { ONE_DAY } from 'utils/oneDay'
import { CONTENTFUL_DOMAIN } from 'api/contentful'
import { ContentfulEntry } from 'hooks/__generated__/contentful'
import { useContentfulClient } from 'contexts/di-context'
import { ContentfulClientApi } from 'contentful'

export * from 'hooks/__generated__/contentful/TypeQuickstartPage'

const { REACT_APP_CONTENTFUL_SPACE_ID, REACT_APP_CONTENTFUL_TOKEN } = process.env

/** @deprecated TODO: please use contentful client */
export const fetchContentfulEntriesByType = (type: string) => async () => {
  const response = await axios.get(
    `${CONTENTFUL_DOMAIN}/spaces/${REACT_APP_CONTENTFUL_SPACE_ID}/environments/master/entries?access_token=${REACT_APP_CONTENTFUL_TOKEN}&content_type=${type}`,
  )
  return response.data
}

export type EntriesByType<E = ContentfulEntry> = UseQueryResult<E> & {
  entries?: E[]
}

export const useContentfulEntriesByType = <E = ContentfulEntry>(
  contentType: string,
  contentfulQuery?: Parameters<ContentfulClientApi['getEntry']>[1],
): EntriesByType<E> => {
  const contentfulClient = useContentfulClient()

  const { data, ...rest } = useQuery(
    [`content-type-${contentType}`, contentType],
    () =>
      contentfulClient.getEntries({
        content_type: contentType,
        include: 10,
        ...contentfulQuery,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      }) as Promise<any>,
    {
      retry: false,
      retryOnMount: false,
      staleTime: ONE_DAY,
      cacheTime: ONE_DAY,
    },
  )

  return {
    entries: data?.items ?? [],
    data,
    ...rest,
  }
}
