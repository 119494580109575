import styled, { css } from 'styled-components/macro'
import classNames from 'classnames'

export const LoadingSpinner = styled.div.attrs((props) => ({
  className: classNames('animate-spin', props.className),
}))<{ size: number; className: string }>`
  border-radius: 50%;
  background: conic-gradient(#0000 10%, #c0c0c0);

  ${(props) => css`
    width: ${props.size}px;
    height: ${props.size}px;
    -webkit-mask: radial-gradient(farthest-side, #0000 calc(100% - ${props.size / 12}px), #000 0);
  `}
`
