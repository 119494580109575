import { makeAutoObservable, runInAction, when } from 'mobx'
import * as Sentry from '@sentry/react'
import { generatePath, matchPath } from 'react-router-dom'
import { Api } from 'api/Api'
import { AxiosError } from 'axios'
import { t } from 'i18next'
import { Entry } from 'contentful'
import { QueryClient } from 'react-query'

import {
  InstructionsStateDto,
  ProjectBuildStatusOut,
  CreateFreeTrialOnboardingRequestDto,
  FreeTrialStage,
  OsTypeValue,
  OsType,
  ProcessingErrorCode,
  ProjectSummaryDto,
  TeamDto,
  TechType,
  TechTypeValue,
  Trace,
  TraceProcessingState,
  TraceVideoMetadataDto,
  VideoProcessingStateDto,
  TraceQuality,
} from 'api/models'

import {
  BuildSystemOption,
  FrameworkOption,
  GuideStepType,
  LanguageOption,
  OsOption,
  PlatformType,
  SelectPlatformFormFields,
  Step,
} from 'components/guide/models'

import {
  PATH_GUIDE_INSTRUMENT_AND_BUILD,
  PATH_GUIDE_RECORD_TRACE,
  PATH_GUIDE_SELECT_PLATFORM,
  PATH_GUIDE_VIEW_TRACE,
} from 'pages/guide/GuideRootPage'

import { PATH_CHART } from 'pages/PsChartPage'
import { fetchContentfulEntry } from 'hooks/contentful/useContentfulEntry'
import { CONTENTFUL_DOCS } from 'api/contentful'
import { suppressMobXWhenAbort } from 'utils/suppressMobXWhenAbort'
import { suppressCanceled } from 'utils/suppressCanceled'
import { TypeMarkdownDocumentFields } from 'hooks/__generated__/contentful/TypeMarkdownDocument'
import {
  fetchAppBuildAndRunStatus,
  fetchBuildProperties,
  fetchFlowTraces,
  fetchPluginVersions,
  fetchProjectsSummary,
  fetchTeams,
  fetchUser,
} from 'hooks/useApiQuery'
import { SubmitRequestStore } from './SubmitRequestStore'

const PULL_TIMEOUT = 10_000
const PULL_TIMEOUT_SHORT = 1_000

export class GuideStore {
  freeTrialProjectSummary: Readonly<ProjectSummaryDto> | null = null

  freeTrialTeam: Readonly<TeamDto> | null = null

  isReady = false

  private readonly api: Api
  private readonly queryClient: QueryClient

  private userId: Readonly<number> | null = null

  private preselectedTechStack: string[] = []

  private scheduledRecheckId: ReturnType<typeof setInterval> | null = null

  buildProperties: string | null = null

  pluginVersion: string | null = null

  appBuildAndRunStatus: InstructionsStateDto | null = null

  mdContent: string | null = null

  androidBuild: string | null = null

  recordTrace: string | null = null

  submitRequestStore?: SubmitRequestStore

  traceVideoMetadataDto: TraceVideoMetadataDto | null = null

  private readonly abortController: AbortController

  private traces: Trace[] = []

  constructor(api: Api, queryClient: QueryClient) {
    makeAutoObservable<
      GuideStore,
      'api' | 'queryClient' | 'abortController' | 'scheduledRecheckId'
    >(this, {
      api: false,
      queryClient: false,
      abortController: false,
      scheduledRecheckId: false,
    })
    this.api = api
    this.queryClient = queryClient
    this.abortController = new AbortController()
  }

  destroy() {
    this.abortController.abort()
    this.cancelPollBuildAndRunStatus()
  }

  get steps(): Step[] {
    const freeTrialProjectExist = Boolean(this.freeTrialProjectSummary)
    return [
      { type: GuideStepType.SelectPlatform, completed: freeTrialProjectExist, available: true },
      {
        type: GuideStepType.InstrumentAndBuild,
        completed:
          this.appBuildAndRunStatus?.buildStatus?.status === ProjectBuildStatusOut.SUCCESS &&
          this.appBuildAndRunStatus.appRunStatus?.status === ProjectBuildStatusOut.SUCCESS,
        available: freeTrialProjectExist,
      },
      {
        type: GuideStepType.RecordTrace,
        completed: this.bestTrace != null,
        available: freeTrialProjectExist,
      },
      { type: GuideStepType.ViewTrace, completed: false, available: freeTrialProjectExist },
    ]
  }

  get selectedTechStack() {
    if (this.freeTrialProjectSummary) {
      const values = this.selectPlatformDefaultValues
      return [values.os, values.devPlatform, values.languages.join('/'), values.buildSystem]
    }
    return this.preselectedTechStack
  }

  setPreselectedTechStack = (values: string[]) => {
    this.preselectedTechStack = values
  }

  private fetchUser() {
    return fetchUser(this.queryClient, this.api, this.abortController.signal).then((user) =>
      runInAction(() => {
        if (user != null) {
          this.userId = user.id
        }
      }),
    )
  }

  private fetchTeam() {
    return fetchTeams(this.queryClient, this.api, this.abortController.signal)
      .then((teams) => {
        const defaultFreeTrialTeam = GuideStore.getDefaultFreeTrialTeam(teams)
        if (defaultFreeTrialTeam != null) {
          runInAction(() => {
            this.freeTrialTeam = defaultFreeTrialTeam
          })
        }
      })
      .catch(suppressCanceled)
  }

  static getDefaultFreeTrialTeam(teams: TeamDto[]): TeamDto | undefined {
    return teams.find((team) => team.freeTrial != null)
  }

  private initSubmitRequestStore() {
    return when(() => this.freeTrialTeam != null, { signal: this.abortController.signal })
      .then(() => {
        return runInAction(() => {
          this.submitRequestStore = new SubmitRequestStore({
            api: this.api,
            teamUrlName: this.freeTrialTeam!.urlName,
          })
          return this.submitRequestStore.fetchData()
        })
      })
      .catch(suppressMobXWhenAbort)
  }

  private startPullingProject() {
    return when(() => this.freeTrialTeam != null, { signal: this.abortController.signal })
      .then(() => this.pullProjects({ teamUrlName: this.freeTrialTeam!.urlName }))
      .catch(suppressMobXWhenAbort)
  }

  private pullProjects({
    teamUrlName,
    isSingleCall = false,
    shouldRefetch,
  }: {
    teamUrlName: string
    isSingleCall?: boolean
    shouldRefetch?: boolean
  }) {
    return fetchProjectsSummary(
      this.queryClient,
      this.api,
      this.abortController.signal,
    )(teamUrlName, shouldRefetch)
      .then((freeTrialTeamProjects) => {
        runInAction(() => {
          const firstFreeTrialProjectSummary = freeTrialTeamProjects.projects.at(0)
          if (firstFreeTrialProjectSummary != null) {
            this.freeTrialProjectSummary = firstFreeTrialProjectSummary
          }
        })
      })
      .catch(suppressCanceled)
      .finally(() => {
        if (!this.abortController.signal.aborted && !isSingleCall) {
          setTimeout(() => this.pullProjects({ teamUrlName, shouldRefetch: true }), PULL_TIMEOUT)
        }
      })
  }

  get freeTrialFlow() {
    return (
      this.freeTrialProjectSummary?.flows.filter((flow) => flow.author.id === this.userId)?.at(0) ??
      null
    )
  }

  private fetchDocs() {
    return Promise.all([
      fetchContentfulEntry(CONTENTFUL_DOCS.gradleMainDoc)().then(
        (docData: Entry<TypeMarkdownDocumentFields>) => {
          this.mdContent = docData.fields.markdown
        },
      ),
      fetchContentfulEntry(CONTENTFUL_DOCS.gradleBuildStepDoc)().then(
        (docData: Entry<TypeMarkdownDocumentFields>) => {
          this.androidBuild = docData.fields.markdown
        },
      ),
      fetchContentfulEntry(CONTENTFUL_DOCS.recordTraceStepDoc)().then(
        (docData: Entry<TypeMarkdownDocumentFields>) => {
          this.recordTrace = docData.fields.markdown
        },
      ),
    ])
  }

  private fetchPluginVersion() {
    when(() => this.freeTrialProjectSummary != null, {
      signal: this.abortController.signal,
    })
      .then(() =>
        fetchPluginVersions(this.queryClient, this.api).then((buildProperties) => {
          runInAction(() => {
            this.pluginVersion = buildProperties.recommended
          })
        }),
      )
      .catch(suppressMobXWhenAbort)
  }

  private fetchBuildProperties() {
    when(() => this.freeTrialProjectSummary != null, {
      signal: this.abortController.signal,
    })
      .then(() =>
        fetchBuildProperties(
          this.queryClient,
          this.api,
        )(this.freeTrialProjectSummary!.project.urlName).then((buildProperties) => {
          runInAction(() => {
            this.buildProperties = buildProperties
          })
        }),
      )
      .catch(suppressMobXWhenAbort)
  }

  private startPullingAppBuildAndRunStatus() {
    return when(() => this.freeTrialProjectSummary != null, {
      signal: this.abortController.signal,
    })
      .then(() => this.pullAppBuildAndRunStatus(this.freeTrialProjectSummary!.project.urlName))
      .catch(suppressMobXWhenAbort)
  }

  private pullAppBuildAndRunStatus(projectUrlName: string, shouldRefetch?: boolean) {
    return fetchAppBuildAndRunStatus(this.queryClient, this.api)(projectUrlName, shouldRefetch)
      .then((appState) => {
        runInAction(() => {
          this.appBuildAndRunStatus = appState
        })
      })
      .finally(() => {
        if (!this.abortController.signal.aborted) {
          setTimeout(() => this.pullAppBuildAndRunStatus(projectUrlName, true), PULL_TIMEOUT)
        }
      })
  }

  private startPullingRecordTraceData() {
    return when(() => this.freeTrialFlow != null && this.freeTrialProjectSummary != null, {
      signal: this.abortController.signal,
    })
      .then(() =>
        this.pullRecordTraceData(
          this.freeTrialProjectSummary!.project.urlName,
          this.freeTrialFlow!.projectLocalId.toString(),
        ),
      )
      .catch(suppressMobXWhenAbort)
  }

  private pullRecordTraceData(
    projectUrlName: string,
    flowProjectLocalId: string,
    shouldRefetch?: boolean,
  ) {
    return this.fetchTraces({ projectUrlName, flowProjectLocalId }, shouldRefetch)
      .then(() => {
        if (this.bestTrace != null) {
          return this.fetchVideoData(projectUrlName, this.bestTrace.projectLocalId.toString())
        }
      })
      .finally(() => {
        if (!this.abortController.signal.aborted) {
          setTimeout(
            () => this.pullRecordTraceData(projectUrlName, flowProjectLocalId, true),
            PULL_TIMEOUT_SHORT,
          )
        }
      })
  }

  private fetchTraces(
    req: {
      projectUrlName: string
      flowProjectLocalId: string
    },
    shouldRefetch?: boolean,
  ) {
    return fetchFlowTraces(this.queryClient, this.api)(req, shouldRefetch).then((traces) => {
      runInAction(() => {
        this.traces = traces
      })
    })
  }

  private fetchVideoData(projectUrlName: string, traceProjectLocalId: string) {
    return this.api
      .getTraceVideoMetadata({ projectUrlName, traceProjectLocalId })
      .then((videoMeta) => {
        runInAction(() => {
          this.traceVideoMetadataDto = videoMeta
        })
      })
  }

  get bestTrace() {
    return (
      this.traces
        .slice()
        .sort(
          (prev, next) =>
            Number.parseInt(prev.projectLocalId) - Number.parseInt(next.projectLocalId),
        )
        .find((trace) => trace.processingState === TraceProcessingState.FINISHED) ??
      this.traces.at(0) ??
      null
    )
  }

  get isVideoFramesNotFound() {
    return (
      this.traceVideoMetadataDto != null &&
      this.traceVideoMetadataDto.processingErrorCode === ProcessingErrorCode.VIDEO_FRAMES_NOT_FOUND
    )
  }

  get isTraceHasQualityIssues(): boolean {
    if (this.bestTrace == null) {
      return false
    }
    const { qualityLabels } = this.bestTrace.qualityReport
    return !(qualityLabels == null || qualityLabels.length === 0)
  }

  get isTraceQualityPoor(): boolean {
    if (!this.isTraceHasQualityIssues) {
      return false
    }
    const { qualityLabels } = this.bestTrace!.qualityReport
    return (
      qualityLabels!.includes(TraceQuality.UNDERFILTERED) ||
      qualityLabels!.includes(TraceQuality.OVERFILTERED)
    )
  }

  get isTraceQualityWarningUnderFiltered(): boolean {
    if (!this.isTraceHasQualityIssues) {
      return false
    }
    const { qualityLabels } = this.bestTrace!.qualityReport
    return qualityLabels!.includes(TraceQuality.UNDERFILTERED_WARNING)
  }

  get isTraceQualityWarningOverFiltered(): boolean {
    if (!this.isTraceHasQualityIssues) {
      return false
    }
    const { qualityLabels } = this.bestTrace!.qualityReport
    return qualityLabels!.includes(TraceQuality.OVERFILTERED_WARNING)
  }

  get isVideoProcessing(): boolean {
    return this.traceVideoMetadataDto?.state === VideoProcessingStateDto.IN_PROGRESS
  }

  get isVideoConditionFailed(): boolean {
    return this.traceVideoMetadataDto?.state === VideoProcessingStateDto.FAILED
  }

  get isVideoConditionPassed(): boolean {
    return (
      this.traceVideoMetadataDto == null ||
      this.traceVideoMetadataDto.state === VideoProcessingStateDto.FINISHED
    )
  }

  get isTraceHasWarnings(): boolean {
    return (
      this.isVideoProcessing ||
      this.isVideoConditionFailed ||
      this.isTraceQualityWarningUnderFiltered ||
      this.isTraceQualityWarningOverFiltered
    )
  }

  get traceUrlPath() {
    if (
      this.bestTrace == null ||
      this.bestTrace.processingState !== TraceProcessingState.FINISHED ||
      this.freeTrialProjectSummary == null ||
      this.freeTrialFlow == null ||
      this.isTraceQualityPoor
    ) {
      return null
    }

    return generatePath(PATH_CHART, {
      projectUrlName: this.freeTrialProjectSummary.project.urlName,
      flowProjectLocalId: this.freeTrialFlow.projectLocalId.toString(),
      traceProjectLocalId: this.bestTrace.projectLocalId.toString(),
    })
  }

  fetchData() {
    this.fetchBuildProperties()
    this.fetchPluginVersion()

    return Promise.allSettled([
      this.fetchUser(),
      this.fetchTeam().then(() => {
        const fetchDocsPromise = this.fetchDocs()
        if (this.freeTrialTeam?.freeTrial?.stage === FreeTrialStage.INSTRUCTIONS) {
          return fetchDocsPromise
        }
      }),
      this.initSubmitRequestStore(),
      this.startPullingProject().then(() => {
        const blockingRequests = []
        const pullBuildRunStatusPromise = this.startPullingAppBuildAndRunStatus()
        const pullTracesPromise = this.startPullingRecordTraceData()
        if (this.freeTrialProjectSummary != null) {
          blockingRequests.push(pullBuildRunStatusPromise)
          if (this.freeTrialFlow != null) {
            blockingRequests.push(pullTracesPromise)
          }
        }
        return Promise.allSettled(blockingRequests)
      }),
    ])
      .catch((error) => {
        if (!(error as AxiosError).isAxiosError) {
          Sentry.captureException(error)
        }
        throw error
      })
      .finally(() => {
        runInAction(() => {
          this.isReady = true
        })
      })
  }

  createFreeTrialProject(teamUrlName: string, data: CreateFreeTrialOnboardingRequestDto) {
    return this.api.createFreeTrialProject(teamUrlName, data).then(() =>
      this.pullProjects({
        teamUrlName,
        isSingleCall: true,
        shouldRefetch: true,
      }),
    )
  }

  get selectPlatformDefaultValues(): SelectPlatformFormFields {
    const project = this.freeTrialProjectSummary?.project
    return {
      os: project ? GuideStore.getOsByOsType(project.os) : '',
      devPlatform: project ? GuideStore.getDevPlatformByTechType(project.tech) : '',
      languages: project
        ? project.languages.map((lang) => GuideStore.getLocalValueByType(LanguageOption, lang))
        : [],
      buildSystem:
        project && project.buildSystem
          ? GuideStore.getLocalValueByType(BuildSystemOption, project.buildSystem)
          : '',
    }
  }

  private cancelPollBuildAndRunStatus() {
    if (this.scheduledRecheckId != null) {
      clearInterval(this.scheduledRecheckId)
    }
  }

  createFlow = async (flowName: string): Promise<string | Error | void> => {
    const projectUrlName = this.freeTrialProjectSummary?.project.urlName

    if (projectUrlName) {
      const projectObject = { projectUrlName }
      const flowObject = { name: flowName, description: '', hidden: false }

      try {
        const newFlow = await this.api.postFlow(projectObject, flowObject)

        if (newFlow && newFlow.projectLocalId) {
          runInAction(() => {
            this.freeTrialProjectSummary?.flows.unshift(newFlow)
          })

          return newFlow.name ?? ''
        }
      } catch (error) {
        if (error instanceof Error) {
          throw new Error(error.message)
        }
        throw new Error(t('guidePage.createFlow.couldNotCreate'))
      }
    } else {
      throw new Error(t('guidePage.createFlow.noProjectPost'))
    }
  }

  public static getStepByPathName(pathName: string): GuideStepType | null {
    if (matchPath(PATH_GUIDE_SELECT_PLATFORM, pathName)) {
      return GuideStepType.SelectPlatform
    } else if (matchPath(PATH_GUIDE_INSTRUMENT_AND_BUILD, pathName)) {
      return GuideStepType.InstrumentAndBuild
    } else if (matchPath(PATH_GUIDE_RECORD_TRACE, pathName)) {
      return GuideStepType.RecordTrace
    } else if (matchPath(PATH_GUIDE_VIEW_TRACE, pathName)) {
      return GuideStepType.ViewTrace
    }
    return null
  }

  public static getPagePathByStep(step: GuideStepType): string {
    switch (step) {
      case GuideStepType.SelectPlatform: {
        return PATH_GUIDE_SELECT_PLATFORM
      }
      case GuideStepType.InstrumentAndBuild: {
        return PATH_GUIDE_INSTRUMENT_AND_BUILD
      }
      case GuideStepType.RecordTrace: {
        return PATH_GUIDE_RECORD_TRACE
      }
      case GuideStepType.ViewTrace: {
        return PATH_GUIDE_VIEW_TRACE
      }
    }
  }

  public static getPageUrl(step: GuideStepType, platform: PlatformType): string {
    return generatePath(GuideStore.getPagePathByStep(step), { platform })
  }

  public static getPlatformByString(platform: string): PlatformType | null {
    if (!Object.values(PlatformType).includes(platform as PlatformType)) {
      return null
    }
    return platform as PlatformType
  }

  public static getOsByOsType(osType: OsTypeValue) {
    switch (osType) {
      case OsType.IOS:
        return OsOption.IOS
      case OsType.ANDROID:
        return OsOption.ANDROID
    }
  }

  public static getDevPlatformByTechType(techType: TechTypeValue) {
    switch (techType) {
      case TechType.JVM:
      case TechType.SWIFT:
        return FrameworkOption.NATIVE
      case TechType.REACT_NATIVE:
        return FrameworkOption.REACT_NATIVE
    }
  }

  public static getLocalValueByType(type: object, value: string) {
    return Object.values(type).find((item) => item.toLowerCase() === value.toLowerCase()) || value
  }
}
