import styled, { css } from 'styled-components/macro'

import config from 'assets/fonts/icon/config.json'

const nameToChar = (name: string) => {
  const glyph = config.glyphs.find((item) => item.css === name)
  if (glyph) {
    return String.fromCodePoint(glyph.code)
  }
  return ''
}

export const renderIcon = (name: string) => css`
  user-select: none;
  text-transform: none;
  font-family: ${config.name};
  speak: none;
  font-weight: normal;
  font-style: normal;
  font-variant: normal;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  &:before {
    content: '${nameToChar(name)}';
  }
`

export const Icon = styled.i
  .attrs(({ as }: { as?: string }) => (!as ? { 'aria-hidden': true } : {}))
  .withConfig({
    shouldForwardProp: (prop, defaultValidatorFn) =>
      !['icon'].includes(prop) && defaultValidatorFn(prop),
  })`
  ${({ icon }: { icon: string }) => renderIcon(icon)}
`
