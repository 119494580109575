import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { colors } from 'utils/styles/colors'
import { Button, ButtonVariantEnum } from 'components/Button'
import { PATH_ROOT } from 'utils/links'
import { PATH_LIVE_DEMO } from 'pages/LiveDemoPage'
import { FEATURE_FLAGS, useFeatureFlag } from 'utils/feature-flags'
import { useTeamsQuery } from 'hooks/useApiQuery'
import { useMemo } from 'react'

export const SwitchButton = ({ isLiveDemo }: { isLiveDemo: boolean }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const isLiveDemoEnabled = useFeatureFlag(FEATURE_FLAGS.LIVE_DEMO)
  const isSelfServerEnabled = useFeatureFlag(FEATURE_FLAGS.SELF_SERVE)

  const { data: teams } = useTeamsQuery()
  const oneTeamAndFreeTrial = useMemo(() => {
    return teams?.length === 1 && teams?.findIndex((team) => team.freeTrial) !== -1
  }, [teams])

  if (!isLiveDemoEnabled || !isSelfServerEnabled) {
    return null
  }

  if (isLiveDemo && !oneTeamAndFreeTrial) {
    return null
  }

  return (
    <Button
      borderColor={isLiveDemo ? colors.state.good : colors.state.attention}
      variant={ButtonVariantEnum.Outlined}
      className="mr-[16px] border-2 border-lime max-h-[32px] max-w-[120px] text-small text-gray-normal hover:text-white"
      onClick={() => {
        navigate({
          pathname: isLiveDemo ? PATH_ROOT : PATH_LIVE_DEMO,
          search: !isLiveDemo ? '?live-demo-guide=0' : undefined,
        })
      }}
    >
      {isLiveDemo ? t('components.header.freeTrial') : t('components.header.liveDemo')}
    </Button>
  )
}
