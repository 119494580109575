import React from 'react'
import { useTranslation } from 'react-i18next'

export const CheckEmailBanner = () => {
  const { t } = useTranslation()

  return (
    <div className="flex flex-col items-center">
      <h3 className="text-normal mb-[16px] font-medium text-gray-normal">
        {t('auth.checkEmail.title')}
      </h3>
      <p className="text-normal text-gray-normal mb-[32px]">{t('auth.checkEmail.subTitle')}</p>
    </div>
  )
}
