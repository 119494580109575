import { OsType, OsTypeValue } from 'api/models'

export const getOsName = (os?: OsTypeValue) => {
  switch (os) {
    case OsType.ANDROID:
      return 'Android'
    case OsType.IOS:
      return 'iOS'
    default:
      return String(os)
  }
}
