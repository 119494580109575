import React, { ChangeEvent, useCallback, useEffect, useMemo, useRef } from 'react'
import { observer } from 'mobx-react-lite'
import { debounce } from 'throttle-debounce'

import { PsChartStore } from 'components/ps-chart/PsChartStore'
import { IconColored } from 'components/common/IconColored'
import { ActionTooltip } from 'components/ActionTooltip'
import { useAnalytics } from 'contexts/di-context'
import { ELEMENTS_IDS } from 'components/ps-chart/elementsIds'
import { useHotKeys } from 'components/ps-chart/hooks/useHotKeys'

export const SearchAction = observer(function SearchAction({
  psChartStore,
}: {
  psChartStore: PsChartStore
}) {
  const inputEl = useRef<HTMLInputElement>(null)
  const analytics = useAnalytics()
  const { searchStore } = psChartStore

  const contextThreadTitle = getContextThreadTitle(psChartStore)

  const handleInputFocus = useCallback(() => psChartStore.lockSelection(), [psChartStore])

  const handleSearchChangeThrottled = useMemo(
    () => debounce(300, searchStore.handleSearchChange),
    [searchStore],
  )

  const handleSearchValueChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target
      searchStore.setInputValue(value)
      handleSearchChangeThrottled(value, psChartStore.sliceById)
    },
    [searchStore, psChartStore, handleSearchChangeThrottled],
  )

  const onSubmit = useCallback(
    (event) => {
      event.preventDefault()
      handleSearchChangeThrottled?.cancel({ upcomingOnly: true })
      searchStore.onSubmit(event.target.searchTerm.value, psChartStore)
    },
    [psChartStore, searchStore, handleSearchChangeThrottled],
  )

  const onSearchIconClick = useCallback(() => {
    searchStore.setIsOpened(!searchStore.isOpened)
    if (searchStore.isOpened) {
      searchStore.resetSearchState()
    }
  }, [searchStore])

  useEffect(() => {
    // Next line was commented, to keep search state between Annotations & PxA & PsChart pages
    // searchStore.resetSearchState(true)
    if (psChartStore.searchState.searchContextThreadId != null) {
      searchStore.setIsOpened(true)
      if (inputEl.current != null) {
        inputEl.current.focus()
        searchStore.handleSearchChange(inputEl.current.value, psChartStore.sliceById)
      }
    }
  }, [psChartStore, searchStore, psChartStore.searchState.searchContextThreadId])

  useEffect(() => {
    if (searchStore.isOpened) {
      if (searchStore.autoFocus) {
        inputEl.current?.focus()
      }
    } else {
      handleSearchChangeThrottled.cancel({ upcomingOnly: true })
      searchStore.resetSearchState()
    }
  }, [searchStore, searchStore.isOpened, searchStore.autoFocus, handleSearchChangeThrottled])

  const onRightArrowClick = useCallback(
    () => searchStore.stepRight(psChartStore),
    [psChartStore, searchStore],
  )

  const onLeftArrowClick = useCallback(
    () => searchStore.stepLeft(psChartStore),
    [psChartStore, searchStore],
  )

  const onKeyF = useCallback(() => {
    if (searchStore.isOpened) {
      inputEl.current?.focus()
    } else {
      searchStore.setIsOpened(true)
      analytics.track('search-hotkey')
    }
  }, [searchStore, analytics])

  const OnEsc = useCallback(() => {
    searchStore.setIsOpened(false)
    searchStore.resetSearchState()
  }, [searchStore])

  useHotKeys(['KeyF'], onKeyF, psChartStore.isEnabledListeners)
  useHotKeys(['Escape'], OnEsc, psChartStore.isEnabledListeners)

  return (
    <div className="flex flex-row" id={ELEMENTS_IDS.CHART_SEARCH_INPUT}>
      {searchStore.isOpened && (
        <div
          className="flex w-[460px] h-[32px] bg-dark-dark3
          text-small text-gray-normal mx-[2px]"
        >
          {contextThreadTitle != null && (
            <div
              title={contextThreadTitle}
              className="pt-[6px] pl-[4px] pr-[4px] whitespace-no-wrap overflow-hidden max-w-[200px] truncate"
            >
              @{contextThreadTitle}
            </div>
          )}
          <div className="flex-grow">
            <form onSubmit={onSubmit}>
              <input
                className="w-full h-[32px] text-small text-white bg-dark-dark3
                focus:ring-0 border-transparent focus:border-transparent"
                type="text"
                name="searchTerm"
                placeholder="Search"
                ref={inputEl}
                onChange={handleSearchValueChange}
                value={searchStore.inputValue}
                onFocus={handleInputFocus}
                onKeyDown={(event) => {
                  if (event.code !== 'Escape') {
                    event.stopPropagation()
                  }
                }}
              />
            </form>
          </div>
          <div className="pt-[6px] pl-[4px] pr-[2px] text-center">
            {getCurFoundCounter(
              searchStore.curResultNum,
              psChartStore.searchState.searchResults.length,
            )}
          </div>
          <IconColored
            className="px-[11px] pt-[6px]"
            icon="arrow-drop-left"
            size={10}
            isDisabled={
              searchStore.curResultNum === 0 || psChartStore.searchState.searchResults.length == 0
            }
            onClick={onLeftArrowClick}
          />
          <IconColored
            className="px-[11px] pt-[7px] rotate-180"
            icon="arrow-drop-left"
            size={10}
            isDisabled={
              searchStore.curResultNum === psChartStore.searchState.searchResults.length - 1 ||
              psChartStore.searchState.searchResults.length === 0
            }
            onClick={onRightArrowClick}
          />
          <ActionTooltip tooltipId="searchExit" place="top">
            <IconColored
              className="pl-[10px] pr-[18px] pt-[6px]"
              icon="cross-icon"
              size={8}
              onClick={onSearchIconClick}
            />
          </ActionTooltip>
        </div>
      )}
      <ActionTooltip tooltipId="search" place="top">
        <div className="flex items-center justify-center align-center h-[32px] w-[64px] bg-dark-dark3">
          <IconColored
            className="p-[8px]"
            icon="search-icon"
            size={16}
            isActive={searchStore.isOpened}
            onClick={onSearchIconClick}
            data-tid="search-btn"
          />
        </div>
      </ActionTooltip>
    </div>
  )
})

const getCurFoundCounter = (curNum: number, totalSize: number) => {
  if (totalSize === 0) {
    return '0 / 0'
  } else {
    return `${curNum + 1} / ${totalSize}`
  }
}

const getContextThreadTitle = (psChartStore: PsChartStore) => {
  if (psChartStore.searchState.searchContextThreadId == null) {
    return null
  }
  return psChartStore.traceDataState.threadsById.get(
    psChartStore.searchState.searchContextThreadId,
  )!.title
}
