import {
  useContentfulEntry,
  TypeLiveDemoData,
  UseQueryResultResponse,
  isTypeLiveDemoData,
} from 'hooks/contentful/useContentfulEntry'
import { ContentfulEntryDto } from 'api/__generated__/api-types'

/**
 * Wrapper around useContentfulEntry that returns LiveDemoData only.
 * * If the result entry from Contentful is not a LiveDemoData, entry will be empty.
 */
export const useContentfulLiveDemoData = (
  liveDemoContentfulData: ContentfulEntryDto,
): UseQueryResultResponse<TypeLiveDemoData> => {
  const { entry, ...rest } = useContentfulEntry<TypeLiveDemoData>(
    liveDemoContentfulData.entryId,
    undefined,
    {
      environmentId: liveDemoContentfulData.environmentId,
      spaceId: liveDemoContentfulData.spaceId,
    },
  )
  if (!entry || !isTypeLiveDemoData(entry)) {
    return rest
  }

  return {
    entry,
    ...rest,
  }
}
