import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import NProgress from 'nprogress'

import { useToaster } from 'hooks/useToaster'
import { Layout } from 'components/Layout'
import { Button } from 'components/Button'
import { useApi } from 'contexts/di-context'
import { ModuleProgressCircle } from 'components/ps-chart/ModuleProgressCircle'
import { LoadingSpinner } from 'components/LoadingSpinner'
import { BaseHeader } from 'components/header/BaseHeader'
import { Footer } from 'components/Footer'
import { FreeTrialExtensionDto } from 'api/__generated__/api-types'

export const EXPIRED_TRIAL_PATH = '/expired-trial'

type PageNavState = {
  teamUrlName: string
}

export const ExpiredTrialPage = () => {
  const location = useLocation()
  const navState: null | PageNavState = location.state
  const teamUrlName = navState?.teamUrlName
  const { t } = useTranslation()
  const toaster = useToaster()
  const api = useApi()
  const [isLoading, setIsLoading] = useState(false)
  const [isExtRequestSet, setIsExtRequestSet] = useState(false)
  const [isReqSubmitting, setIsReqSubmitting] = useState(false)
  NProgress.done()

  useEffect(() => {
    if (!teamUrlName) {
      return
    }
    setIsLoading(true)
    api
      .getExtensionRequest(teamUrlName)
      .then((dates: FreeTrialExtensionDto) => {
        if (
          dates.extensionRequestedDate !== undefined &&
          dates.extensionRequestedDate !== null &&
          dates.expirationDate !== undefined &&
          dates.expirationDate !== null
        ) {
          const expirationDate = new Date(dates.expirationDate)
          const extensionRequestedDate = new Date(dates.extensionRequestedDate)
          if (expirationDate < extensionRequestedDate) {
            setIsExtRequestSet(true)
          }
        }
      })
      .catch((err) => {
        console.error(err)
        //TODO: uncomment when API is fixed
        // toaster.error(err, 'trialExpired.failedGetExtRequest')
      })
      .finally(() => {
        setIsLoading(false)
      })
  }, [api, teamUrlName, toaster])

  const onRequestButtonClick = useCallback(() => {
    if (!teamUrlName) {
      return
    }
    setIsReqSubmitting(true)
    api
      .postExtensionRequest(teamUrlName)
      .then(() => {
        setIsExtRequestSet(true)
      })
      .catch((err) => {
        console.error(err)
        toaster.error(err, 'trialExpired.failedPostExtRequest')
      })
      .finally(() => {
        setIsReqSubmitting(false)
      })
  }, [api, teamUrlName, toaster])

  const header = useMemo(() => <BaseHeader />, [])

  const footer = useMemo(() => <Footer />, [])

  return (
    <Layout headerComponent={header} footerComponent={footer}>
      <div className="flex min-h-full items-center justify-center bg-dark-dark2">
        <div className="w-full">
          <div className="flex flex-col items-center">
            {isLoading ? (
              <ModuleProgressCircle />
            ) : isExtRequestSet ? (
              <>
                <h3
                  className="typography-normal-accent mb-[16px]"
                  data-testid="expTrialReceivedTitle"
                >
                  {t('trialExpired.receivedReqTitle')}
                </h3>
                <p className="typography-small" data-testid="expTrialReceivedSubTitle">
                  {t('trialExpired.receivedReqSubTitle')}
                </p>
              </>
            ) : (
              <>
                <h3
                  className="typography-normal-accent text-state-bad mb-[16px]"
                  data-testid="expTrialTitle"
                >
                  {t('trialExpired.title')}
                </h3>
                <p className="typography-small mb-[32px]" data-testid="expTrialSubTitle">
                  {t('trialExpired.subTitle')}
                </p>
                <Button
                  className="min-w-[172px] text-center"
                  isSmall
                  onClick={onRequestButtonClick}
                  data-testid="expTrialRequestButton"
                  disabled={teamUrlName == null || isReqSubmitting}
                >
                  {isReqSubmitting ? (
                    <LoadingSpinner size={18} className="inline-block" />
                  ) : (
                    t('trialExpired.requestButton')
                  )}
                </Button>
              </>
            )}
          </div>
        </div>
      </div>
    </Layout>
  )
}
