import { observer } from 'mobx-react-lite'
import { PsChartStore } from 'components/ps-chart/PsChartStore'
import React, { useEffect, useMemo } from 'react'
import {
  PerfDynamicMeasurerStore,
  PerfMeasurerSubType,
} from 'components/ps-chart/dev-monitor/PerfDynamicMeasurerStore'
import dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration'
import { MeasureButton } from './MeasureButton'
import { MeasureValue } from './MeasureValue'

dayjs.extend(duration)

export interface PerfDynamicMeasurerProps {
  psChartStore: PsChartStore
}

export const PerfDynamicMeasurer = observer(function PerfDynamicMeasurer({
  psChartStore,
}: PerfDynamicMeasurerProps) {
  const perfMeasurerStore = useMemo(
    () => new PerfDynamicMeasurerStore(psChartStore),
    [psChartStore],
  )

  useEffect(() => () => perfMeasurerStore.stop(), [perfMeasurerStore])

  const startMeasuring = () => {
    perfMeasurerStore.run()
  }

  const stopMeasuring = () => {
    perfMeasurerStore.stop()
  }

  const toggleSubType = () => {
    perfMeasurerStore.toggleSubType()
  }

  const getSubTypeText = (subType: PerfMeasurerSubType) => {
    switch (subType) {
      case PerfMeasurerSubType.ZOOM_SCROLL:
        return 'ZoomScroll'
      case PerfMeasurerSubType.ZOOM:
        return 'Zoom'
    }
  }

  const perfDuration = dayjs.duration(perfMeasurerStore.getElapsedTime()).format('mm:ss:SSS')

  return (
    <>
      <MeasureValue value={String(perfMeasurerStore.averageFps)} text="FPS" />
      <MeasureValue value={perfDuration} text="time" />
      <MeasureButton text="Start" onClick={startMeasuring} />
      <MeasureButton text="Stop" onClick={stopMeasuring} />
      <MeasureButton text={getSubTypeText(perfMeasurerStore.subType)} onClick={toggleSubType} />
    </>
  )
})
