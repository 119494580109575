import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'
import { useOutletContext } from 'react-router-dom'
import { GuidePageOutletContext } from 'pages/guide/GuidePageOutletContext'
import { ProjectBuildStatusOut, InstrumentStepKey } from 'api/models'

interface Props {
  step: InstrumentStepKey
}

export const StepStatus = observer(function StepStatus({ step }: Props) {
  const { t } = useTranslation()
  const { guideStore } = useOutletContext<GuidePageOutletContext>()
  return (
    <>
      {guideStore.appBuildAndRunStatus?.[step]?.status === ProjectBuildStatusOut.SUCCESS ? (
        <p
          className={`absolute right-0 top-[10px] font-[500] leading-[16.8px] tracking-[3%] text-[12px] text-state-good`}
        >
          {t('guidePage.complete')}
        </p>
      ) : (
        <p
          className={`absolute right-0 top-[10px] font-[500] leading-[16.8px] tracking-[3%] text-[12px] text-gray-normal`}
        >
          <span className="text-state-attention">{t('guidePage.requiredStep')}</span>{' '}
          {t('guidePage.automaticVerification')}
        </p>
      )}
    </>
  )
})
