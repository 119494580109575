import { useState, useMemo, useRef, useEffect } from 'react'
import {
  AnnotationsFeatureState,
  PsChartFeatures,
  PsChartStore,
} from 'components/ps-chart/PsChartStore'
import { PsChart } from 'components/ps-chart/PsChart'
import { LiveDemoFooter } from 'components/live-demo/LiveDemoFooter'
import { LiveDemoStepsModal } from 'components/live-demo/LiveDemoModal'
import { LIVE_DEMO_TABS, LiveDemoController } from 'components/live-demo/LiveDemoController'
import { useChartStatePreparation } from 'components/live-demo/useChartStatePreparation'
import { LiveDemoHighlighting } from 'components/live-demo/LiveDemoHighlighting'
import { ModuleProgressCircle } from 'components/ps-chart/ModuleProgressCircle'
import { useDisablePinchZoom } from 'hooks/useDisablePinchZoom'
import { PsAnnotations } from 'components/annotations/PsAnnotations'
import { TypeLiveDemoData } from 'hooks/__generated__/contentful/TypeLiveDemoData'
import { LiveDemoModalControl } from 'components/live-demo/LiveDemoModalControl'
import { observer } from 'mobx-react-lite'

const processChartFeatures: PsChartFeatures = {
  flags: false,
  measurementTool: false,
  annotations: { enabled: true, draggable: true, flowAccess: false } as AnnotationsFeatureState,
}

const traceChartFeatures: PsChartFeatures = {
  flags: true,
  measurementTool: true,
  annotations: { enabled: true, draggable: false, flowAccess: false } as AnnotationsFeatureState,
}

interface LiveDemoProps {
  psChartStore: PsChartStore
  liveDemoData: TypeLiveDemoData
}

export const EARLY_BIRD_FORM_LINK =
  'https://docs.google.com/forms/d/e/1FAIpQLSdwR_QymnT0kM2xLmKPYJCD3TS0zCfZviKAg-8iOlTuzdLPZw/viewform'

export const LiveDemo = observer(function LiveDemo({ psChartStore, liveDemoData }: LiveDemoProps) {
  const liveDemoController = useMemo(() => new LiveDemoController(liveDemoData), [liveDemoData])
  const wrapperRef = useRef<HTMLDivElement>(null)
  const liveDemoRef = useRef<HTMLDivElement>(null)

  const [ready, setReady] = useState(false)

  useChartStatePreparation(psChartStore, liveDemoController, setReady)
  useDisablePinchZoom(liveDemoRef)
  liveDemoController.enableFooter()

  useEffect(() => {
    if (liveDemoController.currentTab !== LIVE_DEMO_TABS.trace) {
      psChartStore.setChartFeatures(processChartFeatures)
    } else {
      psChartStore.setChartFeatures(traceChartFeatures)
    }
  }, [psChartStore, liveDemoController.currentTab])

  return (
    <div className="flex flex-1 flex-col relative" ref={liveDemoRef}>
      <div className="flex flex-1 flex-col relative" ref={wrapperRef}>
        {liveDemoController.currentTab === LIVE_DEMO_TABS.annotation && (
          <PsAnnotations psChartStore={psChartStore} />
        )}
        {liveDemoController.currentTab !== LIVE_DEMO_TABS.annotation && (
          <PsChart psChartStore={psChartStore} />
        )}

        {ready && (
          <LiveDemoHighlighting
            liveDemoController={liveDemoController}
            wrapperRef={wrapperRef}
            psChartStore={psChartStore}
          />
        )}
      </div>
      <LiveDemoModalControl liveDemoController={liveDemoController} psChartStore={psChartStore} />
      <LiveDemoFooter liveDemoController={liveDemoController} />
      <LiveDemoStepsModal liveDemoController={liveDemoController} />
      {!ready && (
        <div className="absolute top-0 right-0 bottom-0 left-0 bg-dark-default z-50">
          <ModuleProgressCircle />
        </div>
      )}
    </div>
  )
})
