import { useCallback, useMemo } from 'react'
import { AxiosError } from 'axios'
import { FieldErrors, useFormContext, UseFormReturn } from 'react-hook-form'
import { generatePath, useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { t as translate } from 'i18next'
import * as yup from 'yup'
import NProgress from 'nprogress'

import { PATH_PROJECT_CONTRIBUTORS } from 'pages/ProjectContributorsPage'
import { PATH_FLOWS } from 'pages/FlowsPage'

import { Modal } from 'components/Modal'
import { Input } from 'components/Input'
import { EmailDomain } from 'components/teams/EmailDomain'
import { Select, SelectTheme } from 'components/Select'
import { ProjectImage } from 'components/projects/ProjectImage'
import { Form, getFormErrorsArrayFromObject, useForm, ValidationSchema } from 'components/Form/Form'
import { Icon } from 'components/Icon'

import { getOsName } from 'utils/getOsName'
import { getTechName } from 'utils/getTechName'

import { usePostProjectMutation, usePutProjectMutation } from 'hooks/useApiQuery'
import { useToaster } from 'hooks/useToaster'

import {
  ApiError,
  AuthenticatedUserDto,
  OsType,
  OsTypeValue,
  ProjectDto,
  TeamDto,
  TechType,
  TechTypeValue,
} from 'api/models'
import { FEATURE_FLAGS, useFeatureFlag } from 'utils/feature-flags'
import { isUserProductScience } from 'utils/hiddenFlow'

const EDIT_AIRTABLE_CLIENT_ID = 'EDIT_AIRTABLE_CLIENT_ID'

interface ProjectFormFields {
  name: string
  imageId: number | null
  os: OsTypeValue
  tech: TechTypeValue
  appId: string
  airtableClientId: string
  doWhitelist: boolean
  interactiveOnboarding: boolean
}

interface ProjectModalProps {
  isEdit?: boolean
  isOpen: boolean
  onClose: () => void
  isProjectCard?: boolean
  isFlowPage?: boolean
  onDeleteProjectClick?: () => void
  projectModel?: ProjectDto
  teamDomain?: TeamDto['domain']
  user: AuthenticatedUserDto
}

export const ProjectModal = ({
  projectModel,
  isOpen,
  onClose,
  isEdit = false,
  isProjectCard = false,
  isFlowPage = false,
  onDeleteProjectClick,
  teamDomain,
  user,
}: ProjectModalProps) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { teamUrlName, projectUrlName } = useParams() as {
    teamUrlName: string
    projectUrlName: string
  }

  const defaultValues = {
    name: projectModel?.name || '',
    os: projectModel?.os || OsType.ANDROID,
    tech: projectModel?.tech || (null as unknown as TechTypeValue),
    appId: projectModel?.appId || '',
    airtableClientId: projectModel?.airtableClientId || undefined,
    doWhitelist: projectModel?.doWhitelist || undefined,
    imageId: projectModel?.image?.id || null,
    interactiveOnboarding: projectModel?.interactiveOnboarding || false,
  }

  const formContext = useForm<ProjectFormFields>({
    validationSchema: projectFormSchema(),
    formProps: {
      defaultValues,
      mode: 'all',
    },
  })

  const postProjectMutation = usePostProjectMutation({
    onMutate: () => {
      NProgress.start()
    },
    onSuccess: async (data) => {
      //TODO: the navigate calls have to be moved out of modal in the parent component
      navigate(generatePath(PATH_PROJECT_CONTRIBUTORS, { projectUrlName: data.urlName }))
      toast.success(t('projects.projectFormModal.addSuccess', { name: data.name }))
      onClose()
    },
    onError: (err: AxiosError<ApiError>) => {
      toast.error(err.response?.data.message ?? t('errorMessage'))
    },
    onSettled: () => {
      NProgress.done()
    },
  })

  const putProjectMutation = usePutProjectMutation({
    onMutate: () => {
      NProgress.start()
    },
    onSuccess: (returnedProjectDto) => {
      //TODO: the navigate calls have to be moved out of modal in the parent component
      if (returnedProjectDto.urlName !== projectUrlName && !isProjectCard) {
        if (isFlowPage) {
          navigate(generatePath(PATH_FLOWS, { projectUrlName: returnedProjectDto.urlName }), {
            replace: true,
          })
        } else {
          navigate(
            generatePath(PATH_PROJECT_CONTRIBUTORS, {
              projectUrlName: returnedProjectDto.urlName,
            }),
            {
              replace: true,
            },
          )
        }
      }
      toast.success(t('projects.projectFormModal.editSuccess'))
      onClose()
    },
    onError: (err: AxiosError<ApiError>) => {
      toast.error(err.response?.data.message ?? t('errorMessage'))
    },
    onSettled: () => {
      NProgress.done()
    },
  })

  const handleActionClick = () => {
    if (isEdit) {
      putProjectMutation.mutate({
        projectUrlName: projectModel!.urlName,
        createProjectDto: formContext.getValues(),
      })
    } else {
      postProjectMutation.mutate({
        teamUrlName,
        createProjectDto: formContext.getValues(),
      })
    }
  }

  const isFormValid = () => {
    const values = formContext.getValues()
    const noErrors = Object.values(formContext.formState.errors).length === 0

    return Boolean(values.name && values.os && values.appId && values.tech) && noErrors
  }

  const afterClose = () => {
    formContext.reset(defaultValues)
  }

  return (
    <Modal
      title={
        isEdit ? t('projects.projectFormModal.editTitle') : t('projects.projectFormModal.addTitle')
      }
      isOpen={isOpen}
      onClose={onClose}
      afterClose={afterClose}
      additionalButton={
        isEdit
          ? { children: t('projects.projectFormModal.deleteButton'), onClick: onDeleteProjectClick }
          : {}
      }
      actionButton={{
        children: isEdit
          ? t('projects.projectFormModal.editButton')
          : t('projects.projectFormModal.addButton'),
        onClick: handleActionClick,
        disabled: !isFormValid(),
      }}
    >
      <div className="py-[16px]">
        <div className="flex items-center mb-[32px]">
          <ProjectImage
            image={projectModel?.image}
            onImageIdChange={(newImageId: number | null) =>
              formContext.setValue('imageId', newImageId)
            }
            className="shrink-0"
          />
          {isEdit && (
            <div className="text-normal tracking-wide pl-[16px]">
              {getOsName(projectModel?.os)}
              <div className="text-gray-normal">{getTechName(projectModel?.tech)}</div>
            </div>
          )}
        </div>
        <ProjectForm
          user={user}
          teamDomain={teamDomain}
          formContext={formContext}
          isEdit={isEdit}
        />
      </div>
    </Modal>
  )
}

interface ProjectFormProps {
  teamDomain?: TeamDto['domain']
  user: AuthenticatedUserDto
  formContext?: UseFormReturn<ProjectFormFields>
  isEdit?: boolean
}

export const ProjectForm = ({
  user,
  teamDomain,
  formContext,
  isEdit = false,
}: ProjectFormProps) => {
  const toaster = useToaster()

  const handleSubmitInvalid = useCallback(
    (errors: FieldErrors<ProjectFormFields>) => {
      const errorMessages = getFormErrorsArrayFromObject(errors)
      errorMessages.forEach((errorMsg) => {
        toaster.error(errorMsg)
      })
    },
    [toaster],
  )

  const isInteractiveOnboardingEnabled = useFeatureFlag(FEATURE_FLAGS.INTERACTIVE_ONBOARDING)
  const isInternalUser = isUserProductScience(user.email)
  const isAirtableEnabled = user.features.includes(EDIT_AIRTABLE_CLIENT_ID)

  return (
    <Form<ProjectFormFields>
      formContext={formContext!}
      onSubmit={() => {}}
      onSubmitInvalid={handleSubmitInvalid}
    >
      <ProjectName />
      {!isEdit && (
        <>
          <ProjectOs />
          <ProjectPlatform />
        </>
      )}
      <AppBundleId />
      {isAirtableEnabled && <AirTableClientId />}
      {teamDomain && <ProjectEmailDomain teamDomain={teamDomain} />}
      {isInteractiveOnboardingEnabled && isInternalUser && <InteractiveOnboarding />}
    </Form>
  )
}

export const ProjectName = () => {
  const { t } = useTranslation()
  const { watch, register, formState } = useFormContext<ProjectFormFields>()
  const value = watch('name')

  return (
    <div className="relative w-[320px]">
      <Input
        {...register('name')}
        placeholder={t('projects.projectFormModal.projectName')}
        maxLength={30}
        showCounter={true}
        error={formState.errors.name?.message}
        value={value || ''}
        inModal
      />
    </div>
  )
}

export const ProjectOs = () => {
  const { t } = useTranslation()

  const { watch, setValue, register, resetField } = useFormContext<ProjectFormFields>()

  const onChange = useCallback(
    (value: string | string[]) => {
      setValue('os', value as OsTypeValue)
      resetField('tech')
    },
    [setValue, resetField],
  )

  const createOsOption = useCallback((os: OsTypeValue) => ({ label: getOsName(os), value: os }), [])

  const listItems = useMemo(
    () => [createOsOption(OsType.ANDROID), createOsOption(OsType.IOS)],
    [createOsOption],
  )

  const osValue = watch('os')

  return (
    <Select
      {...register('os')}
      options={listItems}
      value={osValue}
      className="w-[320px] mb-[16px]"
      placeholder={t('projects.projectFormModal.os')}
      theme={SelectTheme.OVERLAY}
      onChange={onChange}
    />
  )
}

export const ProjectPlatform = () => {
  const { t } = useTranslation()

  const { watch, setValue, register } = useFormContext<ProjectFormFields>()

  const techValue = watch('tech')
  const osValue = watch('os')

  const createTechOption = useCallback(
    (tech: TechTypeValue) => ({ label: getTechName(tech), value: tech }),
    [],
  )

  const platformOptions = useMemo(() => {
    return osValue === OsType.ANDROID
      ? [createTechOption(TechType.JVM), createTechOption(TechType.REACT_NATIVE)]
      : [createTechOption(TechType.SWIFT)]
  }, [createTechOption, osValue])

  const onChange = useCallback(
    (value: TechTypeValue | TechTypeValue[]) => {
      setValue('tech', value as TechTypeValue)
    },
    [setValue],
  )

  return (
    <Select
      {...register('tech')}
      options={platformOptions}
      value={techValue}
      className="w-[320px] mb-[16px]"
      placeholder={t('projects.projectFormModal.platform')}
      theme={SelectTheme.OVERLAY}
      onChange={onChange}
    />
  )
}

export const AppBundleId = () => {
  const { t } = useTranslation()
  const { register, watch, formState } = useFormContext<ProjectFormFields>()
  const appIdValue = watch('appId')

  return (
    <Input
      {...register('appId')}
      placeholder={t('projects.projectFormModal.appBundleId')}
      maxLength={50}
      inModal
      showCounter={true}
      value={appIdValue}
      error={formState.errors.appId?.message}
    />
  )
}

export const AirTableClientId = () => {
  const { t } = useTranslation()
  const { register, watch } = useFormContext<ProjectFormFields>()
  const airtableClientIdValue = watch('airtableClientId')

  return (
    <Input
      {...register('airtableClientId', {
        setValueAs: (value) =>
          typeof value === 'string' && value.trim() === '' ? undefined : value,
      })}
      placeholder={t('projects.projectFormModal.airtableClientId')}
      maxLength={50}
      inModal
      showCounter={true}
      value={airtableClientIdValue}
    />
  )
}

export const ProjectEmailDomain = ({ teamDomain }: { teamDomain: string }) => {
  const { setValue, watch } = useFormContext<ProjectFormFields>()
  const onChange = useCallback(
    (value: boolean) => {
      setValue('doWhitelist', value)
    },
    [setValue],
  )

  const value = watch('doWhitelist')
  return (
    <EmailDomain
      domainValue={teamDomain}
      doWhitelistValue={value}
      onDoWhitelistValueChange={onChange}
      readonly
    />
  )
}

export const InteractiveOnboarding = () => {
  const { register } = useFormContext<ProjectFormFields>()
  const { t } = useTranslation()
  return (
    <div>
      <hr className="mt-[16px] mb-[16px] border-gray-strokeLight" />
      <p className="text-[12px] mb-[8px] font-[500]">
        {t('projects.projectFormModal.interactiveOnboarding')}
      </p>
      <label
        className="text-small tracking-wide text-gray-normal select-none flex hover:cursor-pointer"
        htmlFor={'interactive-onboarding'}
      >
        <div className="relative w-[16px] h-[16px] items-center flex justify-center">
          <input
            {...register('interactiveOnboarding')}
            id="interactive-onboarding"
            type="checkbox"
            className="peer absolute w-full h-full opacity-0 z-[2] cursor-pointer"
          />
          <div className="w-full h-full border border-gray-faded rounded-sm peer-checked:border-electro peer-checked:bg-electro transition-colors peer-focus:outline peer-focus:outline-offset-1 peer-focus:outline-electro" />
          <Icon
            icon="check"
            className="absolute text-icon text-white opacity-0 transition-opacity peer-checked:opacity-100 z-[1]"
          />
        </div>
        <span className="ml-[8px]">{t('projects.projectFormModal.limitUsers')}</span>
      </label>
      <hr className="mt-[24px] border-gray-strokeLight" />
    </div>
  )
}

export function projectFormSchema() {
  return yup.object<ValidationSchema<ProjectFormFields>>({
    name: yup
      .string()
      .required(translate('projects.projectFormModal.nameIsRequired'))
      .matches(/.*[^\d].*/, translate('projects.projectFormModal.mustContainLetter'))
      .matches(/^[a-zA-Z0-9\s\-_]+$/, translate('projects.projectFormModal.projectNameValid'))
      .max(30),
    os: yup.string().required(),
    tech: yup.string().required(),
    appId: yup
      .string()
      .required(translate('projects.projectFormModal.appBundleIdIsRequired'))
      .max(50),
    airtableClientId: yup.string().nullable(),
    doWhitelist: yup.boolean().required(),
    interactiveOnboarding: yup.boolean().nullable(),
    imageId: yup.number().nullable(),
  })
}
