import React, { useEffect, useState } from 'react'
import styled from 'styled-components/macro'
import tw from 'twin.macro'
import { Popover } from '@headlessui/react'
import { useTranslation } from 'react-i18next'
import dayjs from 'dayjs'
import { useSearchParams } from 'react-router-dom'
import customParseFormat from 'dayjs/plugin/customParseFormat'

import { DATE_STRING_FORMAT, DateInput } from 'components/DateInput'
import { Button, ButtonTextColorVariantEnum, ButtonVariantEnum } from 'components/Button'

interface DateFilterProps {
  param: string
  traceTimes: TraceTimesType
}

export type TraceTimesType = { first?: string; last?: string } | null

dayjs.extend(customParseFormat)

export const DateFilter = ({ param, traceTimes }: DateFilterProps) => {
  const { t } = useTranslation()
  const [searchParams, setSearchParams] = useSearchParams()

  const [dateFrom, setDateFrom] = useState('')
  const [dateTo, setDateTo] = useState('')

  const [isRequired, setRequired] = useState({ from: false, to: false })

  useEffect(() => {
    const dateFromParam = searchParams.get(param)
    const dateToParam = searchParams.get(`${param}To`)
    if (
      dateFromParam &&
      dateToParam &&
      dayjs(dateFromParam).isValid() &&
      dayjs(dateToParam).isValid()
    ) {
      setDateFrom(dayjs(dateFromParam).format(DATE_STRING_FORMAT))
      setDateTo(dayjs(dateToParam).format(DATE_STRING_FORMAT))
    } else {
      setDateFrom('')
      setDateTo('')
    }
  }, [param, searchParams])

  const handleApplyClick = (close: () => void) => () => {
    const parsedDateFrom = dayjs(dateFrom, DATE_STRING_FORMAT)
    const parsedDateTo = dayjs(dateTo, DATE_STRING_FORMAT)
    const getDateFromTextLength = dateFrom.replace(/[^\d]/g, '').length
    const getDateToTextLength = dateTo.replace(/[^\d]/g, '').length
    if (
      (getDateFromTextLength > 0 || getDateToTextLength > 0) &&
      (getDateFromTextLength === 0 || getDateToTextLength === 0)
    ) {
      setRequired({ from: getDateFromTextLength === 0, to: getDateToTextLength === 0 })
      return
    }
    if (
      getDateFromTextLength === 12 &&
      getDateToTextLength === 12 &&
      parsedDateFrom.isValid() &&
      parsedDateTo.isValid() &&
      parsedDateFrom.format(DATE_STRING_FORMAT) === dateFrom &&
      parsedDateTo.format(DATE_STRING_FORMAT) === dateTo
    ) {
      searchParams.set(param, parsedDateFrom.format())
      searchParams.set(`${param}To`, parsedDateTo.format())
      setSearchParams(searchParams)
    } else {
      searchParams.delete(param)
      searchParams.delete(`${param}To`)
      setSearchParams(searchParams)
    }
    close()
  }

  const handleResetRequired = () => {
    setRequired({ from: false, to: false })
  }

  return (
    <Panel>
      {({ close }: { close: () => void }) => (
        <>
          <Head>
            <SectionTitle>{`${t('traces.filters.by')} ${t(
              `traces.filters.columnsBy.${param}`,
            ).toLocaleLowerCase()}`}</SectionTitle>
            <DateSelectBlock>
              <DateType>{t('traces.filters.from')}</DateType>
              <DateInputView>
                <DateInput
                  value={dateFrom}
                  onChange={setDateFrom}
                  isRequired={isRequired.from}
                  resetRequired={handleResetRequired}
                />
                <DateNotice>
                  {t('traces.filters.firstTraceTime')}:{' '}
                  {traceTimes && dayjs(traceTimes.first).format('MM.DD.YY HH:mm:ss')}
                </DateNotice>
              </DateInputView>
            </DateSelectBlock>
            <DateSelectBlock>
              <DateType>{t('traces.filters.to')}</DateType>
              <DateInputView>
                <DateInput
                  value={dateTo}
                  onChange={setDateTo}
                  isRequired={isRequired.to}
                  resetRequired={handleResetRequired}
                />
                <DateNotice>
                  {t('traces.filters.latestTraceTime')}:{' '}
                  {traceTimes && dayjs(traceTimes.last).format('MM.DD.YY HH:mm:ss')}
                </DateNotice>
              </DateInputView>
            </DateSelectBlock>
          </Head>
          <Bottom>
            <Button
              variant={ButtonVariantEnum.Text}
              textColorVariant={ButtonTextColorVariantEnum.Muted}
              onClick={close}
            >
              {t('traces.filters.cancel')}
            </Button>
            <Button
              variant={ButtonVariantEnum.Text}
              onClick={handleApplyClick(close)}
              data-tid={`flowlib-filterby-${param}`}
            >
              {t('traces.filters.apply')}
            </Button>
          </Bottom>
        </>
      )}
    </Panel>
  )
}

const Panel = styled(Popover.Panel)`
  ${tw`shadow-base`}
  position: absolute;
  z-index: 2;
  top: 100%;
  left: 0;
  width: 280px;
  margin-top: 1px;
  border-radius: 2px;
  background-color: ${({ theme }) => theme.colors.dark.dark5};
`

const Head = styled.div`
  padding-top: 19px;
  padding-left: 16px;
`

const DateType = styled.div`
  min-width: 52px;
`

const DateSelectBlock = styled.div`
  display: flex;
  padding-top: 8px;
`

const DateInputView = styled.div`
  ${tw`border-b border-solid border-white/10`}
  width: 100%;
  padding-bottom: 11px;

  ${DateSelectBlock}:last-child & {
    border-bottom: none;
  }
`

const DateNotice = styled.span`
  margin-top: 8px;
`

const SectionTitle = styled.div`
  padding-bottom: 10px;
`

const Bottom = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 56px;
  margin-top: 8px;
  padding: 0 16px;
  background: ${({ theme }) => theme.colors.dark.dark4};
`
