import type { Entry, EntryFields } from 'contentful'
import type { WithContentTypeLink } from './WithContentTypeLink'

export interface TypeQuickstartPageFields {
  title: EntryFields.Symbol
  body: EntryFields.Text
  tryBlock?: EntryFields.Text
}

export type TypeQuickstartPage = Entry<TypeQuickstartPageFields>

export function isTypeQuickstartPage(entry: WithContentTypeLink): entry is TypeQuickstartPage {
  return entry.sys.contentType.sys.id === 'quickstartPage'
}
