import React, { useCallback, useEffect, useState } from 'react'

import { Modal } from 'components/Modal'
import { ChartPageParams, ContentfulEntryDto, FlowReq } from 'api/models'
import { queryKeys, useFlowsQuery, useFlowTracesQuery } from 'hooks/useApiQuery'
import { Select } from 'components/Select'
import { Button, ButtonTextColorVariantEnum } from 'components/Button'
import { useTranslation } from 'react-i18next'
import { useApi } from 'contexts/di-context'
import { useToaster } from 'hooks/useToaster'
import { LoadingSpinner } from 'components/LoadingSpinner'
import { generatePath } from 'react-router-dom'
import { PATH_FLOW_TRACE_LIVE_DEMO } from 'pages/FlowTraceLiveDemo'
import { Api } from 'api/Api'
import { QueryClient, useQueryClient } from 'react-query'
import { PATH_FLOW } from 'pages/FlowPage'
import { createClient } from 'contentful'

export interface LiveDemoPerFlowAssignModalState {
  isOpen: boolean
  traceParams?: ChartPageParams
}

type LiveDemoPerFlowAssignModalProps = {
  onClose: () => void
} & Required<LiveDemoPerFlowAssignModalState>

export const LiveDemoPerFLowAssignModal = ({
  traceParams,
  isOpen,
  onClose,
}: LiveDemoPerFlowAssignModalProps) => {
  const { traceProjectLocalId, flowProjectLocalId, projectUrlName } = traceParams
  const { data, refetch } = useFlowTracesQuery({ projectUrlName, flowProjectLocalId })
  const { data: flowsData } = useFlowsQuery({ projectUrlName })
  const trace = data?.find((item) => item.projectLocalId === traceProjectLocalId)
  const contentfulData = trace?.liveDemo?.content.contentful
  const targetFlowId = trace?.liveDemo?.target?.flow?.flowId ?? null
  const targetFlowIdString = targetFlowId !== null ? String(targetFlowId) : null
  const api = useApi()
  const queryClient = useQueryClient()

  const { t } = useTranslation()
  const [selectedFlow, setSelectedFlow] = useState<string | null>(targetFlowIdString)
  const flowChanged = selectedFlow !== targetFlowIdString

  const createDoc = useCallback(() => {
    return api.createLiveDemoContent(traceParams).then(() => refetch())
  }, [api, refetch, traceParams])

  const assignLiveDemoTrace = useCallback(async () => {
    if (selectedFlow && contentfulData) {
      const contentfulClient = createClient({
        space: contentfulData.spaceId,
        environment: contentfulData.environmentId,
        accessToken: process.env.REACT_APP_CONTENTFUL_TOKEN!,
      })

      const targetFLowReq = {
        projectUrlName: traceParams.projectUrlName,
        flowProjectLocalId: selectedFlow,
      }

      // Checking that contentful entry is published
      try {
        await contentfulClient.getEntry(contentfulData.entryId)
      } catch {
        throw new Error(t('components.liveDemo.assignmentModal.contentfulWarning'))
      }

      await api.assignLiveDemoTraceToFlow(targetFLowReq, {
        flowId: Number(traceParams.flowProjectLocalId),
        traceId: Number(traceParams.traceProjectLocalId),
      })

      await Promise.all([refetch(), refetchChangedFlowTraces(targetFLowReq, api, queryClient)])
    }
  }, [api, queryClient, refetch, selectedFlow, traceParams, contentfulData, t])

  const removeLiveDemoAssignment = useCallback(() => {
    const targetFlow = trace?.liveDemo?.target?.flow
    if (targetFlow) {
      const flowReq = {
        projectUrlName: targetFlow.projectUrlName,
        flowProjectLocalId: String(targetFlow.flowId),
      }
      return api
        .removeLiveDemoAssignment(flowReq)
        .then(() => refetch())
        .then(() => refetchChangedFlowTraces(flowReq, api, queryClient))
    }
    return Promise.resolve()
  }, [api, queryClient, trace, refetch])

  const { isPending: createDocPending, execute: handleCrateDockClick } = usePromise(createDoc)
  const { isPending: assignLiveDemoTracePending, execute: handleAssignClick } =
    usePromise(assignLiveDemoTrace)
  const { isPending: removeAssignmentPending, execute: handleRemoveClick } =
    usePromise(removeLiveDemoAssignment)

  const isPending =
    !trace ||
    !flowsData ||
    !contentfulData ||
    createDocPending ||
    assignLiveDemoTracePending ||
    removeAssignmentPending

  const hasData = trace && flowsData

  const showDeleteButton = !flowChanged && targetFlowIdString !== null

  useEffect(() => {
    if (isOpen) {
      setSelectedFlow(targetFlowIdString)
    }
  }, [projectUrlName, traceProjectLocalId, flowProjectLocalId, targetFlowIdString, isOpen])

  return (
    <Modal
      title={t('components.liveDemo.assignmentModal.title')}
      isOpen={isOpen}
      onClose={onClose}
      actionButton={{
        textColorVariant: ButtonTextColorVariantEnum.Primary,
        disabled: isPending || !selectedFlow || !flowChanged || !!targetFlowId,
        children: assignLiveDemoTracePending ? (
          <LoadingSpinner size={12} />
        ) : (
          t('components.liveDemo.assignmentModal.assign')
        ),
        onClick: handleAssignClick,
      }}
      additionalButton={
        showDeleteButton
          ? {
              textColorVariant: ButtonTextColorVariantEnum.Bad,
              children: removeAssignmentPending ? (
                <LoadingSpinner size={12} />
              ) : (
                t('components.liveDemo.assignmentModal.remove')
              ),
              disabled: isPending,
              onClick: handleRemoveClick,
            }
          : undefined
      }
    >
      {hasData ? (
        <div className="py-[15px]">
          <div className="text-small mb-[10px]">
            <span className="mr-[20px]">{t('components.liveDemo.assignmentModal.traceName')}</span>
            <span className="font-bold">{trace.name}</span>
          </div>
          <div className="flex items-center mb-[10px]">
            <span className="text-small mr-[20px]">
              {t('components.liveDemo.assignmentModal.contentfulLabel')}
            </span>
            {contentfulData ? (
              <a
                href={getDocEditLink(contentfulData)}
                target="_blank"
                rel="noreferrer"
                className="text-small underline"
              >
                {t('components.liveDemo.assignmentModal.linkToEdit')}
              </a>
            ) : (
              <Button disabled={createDocPending} isSmall={true} onClick={handleCrateDockClick}>
                {createDocPending ? (
                  <LoadingSpinner size={12} />
                ) : (
                  t('components.liveDemo.assignmentModal.contentfulCreate')
                )}
              </Button>
            )}
          </div>
          {contentfulData && (
            <div className="flex items-center mb-[10px]">
              <span className="text-small mr-[20px]">
                {t('components.liveDemo.assignmentModal.liveDemoPreview')}
              </span>
              <a
                href={generatePath(PATH_FLOW_TRACE_LIVE_DEMO, {
                  projectUrlName,
                  flowProjectLocalId,
                  traceProjectLocalId,
                })}
                target="_blank"
                rel="noreferrer"
                className="text-small underline"
              >
                {t('components.liveDemo.assignmentModal.liveDemoPreviewLink')}
              </a>
            </div>
          )}
          <div>
            <div className="mb-[10px]">
              <span className="text-small mr-[20px]">
                {t('components.liveDemo.assignmentModal.flow')}
              </span>
              {targetFlowIdString && !flowChanged && (
                <a
                  href={generatePath(PATH_FLOW, {
                    projectUrlName,
                    flowProjectLocalId: targetFlowIdString,
                  })}
                  target="_blank"
                  rel="noreferrer"
                  className="text-small underline"
                >
                  {t('components.liveDemo.assignmentModal.liveDemoPreviewLink')}
                </a>
              )}
            </div>
            <Select
              value={selectedFlow}
              onChange={(value) => setSelectedFlow(value as string)}
              placeholder="Select flow to assign"
              disabled={!contentfulData || !!targetFlowId}
              options={flowsData.map((flow) => ({
                label: flow.name,
                value: String(flow.projectLocalId),
              }))}
            />
          </div>
          {!contentfulData && (
            <div className="text-small text-state-bad mt-[20px]">
              {t('components.liveDemo.assignmentModal.contentfulError')}
            </div>
          )}
          {!!targetFlowId && (
            <div className="text-small text-state-attention mt-[20px]">
              {t('components.liveDemo.assignmentModal.changeFlowWarning')}
            </div>
          )}
        </div>
      ) : (
        <div className="p-[50px]">
          <LoadingSpinner size={50} />
        </div>
      )}
    </Modal>
  )
}

export const usePromise = (func: () => Promise<unknown>) => {
  const [isPending, setIsPending] = useState(false)
  const toaster = useToaster()

  const execute = useCallback(() => {
    setIsPending(true)
    func()
      .catch((err) => {
        toaster.error(err)
      })
      .finally(() => {
        setIsPending(false)
      })
  }, [toaster, func])

  return {
    isPending,
    execute,
  }
}

const getDocEditLink = (data: ContentfulEntryDto) =>
  `https://app.contentful.com/spaces/${data.spaceId}/environments/${
    data.environmentId || 'master'
  }/entries/${data.entryId}`

const refetchChangedFlowTraces = (
  flowReq: Required<FlowReq>,
  api: Api,
  queryClient: QueryClient,
) => {
  return api.getFlowTraces(flowReq).then((traces) => {
    queryClient.setQueryData(queryKeys.flowTraces(flowReq), traces)
  })
}
