import { HeaderCol } from 'components/flows/ra/runs/details/utils/HeaderCol'
import React from 'react'
import { useTranslation } from 'react-i18next'

export const GridHeader = () => {
  const { t } = useTranslation()
  return (
    <div className="grid grid-cols-3 bg-dark-dark3 text-small rounded">
      <HeaderCol>{t('ra.flow.run.gridHeader.property')}</HeaderCol>
      <HeaderCol>{t('ra.flow.run.gridHeader.current')}</HeaderCol>
      <HeaderCol>{t('ra.flow.run.gridHeader.previous')}</HeaderCol>
    </div>
  )
}
