import React, { useContext, useEffect, useMemo } from 'react'
import 'locale/i18n'
import { QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import { CompositionRoot } from 'contexts/CompositionRoot'
import { Axios } from 'axios'
import { Api } from 'api/Api'
import { ExceptionHandler } from 'utils/ExceptionHandler'
import { FirebaseAuth } from 'contexts/FirebaseAuth'
import { Okta } from 'contexts/Okta'
import { AuthStore } from 'contexts/AuthStore'
import { observer } from 'mobx-react-lite'
import type { Analytics } from 'utils/analytics'
import { ContentfulClientApi, createClient } from 'contentful'

export const DiContext = React.createContext<DiContextInterface | null>(null)

export interface DiContextInterface {
  compositionRoot: CompositionRoot
}

export function useDi(): DiContextInterface {
  const context = useContext(DiContext)
  if (context == null) {
    throw new Error('DI context is not initialized')
  }
  return context
}

export function useAxios(): Axios {
  return useDi().compositionRoot.axios
}

export function useApi(): Api {
  return useDi().compositionRoot.api
}

export function useExceptionHandler(): ExceptionHandler {
  return useDi().compositionRoot.exceptionHandler
}

export function useFirebaseAuth(): FirebaseAuth {
  return useDi().compositionRoot.firebaseAuth
}

export function useOkta(): Okta {
  return useDi().compositionRoot.oktaAuth
}

export function useAuthStore(): AuthStore {
  return useDi().compositionRoot.authStore
}

export function useAnalytics(): Analytics {
  return useDi().compositionRoot.analytics
}

export function useContentfulClient(contentfulOptions?: {
  spaceId: string
  environmentId: string
}): ContentfulClientApi {
  const { contentfulClient: clientFromDI } = useDi().compositionRoot
  return useMemo(() => {
    if (contentfulOptions) {
      return createClient({
        space: contentfulOptions.spaceId,
        environment: contentfulOptions.environmentId,
        accessToken: process.env.REACT_APP_CONTENTFUL_TOKEN!,
      })
    }
    return clientFromDI
  }, [clientFromDI, contentfulOptions])
}

interface DiContextProviderProps {
  children: React.ReactNode
  compositionRoot: CompositionRoot
}

export const DiContextProvider = observer(function DiContextProvider({
  compositionRoot: cr,
  children,
}: DiContextProviderProps) {
  const { isReady } = cr.oktaAuth
  useEffect(() => cr.oktaAuth.dispose)
  return (
    <DiContext.Provider value={{ compositionRoot: cr }}>
      <QueryClientProvider client={cr.queryClient}>
        <ReactQueryDevtools initialIsOpen={false} />
        {isReady ? children : null}
      </QueryClientProvider>
    </DiContext.Provider>
  )
})
