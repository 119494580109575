import { Trans } from 'react-i18next'
import { useCustomerSupportModal } from 'contexts/customer-support-modal-context'
import { useAuth } from 'contexts/auth-context'
import { PRIVACY_LINK, TERMS_SELF_SERVE_LINK } from 'utils/links'
import { twMerge } from 'tailwind-merge'
import { Icon } from './Icon'

export const Footer = ({ className }: { className?: string }) => {
  const { openModal } = useCustomerSupportModal()
  const { isSignedIn } = useAuth()

  return (
    <footer
      className={twMerge(
        'bg-dark-dark2 px-4 w-full h-[40px] flex justify-between items-center text-[12px] leading-[16.8px] tracking-[3%] font-[400] whitespace-nowrap',
        className,
      )}
    >
      <div className="text-gray-faded">
        <Trans
          i18nKey="footer.allRightsReserved"
          values={{
            year: new Date().getFullYear(),
          }}
        />
      </div>
      <div className="hidden sm:block ml-4">
        <ul className="flex text-gray-normal">
          <li className="mx-1 hover:text-white hover:cursor-pointer whitespace-nowrap">
            <Trans
              i18nKey="footer.customerSupport"
              components={{
                a: isSignedIn ? (
                  <span role="button" onClick={openModal} />
                ) : (
                  <a
                    target="_blank"
                    href="https://www.productscience.ai/customer-support"
                    rel="noreferrer"
                  />
                ),
                icon: <Icon icon="support" className="mr-2" />,
              }}
            />
          </li>
          <li className="mx-1 hover:text-white hover:cursor-pointer whitespace-nowrap">
            <Trans
              i18nKey="footer.documentation"
              components={{
                a: <a target="_blank" href="https://product-science.github.io/" rel="noreferrer" />,
              }}
            />
          </li>
          <li className="mx-1 hover:text-white hover:cursor-pointer whitespace-nowrap">
            <Trans
              i18nKey="footer.privacy"
              components={{
                a: <a target="_blank" href={PRIVACY_LINK} rel="noreferrer" />,
              }}
            />
          </li>
          <li className="mx-1 hover:text-white hover:cursor-pointer whitespace-nowrap">
            <Trans
              i18nKey="footer.terms"
              components={{
                a: <a target="_blank" href={TERMS_SELF_SERVE_LINK} rel="noreferrer" />,
              }}
            />
          </li>
        </ul>
      </div>
    </footer>
  )
}
