import { Rect } from 'components/ps-chart/models/helper-types'

export const getBorderRect = <T extends Rect>(sliceRect: T, borderWidth: number): T => {
  const shift = borderWidth / 2

  if (sliceRect.w === 0) {
    return sliceRect
  }

  return {
    ...sliceRect,
    x: sliceRect.x + shift,
    y: sliceRect.y + shift,
    w: sliceRect.w - borderWidth,
    h: sliceRect.h - borderWidth,
  }
}
