import { useTranslation } from 'react-i18next'
import { UserMenu } from 'components/lite/UserMenu'
import { PATH_LITE_PROMO } from 'pages/lite/LitePromoPage'
import { Link } from 'react-router-dom'
import { LiteLayoutContainer } from 'components/lite/LiteLayout'
import { Logo } from 'components/Logo'

type Props = {
  isRootPage?: boolean
}

export function LiteHeader({ isRootPage }: Props) {
  const { t } = useTranslation()
  return (
    <LiteLayoutContainer className="mt-3 mb-20">
      <div className="flex flex-nowrap">
        <h3 className="text-header-small relative mt-2">
          {isRootPage ? (
            t('lite.psToolLite')
          ) : (
            <Link to={PATH_LITE_PROMO}>{t('lite.psToolLite')}</Link>
          )}
          <span className="text-gray-faded absolute top-0 text-small">{t('lite.beta')}</span>
        </h3>
        <div className="flex-grow"></div>
        <div className="min-w-[100px] flex items-end flex-col">
          <UserMenu />
        </div>
      </div>
    </LiteLayoutContainer>
  )
}

export function LiteTargetHeader() {
  return (
    <LiteLayoutContainer className="mt-3 mb-20">
      <div className="flex flex-nowrap">
        <Logo color="black" className="mt-4" />
        <div className="flex-grow"></div>
        <div className="min-w-[100px] flex items-end flex-col">
          <UserMenu />
        </div>
      </div>
    </LiteLayoutContainer>
  )
}
