import { observer } from 'mobx-react-lite'
import { GuideStore } from 'components/guide/GuideStore'

interface Props {
  guideStore: GuideStore
}

export const TechStackBreadcrumbs = observer(function TechStackBreadcrumbs({ guideStore }: Props) {
  const { selectedTechStack } = guideStore
  return (
    <div className="text-small text-gray-normal pb-[2px] ">
      {selectedTechStack.map((item, index) => (
        <span className="mr-[8px]" key={String(index)}>
          {item}
        </span>
      ))}
    </div>
  )
})
