import React from 'react'

import { Icon } from 'components/Icon'

interface AuthButtonProps {
  label: string
  isDisabled?: boolean
}

export function AuthButton({ label, isDisabled }: AuthButtonProps) {
  return (
    <button
      className="group flex relative mx-auto my-0 outline-offset-2"
      aria-label={label}
      disabled={isDisabled}
    >
      <span className="flex items-center justify-center w-[28px] h-[28px] rounded-[28px] border border-electro bg-electro group-hover:border-sky group-hover:bg-sky group-disabled:border-gray-dark group-disabled:bg-transparent transition">
        <Icon icon="auth-arrow-r" className="absolute text-icon group-disabled:text-gray-dark" />
      </span>
    </button>
  )
}
