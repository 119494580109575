import { useEffect, useRef } from 'react'

/**
 * Implemented with https://github.com/darsain/fpsmeter
 * https://darsa.in/fpsmeter/
 */

let isFpsMeterScriptAdded = false
export const FpsMeter = () => {
  const container = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (container.current == null) {
      return undefined
    }
    const fpsContainer = container.current

    let fpsMeter: FPSMeter
    const startFpsMeter = () => {
      fpsMeter = new FPSMeter(fpsContainer, {
        theme: 'dark',
        graph: 1,
        heat: 1,
        decimals: 0,
        history: 40,
      })

      const calc = () => {
        requestAnimationFrame(() => {
          fpsMeter?.tick()
          calc()
        })
      }
      calc()
    }

    if (isFpsMeterScriptAdded) {
      startFpsMeter()
    } else {
      const script = document.createElement('script')
      script.src = '/fpsmeter.js'
      script.async = true

      document.body.appendChild(script)
      isFpsMeterScriptAdded = true

      script.addEventListener('load', startFpsMeter)
    }

    return () => {
      fpsMeter?.destroy()
    }
  }, [])

  return <div ref={container} className="h-11 relative" />
}
