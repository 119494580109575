import React, {
  useState,
  useCallback,
  useRef,
  useEffect,
  ReactElement,
  ReactNode,
  MutableRefObject,
  useMemo,
} from 'react'
import { ChildrenRect } from 'components/tooltip/getTooltipPosition'
import { BodyPortal } from 'components/BodyPortal'
import { TooltipContainer } from './TooltipContainer'
import { TooltipPositionProps } from './types'

export type TooltipChildrenRef = MutableRefObject<HTMLElement | null>

export type TooltipAnimationSettings = {
  animated?: boolean
  showDelay?: number
  hideDelay?: number
  showAnimationTime?: number
  hideAnimationTime?: number
}

export type TooltipProps = {
  children?: ReactElement
  content: ReactNode
  childrenRef?: TooltipChildrenRef
  childrenRect?: ChildrenRect
  visible?: boolean
  disabled?: boolean
} & TooltipAnimationSettings &
  TooltipPositionProps

export const Tooltip = (props: TooltipProps) => {
  const {
    children,
    content,
    childrenRef: passedChildrenRef,
    childrenRect,
    offsetX = 0,
    offsetY = 0,
    place = 'bottom',
    align = 'center',
    offsetFromTarget = 4,
    offsetFromEdge = 8,
    animated = false,
    showDelay = 0,
    hideDelay = 0,
    showAnimationTime = 0,
    hideAnimationTime = 0,
    visible,
    disabled,
  } = props
  const [active, setActive] = useState(visible !== undefined ? visible : !children)
  const [renderTooltip, setRenderTooltip] = useState(false)
  const [showTooltip, setShowTooltip] = useState(false)
  const childrenRef = useRef<HTMLElement>(null)

  const hideTooltip = useCallback(() => setActive(false), [setActive])

  const handleMouseEnter = useCallback(() => {
    if (children?.props.onPointerEnter) {
      children.props.onPointerEnter()
    }
    setActive(true)
  }, [setActive, children])
  const handleMouseLeave = useCallback(() => {
    if (children?.props.onPointerLeave) {
      children.props.onPointerLeave()
    }
    setActive(false)
  }, [setActive, children])

  useEffect(() => {
    if (passedChildrenRef) {
      passedChildrenRef.current = childrenRef.current
    }
  }, [passedChildrenRef, children])

  const wrappedChildren = useMemo(
    () =>
      children &&
      React.cloneElement(children, {
        onPointerEnter: handleMouseEnter,
        onPointerLeave: handleMouseLeave,
        ref: childrenRef,
      }),
    [children, handleMouseEnter, handleMouseLeave],
  )

  useEffect(() => {
    if (visible !== undefined) {
      setActive(visible)
    }
  }, [visible])

  const showTimeout = useRef<number | null>(null)
  const renderTimeout = useRef<number | null>(null)
  useEffect(() => {
    function clear() {
      if (renderTimeout.current) {
        clearTimeout(renderTimeout.current)
        renderTimeout.current = null
      }
      if (showTimeout.current) {
        clearTimeout(showTimeout.current)
        showTimeout.current = null
      }
    }
    clear()

    if (active) {
      if (showDelay !== 0) {
        renderTimeout.current = window.setTimeout(() => {
          setRenderTooltip(true)
          setShowTooltip(true)
        }, showDelay)
      } else {
        setRenderTooltip(true)
        setShowTooltip(true)
      }
    } else {
      if (hideDelay !== 0) {
        renderTimeout.current = window.setTimeout(() => {
          setShowTooltip(false)
          showTimeout.current = window.setTimeout(() => {
            setRenderTooltip(false)
          }, hideAnimationTime)
        }, hideDelay)
      } else {
        setRenderTooltip(false)
        setShowTooltip(false)
      }
    }

    return clear
  }, [active, showDelay, hideDelay, hideAnimationTime])

  return (
    <>
      {wrappedChildren}
      {renderTooltip && !disabled && (
        <BodyPortal>
          <TooltipContainer
            childrenRect={childrenRect}
            hideTooltip={hideTooltip}
            offsetX={offsetX}
            offsetY={offsetY}
            offsetFromEdge={offsetFromEdge}
            offsetFromTarget={offsetFromTarget}
            place={place}
            align={align}
            childrenRef={childrenRef}
            animated={animated}
            transitionTime={showTooltip ? showAnimationTime : hideAnimationTime}
            show={showTooltip}
          >
            {content}
          </TooltipContainer>
        </BodyPortal>
      )}
    </>
  )
}
