import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'
import { isNodeEnvProd } from 'utils/env'
import { getHostType } from 'utils/getHostType'

let isInitialized = false

export class ExceptionHandler {
  constructor() {
    this.initSentry()
  }

  private initSentry() {
    if (!isNodeEnvProd()) {
      return
    }

    if (!process.env.REACT_APP_SENTRY_DSN) {
      throw new Error('Undefined Sentry DSN')
    }

    if (!isInitialized) {
      Sentry.init({
        dsn: process.env.REACT_APP_SENTRY_DSN,
        integrations: [new BrowserTracing()],
        tracesSampleRate: 1.0, // todo: adjusting this value in production
        environment: getHostType(),
        release: process.env.REACT_APP_VERSION ?? 'unknown',
      })
      isInitialized = true
    }
  }

  handleMessage(message: string) {
    if (!isInitialized) {
      return
    }
    Sentry.captureMessage(message)
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  handleException(exception: any) {
    if (!isInitialized) {
      return
    }
    Sentry.captureException(exception)
  }
}
