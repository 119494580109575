import { initializeApp } from 'firebase/app'
import {
  signInWithCustomToken,
  signInWithCredential,
  GoogleAuthProvider,
  getAuth,
} from 'firebase/auth'
import { Api } from 'api/Api'

export class FirebaseAuth {
  private api: Api
  constructor(api: Api) {
    this.api = api

    initializeApp({
      apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
      authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
      projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
      storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
      messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
      appId: process.env.REACT_APP_FIREBASE_APP_ID,
      measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
    })
  }

  private get fbAuth() {
    return getAuth()
  }

  signInWithGoogle = (googleAccessToken: string) => {
    return signInWithCredential(
      this.fbAuth,
      GoogleAuthProvider.credential(null, googleAccessToken),
    ).then(({ user }) => {
      return user.getIdToken()
    })
  }

  signInWithOktaToken = async (oktaAccessToken: string): Promise<string> => {
    if (oktaAccessToken) {
      const data = await this.api.convertOktaToken(oktaAccessToken)
      if (data && data.firebaseToken) {
        const user = await signInWithCustomToken(this.fbAuth, data.firebaseToken)
        if (user) {
          return user.user.getIdToken()
        }
      }
    }

    throw new Error('auth.common.firebaseOktaError')
  }

  signOut = () => {
    this.fbAuth.signOut()
  }
}
