import React from 'react'
import styled from 'styled-components/macro'
import tw from 'twin.macro'
import { UserDto } from 'api/models'

interface UserpicProps extends Pick<UserDto, 'name' | 'iconColor' | 'image'> {
  className?: string
  size?: number
  disabledMarginRight?: boolean
}

export const Userpic = ({
  className,
  name,
  iconColor,
  image,
  size,
  disabledMarginRight,
}: UserpicProps) => {
  return (
    <View
      className={className}
      iconColor={iconColor}
      size={size}
      disabledMarginRight={disabledMarginRight}
      aria-hidden="true"
    >
      {image ? <img src={image.tq.url} alt="" /> : String(name)[0]}
    </View>
  )
}

const View = styled.div<{ iconColor?: string; size?: number; disabledMarginRight?: boolean }>`
  ${tw`text-mini font-medium`}
  position: relative;
  overflow: hidden;
  width: ${({ size }) => size || 28}px;
  height: ${({ size }) => size || 28}px;
  margin-right: ${({ disabledMarginRight }) => (disabledMarginRight ? 0 : 8)}px;
  user-select: none;
  text-align: center;
  color: ${({ theme }) => theme.colors.white};
  border-radius: 50%;
  background-color: ${({ iconColor, theme }) => iconColor || theme.colors.electro};
  line-height: ${({ size }) => size || 28}px;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`
