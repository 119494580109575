import { PsChartStore } from 'components/ps-chart/PsChartStore'
import { TypeLiveDemoSteps } from 'hooks/__generated__/contentful'
import { PinType } from 'components/ps-chart/stores/AnnotationsStore'

const resetChartState = (psChartStore: PsChartStore) => {
  psChartStore.setSelectedSlice(null)
  psChartStore.flagsStore.clearSelected()
  psChartStore.hState.setXStartAndXEnd(psChartStore.hState.xMin, psChartStore.hState.xMax)
  psChartStore.vState.setYStart(0)

  psChartStore.searchStore.setInputValue('')
  psChartStore.searchStore.resetSearchState()
  psChartStore.searchStore.setIsOpened(false)

  psChartStore.disableMeasurementMode()
  psChartStore.setMeasurementPoints(null)
  psChartStore.setIsMeasuredSlicesShow(false)

  psChartStore.uiState.setRightCollapsed(false)
  psChartStore.uiState.setLeftCollapsed(false)
  psChartStore.uiState.setSelectedTabIndex(0)

  psChartStore.videoPlayerStore.setVideoPointerTimeSeconds(0)
}

export const prepareChartState = (psChartStore: PsChartStore, liveDemoStep: TypeLiveDemoSteps) => {
  resetChartState(psChartStore)

  const {
    searchText,
    xStart,
    xEnd,
    yStart,
    selectedSliceId,
    selectedFlagId,
    selectedFlagType,
    selectedDetailsTab,
    videoTime,
    measurementY,
    measurementXEnd,
    measurementXStart,
  } = liveDemoStep.fields

  if (selectedSliceId !== undefined) {
    const slice = psChartStore.sliceById.get(selectedSliceId)
    if (slice) {
      psChartStore.setSelectedSlice(slice)
    }
  }

  if (selectedFlagId !== undefined) {
    if (selectedFlagType !== undefined) {
      const type = selectedFlagType === PinType.ACTION ? PinType.ACTION : PinType.REACTION
      psChartStore.annotationsStore.setSelectedId({ id: selectedFlagId, type })
    } else {
      psChartStore.selectFlag(selectedFlagId, undefined)
    }
  }

  if (searchText) {
    psChartStore.searchStore.setIsOpened(true)
    psChartStore.searchStore.setInputValue(searchText)
    psChartStore.searchStore.handleSearchChange(searchText, psChartStore.sliceById)
  }

  if (xStart !== undefined && xEnd !== undefined) {
    psChartStore.hState.setXStartAndXEnd(xStart, xEnd)
  }

  if (
    measurementXStart !== undefined &&
    measurementXEnd !== undefined &&
    measurementY !== undefined
  ) {
    const isMain = measurementY > psChartStore.vState.pinnedCanvasHeight
    psChartStore.enableMeasurementMode()
    psChartStore.setIsMeasuredSlicesShow(true)
    psChartStore.setMeasurementPoints({
      startTime: measurementXStart,
      endTime: measurementXEnd,
      y: isMain ? measurementY - psChartStore.vState.pinnedCanvasHeight : measurementY,
      isMain: isMain,
    })
  }

  if (yStart !== undefined) {
    psChartStore.vState.setYStart(yStart)
  }

  if (selectedDetailsTab !== undefined) {
    psChartStore.uiState.setSelectedTabIndex(selectedDetailsTab)
  }

  if (videoTime !== undefined) {
    psChartStore.videoPlayerStore.setVideoPointerTimeSeconds(videoTime)
  }
}
