import React, { useState, useEffect } from 'react'
import { ActionTooltip } from 'components/ActionTooltip'
import { observer } from 'mobx-react-lite'
import { Button, ButtonVariantEnum } from 'components/Button'

import { useCcoParams } from 'components/cco/useCcoParams'
import { useApi } from 'contexts/di-context'

interface ConnectionDirectionActionProps {
  traceData: object
  summaryData: object
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function createSnapshotFile(traceData: any, summaryData: any) {
  const fileName = `Snapshot ${Date.now()}.json`
  const jsonString = JSON.stringify({ traceData, summaryData })
  const blob = new Blob([jsonString], { type: 'application/json' })
  const file = new File([blob], fileName, { type: 'application/json' })
  return file
}

export const SaveSnapshotAction = observer(function SaveSnapshotAction({
  traceData,
  summaryData,
}: ConnectionDirectionActionProps) {
  const [uploadState, setUploadState] = useState<'ready' | 'uploading' | 'uploaded'>('ready')

  const api = useApi()
  const { projectUrlName, isDemo } = useCcoParams()
  const flowProjectLocalId = '1'

  useEffect(() => {
    let timeoutId: NodeJS.Timeout | undefined
    if (uploadState === 'uploaded') {
      timeoutId = setTimeout(() => setUploadState('ready'), 5000)
    }
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId)
      }
    }
  }, [uploadState])

  const uploadSnapshot = async () => {
    setUploadState('uploading')
    const file = createSnapshotFile(traceData, summaryData)

    const { uploadRequest } = await api.postTraceEntity(
      {
        projectIdOrUrlName: projectUrlName,
        flowProjectLocalId: flowProjectLocalId,
      },
      {
        traceName: file.name,
        sizeBytes: file.size,
        // @ts-expect-error Update types when BE is updated
        isProcessed: true,
      },
      {},
    )
    await api.uploadTraceToObjectStorage(file, uploadRequest)
    setUploadState('uploaded')
  }

  if (isDemo) {
    return null
  }

  return (
    <ActionTooltip place="top" tooltipId="saveSnapshot">
      <div className="flex items-center justify-center align-center px-3 h-[32px] bg-dark-dark3">
        <Button
          variant={ButtonVariantEnum.Text}
          onClick={() => uploadSnapshot()}
          disabled={uploadState !== 'ready'}
        >
          {uploadState === 'ready' && 'Snapshot'}
          {uploadState === 'uploading' && 'Uploading...'}
          {uploadState === 'uploaded' && 'Uploaded'}
        </Button>
      </div>
    </ActionTooltip>
  )
})
