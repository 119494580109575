import { useMemo } from 'react'
import { useOutletContext } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react-lite'

import { NextButton } from 'components/guide/NextButton'
import { Markdown } from 'components/guide/markdown/Markdown'
import { StepStatus } from 'components/guide/StepStatus'
import { AppBuildError } from 'components/guide/AppBuildError'
import { MarkdownConfig, MD_STYLES_DEFAULT } from 'components/guide/markdown/MarkdownConfig'
import { GetHelpBanner } from 'components/support/GetHelpBanner'

import { GuidePageOutletContext } from './GuidePageOutletContext'

export enum InstrumentStep {
  Build = 'buildStatus',
  Run = 'appRunStatus',
}

export const InstrumentAndBuild = observer(function InstrumentAndBuild() {
  const { t } = useTranslation()
  const { guideStore } = useOutletContext<GuidePageOutletContext>()

  const mdContent = useMemo(() => {
    if (!guideStore.mdContent) {
      return null
    }

    const config: MarkdownConfig = {
      headerLevelsToCollapse: [2],
      styles: MD_STYLES_DEFAULT,
    }

    const buildPropertiesData = guideStore.buildProperties || t('guidePage.buildPropsFetchError')
    const blob = new Blob([buildPropertiesData!], {
      type: 'text/plain;charset=UTF-8',
    })
    const url = URL.createObjectURL(blob)
    const pluginVersion = guideStore.pluginVersion || t('guidePage.pluginVersionFetchError')

    const buildPropertiesTemplateValues = new Map()
    buildPropertiesTemplateValues.set('readToken', buildPropertiesData)
    buildPropertiesTemplateValues.set('pluginVersion', pluginVersion)
    buildPropertiesTemplateValues.set('downloadFileLink', url)

    return (
      <Markdown
        markdown={guideStore.mdContent}
        templateValues={buildPropertiesTemplateValues}
        config={config}
      />
    )
  }, [guideStore.buildProperties, guideStore.pluginVersion, guideStore.mdContent, t])

  const androidBuildApp = useMemo(() => {
    if (!guideStore.androidBuild) {
      return null
    }

    const config: MarkdownConfig = {
      headerLevelsToCollapse: [2],
      styles: MD_STYLES_DEFAULT,
    }

    const templateValues = new Map()

    return (
      <details className="mt-6 mb-4">
        <summary className="relative text-header-small font-medium text-white break-all hover:cursor-pointer">
          <h2 className="inline">{t('guidePage.buildApp')}</h2>
          <StepStatus step={InstrumentStep.Build} />
        </summary>
        <Markdown
          markdown={guideStore.androidBuild}
          templateValues={templateValues}
          config={config}
        />
        <AppBuildError />
      </details>
    )
  }, [guideStore.androidBuild, t])

  return (
    <div className="pt-[40px]">
      {mdContent}
      {androidBuildApp}
      <details className="mt-6 mb-4">
        <summary className="relative text-header-small font-medium text-white break-all hover:cursor-pointer">
          <h2 className="inline">{t('guidePage.runApp')}</h2>
          <StepStatus step={InstrumentStep.Run} />
        </summary>
        <ul className="list-disc marker:text-dark-dark6 mt-[16px] text-[14px] text-gray-normal mb-[56px]">
          <li className="ml-[35px] mb-[16px]">{t('guidePage.runTheBuild')}</li>
        </ul>
        <GetHelpBanner />
      </details>
      <NextButton />
    </div>
  )
})
