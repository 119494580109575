/* eslint-disable no-console */
import React, { useState, useEffect } from 'react'

import { ArkanoidGame } from 'components/arkanoid/ArkanoidGame'
import { ArkanoidSplashScreen } from 'components/arkanoid/ArkanoidSplashScreen'
import { ArkanoidStartScreen } from 'components/arkanoid/ArkanoidStartScreen'
import { ArkanoidLeaderboardScreen } from 'components/arkanoid/ArkanoidLeaderboardScreen'

export const PATH_ARKANOID = '/smash'

export type Leaderboard = Array<{ name: string; score: number; isCurrent?: boolean }>

export const Arkanoid = () => {
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [leaderboard, setLeaderboard] = useState<Leaderboard>([])
  const [screenState, setScreenState] = useState<'splash' | 'start' | 'game' | 'leaderboard'>(
    'splash',
    // 'start',
    // 'game',
    // 'leaderboard',
  )

  useEffect(() => {
    fetch('/arkanoid/api/leaderboard')
      .then((response) => response.json())
      .then((data: { leaderboard: Leaderboard }) => {
        setLeaderboard(data.leaderboard.sort((a, b) => b.score - a.score))
      })
      .catch((error) => console.error('Error fetching data:', error))
  }, [])

  const handleSaveScore = async (score: number) => {
    try {
      setLeaderboard((oldLeaderboard) =>
        [...oldLeaderboard, { name, score, isCurrent: true }].sort((a, b) => b.score - a.score),
      )
      fetch('/arkanoid/api/leaderboard', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ name, score }),
      })
    } catch (error) {
      console.error('Error:', error)
    }
  }

  const handleGameOver = async () => {
    setScreenState('leaderboard')
  }

  const handleNewGame = () => {
    setScreenState('start')
    setName('')
    setEmail('')
  }

  const saveEmail = () => {
    try {
      fetch('/arkanoid/api/email', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ name, email }),
      })
    } catch (error) {
      console.error('Error:', error)
    }
  }

  switch (screenState) {
    case 'splash':
      return <ArkanoidSplashScreen onClick={() => handleNewGame()} />

    case 'start':
      return (
        <ArkanoidStartScreen
          onStart={() => {
            setScreenState('game')
            saveEmail()
          }}
          name={name}
          email={email}
          setName={setName}
          setEmail={setEmail}
        />
      )
    case 'game':
      return (
        <ArkanoidGame
          name={name}
          bestScore={leaderboard[0]?.score}
          onSaveScore={handleSaveScore}
          onGameOver={handleGameOver}
        />
      )
    case 'leaderboard':
      return <ArkanoidLeaderboardScreen onClick={() => handleNewGame()} leaderboard={leaderboard} />
  }
}
