import React, { useContext, useRef } from 'react'
import classNames from 'classnames'

import { BottomButton } from 'components/BottomButton'
import { LayoutContext } from 'contexts/layout-context'

export interface LayoutProps {
  children?: React.ReactNode
  className?: string
  footerComponent?: React.ReactNode
  headerComponent?: React.ReactNode
  pageConfig?: {
    withBottomButton?: boolean
    withoutStyledContent?: boolean
  }
}

export const Layout = ({
  children,
  className,
  pageConfig,
  headerComponent,
  footerComponent,
}: LayoutProps) => {
  const contentEl = useRef<HTMLDivElement>(null)

  const { withBottomButton, withoutStyledContent } = pageConfig || {}

  const { hintsIsOpened } = useContext(LayoutContext)

  return (
    <div className="flex h-full flex-col items-stretch alwaysShowScrollbar">
      {headerComponent}
      <div
        className={classNames('flex-1 overflow-y-auto scrolling-touch flex flex-col', className)}
        id="__CONTENT__"
      >
        {withoutStyledContent ? (
          children
        ) : (
          <main className="flex flex-1">
            <div className="overflow-hidden flex flex-1 flex-col" ref={contentEl}>
              {children}
            </div>
          </main>
        )}
        {withBottomButton && <BottomButton contentEl={contentEl} hintsOpened={hintsIsOpened} />}
      </div>
      {footerComponent}
    </div>
  )
}
