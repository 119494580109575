import React, { useEffect, useState } from 'react'
import classNames from 'classnames'
import { throttle } from 'throttle-debounce'

import { Icon } from 'components/Icon'

interface ButtonButtonProps {
  contentEl: React.RefObject<HTMLDivElement> | null
  hintsOpened: boolean
}

const BOTTOM_MARGIN = 65
const MIN_ITEM_HEIGHT = 234
const THROTTLE_DELAY = 200

export const BottomButton = ({ contentEl, hintsOpened }: ButtonButtonProps) => {
  const [showButton, setShowButton] = useState(true)

  const handleClick = () => {
    window.scrollTo({ top: window.scrollY + window.innerHeight / 2, behavior: 'smooth' })
    setShowButton(false)
  }

  useEffect(() => {
    const handleResize = throttle(THROTTLE_DELAY, () => {
      if (!showButton) {
        return null
      }
      if (window.scrollY > 100) {
        setShowButton(false)
      }

      if (contentEl && contentEl.current) {
        const contentRect = contentEl.current.getBoundingClientRect()
        setShowButton(contentRect.bottom - (window.innerHeight - BOTTOM_MARGIN) > MIN_ITEM_HEIGHT)
      }
    })

    handleResize()
    window.addEventListener('scroll', handleResize)
    window.addEventListener('resize', handleResize)
    window.addEventListener('orientationchange', handleResize)

    return () => {
      window.removeEventListener('scroll', handleResize)
      window.removeEventListener('resize', handleResize)
      window.removeEventListener('orientationchange', handleResize)
    }
  }, [contentEl, showButton])

  if (!showButton) {
    return null
  }

  return (
    <Icon
      as="button"
      className={classNames(
        'fixed bottom-[65px] left-1/2 w-[54px] h-[32px] shadow-base text-icon text-gray-normal rounded-[32px] bg-dark-dark4 -translate-x-1/2 hover:text-white transition-[opacity,margin-left] duration-250',
        hintsOpened ? 'ml-[140px]' : 'ml-[16px]',
      )}
      onClick={handleClick}
      icon="arrow-small-d"
    />
  )
}
