import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Button } from 'components/Button'
import { useResendLink } from 'components/free-trial/UseResendLink'
import { ProgressCircle } from 'components/common/util/ProgressCircle'

export const LinkExpired = ({ inviteToken }: { inviteToken: string }) => {
  const [reSent, setReSent] = useState(false)
  const { t } = useTranslation()

  const { isResendInactive, isLoading, handleResendLink } = useResendLink({ inviteToken })

  const handleClick = useCallback(() => {
    handleResendLink().then(() => {
      setReSent(true)
    })
  }, [handleResendLink])

  return (
    <div className="flex flex-col items-center">
      {reSent ? (
        <>
          <h3 className="text-normal mb-[16px] font-medium">
            {t('auth.freeTrial.linkSent.title')}
          </h3>
          <p className="text-normal text-gray-normal mb-[32px]">
            {t('auth.freeTrial.linkSent.subTitle')}
          </p>
          <Button
            className="min-w-[186px]"
            isSmall
            onClick={handleClick}
            disabled={isLoading || isResendInactive}
          >
            {(isLoading || isResendInactive) && <ProgressCircle className="inline mr-[8px]" />}
            {t('auth.freeTrial.linkExpired.resendButton')}
          </Button>
        </>
      ) : (
        <>
          <h3 className="text-normal text-state-bad mb-[32px] font-medium">
            {t('auth.freeTrial.linkExpired.title')}
          </h3>
          <Button
            className="min-w-[172px]"
            isSmall
            onClick={handleClick}
            disabled={isLoading || isResendInactive}
          >
            {(isLoading || isResendInactive) && <ProgressCircle className="inline mr-[8px]" />}
            {t('auth.freeTrial.linkExpired.button')}
          </Button>
        </>
      )}
    </div>
  )
}
