import { useTranslation } from 'react-i18next'
import { toast, ToastOptions } from 'react-hot-toast'
import { psLocalStorage } from 'utils/localStorage/PsLocalStorage'
import { Button, ButtonTextColorVariantEnum, ButtonVariantEnum } from 'components/Button'

interface ToastGotItProps {
  storageKey?: string
  toastId: string
  callback?: () => void
  gotItText?: string
}

export const ToastGotIt = ({ storageKey, toastId, callback, gotItText }: ToastGotItProps) => {
  const { t } = useTranslation()

  const handleClick = () => {
    if (callback) {
      callback()
    }
    toast.dismiss(toastId)
    if (storageKey) {
      psLocalStorage.addGotIt(storageKey)
    }
  }

  return (
    <Button
      className="group ml-3"
      variant={ButtonVariantEnum.Text}
      onClick={handleClick}
      textColorVariant={ButtonTextColorVariantEnum.Muted}
    >
      <span className="text-white group-hover:text-gray-normal transition-colors">
        {gotItText ? t(gotItText) : t('gotIt')}
      </span>
    </Button>
  )
}

export const showGotItToaster = (
  message: string,
  storageKey?: string,
  callback?: () => void,
  gotItText?: string,
  options?: ToastOptions,
) => {
  const toastId = options?.id !== undefined ? options.id : `${storageKey}_${Date.now()}`
  toast.success(message, {
    ...options,
    id: toastId,
    icon: (
      <ToastGotIt
        storageKey={storageKey}
        toastId={toastId}
        callback={callback}
        gotItText={gotItText}
      />
    ),
    duration: Infinity,
  })
}
