import { QueryClient, useMutation, useQuery, useQueryClient } from 'react-query'

import {
  ApiError,
  ComputationStatus,
  CreateProjectDto,
  FlowComputationReq,
  FlowReq,
  ProjectDto,
  ProjectReq,
  SliceSuggestionsReq,
  TeamReq,
  TraceProcessingState,
  TraceReq,
} from 'api/models'
import { useApi } from 'contexts/di-context'
import { ONE_DAY } from 'utils/oneDay'
import { Api } from 'api/Api'
import { UseMutationOptions } from 'react-query/types/react/types'
import { AxiosError } from 'axios'

export const queryKeys = {
  user: ['user'] as const,
  teams: ['teams'] as const,
  projects: ['projects'] as const,
  projectsSummary: ({ teamUrlName }: TeamReq) => ['projects-summary', teamUrlName] as const,
  buildProperties: (projectUrlName: string) => ['build-properties', projectUrlName] as const,
  androidPluginVersions: ['android-plugin-versions'] as const,
  instructionsState: (projectUrlName: string) => ['instructions-state', projectUrlName] as const,
  teamUsers: ({ teamUrlName }: TeamReq) => ['team-users', teamUrlName] as const,
  projectBuilds: ({ projectUrlName }: ProjectReq) => ['project-builds', projectUrlName] as const,
  projectUsers: ({ projectUrlName }: ProjectReq) => ['project-users', projectUrlName] as const,
  project: ({ projectUrlName }: ProjectReq) => ['project', projectUrlName] as const,
  flows: ({ projectUrlName }: ProjectReq) => ['flows', projectUrlName] as const,
  unassignedTraces: ({ projectUrlName }: ProjectReq) =>
    ['unassigned-traces', projectUrlName] as const,
  flow: ({ projectUrlName, flowProjectLocalId }: FlowReq) =>
    ['flow', projectUrlName, flowProjectLocalId] as const,
  flowTraces: ({ projectUrlName, flowProjectLocalId }: FlowReq) =>
    ['flow-traces', projectUrlName, flowProjectLocalId] as const,
  trace: ({ projectUrlName, traceProjectLocalId }: TraceReq) =>
    ['trace', projectUrlName, traceProjectLocalId] as const,
  flowAnnotations: ({ projectUrlName, flowProjectLocalId }: FlowReq) =>
    ['flow-annotations', projectUrlName, flowProjectLocalId] as const,
  flowVideo: ({ projectUrlName, flowProjectLocalId }: FlowReq) =>
    ['flow-video', projectUrlName, flowProjectLocalId] as const,
  sliceSuggestions: ({
    projectUrlName,
    flowProjectLocalId,
    traceProjectLocalId,
  }: SliceSuggestionsReq) =>
    ['slice-suggestions', projectUrlName, flowProjectLocalId, traceProjectLocalId] as const,
  flowComputation: ({ projectUrlName, flowProjectLocalId }: FlowComputationReq) =>
    ['flow-computation', projectUrlName, flowProjectLocalId] as const,
  globalLiveDemo: ['global-live-demo'] as const,
}

interface PutProjectMutationVariables {
  projectUrlName: string
  createProjectDto: CreateProjectDto
}

export const usePutProjectMutation = (
  options: UseMutationOptions<
    ProjectDto,
    AxiosError<ApiError>,
    PutProjectMutationVariables,
    unknown
  >,
) => {
  const api = useApi()
  const queryClient = useQueryClient()
  return useMutation({
    ...options,
    mutationFn: async ({ projectUrlName, createProjectDto }: PutProjectMutationVariables) => {
      const returnData = await api.putProject({ projectUrlName }, createProjectDto)
      if (options.mutationFn != null) {
        await options.mutationFn({ projectUrlName, createProjectDto })
      }
      return returnData
    },
    onSuccess: async (returnedProjectDto, variables, context) => {
      const teamUrlName = returnedProjectDto.team.urlName
      const projectUrlName = returnedProjectDto.urlName
      await Promise.allSettled([
        queryClient.invalidateQueries(queryKeys.projectsSummary({ teamUrlName }), {
          refetchInactive: true,
        }),
        queryClient.invalidateQueries(queryKeys.projectUsers({ projectUrlName }), {
          refetchInactive: true,
        }),
        queryClient.invalidateQueries(queryKeys.project({ projectUrlName }), {
          refetchInactive: true,
        }),
      ])
      if (options.onSuccess != null) {
        await options.onSuccess(returnedProjectDto, variables, context)
      }
    },
  })
}

interface PostProjectMutationVariables {
  teamUrlName: string
  createProjectDto: CreateProjectDto
}

export const usePostProjectMutation = (
  options: UseMutationOptions<
    ProjectDto,
    AxiosError<ApiError>,
    PostProjectMutationVariables,
    unknown
  >,
) => {
  const api = useApi()
  const queryClient = useQueryClient()
  return useMutation({
    ...options,
    mutationFn: async ({ teamUrlName, createProjectDto }: PostProjectMutationVariables) => {
      const returnData = await api.postProject({ teamUrlName }, createProjectDto)
      if (options.mutationFn != null) {
        await options.mutationFn({ teamUrlName, createProjectDto })
      }
      return returnData
    },
    onSuccess: async (returnedProjectDto, variables, context) => {
      const teamUrlName = returnedProjectDto.team.urlName
      await queryClient.invalidateQueries(queryKeys.projectsSummary({ teamUrlName }), {
        refetchInactive: true,
      })
      if (options.onSuccess != null) {
        await options.onSuccess(returnedProjectDto, variables, context)
      }
    },
  })
}

export const useUserQuery = (enabled = true) => {
  const api = useApi()
  return useQuery(queryKeys.user, () => api.getUser(), {
    enabled: enabled,
    retry: false,
    refetchOnMount: false,
    staleTime: ONE_DAY,
    cacheTime: ONE_DAY,
  })
}

export const fetchUser = (queryClient: QueryClient, api: Api, abortSignal?: AbortSignal) =>
  queryClient.fetchQuery(queryKeys.user, () => api.getUser(abortSignal), {
    retry: false,
    staleTime: ONE_DAY,
    cacheTime: ONE_DAY,
  })

export const useTeamsQuery = (enabled?: boolean, useErrorBoundary = true) => {
  const api = useApi()
  return useQuery(queryKeys.teams, () => api.getTeams(), {
    enabled,
    useErrorBoundary,
    retry: false,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  })
}

export const fetchTeams = (queryClient: QueryClient, api: Api, abortSignal?: AbortSignal) =>
  queryClient.fetchQuery(queryKeys.teams, () => api.getTeams(abortSignal), {
    retry: false,
    staleTime: ONE_DAY,
    cacheTime: ONE_DAY,
  })

export const useAllProjectsQuery = () => {
  const api = useApi()
  return useQuery(queryKeys.projects, () => api.getAllProjects(), {
    retry: false,
    refetchOnMount: false,
    staleTime: ONE_DAY,
    cacheTime: ONE_DAY,
  })
}

export const fetchAllProjects = (queryClient: QueryClient, api: Api) =>
  queryClient.fetchQuery(queryKeys.projects, () => api.getAllProjects(), {
    retry: false,
    staleTime: ONE_DAY,
    cacheTime: ONE_DAY,
  })

export const useProjectsSummaryQuery = ({ teamUrlName }: TeamReq) => {
  const api = useApi()
  return useQuery(
    queryKeys.projectsSummary({ teamUrlName }),
    () => api.getProjectsSummary(teamUrlName!),
    {
      enabled: teamUrlName != null,
      useErrorBoundary: true,
      retry: false,
      refetchOnMount: false,
      staleTime: ONE_DAY,
      cacheTime: ONE_DAY,
    },
  )
}

export const fetchProjectsSummary =
  (queryClient: QueryClient, api: Api, abortSignal?: AbortSignal) =>
  async (teamUrlName: string, shouldRefetch = false) => {
    if (shouldRefetch && !abortSignal?.aborted) {
      await queryClient.invalidateQueries(queryKeys.projectsSummary({ teamUrlName }), {
        refetchInactive: true,
      })
    }

    return queryClient.fetchQuery(
      queryKeys.projectsSummary({ teamUrlName }),
      () => api.getProjectsSummary(teamUrlName, abortSignal),
      {
        retry: false,
        staleTime: ONE_DAY,
        cacheTime: ONE_DAY,
      },
    )
  }

export const fetchPluginVersions = (queryClient: QueryClient, api: Api) =>
  queryClient.fetchQuery(queryKeys.androidPluginVersions, () => api.getAndroidPluginVersions(), {
    retry: false,
    staleTime: ONE_DAY,
    cacheTime: ONE_DAY,
  })

export const fetchBuildProperties =
  (queryClient: QueryClient, api: Api) => (projectUrlName: string) =>
    queryClient.fetchQuery(
      queryKeys.buildProperties(projectUrlName),
      () => api.postBuildProperties(projectUrlName),
      {
        retry: false,
        staleTime: ONE_DAY,
        cacheTime: ONE_DAY,
      },
    )

export const fetchAppBuildAndRunStatus =
  (queryClient: QueryClient, api: Api) =>
  async (projectUrlName: string, shouldRefetch = false) => {
    if (shouldRefetch) {
      await queryClient.refetchQueries(queryKeys.instructionsState(projectUrlName))
    }

    return queryClient.fetchQuery(
      queryKeys.instructionsState(projectUrlName),
      () => api.getAppBuildAndRunStatus(projectUrlName),
      {
        retry: false,
        staleTime: ONE_DAY,
        cacheTime: ONE_DAY,
      },
    )
  }

export const useTeamUsersQuery = ({ teamUrlName }: TeamReq) => {
  const api = useApi()
  return useQuery(queryKeys.teamUsers({ teamUrlName }), () => api.getTeamUsers({ teamUrlName }), {
    useErrorBoundary: true,
    retry: false,
  })
}

export const useProjectUsersQuery = ({ projectUrlName }: ProjectReq) => {
  const api = useApi()
  return useQuery(
    queryKeys.projectUsers({ projectUrlName }),
    () => api.getProjectUsers({ projectUrlName }),
    {
      useErrorBoundary: true,
      retry: false,
    },
  )
}

export const useProjectQuery = (
  { projectUrlName }: ProjectReq,
  enabled?: boolean,
  useErrorBoundary = true,
) => {
  const api = useApi()
  return useQuery(queryKeys.project({ projectUrlName }), () => api.getProject({ projectUrlName }), {
    enabled,
    useErrorBoundary,
    retry: false,
    refetchOnMount: false,
    staleTime: ONE_DAY,
    cacheTime: ONE_DAY,
  })
}

export const useFlowsQuery = ({ projectUrlName }: ProjectReq) => {
  const api = useApi()
  return useQuery(queryKeys.flows({ projectUrlName }), () => api.getFlows({ projectUrlName }), {
    enabled: !!projectUrlName,
    useErrorBoundary: true,
    retry: false,
    refetchOnMount: false,
    staleTime: ONE_DAY,
    cacheTime: ONE_DAY,
  })
}

export const useUnassignedTracesQuery = ({ projectUrlName }: ProjectReq) => {
  const api = useApi()
  return useQuery(
    queryKeys.unassignedTraces({ projectUrlName }),
    () => api.getUnassignedTraces({ projectUrlName }),
    {
      useErrorBoundary: true,
      retry: false,
    },
  )
}

export const useFlowQuery = ({ projectUrlName, flowProjectLocalId }: FlowReq) => {
  const api = useApi()
  return useQuery(
    queryKeys.flow({ projectUrlName, flowProjectLocalId }),
    () => api.getFlow({ projectUrlName, flowProjectLocalId }),
    {
      useErrorBoundary: true,
      retry: false,
      refetchOnMount: false,
      staleTime: ONE_DAY,
      cacheTime: ONE_DAY,
    },
  )
}

export const useFlowTracesQuery = ({ projectUrlName, flowProjectLocalId }: FlowReq) => {
  const api = useApi()
  return useQuery(
    queryKeys.flowTraces({ projectUrlName, flowProjectLocalId }),
    () => api.getFlowTraces({ projectUrlName, flowProjectLocalId }),
    {
      enabled: !!projectUrlName && !!flowProjectLocalId,
      useErrorBoundary: true,
      retry: false,
      refetchOnMount: false,
      staleTime: ONE_DAY,
      cacheTime: ONE_DAY,
      refetchInterval: (data) =>
        data?.find(
          (item) =>
            item.processingState === TraceProcessingState.IN_PROGRESS ||
            item.videoState === TraceProcessingState.IN_PROGRESS,
        )
          ? 5000
          : false,
    },
  )
}

export const fetchFlowTraces =
  (queryClient: QueryClient, api: Api) =>
  async (req: { projectUrlName: string; flowProjectLocalId: string }, shouldRefetch = false) => {
    if (shouldRefetch) {
      await queryClient.refetchQueries(queryKeys.flowTraces(req))
    }

    return queryClient.fetchQuery(queryKeys.flowTraces(req), () => api.getFlowTraces(req), {
      retry: false,
      staleTime: ONE_DAY,
      cacheTime: ONE_DAY,
    })
  }

export const useTraceQuery = ({ projectUrlName, traceProjectLocalId }: TraceReq) => {
  const api = useApi()
  return useQuery(
    queryKeys.trace({ projectUrlName, traceProjectLocalId }),
    () =>
      api.getSingleTrace({
        projectUrlName: projectUrlName!,
        traceProjectLocalId: traceProjectLocalId!,
      }),
    {
      useErrorBoundary: true,
      retry: false,
      staleTime: ONE_DAY,
      cacheTime: ONE_DAY,
    },
  )
}

export const useSliceSuggestions = (request: SliceSuggestionsReq) => {
  const api = useApi()
  return useQuery(queryKeys.sliceSuggestions(request), () => api.getSliceSuggestions(request), {
    useErrorBoundary: true,
    retry: false,
    refetchInterval: 30000,
  })
}

export const useFlowComputation = (request: FlowComputationReq) => {
  const api = useApi()
  return useQuery(queryKeys.flowComputation(request), () => api.getFlowComputation(request), {
    useErrorBoundary: true,
    retry: false,
    refetchInterval: (data) =>
      data && data.computationStatus === ComputationStatus.IN_PROGRESS ? 5000 : 0,
  })
}

export const useGlobalLiveDemoQuery = () => {
  const api = useApi()
  return useQuery(queryKeys.globalLiveDemo, () => api.getGlobalLiveDemo(), {
    useErrorBoundary: true,
    retry: false,
    staleTime: ONE_DAY,
    cacheTime: ONE_DAY,
  })
}

export const useProjectBuilds = (req: ProjectReq) => {
  const api = useApi()
  return useQuery(
    queryKeys.projectBuilds(req),
    () => api.getProjectBuilds(req.projectUrlName ?? ''),
    {
      useErrorBoundary: true,
      retry: false,
      staleTime: ONE_DAY,
      cacheTime: ONE_DAY,
    },
  )
}
