import { twMerge } from 'tailwind-merge'

import { LiteLayout, LiteLayoutContainer } from 'components/lite/LiteLayout'
import { LiteTargetHeader } from 'components/lite/LiteHeader'
import { LiteFooter } from 'components/lite/LiteFooter'
import { ContactForm } from 'components/target/ContactForm'

export const PATH_LITE_TARGET_4 = '/pmt'

export const LiteTarget4Page = () => {
  return (
    <LiteLayout header={<LiteTargetHeader />} footer={<LiteFooter hideDocs />}>
      <LiteLayoutContainer>
        <div className="md:grid md:grid-cols-3 gap-5">
          <div className="z-20 relative">
            <h1
              className={twMerge(
                'tracking-[-.05em] font-semibold leading-tighter',
                'md:mt-16 text-[42px]',
                '1280:text-[52px]',
              )}
            >
              Launch with your best impression
            </h1>
            <p className="my-3 text-gray-faded md:max-w-xs">
              PS Tool is the first user experience visualization tool that enables you to quickly
              identify and remedy unnecessary blips in user experience
            </p>
            <p className="my-3 text-gray-faded md:max-w-xs">
              Our product visualizes user experience synced with trace
            </p>
          </div>
          <div className="mb-[40px] relative col-span-2">
            <img
              src="https://images.ctfassets.net/sp0nb9fw4dnv/3IAEMLXK9V7IAOFLZlQLs7/3210760b96786f84c739d281bfcca687/pmt.png"
              width="1982"
              height="1278"
              className="rounded-2xl"
            />
          </div>
          <div></div>
        </div>
        <div className="md:grid md:grid-cols-3 gap-5">
          <div className="mb-16 relative">
            <h2 className="text-header-big font-semibold mb-4 z-20 relative">Be your user</h2>
            <p className="text-header-small">
              Visually walk a mile in your first users’ shoes, don’t fly blind into your launch
            </p>
          </div>
          <div className="mb-10 relative gap-3">
            <h2 className="text-header-big font-semibold mb-4">
              Translate User Experience to Code
            </h2>
            <p className="text-header-small">
              Record delays and have them automatically synced with the code causing them
            </p>
          </div>
          <div className="mb-10 relative gap-3">
            <h2 className="text-header-big font-semibold mb-4">Don’t grow your backlog </h2>
            <p className="text-header-small">
              Implement a fix in the time it normally takes to open a ticket
            </p>
          </div>
        </div>
        <div className="mt-16 mb-[160px] text-center">
          <ContactForm />
        </div>
      </LiteLayoutContainer>
    </LiteLayout>
  )
}
