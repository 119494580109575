import React, { useCallback, useEffect, useRef, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { twMerge } from 'tailwind-merge'
import { Link } from 'react-router-dom'

import { useDisablePinchZoom } from 'hooks/useDisablePinchZoom'
import { Icon } from 'components/Icon'
import { PsChartStore } from 'components/ps-chart/PsChartStore'
import { ActionTooltip } from 'components/ActionTooltip'
import { useHotKeys } from 'components/ps-chart/hooks/useHotKeys'
import { SearchStore } from 'components/ps-chart/actions-panel/SearchStore'
import { useCcoParams } from 'components/cco/useCcoParams'

import type { SessionData, SessionEndpointData } from 'components/cco/types'

const formatTitle = (title: string): string =>
  // eslint-disable-next-line no-irregular-whitespace
  title.replace(/([/])/g, `$1​`)

export const SessionsLeftBar = observer(function SessionsLeftBar({
  selectedSession,
  sessions,
  psChartStore,
}: {
  selectedSession: SessionData | null
  sessions: SessionData[]
  psChartStore: PsChartStore
}) {
  const { ccoSessionPath } = useCcoParams()
  const { uiState } = psChartStore

  const [showContent, setShowContent] = useState(true)

  const viewRef = useRef<HTMLDivElement>(null)
  useDisablePinchZoom(viewRef)

  const onCollapse = useCallback(() => {
    uiState.setLeftCollapsed(true)
    setShowContent(false)
  }, [uiState])

  const onExpand = useCallback(() => {
    uiState.setLeftCollapsed(false)
  }, [uiState])

  const zoomToEndpoint = useCallback(
    (endpoint: SessionEndpointData) => () => {
      const endpointSlice = SearchStore.manualSearch(
        endpoint.title,
        psChartStore.sliceById,
        endpoint.start,
      )
      if (endpointSlice !== undefined) {
        psChartStore.setSelectedSlice(endpointSlice)
      }
    },
    [psChartStore],
  )

  useHotKeys(
    ['BracketLeft'],
    () => {
      if (uiState.isLeftCollapsed) {
        onExpand()
      } else {
        onCollapse()
      }
    },
    psChartStore.isEnabledListeners,
  )

  const sessionRefs = useRef(new Map())

  useEffect(() => {
    if (selectedSession && sessionRefs.current.has(selectedSession.title)) {
      sessionRefs.current.get(selectedSession.title).scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      })
    }
  }, [selectedSession])

  return (
    <>
      {uiState.isLeftCollapsed ? (
        <div
          ref={viewRef}
          className="w-[32px] bg-dark-dark3 transition-all cursor-pointer flex justify-center"
          onClick={onExpand}
        >
          <ActionTooltip tooltipId="showLeftPanel" place="right">
            <div className="flex items-center">
              <Icon icon="arrow-drop-r" className="text-gray-normal text-icon" />
            </div>
          </ActionTooltip>
        </div>
      ) : (
        <div
          ref={viewRef}
          className="w-[320px] h-[100vh] bg-dark-dark3 transition-all flex flex-col relative"
          onTransitionEnd={() => setShowContent(true)}
        >
          {showContent && (
            <>
              {!uiState.isRightHidden && (
                <>
                  <ActionTooltip tooltipId="hideLeftPanel" place="bottom" offsetFromTarget={8}>
                    <div
                      className="w-[32px] h-[32px] absolute right-0 mt-[4px] mr-[4px] cursor-pointer"
                      onClick={onCollapse}
                    >
                      <Icon icon="arrow-drop-l" className="text-gray-normal text-icon" />
                    </div>
                  </ActionTooltip>
                  <div
                    className={twMerge(
                      'border-y-[1px] border-y-[#383838]',
                      'flex items-center',
                      'min-h-[40px] pl-[24px]',
                      'text-[12px] leading-[17px]',
                    )}
                  >
                    User Sessions
                  </div>
                </>
              )}
              <div className="flex-grow overflow-y-scroll outline-none webkit mb-[64px]">
                {sessions.map(({ title, endpoints }, sessionIndex) => {
                  return (
                    <div
                      key={`sessionIndex-${sessionIndex}`}
                      className={twMerge(
                        'mb-[24px]',
                        sessionIndex !== 0 && 'border-t-[1px] border-t-[#383838]',
                      )}
                      ref={(refElement) => sessionRefs.current.set(title, refElement)}
                    >
                      <div
                        className={twMerge(
                          'pt-[12px] pb-[11px] px-[16px] flex items-center',
                          title === selectedSession?.title && 'bg-dark-dark2',
                          title === selectedSession?.title && 'border-r-[1px] border-r-[#495BFB]',
                          'cursor-pointer',
                        )}
                      >
                        {title}
                      </div>
                      {endpoints.map((endpoint, endpointIndex) => {
                        return (
                          <div
                            key={`endpointIndex-${endpointIndex}`}
                            className={twMerge(
                              'pt-[12px] pb-[11px] pl-[24px] pr-[16px]',
                              'break-anywhere',
                              'hover:bg-dark-dark2 hover:border-r-[1px] hover:border-r-[#495BFB]',
                              'cursor-pointer',
                            )}
                            onClick={zoomToEndpoint(endpoint)}
                          >
                            {formatTitle(endpoint.title)}
                          </div>
                        )
                      })}
                    </div>
                  )
                })}
              </div>
              <div className="absolute bottom-0 w-[320px] h-[64px] bg-dark-dark3 flex justify-center items-center border-t-[1px] border-[#383838]">
                {selectedSession && (
                  <Link
                    to={ccoSessionPath(selectedSession.title)}
                    className={twMerge(
                      'w-[208px] h-[32px]',
                      'rounded-[4px] border-[1px] border-gray-service',
                      'flex justify-center items-center',
                      'text-[12px] leading-[17px]',
                    )}
                  >
                    Back to Dashboard
                  </Link>
                )}
              </div>
            </>
          )}
        </div>
      )}
    </>
  )
})
