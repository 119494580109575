import NProgress from 'nprogress'
import { useEffect } from 'react'

const originalTemplate = NProgress.settings.template

const overlayTemplate = `<div class='absolute z-progress inset-0 opacity-50 bg-black'>${originalTemplate}</div>`

export const setNProgressOverlay = () => {
  NProgress.configure({
    template: overlayTemplate,
  })
}

export const resetNProgress = () => {
  NProgress.configure({
    template: originalTemplate,
  })
}

export const useNProgressOverlay = () => {
  useEffect(() => {
    setNProgressOverlay()
    return () => {
      resetNProgress()
    }
  }, [])
}
