import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'
import { useNavigate } from 'react-router-dom'
import { PATH_LIVE_DEMO } from 'pages/LiveDemoPage'
import { FEATURE_FLAGS, useFeatureFlag } from 'utils/feature-flags'

export const LiveDemoButton = (props: React.ButtonHTMLAttributes<HTMLButtonElement>) => {
  const { children, className, ...otherProps } = props
  const { t } = useTranslation()
  const isLiveDemoEnabled = useFeatureFlag(FEATURE_FLAGS.LIVE_DEMO)
  const navigate = useNavigate()

  const handleButtonClick = useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.preventDefault()
      navigate(PATH_LIVE_DEMO)
    },
    [navigate],
  )

  if (!isLiveDemoEnabled) {
    return null
  }

  return (
    <button
      className={classNames(
        'h-[32px] flex justify-center items-center w-full mt-[24px] border-[1px] border-gray-strokeLight text-small font-medium transition-colors',
        !otherProps.disabled && 'hover:border-white',
        className,
      )}
      onClick={handleButtonClick}
      {...otherProps}
    >
      {children || t('auth.freeTrial.livedemo')}
    </button>
  )
}
