const PASSWORD_MIN_LENGTH = 8

export const isPasswordStrong = (value: string) => {
  const hasSpecialChar = /[~`¿¡!#$%\^&*€£@+÷=\-\[\]\\';,/{}\(\)|\\":<>\?\.\_]/g.test(value)
  const hasNumber = /\d/g.test(value)
  const hasCapital = () => {
    let i = 0
    if (value.length === 0) {
      return false
    }
    while (i < value.length) {
      const character = value.charAt(i)
      if (character == character.toLowerCase()) {
      } else if (character == character.toUpperCase()) {
        return true
      }
      i++
    }
    return false
  }
  const hasLowercase = () => {
    let i = 0
    if (value.length === 0) {
      return false
    }
    while (i < value.length) {
      const character = value.charAt(i)
      if (character == character.toUpperCase()) {
      } else if (character == character.toLowerCase()) {
        return true
      }
      i++
    }
    return false
  }
  return (
    value.length >= PASSWORD_MIN_LENGTH &&
    hasSpecialChar &&
    hasNumber &&
    hasCapital() &&
    hasLowercase()
  )
}

export const getNameValid = (value: string) => {
  const allowRule = /[^a-z\d\s\-\_]/gi.test(value)
  return !allowRule
}

export const getDomainValid = (value: string) => {
  const allowRule = /^\S+\.\S+$/gi.test(value)
  if (value.length > 0) {
    return allowRule
  }
  return true
}

export const isEmailValid = (testEmail: string): boolean => {
  const regex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return regex.test(String(testEmail).toLowerCase())
}
