import { ImgHTMLAttributes } from 'react'

export interface MdImageProps extends ImgHTMLAttributes<HTMLImageElement> {
  wrapperclassname?: string
}

export const MdImage = (props: MdImageProps) => {
  if (props?.wrapperclassname) {
    return (
      <div className={props.wrapperclassname}>
        <img {...props} />
      </div>
    )
  } else {
    return <img {...props} />
  }
}
