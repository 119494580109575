import { Dispatch, SetStateAction } from 'react'
import { useTranslation } from 'react-i18next'

import { VideoPlayerStore } from 'components/ps-chart/stores/VideoPlayerStore'
import { Modal, ModalSizeEnum } from 'components/Modal'
import { ButtonTextColorVariantEnum } from 'components/Button'
import { useDeleteVideo } from 'components/ps-chart/hooks/useDeleteVideo'
import { useToaster } from 'hooks/useToaster'

export const DeleteVideoModal = ({
  videoStore,
  isDeleteModalOpen,
  setIsDeleteModalOpen,
}: {
  videoStore: VideoPlayerStore
  isDeleteModalOpen: boolean
  setIsDeleteModalOpen: Dispatch<SetStateAction<boolean>>
}) => {
  const { t } = useTranslation()
  const toaster = useToaster()
  const deleteVideo = useDeleteVideo(videoStore, toaster)

  return (
    <Modal
      title={'Delete video'}
      isOpen={isDeleteModalOpen}
      size={ModalSizeEnum.NORMAL}
      onClose={() => setIsDeleteModalOpen(false)}
      actionButton={{
        children: t('delete'),
        textColorVariant: ButtonTextColorVariantEnum.Bad,
        onClick: deleteVideo,
      }}
      hiddenCloseButton={false}
    >
      <div className="flex min-h-[51px] items-center">
        <p className="text-small text-gray-normal">
          After deleting the video, it will be difficult to annotate.
        </p>
      </div>
    </Modal>
  )
}
