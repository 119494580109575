import { useRef } from 'react'
import { useDisablePinchZoom } from 'hooks/useDisablePinchZoom'
import type { ChartPageParams } from 'api/models'
import { observer } from 'mobx-react-lite'
import { ELEMENTS_IDS } from 'components/ps-chart/elementsIds'
import { VideoStatus } from 'components/video-timeline/VideoStatus'
import { VideoTimeline } from 'components/video-timeline/VideoTimeline'
import { GlobalTimeline } from 'components/global-timeline/GlobalTimeline'
import { LocalTimeline } from './local-timeline/LocalTimeline'
import type { PsChartStore } from './PsChartStore'

export interface ChartExtensionsProps {
  psChartStore: PsChartStore
  tracePageParams: ChartPageParams
  isVideoPreviewOnly?: boolean
}

export const ChartExtensions = ({
  psChartStore,
  tracePageParams,
  isVideoPreviewOnly = false,
}: ChartExtensionsProps) => {
  const extensionsRef = useRef<HTMLDivElement>(null)
  useDisablePinchZoom(extensionsRef)
  return (
    <div ref={extensionsRef} className="h-[136px] flex">
      <div className="flex-grow h-[136px] bg-dark-dark5">
        <div id={ELEMENTS_IDS.CHART_GLOBAL_TIMELINE}>
          <OverviewTimeline psChartStore={psChartStore} isVideoPreviewOnly={isVideoPreviewOnly} />
        </div>
        <div id={ELEMENTS_IDS.CHART_LOCAL_TIMELINE}>
          <LocalTimeline psChartStore={psChartStore} tracePageParams={tracePageParams} />
        </div>
      </div>
    </div>
  )
}

export interface OverviewTimelineProps {
  psChartStore: PsChartStore
  isVideoPreviewOnly: boolean
}

export const OverviewTimeline = observer(function OverviewTimeline(props: OverviewTimelineProps) {
  const { psChartStore, isVideoPreviewOnly } = props
  return isVideoPreviewOnly || psChartStore.isVideoPreviewInGlobalTimelineEnabled ? (
    <div className="relative">
      <VideoStatus psChartStore={psChartStore} />
      <VideoTimeline psChartStore={psChartStore} />
    </div>
  ) : (
    <GlobalTimeline psChartStore={psChartStore} />
  )
})
