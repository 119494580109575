import { generatePath, matchPath, useLocation, useNavigate, useParams } from 'react-router-dom'
import { useEffect } from 'react'
import { PATH_CHART } from 'pages/PsChartPage'
import { PATH_PROCESSES } from 'pages/PxAPage'
import { PATH_ANNOTATIONS } from 'pages/AnnotationsPage'
import { PsChartPageEnum, useAvailablePages } from 'hooks/useAvailablePages'
import { ChartPageParams } from 'api/models'

import { FEATURE_FLAGS, useFeatureFlag } from 'utils/feature-flags'

export const useNavigationRules = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const params = useParams<ChartPageParams>()
  const { availablePages, hasVideo, isDataLoaded, allAnnotationsConnected } = useAvailablePages()
  const annotationsEnabled = useFeatureFlag(FEATURE_FLAGS.ANNOTATIONS)

  useEffect(() => {
    if (availablePages && isDataLoaded) {
      const currentPsChartPage = getCurrentPsChartPage(location.pathname)

      let redirectPage: PsChartPageEnum | null = null

      if (!annotationsEnabled) {
        if (currentPsChartPage !== PsChartPageEnum.TRACE_VIEWER) {
          redirectPage = PsChartPageEnum.TRACE_VIEWER
        }
      } else if (currentPsChartPage) {
        if (!availablePages.has(currentPsChartPage)) {
          redirectPage = PsChartPageEnum.ANNOTATIONS
        }
      } else {
        // currentPsChartPage could be false if it is Router page
        redirectPage = PsChartPageEnum.ANNOTATIONS
        if (hasVideo) {
          if (
            availablePages.has(PsChartPageEnum.TRACE_VIEWER) &&
            availablePages.has(PsChartPageEnum.PROCESS_X_ANNOTATIONS) &&
            allAnnotationsConnected
          ) {
            redirectPage = PsChartPageEnum.TRACE_VIEWER
          } else if (availablePages.has(PsChartPageEnum.PROCESS_X_ANNOTATIONS)) {
            redirectPage = PsChartPageEnum.PROCESS_X_ANNOTATIONS
          }
        }
      }

      if (redirectPage) {
        navigate(generatePath(getPathByPsChartPage(redirectPage), params), { replace: true })
      }
    }
  }, [
    location,
    params,
    navigate,
    availablePages,
    hasVideo,
    isDataLoaded,
    allAnnotationsConnected,
    annotationsEnabled,
  ])
}

function getCurrentPsChartPage(pathName: string): PsChartPageEnum | null {
  if (Boolean(matchPath(PATH_CHART, pathName))) {
    return PsChartPageEnum.TRACE_VIEWER
  } else if (Boolean(matchPath(PATH_PROCESSES, pathName))) {
    return PsChartPageEnum.PROCESS_X_ANNOTATIONS
  } else if (Boolean(matchPath(PATH_ANNOTATIONS, pathName))) {
    return PsChartPageEnum.ANNOTATIONS
  }

  return null
}

function getPathByPsChartPage(page: PsChartPageEnum): string {
  switch (page) {
    case PsChartPageEnum.ANNOTATIONS:
      return PATH_ANNOTATIONS
    case PsChartPageEnum.PROCESS_X_ANNOTATIONS:
      return PATH_PROCESSES
    case PsChartPageEnum.TRACE_VIEWER: {
      return PATH_CHART
    }
  }
}
