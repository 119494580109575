import { AnnotationsStore } from 'components/ps-chart/stores/AnnotationsStore'
import { observer } from 'mobx-react-lite'
import { useArrowElements } from 'components/annotations/details-view/list/useArrows'
import { AnnotationListItem } from 'components/annotations/details-view/list/AnnotationListItem'
import { VideoPlayerState } from 'components/ps-chart/stores/VideoPlayerStore'

interface Props {
  mapped: boolean
  annotationsStore: AnnotationsStore
  videoPlayerState: VideoPlayerState
}

/**
 * @description displays mapped annotations list
 */
export const AnnotationList = observer(function AnnotationList({
  mapped,
  annotationsStore,
  videoPlayerState,
}: Props) {
  const pinIds = mapped
    ? annotationsStore.pinIdsSortedByTime
    : annotationsStore.pinIdsNotPlacedOnTimelineByCreatedDate

  // For each mapped annotation, create a ref for the arrow and attach it to the list item
  const { elRefs } = useArrowElements(annotationsStore.placedOnTimelineAnnotations)

  return (
    <div className="mt-[8px]">
      <ul>
        {pinIds.map((annotationIdAndType, index) => {
          const annotation = annotationsStore.getById(annotationIdAndType.id)!

          // visually groups annotation actions and reactions that are part of the same annotation when they are together
          // and adds margins to the top and bottom of the annotation when the action and reaction are not together
          const siblingIsAbove = pinIds[index + 1]?.id === annotationIdAndType.id
          const siblingIsBelow = pinIds[index - 1]?.id === annotationIdAndType.id
          const siblingIsNotAboveOrBelow = !siblingIsAbove && !siblingIsBelow
          const margins = siblingIsNotAboveOrBelow
            ? 'mt-[8px] mb-[8px]'
            : siblingIsBelow
            ? 'mb-[8px]'
            : ''

          return (
            <li
              className={`pb-[1px] list-none  ${margins} `}
              ref={elRefs[index || 0]}
              key={JSON.stringify(annotationIdAndType)}
            >
              <AnnotationListItem
                annotation={annotation}
                mapped={mapped}
                type={annotationIdAndType.type}
                elRefs={elRefs}
                annotationsStore={annotationsStore}
                videoPlayerState={videoPlayerState}
              />
            </li>
          )
        })}
      </ul>
    </div>
  )
})
