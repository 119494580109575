import React from 'react'
import styled from 'styled-components/macro'
import tw from 'twin.macro'

interface InputProps {
  name?: string
  value: string
  hint?: string
  required?: boolean
  isInvalid?: boolean
  placeholder?: string
  label?: string
  type?: string
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  autoFocus?: boolean
  maxLength?: number
}

/**
 * @description Input component used in the create and edit annotation forms
 */
export const Input = ({
  type = 'text',
  hint,
  label,
  isInvalid,
  autoFocus,
  maxLength,
  ...props
}: InputProps) => {
  const inputRef = React.useRef<HTMLInputElement>(null)

  React.useEffect(() => {
    if (autoFocus && inputRef.current) {
      inputRef.current.focus()
    }
  }, [autoFocus])

  return (
    <label>
      {label && <div className="mb-[3px] text-small tracking-wide">{label}</div>}
      <div className="relative">
        <StyledInput
          ref={inputRef}
          onKeyDown={(e) => e.stopPropagation()}
          type={type}
          {...props}
          maxLength={maxLength && maxLength > 0 ? maxLength : undefined}
        />
        <FocusLine isInvalid={!!isInvalid && !!props.required} />
      </div>
      {hint && <div className="mt-[4px] text-micro tracking-widest text-gray-normal">{hint}</div>}
    </label>
  )
}

const StyledInput = styled.input`
  ${tw`text-small tracking-wide placeholder:text-gray-normal`}
  overflow: hidden;
  width: 100%;
  height: 32px;
  padding: 0 16px;
  text-overflow: ellipsis;
  border: 0;
  border-radius: 2px;
  background: ${({ theme }) => theme.colors.dark.dark3};
  box-shadow: none;
  appearance: none;

  &::-webkit-inner-spin-button {
    appearance: none;
  }

  &:focus {
    border: 0;
    outline: none !important;
    box-shadow: 0 0 0 transparent;
  }
`

const FocusLine = styled.div<{ isInvalid: boolean }>`
  ${tw`transition`}
  position: absolute;
  right: 12px;
  bottom: 0;
  left: 12px;
  opacity: ${({ isInvalid }) => (isInvalid ? 1 : 0)};
  border-bottom: 1px solid
    ${({ theme, isInvalid }) => (isInvalid ? theme.colors.state.bad : theme.colors.electro)};

  ${StyledInput}:focus + & {
    opacity: 1;
  }
`
