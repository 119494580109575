import React from 'react'

interface MeasureValueProps {
  value: string | number
  text: string
}

export const MeasureValue = ({ value, text }: MeasureValueProps) => {
  return (
    <div>
      <span className="pr-1 font-mono">{value}</span>
      <span className="text-gray-normal">{text}</span>
    </div>
  )
}
