import { ConnectionType } from 'components/ps-chart/models/ConnectionType'
import { Rect } from 'components/ps-chart/models/helper-types'
import {
  ConnectionCoordinates,
  PathSteps,
} from 'components/ps-chart/connections-render/ConnectionCurves'
import { ConnectionDirection } from 'components/ps-chart/models/ConnectionDirection'

export const addConnectionCurve = (
  childRect: Rect,
  parentRect: Rect,
  connectionType: ConnectionType,
  curvePath: PathSteps,
  connectionCurveOuterWidth: number,
  connectionCurveInnerWidth: number,
  connectionDirection: ConnectionDirection = ConnectionDirection.BACKWARD,
) => {
  const connection: ConnectionCoordinates = []
  const minCurveWidth = connectionCurveOuterWidth
  const minCurveHeight = childRect.h

  const curveWidthShift = connectionCurveOuterWidth / 2
  const curveInnerWidthShift = connectionCurveInnerWidth / 2

  const parentRectRightBorder = parentRect.x + parentRect.w
  const childRectRightBorder = childRect.x + childRect.w
  const parentRectBottomBorder = parentRect.y + parentRect.h
  const parentRectBottomCurvePoint = parentRectBottomBorder - curveWidthShift
  const childRectBottomBorder = childRect.y + childRect.h
  const childRectBottomCurvePoint = childRectBottomBorder - curveWidthShift

  if (childRect.x - parentRectRightBorder >= minCurveWidth) {
    /**
     * The parent rect is on the LEFT side of the child one,
     *  and the distance between child's LEFT border and parent's RIGHT border is big enough.
     *
     * The measure looks like:
     *  PPP◄──►CCC
     *
     * The curve will look like:
     *  PPP──┐       |  PPP─────CCC  |       ┌──CCC
     *       └──CCC  |               |  PPP──┘
     *
     */
    const lineXCenter = parentRectRightBorder + (childRect.x - parentRectRightBorder) / 2

    connection.push([parentRectRightBorder, parentRectBottomCurvePoint])
    connection.push([lineXCenter, parentRectBottomCurvePoint])
    connection.push([lineXCenter, childRectBottomCurvePoint])
    connection.push([childRect.x, childRectBottomCurvePoint])
  } else if (parentRect.x - childRectRightBorder >= minCurveWidth) {
    /**
     * The parent rect is on the RIGHT side of the child,
     *  and the distance between parent's RIGHT border and child's LEFT border is big enough.
     *
     * The measure looks like:
     *  CCC◄──►PPP
     *
     * The curve will look like:
     *  CCC──┐       |  CCC─────PPP  |       ┌──PPP
     *       └──PPP  |               |  CCC──┘
     */
    const lineXCenter = childRectRightBorder + (parentRect.x - childRectRightBorder) / 2

    connection.push([parentRect.x, parentRectBottomCurvePoint])
    connection.push([lineXCenter, parentRectBottomCurvePoint])
    connection.push([lineXCenter, childRectBottomCurvePoint])
    connection.push([childRectRightBorder, childRectBottomCurvePoint])
  } else if (Math.abs(parentRect.y - childRect.y) > minCurveHeight) {
    /**
     * We will draw a connection line only for the cases
     *  when two slices have enough distance at least horizontally or vertically.
     *
     * Also, after the two horizontal distance checks before
     *  the relative horizontal positions in this branch can be only:
     *   PPP     |  PPP   |  PPPPPP  |   PPP  |     PPP
     *      CCC  |   CCC  |   CCC    |  CCC   |  CCC
     */

    if (childRect.x - parentRectRightBorder > 0) {
      /**
       * The parent rect is on the LEFT side of the child,
       *  and there is a non-zero distance between child's LEFT border and parent's RIGHT border.
       *
       * The measure looks like:
       *  PPP◄─►CCC
       *
       * The curve will look like:
       *  PPP      |    ┌─CCC
       *    │      |    │
       *    └─CCC  |  PPP
       */
      connection.push([childRect.x, childRectBottomCurvePoint])
      const parentRectRightXCurveStart = parentRectRightBorder - curveInnerWidthShift
      connection.push([parentRectRightXCurveStart, childRectBottomCurvePoint])

      if (parentRect.y < childRect.y) {
        connection.push([parentRectRightXCurveStart, parentRectBottomBorder])
      } else {
        connection.push([parentRectRightXCurveStart, parentRect.y])
      }
    } else if (parentRect.x - childRectRightBorder > 0) {
      /**
       * The parent rect is on the RIGHT side of the child,
       *  and there is a non-zero distance between parent's LEFT border and child's RIGHT border.
       *
       * The measure looks like:
       * CCC◄─►PPP
       *
       * The curve will look like:
       *  CCC      |    ┌─PPP
       *    │      |    │
       *    └─PPP  |  CCC
       */

      connection.push([parentRect.x, parentRectBottomCurvePoint])
      const childRectRightXCurveStart = childRectRightBorder - curveInnerWidthShift
      connection.push([childRectRightXCurveStart, parentRectBottomCurvePoint])

      if (parentRect.y < childRect.y) {
        connection.push([childRectRightXCurveStart, childRect.y])
      } else {
        connection.push([childRectRightXCurveStart, childRectBottomBorder])
      }
    } else {
      if (
        connectionType === ConnectionType.TREE &&
        connectionDirection === ConnectionDirection.BACKWARD
      ) {
        /**
         * The parent rect and the child are from same chart tree
         *
         * The curve will look like:
         *  PPP   |  PPP
         *   │    |   │
         *   CCC  |   C
         */
        const childMiddleBorderCurveStart =
          childRect.w <= minCurveWidth
            ? childRect.x + childRect.w / 2
            : childRect.x + curveWidthShift
        connection.push([childMiddleBorderCurveStart, childRect.y])
        connection.push([childMiddleBorderCurveStart, parentRectBottomBorder])
      } else if (
        connectionType === ConnectionType.ASYNC ||
        (connectionType === ConnectionType.TREE &&
          connectionDirection === ConnectionDirection.FORWARD)
      ) {
        /**
         * The ASYNC connection will be look like regular connection but in opposite direction
         * so parent now is a child and child become async parent (ASC)
         *
         * The curve will look like:
         *    ASC   |  ASC
         *     │    |   │
         *   CCC    |   C
         */
        const asyncParentRect = childRect
        const asyncChildRect = parentRect
        const middleBorderCurveStart =
          asyncChildRect.w <= minCurveWidth
            ? asyncChildRect.x + curveWidthShift
            : asyncChildRect.x + asyncChildRect.w - curveWidthShift
        connection.push([middleBorderCurveStart, asyncChildRect.y])
        connection.push([middleBorderCurveStart, asyncParentRect.y + asyncParentRect.h])
      } else {
        /**
         * The parent rect and the child one have a crossing horizontally
         *
         * The curve will look like:
         *  PPP   |  PPPPPP  |   PPP
         *   │    |   │      |   │
         *   CCC  |   CCC    |  CCC
         */
        const childLeftBorderCurveStart = childRect.x + curveInnerWidthShift
        const parentLeftBorderCurveStart = parentRect.x + curveInnerWidthShift
        const curveX = Math.max(childLeftBorderCurveStart, parentLeftBorderCurveStart)

        if (parentRect.y < childRect.y) {
          connection.push([curveX, childRect.y])
          connection.push([curveX, parentRectBottomBorder])
        } else {
          connection.push([curveX, parentRect.y])
          connection.push([curveX, childRectBottomBorder])
        }
      }
    }
  }
  if (connection.length) {
    curvePath.push(connection)
  }
}
