import React, { HTMLProps, ReactNode } from 'react'
import { MdHeader } from 'components/guide/markdown/blocks/MdHeader'
import { HeaderLevel, MdHeaderStyle } from 'components/guide/markdown/MarkdownConfig'

export interface MdCollapsibleHeaderProps extends HTMLProps<HTMLHeadingElement> {
  level: HeaderLevel
  headerStyles: MdHeaderStyle
  contentChildren: ReactNode
  open?: boolean
}

export const MdCollapsibleHeader = (props: MdCollapsibleHeaderProps) => {
  const { contentChildren, headerStyles, ...rest } = props
  return (
    <details className={headerStyles.marginClassName} open={props.open}>
      <summary className={headerStyles.otherClassName}>
        <MdHeader {...rest} className={`inline`} level={rest.level}>
          {rest.children}
        </MdHeader>
      </summary>
      <div className={headerStyles.marginClassName}>{contentChildren}</div>
    </details>
  )
}
