import Konva from 'konva'
import { VideoPointerSettings } from 'components/ps-chart/local-timeline/video-pointer/VideoPointerSettings'

export class KonvaVideoPointer {
  private readonly height: number
  private readonly settings: VideoPointerSettings

  private readonly pointerLine: Konva.Line
  private readonly timeRect: Konva.Rect
  private readonly timeText: Konva.Text
  readonly group = new Konva.Group({ draggable: true })

  constructor(height: number, settings: VideoPointerSettings) {
    this.height = height
    this.settings = settings

    this.pointerLine = new Konva.Line({
      points: [0, 0, 0, height],
      stroke: settings.lineColor,
      strokeWidth: settings.lineWidth,
    })

    this.timeRect = new Konva.Rect({
      x: 0.4,
      y: 0,
      width: this.settings.rectWidth,
      height: this.settings.rectHeight,
      fill: this.settings.rectColor,
    })

    this.timeText = new Konva.Text({
      x: this.settings.timeTextPadding,
      y: 0,
      width: this.settings.rectWidth,
      height: this.settings.rectHeight,
      fill: this.settings.titleColor,
      fontSize: this.settings.fontSize,
      fontFamily: this.settings.fontFamily,
      align: 'left',
      verticalAlign: 'middle',
    })

    this.initGroup()
  }

  private initGroup() {
    this.group.add(this.pointerLine, this.timeRect, this.timeText)
    this.group.on('mouseover', this.setCursorResize)
    this.group.on('mouseout', this.setCursorDefault)
    this.group.on('dragstart', this.setCursorResize)
    this.group.on('dragend', this.setCursorDefault)
    this.group.on('mousedown', this.setCursorResize)
    this.group.on('mouseup', this.setCursorDefault)
  }

  setCursorDefault = () => (document.body.style.cursor = this.settings.cursorDefault)

  private setCursorResize = () => (document.body.style.cursor = this.settings.cursorResize)

  get x(): number {
    return this.group.x()
  }

  set x(value: number) {
    this.group.x(value)
  }

  set y(value: number) {
    this.group.y(value)
  }

  remove() {
    this.group.remove()
  }

  removeAndUnsubscribe() {
    this.group.off('dragstart dragend mousedown mouseup mouseover mouseout')
    this.remove()
  }

  updateText(timeText: string) {
    this.timeText.text(timeText)
  }
}
