import { useMemo } from 'react'
import { observer } from 'mobx-react-lite'

import { GuideIoStore } from 'components/guide-io/GuideIoStore'
import { MarkdownConfig, MD_STYLES_DEFAULT_V2 } from 'components/guide/markdown/MarkdownConfig'
import { Markdown } from 'components/guide/markdown/Markdown'

interface GuideIoMdProps {
  guideIoStore: GuideIoStore
}

export const GuideIoMd = observer(function GuideIoMd({ guideIoStore }: GuideIoMdProps) {
  const config: MarkdownConfig = useMemo(
    () => ({
      headerLevelsToCollapse: [2],
      styles: MD_STYLES_DEFAULT_V2,
    }),
    [],
  )

  return (
    <Markdown
      markdown={guideIoStore.page.content}
      templateValues={guideIoStore.templateValuesMap}
      config={config}
    />
  )
})
