import { useTranslation } from 'react-i18next'

export const WrongTeamToken = () => {
  const { t } = useTranslation()

  return (
    <div className="flex flex-col items-center">
      <h3 className="text-normal mb-[16px] font-medium text-gray-normal">
        {t('auth.freeTrial.signUp.wrongShareToken.title')}
      </h3>
      <p className="text-normal text-gray-normal mb-[32px]">
        {t('auth.freeTrial.signUp.wrongShareToken.msg')}
      </p>
    </div>
  )
}
