import { PsChartStore } from 'components/ps-chart/PsChartStore'
import { observer } from 'mobx-react-lite'
import React from 'react'

export const ChartStateInfo = observer(function ChartStateInfo({
  psChartStore,
}: {
  psChartStore: PsChartStore
}) {
  const { selectedSlice } = psChartStore.traceAnalyzeStore
  const { selectedFlag } = psChartStore.flagsStore

  return (
    <div className="text-small p-3">
      <div>
        xStart: <span className="font-mono">{psChartStore.hState.xStart.toFixed(0)}</span>
      </div>
      <div>
        xEnd: <span className="font-mono">{psChartStore.hState.xEnd.toFixed(0)}</span>
      </div>
      <div>
        yStart: <span className="font-mono">{psChartStore.vState.yStart.toFixed(0)}</span>
      </div>
      <div>
        videoX:{' '}
        <span className="font-mono">
          {psChartStore.videoPlayerStore.traceVideoPointerTimeNanos.toFixed(0)}
        </span>
      </div>
      {selectedSlice && (
        <>
          <div>
            Selected slice start:{' '}
            <span className="font-mono">{selectedSlice.start.toFixed(0)}</span>
          </div>
          <div>
            Selected slice end: <span className="font-mono">{selectedSlice.end.toFixed(0)}</span>
          </div>
        </>
      )}
      {selectedFlag && (
        <>
          <div>
            Selected Flag ID: <span className="font-mono">{selectedFlag.id}</span>
          </div>
          <div>
            Selected Flag time: <span className="font-mono">{selectedFlag.time}</span>
          </div>
        </>
      )}
      {psChartStore.measurementPoints && (
        <>
          <div>MeasurementPointsInTime:</div>
          <div className="pl-4">
            <div>
              xStart: <span className="font-mono">{psChartStore.measurementPoints.startTime}</span>
            </div>
            <div>
              xEnd: <span className="font-mono">{psChartStore.measurementPoints.endTime}</span>
            </div>
            <div>
              y:{' '}
              <span className="font-mono">
                {psChartStore.measurementPoints.y +
                  (psChartStore.measurementPoints.isMain
                    ? psChartStore.vState.pinnedCanvasHeight
                    : 0)}
              </span>
            </div>
          </div>
        </>
      )}
    </div>
  )
})
