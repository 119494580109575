import { twMerge } from 'tailwind-merge'

import { LiteLayout, LiteLayoutContainer } from 'components/lite/LiteLayout'
import { LiteTargetHeader } from 'components/lite/LiteHeader'
import { LiteFooter } from 'components/lite/LiteFooter'
import { ContactForm } from 'components/target/ContactForm'

export const PATH_LITE_TARGET_2 = '/pm'

export const LiteTarget2Page = () => {
  return (
    <LiteLayout header={<LiteTargetHeader />} footer={<LiteFooter hideDocs />}>
      <LiteLayoutContainer>
        <div className="z-20 relative">
          <h1
            className={twMerge(
              'tracking-[-.05em] font-semibold leading-tighter',
              'md:my-16 text-[42px]',
              '1280:text-[52px]',
            )}
          >
            You will never hear “I can’t replicate the issue” again
          </h1>
        </div>
        <div className="md:grid md:grid-cols-4 gap-5">
          <div className="mb-16 relative col-span-3">
            <p className="text-header-small mb-[1em]">
              We have all heard it — you think the user flow is too slow, you bring it to the
              engineering team, they can’t seem to replicate the issue or don’t see where the root
              cause is, and a critical delay ends up in the backlog. And your users keep churning.
            </p>
            <p className="text-header-small mb-[1em]">
              Well, not any more: introducing the first user experience analytics/measurement
              product that works for <strong>both</strong> product managers and engineers.
            </p>
            <p className="text-header-small mb-[1em]">
              Now <strong>you</strong> can find the delay — your engineering team picking it up
              exactly where you left off is as simple as opening a link.
            </p>
          </div>
          <div className="mb-10 relative gap-3"></div>
        </div>
        <div className="md:grid md:grid-cols-2 gap-5">
          <div className="mb-16 relative">
            <h2 className="text-header-big font-semibold mb-4 z-20 relative">View For PM</h2>
            <div className="relative w-full pt-[50%]">
              <img
                src="https://images.ctfassets.net/sp0nb9fw4dnv/4hhpbL8oc83X9vtJFqMzS0/95a849dd292f1ff53ac0c8895e20851f/pm-2.png"
                width="1254"
                height="815"
                className="absolute top-0 h-[100%] w-auto"
              />
            </div>
            <ul className="list-none text-header-small mt-5">
              <li className="flex items-start gap-x-2">&mdash; See the delay/bug</li>
              <li className="flex items-start gap-x-2">&mdash; Measure it</li>
              <li className="flex items-start gap-x-2">
                &mdash; Send an automated report to eng team with exact details
              </li>
            </ul>
          </div>
          <div className="mb-16 relative">
            <h2 className="text-header-big font-semibold mb-4 z-20 relative">View For Engineer</h2>
            <div className="relative w-full pt-[50%] border">
              <img
                src="https://images.ctfassets.net/sp0nb9fw4dnv/0kFtYITiXkDkOwerj4yY4/4d48661a6f98a340228e0a959784934c/pm-1.png"
                width="1533"
                height="766"
                className="absolute top-0 left-0 object-cover h-full"
              />
            </div>
            <ul className="list-none text-header-small mt-5">
              <li className="flex items-start gap-x-2">&mdash; Replay exact experience</li>
              <li className="flex items-start gap-x-2">
                &mdash; Understand executed code functions that caused the issue
              </li>
              <li className="flex items-start gap-x-2">&mdash; Fix root cause</li>
            </ul>
          </div>
        </div>
        <div className="md:grid md:grid-cols-4 gap-5">
          <div className="mb-16 relative col-span-1"></div>
          <div className="mb-10 relative gap-3 col-span-2">
            <p className="text-header-normal-v2 italic relative mb-4">
              <div className="absolute top-0 left-[-30px] text-[60px]">“</div>
              We spent 6 months trying to improve Search by 3%. After first two weeks of using
              Product Science we were able to improve it by 23%!
            </p>
            <p className="text-header-small text-right">
              Happy PM from the app you use that we can’t name :)
            </p>
          </div>
        </div>
        <div className="mt-14 mb-[160px] text-center">
          <ContactForm />
        </div>
      </LiteLayoutContainer>
    </LiteLayout>
  )
}
