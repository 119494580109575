module.exports = {
  colors: {
    white: '#FFFFFF',
    black: '#000000',
    electro: '#495BFB',
    sky: '#343C85',
    night: '#202030',
    lime: '#D4FF5B',
    turquoise: '#157D6B',
    delay: '#483636',
    accentRed: '#C3465C',
    transparent: 'transparent',

    light: {
      one: '#3D3D3F',
    },

    dark: {
      default: '#292929',
      dark1: '#242424',
      dark2: '#1F1F1F',
      dark3: '#191919',
      dark4: '#141414',
      dark5: '#0F0F0F',
      dark6: '#333335',
    },

    gray: {
      normal: '#C1C1C1',
      service: '#B3B3B3',
      faded: '#616161',
      dark: '#434343',
      iconStatus: '#4D4D4D',
      strokeLight: '#363636',
      inputBG: '#383838',
      divider: '#49494A',
    },

    rollover: {
      dark: '#0000001A',
      light: '#FFFFFF0D',
    },

    state: {
      good: '#13988E',
      attention: '#D39978',
      bad: '#FA8281',
      neutral: '#A7A7A7',
    },

    globalTimeline: {
      white10: '#FFFFFF1A',
      white30: '#FFFFFF4D',
      dark86: '#171718D9',
      dark76: '#1A1A1Acc',
    },
  },
}
