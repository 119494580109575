import { ActionTooltip } from 'components/ActionTooltip'
import { FEATURE_FLAGS, useFeatureFlag } from 'utils/feature-flags'

interface Props {
  onClick: () => void
}

export const JumpToSource = ({ onClick }: Props) => {
  const isJumpToSourceEnabled = useFeatureFlag(FEATURE_FLAGS.JUMP_TO_SOURCE)

  if (!isJumpToSourceEnabled) {
    return null
  }

  return (
    <ActionTooltip text="Jump To Source" place="top">
      <button
        onClick={onClick}
        className="absolute right-[8px] top-[8px] text-[10px] font-black text-gray-normal hover:text-white hover:cursor-pointer"
      >
        &#123; ... &#125;
      </button>
    </ActionTooltip>
  )
}
