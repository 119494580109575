import { Code } from 'mdast'
import { Tab } from '@headlessui/react'
import { MarkdownProcessor } from 'components/guide/markdown/MarkdownProcessor'
import { GenIdFunction } from 'components/guide/markdown/Markdown'
import { TemplateValues } from 'components/guide/markdown/TemplateEngine'
import { MarkdownConfig } from 'components/guide/markdown/MarkdownConfig'
import { useCodeTabsLanguage } from 'components/guide/markdown/CodeTabsLanguageProvider'

import { observer } from 'mobx-react-lite'
import classNames from 'classnames'

export interface CodeTabsProps {
  codeContentArray: Code[]
  genId: GenIdFunction
  templateValues: TemplateValues
  config: MarkdownConfig
}

export const CodeTabs = observer(
  ({ codeContentArray, genId, templateValues, config }: CodeTabsProps) => {
    const selectedLanguage = useCodeTabsLanguage()
    const selectedIndex = codeContentArray.findIndex(
      ({ lang }) => lang === selectedLanguage.language,
    )
    const { tabListClassName, tabClassName, selectedTabClassName } = config.styles.code

    return (
      <div key={genId()}>
        <Tab.Group
          selectedIndex={selectedIndex}
          onChange={(index) => selectedLanguage.setLanguage(codeContentArray[index].lang)}
        >
          <Tab.List className={tabListClassName}>
            {codeContentArray.map((codeContent) => {
              return (
                <Tab key={genId()} className="capitalize">
                  {({ selected }) => (
                    <div className={classNames(selected ? selectedTabClassName : tabClassName)}>
                      {codeContent.lang}
                    </div>
                  )}
                </Tab>
              )
            })}
          </Tab.List>
          <Tab.Panels>
            {codeContentArray.map((codeContent) => {
              return (
                <Tab.Panel key={genId()}>
                  {MarkdownProcessor.processNode(genId, codeContent, templateValues, config)}
                </Tab.Panel>
              )
            })}
          </Tab.Panels>
        </Tab.Group>
      </div>
    )
  },
)
