import React, { useCallback, useEffect, useState } from 'react'
import NProgress from 'nprogress'
import { useTranslation } from 'react-i18next'
import { useToaster } from 'hooks/useToaster'
import * as yup from 'yup'
import { Form, useForm, ValidationSchema } from 'components/Form/Form'
import { FormInput } from 'components/Form/FormInput'
import { Button } from 'components/Button'
import { GoogleButton, GoogleButtonSuccessHandler } from 'components/auth/GoogleButton'
import { useAuth } from 'contexts/auth-context'
import { Input } from 'components/Input'
import { FreeTrialRequest } from 'api/models'
import { usePreDefinedCompanyName } from 'hooks/usePreDefinedCompanyName'
import { WrongTeamToken } from 'components/free-trial/WrongTeamToken'
import { OktaButton } from 'components/auth/OktaButton'
import { TermsAndConditions } from 'components/free-trial/TermsAndConditions'
import { CheckEmailBanner } from 'components/auth/CheckEmailBanner'
import { LiveDemoButton } from 'components/auth/LiveDemoButton'
import { Link } from 'react-router-dom'
import { PATH_SIGN_IN } from 'pages/auth/SignInPage'

interface RequestFreeTrialFormProps {
  onFirebaseSuccess: GoogleButtonSuccessHandler
  onOktaSuccess?: GoogleButtonSuccessHandler
  teamToken?: string
  withForm?: boolean
}

export const RequestFreeTrialForm = ({
  onFirebaseSuccess,
  onOktaSuccess,
  teamToken,
  withForm = true,
}: RequestFreeTrialFormProps) => {
  const [completed, setCompleted] = useState(false)
  const { preDefinedCompanyName, isTokenCheckFailed } = usePreDefinedCompanyName(teamToken)
  const { t } = useTranslation()
  const toaster = useToaster()
  const { requestFreeTrial } = useAuth()
  const formContext = useForm<SignUpFormValues>({ validationSchema: signUpSchema })

  const handleSubmit = useCallback(
    (values: SignUpFormValues) => {
      NProgress.start()
      const requestData: FreeTrialRequest = teamToken != null ? { ...values, teamToken } : values
      requestFreeTrial(requestData)
        .then(() => setCompleted(true))
        .catch((error) => toaster.error(error))
        .finally(() => NProgress.done())
    },
    [teamToken, requestFreeTrial, toaster],
  )

  useEffect(() => {
    if (preDefinedCompanyName == null) {
      return
    }
    formContext.setValue('companyName', preDefinedCompanyName)
  }, [formContext, preDefinedCompanyName])

  const handleSubmitInvalid = useCallback(() => {
    toaster.error('auth.freeTrial.signUp.errors.checkFields')
  }, [toaster])

  if (isTokenCheckFailed) {
    return <WrongTeamToken />
  }

  if (completed) {
    return <CheckEmailBanner />
  }

  return (
    <div className="max-w-[600px] mx-auto">
      {!withForm && (
        <div className="text-center mb-[48px]">
          <h2 className="text-header-small mb-[8px]">{t('auth.freeTrial.signUp.title')}</h2>
          <h3 className="text-normal text-gray-normal">{t('auth.freeTrial.signUp.subTitle')}</h3>
        </div>
      )}
      <div className="max-w-[320px] mx-auto">
        <Form<SignUpFormValues>
          formContext={formContext}
          onSubmit={handleSubmit}
          onSubmitInvalid={handleSubmitInvalid}
        >
          <FormInput<SignUpFormValues>
            name="name"
            autoComplete="given-name"
            containerClassName="mb-[24px]"
            className="text-small bg-gray-inputBG"
            placeholder={t('auth.freeTrial.signUp.firstName')}
          />
          <FormInput<SignUpFormValues>
            name="lastName"
            autoComplete="family-name"
            containerClassName="mb-[24px]"
            className="text-small bg-gray-inputBG"
            placeholder={t('auth.freeTrial.signUp.lastName')}
          />
          <FormInput<SignUpFormValues>
            name="email"
            autoComplete="email"
            containerClassName="mb-[24px]"
            className="text-small bg-gray-inputBG"
            placeholder={t('auth.freeTrial.signUp.email')}
          />
          {preDefinedCompanyName != null ? (
            <Input
              name="companyName"
              containerClassName="mb-[24px]"
              className="text-small bg-gray-inputBG"
              placeholder={t('auth.freeTrial.signUp.company')}
              value={preDefinedCompanyName}
              title={t('auth.freeTrial.signUp.preDefinedCompanyWarning')}
              disabled
            />
          ) : (
            <FormInput<SignUpFormValues>
              name="companyName"
              containerClassName="mb-[24px]"
              className="text-small bg-gray-inputBG"
              placeholder={t('auth.freeTrial.signUp.company')}
            />
          )}
          <TermsAndConditions className="text-small text-gray-normal" linkClassName="text-white" />
          <Button
            className="w-full"
            isSmall
            type="submit"
            disabled={formContext.formState.isValidating || !formContext.formState.isValid}
          >
            {t('auth.freeTrial.signUp.button')}
          </Button>
          <GoogleButton className="mt-[24px]" onSuccess={onFirebaseSuccess}>
            {t('auth.common.signUpWithGoogle')}
          </GoogleButton>
          {onOktaSuccess && (
            <OktaButton onSuccess={onOktaSuccess}>{t('auth.common.signUpWithOkta')}</OktaButton>
          )}
          <LiveDemoButton>Try without registration</LiveDemoButton>
          <div className="text-center pt-[20px]">
            <span className="text-small text-gray-normal">Have an account?</span>{' '}
            <Link className="text-small" to={PATH_SIGN_IN}>
              Sign in
            </Link>
          </div>
        </Form>
      </div>
    </div>
  )
}

interface SignUpFormValues {
  name: string
  lastName: string
  email: string
  companyName: string
}

export const signUpSchema = yup.object<ValidationSchema<SignUpFormValues>>({
  name: yup.string().required(),
  lastName: yup.string().required(),
  email: yup.string().email().required(),
  companyName: yup.string().required(),
})
