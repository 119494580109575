const ELLIPSIS = '…'

export class TextMeasurer {
  private static readonly precision = 2

  private readonly fontStyle: string

  private readonly measuresTable: { [key: string]: number } = {}

  private averageWidth = 0

  private ellipsisWidth = 0

  constructor(fontStyle: string) {
    this.fontStyle = fontStyle
    this.genMeasuresTable()
    document.fonts.ready.then(() => {
      this.genMeasuresTable()
    })
  }

  private static round(num: number) {
    const divider = Math.pow(10, TextMeasurer.precision)
    return Math.round(num * divider) / divider
  }

  private static getWidth(textMetrics: TextMetrics) {
    return TextMeasurer.round(textMetrics.width)
  }

  private genMeasuresTable() {
    const context = document.createElement('canvas').getContext('2d')
    if (context == null) {
      throw new Error('TextMeasurer failed to create the test canvas context')
    }
    context.font = this.fontStyle

    const ASCIIPrintableChartsStart = 32
    const ASCIIPrintableChartsEnd = 126

    let totalWidth = 0
    for (let i = ASCIIPrintableChartsStart; i <= ASCIIPrintableChartsEnd; i++) {
      const symbol = String.fromCharCode(i)
      const width = TextMeasurer.getWidth(context.measureText(symbol))
      this.measuresTable[symbol] = width
      totalWidth += width
    }
    this.averageWidth = TextMeasurer.round(
      totalWidth / (ASCIIPrintableChartsEnd - ASCIIPrintableChartsStart + 1),
    )

    this.ellipsisWidth = TextMeasurer.getWidth(context.measureText(ELLIPSIS))
  }

  private getSymbolWidth(symbol: string) {
    if (this.measuresTable[symbol] != null) {
      return this.measuresTable[symbol]
    } else {
      return this.averageWidth
    }
  }

  public getTextWidth(text: string) {
    let width = 0
    for (const symbol of text) {
      width += this.getSymbolWidth(symbol)
    }
    return TextMeasurer.round(width)
  }

  getEllipsizedText(text: string, maxWidth: number) {
    const textWidth = this.getTextWidth(text)
    if (textWidth <= maxWidth) {
      return text
    }

    if (maxWidth < this.ellipsisWidth) {
      return text[0]
    }

    let resultString = ''
    let availableWidth = maxWidth - this.ellipsisWidth
    for (const sym of text) {
      const symWidth = this.getSymbolWidth(sym)
      if (symWidth > availableWidth) {
        break
      }
      resultString += sym
      availableWidth -= symWidth
    }
    if (resultString.length === 0) {
      return text[0]
    }
    resultString += ELLIPSIS

    return resultString
  }
}
