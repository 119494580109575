import { OktaAuth } from '@okta/okta-auth-js'
import { makeObservable, runInAction, observable } from 'mobx'
import { IssuerDto } from 'api/__generated__/api-types'
import { psLocalStorage } from 'utils/localStorage/PsLocalStorage'

export class Okta {
  isReady: boolean
  issuer?: IssuerDto
  private authClient?: OktaAuth

  constructor() {
    this.setIssuer(psLocalStorage.getOktaIssuer())

    if (this.authClient) {
      const authState = this.authClient.authStateManager.getAuthState()
      this.isReady = !authState?.idToken
    } else {
      this.isReady = true
    }

    makeObservable(this, {
      isReady: observable,
    })
  }

  get accessToken() {
    if (this.authClient) {
      const tokens = this.authClient.tokenManager.getTokensSync()
      if (tokens.accessToken?.accessToken) {
        return tokens.accessToken?.accessToken
      }
    }
    return undefined
  }

  signIn = (): Promise<string | null> => {
    if (this.authClient) {
      return this.authClient.token
        .getWithPopup({
          scopes: ['offline_access', 'openid', 'profile'],
        })
        .then((result) => {
          const accessToken = result?.tokens?.accessToken?.accessToken
          if (this.authClient) {
            this.authClient.tokenManager.setTokens(result.tokens)
            if (accessToken) {
              return accessToken
            }
          }
          return null
        })
    }
    return Promise.resolve(null)
  }

  signOut = async () => {
    if (this.authClient) {
      this.authClient.revokeAccessToken()
      this.authClient.revokeRefreshToken()
      await this.authClient.closeSession()
      this.setIssuer(undefined)
    }
  }

  setIssuer = (issuer: IssuerDto | undefined) => {
    this.issuer = issuer
    psLocalStorage.setOktaIssuer(issuer)

    if (this.authClient) {
      this.authClient.stop()
    }

    if (this.issuer) {
      this.authClient = new OktaAuth({
        issuer: this.issuer.path,
        clientId: this.issuer.clientId,
        tokenManager: {
          autoRenew: true,
        },
        devMode: false,
      })
      this.authClient
        .start()
        .catch(() => {
          console.error('Unable to start Okta service')
        })
        .finally(() => {
          runInAction(() => {
            this.isReady = true
          })
        })
    } else if (this.authClient) {
      this.authClient = undefined
    }
  }

  dispose = () => {
    if (this.authClient) {
      this.authClient.stop()
    }
  }
}
