import { colors } from 'utils/styles/colors'
import { commonTimelineSettings } from './CommonTimelineSettings'

export class GhostIndicatorSettings {
  readonly color = colors.gray.normal
  readonly hoverColor = colors.globalTimeline.dark76
  readonly textColor = colors.dark.dark5
  readonly strokeWidth = 1
  readonly textRectWidth = 49
  readonly textRectHeight = 18
  readonly fontSize = commonTimelineSettings.fontSize
  readonly fontFamily = commonTimelineSettings.fontFamily
}

export const ghostIndicatorSettings = new GhostIndicatorSettings()
