import { twMerge } from 'tailwind-merge'

import { LiteLayout, LiteLayoutContainer } from 'components/lite/LiteLayout'
import { LiteTargetHeader } from 'components/lite/LiteHeader'
import { LiteFooter } from 'components/lite/LiteFooter'
import { ContactForm } from 'components/target/ContactForm'

export const PATH_LITE_TARGET_3 = '/pmtooling'

export const LiteTarget3Page = () => {
  return (
    <LiteLayout header={<LiteTargetHeader />} footer={<LiteFooter hideDocs />}>
      <LiteLayoutContainer>
        <div className="z-20 relative">
          <h1
            className={twMerge(
              'tracking-[-.05em] font-semibold leading-tighter',
              'md:my-16 text-[42px]',
              '1280:text-[52px]',
            )}
          >
            See app delays for new release before it gets to your users
            <br />
            Walk in your customer’s shoes for the first time
          </h1>
          <p className="text-header-small mb-10">
            Don’t wait for 3 month before you learn that your users churn because of performance
            issue
          </p>
        </div>
        <div className="md:grid md:grid-cols-2 gap-5">
          <div className="mb-16 relative">
            <h2 className="text-header-big font-semibold mb-4 z-20 relative">
              Feature release before PS
            </h2>
            <img
              src="https://images.ctfassets.net/sp0nb9fw4dnv/4x5wFj1bBCGohOeiocgDRP/24d5938fc2a0b7955951f74bb344afcd/pmtooling-left.png"
              width="512"
              height="253"
              className=""
            />
            <ol className="text-header-small mt-5 list-decimal ml-5 max-w-[600px]">
              <li>Wait for the feature rollout to 20% of users</li>
              <li>Experience user churn</li>
              <li>Wait for statistically impactful data</li>
              <li>Get a QA report</li>
              <li>Bring it to engineers</li>
              <li>Do back and forth with engineers since they don’t see same issues QA saw</li>
            </ol>
          </div>
          <div className="mb-16 relative">
            <h2 className="text-header-big font-semibold mb-4 z-20 relative">
              Feature release with PS
            </h2>
            <img
              src="https://images.ctfassets.net/sp0nb9fw4dnv/gvt0t5CddulZoVhPK03gQ/bb7477b0e3555b30f05c87d2c5e72efd/pmtooling-right.png"
              width="512"
              height="253"
              className="min-w-full"
            />
            <ol className="text-header-small mt-5 list-decimal ml-5 max-w-[600px]">
              <li>Use PS</li>
              <li>Send to your engineer before the rollout</li>
            </ol>
          </div>
        </div>
        <div className="mt-10 mb-[160px] text-center">
          <ContactForm />
        </div>
      </LiteLayoutContainer>
    </LiteLayout>
  )
}
