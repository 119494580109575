import { Outlet, ScrollRestoration } from 'react-router-dom'

import { StyledToaster } from 'components/StyledToaster'
import { RootBodyPortal } from 'components/BodyPortal'
import { ContentScrollRestoration } from 'components/ContentScrollRestoration'
import { DiContextProvider } from 'contexts/di-context'
import { AuthContextProvider } from 'contexts/auth-context'
import { CompositionRootImpl } from 'contexts/CompositionRoot'
import { LayoutContextProvider } from 'contexts/layout-context'
import { CustomerSupportModalContextProvider } from 'contexts/customer-support-modal-context'
import { PostHogProvider } from 'utils/posthog'
import { RootThemeProvider } from 'utils/styles'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'
import { IntercomProvider } from 'components/lite/IntercomProvider'

export const Root = () => {
  const { t } = useTranslation()
  const defaultTitle = !!process.env.REACT_APP_IS_LITE ? t('lite.meta.title') : t('appName')

  return (
    <DiContextProvider compositionRoot={new CompositionRootImpl()}>
      <AuthContextProvider>
        <PostHogProvider>
          <RootThemeProvider>
            <LayoutContextProvider>
              <CustomerSupportModalContextProvider>
                <IntercomProvider>
                  <Helmet>
                    <title>{defaultTitle}</title>
                  </Helmet>
                  <ScrollRestoration />
                  <ContentScrollRestoration />
                  <Outlet />
                  <StyledToaster />
                  <RootBodyPortal />
                </IntercomProvider>
              </CustomerSupportModalContextProvider>
            </LayoutContextProvider>
          </RootThemeProvider>
        </PostHogProvider>
      </AuthContextProvider>
    </DiContextProvider>
  )
}
