import React, { useCallback, useEffect, useState } from 'react'
import { useLocation, useSearchParams } from 'react-router-dom'
import { FieldErrors } from 'react-hook-form'
import * as yup from 'yup'
import { t } from 'i18next'
import NProgress from 'nprogress'

import { Form, getFormErrorsArrayFromObject, useForm, ValidationSchema } from 'components/Form/Form'
import { useToaster } from 'hooks/useToaster'
import { useAuth } from 'contexts/auth-context'
import { PATH_ROOT } from 'utils/links'
import { TokenDest } from 'api/__generated__/api-constants'
import { ModuleProgressCircle } from 'components/ps-chart/ModuleProgressCircle'
import { Input } from 'components/Input'
import { TermsAndConditions } from 'components/free-trial/TermsAndConditions'
import { Button } from 'components/Button'
import { GoogleButton } from 'components/auth/GoogleButton'
import { OktaButton } from 'components/auth/OktaButton'
import { useApi } from 'contexts/di-context'
import { CheckEmailBanner } from 'components/auth/CheckEmailBanner'

interface SignUpIoFormFields {
  email: string
}

const signUpIoSchema = yup.object<ValidationSchema<SignUpIoFormFields>>({
  email: yup
    .string()
    .email(t('auth.common.validation.email.pattern'))
    .required(t('auth.common.validation.email.required')),
})
export const SignUpIo = () => {
  const toaster = useToaster()
  const [searchParams] = useSearchParams()
  const formContext = useForm<SignUpIoFormFields>({ validationSchema: signUpIoSchema })
  const [isSubmitInProgress, setIsSubmitInProgress] = useState(false)
  const projectToken = searchParams.get('project-token')
  const [isTokenCheckFailed, setIsTokenCheckFailed] = useState(false)
  const [isTokenCheckInProgress, setIsTokenCheckInProgress] = useState(false)
  const { signInSSO, signUpCheckToken } = useAuth()
  const location = useLocation()
  const api = useApi()
  const [completed, setCompleted] = useState(false)

  const onFirebaseSuccess = useCallback(
    async (ssoToken: string) => {
      NProgress.start()
      setIsSubmitInProgress(true)
      try {
        await signInSSO({
          data: { ssoData: { ssoIdToken: ssoToken, projectToken } },
          redirectPath: location.state?.from?.pathname ?? PATH_ROOT,
        })
      } catch (error) {
        console.error(error)
        toaster.error(error)
      } finally {
        NProgress.done()
        setIsSubmitInProgress(false)
      }
    },
    [signInSSO, toaster, location, projectToken],
  )

  const onOktaSuccess = useCallback(
    async (ssoToken: string) => {
      NProgress.start()
      setIsSubmitInProgress(true)
      try {
        await signInSSO({
          data: {
            ssoData: { ssoIdToken: ssoToken, projectToken },
            tokenDest: TokenDest.BODY,
          },
          redirectPath: location.state?.from?.pathname ?? PATH_ROOT,
        })
      } catch (error) {
        console.error(error)
        toaster.error(error)
      } finally {
        NProgress.done()
        setIsSubmitInProgress(false)
      }
    },
    [signInSSO, toaster, location, projectToken],
  )

  const onSubmit = useCallback(
    async (values: SignUpIoFormFields) => {
      console.info('SignUpIoPage->formValues', values)
      if (projectToken == null) {
        throw new Error('The projectToken can not be null and has to be checked before')
      }
      setIsSubmitInProgress(true)
      try {
        await api.requestProjectAccess({ email: values.email, projectToken })
        setCompleted(true)
      } catch (error) {
        console.error(error)
        toaster.error(error)
      } finally {
        setIsSubmitInProgress(false)
      }
    },
    [api, toaster, projectToken],
  )

  const onSubmitInvalid = useCallback(
    (errors: FieldErrors<SignUpIoFormFields>) =>
      getFormErrorsArrayFromObject(errors).forEach((errorMsg) => {
        toaster.error(errorMsg)
      }),
    [toaster],
  )

  useEffect(() => {
    if (projectToken == null) {
      setIsTokenCheckFailed(true)
      return
    }
    setIsTokenCheckInProgress(true)
    signUpCheckToken({ projectToken, returnData: true })
      .catch((error) => {
        console.error(error)
        setIsTokenCheckFailed(true)
        setIsSubmitInProgress(false)
      })
      .finally(() => {
        setIsTokenCheckInProgress(false)
      })
  }, [toaster, signUpCheckToken, projectToken])

  if (isTokenCheckInProgress) {
    return <ModuleProgressCircle />
  }

  if (isTokenCheckFailed) {
    return (
      <div className="flex flex-col items-center">
        <h3 className="text-normal mb-[16px] font-medium text-gray-normal">
          {t('auth.freeTrial.signUp.wrongShareToken.title')}
        </h3>
        <p className="text-normal text-gray-normal mb-[32px]">
          {t('auth.freeTrial.signUp.wrongShareToken.msg')}
        </p>
      </div>
    )
  }

  if (completed) {
    return <CheckEmailBanner />
  }

  return (
    <>
      <div className="text-center mb-[48px]">
        <h2 className="text-header-small mb-[8px]">{t('auth.io.signUp.title')}</h2>
        <h3 className="text-normal text-gray-normal">{t('auth.io.signUp.subTitle')}</h3>
      </div>
      <div className="max-w-[320px] mx-auto">
        <Form formContext={formContext} onSubmit={onSubmit} onSubmitInvalid={onSubmitInvalid}>
          <Input
            {...formContext.register('email')}
            autoComplete="email"
            containerClassName="mb-[24px]"
            className="bg-gray-inputBG"
            placeholder={t('auth.common.email')}
            disabled={isSubmitInProgress}
          />
          <TermsAndConditions />
          <Button className="w-full" isSmall type="submit" disabled={isSubmitInProgress}>
            {t('auth.io.signUp.submitBtn')}
          </Button>
        </Form>
      </div>
      <div className="max-w-[320px] mx-auto">
        <GoogleButton
          className="mt-[24px]"
          onSuccess={onFirebaseSuccess}
          disabled={isSubmitInProgress}
        >
          {t('auth.common.signUpWithGoogle')}
        </GoogleButton>
        <OktaButton onSuccess={onOktaSuccess} disabled={isSubmitInProgress}>
          {t('auth.common.signUpWithOkta')}
        </OktaButton>
      </div>
    </>
  )
}
