import React from 'react'
import { generatePath, Navigate, Outlet, useLocation, useMatch } from 'react-router-dom'

import { useAuth } from 'contexts/auth-context'
import { PATH_SIGN_IN } from 'pages/auth/SignInPage'
import { useTeamsQuery } from 'hooks/useApiQuery'
import { EXPIRED_TRIAL_PATH } from 'pages/ExpiredTrialPage'
import { PATH_TEAM } from 'pages/TeamPage'
import { PATH_LITE_PROMO } from 'pages/lite/LitePromoPage'

export function RequireAuth() {
  const auth = useAuth()
  const location = useLocation()
  const { data: teams } = useTeamsQuery()
  const canRedirectToExpiredTrial = !useMatch(EXPIRED_TRIAL_PATH)

  const isLite = !!process.env.REACT_APP_IS_LITE

  if (!auth.isSignedIn) {
    return <Navigate to={isLite ? PATH_LITE_PROMO : PATH_SIGN_IN} state={{ from: location }} />
  }

  if (
    !canRedirectToExpiredTrial &&
    teams != null &&
    teams.length === 1 &&
    teams[0].freeTrial?.isExpired === false
  ) {
    return (
      <Navigate to={generatePath(PATH_TEAM, { teamUrlName: teams[0].urlName })} replace={true} />
    )
  }

  if (
    canRedirectToExpiredTrial &&
    teams != null &&
    teams.length === 1 &&
    teams[0].freeTrial?.isExpired === true
  ) {
    return <Navigate to={EXPIRED_TRIAL_PATH} state={{ teamUrlName: teams[0].urlName }} />
  }

  return <Outlet />
}
