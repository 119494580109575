import { makeAutoObservable } from 'mobx'
import { Thread } from 'components/ps-chart/models/Thread'
import { ThreadsState, TopBottomByThreadId } from 'components/ps-chart/stores/TraceAnalyzeStore'
import { HorizontalState } from 'components/ps-chart/models/HorizontalState'
import { VerticalState } from 'components/ps-chart/models/VerticalState'
import { Slice } from 'components/ps-chart/models/Slice'
import { findClosestLeftIndex, findClosestRightIndex } from 'components/ps-chart/utils/slice'
import { PsChartSettings } from 'components/ps-chart/models/settings'
import { cluster } from 'components/ps-chart/flame-chart/logic/clustering'
import { merger } from 'components/ps-chart/flame-chart/logic/merger'

export interface ChartRendererState {
  readonly processedMainThreads: Thread[]
  readonly processedPinnedThreads: Thread[]
}

export enum RendererType {
  CLUSTERED = 'cluster',
  MERGED = 'merged',
}

enum ThreadGroupType {
  MAIN = 'main',
  PINNED = 'pin',
}

/**
 * For better chart render performance we should remove threads "processing" (filter + merge/cluster)
 * from every frame rendering and do processing only when View is out of stored "processed" threads.
 */
interface CachedThreads {
  xStart: number
  xEnd: number
  renderType: RendererType
  zoom: number
  timeResolution: number
  threads: Thread[]
}

export class ChartRendererStore implements ChartRendererState {
  private threadsState: ThreadsState
  private hState: HorizontalState
  private vState: VerticalState
  private cacheStore = {} as { [key in ThreadGroupType]: CachedThreads }

  renderType: RendererType

  readonly chartSettings: PsChartSettings

  constructor(
    threadsState: ThreadsState,
    hState: HorizontalState,
    vState: VerticalState,
    chartSettings: PsChartSettings,
  ) {
    makeAutoObservable(this, {
      chartSettings: false,
    })
    this.threadsState = threadsState
    this.hState = hState
    this.vState = vState
    this.chartSettings = chartSettings
    this.renderType = RendererType.MERGED
  }

  private filteredVerticallyThreads(threadGroupType: ThreadGroupType): Thread[] {
    let threads: Thread[] = []
    let threadsTopBottomMap: TopBottomByThreadId = new Map<number, [number, number]>()
    let yStart = 0
    let yEnd = 0

    switch (threadGroupType) {
      case ThreadGroupType.PINNED:
        threads = this.threadsState.pinnedThreads
        threadsTopBottomMap = this.vState.pinnedThreadsTopBottomMap
        yStart = this.vState.yStartPinned
        yEnd = this.vState.yEndPinned
        break
      case ThreadGroupType.MAIN:
        threads = this.threadsState.mainThreads
        threadsTopBottomMap = this.vState.mainThreadsTopBottomMap
        yStart = this.vState.yStart
        yEnd = this.vState.yEndMain
        break
    }

    return ChartRendererStore.filterThreads(threads, threadsTopBottomMap, yStart, yEnd)
  }

  private filteredThreadsSlices(threads: Thread[]): Thread[] {
    const { timeResolution, xCacheStart, xCacheEnd } = this.hState

    return threads.map((thread) => {
      const filteredSlices = this.filterSlicesByLevel(
        thread.slicesByLevel,
        thread.maxLevel,
        timeResolution,
        xCacheStart,
        xCacheEnd,
      )
      return thread.cloneAndSubstituteSlicesByLevel(filteredSlices)
    })
  }

  private filterSlicesByLevel(
    slicesByLevel: ReadonlyMap<number, Slice[]>,
    maxLevel: number,
    resolution: number,
    xStart: number,
    xEnd: number,
  ): Map<number, Slice[]> {
    const filteredMap = new Map<number, Slice[]>()
    const mergerNs = Math.max(Math.round(resolution / 2) * 2, 1)
    for (let level = 0; level <= maxLevel; level++) {
      const slices = slicesByLevel.get(level) ?? []
      const leftIndex = findClosestLeftIndex(slices, xStart)
      const rightIndex = findClosestRightIndex(slices, xEnd)
      const filteredSlices = slices.slice(leftIndex, rightIndex + 1)

      if (this.renderType === RendererType.MERGED && mergerNs > 3) {
        const mergedSlices = merger(filteredSlices, mergerNs)
        filteredMap.set(level, mergedSlices)
        continue
      }

      if (this.renderType === RendererType.CLUSTERED) {
        const clusteredSlices = cluster(
          filteredSlices,
          this.minSliceSize,
          this.stickSize,
          this.chartSettings.clusterColor,
        )
        filteredMap.set(level, clusteredSlices)
        continue
      }

      filteredMap.set(level, filteredSlices)
    }

    return filteredMap
  }

  filteredAndProcessedThreads(
    threadGroupType: ThreadGroupType,
    renderType: RendererType,
  ): Thread[] {
    const threads = this.filteredVerticallyThreads(threadGroupType)
    const cacheStore = this.cacheStore[threadGroupType]

    if (cacheStore) {
      const resolutionCheckByRenderType = {
        [RendererType.CLUSTERED]: cacheStore.zoom === Math.floor(this.hState.zoom),
        [RendererType.MERGED]: cacheStore.timeResolution === this.hState.timeResolution,
      }

      const sameRenderType = cacheStore.renderType === this.renderType

      const timeRangeOutsideCachedRange =
        this.hState.xStart < cacheStore.xStart || this.hState.xEnd > cacheStore.xEnd

      const sameThreads =
        cacheStore.threads.length === threads.length &&
        cacheStore.threads.every(({ id }, index) => id === threads[index].id)

      if (sameRenderType && sameThreads && resolutionCheckByRenderType[renderType]) {
        if (!timeRangeOutsideCachedRange) {
          return cacheStore.threads
        }
      }
    }

    const processedThreads = this.filteredThreadsSlices(threads)

    this.cacheStore[threadGroupType] = {
      timeResolution: this.hState.timeResolution,
      zoom: Math.floor(this.hState.zoom),
      renderType: this.renderType,
      xStart: this.hState.xCacheStart,
      xEnd: this.hState.xCacheEnd,
      threads: processedThreads,
    }

    return processedThreads
  }

  get processedMainThreads(): Thread[] {
    return this.filteredAndProcessedThreads(ThreadGroupType.MAIN, this.renderType)
  }

  get processedPinnedThreads(): Thread[] {
    return this.filteredAndProcessedThreads(ThreadGroupType.PINNED, this.renderType)
  }

  get minSliceSize(): number {
    return this.chartSettings.clusteringMinSliceSizePx * this.hState.timePerPx
  }

  get stickSize(): number {
    return this.chartSettings.clusteringStickSizePx * this.hState.timePerPx
  }

  private static filterThreads(
    threads: Thread[],
    threadsTopBottomMap: TopBottomByThreadId,
    yStart: number,
    yEnd: number,
  ): Thread[] {
    return threads.filter((thread) => {
      const [threadTop, threadBottom] = threadsTopBottomMap.get(thread.id)!
      const isAboveCanvas = threadBottom < yStart
      const isBellowCanvas = threadTop > yEnd
      return !(isAboveCanvas || isBellowCanvas)
    })
  }

  switchRenderTypeMode() {
    switch (this.renderType) {
      case RendererType.CLUSTERED:
        this.renderType = RendererType.MERGED
        break
      case RendererType.MERGED:
        this.renderType = RendererType.CLUSTERED
        break
    }
  }
}
