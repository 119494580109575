import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useSearchParams } from 'react-router-dom'
import { useMutation } from 'react-query'
import { useAnalytics } from 'contexts/di-context'
import { usePostHog } from 'posthog-js/react'

import { useAuth } from 'contexts/auth-context'
import { SigninDto, TokenDest } from 'api/models'
import { PATH_LITE_TRACES } from 'pages/lite/LiteTracesPage'
import { LITE_EVENTS } from 'components/lite/config'

export const PATH_SIGN_IN = '/sign-in'

export const LoginViaEmail = () => {
  const { t } = useTranslation()
  const auth = useAuth()
  const location = useLocation()
  const [searchParams] = useSearchParams()

  const [email, setEmail] = useState(searchParams.get('email') || location.state?.email || '')
  const [password, setPassword] = useState('')

  const [error, setError] = useState(false)

  const analytics = useAnalytics()
  const posthog = usePostHog()

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value)
    setError(false)
  }

  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value)
    setError(false)
  }

  const signInMutation = useMutation(
    (variables: SigninDto) =>
      auth.signIn(variables, PATH_LITE_TRACES).then(() => {
        const { emailData } = variables
        analytics.track(LITE_EVENTS.LOG_IN_EMAIL)
        if (emailData) {
          posthog?.identify(emailData.email, {
            email: emailData.email,
            isLite: true,
          })
        }
      }),
    {
      onError: () => {
        setError(true)
      },
    },
  )

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault()
    signInMutation.mutate({
      emailData: { email, password },
      rememberMe: true,
      tokenDest: TokenDest.COOKIE,
    })
  }

  return (
    <div>
      <form className="mt-6" onSubmit={handleSubmit}>
        <input type="hidden" name="remember" defaultValue="true" />
        <div className="mb-5">
          <label htmlFor="email-address" className="sr-only">
            {t('auth.common.email')}
          </label>
          <input
            className="rounded-full w-full px-5"
            type="email"
            value={email}
            onChange={handleEmailChange}
            id="email-address"
            name="email"
            autoComplete="email"
            placeholder={t('auth.common.email')}
            required
          />
        </div>
        <div className="mb-5">
          <label htmlFor="password" className="sr-only">
            {t('auth.common.password')}
          </label>
          <input
            className="rounded-full w-full px-5"
            value={password}
            onChange={handlePasswordChange}
            id="password"
            name="password"
            type="password"
            autoComplete="current-password"
            placeholder={t('auth.common.password')}
            required
          />
        </div>
        {error && <p className="text-accentRed my-3">Email or password is incorrect.</p>}
        <button
          type="submit"
          className="rounded-full my-3 bg-black text-white px-6 py-2 focus-visible:outline-electro hover:opacity-80 disabled:opacity-20 focus-visible:border-white border border-black"
          disabled={signInMutation.isLoading}
        >
          <span className="text-header-small-v2">Log In</span>
        </button>
      </form>
    </div>
  )
}
