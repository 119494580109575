import classNames from 'classnames'

interface MenuItemButtonProps {
  title: string
  isSelected: boolean
  onClick: () => void
  isOptional?: boolean
}

export const MenuItemButton = ({
  title,
  isSelected,
  onClick,
  isOptional = false,
}: MenuItemButtonProps) => {
  return (
    <div>
      <button
        className={classNames(
          'pb-[5px] mb-[8px] cursor-pointer text-normal-v2 font-medium border-b-2 border-solid hover:text-white hover:border-white',
          isSelected ? 'text-white border-sky' : 'text-gray-normal border-transparent',
        )}
        onClick={onClick}
      >
        {title}
      </button>
      {isOptional && <span className="ml-[8px] text-normal-v2 text-gray-faded">Optional</span>}
    </div>
  )
}
