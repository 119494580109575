import { PsChartStore } from 'components/ps-chart/PsChartStore'
import { useLayoutEffect } from 'react'
import { LiveDemoController } from 'components/live-demo/LiveDemoController'
import { prepareChartState } from 'components/live-demo/prepareChartState'
import { reaction } from 'mobx'
import { TypeLiveDemoSteps } from 'hooks/__generated__/contentful'

export const useChartStatePreparation = (
  psChartStore: PsChartStore,
  liveDemoController: LiveDemoController,
  setReady: (isReady: boolean) => void,
) => {
  useLayoutEffect(() => {
    function prepare(enabled: boolean, step: TypeLiveDemoSteps) {
      if (enabled) {
        psChartStore.setIsEnabledListeners(false)
        psChartStore.searchStore.setAutoFocus(false)
        prepareChartState(psChartStore, step)
      } else {
        psChartStore.disableMeasurementMode()
        psChartStore.setIsMeasuredSlicesShow(false)
        psChartStore.searchStore.setAutoFocus(true)
        psChartStore.setIsEnabledListeners(true)
      }
    }

    const timeoutId = setTimeout(() => {
      // Without a delay could be an error, I don't know what is the best approach here, so this is a temporary solution
      prepare(liveDemoController.enabled, liveDemoController.currentStep.data)
      setReady(true)
    }, 200)

    const reactionDisposer = reaction(
      () => ({ enabled: liveDemoController.enabled, step: liveDemoController.currentStep.data }),
      (value, prevValue) => {
        if (value.enabled === prevValue.enabled && value.step === prevValue.step) {
          return
        }

        prepare(value.enabled, value.step)
      },
    )

    return () => {
      reactionDisposer()
      clearTimeout(timeoutId)
    }
  }, [psChartStore, liveDemoController, setReady])
}
