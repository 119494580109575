import React, { useCallback, useContext, useEffect, useRef, useState } from 'react'
import { autorun, reaction, runInAction } from 'mobx'
import { observer } from 'mobx-react-lite'

import { ModuleProgressCircle } from 'components/ps-chart/ModuleProgressCircle'
import { PsChartStore } from 'components/ps-chart/PsChartStore'
import { FlameChartRender } from 'components/ps-chart/flame-chart/FlameChartRender'
import { FlameTooltip } from 'components/ps-chart/flame-chart/FlameTooltip'
import { useToaster } from 'hooks/useToaster'
import { useAnalytics } from 'contexts/di-context'
import { moveToSlice } from 'components/ps-chart/utils/moveTo'
import { ThreadActions } from 'components/ps-chart/ThreadActions'
import { useTranslation } from 'react-i18next'
import { ELEMENTS_IDS } from 'components/ps-chart/elementsIds'
import { SliceRequestPreview } from 'components/ps-chart/flame-chart/SliceRequestPreview'
import { FEATURE_FLAGS, useFeatureFlag } from 'utils/feature-flags'
import { LayoutContext } from 'contexts/layout-context'

const Loading = observer(function FlameChartLoading({
  psChartStore,
}: {
  psChartStore: PsChartStore
}) {
  return psChartStore.isLoading ? <ModuleProgressCircle /> : null
})

export const FlameChart = ({ psChartStore }: { psChartStore: PsChartStore }) => {
  const mainCanvasRef = useRef<HTMLCanvasElement>(null)
  const pinnedCanvasRef = useRef<HTMLCanvasElement>(null)
  const chartWrapperRef = useRef<HTMLDivElement>(null)
  const mainTitleRef = useRef<HTMLSpanElement>(null)
  const favTitleRef = useRef<HTMLSpanElement>(null)
  const [flameChartRender, setFlameChart] = useState<FlameChartRender>()
  const [isListenersEnabled, setIsListenersEnabled] = useState(psChartStore.isEnabledListeners)
  const toaster = useToaster()
  const analytics = useAnalytics()
  const layout = useContext(LayoutContext)
  const { t } = useTranslation()

  useEffect(() =>
    reaction(
      () => psChartStore.isEnabledListeners,
      (isEnabledListeners) => {
        setIsListenersEnabled(isEnabledListeners)
      },
    ),
  )

  const setTitlesParams = useCallback(() => {
    if (favTitleRef.current) {
      if (psChartStore.traceAnalyzeStore.favIdSet.size === 0) {
        favTitleRef.current.style.display = 'none'
      }
      favTitleRef.current.style.display = 'block'
      favTitleRef.current.style.transform = `translateY(${psChartStore.vState.utilTotalHeight}px)`
    }
    if (mainTitleRef.current) {
      mainTitleRef.current.style.transform = `translateY(${psChartStore.vState.pinnedCanvasHeight}px)`
    }
  }, [psChartStore, favTitleRef, mainTitleRef])

  useEffect(() => {
    if (
      mainCanvasRef.current == null ||
      pinnedCanvasRef.current == null ||
      chartWrapperRef.current == null
    ) {
      throw new Error('Canvas has not been initialized')
    }
    const wrapperEl = chartWrapperRef.current
    const newFlameChartRender = new FlameChartRender(
      mainCanvasRef.current,
      pinnedCanvasRef.current,
      psChartStore,
      layout,
      toaster,
      analytics,
    )
    setFlameChart(newFlameChartRender)

    if (psChartStore.isLoaded) {
      newFlameChartRender.setCanvasSizeParams()
      setTitlesParams()
      newFlameChartRender.prepareDataAndRender()
    }

    if (isListenersEnabled) {
      newFlameChartRender.addEventListeners(wrapperEl)

      return () => newFlameChartRender.removeEventListeners(wrapperEl)
    }
  }, [psChartStore, toaster, analytics, layout, setTitlesParams, isListenersEnabled])

  useEffect(
    () =>
      autorun(
        () => {
          if (
            psChartStore.traceDataState.threads.length > 0 &&
            psChartStore.isEmpty &&
            flameChartRender != null
          ) {
            runInAction(() => {
              psChartStore.setIsLoading()
            })
            flameChartRender.setCanvasSizeParams()
            setTitlesParams()
            runInAction(() => {
              psChartStore.setIsLoaded()
            })
            flameChartRender.prepareDataAndRender()
          }
        },
        { name: 'autorun @ FlameChart & psChartStore.isEmpty -> prepareDataAndRender' },
      ),
    [flameChartRender, psChartStore, setTitlesParams],
  )

  /**
   * Canvas redraw reaction to changes in favorites thread list.
   * Thread's height be dynamic because of different viewing modes
   **/
  useEffect(
    () =>
      reaction(
        () => psChartStore.vState.pinnedCanvasHeight,
        () => {
          if (psChartStore.isLoaded) {
            flameChartRender?.setCanvasSizeParams()
            setTitlesParams()
            flameChartRender?.prepareDataAndRender()
          }
        },
        { name: 'reaction @ FlameChart & pinnedCanvasHeight -> prepareDataAndRender' },
      ),
    [flameChartRender, psChartStore, setTitlesParams],
  )

  useEffect(
    () =>
      reaction(
        () => [
          psChartStore.hoveredThreadId,

          psChartStore.hState.width,
          psChartStore.hState.xWidthTotal,
          psChartStore.hState.xMin,
          psChartStore.hState.xMax,
          psChartStore.hState.xStart,
          psChartStore.hState.xEnd,
          psChartStore.hState.xWidth,
          psChartStore.hState.zoom,
          psChartStore.hState.timePerPx,
          psChartStore.hState.xGridLines,

          psChartStore.vState.height,
          psChartStore.vState.yStart,
          psChartStore.vState.yEndMain,
          psChartStore.vState.yStartFav,
          psChartStore.vState.yEndFav,
          psChartStore.vState.yStartPinned,
          psChartStore.vState.yEndPinned,
          psChartStore.vState.mainTotalHeight,
          psChartStore.vState.mainCanvasHeight,
          psChartStore.vState.scrollableHeight,
          psChartStore.vState.mainScrollableHeight,
          psChartStore.vState.favTotalHeight,
          psChartStore.vState.favCanvasHeight,
          psChartStore.vState.pinnedTotalHeight,
          psChartStore.vState.pinnedCanvasHeight,
          psChartStore.vState.mainThreadsTopMap,
          psChartStore.vState.pinnedThreadsTopMap,
          psChartStore.vState.mainThreadsTopBottomMap,
          psChartStore.vState.pinnedThreadsTopBottomMap,

          psChartStore.isLoaded,
          psChartStore.traceAnalyzeStore.selectedCycleSliceVariantIndex,
          psChartStore.traceAnalyzeStore.detailsChainType,
          psChartStore.traceAnalyzeStore.allRegularLinksTree,
          psChartStore.traceAnalyzeStore.linksTree,
          psChartStore.traceAnalyzeStore.mainChainSlices,
          psChartStore.traceAnalyzeStore.chainExists,
          psChartStore.traceAnalyzeStore.favIdSet,
          psChartStore.traceAnalyzeStore.mainThreads,
          psChartStore.traceAnalyzeStore.pinnedThreads,
          psChartStore.traceAnalyzeStore.maxLevelByThreadId,
          psChartStore.traceAnalyzeStore.heightByThreadId,
          psChartStore.traceAnalyzeStore.showMaxLevelMode,
          psChartStore.traceAnalyzeStore.showOnlyActiveLevelsMode,
          psChartStore.isLinkModeActive,
          psChartStore.flagsStore.flags.slice(),
          psChartStore.flagsStore.selectedFlagId,
          psChartStore.flagsStore.hoverTime,
          psChartStore.flagsStore.showLabels,
          psChartStore.flagsStore.enabled,
          psChartStore.traceDataState.threads,
          psChartStore.traceDataState.utilityThreads,
          psChartStore.searchState.searchResults,
          psChartStore.videoPlayerStore.status,
          psChartStore.videoPlayerStore.traceVideoPointerTimeNanos,
          psChartStore.videoPlayerStore.hoverTime,

          psChartStore.isTransparentConnectionEnabled,
          psChartStore.rendererStore.renderType,

          psChartStore.shouldDimDisconnectedSlices,

          psChartStore.annotationsStore.annotationsWithConnectedSlices,

          psChartStore.measurementPoints,
          psChartStore.isMeasurementModeActive,
        ],
        () => psChartStore.isLoaded && flameChartRender?.prepareDataAndRender(),
        { name: 'reaction @ FlameChart & list -> prepareDataAndRender' },
      ),
    [flameChartRender, psChartStore],
  )

  useEffect(() => {
    if (chartWrapperRef.current != null) {
      const resizeObserver = new ResizeObserver(([chartWrapperEntry]) => {
        psChartStore.hState.setWidth(chartWrapperEntry.target.clientWidth)
        psChartStore.vState.setHeight(chartWrapperEntry.target.clientHeight)
        flameChartRender?.onResize()
      })
      resizeObserver.observe(chartWrapperRef.current)

      return () => {
        resizeObserver.disconnect()
      }
    }
  }, [flameChartRender, psChartStore])

  useEffect(() => {
    if (chartWrapperRef.current != null) {
      psChartStore.hState.setWidth(chartWrapperRef.current.clientWidth)
      psChartStore.vState.setHeight(chartWrapperRef.current.clientHeight)
    }
  }, [psChartStore])

  useEffect(
    () =>
      reaction(
        () => psChartStore.traceAnalyzeStore.selectedSlice,
        (selectedSlice) => {
          if (selectedSlice != null) {
            if (psChartStore.traceDataState.hasReactQueueThread) {
              if (
                psChartStore.traceDataState.reactJSThreadIds.has(selectedSlice.threadId) &&
                psChartStore.traceAnalyzeStore.nativeChainExists
              ) {
                psChartStore.traceAnalyzeStore.switchDetailsToNativeChain()
              } else {
                psChartStore.traceAnalyzeStore.switchDetailsToRegularChain()
              }
            }
          }
        },
        { name: 'reaction @ FlameChart & selectedSlice -> switchChainDetailsTab' },
      ),
    [psChartStore],
  )

  /**
   * If search occurs for a slice positioned out of active screen area - re-sorting will happen most likely with slight delay
   * that's why there is reaction which will happen after threads sorted
   */
  useEffect(
    () =>
      reaction(
        () => psChartStore.sortedThreads,
        () => {
          const { searchCurrentSliceId } = psChartStore.searchState
          if (searchCurrentSliceId != null) {
            moveToSlice(searchCurrentSliceId, psChartStore)
            psChartStore.searchState.setSearchCurrentSliceId(null)
          }
        },
        { name: 'reaction @ FlameChart & sortedThreads & searchState -> moveToSlice' },
      ),
    [psChartStore],
  )

  useEffect(
    () =>
      reaction(
        () => [
          psChartStore.traceAnalyzeStore.favIdSet,
          psChartStore.vState.utilTotalHeight,
          psChartStore.vState.pinnedCanvasHeight,
        ],
        () => {
          setTitlesParams()
        },
        {
          name: 'reaction @ ChartScroll & vState update -> style update',
        },
      ),
    [psChartStore, setTitlesParams],
  )

  // Rendering performance measuring
  const runPerfMeasure = useCallback(() => {
    flameChartRender?.prepareDataAndRender()
    if (psChartStore.renderingMeasuring.isStarted) {
      setTimeout(() => {
        runPerfMeasure()
      }, 1000)
    }
  }, [flameChartRender, psChartStore])

  // Rendering performance measuring
  useEffect(
    () =>
      reaction(
        () => psChartStore.renderingMeasuring.isStarted,
        (isStarted) => {
          if (flameChartRender != null && isStarted) {
            runPerfMeasure()
          }
        },
        { name: 'reaction @ FlameChart & renderingMeasuring -> runPerfMeasure' },
      ),
    [flameChartRender, psChartStore, runPerfMeasure],
  )

  const isNetworkRequestPreviewEnabled = useFeatureFlag(FEATURE_FLAGS.NETWORK_REQUEST_PREVIEW)

  return (
    <div
      className="flex-grow relative overflow-hidden"
      ref={chartWrapperRef}
      id={ELEMENTS_IDS.CHART_CANVAS_VIEW}
    >
      <canvas ref={pinnedCanvasRef} className="absolute" />
      <span ref={favTitleRef} className="absolute top-[4px] left-[16px] text-normal">
        {t('psChart.threads.favoritesTitle')}
      </span>
      <canvas ref={mainCanvasRef} className="absolute" />
      <span ref={mainTitleRef} className="absolute top-[4px] left-[16px] text-normal">
        {t('psChart.threads.title')}
      </span>
      <FlameTooltip psChartStore={psChartStore} />
      <Loading psChartStore={psChartStore} />
      <ThreadActions psChartStore={psChartStore} />
      {isNetworkRequestPreviewEnabled && <SliceRequestPreview psChartStore={psChartStore} />}
    </div>
  )
}
