import React, { useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { RaMvpStore } from 'components/regression-analysis-mvp/RaMvpStore'
import { observer } from 'mobx-react-lite'
import { Link } from 'react-router-dom'
import { Button, ButtonVariantEnum } from 'components/Button'
import { UploadBuildModal } from 'components/flows/ra/builds/UploadBuildModal'
import { BuildGrid } from 'components/flows/ra/builds/BuildGrid'

export interface BuildsTabProps {
  raStore: RaMvpStore
}

export const BuildsTab = observer(function BuildsTab(props: BuildsTabProps) {
  const { raStore } = props
  const { t } = useTranslation()

  const [showUploadBuildModal, setShowUploadBuildModal] = useState(false)

  return (
    <div className="overflow-x-auto">
      <div className="w-fit pl-flowLeftPadding pr-flowRightPadding">
        <div className="flex items-center mb-4 pt-1 text-small text-gray-normal">
          <Button
            className="mr-2"
            variant={ButtonVariantEnum.Outlined}
            isSmall
            onClick={() => setShowUploadBuildModal(true)}
          >
            {t('ra.flow.build.upload.label')}
          </Button>
          <div>
            <Trans
              i18nKey={'ra.flow.build.upload.seeDocs'}
              components={{
                a: (
                  <Link
                    className="text-electro hover:opacity-80"
                    target="_blank"
                    to="https://docs.productscience.app/regression-analysis/overview/"
                    rel="noreferrer"
                  />
                ),
              }}
            />
          </div>
        </div>

        {raStore.builds.length > 0 && <BuildGrid raStore={raStore} />}
      </div>
      <UploadBuildModal
        isOpen={showUploadBuildModal}
        onClose={() => setShowUploadBuildModal(false)}
        raStore={props.raStore}
      />
    </div>
  )
})
