import { ImageDataDto } from 'api/models'

export enum DropdownVariant {
  LISTBOX = 'LISTBOX',
  MENU = 'MENU',
  POPOVER = 'POPPVER',
}

export enum OptionsVariant {
  LIGHT = 'LIGHT',
  DARK = 'DARK',
}

export type SectionType = {
  name?: string | React.ReactNode
  options: OptionType[]
  withSelect?: boolean
}

export type OptionType = {
  name: string
  onClick?: (e?: React.MouseEvent) => void
  isSelect?: boolean
  withSelect?: boolean
  isDisabled?: boolean
  dataTid?: string
  image?: ImageDataDto | null
  customRender?: JSX.Element
}
