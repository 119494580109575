import React, { useCallback, useMemo } from 'react'
import { Popover, Transition } from '@headlessui/react'
import { useTranslation } from 'react-i18next'

import { Button, ButtonVariantEnum } from 'components/Button'
import { useUserQuery } from 'hooks/useApiQuery'
import { useAuth } from 'contexts/auth-context'
import { Userpic } from 'components/Userpic'
import { getTeamRoleName } from 'utils/getTeamRoleName'
import { getProjectRoleName } from 'utils/getProjectRoleName'
import { ProjectRoleApi, TeamRoleApi } from 'api/models'

export const UserMenu = () => {
  const { t } = useTranslation()
  const auth = useAuth()

  const { isSuccess: isUserSuccess, data: user } = useUserQuery()

  const isSuperAdmin = user?.roles.isSuperAdmin

  const userRoles = useMemo(() => {
    const sortRolesArray = [
      TeamRoleApi.ADMIN,
      TeamRoleApi.CONTRIBUTOR,
      ProjectRoleApi.ADMIN,
      ProjectRoleApi.CONTRIBUTOR,
    ] as Array<string | undefined>

    const teamsRoles = Array.from(new Set(Object.values(user?.roles.projects || {})))
      .filter(Boolean)
      .filter((item) => item !== (TeamRoleApi.NONE as string)) // Exclude TeamRoleApi.NONE from the filtered values
      .sort((a, b) => sortRolesArray.indexOf(a) - sortRolesArray.indexOf(b))
      .map((item) => getTeamRoleName(item, t))

    const projectsRoles = Array.from(new Set(Object.values(user?.roles.projects || {})))
      .filter(Boolean)
      .sort((a, b) => sortRolesArray.indexOf(a) - sortRolesArray.indexOf(b))
      .map((item) => getProjectRoleName(item, t))

    return [
      isSuperAdmin && t('components.userMenu.superAdmin'),
      ...teamsRoles,
      ...projectsRoles,
    ].filter(Boolean)
  }, [isSuperAdmin, t, user?.roles.projects])

  const handleSignOutClick = useCallback(() => {
    auth.signOut().catch((reason) => console.error('Sign out error: ', reason))
  }, [auth])

  return (
    <div className="min-w-[33px]">
      {isUserSuccess && user ? (
        <Popover className="relative">
          {({ open }) => (
            <>
              <Popover.Button
                className="flex items-center"
                data-testid="user-menu"
                aria-label={
                  open
                    ? t('components.userMenu.closeAriaLabel')
                    : t('components.userMenu.openAriaLabel')
                }
              >
                <Userpic {...user} className="!m-0" size={32} />
              </Popover.Button>
              <Transition
                as={React.Fragment}
                enter="transition ease-out duration-200"
                enterFrom="opacity-0 translate-y-1"
                enterTo="opacity-100 translate-y-0"
                leave="transition ease-in duration-150"
                leaveFrom="opacity-100 translate-y-0"
                leaveTo="opacity-0 translate-y-1"
              >
                <Popover.Panel className="absolute right-0 w-[280px] mt-[9px] p-[16px] pt-[20px] bg-dark-dark5 rounded-sm text-small tracking-wide z-50">
                  <div className="border-b border-b-white/[.15] pb-[15px] mb-[15px]">
                    <div>{user?.name}</div>
                    <div className="text-gray-normal" data-testid="user-roles">
                      {userRoles.length > 1 && (
                        <>
                          {t('components.userMenu.multipleRoles')}
                          <br />
                        </>
                      )}
                      {userRoles.join(', ')}
                    </div>
                  </div>
                  <div className="text-gray-normal mb-[15px] min-h-[56px] break-words">
                    {user?.email}
                  </div>
                  <Button
                    variant={ButtonVariantEnum.Text}
                    onClick={handleSignOutClick}
                    data-tid="sign-out"
                  >
                    {t('components.userMenu.signOut')}
                  </Button>
                </Popover.Panel>
              </Transition>
            </>
          )}
        </Popover>
      ) : null}
    </div>
  )
}
