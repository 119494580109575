export abstract class StorageItem<T> {
  readonly storageKey: string

  private localStorage?: Storage

  constructor(key: string) {
    this.storageKey = key
    // Workaround to make it work in jsdom
    if (typeof localStorage !== 'undefined') {
      this.localStorage = localStorage
    }
  }

  protected get localStorageValue(): string | null | undefined {
    return this.localStorage?.getItem(this.storageKey)
  }

  protected set localStorageValue(newValue: string | null | undefined) {
    if (!newValue) {
      this.remove()
    } else {
      this.localStorage?.setItem(this.storageKey, newValue)
    }
  }

  protected showParsingErrorAndResetData(error: unknown) {
    console.warn(
      `Error while parsing data from localStorage item with key "${this.storageKey}". Data was reset!`,
      error,
    )
    this.remove()
  }

  public remove() {
    this.localStorage?.removeItem(this.storageKey)
  }

  abstract get value(): T
  abstract set value(newValue: T)
}
