import type { Entry, EntryFields } from 'contentful'
import type { WithContentTypeLink } from './WithContentTypeLink'

export interface TypeMarkdownDocumentFields {
  title: EntryFields.Symbol
  markdown: EntryFields.Text
}

export type TypeMarkdownDocument = Entry<TypeMarkdownDocumentFields>

export function isTypeMarkdownDocument(entry: WithContentTypeLink): entry is TypeMarkdownDocument {
  return entry.sys.contentType.sys.id === 'markdownDocument'
}
