import { TypeLiveDemoHighlightingAreaFields } from 'hooks/__generated__/contentful/TypeLiveDemoHighlightingArea'

type ElementID = TypeLiveDemoHighlightingAreaFields['elementId']

export const ELEMENTS_IDS: { [Property in ElementID]: Property } = {
  CHART_CANVAS_VIEW: 'CHART_CANVAS_VIEW',
  CHART_VIDEO_VIEW: 'CHART_VIDEO_VIEW',
  CHART_VIDEO_PLAYER: 'CHART_VIDEO_PLAYER',
  CHART_LOCAL_TIMELINE: 'CHART_LOCAL_TIMELINE',
  CHART_GLOBAL_TIMELINE: 'CHART_GLOBAL_TIMELINE',
  CHART_DETAILS_VIEW: 'CHART_DETAILS_VIEW',
  CHART_SEARCH_INPUT: 'CHART_SEARCH_INPUT',
}
