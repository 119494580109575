import React from 'react'

interface SettingsCheckBoxProps {
  label: string
  value: boolean
  onChange: (value: boolean) => void
  disabled?: boolean
}

export const SettingsCheckBox = ({ label, value, onChange, disabled }: SettingsCheckBoxProps) => {
  return (
    <div className="flex items-center">
      <input
        className="
          bg-dark-dark1 disabled:opacity-40 rounded-sm checked:bg-electro
          focus:ring-offset-gray-normal
        "
        checked={value}
        onChange={(event) => onChange(event.target.checked)}
        type="checkbox"
        disabled={disabled}
      />
      <label className="text-small pl-2 text-gray-normal" onClick={() => onChange(!value)}>
        {label}
      </label>
    </div>
  )
}
