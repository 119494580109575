import { makeAutoObservable } from 'mobx'
import { ChartPageParams } from 'api/models'

export enum UploaderViewEnum {
  MAIN,
  UPLOADING,
  SUCCESS,
  ERROR,
}

interface UploadingFile {
  file: File
  finished: boolean
  traceParams?: ChartPageParams
}

export class UploadingController {
  files: UploadingFile[] = []
  error?: true

  constructor() {
    makeAutoObservable(this)
  }

  setFiles = (files: File[]) => {
    this.clear()
    this.files = files.map((file) => ({
      file,
      finished: false,
    }))
  }

  removeFile = (uploadingFile: UploadingFile) => {
    const index = this.files.findIndex((item) => item === uploadingFile)
    if (index !== -1) {
      this.files.splice(index, 1)
    }
  }

  setError = () => {
    this.error = true
  }

  completeCurrentUploading = (traceParams: ChartPageParams) => {
    const currentFile = this.uploadingFiles[0]
    if (currentFile) {
      currentFile.finished = true
      currentFile.traceParams = traceParams
    }
  }

  clear = () => {
    this.files = []
    this.error = undefined
  }

  get uploadedFiles(): UploadingFile[] {
    return this.files.filter((item) => item.finished)
  }

  get uploadingFiles(): UploadingFile[] {
    return this.files.filter((item) => !item.finished)
  }

  get view(): UploaderViewEnum {
    if (this.files.length > 0) {
      if (this.uploadedFiles.length > 0 && this.uploadingFiles.length === 0) {
        return UploaderViewEnum.SUCCESS
      } else if (this.error) {
        return UploaderViewEnum.ERROR
      }
      return UploaderViewEnum.UPLOADING
    }
    return UploaderViewEnum.MAIN
  }

  get isSingleFile() {
    return this.files.length === 1
  }
}
