import React, { useEffect, useMemo } from 'react'
import NProgress from 'nprogress'

import { Layout } from 'components/Layout'
import { ErrorComponent } from 'components/ErrorComponent'
import { BaseHeader } from 'components/header/BaseHeader'
import { PageTitle, TitleKey } from 'components/header/PageTitle'

interface ErrorPageAuthorizedProps {
  fullReload?: boolean
  headerTitleKey?: TitleKey
}

export const ErrorPageAuthorized = ({
  fullReload = false,
  headerTitleKey,
}: ErrorPageAuthorizedProps) => {
  useEffect(() => {
    NProgress.done()
  }, [])

  const header = useMemo(
    () => (
      <BaseHeader
        centerContent={headerTitleKey != null ? <PageTitle titleKey={headerTitleKey} /> : undefined}
      />
    ),
    [headerTitleKey],
  )

  return (
    <Layout
      headerComponent={header}
      pageConfig={{ withoutStyledContent: true }}
      className="justify-center"
    >
      <ErrorComponent fullReload={fullReload} />
    </Layout>
  )
}
