import React from 'react'
import classNames from 'classnames'

import { Button, ButtonProps } from 'components/Button'

interface EmptyProps {
  image?: string
  title?: string
  text?: string | React.ReactNode
  buttons?: (ButtonProps & {
    name: string
    onClick?: () => void
  })[]
  textClassName?: string
}

export const Empty = ({ image, title, text, buttons, textClassName }: EmptyProps) => {
  return (
    <div className="flex items-center flex-1 flex-col justify-center py-[50px] text-center text-normal tracking-wide">
      {image && <img src={image} alt="" className="w-[64px]" aria-hidden="true" />}
      <div className="font-medium">{title}</div>
      <div className={classNames('mt-[2px] text-gray-normal', textClassName)}>{text}</div>
      <div className="flex gap-[11px] mt-[32px]">
        {buttons?.map(({ name, ...buttonProps }, index) => (
          <Button {...buttonProps} key={String(index)}>
            {name}
          </Button>
        ))}
      </div>
    </div>
  )
}
