import {
  DashboardRunDtoStatisticsDto,
  DashboardRunV2Dto,
  DashboardRunV2DtoMeasurement,
  DashboardRunV2DtoSingleFlowNonInstrumentedResultDto,
} from 'api/models'
import { capitalizeFirstLetter } from 'utils/stringUtils'
import { isFinished, RegressionStatus } from 'components/flows/ra/runs/runUtils'

export class Run {
  readonly runDto: DashboardRunV2Dto

  readonly userFlowData: Array<UserFlowData> = []

  regressionStatus: RegressionStatus
  statusText: string
  dateCreated: string
  dateFinished: string

  constructor(runDto: DashboardRunV2Dto) {
    this.runDto = runDto
    this.calcUserFlowData()
    this.regressionStatus = this.calcRegressionStatus() // call only after calcUserFlowData
    this.statusText = capitalizeFirstLetter(this.runDto.status.toLowerCase())
    this.dateCreated = new Date(this.runDto.dateCreated).toLocaleString()
    this.dateFinished = this.runDto.dateFinished
      ? new Date(this.runDto.dateFinished).toLocaleString()
      : ''
  }

  private calcUserFlowData() {
    const curFlows = this.runDto.results.current.nonInstrumented?.flows
    const prevFlows = this.runDto.results.previous?.nonInstrumented?.flows
    const userFlowsIds: Set<string> = new Set()
    curFlows?.forEach((value) => userFlowsIds.add(value.raUserFlowId))
    prevFlows?.forEach((value) => userFlowsIds.add(value.raUserFlowId))
    for (const userFlowId of userFlowsIds.values()) {
      const userFlowData: UserFlowData = {
        flowId: userFlowId,
        current: this.calcData(curFlows, userFlowId),
        previous: this.calcData(prevFlows, userFlowId),
        regressionDetected: this.calcRegressionDetected(userFlowId),
      }
      this.userFlowData.push(userFlowData)
    }
  }

  private calcData(results: ResultDto[] | null | undefined, userFlowId: string): Data | undefined {
    if (!results) {
      return undefined
    }

    const result = results.find((value) => value.raUserFlowId == userFlowId)
    if (!result) {
      return undefined
    }

    return {
      measurements: result.runs ?? [],
      statistics: result.statistics ?? undefined,
    }
  }

  private calcRegressionDetected(userFlowId: string) {
    const comparison = this.runDto.results.comparison.flows?.find(
      (value) => (value.raUserFlowId = userFlowId),
    )
    if (!comparison) {
      return false
    }
    return comparison.regressionDetected
  }

  get isRunFailed(): boolean {
    return this.runDto.status === 'FAILED'
  }

  private calcRegressionStatus(): RegressionStatus {
    let result: RegressionStatus = RegressionStatus.NOT_DETECTED
    for (const data of this.userFlowData.values()) {
      if (data.regressionDetected) {
        if (data.current!.statistics!.meanValue > data.previous!.statistics!.meanValue) {
          return RegressionStatus.SLOWDOWN
        } else {
          result = RegressionStatus.SPEEDUP
        }
      }
    }
    return result
  }

  get hasInstrumentedResults(): boolean {
    const hasCurrent = (this.runDto.results.current.instrumented?.traceIds?.length ?? 0) > 0
    const hasPrev = (this.runDto.results.previous?.instrumented?.traceIds?.length ?? 0) > 0
    return hasCurrent || hasPrev
  }

  get isFinished(): boolean {
    return isFinished(this.runDto)
  }

  get deviceName(): string {
    return this.runDto.device?.name ?? ''
  }

  get id(): string {
    return this.runDto.id
  }

  get hasComparison(): boolean {
    return (this.runDto.results.comparison.flows?.length ?? 0) > 0
  }
}

type ResultDto = DashboardRunV2DtoSingleFlowNonInstrumentedResultDto

interface UserFlowData {
  flowId: string
  regressionDetected: boolean
  current?: Data
  previous?: Data
}

interface Data {
  measurements: DashboardRunV2DtoMeasurement[]
  statistics?: DashboardRunDtoStatisticsDto
}
