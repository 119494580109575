import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { twMerge } from 'tailwind-merge'

import { LiteModal } from 'components/lite/LiteModal'

import { HubSpotForm } from 'components/target/HubSpotForm'

type Props = {
  buttonLabel?: React.ReactNode
  buttonClassName?: string
}

export const ContactForm = ({ buttonLabel, buttonClassName }: Props) => {
  const { t } = useTranslation()
  const [isSignInModalOpened, setIsSignInModalOpened] = useState(false)

  return (
    <>
      <button
        type="button"
        className={twMerge(
          'rounded-full m-1 bg-[#653cd9] text-[30px] text-white px-6 py-2 focus:outline-black hover:opacity-80',
          'focus-visible:border-white focus-visible:outline-electro border',
          buttonClassName,
        )}
        onClick={() => setIsSignInModalOpened(true)}
      >
        <span className="whitespace-nowrap">{buttonLabel || t('lite.tryItOut')}</span>
      </button>
      {!isSignInModalOpened && (
        <div className="absolute opacity-0 w-0 h-0">
          <HubSpotForm portalId="21332124" formId="1492ab70-8a23-4385-8726-41d73104ace1" />
        </div>
      )}
      <LiteModal isOpen={isSignInModalOpened} onClose={() => setIsSignInModalOpened(false)}>
        <HubSpotForm portalId="21332124" formId="1492ab70-8a23-4385-8726-41d73104ace1" />
      </LiteModal>
    </>
  )
}
