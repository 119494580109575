import { TreeNode } from 'components/ps-chart/stores/connections-store/LinksTree/TreeNode'

export const findOrCreateTreeNode = (id: number, map: Map<number, TreeNode>): TreeNode => {
  if (!map.has(id)) {
    map.set(id, {
      sliceId: id,
      fromLinks: [],
      toLinks: [],
    } as TreeNode)
  }
  return map.get(id)!
}
