/**
 * Feature Flags that are available for use.
 * TODO: Make a codemod to sync with PostHog.
 */
export const FEATURE_FLAGS = {
  /** @deprecated For tests only */
  SAMPLE_FLAG: 'sample-flag-for-tests',
  SELF_SERVE: 'self-serve',
  ANNOTATIONS: 'annotations',
  REGRESSION_ANALYSIS: 'regression-analysis',
  SUITABLE_SLICES: 'suitable-slices',
  OKTA_AUTH_BUTTON: 'okta-auth-button',
  READ_ONLY_PROJECTS: 'read-only-projects',
  OPEN_IN_MVP: 'open-in-mvp',
  TRACE_UPLOAD: 'trace-upload',
  COPY_TRACE: 'copy-trace',
  HIDDEN_FLOW: 'hidden-flow',
  BE_CONNECTIONS: 'be-connections',
  ADD_SLICE_CONNECTIONS: 'add-slice-connections',
  TRACE_QUALITY_LABEL: 'trace-quality-label',
  SESSION_RECORDING: 'session-recording',
  LIVE_DEMO: 'live-demo',
  LIVE_DEMO_PER_FLOW: 'live-demo-per-flow',
  INTERACTIVE_ONBOARDING: 'interactive-onboarding',
  CYCLE_VARIANTS_FILTRATION: 'cycle-variants-filtration',
  NETWORK_REQUEST_PREVIEW: 'network-request-preview',
  PSTOOL_LITE: 'pstool-lite',
  SORT_TRACE_THREADS_BY_ID: 'force-thread-sort-by-id',
  JUMP_TO_SOURCE: 'jump-to-source',
  PROJECT_BUILDS: 'project-builds-page',
  CCO_TREE_VIEW_TABLE: 'cco-tree-view-mode',
} as const

export type FeatureFlagPayloads = {
  [FEATURE_FLAGS.SAMPLE_FLAG]: boolean
  [FEATURE_FLAGS.SELF_SERVE]: boolean
  [FEATURE_FLAGS.ANNOTATIONS]: boolean
  [FEATURE_FLAGS.REGRESSION_ANALYSIS]: boolean
  [FEATURE_FLAGS.SUITABLE_SLICES]: boolean
  [FEATURE_FLAGS.READ_ONLY_PROJECTS]: string[]
  [FEATURE_FLAGS.OPEN_IN_MVP]: boolean
  [FEATURE_FLAGS.COPY_TRACE]: boolean
  [FEATURE_FLAGS.OKTA_AUTH_BUTTON]: boolean
  [FEATURE_FLAGS.HIDDEN_FLOW]: boolean
  [FEATURE_FLAGS.TRACE_UPLOAD]: boolean
  [FEATURE_FLAGS.BE_CONNECTIONS]: boolean
  [FEATURE_FLAGS.ADD_SLICE_CONNECTIONS]: boolean
  [FEATURE_FLAGS.TRACE_QUALITY_LABEL]: boolean
  [FEATURE_FLAGS.SESSION_RECORDING]: boolean
  [FEATURE_FLAGS.LIVE_DEMO]: boolean
  [FEATURE_FLAGS.INTERACTIVE_ONBOARDING]: boolean
  [FEATURE_FLAGS.CYCLE_VARIANTS_FILTRATION]: boolean
  [FEATURE_FLAGS.LIVE_DEMO_PER_FLOW]: boolean
  [FEATURE_FLAGS.NETWORK_REQUEST_PREVIEW]: boolean
  [FEATURE_FLAGS.PSTOOL_LITE]: boolean
  [FEATURE_FLAGS.SORT_TRACE_THREADS_BY_ID]: boolean
  [FEATURE_FLAGS.JUMP_TO_SOURCE]: boolean
  [FEATURE_FLAGS.PROJECT_BUILDS]: boolean
  [FEATURE_FLAGS.CCO_TREE_VIEW_TABLE]: boolean
}

export type FeatureFlags = typeof FEATURE_FLAGS[keyof typeof FEATURE_FLAGS]

/**
 * Override feature flags on development. Will work only for `process.env.NODE_ENV === 'development'`.
 * You can use it to quickly override flags value to test them during development.
 *
 * Example:
 * ```
 * {
 *   ...
 *   [FEATURE_FLAGS.SELF_SERVE]: true,
 * }
 * ```
 */
export const devOverride: { [key in FeatureFlags]?: FeatureFlagPayloads[key] } = {
  // [FEATURE_FLAGS.SELF_SERVE]: true,
  // [FEATURE_FLAGS.BE_CONNECTIONS]: true,
  // [FEATURE_FLAGS.TRACE_QUALITY_LABEL]: true,
  // [FEATURE_FLAGS.SESSION_RECORDING]: true,
  // [FEATURE_FLAGS.PSTOOL_LITE]: true,
  // [FEATURE_FLAGS.JUMP_TO_SOURCE]: true,
}
