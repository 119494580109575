export const truncate = (str: string, maxLength: number) =>
  str.length > maxLength ? `${str.slice(0, maxLength + 1)}…` : str

export function capitalizeFirstLetter(value: string) {
  return value.charAt(0).toUpperCase() + value.slice(1)
}

export const extractFileName = (filePath?: string): string => {
  if (filePath) {
    const index = filePath.lastIndexOf('/')
    return filePath.substring(index + 1)
  } else {
    return ''
  }
}
