import React, { useMemo } from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { Layout } from 'components/Layout'
import { useTeamsQuery } from 'hooks/useApiQuery'
import { GuideStore } from 'components/guide/GuideStore'
import { useFreeTrialShareLink } from 'components/share-with-team/useFreeTrialShareLink'
import { ShareLinkContextProvider } from 'components/share-with-team/ShareLinkContext'
import { Footer } from 'components/Footer'
import { Icon } from 'components/Icon'
import { PATH_LIVE_DEMO } from 'pages/LiveDemoPage'
import { PATH_FREE_TRIAL_QUESTIONNAIRE } from 'pages/FreeTrialQuestionnairePage'
import { BaseHeader } from 'components/header/BaseHeader'
import { ShareLink } from 'components/share-with-team/ShareLink'

export const PATH_WELCOME_PAGE = '/welcome'

export const WelcomePage = () => {
  const { data: teams } = useTeamsQuery()
  const defaultFreeTrialTeam = GuideStore.getDefaultFreeTrialTeam(teams ?? [])
  const { t } = useTranslation()

  const { getShareLink, sendShareLink, isShareLinkReady } = useFreeTrialShareLink(
    defaultFreeTrialTeam?.urlName,
  )

  const header = useMemo(() => <BaseHeader rightSideContent={<ShareLink />} />, [])

  const footer = useMemo(() => <Footer />, [])

  return (
    <ShareLinkContextProvider
      getShareLink={getShareLink}
      sendShareLink={sendShareLink}
      isShareLinkReady={isShareLinkReady}
    >
      <Layout
        pageConfig={{ withoutStyledContent: true }}
        headerComponent={header}
        footerComponent={footer}
      >
        <div className="min-h-full flex flex-col justify-center">
          <div className="text-center mb-[48px]">
            <h2 className="text-header-small font-medium mb-[8px]">
              {t('components.welcome.title')}
            </h2>
            <p className="text-normal text-gray-normal">{t('components.welcome.description')}</p>
          </div>
          <div className="flex justify-center">
            <Link to={`${PATH_LIVE_DEMO}?live-demo-guide=0`}>
              <div className="w-[302px] h-[360px] cursor-pointer bg-dark-dark2 hover:bg-dark-dark1 transition-colors rounded-[16px] pt-[16px] px-[16px] mx-[12px] flex flex-col justify-between">
                <div className="pt-[4px] px-[8px]">
                  <h3 className="text-header-small mb-[14px]">
                    {t('components.welcome.liveDemo.title')}
                  </h3>
                  <p className="text-normal text-gray-normal">
                    {t('components.welcome.liveDemo.description')}
                  </p>
                </div>

                <div className="flex items-center justify-between text-state-attention border-gray-strokeLight border-solid border-t-[1px] px-[8px] pb-[6px]">
                  <span className="text-small font-medium">
                    {t('components.welcome.liveDemo.action')}
                  </span>
                  <Icon icon="auth-arrow-r" className="text-[30px]" />
                </div>
              </div>
            </Link>

            <Link to={PATH_FREE_TRIAL_QUESTIONNAIRE}>
              <div className="w-[302px] h-[360px] cursor-pointer bg-dark-dark2 hover:bg-dark-dark1 transition-colors rounded-[16px] pt-[16px] px-[16px] mx-[12px] flex flex-col justify-between">
                <div className="pt-[4px] px-[8px]">
                  <h3 className="text-header-small mb-[14px]">
                    {t('components.welcome.freeTrial.title')}
                  </h3>
                  <p className="text-normal text-gray-normal">
                    {t('components.welcome.freeTrial.description')}
                  </p>
                </div>

                <div className="flex items-center justify-between text-state-good border-gray-strokeLight border-solid border-t-[1px] px-[8px] pb-[6px]">
                  <span className="text-small font-medium">
                    {t('components.welcome.freeTrial.action')}
                  </span>
                  <Icon icon="auth-arrow-r" className="text-[30px]" />
                </div>
              </div>
            </Link>
          </div>
        </div>
      </Layout>
    </ShareLinkContextProvider>
  )
}
