import React, { useState } from 'react'
import styled from 'styled-components/macro'
import tw from 'twin.macro'
import InputMask from 'react-input-mask'
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import { useTranslation } from 'react-i18next'

dayjs.extend(customParseFormat)

interface DateInputProps {
  value: string
  onChange: React.Dispatch<React.SetStateAction<string>>
  isRequired: boolean
  resetRequired: () => void
}

export const DATE_STRING_FORMAT = 'MM.DD.YY  HH:mm:ss'

export const DateInput = ({ value, onChange, isRequired, resetRequired }: DateInputProps) => {
  const { t } = useTranslation()
  const [isInvalid, setInvalid] = useState(false)

  const handleDateFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const getValue = event.target.value
    onChange(getValue)
    if (isRequired) {
      resetRequired()
    }
    const parsedDate = dayjs(getValue, DATE_STRING_FORMAT)
    setInvalid(
      getValue.replace(/[^\d]/g, '').length === 12 &&
        parsedDate.format(DATE_STRING_FORMAT) !== getValue,
    )
  }

  return (
    <>
      <Input
        placeholder="MM.DD.YY  HH:MM:SS"
        mask="99.99.99  99:99:99"
        value={value}
        onChange={handleDateFilterChange}
      />
      {(isInvalid || isRequired) && (
        <DateError>
          {t(isRequired ? 'components.dateInput.required' : 'components.dateInput.invalidDate')}
        </DateError>
      )}
    </>
  )
}

const Input = styled(InputMask)`
  ${tw`text-normal tracking-wide placeholder:text-white`}
  display: block;
  color: ${({ theme }) => theme.colors.white};
  border: none;
  outline: none;
  background: none;
`

const DateError = styled.div`
  ${tw`text-micro`}
  color: ${({ theme }) => theme.colors.state.bad};
`
