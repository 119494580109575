import { useCallback, useEffect, useState } from 'react'
import { useLocation, useSearchParams } from 'react-router-dom'

export const useModal = (modal: string) => {
  const location = useLocation()
  const [, setSearchParams] = useSearchParams()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [paramValue, setParamValue] = useState<string>('')

  const closeModal = useCallback(() => {
    const currentSearchParams = new URLSearchParams(location.search)
    currentSearchParams.delete(modal)
    setSearchParams(currentSearchParams)
  }, [modal, setSearchParams, location])

  const openModal = useCallback(
    (value: string) => {
      const currentSearchParams = new URLSearchParams(location.search)
      currentSearchParams.set(modal, value)
      setSearchParams(currentSearchParams)
    },
    [modal, setSearchParams, location],
  )

  useEffect(() => {
    const currentSearchParams = new URLSearchParams(location.search)
    setIsModalOpen(currentSearchParams.has(modal))

    if (currentSearchParams.has(modal)) {
      setParamValue(currentSearchParams.get(modal) || '')
      localStorage.setItem(modal, currentSearchParams.get(modal) || '')
    }
  }, [modal, location])

  return {
    isModalOpen,
    closeModal,
    openModal,
    paramValue,
  }
}
