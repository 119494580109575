import { twMerge } from 'tailwind-merge'

import { LiteLayout, LiteLayoutContainer } from 'components/lite/LiteLayout'
import { LiteTargetHeader } from 'components/lite/LiteHeader'
import { LiteFooter } from 'components/lite/LiteFooter'

import { ContactForm } from 'components/target/ContactForm'

export const PATH_LITE_TARGET_1 = '/pmtool'

export const LiteTarget1Page = () => {
  return (
    <LiteLayout header={<LiteTargetHeader />} footer={<LiteFooter hideDocs />}>
      <LiteLayoutContainer>
        <div className="z-20 relative">
          <h1
            className={twMerge(
              'tracking-[-.05em] font-semibold leading-tighter',
              'md:my-16 text-[42px]',
              '1280:text-[52px]',
            )}
          >
            See app delays for new release before it gets to your users
            <br />
            Walk in your customer’s shoes for the first time
          </h1>
        </div>
        <div className="md:grid md:grid-cols-4 gap-5">
          <div className="mb-16 relative">
            <p className="text-header-big text-right">
              How you think your user is experiencing your app
            </p>
          </div>
          <div className="mb-10 relative gap-3">
            <video width="1040" height="1120" autoPlay loop muted playsInline>
              <source
                src="https://videos.ctfassets.net/sp0nb9fw4dnv/7sPNLR5fsFOXp3ncGkBdpH/570dd3b715b349af4155136e94d7d6db/FinalAntennaPodComparison.mov"
                type="video/mp4"
              />
              <img
                src="https://images.ctfassets.net/sp0nb9fw4dnv/5Ga4B6isx2oXLl9vx1Jp5K/67b5ac4a05d2dfa06be190ed00c610ba/antenna-comparison.gif"
                width="1040"
                height="1120"
              />
            </video>
          </div>
          <div className="mb-10 relative gap-3">
            <p className="text-header-big">How the user is really experiencing the app</p>
          </div>
        </div>
        <div className="md:grid md:grid-cols-4 gap-5">
          <div className="mb-16 relative col-span-3">
            <p className="text-header-small mb-[1em]">
              The impact that the feature is having on your user experience is entangled with
              technical debt, misplaced API calls and other unnecessary functions.
            </p>
            <p className="text-header-small mb-[1em]">
              In reality, up until now, you could never know what's really causing your userflow or
              feature to fail V2: In reality, up until now, you could never really know the source
              of poor user experience.
            </p>
            <p className="text-header-small">
              Post production app testing does not accurately represent what's happening to the user
              in real time. Product Science does.
            </p>
          </div>
          <div className="mb-10 relative gap-3"></div>
        </div>

        <div className="md:grid md:grid-cols-7 gap-5 text-header-small">
          <div className="col-span-2">
            <p className="text-turquoise">
              <strong>Analytics measurement:</strong> 5.34s
            </p>
            <p className="text-electro">
              <strong>User experienced:</strong> 6.99s
            </p>
          </div>
          <div className="col-span-3">
            <p>Product analytics underreported by &gt;20% vs real UX</p>
          </div>
        </div>
      </LiteLayoutContainer>
      <LiteLayoutContainer className="mx-0 px-[5%] overflow-scroll">
        <div className="grid grid-cols-7 gap-5 mb-5 text-big items-end min-w-[1000px] text-electro">
          <p className="text-center">
            <strong>User tapped search</strong>
            <br />
            00:01.35
          </p>
          <p className="text-center col-span-4">
            <strong>Waiting for Request</strong>
            <br />
            00:01.95 &mdash; 00:06.93
          </p>
          <p className="text-center">
            <strong>Churn point</strong>
            <br />
            <strong>User leaves tired of waiting</strong>
            <br />
            00:07.76
          </p>
          <p className="text-center">
            <strong>User sees all results</strong>
            <br />
            00:08.34
          </p>
        </div>
        <div className="grid grid-cols-7 gap-5 min-w-[1000px]">
          <img
            src="https://images.ctfassets.net/sp0nb9fw4dnv/Maej2PSEFk8x6BHgRxQWi/ea305da4166d23314cb47a35865fbfde/pmtool-timeline-1.png"
            width="925"
            height="2048"
            className="min-w-full"
          />
          <img
            src="https://images.ctfassets.net/sp0nb9fw4dnv/4t9a3NHza3ilrTo2ZlRkZF/be800905ec56492a286054a6cca2d191/pmtool-timeline-2.png"
            width="925"
            height="2048"
            className="min-w-full"
          />
          <img
            src="https://images.ctfassets.net/sp0nb9fw4dnv/6uAXuwzDk0QVIcXDl6PyAc/476384bb5a961ff7a1b17f649698fa52/pmtool-timeline-3.png"
            width="925"
            height="2048"
            className="min-w-full"
          />
          <img
            src="https://images.ctfassets.net/sp0nb9fw4dnv/bfNdrKuesH1azSgNurmrV/06b90f110e7503ef45dc2ff64264b1b8/pmtool-timeline-4.png"
            width="925"
            height="2048"
            className="min-w-full"
          />
          <img
            src="https://images.ctfassets.net/sp0nb9fw4dnv/7DdhtACEiLdiY1oEbTxir0/ef52144f03f747583a686e49df34177d/pmtool-timeline-5.png"
            width="925"
            height="2048"
            className="min-w-full"
          />
          <img
            src="https://images.ctfassets.net/sp0nb9fw4dnv/1o16aiQTXn0vNeD13Wq1op/57710b11a068177640b4a92ef95f0aa6/pmtool-timeline-6.png"
            width="925"
            height="2048"
            className="min-w-full"
          />
          <img
            src="https://images.ctfassets.net/sp0nb9fw4dnv/4roq8bmW1ch3oh4gNYm7zI/2d41a3b230f7f4c5dafce01db138eeff/pmtool-timeline-7.png"
            width="925"
            height="2048"
            className="min-w-full"
          />
        </div>
        <div className="grid grid-cols-7 gap-5 mt-5 text-big min-w-[1000px] text-turquoise">
          <div></div>
          <p className="text-center">
            00:01.78
            <br />
            <strong>Your analytics marked start</strong>
            <br />
            performSearch method called
          </p>
          <div></div>
          <div></div>
          <p className="text-center">
            00:07:12
            <br />
            <strong>Your analytics marked finish</strong>
            <br />
            Results returned and view appears without images performSearch method called
          </p>
        </div>
      </LiteLayoutContainer>
      <LiteLayoutContainer>
        <div className="mt-28 mb-[160px] text-center">
          <ContactForm />
        </div>
      </LiteLayoutContainer>
    </LiteLayout>
  )
}
