import { observer } from 'mobx-react-lite'
import { PsChartStore } from 'components/ps-chart/PsChartStore'
import React, { useCallback } from 'react'
import { MeasureValue } from './MeasureValue'
import { MeasureButton } from './MeasureButton'

export const PerfStaticMeasurer = observer(function PerfStaticMeasurer({
  psChartStore,
}: {
  psChartStore: PsChartStore
}) {
  const startMeasuring = useCallback(() => {
    psChartStore.renderingMeasuring.start()
  }, [psChartStore])

  const stopMeasuring = useCallback(() => {
    psChartStore.renderingMeasuring.stop()
  }, [psChartStore])

  const clearMeasures = useCallback(() => {
    psChartStore.renderingMeasuring.clear()
  }, [psChartStore])

  return (
    <>
      <MeasureValue value={psChartStore.renderingMeasuring.renderTime} text="ms per render" />
      <MeasureValue value={psChartStore.renderingMeasuring.measuresCount} text="measures" />
      <MeasureButton text="Start" onClick={startMeasuring} />
      <MeasureButton text="Stop" onClick={stopMeasuring} />
      <MeasureButton text="Clear" onClick={clearMeasures} />
    </>
  )
})
