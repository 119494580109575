import { useEffect, useState, useRef } from 'react'
import { useLocation } from 'react-router-dom'
import { useAuth } from 'contexts/auth-context'
import { useUserQuery } from 'hooks/useApiQuery'

import { IntercomProvider as OriginalIntercomProvider, useIntercom } from 'react-use-intercom'

export const IntercomProvider = ({ children }: { children: React.ReactNode }) => {
  const appId = process.env.REACT_APP_INTERCOM_APP_ID
  if (!appId) {
    return <>children</>
  }
  return (
    <OriginalIntercomProvider appId={appId} initializeDelay={500}>
      {children}
      <IntercomHandlers />
    </OriginalIntercomProvider>
  )
}

const IntercomHandlers = () => {
  const location = useLocation()
  const { isSignedIn } = useAuth()
  const prevIsSignedIn = useRef<boolean>()
  const { data } = useUserQuery(isSignedIn)
  const [isInitialized, setIsInitialized] = useState(false)

  const { boot, update, shutdown } = useIntercom()

  const { id, email, name } = data || {}

  // Boot
  useEffect(() => {
    const shouldShowIntercom =
      location.pathname.startsWith('/lite/') || !!process.env.REACT_APP_IS_LITE
    if (!isInitialized && shouldShowIntercom) {
      boot()
      setIsInitialized(true)
    }
  }, [location.pathname, isInitialized, boot])

  // User is logged in
  useEffect(() => {
    if (isInitialized && !prevIsSignedIn.current && isSignedIn && id) {
      update({
        name,
        email,
        userId: String(id),
      })
      prevIsSignedIn.current = isSignedIn
    }
  }, [isInitialized, isSignedIn, update, id, email, name])

  // User is logged out
  useEffect(() => {
    if (isInitialized && prevIsSignedIn.current && !isSignedIn) {
      shutdown()
      boot()
      prevIsSignedIn.current = isSignedIn
    }
  }, [isInitialized, isSignedIn, boot, shutdown])

  return null
}
