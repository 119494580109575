import React, { useCallback, useContext, useEffect } from 'react'
import { GoogleOAuthProvider } from '@react-oauth/google'
import { useQueryClient } from 'react-query'
import { useNavigate } from 'react-router-dom'
import { PATH_SIGN_UP_SSO } from 'pages/auth/SignUpSsoPage'
import {
  ApiError,
  CheckInviteTokenRequest,
  CheckInviteTokenResponse,
  FreeTrialRequest,
  PsLiteRequest,
  ResetPasswordRequest,
  SigninDto,
  SignupRequestFreeTrial,
  SignupRequest,
  SignupRequestPsLite,
  SignInResDto,
} from 'api/models'
import { useApi, useDi } from 'contexts/di-context'
import { AxiosError } from 'axios'
import { isAxiosError } from 'utils/axios'
import { psLocalStorage } from 'utils/localStorage/PsLocalStorage'
import { observer } from 'mobx-react-lite'
import NProgress from 'nprogress'
import { PATH_ROOT } from 'utils/links'
import { PATH_LITE_TRACES } from 'pages/lite/LiteTracesPage'

export interface AuthContextInterface {
  isSignedIn: boolean
  check: () => Promise<void>
  signIn: (data: SigninDto, redirectPath: string) => Promise<void>
  signInSSO: (params: {
    data: SigninDto
    redirectPath: string
    stateFields?: Record<string, unknown>
  }) => Promise<SignInResDto>
  signOut: (redirectPath?: string) => Promise<void>
  signUp: (data: SignupRequest) => Promise<void>
  signUpCheckToken: (data: CheckInviteTokenRequest) => Promise<CheckInviteTokenResponse>
  signUpFreeTrial: (data: SignupRequestFreeTrial) => Promise<void>
  requestFreeTrial: (data: FreeTrialRequest) => Promise<void>
  signUpPsLite: (data: SignupRequestPsLite) => Promise<void>
  requestPsLite: (data: PsLiteRequest) => Promise<void>
  resetPassword: (data: ResetPasswordRequest) => Promise<void>
}

interface AuthContextProviderProps {
  children: React.ReactNode
}

export const AuthContext = React.createContext<AuthContextInterface | null>(null)

export function useAuth(): AuthContextInterface {
  const authContext = useContext(AuthContext)
  if (authContext == null) {
    throw new Error('Auth context is not initialized')
  }
  return authContext
}

export const AuthContextProvider = observer(function AuthContextProvider(
  props: AuthContextProviderProps,
) {
  const cr = useDi().compositionRoot
  const { isSignedIn, setIsSignedIn } = cr.authStore
  const navigate = useNavigate()
  const api = useApi()
  const queryClient = useQueryClient()

  const clearAll = useCallback(
    (clearQueryClient: boolean) => {
      psLocalStorage.removeLastTeam()
      setIsSignedIn(false)
      cr.firebaseAuth.signOut()
      cr.oktaAuth.signOut()
      if (clearQueryClient) {
        queryClient.clear()
      }
    },
    [queryClient, cr, setIsSignedIn],
  )

  // Setup network requests interceptor to set current signedIn state
  useEffect(() => {
    cr.subscribeToAxiosError((error: unknown) => {
      if (error && isAxiosError(error)) {
        const axiosError = error as AxiosError<ApiError>
        if (axiosError.response?.status === 401) {
          const url = axiosError.config?.url
          const clearQueryClient = url ? !cr.api.urlsNotRequireAuth().includes(url) : true

          // We should clear query client cache only for queries requiring auth.
          // If not, there could be an error message lost in toast during sign-in/sign-up requests
          clearAll(clearQueryClient)
          NProgress.done()
        }
      }
    })
  }, [clearAll, cr])

  const checkHandler = useCallback(() => {
    return api
      .getAuthCheck()
      .then(() => setIsSignedIn(true))
      .catch(() => {})
  }, [api, setIsSignedIn])

  const signInHandler = useCallback(
    (data: SigninDto, redirectPath: string) => {
      return api.postSignIn(data).then(() => {
        setIsSignedIn(true)
        navigate(redirectPath, { replace: true })
      })
    },
    [api, navigate, setIsSignedIn],
  )

  const signInSSOHandler = useCallback<AuthContextInterface['signInSSO']>(
    ({ data, redirectPath, stateFields = {} }) => {
      return api.postSignIn(data).then((response) => {
        if (response.hasOwnProperty('user')) {
          setIsSignedIn(true)
          navigate(redirectPath, { replace: true })
        } else {
          navigate(PATH_SIGN_UP_SSO, {
            replace: true,
            state: {
              ssoIdToken: data.ssoData?.ssoIdToken as string,
              userData: response,
              allowFreeTrial: data.ssoData?.allowFreeTrial,
              ...stateFields,
            },
          })
        }
        return response
      })
    },
    [api, navigate, setIsSignedIn],
  )

  const signOutHandler = useCallback(
    (redirectPath = PATH_ROOT) => {
      return api.postSignOut().then(() => {
        clearAll(true)
        navigate(redirectPath, { state: { isSignOutAction: true }, replace: true })
      })
    },
    [api, clearAll, navigate],
  )

  const resetPasswordHandler = useCallback(
    (data: ResetPasswordRequest) => {
      return api.postResetPassword(data).then(() => {
        setIsSignedIn(true)
        navigate(PATH_ROOT, { replace: true })
      })
    },
    [api, navigate, setIsSignedIn],
  )

  const signUpHandler = useCallback(
    (data: SignupRequest) => {
      return api.postSignUp(data).then(() => {
        setIsSignedIn(true)
        navigate(PATH_ROOT, { replace: true })
      })
    },
    [api, navigate, setIsSignedIn],
  )

  const signUpCheckTokenHandler = useCallback(
    (data: CheckInviteTokenRequest) => {
      return api.postSignUpCheckToken(data)
    },
    [api],
  )

  const signUpFreeTrialHandler = useCallback(
    (data: SignupRequestFreeTrial) => {
      return api.postSignUpFreeTrial(data).then(() => {
        setIsSignedIn(true)
        navigate(PATH_ROOT, { replace: true })
      })
    },
    [api, navigate, setIsSignedIn],
  )

  const signUpPsLiteHandler = useCallback(
    async (data: SignupRequestPsLite) => {
      await api.postSignUpPsLite(data)
      setIsSignedIn(true)
      navigate(PATH_LITE_TRACES, { replace: true })
    },
    [api, navigate, setIsSignedIn],
  )

  const requestFreeTrialHandler = useCallback(
    (data: FreeTrialRequest) => {
      return api.postRequestFreeTrial(data)
    },
    [api],
  )

  const requestPsLiteHandler = useCallback(
    (data: PsLiteRequest) => {
      return api.postRequestPsLite(data)
    },
    [api],
  )

  const contextValue: AuthContextInterface = {
    isSignedIn: isSignedIn,
    check: checkHandler,
    signIn: signInHandler,
    signInSSO: signInSSOHandler,
    signOut: signOutHandler,
    signUp: signUpHandler,
    signUpCheckToken: signUpCheckTokenHandler,
    signUpFreeTrial: signUpFreeTrialHandler,
    requestFreeTrial: requestFreeTrialHandler,
    signUpPsLite: signUpPsLiteHandler,
    requestPsLite: requestPsLiteHandler,
    resetPassword: resetPasswordHandler,
  }
  return (
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_WEBSDK_CLIENT_ID!}>
      <AuthContext.Provider value={contextValue}>{props.children}</AuthContext.Provider>
    </GoogleOAuthProvider>
  )
})
