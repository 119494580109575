import { observer } from 'mobx-react-lite'
import { useOutletContext } from 'react-router-dom'
import { GuidePageOutletContext } from 'pages/guide/GuidePageOutletContext'
import { SelectPlatform } from 'components/guide/SelectPlatform'

export const GuideSelectPlatform = observer(function GuideSelectPlatform() {
  const { guideStore } = useOutletContext<GuidePageOutletContext>()
  const { submitRequestStore } = guideStore

  if (!submitRequestStore) {
    throw new Error('There is no the submitRequestStore in a guides store')
  }

  return <SelectPlatform submitRequestStore={submitRequestStore} />
})
