import { SliceLink } from 'components/ps-chart/models/SliceLink'
import { ReadonlySliceById } from 'components/ps-chart/stores/TraceDataStore'
import { ConnectionDirection } from 'components/ps-chart/models/ConnectionDirection'

export const sortLinksByTargetSliceLevel = (
  chainsRoots: SliceLink[],
  sliceById: ReadonlySliceById,
  linkDirection = ConnectionDirection.BACKWARD,
) => {
  // Array.prototype.sort mutates original array which ruins original sorting of stack
  return [...chainsRoots].sort((chainRootA, chainRootB) => {
    const { toSliceId: chainAToSliceId, fromSliceId: chainAFromSliceId } = chainRootA
    const { toSliceId: chainBToSliceId, fromSliceId: chainBFromSliceId } = chainRootB

    const chainRootAId =
      linkDirection === ConnectionDirection.BACKWARD ? chainAToSliceId : chainAFromSliceId
    const chainRootBId =
      linkDirection === ConnectionDirection.BACKWARD ? chainBToSliceId : chainBFromSliceId

    const targetSliceA = sliceById.get(chainRootAId)!
    const targetSliceB = sliceById.get(chainRootBId)!

    return targetSliceB.level - targetSliceA.level
  })
}

export const mergeSameTargetStackChains =
  (sliceById: ReadonlySliceById, linkDirection = ConnectionDirection.BACKWARD) =>
  (chainsRoots: SliceLink[]) => {
    const youngTargetFirstChainsRoots = sortLinksByTargetSliceLevel(
      chainsRoots,
      sliceById,
      linkDirection,
    )
    const occupiedSlices = new Set<number>()
    const filteredChainsRoots = new Set<SliceLink>()
    for (const curChainRoot of youngTargetFirstChainsRoots) {
      const { toSliceId, fromSliceId } = curChainRoot
      const curTargetId = linkDirection === ConnectionDirection.BACKWARD ? toSliceId : fromSliceId
      if (occupiedSlices.has(curTargetId)) {
        continue
      }
      filteredChainsRoots.add(curChainRoot)
      let curTargetSlice = sliceById.get(curTargetId)!
      while (true) {
        occupiedSlices.add(curTargetSlice.id)
        if (curTargetSlice.parent == null) {
          break
        }
        curTargetSlice = curTargetSlice.parent!
      }
    }

    return chainsRoots.filter((root) => filteredChainsRoots.has(root))
  }

export const getAncestorsOccupiedSliceIds = (
  sliceById: ReadonlySliceById,
  stackChainLinks: SliceLink[],
) => {
  const deepTargetFirstStackLinks = sortLinksByTargetSliceLevel(stackChainLinks, sliceById)
  const occupiedSlices = new Set<number>()
  for (const curStackLink of deepTargetFirstStackLinks) {
    if (occupiedSlices.has(curStackLink.toSliceId)) {
      continue
    }
    let curTargetSlice = sliceById.get(curStackLink.toSliceId)!
    while (true) {
      occupiedSlices.add(curTargetSlice.id)
      if (curTargetSlice.parent == null) {
        break
      }
      curTargetSlice = curTargetSlice.parent!
    }
  }

  return occupiedSlices
}
