import { observer } from 'mobx-react-lite'
import { generatePath, useNavigate } from 'react-router-dom'
import classNames from 'classnames'

import { ModuleProgressCircle } from 'components/ps-chart/ModuleProgressCircle'
import { GuideIoStore } from 'components/guide-io/GuideIoStore'
import { GUIDE_IO_PROJECT_PATH } from 'components/guide-io/constants'
import { GuideIoMd } from 'components/guide-io/GuideIoMd'
import { GetHelpBanner } from 'components/support/GetHelpBanner'
import { MenuItemButton } from 'components/guide-io/MenuItemButton'
import './optionalPageStyle.css'

interface GuideIoPageContentProps {
  guideIoStore: GuideIoStore
}

export const GuideIoPageContent = observer(function GuideIoPageContent({
  guideIoStore,
}: GuideIoPageContentProps) {
  const navigate = useNavigate()
  const { faqPage } = guideIoStore

  if (guideIoStore.error != null) {
    return (
      <div className="flex flex-grow flex-col typography-big-accent text-state-bad text-center pt-[10%] pr-[107px]">
        {guideIoStore.error}
      </div>
    )
  }

  if (!guideIoStore.isReady) {
    return <ModuleProgressCircle />
  }

  return (
    <div className="flex flex-grow justify-center mt-[38px]">
      <div className="w-[280px] mr-[40px]">
        <div className="mb-[24px]">
          {guideIoStore.section.steps.map((step) => (
            <div key={step.fields.id}>
              <MenuItemButton
                title={step.fields.menuTitle}
                isSelected={step.fields.urlKey === guideIoStore.stepKey}
                onClick={() => {
                  navigate(
                    generatePath(GUIDE_IO_PROJECT_PATH, {
                      projectUrlName: guideIoStore.projectUrlName,
                      stepKey: step.fields.urlKey,
                    }),
                  )
                }}
                isOptional={step.fields.isStepOptional}
              />
            </div>
          ))}
        </div>
        <div className="pt-[24px] border-t-[1px] border-solid border-gray-strokeLight">
          <MenuItemButton
            title={faqPage!.menuTitle}
            isSelected={guideIoStore.stepKey === faqPage?.urlKey}
            onClick={() => {
              navigate(
                generatePath(GUIDE_IO_PROJECT_PATH, {
                  projectUrlName: guideIoStore.projectUrlName,
                  stepKey: faqPage?.urlKey,
                }),
              )
            }}
            isOptional={faqPage!.isStepOptional}
          />
        </div>
      </div>
      <div
        className={classNames([
          'w-[628px] ml-[40px] mt-[-32px] mb-[64px]',
          guideIoStore.page.isStepOptional && 'io-optional-page',
        ])}
      >
        <GuideIoMd guideIoStore={guideIoStore} />
        {guideIoStore.shouldShowGetHelp && <GetHelpBanner />}
      </div>
    </div>
  )
})
