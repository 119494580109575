import { ActionTooltip, ActionTooltipType } from 'components/ActionTooltip'
import { observer } from 'mobx-react-lite'
import { PsChartStore } from 'components/ps-chart/PsChartStore'
import { RendererType } from 'components/ps-chart/stores/ChartRendererStore'
import { IconColored } from 'components/common/IconColored'
import { useCallback, useContext } from 'react'
import { LayoutContext } from 'contexts/layout-context'

export const ACTION_CHANGE_CHART_RENDER_TYPE = 'renderType'

interface RenderTypeModeActionProps {
  psChartStore: PsChartStore
}

export const RenderTypeModeAction = observer(function RenderTypeModeAction({
  psChartStore,
}: RenderTypeModeActionProps) {
  const { activeHints, deactivateHint } = useContext(LayoutContext)

  const actionType =
    activeHints.has(ACTION_CHANGE_CHART_RENDER_TYPE) &&
    activeHints.get(ACTION_CHANGE_CHART_RENDER_TYPE)
      ? ActionTooltipType.HINT
      : ActionTooltipType.SHORTCUT

  const handleMouseEnter = useCallback(() => {
    deactivateHint(ACTION_CHANGE_CHART_RENDER_TYPE)
  }, [deactivateHint])

  return (
    <ActionTooltip place="top" tooltipType={actionType} tooltipId={ACTION_CHANGE_CHART_RENDER_TYPE}>
      <div className="flex items-center justify-center align-center w-[64px] h-[32px] bg-dark-dark3">
        <IconColored
          className="p-[8px]"
          icon="chart-clustering"
          size={16}
          isActive={psChartStore.rendererStore.renderType === RendererType.CLUSTERED}
          onClick={() => psChartStore.toggleRenderTypeMode()}
          data-tid="cluster-btn"
          onPointerEnter={handleMouseEnter}
        />
      </div>
    </ActionTooltip>
  )
})
