import React, { useEffect } from 'react'

import { AuthLayout } from 'components/auth/AuthLayout'
import NProgress from 'nprogress'
import { ErrorComponent } from 'components/ErrorComponent'

export const ErrorPage = () => {
  useEffect(() => {
    NProgress.done()
  }, [])

  return (
    <AuthLayout>
      <ErrorComponent />
    </AuthLayout>
  )
}
