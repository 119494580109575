import { PsChartStore } from 'components/ps-chart/PsChartStore'
import { getSliceVisibleRect } from 'components/ps-chart/utils/getSliceVisibleRect'
import { getSliceTimelineTitle } from 'components/ps-chart/utils/slice'
import { VerticalState } from 'components/ps-chart/models/VerticalState'
import { HorizontalState } from 'components/ps-chart/models/HorizontalState'
import { Thread } from 'components/ps-chart/models/Thread'
import { Slice } from 'components/ps-chart/models/Slice'
import {
  GLOBAL_ALPHA_DEFAULT_VALUE,
  RenderEngineSettings,
} from 'components/ps-chart/models/settings'
import {
  ChainDelayData,
  ChainNodeData,
  LevelsByThreadId,
  MaxLevelByThreadId,
  TopBottomByThreadId,
} from 'components/ps-chart/stores/TraceAnalyzeStore'
import {
  ConnectionCurves,
  ConnectionPaths,
  SliceBordersPaths,
} from 'components/ps-chart/connections-render/ConnectionCurves'
import { FlagsState } from 'components/ps-chart/stores/FlagsStore'
import { TextMeasurer } from 'components/ps-chart/flame-chart/TextMeasurer'

import { nanoToMeasurementString } from 'components/ps-chart/utils/nanoToString'
import { getSliceDimmedColor } from 'components/ps-chart/utils/getSliceDimmedColor'
import { getConnectionError } from 'components/ps-chart/stores/connections-store/getConnectionError'
import {
  AllBorderTypes,
  AllLineTypes,
  BorderType,
} from 'components/ps-chart/connections-render/NodeRenderData'
import { VideoPlayerState } from 'components/ps-chart/stores/VideoPlayerStore'
import { AnnotationsState, AnnotationsStore } from 'components/ps-chart/stores/AnnotationsStore'
import { AnnotationBindingDto, NamedLinkType } from 'api/models'
import { colors } from 'utils/styles/colors'
import {
  BasicRenderer,
  RenderLabelParams,
  RenderLabelTypes,
  RenderTextParams,
} from './BasicRenderer'

export type ThreadsTopMap = Map<number, number>

export type MeasurementPoints = {
  startTime: number
  endTime?: number
  y: number
  isMain: boolean
} | null

export interface RenderEngineData {
  psChartStore: PsChartStore
  threads: Thread[]
  isPinned: boolean
  shouldRenderMeasurement: boolean
  /**
   * Map which gives for specified thread id - thread's levels occupied by slices from execution path
   */
  activeLevelsByThreadId: LevelsByThreadId
  /**
   * Map which gives for specified thread id - thread's maximum level which should be rendered
   */
  maxLevelByThreadId: MaxLevelByThreadId
  hState: HorizontalState
  vState: VerticalState
  flagsState: FlagsState
  videoState: VideoPlayerState
  annotationsState: AnnotationsState
  measurementPoints: MeasurementPoints
  isTransparentConnectionEnabled: boolean
  /**
   * View mode which should render only slices which belongs to level occupied by slices from execution path.
   * And also render only zero level for favorite/pinned threads not used in execution path.
   */
  isThreadShrunkModeEnabled: boolean
  connectionCurves?: ConnectionCurves
  linkModeSlice?: Slice
  delays?: ChainDelayData[]
}

/**
 * Gets the data and renders timeline, slices, etc
 */
export class RenderEngine {
  private readonly context: CanvasRenderingContext2D

  private readonly renderer: BasicRenderer

  private readonly measurementLineTextMeasurer: TextMeasurer

  private readonly fontStyle: string

  private readonly textMeasurer: TextMeasurer

  readonly measurementFontStyle = `${RenderEngine.textMeasurerFontSize}px Manrope`

  readonly iconFontStyle = `8px icon`

  private readonly settings: RenderEngineSettings

  static readonly textMeasurerFontSize = 10

  private sliceLabelsToRender: Map<number, RenderLabelParams> = new Map<number, RenderLabelParams>()

  constructor(
    context: CanvasRenderingContext2D,
    settings: RenderEngineSettings,
    fontStyle: string,
    textMeasurer: TextMeasurer,
  ) {
    this.settings = settings
    this.fontStyle = fontStyle
    this.textMeasurer = textMeasurer
    this.measurementLineTextMeasurer = new TextMeasurer(this.measurementFontStyle)
    this.context = context
    this.renderer = new BasicRenderer(this.context, settings.basicRenderer)
  }

  render(data: RenderEngineData) {
    RenderEngine.log(data)

    const start = performance.now()

    this.context.save()

    this.fillBackground(data.hState.width, data.vState.height)

    this.renderHoveredThreadHighlighting(data)

    if (data.threads.length && data.delays?.length) {
      this.renderDelays(data.hState, data.vState.height, data.delays)
    }
    this.renderGridLines(data.hState.xGridLines, data.isPinned, data.hState, data.vState)

    this.renderThreadsText(data)

    this.renderThreads(data)
    if (data.connectionCurves != null) {
      // We should render connection lines first so slice border won't be broken by close to border connection line
      this.renderConnectionsLines(
        data.connectionCurves.localCurvePaths,
        data.isTransparentConnectionEnabled,
      )
      this.renderSlicesBorders(data.connectionCurves.sliceBordersPaths)
    }

    const { chainNodes } = data.psChartStore.traceAnalyzeStore
    this.renderSliceLabels(chainNodes)

    this.context.restore()
    this.renderHeader(data)

    this.context.save()

    if (data.connectionCurves != null) {
      this.renderConnectionsLines(
        data.connectionCurves.crossCurvePaths,
        data.isTransparentConnectionEnabled,
      )
    }

    this.context.restore()

    this.renderHover(data.flagsState, data.videoState, data.hState, data.vState)
    this.renderAnnotationsConnections(data.annotationsState, data.psChartStore)
    if (data.flagsState.enabled) {
      this.renderFlags(data.flagsState, data.hState, data.vState)
    }
    this.renderVideoPointer(data.hState, data.vState, data.videoState)
    if (
      data.shouldRenderMeasurement &&
      data.measurementPoints &&
      data.measurementPoints.isMain === !data.isPinned
    ) {
      this.renderMeasurement(data.psChartStore, data.measurementPoints)
    }

    console.debug('#RE rendering took', (performance.now() - start).toFixed(1), 'ms')
  }

  renderEmpty(data: RenderEngineData) {
    const maxHeightWithHeader = data.isPinned
      ? data.vState.pinnedCanvasHeight
      : data.vState.mainCanvasHeight
    this.fillBackground(data.hState.width, maxHeightWithHeader)
  }

  private renderSlicesBorders(sliceBordersPaths: SliceBordersPaths) {
    for (const borderType of AllBorderTypes) {
      sliceBordersPaths[borderType].forEach((borderPaths: Path2D, layerIndex: number) => {
        this.renderer.strokePath(
          borderPaths,
          this.settings.palette.slice.borders[borderType][layerIndex],
          this.settings.threads.sliceBorderWidths[layerIndex],
        )
      })
    }
  }

  private renderConnectionsLines(
    connectionPaths: ConnectionPaths,
    isTransparentConnectionEnabled: boolean,
  ) {
    if (isTransparentConnectionEnabled) {
      this.context.globalAlpha = this.settings.connectionsTransparentModeOpacity
    }
    for (const lineType of AllLineTypes) {
      this.renderer.strokePathSteps(
        connectionPaths[lineType],
        this.settings.palette.connectionLines.background,
        this.settings.connectionCurveBackgroundWidth,
      )
      this.renderer.strokePathSteps(
        connectionPaths[lineType],
        this.settings.palette.connectionLines[lineType],
        this.settings.connectionCurveWidth,
        this.settings.connectionCurveDashes[lineType],
      )
    }
    if (isTransparentConnectionEnabled) {
      this.context.globalAlpha = GLOBAL_ALPHA_DEFAULT_VALUE
    }
  }

  private renderHeader(data: RenderEngineData) {
    const headerPath = new Path2D()
    const yOffset =
      data.isPinned && data.vState.favTotalHeight > 0 ? data.vState.utilTotalHeight : 0
    const height =
      !data.isPinned || (data.isPinned && data.vState.favTotalHeight > 0)
        ? this.settings.headerHeight
        : 0
    headerPath.rect(0, yOffset, data.hState.width, height)
    this.renderer.fillPath(headerPath, this.settings.palette.headerColor)
  }

  private static log(data: RenderEngineData) {
    console.debug(
      `#RE zoom=${data.hState.zoom}, timePerPx=${data.hState.timePerPx}, ` +
        `x=[${Math.round(data.hState.xStart / 1_000_000)}, ${Math.round(
          data.hState.xEnd / 1_000_000,
        )}] ms, ` +
        `width=${Math.round(data.hState.xWidth / 1_000_000)}, ` +
        `y=[${data.vState.yStart}, ${data.vState.yEndMain}] ms, height=${
          data.vState.yEndMain - data.vState.yStart
        }`,
    )
  }

  private fillBackground(width: number, height: number) {
    this.renderer.fillBackground(0, 0, width, height)
  }

  private renderDelays(hState: HorizontalState, height: number, delays: ChainDelayData[]) {
    for (const delay of delays) {
      if (delay.start > hState.xEnd + hState.xWidth || delay.end < hState.xStart - hState.xWidth) {
        continue
      }
      let start = RenderEngine.timeToPosition(delay.start, hState.xStart, hState.timePerPx)
      let end = RenderEngine.timeToPosition(delay.end, hState.xStart, hState.timePerPx)

      if (start < 0) {
        start = 0
      }
      if (end > hState.width) {
        end = hState.width
      }

      const width = end - start
      this.renderer.fillRect(start, 0, width, height, this.settings.palette.delay)
    }
  }

  static timeToPosition(time: number, xStart: number, timePerPx: number): number {
    return Math.round((time - xStart) / timePerPx)
  }

  private static heightToPosition(y: number, posY: number): number {
    return y - posY
  }

  private static isSliceOutByX(slice: Slice, xStart: number, xEnd: number) {
    return slice.end < xStart || slice.start > xEnd
  }

  private isSliceOutByY(slice: Slice, threadTopY: number, maxVisibleHeight: number) {
    const sliceTopY = threadTopY + slice.level * this.settings.threads.blockHeight
    const sliceBottomY = sliceTopY + this.settings.threads.blockHeight

    return sliceBottomY < -this.settings.threads.topPadding || sliceTopY > maxVisibleHeight
  }

  private renderThreads(data: RenderEngineData) {
    this.renderThreadsDividers(
      data.threads,
      data.isPinned ? data.vState.pinnedThreadsTopBottomMap : data.vState.mainThreadsTopBottomMap,
      data.hState.width,
      data.isPinned ? data.vState.yStartPinned : data.vState.yStart,
    )
    const sliceTextsToRender: RenderTextParams[] = []
    this.sliceLabelsToRender = new Map<number, RenderLabelParams>()
    for (const thread of data.threads) {
      if (thread.isExpanded) {
        const topBottomMap = data.isPinned
          ? data.vState.pinnedThreadsTopBottomMap
          : data.vState.mainThreadsTopBottomMap
        const [threadTop] = topBottomMap.get(thread.id)!
        const threadTopY = RenderEngine.heightToPosition(
          threadTop,
          data.isPinned ? data.vState.yStartPinned : data.vState.yStart,
        )
        const maxHeightWithHeader = data.isPinned
          ? data.vState.pinnedCanvasHeight
          : data.vState.mainCanvasHeight //+ this.settings.headerHeight

        /**
         * threadActiveLevelsFromChain used by Thread Shrunk Mode which displays only threads used in execution path
         * and favorite threads with only base/zero level
         **/
        const threadActiveLevelsFromChain = data.activeLevelsByThreadId.get(thread.id) ?? [0]
        /**
         * threadMaxLevel used to optimize cycle, so it doesn't go over level which we didn't need to render
         * by one of view mode
         **/
        const threadMaxLevel = data.isThreadShrunkModeEnabled
          ? Math.max(...threadActiveLevelsFromChain)
          : data.maxLevelByThreadId.get(thread.id)!
        const threadSliceByLevel = thread.slicesByLevel

        for (let level = 0; level <= threadMaxLevel; level++) {
          if (data.isThreadShrunkModeEnabled) {
            if (
              threadActiveLevelsFromChain.length &&
              !threadActiveLevelsFromChain.includes(level)
            ) {
              continue
            }
          }
          const slices = threadSliceByLevel.get(level) ?? []

          for (const slice of slices) {
            const isOutOfXorY =
              RenderEngine.isSliceOutByX(slice, data.hState.xStart, data.hState.xEnd) ||
              this.isSliceOutByY(slice, threadTopY, maxHeightWithHeader)
            if (isOutOfXorY) {
              continue
            }

            let isDimmed = false
            if (data.linkModeSlice != null) {
              if (slice.isCluster) {
                isDimmed = true
              } else {
                isDimmed = Boolean(
                  getConnectionError(
                    data.linkModeSlice,
                    slice,
                    data.psChartStore.sliceById,
                    data.psChartStore.traceAnalyzeStore.sliceLinksBySliceId,
                    NamedLinkType.SYNC,
                  ),
                )
              }
            }
            // It is not "else" case because the slice can be dimmed by "link mode" or by "search results" simultaneously
            if (data.psChartStore.searchState.searchResultsSet.size > 0) {
              isDimmed = !data.psChartStore.searchState.searchResultsSet.has(slice.id)
            }

            // dim all slices that are not connected to the links tree if Dim Disconnected Slices is enabled
            if (
              data.psChartStore.traceAnalyzeStore.selectedSlice &&
              data.psChartStore.shouldDimDisconnectedSlices
            ) {
              // if all paths are visible then do not dim non-MEP slices
              if (data.psChartStore.traceAnalyzeStore.shouldShowAllPaths) {
                isDimmed = !data.psChartStore.traceAnalyzeStore.allChainsIds.has(slice.id)
              } else {
                // if only MEP, dim non-MEP slices
                isDimmed = !data.psChartStore.traceAnalyzeStore.mainChainIdsWithParents.has(
                  slice.id,
                )
              }
            }
            this.prepareForRendering(
              thread,
              threadActiveLevelsFromChain,
              slice,
              data.psChartStore,
              sliceTextsToRender,
              isDimmed,
            )
          }
        }
      }
    }
    this.renderSliceTexts(sliceTextsToRender)
  }

  private renderThreadsText(data: RenderEngineData) {
    this.renderer.setSliceTextDrawingSettings(
      this.settings.palette.thread.text,
      this.settings.threads.fontStyle,
    )
    const measurer = new TextMeasurer(this.settings.threads.fontStyle)
    data.threads.forEach((item) => {
      const text = item.title
      const width = measurer.getTextWidth(text)
      const isPinned = data.psChartStore.traceAnalyzeStore.pinnedIdsSet.has(item.id)
      const y = data.psChartStore.getThreadY(item.id) - (isPinned ? 0 : data.vState.yStart) + 14
      this.context.fillText(text, width / 2 + 16, y)
    })
  }

  renderHoveredThreadHighlighting = (data: RenderEngineData) => {
    if (
      data.psChartStore.hoveredThreadId &&
      data.psChartStore.traceAnalyzeStore.favIdSet.has(data.psChartStore.hoveredThreadId) ===
        data.isPinned
    ) {
      const isFavorite = data.psChartStore.traceAnalyzeStore.favIdSet.has(
        data.psChartStore.hoveredThreadId,
      )
      const y = data.psChartStore.hoveredThreadY - (isFavorite ? 0 : data.vState.yStart)
      const height = data.psChartStore.traceAnalyzeStore.heightByThreadId.get(
        data.psChartStore.hoveredThreadId,
      )!
      this.renderer.fillRect(0, y, data.hState.width, height, colors.dark.dark1)
    }
  }

  private prepareForRendering(
    thread: Thread,
    threadActiveLevelsFromChain: number[],
    slice: Slice,
    psChartStore: PsChartStore,
    textsToRender: RenderTextParams[],
    isDimmed: boolean,
  ) {
    const { x, y, w, h } = getSliceVisibleRect(
      thread,
      threadActiveLevelsFromChain,
      slice,
      psChartStore,
    )

    if (w > this.settings.basicRenderer.minLengthForText) {
      textsToRender.push({
        text: getSliceTimelineTitle(slice),
        x,
        y: y + Math.floor(h / 2),
        width: w,
        textMeasurer: this.textMeasurer,
        active: !isDimmed,
        isUtility: thread.isUtility,
      })
    }

    if (slice.isNetworkRequest) {
      this.sliceLabelsToRender.set(slice.id, {
        x,
        y,
        width: w,
        type: RenderLabelTypes.NETWORK_REQUEST,
      })
    }

    const palette = psChartStore.chartSettings.renderEngine.palette.slice
    const color = isDimmed ? getSliceDimmedColor(slice.color, palette) : slice.color

    this.renderer.fillRect(x, y, w, h, color)
  }

  private renderSliceLabels(chainNodes: ChainNodeData[]) {
    if (this.sliceLabelsToRender.size === 0) {
      return null
    }

    const chainBorderTypeBySliceId = new Map<number, BorderType>()
    for (const node of chainNodes) {
      chainBorderTypeBySliceId.set(node.slice.id, node.borderType)
    }

    const palette = this.settings.palette.utility
    let backgroundColor

    const labelColor = palette.text
    this.renderer.setSliceTextDrawingSettings(labelColor, this.iconFontStyle)

    this.sliceLabelsToRender.forEach((labelParams, sliceId) => {
      if (chainBorderTypeBySliceId.has(sliceId)) {
        const borderType = chainBorderTypeBySliceId.get(sliceId)!
        backgroundColor = this.settings.palette.connectionLines[borderType]
      } else {
        backgroundColor = palette.regularColor
      }
      this.renderer.drawLabel(labelParams, backgroundColor, labelColor)
    })
  }

  private renderSliceTexts(textsToRender: RenderTextParams[]) {
    if (textsToRender.length === 0) {
      return null
    }

    textsToRender.forEach((textParams) => {
      const palette = textParams.isUtility
        ? this.settings.palette.utility
        : this.settings.palette.slice
      this.renderer.setSliceTextDrawingSettings(
        textParams.active ? palette.text : palette.textInactive,
        this.fontStyle,
      )
      this.renderer.drawEllipsizedText(textParams)
    })
  }

  private renderThreadsDividers(
    threads: Thread[],
    topBottomByThreadId: TopBottomByThreadId,
    canvasWidth: number,
    posY: number,
  ) {
    const threadsPath = new Path2D()
    threads.forEach((thread, key) => {
      if (key === threads.length - 1) {
        return null
      }
      const [, threadBottom] = topBottomByThreadId.get(thread.id)!
      threadsPath.rect(
        0,
        RenderEngine.heightToPosition(threadBottom - this.settings.threads.dividerHeight, posY),
        canvasWidth,
        this.settings.threads.dividerHeight,
      )
    })
    this.renderer.fillPath(threadsPath, this.settings.palette.thread.delimiter)
  }

  renderVideoPointer(hState: HorizontalState, vState: VerticalState, videoState: VideoPlayerState) {
    if (videoState.hasFullData) {
      const time = videoState.traceVideoPointerTimeNanos
      if (time >= hState.xEnd) {
        // don't show video pointer if it is out of the view
        return
      } else if (hState.xStart <= time && time <= hState.xEnd) {
        const path = new Path2D()
        let x = RenderEngine.timeToPosition(time, hState.xStart, hState.timePerPx)
        if (x === hState.width) {
          // To sync with global timeline limiter line
          x = x - 0.5
        }
        path.moveTo(x, 0)
        path.lineTo(x, vState.height)
        const color = this.settings.videoPointer.lineColor
        this.renderer.strokePath(path, color, this.settings.videoPointer.lineWidth)
      }
    }
  }

  private renderFlags(flagsState: FlagsState, hState: HorizontalState, vState: VerticalState) {
    if (flagsState.enabled && !flagsState.showLabels) {
      return
    }

    // Need to sort to put selected flag always higher in z-order when drawing
    const sortedFlags = [...flagsState.flags].sort((a) =>
      a.id === flagsState.selectedFlagId ? 1 : -1,
    )
    sortedFlags.forEach((flag) => {
      /** Could be improved with grouping and stroking by color,
       * but considering small amount of flags, it won't
       * bring any performance boost.
       */
      if (hState.xStart <= flag.time && flag.time <= hState.xEnd) {
        const path = new Path2D()
        const x = RenderEngine.timeToPosition(flag.time, hState.xStart, hState.timePerPx)
        path.moveTo(x, 0)
        path.lineTo(x, vState.height)
        const isSelected = flag.id === flagsState.selectedFlagId
        const flagColor =
          typeof flag.color === 'number' ? this.settings.flags.colors[flag.color] : flag.color
        const stateFlagColor = isSelected ? this.settings.flags.selectedColor : flagColor
        this.renderer.strokePath(path, stateFlagColor, this.settings.flags.strokeWidth)
      }
    })
  }

  private renderMeasurement(psChartStore: PsChartStore, points: MeasurementPoints) {
    const blockHeight = 14
    const borderRadius = 2
    const arrowWidth = 5
    const arrowHeight = 3
    const textBlockPaddingX = 8
    const textColor = '#000000'

    if (points && points.endTime !== undefined) {
      const { y, isMain } = points

      const startTime = points.startTime > points.endTime ? points.endTime : points.startTime
      const endTime = points.startTime > points.endTime ? points.startTime : points.endTime

      const startX = (startTime - psChartStore.hState.xStart) / psChartStore.hState.timePerPx
      const endX = (endTime - psChartStore.hState.xStart) / psChartStore.hState.timePerPx
      const canvasHeight = isMain
        ? psChartStore.vState.mainCanvasHeight
        : psChartStore.vState.pinnedCanvasHeight
      let startY = y - (isMain ? psChartStore.vState.yStart : 0)

      if (startY < blockHeight / 2) {
        startY = blockHeight / 2
      }
      if (startY > canvasHeight - blockHeight / 2) {
        startY = canvasHeight - blockHeight / 2
      }

      const lineWidth = endX - startX

      if (lineWidth < arrowWidth * 2) {
        return
      }

      const center = startX + lineWidth / 2
      const text = nanoToMeasurementString(lineWidth * psChartStore.hState.timePerPx)
      const textWidth = this.measurementLineTextMeasurer.getTextWidth(text)

      const linePath = new Path2D()
      linePath.moveTo(startX, startY)
      linePath.lineTo(endX, startY)
      this.renderer.strokePath(
        linePath,
        this.settings.measurementColor,
        this.settings.measurementStrokeWidth,
      )

      const leftArrowPath = new Path2D()
      leftArrowPath.moveTo(startX, startY)
      leftArrowPath.lineTo(startX + arrowWidth, startY - arrowHeight)
      leftArrowPath.lineTo(startX + arrowWidth, startY + arrowHeight)
      this.renderer.fillPath(leftArrowPath, this.settings.measurementColor)

      const rightArrowPath = new Path2D()
      rightArrowPath.moveTo(endX, startY)
      rightArrowPath.lineTo(endX - arrowWidth, startY - arrowHeight)
      rightArrowPath.lineTo(endX - arrowWidth, startY + arrowHeight)
      this.renderer.fillPath(rightArrowPath, this.settings.measurementColor)

      if (lineWidth > textWidth + textBlockPaddingX * 2 + arrowWidth * 2) {
        const blockWidth = textWidth + textBlockPaddingX * 2
        const blockX = center - blockWidth / 2
        const blockY = startY - blockHeight / 2
        const blockPath = new Path2D()

        blockPath.moveTo(blockX + borderRadius, blockY)
        blockPath.lineTo(blockX + blockWidth - borderRadius, blockY)
        blockPath.quadraticCurveTo(
          blockX + blockWidth,
          blockY,
          blockX + blockWidth,
          blockY + borderRadius,
        )
        blockPath.lineTo(blockX + blockWidth, blockY + blockHeight - borderRadius)
        blockPath.quadraticCurveTo(
          blockX + blockWidth,
          blockY + blockHeight,
          blockX + blockWidth - borderRadius,
          blockY + blockHeight,
        )
        blockPath.lineTo(blockX + borderRadius, blockY + blockHeight)
        blockPath.quadraticCurveTo(
          blockX,
          blockY + blockHeight,
          blockX,
          blockY + blockHeight - borderRadius,
        )
        blockPath.lineTo(blockX, blockY + borderRadius)
        blockPath.quadraticCurveTo(blockX, blockY, blockX + borderRadius, blockY)
        this.renderer.fillPath(blockPath, this.settings.measurementColor)
        this.renderer.setSliceTextDrawingSettings(textColor, this.measurementFontStyle)
        this.renderer.drawEllipsizedText({
          text,
          width: blockWidth,
          x: blockX,
          y: blockY + blockHeight / 2 - 1,
          textMeasurer: this.measurementLineTextMeasurer,
          active: true,
          isUtility: false,
        })
      }
    }
  }

  private renderGridLines(
    gridLines: number[],
    isPinned: boolean,
    hState: HorizontalState,
    vState: VerticalState,
  ) {
    const maxHeightWithHeader = isPinned ? vState.pinnedCanvasHeight : vState.mainCanvasHeight
    for (const time of gridLines) {
      const x = RenderEngine.timeToPosition(time, hState.xStart, hState.timePerPx)
      this.renderer.fillRect(
        x,
        0,
        this.settings.commonTimeline.scaleWidth,
        maxHeightWithHeader,
        this.settings.palette.timeline.delimiterLine,
      )
    }
  }

  private renderHover(
    flagsState: FlagsState,
    videoState: VideoPlayerState,
    hState: HorizontalState,
    vState: VerticalState,
  ) {
    const hoverTime = flagsState.hoverTime ? flagsState.hoverTime : videoState.hoverTime
    if (hoverTime && hState.xStart <= hoverTime && hoverTime <= hState.xEnd) {
      const path = new Path2D()
      const x = RenderEngine.timeToPosition(hoverTime, hState.xStart, hState.timePerPx)
      path.moveTo(x, 0)
      path.lineTo(x, vState.height)

      const { flags, ghostIndicator } = this.settings

      if (videoState.hoverTime) {
        this.renderer.strokePath(path, ghostIndicator.color, ghostIndicator.strokeWidth)
      } else if (flagsState.enabled) {
        this.renderer.strokePath(path, flags.hoverFlagColor, flags.strokeWidth)
      }
    }
  }

  private renderAnnotationsConnections(
    annotationsState: AnnotationsState,
    psChartStore: PsChartStore,
  ) {
    if (!annotationsState.featureState.enabled) {
      return
    }

    annotationsState.annotationsWithConnectedSlices.forEach((annotation) => {
      const actionBinding = annotation.action.binding
      if (actionBinding !== undefined && actionBinding?.sliceId !== undefined) {
        const hasDelay = AnnotationsStore.hasDelay(annotation.delay)
        this.renderPinBinding(hasDelay, actionBinding, annotationsState, psChartStore)
      }
      const reactionBinding = annotation.reaction.binding
      if (reactionBinding !== undefined && reactionBinding?.sliceId !== undefined) {
        const hasDelay = AnnotationsStore.hasDelay(annotation.delay)
        this.renderPinBinding(hasDelay, reactionBinding, annotationsState, psChartStore)
      }
    })
  }

  private renderPinBinding(
    hasDelay: boolean,
    pinBinding: AnnotationBindingDto,
    state: AnnotationsState,
    psChartStore: PsChartStore,
  ) {
    const active = state.selectedPinBinding === pinBinding || state.hoveredPinBinding === pinBinding
    const sliceId = pinBinding.sliceId!
    const { time } = pinBinding
    const slicesIds = psChartStore.traceAnalyzeStore.shouldShowAllPaths
      ? psChartStore.traceAnalyzeStore.allChainsIds
      : psChartStore.traceAnalyzeStore.mainChainIds
    const isSliceHidden =
      psChartStore.traceAnalyzeStore.showOnlyActiveLevelsMode && !slicesIds.has(sliceId)
    if (!isSliceHidden) {
      this.renderAnnotationToSliceConnection(psChartStore, active, hasDelay, sliceId, time)
    }
  }

  private renderAnnotationToSliceConnection(
    psChartStore: PsChartStore,
    active: boolean,
    hasDelay: boolean,
    sliceId: number,
    time: number,
  ) {
    const { hState } = psChartStore
    const { annotation } = this.settings

    const slice = psChartStore.sliceById.get(sliceId)!
    const thread = psChartStore.traceDataState.threadsById.get(slice.threadId)!
    const threadActiveLevels =
      psChartStore.traceAnalyzeStore.activeLevelsFromChainByThreadId.get(slice.threadId) ?? []

    const baseColor = hasDelay ? annotation.delayColor : annotation.pinColor
    const activeColor = hasDelay ? annotation.delayColor : annotation.pinHoveredColor

    const x = RenderEngine.timeToPosition(time, hState.xStart, hState.timePerPx)
    const { y } = getSliceVisibleRect(thread, threadActiveLevels, slice, psChartStore)
    let top = y + this.settings.headerHeight + 0.5 * this.settings.threads.blockHeight
    if (top < this.settings.headerHeight) {
      top = this.settings.headerHeight
    }

    const circle = new Path2D()
    const dotSize = active ? annotation.connectionActiveDotSize : annotation.connectionDotSize
    circle.ellipse(x, top, dotSize, dotSize, 0, 0, 360)
    this.renderer.fillRect(
      x - annotation.strokeWidth / 2,
      0,
      annotation.strokeWidth,
      top,
      active ? activeColor : baseColor,
    )
    this.renderer.fillPath(circle, annotation.pinColor)
    if (active) {
      const activeCircle = new Path2D()
      activeCircle.ellipse(
        x,
        top,
        annotation.connectionActiveDotSize,
        annotation.connectionActiveDotSize,
        0,
        0,
        360,
      )
      this.renderer.strokePath(activeCircle, activeColor, annotation.strokeWidth)
    }
  }
}
