import {
  FlowAutomationConfigDtoInstrumentedRunConditionDtoValue,
  FlowAutomationConfigDtoPriorityDtoValue,
} from 'api/models'
import {
  FlowAutomationConfigDtoInstrumentedRunConditionDto,
  FlowAutomationConfigDtoPriorityDto,
} from 'api/__generated__/api-constants'
import { capitalizeFirstLetter } from 'utils/stringUtils'
import { SelectOption } from 'components/Select'

export type InstrumentedRunConditionValue = FlowAutomationConfigDtoInstrumentedRunConditionDtoValue
export const InstrumentedRunCondition = FlowAutomationConfigDtoInstrumentedRunConditionDto

export const prettifyRunCondition = (runCondition: InstrumentedRunConditionValue): string => {
  if (runCondition === InstrumentedRunCondition.ON_ANY_CHANGE) {
    return capitalizeFirstLetter(runCondition.toLowerCase().replaceAll('_', ' '))
  } else {
    return capitalizeFirstLetter(runCondition.toLowerCase())
  }
}

export const RUN_CONDITION_OPTIONS: SelectOption<InstrumentedRunConditionValue>[] = [
  {
    value: InstrumentedRunCondition.NEVER,
    label: prettifyRunCondition(InstrumentedRunCondition.NEVER),
  },
  {
    value: InstrumentedRunCondition.ALWAYS,
    label: prettifyRunCondition(InstrumentedRunCondition.ALWAYS),
  },
  {
    value: InstrumentedRunCondition.ON_ANY_CHANGE,
    label: prettifyRunCondition(InstrumentedRunCondition.ON_ANY_CHANGE),
  },
]

export type PriorityValue = FlowAutomationConfigDtoPriorityDtoValue
export const Priority = FlowAutomationConfigDtoPriorityDto

export const PRIORITY_OPTIONS: SelectOption<PriorityValue>[] = [
  {
    value: Priority.LOW,
    label: capitalizeFirstLetter(Priority.LOW.toLowerCase()),
  },
  {
    value: Priority.REGULAR,
    label: capitalizeFirstLetter(Priority.REGULAR.toLowerCase()),
  },
  {
    value: Priority.HIGH,
    label: capitalizeFirstLetter(Priority.HIGH.toLowerCase()),
  },
]
