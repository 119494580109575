import { Trans } from 'react-i18next'
import { PRIVACY_LINK, TERMS_SELF_SERVE_LINK } from 'utils/links'
import { twMerge } from 'tailwind-merge'

type Props = {
  className?: string
  linkClassName?: string
}

export const TermsAndConditions = ({ linkClassName, className }: Props) => {
  return (
    <div className={twMerge('text-[12px] leading-[19px] text-gray-normal my-[24px]', className)}>
      <Trans
        i18nKey="auth.freeTrial.signUp.termsPrivacy"
        components={[
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={TERMS_SELF_SERVE_LINK}
            className={twMerge('text-white', linkClassName)}
          ></a>,
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={PRIVACY_LINK}
            className={twMerge('text-white', linkClassName)}
          ></a>,
        ]}
      />
    </div>
  )
}
