import { Slice } from 'components/ps-chart/models/Slice'
import { TreeNode } from 'components/ps-chart/stores/connections-store/LinksTree/TreeNode'
import { ReadonlySliceById } from 'components/ps-chart/stores/TraceDataStore'
import { PsChartStore } from 'components/ps-chart/PsChartStore'
import { SummaryDto } from 'components/cco/types'

// Extend ChainedSlice from Slice and SummaryDto
interface ChainedSlice extends Slice, SummaryDto {
  links: ChainedSlice[] // Always an array
}

// MergedSlice extends SummaryDto and includes title, links, and variant
export interface MergedSlice extends SummaryDto {
  title: string
  links: MergedSlice[]
  variant?: string // Optional variant identifier
}

// Function to build the ChainedSlice tree
export function getChainedSliceTree(node: TreeNode, sliceById: ReadonlySliceById): ChainedSlice {
  // Get the corresponding slice for the current node
  const slice = sliceById.get(node.sliceId)
  if (!slice) {
    throw new Error(`Slice with id ${node.sliceId} not found`)
  }

  // Base case: if no fromLinks, return the slice with an empty links array
  if (!node.fromLinks || node.fromLinks.length === 0) {
    return {
      ...slice,
      links: [],
      cpuTime: 0,
      traffic: 0,
      storage: 0,
      count: 1,
      initialStart: slice.start, // Initialize initialStart
    }
  }

  // Recursive case: create ChainedSlices for all fromLinks
  const links: ChainedSlice[] = node.fromLinks.map((link) => {
    return getChainedSliceTree(link.toTreeNode, sliceById)
  })

  // Return the ChainedSlice with its corresponding links array
  return {
    ...slice,
    links,
    cpuTime: 0,
    traffic: 0,
    storage: 0,
    count: 1, // Initialize count to 1 for the current slice
    initialStart: slice.start, // Initialize initialStart
  }
}

// Function to add summary data to the ChainedSlice tree
export function addChainSummary(slice: ChainedSlice): ChainedSlice {
  const ownDuration = slice.end - slice.start

  // The count remains 1, representing the occurrence of this slice

  // Recursively compute the cpuTime and initialStart for links
  let linksDuration = 0

  for (let i = 0; i < slice.links.length; i++) {
    slice.links[i] = addChainSummary(slice.links[i])
    linksDuration += slice.links[i].cpuTime ?? 0
  }

  const totalDuration = ownDuration + linksDuration

  // Set the summary properties directly on the slice
  slice.cpuTime = totalDuration
  slice.traffic = 0 // Adjust as needed
  slice.storage = 0 // Adjust as needed

  return slice
}

// Function to merge multiple ChainedSlices into a single MergedSlice
function mergeChainedSlices(chainedSlices: ChainedSlice[]): MergedSlice {
  const { title } = chainedSlices[0]

  // Sum the cpuTimes and counts of the current slices
  let totalCpuTime = 0
  let totalCount = 0 // This will represent the count for this specific title
  let minInitialStart = Number.MAX_SAFE_INTEGER

  const childSlicesByTitle = new Map<string, ChainedSlice[]>()

  // Collect child slices by their titles
  for (const slice of chainedSlices) {
    totalCpuTime += slice.cpuTime ?? 0
    totalCount += slice.count ?? 0
    minInitialStart = Math.min(minInitialStart, slice.initialStart ?? Number.MAX_SAFE_INTEGER)

    for (const child of slice.links) {
      const childTitle = child.title
      if (!childSlicesByTitle.has(childTitle)) {
        childSlicesByTitle.set(childTitle, [])
      }
      childSlicesByTitle.get(childTitle)!.push(child)
    }
  }

  const totalChains = chainedSlices.length

  // Determine which child titles are common across all chains
  const commonChildTitles: Set<string> = new Set()
  const titleOccurrences: Map<string, number> = new Map()

  for (const [childTitle, slices] of childSlicesByTitle.entries()) {
    titleOccurrences.set(childTitle, slices.length)
    if (slices.length === totalChains) {
      commonChildTitles.add(childTitle)
    }
  }

  const mergedLinks: MergedSlice[] = []

  // First, merge common child nodes
  for (const commonTitle of commonChildTitles) {
    const childGroup = childSlicesByTitle.get(commonTitle)!
    const mergedChild = mergeChainedSlices(childGroup)
    mergedLinks.push(mergedChild)
    // Remove from childSlicesByTitle to process variants later
    childSlicesByTitle.delete(commonTitle)
  }

  // If there are remaining child slices, they are divergent and need variants
  if (childSlicesByTitle.size > 0) {
    // Group the remaining slices by the combination of chains they appear in
    const variantGroups: Map<string, ChainedSlice[]> = new Map()
    const chainIndicesMap: Map<string, Set<number>> = new Map()

    let variantIndex = 0
    const variantLetters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'

    for (const [, slices] of childSlicesByTitle.entries()) {
      // Determine which chains this child appears in
      const chainIndices = new Set<number>()
      for (const slice of slices) {
        const parentIndex = chainedSlices.findIndex((parentSlice) =>
          parentSlice.links.includes(slice),
        )
        if (parentIndex !== -1) {
          chainIndices.add(parentIndex)
        }
      }

      // Create a key based on the chain indices
      const chainKey = Array.from(chainIndices).sort().join(',')

      // Assign a variant to this group if not already assigned
      if (!chainIndicesMap.has(chainKey)) {
        chainIndicesMap.set(chainKey, chainIndices)
        const variantLetter = variantLetters[variantIndex++] || `Variant${variantIndex}`
        variantGroups.set(variantLetter, [])
      }

      // Add the slices to the appropriate variant group
      const variantLetter = Array.from(variantGroups.keys())[variantGroups.size - 1]
      variantGroups.get(variantLetter)!.push(...slices)
    }

    // Merge the variant groups and assign variants
    for (const [variant, slices] of variantGroups.entries()) {
      // Group slices by title
      const slicesByTitle = new Map<string, ChainedSlice[]>()
      for (const slice of slices) {
        if (!slicesByTitle.has(slice.title)) {
          slicesByTitle.set(slice.title, [])
        }
        slicesByTitle.get(slice.title)!.push(slice)
      }

      // Merge slices with the same title and assign variant
      for (const [, groupedSlices] of slicesByTitle.entries()) {
        const mergedSlice = mergeChainedSlices(groupedSlices)
        mergedSlice.variant = variant
        mergedLinks.push(mergedSlice)
      }
    }
  }

  // Calculate total CPU time of children
  let totalCpuTimeOfChildren = 0
  for (const child of mergedLinks) {
    totalCpuTimeOfChildren += child.cpuTime ?? 0
  }

  // If totalCpuTimeOfChildren is less than totalCpuTime, add 'other' node
  if (totalCpuTime > totalCpuTimeOfChildren) {
    const otherCpuTime = totalCpuTime - totalCpuTimeOfChildren

    const otherNode: MergedSlice = {
      title: 'other',
      cpuTime: otherCpuTime,
      traffic: 0, // Adjust as needed
      storage: 0, // Adjust as needed
      count: 1, // 'other' node counts as 1 occurrence
      links: [],
      initialStart: minInitialStart, // Assign initialStart to otherNode
    }

    mergedLinks.push(otherNode)
  }

  return {
    title,
    cpuTime: totalCpuTime,
    traffic: 0, // Adjust as needed
    storage: 0, // Adjust as needed
    count: totalCount,
    links: mergedLinks,
    initialStart: minInitialStart, // Assign min initialStart
  }
}

// Function to generate the MergedSlice for given sliceIds
export function generateMergedStatsSlice(
  title: string,
  sliceIds: number[],
  psChartStore: PsChartStore,
): MergedSlice {
  const chainedSlices: ChainedSlice[] = []

  if (!sliceIds.length) {
    return {
      title,
      cpuTime: 0,
      traffic: 0,
      storage: 0,
      count: 0,
      links: [],
      initialStart: 0, // Default value
    }
  }

  for (const sliceId of sliceIds) {
    // Get the corresponding TreeNode for the sliceId
    const treeNode = psChartStore.traceAnalyzeStore.getLinksTreeBySliceId(sliceId)

    if (!treeNode) {
      throw new Error(`TreeNode not found for sliceId ${sliceId}`)
    }

    // Build the ChainedSlice tree
    let chainedSlice = getChainedSliceTree(treeNode, psChartStore.sliceById)

    // Compute the summary statistics and get the updated ChainedSlice
    chainedSlice = addChainSummary(chainedSlice)

    // Collect the ChainedSlices
    chainedSlices.push(chainedSlice)
  }

  // Merge the ChainedSlice trees into a single MergedSlice tree
  const mergedSlice = mergeChainedSlices(chainedSlices)

  return mergedSlice
}
