import React, { useEffect, useMemo } from 'react'
import { Outlet } from 'react-router-dom'
import { useQueryClient } from 'react-query'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react-lite'

import { Layout } from 'components/Layout'
import { useGuideNavigationRules } from 'components/guide/hooks/useGuideNavigationRules'
import { GuideStore } from 'components/guide/GuideStore'
import { Sidebar } from 'components/guide/Sidebar'
import { TechStackBreadcrumbs } from 'components/guide/TechStackBreadcrumbs'
import { ModuleProgressCircle } from 'components/ps-chart/ModuleProgressCircle'
import { useApi } from 'contexts/di-context'
import { useToaster } from 'hooks/useToaster'
import { GuideStepType } from 'components/guide/models'
import { Footer } from 'components/Footer'
import { BaseHeader } from 'components/header/BaseHeader'
import { SwitchButton } from 'components/header/SwitchButton'

export const PATH_GUIDE_ROUTER = '/guide'
export const PATH_GUIDE_SELECT_PLATFORM = `${PATH_GUIDE_ROUTER}/${GuideStepType.SelectPlatform}`
export const PATH_GUIDE_INSTRUMENT_AND_BUILD = `${PATH_GUIDE_ROUTER}/${GuideStepType.InstrumentAndBuild}/:platform`
export const PATH_GUIDE_RECORD_TRACE = `${PATH_GUIDE_ROUTER}/${GuideStepType.RecordTrace}/:platform`
export const PATH_GUIDE_VIEW_TRACE = `${PATH_GUIDE_ROUTER}/${GuideStepType.ViewTrace}/:platform`

interface GuidePageSubHeaderProps {
  guideStore: GuideStore
}

const GuidePageSubHeader = ({ guideStore }: GuidePageSubHeaderProps) => {
  const { t } = useTranslation()
  return (
    <div className="bg-dark-dark3 z-10 h-[64px] flex w-full">
      <ContentLayout additionalStyles="h-[100%] mb-[10px] items-end">
        <h1 className="w-[171px] text-[26px] font-[500] text-white pb-[10px]">
          {t('guidePage.freeTrialHeader')}
        </h1>
      </ContentLayout>
      <div className="flex h-[100%] pb-[10px] items-end pl-[91px] w-full">
        <TechStackBreadcrumbs guideStore={guideStore} />
      </div>
    </div>
  )
}

export const GuidePage = observer(function GuidePage() {
  const toaster = useToaster()
  const api = useApi()
  const queryClient = useQueryClient()

  const guideStore: GuideStore = useMemo(() => new GuideStore(api, queryClient), [api, queryClient])

  useGuideNavigationRules(guideStore)

  useEffect(() => {
    guideStore.fetchData().catch((error) => {
      toaster.error(error, 'guidePage.cantFindFreeTrialProject')
    })

    return () => {
      guideStore.destroy()
    }
  }, [guideStore, toaster])

  const header = useMemo(() => {
    return (
      <BaseHeader
        rightSideContent={<SwitchButton isLiveDemo={false} />}
        subHeader={<GuidePageSubHeader guideStore={guideStore} />}
      />
    )
  }, [guideStore])

  const footer = useMemo(() => <Footer />, [])

  if (!guideStore.isReady) {
    return (
      <Layout headerComponent={header}>
        <main className="flex flex-grow relative">
          <ModuleProgressCircle />
        </main>
      </Layout>
    )
  }

  return (
    <Layout headerComponent={header} footerComponent={footer}>
      <div className="flex flex-grow bg-dark-dark2">
        <ContentLayout>
          <div className="w-[171px] pt-[40px]">
            <Sidebar guideStore={guideStore} />
          </div>
        </ContentLayout>
        <div className="pl-[91px] w-full">
          <div className="max-w-[628px]">
            <Outlet context={{ guideStore }} />
          </div>
        </div>
      </div>
    </Layout>
  )
})

interface ContentLayoutProps {
  additionalStyles?: string
  children: React.ReactNode
}

export const ContentLayout = ({ children, additionalStyles }: ContentLayoutProps) => {
  return (
    <div
      className={`1280:w-[40%] 1680:w-[45%] 1920:w-[60%] pl-[40px] flex justify-end ${additionalStyles}`}
    >
      {children}
    </div>
  )
}
