import React, { useContext, useMemo } from 'react'
import { useFeatureFlag, FEATURE_FLAGS } from 'utils/feature-flags'

import { Layout } from 'components/Layout'
import { PsChart } from 'components/ps-chart/PsChart'
import {
  AnnotationsFeatureState,
  PsChartFeatures,
  psChartStoreContext,
} from 'components/ps-chart/PsChartStore'
import { useNavigationRules } from 'hooks/useNavigationRules'
import { BaseHeader } from 'components/header/BaseHeader'
import { PageTitle } from 'components/header/PageTitle'
import { Navbar } from 'components/Navbar'
import { TracePageBreadcrumbs } from 'components/breadcrumbs/TracePageBreadcrumbs'

export const PATH_CHART_FOLDER = 'traceviewer'
export const PATH_CHART = `/projects/:projectUrlName/flows/:flowProjectLocalId/traces/:traceProjectLocalId/${PATH_CHART_FOLDER}`
export const PATH_CHART_DIRECT = '/projects/:projectUrlName/traces/:traceProjectLocalId'

const defaultChartFeatures = (annotationsEnabled?: boolean): PsChartFeatures => ({
  flags: true,
  measurementTool: true,
  annotations: { enabled: !!annotationsEnabled, draggable: false } as AnnotationsFeatureState,
})

export const PsChartPage = () => {
  const psChartStore = useContext(psChartStoreContext)!

  const annotationsEnabled = useFeatureFlag(FEATURE_FLAGS.ANNOTATIONS)

  useNavigationRules()

  const header = useMemo(
    () => (
      <BaseHeader
        leftSideContent={<TracePageBreadcrumbs />}
        centerContent={<PageTitle titleKey="flameChart" />}
      />
    ),
    [],
  )
  const footer = useMemo(() => <Navbar />, [])

  if (annotationsEnabled === undefined) {
    return null
  }
  psChartStore.setChartFeatures(defaultChartFeatures(annotationsEnabled))

  return (
    <Layout
      headerComponent={header}
      footerComponent={footer}
      pageConfig={{ withoutStyledContent: true }}
    >
      <PsChart psChartStore={psChartStore} />
    </Layout>
  )
}
