import { ProjectRoleApi, ProjectRoleApiValue } from 'api/models'
import { TFunction } from 'react-i18next'

export const getProjectRoleName = (
  role?: ProjectRoleApiValue | null,
  t?: TFunction<'translation', undefined>,
) => {
  if (t) {
    switch (role) {
      case ProjectRoleApi.ADMIN:
        return t('user.projectRole.admin')
      case ProjectRoleApi.CONTRIBUTOR:
        return t('user.projectRole.contributor')
      default:
        return ''
    }
  }
  return ''
}
