import React from 'react'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react-lite'

import { TraceProcessingErrorCode } from 'api/__generated__/api-constants'
import { GuideStore } from 'components/guide/GuideStore'
import { getQualityActionKey, getQualityWarningKey } from 'components/traces/getQualityWording'

interface TraceErrorProps {
  errorMessage: string | React.ReactNode
  dataTestId?: string
}

export const ViewTraceError = observer(function ViewTraceError({
  guideStore,
}: {
  guideStore: GuideStore
}) {
  const { t } = useTranslation()

  const getTraceErrorProps = (store: GuideStore): TraceErrorProps => {
    const videoProcessingErrorCode = store.traceVideoMetadataDto?.processingErrorCode

    if (store.isVideoFramesNotFound) {
      return {
        errorMessage: t('guidePage.viewTrace.errors.videoFramesNotFound.title'),
        dataTestId: 'videoFramesNotFoundTitle',
      }
    } else if (store.isTraceQualityPoor && store.bestTrace != null) {
      const { qualityLabels } = store.bestTrace.qualityReport
      const ql = qualityLabels?.at(0)
      return {
        errorMessage: (
          <>
            <p>{t(getQualityWarningKey(ql))}</p>
            <p>{t(getQualityActionKey(ql))}</p>
          </>
        ),
        dataTestId: 'traceQualityErrorTitle',
      }
    } else if (videoProcessingErrorCode != null) {
      return {
        errorMessage: t(`psChart.error.video.serverSide.${videoProcessingErrorCode}`),
      }
    }

    switch (store.bestTrace?.processingErrorCode) {
      case TraceProcessingErrorCode.APP_ID_NOT_FOUND:
        return { errorMessage: t('traces.processingError.appIdNotFound') }
      case TraceProcessingErrorCode.UNSUPPORTED_FILE_FORMAT:
        return { errorMessage: t('traces.processingError.unsupportedFileFormat') }
      case TraceProcessingErrorCode.INTERNAL_SERVER_ERROR:
        return { errorMessage: t('traces.processingError.internalServerError') }
      default:
        return { errorMessage: t('guidePage.viewTrace.errors.general') }
    }
  }

  const { errorMessage, dataTestId } = getTraceErrorProps(guideStore)

  return (
    <div className="text-state-bad text-center" data-testid={dataTestId}>
      {errorMessage}
    </div>
  )
})
