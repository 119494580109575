import { PsChartStore } from 'components/ps-chart/PsChartStore'
import { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { Slice } from 'components/ps-chart/models/Slice'
import { usePopper } from 'react-popper'
import { VirtualElement } from '@popperjs/core'
import { BodyPortal } from 'components/BodyPortal'
import { ELEMENTS_IDS } from 'components/ps-chart/elementsIds'
import { getNetworkRequestURL, SLICE_ARGUMENT_CONTAINTS_URL } from 'components/ps-chart/utils/slice'
import { reaction } from 'mobx'

export const SliceRequestPreview = observer(function SliceRequestPreview({
  psChartStore,
}: {
  psChartStore: PsChartStore
}) {
  const { hoveredSliceId } = psChartStore
  const [state, setState] = useState<{ imageUrl: string; element: VirtualElement }>()
  const [previewElement, setPreviewElement] = useState<HTMLElement | null>(null)

  const { styles, attributes, update } = usePopper(state?.element, previewElement, {
    strategy: 'fixed',
    placement: 'left-end',
    modifiers: [
      {
        name: 'flip',
        options: {
          fallbackPlacements: ['right-end', 'top-start', 'top', 'bottom-start', 'bottom'],
        },
      },
      {
        name: 'preventOverflow',
        options: {
          rootBoundary: 'viewport',
        },
      },
    ],
  })

  useEffect(
    () =>
      reaction(
        () => psChartStore.hState.xStart,
        () => {
          if (update) {
            update()
          }
        },
      ),
    [psChartStore, update],
  )

  useEffect(() => {
    if (hoveredSliceId !== null) {
      const slice = psChartStore.sliceById.get(hoveredSliceId)
      if (slice && slice.isNetworkRequest) {
        let timeoutId: number | undefined = undefined
        const delayResolving = new Promise((resolve) => {
          timeoutId = window.setTimeout(() => {
            resolve(true)
          }, 1000)
        })

        const urlArgument = getNetworkRequestURL(slice)
        const url = urlArgument.match(SLICE_ARGUMENT_CONTAINTS_URL)?.[1]
        if (url) {
          const imageResolving = doesImageExist(url)
          const chartElement = document.getElementById(ELEMENTS_IDS.CHART_CANVAS_VIEW)
          imageResolving.exist.then((exist) => {
            if (exist && chartElement) {
              delayResolving.then(() => {
                setState({
                  imageUrl: url,
                  element: {
                    getBoundingClientRect: getSliceBoundingClientRect(
                      slice,
                      psChartStore,
                      chartElement,
                    ),
                  },
                })
              })
            }
          })
          return () => {
            imageResolving.abort()
            clearTimeout(timeoutId)
          }
        }
      }
    }

    setState(undefined)
  }, [hoveredSliceId, psChartStore])

  if (state) {
    return (
      <BodyPortal>
        <div
          className="flex bg-repeat bg-[url('/public/transparent-bg.png')] shadow-md"
          ref={setPreviewElement}
          style={styles.popper}
          {...attributes.popper}
        >
          <img
            src={state.imageUrl}
            alt="Slice preview"
            className="max-w-[400px] max-h-[400px] object-contain"
          />
        </div>
      </BodyPortal>
    )
  }

  return null
})

const doesImageExist = (url: string): { exist: Promise<boolean>; abort: () => unknown } => {
  const img = new Image()
  img.src = url
  return {
    exist: new Promise<boolean>((resolve) => {
      img.onload = () => resolve(true)
      img.onerror = () => resolve(false)
    }),
    abort: () => {
      img.src = ''
    },
  }
}

const getSliceBoundingClientRect =
  (slice: Slice, psChartStore: PsChartStore, canvasElement: HTMLElement) => (): DOMRect => {
    const chartRect = canvasElement.getBoundingClientRect()
    let sliceStart = slice.start
    if (sliceStart < psChartStore.hState.xStart) {
      sliceStart = psChartStore.hState.xStart
    }

    let sliceEnd = slice.end
    if (sliceEnd > psChartStore.hState.xEnd) {
      sliceEnd = psChartStore.hState.xEnd
    }

    const left = chartRect.left + psChartStore.getXByTime(sliceStart)
    const width = psChartStore.getXByTime(sliceEnd) - psChartStore.getXByTime(sliceStart)
    const sliceTopY = psChartStore.getSliceY(slice)
    const sliceBottomY = sliceTopY + psChartStore.chartSettings.renderEngine.threads.blockHeight

    const top = chartRect.top + sliceTopY
    const height = sliceBottomY - sliceTopY

    const result = {
      height: height,
      width: width,
      top: top,
      right: left + width,
      bottom: top + height,
      left: left,
      x: left,
      y: top,
    }
    return {
      ...result,
      toJSON: () => result,
    }
  }
