import { useAllProjectsQuery, useFlowsQuery } from 'hooks/useApiQuery'
import { LiteUploader } from 'components/lite/LiteUploader'
import { LiteTraces } from 'components/lite/LiteTraces'
import { LiteLayout, LiteLayoutContainer } from 'components/lite/LiteLayout'
import { LiteHeader } from 'components/lite/LiteHeader'
import { LiteFooter } from 'components/lite/LiteFooter'
import { useIsLiteUser } from 'components/lite/useIsLiteUser'
import { LiteBuildInstructions } from 'components/lite/LiteBuildInstructions'

export const PATH_LITE_TRACES = !!process.env.REACT_APP_IS_LITE ? '/traces' : '/lite/traces'

export const LiteTracesPage = () => {
  const isLiteUser = useIsLiteUser()

  const { data: projects } = useAllProjectsQuery()
  const projectUrlName = projects?.[0]?.urlName
  const { data: flows } = useFlowsQuery({ projectUrlName })
  const flowProjectLocalId = flows?.[0]?.projectLocalId
    ? String(flows?.[0]?.projectLocalId)
    : undefined

  return (
    <LiteLayout header={<LiteHeader />} footer={<LiteFooter />}>
      <LiteLayoutContainer>
        {isLiteUser === false ? (
          <div>
            This is not a CriticalPath account. Please log in on{' '}
            <a href="http://productscience.app" className="cursor-pointer underline">
              http://productscience.app
            </a>{' '}
            with this account.
          </div>
        ) : (
          <>
            {projectUrlName && flowProjectLocalId && (
              <>
                <LiteBuildInstructions projectUrlName={projectUrlName} />
                <LiteUploader
                  projectUrlName={projectUrlName}
                  flowProjectLocalId={flowProjectLocalId}
                />
                <div className="pt-12">
                  <LiteTraces
                    projectUrlName={projectUrlName}
                    flowProjectLocalId={flowProjectLocalId}
                  />
                </div>
              </>
            )}
          </>
        )}
      </LiteLayoutContainer>
    </LiteLayout>
  )
}
