import { Fragment } from 'react'
import classNames from 'classnames'
import { Listbox, Transition } from '@headlessui/react'

import { Icon } from 'components/Icon'
import { OptionsVariant, OptionType, SectionType } from './models'

interface ListboxProps<T> {
  value?: T
  defualtValue?: T
  disabled?: boolean
  by?: keyof T | ((a: T, z: T) => boolean)
  onSelect?: (index: number) => void
  horizontal?: boolean
  name?: string
  multiple?: boolean
  className?: string
  as?: keyof Pick<HTMLElementTagNameMap, 'ul' | 'select' | 'div'>
  buttonClass?: (open?: boolean, value?: T, disabled?: boolean) => string
  buttonChildren?: (open?: boolean, value?: T, disabled?: boolean) => JSX.Element | string
  buttonLabel?: (open?: boolean, value?: T, disabled?: boolean) => string
  menuClass?: string // style the options
  optionsVariant?: OptionsVariant // style the options
  menuSections: SectionType[]
  position?: { clientX: number; clientY: number }
  show?: (open?: boolean) => boolean | undefined | boolean
  optionAs?: keyof Pick<HTMLElementTagNameMap, 'a' | 'div' | 'li'>
  withSelect?: boolean
  inModal?: boolean
}

/**
 * Use this for <select /> boxes. Warning: do not use this for menus that open modals.
 * Try to prefer this dropdown as much as possible because it is the most accessible.
 */
export const ListBox = ({
  value,
  defualtValue,
  disabled,
  by,
  onSelect,
  horizontal,
  name,
  multiple,
  className,
  as,
  buttonClass,
  buttonChildren,
  buttonLabel,
  menuClass,
  optionsVariant,
  menuSections,
  optionAs,
  withSelect,
}: ListboxProps<string | number>) => {
  return (
    <div className="relative">
      <Transition as={Fragment} {...transitionProps} show={true}>
        <Listbox
          value={value}
          defaultValue={defualtValue}
          as={as || 'div'}
          disabled={disabled}
          by={by}
          onChange={onSelect}
          horizontal={horizontal}
          name={name}
          multiple={multiple}
          className={className}
        >
          {({ open, value: internalValue, disabled: internalDisabled }) => (
            <>
              <Listbox.Button
                as="button"
                className={classNames(
                  buttonClass && buttonClass(open, internalValue, internalDisabled),
                  'focus:outline-electro',
                )}
                aria-label={buttonLabel && buttonLabel(open, internalValue, internalDisabled)}
              >
                {buttonChildren && buttonChildren(open, internalValue, internalDisabled)}
              </Listbox.Button>
              <Listbox.Options
                className={classNames(
                  'absolute max-h-[500px] z-10 w-[200px] first:rounded-b-sm rounded-sm py-[8px] focus:outline-none',
                  optionsVariant === OptionsVariant.DARK ? 'bg-dark-dark1' : 'bg-dark-dark5',
                  menuClass,
                )}
              >
                {menuSections &&
                  menuSections.map((section: SectionType, sectionIndex: number) => (
                    <div key={sectionIndex}>
                      {section.name ? (
                        <div className="px-[16px]">
                          <span className="text-gray-faded text-small">{section.name}</span>
                        </div>
                      ) : null}
                      {section.options
                        ? section.options.map((option: OptionType, optionIndex: number) => (
                            <Listbox.Option
                              key={String(optionIndex)}
                              value={optionIndex}
                              as={optionAs || 'li'}
                              disabled={option.isDisabled}
                              data-testid={`${name}-${optionIndex}`}
                              className={({ active }) =>
                                `${
                                  active && 'bg-dark-dark2'
                                } hover:bg-dark-dark2 select-auto cursor-pointer relative transition-colors`
                              }
                              onClick={
                                option.isDisabled || typeof option.onClick !== 'function'
                                  ? () => {}
                                  : option.onClick
                              }
                            >
                              <>
                                {option.customRender ? (
                                  option.customRender
                                ) : (
                                  <div
                                    className={classNames(
                                      'inline-block px-[16px] py-[6px] text-small group-disabled:text-gray-dark cursor-pointer disabled:cursor-not-allowed',
                                      option.isDisabled ? 'text-gray-faded cursor-not-allowed' : '',
                                    )}
                                  >
                                    {option.image && (
                                      <img
                                        src={option.image.tq.url}
                                        className="w-[18px] h-[18px] inline-block mr-[6px]"
                                        aria-hidden="true"
                                      />
                                    )}
                                    {option.name}
                                  </div>
                                )}
                                {(withSelect || section.withSelect) && (
                                  <div
                                    className={classNames(
                                      'w-[20px] h-[20px] mr-[-6px] absolute top-0 right-[18px] transition-opacity',
                                      option.isSelect ? 'opacity-100' : 'opacity-0',
                                    )}
                                  >
                                    <Icon
                                      icon="check"
                                      className="absolute text-icon text-electro"
                                    />
                                  </div>
                                )}
                              </>
                            </Listbox.Option>
                          ))
                        : null}
                    </div>
                  ))}
              </Listbox.Options>
            </>
          )}
        </Listbox>
      </Transition>
    </div>
  )
}

export const transitionProps = {
  enter: 'transition ease-out duration-100',
  enterFrom: 'opacity-0',
  enterTo: 'opacity-100',
  leave: 'transition ease-in duration-100',
  leaveFrom: 'opacity-100',
  leaveTo: 'opacity-0',
}
