import { Outlet, useNavigate } from 'react-router-dom'

import { useAuth } from 'contexts/auth-context'

import { PATH_ROOT } from 'utils/links'

export function SkipAuth() {
  const auth = useAuth()
  const navigate = useNavigate()

  if (auth.isSignedIn) {
    navigate(PATH_ROOT)
  }

  return <Outlet />
}
