const registeredHandlers = new Set<(event: Event) => void>()

const onTopLevelClick = (event: Event) => {
  registeredHandlers.forEach((handler) => handler(event))
}

let isInitialized = false

/**
 * This util is used to add the very first top level capture phase click handler.
 * This can be helpful to intervene with logic of some third party libraries' top level event handling.
 */
export const initTopLevelClickHandling = () => {
  if (!isInitialized) {
    document.addEventListener('click', onTopLevelClick, true)
    isInitialized = true
  }
}

export const addTopLevelClickHandler = (handler: (event: Event) => void) =>
  registeredHandlers.add(handler)

export const removeTopLevelClickHandler = (handler: (event: Event) => void) =>
  registeredHandlers.delete(handler)
