import React, { useCallback, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import NProgress from 'nprogress'
import * as yup from 'yup'

import { useToaster } from 'hooks/useToaster'
import { Form, useForm, ValidationSchema } from 'components/Form/Form'
import { FormInput } from 'components/Form/FormInput'
import { Button } from 'components/Button'
import { Layout } from 'components/Layout'
import { SsoSignupRequireInfoResponse } from 'api/models'
import { useAuth } from 'contexts/auth-context'
import { usePreDefinedCompanyName } from 'hooks/usePreDefinedCompanyName'
import { Input } from 'components/Input'
import { WrongTeamToken } from 'components/free-trial/WrongTeamToken'
import { PATH_ROOT } from 'utils/links'
import { BaseHeader } from 'components/header/BaseHeader'
import { Footer } from 'components/Footer'

export const PATH_SIGN_UP_SSO = '/sign-up-sso'

type PageNavState = {
  ssoIdToken: string
  userData: SsoSignupRequireInfoResponse
  allowFreeTrial?: boolean
  teamToken?: string
}

export const SignUpSsoPage = () => {
  const { t } = useTranslation()
  const { signInSSO } = useAuth()
  const navigate = useNavigate()
  const toaster = useToaster()
  const location = useLocation()
  const navState: null | PageNavState = location.state
  const { preDefinedCompanyName, isTokenCheckFailed } = usePreDefinedCompanyName(
    navState?.teamToken,
  )
  const formContext = useForm<SignUpFormValues>({
    validationSchema: signUpSchema,
    formProps: {
      defaultValues: navState
        ? {
            name: navState.userData.name,
            lastName: navState.userData.lastName,
            companyName: navState.userData.companyName as string | undefined,
          }
        : undefined,
    },
  })

  useEffect(() => {
    if (preDefinedCompanyName == null) {
      return
    }
    formContext.setValue('companyName', preDefinedCompanyName)
    formContext.trigger()
  }, [formContext, preDefinedCompanyName])

  useEffect(() => {
    if (!navState) {
      navigate(PATH_ROOT)
    }
  }, [navState, navigate])

  const handleSubmit = useCallback(
    (values: SignUpFormValues) => {
      if (navState) {
        NProgress.start()

        signInSSO({
          data: {
            ssoData: {
              ssoIdToken: navState.ssoIdToken,
              signUpData: values,
              allowFreeTrial: navState.allowFreeTrial || false,
              teamToken: navState.teamToken,
            },
          },
          redirectPath: location.state?.from?.pathname ?? PATH_ROOT,
        })
          .catch((error) => {
            toaster.error(error)
          })
          .finally(() => {
            NProgress.done()
          })
      }
    },
    [navState, location, toaster, signInSSO],
  )

  const handleSubmitInvalid = useCallback(() => {
    toaster.error('auth.freeTrial.signUp.errors.checkFields')
  }, [toaster])

  const header = useMemo(() => <BaseHeader />, [])
  const footer = useMemo(() => <Footer />, [])

  return (
    <Layout headerComponent={header} footerComponent={footer}>
      <div className="flex min-h-full items-center justify-center bg-dark-dark3">
        <div className="w-full">
          {isTokenCheckFailed ? (
            <WrongTeamToken />
          ) : (
            <>
              <div className="text-center mb-[48px]">
                <h2 className="text-header-small mb-[8px]">{t('auth.signUpSSOPage.title')}</h2>
              </div>
              <div className="max-w-[320px] mx-auto">
                <Form<SignUpFormValues>
                  formContext={formContext}
                  onSubmit={handleSubmit}
                  onSubmitInvalid={handleSubmitInvalid}
                >
                  <FormInput<SignUpFormValues>
                    name="name"
                    containerClassName="mb-[24px]"
                    className="bg-gray-inputBG"
                    placeholder={t('auth.signUpSSOPage.firstName')}
                  />
                  <FormInput<SignUpFormValues>
                    name="lastName"
                    containerClassName="mb-[24px]"
                    className="bg-gray-inputBG"
                    placeholder={t('auth.signUpSSOPage.lastName')}
                  />
                  {preDefinedCompanyName != null ? (
                    <Input
                      name="companyName"
                      containerClassName="mb-[24px]"
                      className="bg-gray-inputBG"
                      placeholder={t('auth.signUpSSOPage.company')}
                      value={preDefinedCompanyName}
                      title={t('auth.freeTrial.signUp.preDefinedCompanyWarning')}
                      disabled
                    />
                  ) : (
                    <FormInput<SignUpFormValues>
                      name="companyName"
                      containerClassName="mb-[24px]"
                      className="bg-gray-inputBG"
                      placeholder={t('auth.signUpSSOPage.company')}
                    />
                  )}
                  <Button
                    className="w-full"
                    isSmall
                    type="submit"
                    disabled={formContext.formState.isValidating || !formContext.formState.isValid}
                  >
                    {t('auth.signUpSSOPage.button')}
                  </Button>
                </Form>
              </div>
            </>
          )}
        </div>
      </div>
    </Layout>
  )
}

interface SignUpFormValues {
  name: string
  lastName: string
  companyName: string
}

export const signUpSchema = yup.object<ValidationSchema<SignUpFormValues>>({
  name: yup.string().required(),
  lastName: yup.string().required(),
  companyName: yup.string().required(),
})
