/**
 * Temporary
 * todo: delete when guides integrated
 */

export const INITIAL_MARKDOWN_SIMPLE =
  '# Header 1\n' +
  '\n' +
  'Simple text\n' +
  '\n' +
  '## Header 2.0\n' +
  '\n' +
  'Para 1\n' +
  '\n' +
  '### Header 2.0.0\n' +
  '\n' +
  'This is header 3  content\n' +
  '\n' +
  '#### Header 2.0.0.0\n' +
  '\n' +
  'Some value\n' +
  '\n' +
  '##### Header 2.0.0.0.0\n' +
  '\n' +
  'Some value\n' +
  '\n' +
  '###### Header 2.0.0.0.0.0\n' +
  '\n' +
  'Smallest header looks like normal text\n' +
  '\n' +
  '### Header 2.0.2\n' +
  '\n' +
  'This is header 3  content\n' +
  '\n' +
  '## Header 2.1\n' +
  '\n' +
  'Para 1\n' +
  '\n' +
  'www.example.com, https://example.com, and contact@example.com.\n' +
  '\n' +
  'Para 3\n' +
  '\n' +
  '## Header 2.2\n' +
  '\n' +
  'Para 1\n' +
  '\n' +
  'Para 2\n' +
  '\n' +
  '## Header 2.3\n' +
  '\n' +
  '## Header 2.4\n' +
  '\n' +
  '## Header 2.5\n' +
  '\n' +
  '## Header 2.6\n' +
  '\n' +
  '**Ivan** bold, but _Alex_ italic'

export const INITIAL_MARKDOWN_WITH_REACT =
  '```groovy title="build.gradle"\n' +
  'buildscript {\n' +
  '    repositories {\n' +
  '        maven {\n' +
  '            url {{ projectId }} "https://artifactory.productscience.app/releases"\n' +
  '        }\n' +
  '    }\n' +
  '    dependencies { ... }  \n' +
  '}\n' +
  '\n' +
  'allprojects {{ testComponent }} {\n' +
  '    repositories {\n' +
  '        maven {\n' +
  '            url "https://artifactory.productscience.app/releases"\n' +
  '        }\n' +
  '    }\n' +
  '}\n' +
  '```\n' +
  ' {{ testComponent }} \n' +
  '\n' +
  'cool link [file]({{downloadFileLink}})'

// https://github.com/product-science/fe-docs
export const INITIAL_MARKDOWN =
  '# Gradle Build Instructions - Android\n' +
  '\n' +
  '## Credentials\n' +
  '\n' +
  'Download {{ androidRelease }} Product Science {{ projectId }} shared access credentials productscience.properties [file]({{downloadFileLink}} "download properties") or create manually it in the root directory of your project.\n' +
  '\n' +
  '```properties\n' +
  '{{ readToken }}\n' +
  '```\n' +
  '## Add Product Science maven repository\n' +
  '\n' +
  'In `build.gradle` add the maven build info to the repositories for project and subprojects:\n' +
  '\n' +
  '```groovy title="build.gradle"\n' +
  'buildscript {\n' +
  '    repositories {\n' +
  '        maven {\n' +
  '            url "https://artifactory.productscience.app/releases"\n' +
  '        }\n' +
  '    }\n' +
  '    dependencies { ... }\n' +
  '}\n' +
  '\n' +
  'allprojects {\n' +
  '    repositories {\n' +
  '        maven {\n' +
  '            url "https://artifactory.productscience.app/releases"\n' +
  '        }\n' +
  '    }\n' +
  '}\n' +
  '```\n' +
  '\n' +
  '```kotlin title="build.gradle.kts"\n' +
  'buildscript {\n' +
  '    repositories {\n' +
  '        maven {\n' +
  '            url "https://artifactory.productscience.app/releases"\n' +
  '        }\n' +
  '    }\n' +
  '    dependencies { ... }\n' +
  '}\n' +
  '\n' +
  'allprojects {\n' +
  '    repositories {\n' +
  '        maven {\n' +
  '            url "https://artifactory.productscience.app/releases"\n' +
  '        }\n' +
  '    }\n' +
  '}\n' +
  '```\n' +
  '\n' +
  'If the project is configured to prefer settings repositories maven source should be added to settings file:\n' +
  '\n' +
  '```groovy title="settings.gradle"\n' +
  '...\n' +
  'dependencyResolutionManagement {\n' +
  '    repositoriesMode.set(RepositoriesMode.FAIL_ON_PROJECT_REPOS)\n' +
  '    repositories {\n' +
  '        maven {\n' +
  '            url "https://artifactory.productscience.app/releases"\n' +
  '        }\n' +
  '    }\n' +
  '}\n' +
  '```\n' +
  '\n' +
  '```kotlin title="settings.gradle.kts"\n' +
  '...\n' +
  'dependencyResolutionManagement {\n' +
  '    repositoriesMode.set(RepositoriesMode.FAIL_ON_PROJECT_REPOS)\n' +
  '    repositories {\n' +
  '        maven {\n' +
  '            url "https://artifactory.productscience.app/releases"\n' +
  '        }\n' +
  '    }\n' +
  '}\n' +
  '```\n' +
  '\n' +
  '\n' +
  'In another case, if `allprojects` is not present in top level `build.gradle` then add it in the top of the file.\n' +
  '\n' +
  '\n' +
  '## Add Product Science plugin to `classpath`\n' +
  '\n' +
  '```groovy title="build.gradle"\n' +
  'buildscript {\n' +
  '    repositories { ... }\n' +
  '    dependencies {\n' +
  '        classpath "com.productscience.transformer:transformer-plugin:{{ androidRelease }}"\n' +
  '        classpath "com.productscience.transformer:transformer-instrumentation:{{ androidRelease }}"\n' +
  '    }\n' +
  '}\n' +
  '...\n' +
  '```\n' +
  '\n' +
  '```kotlin title="build.gradle.kts"\n' +
  'buildscript {\n' +
  '    repositories { ... }\n' +
  '    dependencies {\n' +
  '        classpath("com.productscience.transformer:transformer-plugin:{{ androidRelease }}")\n' +
  '        classpath("com.productscience.transformer:transformer-instrumentation:{{ androidRelease }}")\n' +
  '    }\n' +
  '}\n' +
  '...\n' +
  '```\n' +
  '\n' +
  '**Please label your build with the PSi Plugin Version from above i.e.**  \n' +
  '`MyAppPSi0.9.1.apk`\n' +
  '**so our AI can learn how its dynamic instrumentation is performing on the build.**\n' +
  '\n' +
  '## Apply the Product Science Plugin\n' +
  '\n' +
  'Apply plugin to `app/build.gradle`\n' +
  '\n' +
  '```groovy title="app/build.gradle"\n' +
  'plugins {\n' +
  '    id "com.android.application"\n' +
  '    id "kotlin-android"\n' +
  '}\n' +
  'apply plugin: "com.productscience.transformer.plugin"\n' +
  '...\n' +
  '```\n' +
  '\n' +
  '```kotlin title="app/build.gradle.kts"\n' +
  'plugins {\n' +
  '    id("com.android.application")\n' +
  '    id("kotlin-android")\n' +
  '    id("com.productscience.transformer.plugin")\n' +
  '}\n' +
  '...\n' +
  '```\n' +
  '\n' +
  '\n' +
  '## Add Proguard rules\n' +
  '\n' +
  'If the application uses obfuscation/shrinking add a new ProGuard rule to your project. To achieve it add the next line to the R8/ProGuard configuration file:\n' +
  '\n' +
  '```proguard title="proguard-rules.pro."\n' +
  '-keep class com.productscience.transformer.module.** { *; }\n' +
  '```\n' +
  '\n' +
  'Your project may use the other proguard file name.\n' +
  '\n' +
  'More information about R8/ProGuard configuration can be found here:\n' +
  '[https://developer.android.com/studio/build/shrink-code](https://developer.android.com/studio/build/shrink-code)\n' +
  '\n' +
  '----\n' +
  '\n' +
  '## Enabling the plugin by build type\n' +
  '\n' +
  'For plugin versions greater than **0.12.1**,\n' +
  'you can integrate Product Science pipeline into your gradle build\n' +
  'selectively apply the plugin to a given build type by adding a `productScience` block\n' +
  'at the top level of your `app/build.gradle` file.\n' +
  '\n' +
  'Inside the proguard block, add a block corresponding to the build type (must have the same name) and set `enabled` to `true`.\n' +
  '\n' +
  '```groovy title="app/build.gradle"\n' +
  'plugins {\n' +
  '    id "com.android.application"\n' +
  '    id "kotlin-android"\n' +
  '}\n' +
  'apply plugin: "com.productscience.transformer.plugin" \n' +
  'productScience {\n' +
  '    psiRelease {\n' +
  '        enabled true\n' +
  '    }\n' +
  '}\n' +
  '\n' +
  'android {\n' +
  '    ...\n' +
  '    buildTypes {\n' +
  '        psiRelease {\n' +
  '            minifyEnabled true\n' +
  '        }\n' +
  '        release {\n' +
  '            minifyEnabled true\n' +
  '        }\n' +
  '    }\n' +
  '}\n' +
  '```\n' +
  '\n' +
  '```kotlin title="app/build.gradle.kts"\n' +
  'plugins {\n' +
  '    id("com.android.application")\n' +
  '    id("kotlin-android")\n' +
  '    id("com.productscience.transformer.plugin")\n' +
  '}\n' +
  '\n' +
  'productScience {\n' +
  '    create("psiRelease") {\n' +
  '        isEnabled = true\n' +
  '    }\n' +
  '}\n' +
  '\n' +
  'android {\n' +
  '    ...\n' +
  '    buildTypes {\n' +
  '        create("psiRelease") {\n' +
  '            isMinifyEnabled = true\n' +
  '        }\n' +
  '\n' +
  '        getByName("release") {\n' +
  '            isMinifyEnabled = true\n' +
  '        }\n' +
  '    }\n' +
  '}\n' +
  '```\n' +
  '\n' +
  'If the `productScience` block is missing or empty, the plugin will be applied to all build types.\n' +
  'If one or more build types appear in the `productScience` block,\n' +
  'the plugin will be applied only to those build types that have `enabled` set to true. \n'

export const INITIAL_MARKDOWN_ADOPTED =
  '# CICD Integration with Gradle\n' +
  '\n' +
  'Product Science suggests two main approach for integration Gradle plugin in CICD. \n' +
  '\n' +
  '## Build Variant \n' +
  '\n' +
  '* [Example App](https://github.com/product-science/demoapps/tree/main/cicd-examples/android-buildvariant)\n' +
  '* [Example GitHub workflow](https://github.com/product-science/demoapps/blob/main/.github/workflows/cicd-buildvariant.yml)\n' +
  '* [Build Variant docs](https://product-science.github.io/android/gradle/#enabling-the-plugin-by-build-type)\n' +
  '\n' +
  '\n' +
  'This approach is based on [Gradle build variant](https://developer.android.com/studio/build/build-variants).\n' +
  'A build variant `psiRelease` is created for instrumented version of the app. \n' +
  'PS Plugin is applied only for this build variant.\n' +
  '\n' +
  '\n' +
  '```kotlin title="app/build.gradle"\n' +
  'plugins {\n' +
  '    id("com.android.application")\n' +
  '    id("kotlin-android")\n' +
  '    id("com.productscience.transformer.plugin")\n' +
  '}\n' +
  '\n' +
  'productScience {\n' +
  '    create("psiRelease") {\n' +
  '        isEnabled = true\n' +
  '    }\n' +
  '}\n' +
  '\n' +
  'android {\n' +
  '    ...\n' +
  '    buildTypes {\n' +
  '        create("psiRelease") {\n' +
  '            ...\n' +
  '        }\n' +
  '\n' +
  '        getByName("debug") {\n' +
  '            ...\n' +
  '        }\n' +
  '    }\n' +
  '}\n' +
  '```\n' +
  '\n' +
  'Build can be triggered with gradle command:\n' +
  '```\n' +
  './gradlew assemblePsiRelease\n' +
  '```\n' +
  '\n' +
  '\n' +
  '## Conditional plugin apply\n' +
  '\n' +
  '* [Example App](https://github.com/product-science/demoapps/tree/main/cicd-examples/android-condition)\n' +
  '* [Example GitHub workflow](https://github.com/product-science/demoapps/blob/main/.github/workflows/cicd-condition.yml)\n' +
  '\n' +
  '\n' +
  'This approach is based on conditional applying of gradle plugin.\n' +
  'PS Plugin is applied only when the value of `USE_PSTOOL` environment variable is true.\n' +
  '\n' +
  '\n' +
  '```kotlin title="app/build.gradle"\n' +
  'plugins {\n' +
  '    id("com.android.application")\n' +
  '    id("kotlin-android")\n' +
  '\n' +
  '    val usePSPlugin: Boolean = System.getenv("USE_PSTOOL").toBoolean()\n' +
  '    if (usePSPlugin) {\n' +
  '        id("com.productscience.transformer.plugin")\n' +
  '    }\n' +
  '}\n' +
  '```\n' +
  '\n' +
  'Build can be triggered with gradle command:\n' +
  '```\n' +
  'USE_PSTOOL=true ./gradlew assembleRelease\n' +
  '```'

// https://user-images.githubusercontent.com/1157437/225666876-f911def6-930b-451a-86f4-c6264ef3f1f3.png
// https://user-images.githubusercontent.com/1157437/225666895-413613e1-2711-4956-8c8b-192a7aed3c39.png

export const QUICK_START_PART_3_DESCRIPTION =
  '## Part 3: Intro to Process x Annotations\n' +
  '\n' +
  'When you open up a trace, particularly a new one or ones that you didn’t record, chances are you will get overwhelmed and lost looking at thousands of functions that happens under a minute. This is where the Process x Annotations screens comes in place.\n' +
  '\n' +
  '![01](https://user-images.githubusercontent.com/1157437/225666876-f911def6-930b-451a-86f4-c6264ef3f1f3.png)\n' +
  '\n' +
  'Here you can connect the annotations you made in the previous screen to slices. Joining video to slices together provides solid visual cues that simply cannot be gained from the code alone.\n' +
  '\n' +
  '###### Slices? What are they?\n' +
  '\n' +
  'The visual representation of a code’s function/process. The length of the slice indicates how long it takes that process to execute.\n' +
  '\n' +
  'The width of the slice represents the duration of the executed process and its position indicates the start time.\n' +
  '\n' +
  '![02](https://user-images.githubusercontent.com/1157437/225666895-413613e1-2711-4956-8c8b-192a7aed3c39.png)\n'

export const QUICK_START_PART_3_GUIDE =
  '### Try it today\n' +
  '\n' +
  '- Drag the current time indicator (CTI) {insert pic} to a reaction\n' +
  '- Use its body like a vertical ruler to locate the corresponding slice that indicates significant UI changes\n' +
  '- Click on the slice to see its details shown on the right side\n' +
  '- Click the {link icon} to connect the annotation to a slice\n' +
  '- Repeat the same for reaction\n' +
  '\n' +
  'Once you’re ready, let’s go to the next screen - Trace Viewer'
