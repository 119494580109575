import Konva from 'konva'

export function createStage(containerId: string, width: number, height: number): Konva.Stage {
  return new Konva.Stage({
    container: containerId,
    width: width,
    height: height,
  })
}

export function createRect(
  x: number,
  y: number,
  width: number,
  height: number,
  color?: string,
): Konva.Rect {
  return new Konva.Rect({
    x: x,
    y: y,
    width: width,
    height: height,
    fill: color,
  })
}

export function createLine(points: Array<number>, color: string, width: number): Konva.Line {
  return new Konva.Line({
    points: points,
    stroke: color,
    strokeWidth: width,
  })
}

export function createText(
  x: number,
  y: number,
  text: string,
  color: string,
  size: number,
  fontFamily: string,
): Konva.Text {
  return new Konva.Text({
    x: x,
    y: y,
    text: text,
    fill: color,
    fontSize: size,
    fontFamily: fontFamily,
  })
}
