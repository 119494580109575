import { AnnotationDto } from 'api/models'
import { Icon } from 'components/Icon'
import { AnnotationsStore, PinType } from 'components/ps-chart/stores/AnnotationsStore'
import { useTranslation } from 'react-i18next'
import { Button, ButtonTextColorVariantEnum, ButtonVariantEnum } from 'components/Button'
import { VideoPlayerState } from 'components/ps-chart/stores/VideoPlayerStore'
import { ActionTooltip } from 'components/ActionTooltip'

interface AnnotationListItemViewProps {
  annotation: AnnotationDto
  type: PinType
  mapped: boolean
  isActive: boolean
  onUpdateAnnotation: () => void
  onDeleteAnnotation: () => void
  onPlaceOnTimeline: () => void
  videoPlayerState: VideoPlayerState
}

export const AnnotationListItemView = (props: AnnotationListItemViewProps) => {
  const {
    annotation,
    type,
    mapped,
    isActive,
    onUpdateAnnotation,
    onDeleteAnnotation,
    onPlaceOnTimeline,
  } = props
  const { t } = useTranslation()

  const title = AnnotationsStore.getPinTitle(annotation, type)
  const description = AnnotationsStore.getPinDescription(annotation, type)!
  const timeString = AnnotationsStore.getPinTimeString(annotation, type)!
  const binding = AnnotationsStore.getBinding(annotation, type)
  const isBoundToTimeline = AnnotationsStore.isBoundToTimeline(annotation, type)

  const isAssignedToSlice = binding?.sliceId !== undefined

  return (
    <div className="px-[16px] flex flex-col justify-between min-h-[90px]">
      <div className="absolute right-[16px] pt-[12px]">
        {isActive && (
          <>
            {mapped && (
              <Icon
                onClick={onUpdateAnnotation}
                icon="small-edit"
                className="mr-[12px] hover:cursor-pointer hover:text-electro"
                as="button"
                aria-label={t('components.hints.showPrevHintAriaLabel')}
              />
            )}
            <Icon
              icon="bin-small"
              onClick={onDeleteAnnotation}
              className="hover:cursor-pointer hover:text-electro"
              as="button"
              aria-label={t('components.hints.showPrevHintAriaLabel')}
            />
          </>
        )}
      </div>

      <div className="pt-[12px]">
        <div className="text-normal font-medium max-w-[240px] leading-[20px] break-words">
          {title}
        </div>
        {description && (
          <div className="mt-[8px] text-gray-normal leading-[16px] mb-[12px] break-words">
            {description}
          </div>
        )}
      </div>

      {type === PinType.REACTION && AnnotationsStore.hasDelay(annotation.delay) && (
        <div className="my-[8px] border-state-bad border-[1px] rounded-sm px-[2px] w-fit text-gray-normal text-[12px] leading-4">
          {t('annotations.body.withDelay')}
        </div>
      )}

      {mapped ? (
        <div className="flex justify-between mt-[8px]">
          <div className="text-gray-faded">
            {isAssignedToSlice && (
              <>
                <Icon icon="lock" className="mr-[2px]" />
                <span>{t('annotations.body.assignedToSlice')}</span>
              </>
            )}
          </div>

          <div className="mb-[8px] text-gray-faded">{timeString}</div>
        </div>
      ) : (
        !isBoundToTimeline && (
          <div className="flex w-full items-center justify-center flex-col mb-[12px] mt-[16px]">
            <ActionTooltip
              disabled={props.videoPlayerState.hasFullData}
              place="bottom"
              tooltipId="noVideoWarning"
            >
              <Button
                variant={ButtonVariantEnum.Text}
                textColorVariant={ButtonTextColorVariantEnum.Primary}
                isSmall={false}
                withIcon={false}
                setFontSize={12}
                disabled={!props.videoPlayerState.hasFullData}
                onClick={onPlaceOnTimeline}
              >
                {t('annotations.create.placeOnTimeline')}
              </Button>
            </ActionTooltip>
          </div>
        )
      )}
    </div>
  )
}
