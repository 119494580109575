import { ProgressBar } from 'components/ProgressBar'
import { ProgressCircle } from 'components/common/util/ProgressCircle'
import { VideoDataStoreStatus } from 'components/ps-chart/stores/VideoDataStore'
import { getFileSize } from 'utils/getFileSize'

const { UPLOADING, PROCESSING, DELETING, DOWNLOADING } = VideoDataStoreStatus

export const VideoProgress = ({
  fileSize,
  progress,
  status,
}: {
  fileSize: number
  progress: number
  status: VideoDataStoreStatus
}) => {
  if (progress > 0 && status === UPLOADING) {
    return (
      <div className="w-full min-w-[200px] mt-4">
        <ProgressBar setWidth={progress} />
        <span className="w-full flex justify-between pt-2">
          <div className="text-mini">
            {getFileSize(fileSize * (progress / 100))}&nbsp;of&nbsp;
            {getFileSize(fileSize)}
          </div>
          <div className="text-mini">{progress}%</div>
        </span>
      </div>
    )
  } else if ([PROCESSING, DOWNLOADING, DELETING].includes(status)) {
    return (
      <div className="w-full flex justify-center pb-4">
        <ProgressCircle className="" />
      </div>
    )
  }
  return <div className="min-h-[40px]" />
}
