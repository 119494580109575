import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'
import { twMerge } from 'tailwind-merge'

type Props = {
  /** Browser page title */
  pageTitle?: string
  children?: React.ReactNode
  footer?: React.ReactNode
  header?: React.ReactNode
  isDark?: boolean
  className?: string
  bodyClassName?: string
}

export const LiteLayoutContainer = ({
  children,
  className,
}: {
  children: React.ReactNode
  className?: string
}) => {
  return <div className={twMerge('font-inter tracking-tighter mx-[5%]', className)}>{children}</div>
}

export const LiteLayout = ({
  children,
  header,
  footer,
  pageTitle,
  className,
  bodyClassName,
}: Props) => {
  const { t } = useTranslation()
  return (
    <div
      className={twMerge(
        'overflow-hidden',
        'flex min-h-full flex-col items-stretch bg-white text-black',
        className,
      )}
    >
      <Helmet>
        <title>{pageTitle || t('lite.meta.title')}</title>
        <meta name="keywords" content={t('lite.meta.keywords')} />
        <meta name="description" content={t('lite.meta.description')} />

        <meta property="og:title" content={t('lite.meta.title')} />
        <meta property="og:description" content={t('lite.meta.description')} />
        <meta property="og:image" content={t('lite.meta.image')} />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" />

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={t('lite.meta.title')} />
        <meta name="twitter:description" content={t('lite.meta.description')} />
        <meta name="twitter:image" content={t('lite.meta.image')} />

        <meta name="robots" content="index, follow" />
      </Helmet>
      <div>{header}</div>
      <div className={twMerge('flex-grow', bodyClassName)}>{children}</div>
      <div>{footer}</div>
    </div>
  )
}
