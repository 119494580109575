import { twMerge } from 'tailwind-merge'

export const enum TableSummaryView {
  TOTAL,
  AVERAGE,
  COST,
}

export const TableStateToggleButton = ({
  value: currentState,
  onChange,
}: {
  value: TableSummaryView
  onChange: (state: TableSummaryView) => void
}) => {
  // Function to handle toggle
  const handleToggle = (state: TableSummaryView) => () => onChange(state)

  const textStyles = [
    'z-[5]',
    'w-1/3 h-full',
    'even:border-x-[1px] even:border-[#B3B3B3]',
    'flex items-center justify-center',
    'text-[12px] leading-[17px] font-semibold',
    'transition-text duration-300',
  ]

  const textColor = (state: TableSummaryView) =>
    state === currentState ? 'text-[#0F0F0F]' : 'text-[#B3B3B3]'
  const togglePosition = (state: TableSummaryView) => {
    switch (state) {
      case TableSummaryView.TOTAL:
        return 'translate-x-0'
      case TableSummaryView.AVERAGE:
        return 'translate-x-[100%]'
      case TableSummaryView.COST:
        return 'translate-x-[200%]'
    }
  }
  return (
    <div
      className={twMerge(
        'relative',
        'w-[456px] h-[32px]',
        'flex',
        'bg-gray-300 rounded-[4px] border-[1px] border-[#B3B3B3]',
        'overflow-hidden',
        'transition-all duration-300',
      )}
    >
      <div
        className={twMerge(
          'absolute top-0',
          'w-1/3 h-full',
          'bg-[#B3B3B3]',
          togglePosition(currentState),
          'transition-transform duration-300',
        )}
      ></div>
      <div
        className={twMerge(...textStyles, textColor(TableSummaryView.TOTAL))}
        onClick={handleToggle(TableSummaryView.TOTAL)}
      >
        Total amount
      </div>
      <div
        className={twMerge(...textStyles, textColor(TableSummaryView.AVERAGE))}
        onClick={handleToggle(TableSummaryView.AVERAGE)}
      >
        Average amount
      </div>
      <div
        className={twMerge(...textStyles, textColor(TableSummaryView.COST))}
        onClick={handleToggle(TableSummaryView.COST)}
      >
        Cost
      </div>
    </div>
  )
}
