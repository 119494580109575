import React, { useCallback, useEffect, useRef, useState } from 'react'
import { autorun } from 'mobx'
import { observer } from 'mobx-react-lite'

import type { PsChartStore } from 'components/ps-chart/PsChartStore'
import { VideoTimelineRender } from 'components/video-timeline/VideoTimelineRender'
import { useAnalytics } from 'contexts/di-context'

export const VideoTimeline = observer(function VideoTimeline({
  psChartStore,
}: {
  psChartStore: PsChartStore
}) {
  const containerId = 'videoTimeline'
  const containerRef = useRef<HTMLDivElement>(null)
  const [render, setRender] = useState<VideoTimelineRender>()
  const analytics = useAnalytics()

  const onTimeWindowChange = useCallback(
    (start: number, end: number) => {
      psChartStore.hState.applyWindowChange(start, end)
      analytics.track('aggregation-view-used')
    },
    [psChartStore, analytics],
  )

  useEffect(() => {
    if (!psChartStore.isLoaded) {
      return
    }

    const newRender = new VideoTimelineRender(
      containerId,
      containerRef,
      psChartStore.hState.xMin,
      psChartStore.hState.xMax,
      psChartStore.videoPlayerStore,
      { onTimeWindowChange },
    )
    setRender(newRender)
    newRender.addEventListeners()
    const disposers = [
      () => newRender.removeEventListeners(),
      autorun(
        () => {
          newRender.renderContent(
            psChartStore.videoTimelineStore.isExtractionInProgress,
            psChartStore.videoTimelineStore.startXPx,
            psChartStore.videoTimelineStore.width,
            psChartStore.videoTimelineStore.canvas,
          )
        },
        { name: 'autorun @ VideoTimeline -> renderContent' },
      ),
    ]

    return () => disposers.forEach((dispose) => dispose())
  }, [psChartStore, psChartStore.isLoaded, onTimeWindowChange])

  useEffect(
    () =>
      autorun(
        () => {
          const { isLoaded } = psChartStore
          const { width } = psChartStore.hState
          const { xStart } = psChartStore.hState
          const { xEnd } = psChartStore.hState
          if (isLoaded) {
            render?.updateState(width, xStart, xEnd)
          }
        },
        { name: 'autorun @ VideoTimeline -> updateState' },
      ),
    [psChartStore, render],
  )

  return <div id={containerId} className="h-[80px]" ref={containerRef} />
})
