import React, { ReactNode } from 'react'
import { Tab } from '@headlessui/react'

export const TabLabel = ({ children }: { children: ReactNode }) => (
  <Tab className="w-[90px]">
    {({ selected }) => {
      const selectedClasses = selected
        ? 'bg-dark-dark3 text-white font-medium'
        : 'bg-dark-dark5 text-gray-normal font-normal'
      return (
        <div className={`pl-[24px] pr-[24px] pt-[10px] pb-[11px] text-small ${selectedClasses}`}>
          {children}
        </div>
      )
    }}
  </Tab>
)
