import {
  AnnotationIdAndType,
  PinType,
  ReadonlyAnnotations,
} from 'components/ps-chart/stores/AnnotationsStore'
import { createRef, RefObject, useCallback, useEffect, useState } from 'react'

export const useArrows = (
  items: AnnotationIdAndType[],
  elRefs: RefObject<HTMLLIElement>[],
  id: number,
) => {
  const findIndex = (idAndType: AnnotationIdAndType) => {
    return items.findIndex((item) => {
      return item.type === idAndType.type && item.id === id
    })
  }

  const actionIndex = findIndex({ id: id, type: PinType.ACTION })

  const reactionIndex = findIndex({ id: id, type: PinType.REACTION })

  const calcArrowWidth =
    reactionIndex === actionIndex + 1 ? 1 : (items?.length / 2 || 0) - actionIndex

  const [heightArrow, setHeightArrow] = useState(0)

  const calcArrowHeight = useCallback(() => {
    const actionEl = elRefs[actionIndex]?.current
    const reactionEl = elRefs[reactionIndex]?.current
    if (actionEl && reactionEl) {
      const actionRect = actionEl.getBoundingClientRect()
      const calcActionTop = actionRect?.top + actionRect?.height / 2
      const reactionRect = reactionEl.getBoundingClientRect()
      const calcReactionTop = reactionRect?.top + reactionRect?.height / 2
      setHeightArrow(calcReactionTop - calcActionTop || 0)
    }
  }, [elRefs, actionIndex, reactionIndex])

  useEffect(() => {
    calcArrowHeight()
  }, [calcArrowHeight])

  useEffect(() => {
    setTimeout(() => {
      calcArrowHeight()
    }, 100)
  }, [calcArrowHeight])

  return { calcArrowWidth, heightArrow }
}

export const useArrowElements = (mappedAnnotations: ReadonlyAnnotations) => {
  // these are for the arrows
  const [elRefs, setElRefs] = useState<RefObject<HTMLLIElement>[]>([])

  // these are for the arrows as well
  const arrLength = mappedAnnotations.length * 2
  useEffect(() => {
    setElRefs((prev) =>
      Array(arrLength)
        .fill(undefined)
        .map((_, i) => prev[i] || createRef()),
    )
  }, [arrLength])

  return { elRefs }
}
