import { useMemo } from 'react'
import { useOutletContext } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import { Trans } from 'react-i18next'

import { Markdown } from 'components/guide/markdown/Markdown'
import { MarkdownConfig, MD_STYLES_DEFAULT } from 'components/guide/markdown/MarkdownConfig'

import { NextButton } from 'components/guide/NextButton'
import { CompleteStepWarning } from 'components/guide/CompleteStepWarning'
import { CreateFlowForm } from 'components/guide/CreateFlowForm'
import { GuidePageOutletContext } from 'pages/guide/GuidePageOutletContext'
import { GuideStepType } from 'components/guide/models'

const headerOne = 'text-[26px] mt-[40px] mb-[32px] text-white font-[500] leading-[39px]'
const headerTwo = 'text-[16px] mb-[16px] text-white font-[500] leading-[24px]'
const headerThree = 'text-[14px] mb-[14px] text-white font-[500] leading-[24px]'
const parentSummary =
  'text-[20px] mb-[16px] text-white font-[500] leading-[30px] hover:cursor-pointer'
const childSummary =
  'text-[14px] mb-[16px] text-gray-normal font-[400] leading-[21px] hover:cursor-pointer'
const childDetails = 'ml-[20px] text-[14px] text-gray-normal leading-[21px] font-[400]'
const listItem = 'ml-[35px] mb-[16px]'
const list = 'list-disc list-outside marker:text-dark-dark6'
const paragraph = 'text-[14px] text-gray-normal leading-[21px] font-[400] mb-[16px]'

export const RecordTrace = observer(function RecordTrace() {
  const { guideStore } = useOutletContext<GuidePageOutletContext>()

  const recordTraceMarkdown = useMemo(() => {
    if (!guideStore.recordTrace) {
      return null
    }

    const styles = {
      ...MD_STYLES_DEFAULT,
      header: {
        ...MD_STYLES_DEFAULT.header,
        h1: { otherClassName: headerOne, marginClassName: '' },
        h2: { otherClassName: parentSummary, marginClassName: '' },
        h3: { otherClassName: headerTwo, marginClassName: childDetails },
        h4: { otherClassName: headerThree, marginClassName: '' },
        h5: { otherClassName: headerTwo, marginClassName: '' },
        h6: { otherClassName: childSummary, marginClassName: childDetails },
      },
      para: { className: paragraph },
      list: { className: list },
      listItem: { className: listItem },
    }

    const config: MarkdownConfig = {
      headerLevelsToCollapse: [2, 3],
      styles,
      headerLevelsToExpand: [4, 5],
    }

    const templateValues = new Map()
    templateValues.set('CreateFlowForm', <CreateFlowForm />)

    return (
      <Markdown markdown={guideStore.recordTrace} templateValues={templateValues} config={config} />
    )
  }, [guideStore.recordTrace /* , t */])

  const isInstrumentAndBuildCompleted = Boolean(
    guideStore.steps.find((step) => step.type === GuideStepType.InstrumentAndBuild)?.completed,
  )

  return (
    <div className="py-[40px]">
      {!isInstrumentAndBuildCompleted && (
        <CompleteStepWarning>
          <Trans i18nKey={'guidePage.pleaseCompleteStep'} values={{ step: 2 }} />
        </CompleteStepWarning>
      )}
      {recordTraceMarkdown}
      <NextButton />
    </div>
  )
})
