import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Modal } from 'components/Modal'
import { RaMvpStore } from 'components/regression-analysis-mvp/RaMvpStore'
import { useToaster } from 'hooks/useToaster'
import NProgress from 'nprogress'
import { SettingsCheckBox } from 'components/flows/ra/settings/modal/SettingsCheckBox'
import { observer } from 'mobx-react-lite'
import { RaUserNotificationConfigDtoTopicDtoValue } from 'api/__generated__/api-types'
import { RaUserNotificationConfigDtoTopicDto } from 'api/__generated__/api-constants'

interface NotificationsModalProps {
  isOpen: boolean
  onClose: () => void
  raStore: RaMvpStore
}

export const NotificationsModal = observer(function NotificationsModal(
  props: NotificationsModalProps,
) {
  const { t } = useTranslation()
  const toaster = useToaster()

  const { isOpen, onClose, raStore } = props

  const [onRunFinished, setOnRunFinished] = useState(false)
  const [onFailure, setOnFailure] = useState(false)
  const [onRegression, setOnRegression] = useState(false)
  const [onProgression, setOnProgression] = useState(false)
  const [onChange, setOnChange] = useState(false)
  useEffect(() => {
    if (isOpen) {
      const topics = raStore.notificationTopics
      setOnRunFinished(topics.includes(RaUserNotificationConfigDtoTopicDto.RUN_FINISHED))
      setOnFailure(topics.includes(RaUserNotificationConfigDtoTopicDto.RUN_FAILED))
      setOnRegression(topics.includes(RaUserNotificationConfigDtoTopicDto.REGRESSION_DETECTED))
      setOnProgression(topics.includes(RaUserNotificationConfigDtoTopicDto.PROGRESSION_DETECTED))
      setOnChange(topics.includes(RaUserNotificationConfigDtoTopicDto.CHANGE_DETECTED))
    }
  }, [isOpen, raStore])

  const currentTopics = useCallback((): RaUserNotificationConfigDtoTopicDtoValue[] => {
    const topics: RaUserNotificationConfigDtoTopicDtoValue[] = []
    if (onRunFinished) {
      topics.push(RaUserNotificationConfigDtoTopicDto.RUN_FINISHED)
    }
    if (onFailure) {
      topics.push(RaUserNotificationConfigDtoTopicDto.RUN_FAILED)
    }
    if (onRegression) {
      topics.push(RaUserNotificationConfigDtoTopicDto.REGRESSION_DETECTED)
    }
    if (onProgression) {
      topics.push(RaUserNotificationConfigDtoTopicDto.PROGRESSION_DETECTED)
    }
    if (onChange) {
      topics.push(RaUserNotificationConfigDtoTopicDto.CHANGE_DETECTED)
    }
    return topics
  }, [onChange, onFailure, onProgression, onRegression, onRunFinished])

  const onSubmit = useCallback(() => {
    NProgress.start()
    const topics = currentTopics()
    raStore
      .changeNotificationTopics(topics)
      .then(() => onClose())
      .catch((reason) => {
        toaster.error(reason, t('ra.flow.settings.notifications.error'))
      })
      .finally(() => NProgress.done())
  }, [currentTopics, raStore, onClose, toaster, t])

  return (
    <Modal
      title={t('ra.flow.settings.notifications.dialogTitle')}
      isOpen={isOpen}
      onClose={onClose}
      actionButton={{
        children: t('ra.flow.settings.notifications.change'),
        onClick: onSubmit,
      }}
    >
      <div className="w-full text-small text-gray-normal space-y-4">
        <span>{t('ra.flow.settings.notifications.dialogDescription')}</span>
        <SettingsCheckBox
          label={'Every run is finished'}
          value={onRunFinished}
          onChange={(value) => setOnRunFinished(value)}
        />
        <SettingsCheckBox
          label={'On any failure'}
          value={onFailure}
          onChange={(value) => setOnFailure(value)}
        />
        <SettingsCheckBox
          label={'On any change (regression or progression)'}
          value={onChange}
          onChange={(value) => setOnChange(value)}
        />
        <SettingsCheckBox
          label={'Every time a regression is detected'}
          value={onRegression}
          onChange={(value) => setOnRegression(value)}
        />
        <SettingsCheckBox
          label={'Every time a progression is detected'}
          value={onProgression}
          onChange={(value) => setOnProgression(value)}
        />
      </div>
    </Modal>
  )
})
