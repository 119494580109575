/**
 * Original: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Object/freeze
 * @param object
 */
export function deepFreeze<T extends Record<string, unknown>>(object: T) {
  // Retrieve the property names defined on object
  const propNames = Reflect.ownKeys(object)

  // Freeze properties before freezing self
  for (const name of propNames) {
    const value = object[name as string]

    if ((value && typeof value === 'object') || typeof value === 'function') {
      deepFreeze(value as Record<string, unknown>)
    }
  }

  return Object.freeze(object)
}
