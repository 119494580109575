import { useEffect, useState } from 'react'

export function useTraceFocus(tableRowRef: React.RefObject<HTMLAnchorElement>) {
  const [isHovered, setIsHovered] = useState(false)

  useEffect(() => {
    const handleDocumentFocus = (e: FocusEvent) => {
      if (tableRowRef.current && !tableRowRef.current.contains(e.target as Node)) {
        setIsHovered(false)
      } else {
        setIsHovered(true)
      }
    }

    const handleDocumentHover = (e: MouseEvent) => {
      if (tableRowRef.current && !tableRowRef.current.contains(e.target as Node)) {
        setIsHovered(false)
      } else {
        setIsHovered(true)
      }
    }

    document.addEventListener('focusin', handleDocumentFocus)
    document.addEventListener('mouseover', handleDocumentHover)

    return () => {
      document.removeEventListener('focusin', handleDocumentFocus)
      document.removeEventListener('mouseover', handleDocumentHover)
    }
  }, [tableRowRef])

  return isHovered
}
