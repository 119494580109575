import { AnnotationDto } from 'api/models'
import { KonvaAnnotation } from 'components/ps-chart/local-timeline/annotations/KonvaAnnotation'
import { AnnotationsSettings } from 'components/ps-chart/local-timeline/annotations/AnnotationsSettings'
import { AnnotationIdAndType, PinType } from 'components/ps-chart/stores/AnnotationsStore'
import { AnnotationsFeatureState } from 'components/ps-chart/PsChartStore'

export class AnnotationElement {
  annotation: AnnotationDto
  konvaAnnotation: KonvaAnnotation

  constructor(
    parentWidth: number,
    parentHeight: number,
    annotation: AnnotationDto,
    settings: AnnotationsSettings,
    featureState: AnnotationsFeatureState,
  ) {
    this.annotation = annotation
    this.konvaAnnotation = new KonvaAnnotation(
      annotation.id,
      parentWidth,
      parentHeight,
      settings,
      featureState,
    )
  }

  updateAnnotation(
    parentWidth: number,
    parentHeight: number,
    annotation: AnnotationDto,
    hoveredId: AnnotationIdAndType | null,
    selectedId: AnnotationIdAndType | null,
    featureState: AnnotationsFeatureState,
  ) {
    this.annotation = annotation
    const hoveredType = hoveredId && this.annotation.id === hoveredId.id ? hoveredId.type : null
    const selectedType = selectedId && this.annotation.id === selectedId.id ? selectedId.type : null
    this.konvaAnnotation.updateState(
      parentWidth,
      parentHeight,
      annotation,
      hoveredType,
      selectedType,
      featureState,
    )
  }

  idAndType(type: PinType): AnnotationIdAndType {
    return { id: this.annotation.id, type: type }
  }
}
