import { TFunction } from 'i18next'
import { exhaustiveGuard } from 'utils/misc'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'
import React from 'react'
import { BuildViewModel, RaMvpStore } from 'components/regression-analysis-mvp/RaMvpStore'
import { observer } from 'mobx-react-lite'
import { capitalizeFirstLetter } from 'utils/stringUtils'
import { prettifyDate } from 'components/flows/ra/runs/runUtils'

const enum BuildCol {
  UPLOAD_ID,
  NON_INSTRUMENTED_BUILD_FILE_NAME,
  INSTRUMENTED_BUILD_FILE_NAME,
  NAME,
  DESCRIPTION,
  SOURCE_CONTROL_ID,
  SOURCE_CONTROL_ISO_TIMESTAMP,
  UPLOAD_DATE,
  UPLOAD_STATE,
}

const BUILD_COLS: BuildCol[] = [
  BuildCol.UPLOAD_ID,
  BuildCol.NON_INSTRUMENTED_BUILD_FILE_NAME,
  BuildCol.INSTRUMENTED_BUILD_FILE_NAME,
  BuildCol.NAME,
  BuildCol.DESCRIPTION,
  BuildCol.SOURCE_CONTROL_ID,
  BuildCol.SOURCE_CONTROL_ISO_TIMESTAMP,
  BuildCol.UPLOAD_DATE,
  BuildCol.UPLOAD_STATE,
]

interface BuildsGridProps {
  raStore: RaMvpStore
}

export const BuildGrid = observer(function BuildGrid(props: BuildsGridProps) {
  const { raStore } = props
  return (
    <>
      <div
        className={classNames('grid')}
        style={{
          gridTemplateColumns: BUILD_COLS.map((col) => widthByCol(col)).join(' '),
        }}
      >
        <Head />
      </div>
      <div
        className={classNames('grid auto-rows-[minmax(50px,max-content)]')}
        style={{
          gridTemplateColumns: BUILD_COLS.map((col) => widthByCol(col)).join(' '),
        }}
      >
        {raStore.buildsVm.map((build, index) => (
          <Row key={index} build={build} />
        ))}
      </div>
    </>
  )
})

const Head = () => {
  const { t } = useTranslation()
  return (
    <>
      {BUILD_COLS.map((col, index) => {
        return (
          <div
            key={index}
            className={classNames(
              'px-4 py-2 bg-dark-dark3 text-small text-gray-normal',
              isCenteredCol(col) && 'text-center',
            )}
          >
            <span className="block truncate">{titleByCol(t, col)}</span>
          </div>
        )
      })}
    </>
  )
}

const Row = ({ build }: { build: BuildViewModel }) => {
  return (
    <>
      {BUILD_COLS.map((col, index) => {
        return (
          <div
            key={index}
            className={classNames(
              'flex items-center px-4 py-2 text-normal text-gray-normal',
              isCenteredCol(col) && 'justify-center',
            )}
          >
            <span className="line-clamp-3 break-words">{valueByCol(build, col)}</span>
          </div>
        )
      })}
    </>
  )
}

const titleByCol = (t: TFunction, col: BuildCol) => {
  let localizationName = ''
  switch (col) {
    case BuildCol.UPLOAD_ID:
      localizationName = 'uploadId'
      break
    case BuildCol.NON_INSTRUMENTED_BUILD_FILE_NAME:
      localizationName = 'nonInstrumentedBuildFileName'
      break
    case BuildCol.INSTRUMENTED_BUILD_FILE_NAME:
      localizationName = 'instrumentedBuildFileName'
      break
    case BuildCol.NAME:
      localizationName = 'name'
      break
    case BuildCol.DESCRIPTION:
      localizationName = 'description'
      break
    case BuildCol.SOURCE_CONTROL_ID:
      localizationName = 'sourceControlId'
      break
    case BuildCol.SOURCE_CONTROL_ISO_TIMESTAMP:
      localizationName = 'sourceControlIsoTimestamp'
      break
    case BuildCol.UPLOAD_DATE:
      localizationName = 'uploadDate'
      break
    case BuildCol.UPLOAD_STATE:
      localizationName = 'uploadState'
      break
    default:
      exhaustiveGuard(col)
  }
  return t(`ra.flow.build.${localizationName}.label`)
}

const valueByCol = (build: BuildViewModel, col: BuildCol) => {
  switch (col) {
    case BuildCol.UPLOAD_ID:
      return build.contextId
    case BuildCol.NON_INSTRUMENTED_BUILD_FILE_NAME:
      return build.nonInstrumentedFileName
    case BuildCol.INSTRUMENTED_BUILD_FILE_NAME:
      return build.instrumentedFileName
    case BuildCol.NAME:
      return build.name
    case BuildCol.DESCRIPTION:
      return build.description
    case BuildCol.SOURCE_CONTROL_ID:
      return build.gitCommit?.substring(0, 8)
    case BuildCol.SOURCE_CONTROL_ISO_TIMESTAMP:
      return prettifyDate(build.gitTime)
    case BuildCol.UPLOAD_DATE:
      return new Date(build.uploadDate).toLocaleString()
    case BuildCol.UPLOAD_STATE:
      return capitalizeFirstLetter(build.uploadState.toLowerCase())
    default:
      exhaustiveGuard(col)
  }
}

const widthByCol = (col: BuildCol) => {
  switch (col) {
    case BuildCol.UPLOAD_ID:
      return '85px'
    case BuildCol.NON_INSTRUMENTED_BUILD_FILE_NAME:
      return '240px'
    case BuildCol.INSTRUMENTED_BUILD_FILE_NAME:
      return '240px'
    case BuildCol.NAME:
      return '240px'
    case BuildCol.DESCRIPTION:
      return '240px'
    case BuildCol.SOURCE_CONTROL_ID:
      return '100px'
    case BuildCol.SOURCE_CONTROL_ISO_TIMESTAMP:
      return '170px'
    case BuildCol.UPLOAD_DATE:
      return '180px'
    case BuildCol.UPLOAD_STATE:
      return '150px'
    default:
      exhaustiveGuard(col)
  }
}

const isCenteredCol = (col: BuildCol): boolean => {
  switch (col) {
    case BuildCol.UPLOAD_ID:
    case BuildCol.SOURCE_CONTROL_ID:
    case BuildCol.SOURCE_CONTROL_ISO_TIMESTAMP:
    case BuildCol.UPLOAD_STATE:
    case BuildCol.UPLOAD_DATE:
      return true
    case BuildCol.NON_INSTRUMENTED_BUILD_FILE_NAME:
    case BuildCol.INSTRUMENTED_BUILD_FILE_NAME:
    case BuildCol.NAME:
    case BuildCol.DESCRIPTION:
      return false
    default:
      exhaustiveGuard(col)
  }
}
