import { useFlowTracesQuery } from 'hooks/useApiQuery'
import { Link } from 'react-router-dom'
import { getTraceUrl } from 'pages/lite/LitePsChartPage'
import { twMerge } from 'tailwind-merge'
import { useTranslation } from 'react-i18next'

type Props = {
  projectUrlName: string
  flowProjectLocalId: string
}

const formattedDate = (date: string) =>
  new Date(date).toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  })

const formattedTime = (date: string) =>
  new Date(date).toLocaleTimeString('en-US', {
    hour: '2-digit',
    minute: '2-digit',
    hour12: true,
  })

export const LiteTraces = ({ projectUrlName, flowProjectLocalId }: Props) => {
  const { t } = useTranslation()
  const { data: traces } = useFlowTracesQuery({ projectUrlName, flowProjectLocalId })

  if (!traces?.length) {
    return null
  }

  const reversedTraces = [...traces].sort(
    (a, b) => new Date(b.dateCreated).getTime() - new Date(a.dateCreated).getTime(),
  )

  const videoStatus = {
    IN_PROGRESS: { icon: '🪚', title: t('lite.videoStatus.processing') },
    FINISHED: { icon: '✔️', title: t('lite.videoStatus.finished') },
    FAILED: { icon: '❌', title: t('lite.videoStatus.failed') },
  }

  return (
    <section className="mb-20">
      <div className="sm:block hidden">
        <h2 className="text-header-big font-semibold mb-4 z-20 relative">Your traces</h2>
        <table>
          <thead>
            <tr className="text-left">
              <th className="px-4 py-2">Trace Name</th>
              <th className="px-4 py-2">Video</th>
              <th className="px-4 py-2">App Id</th>
              <th className="px-4 py-2">Device</th>
              <th className="px-4 py-2">Date</th>
            </tr>
          </thead>
          <tbody>
            {reversedTraces.map(
              ({
                projectLocalId,
                name,
                processingState,
                dateCreated,
                appId,
                device,
                videoState,
              }) => (
                <tr
                  key={projectLocalId}
                  className={twMerge(
                    'border-t',
                    processingState !== 'FINISHED' && 'text-gray-normal',
                  )}
                >
                  <td className="px-4 py-2">
                    {processingState === 'FINISHED' ? (
                      <Link
                        to={getTraceUrl(projectLocalId)}
                        className="cursor-pointer hover:text-electro"
                      >
                        {name}
                      </Link>
                    ) : (
                      <span>{name}</span>
                    )}
                  </td>
                  <td
                    className="px-4 py-2"
                    title={videoState ? videoStatus[videoState].title : undefined}
                  >
                    {videoState && videoStatus[videoState].icon}
                  </td>
                  <td className="px-4 py-2">{appId}</td>
                  <td className="px-4 py-2">{device}</td>
                  <td className="px-4 py-2">
                    {formattedDate(dateCreated)} {formattedTime(dateCreated)}
                  </td>
                </tr>
              ),
            )}
          </tbody>
        </table>
      </div>
    </section>
  )
}
