import { Navigate } from 'react-router-dom'
import { useFeatureFlag } from 'utils/feature-flags'
import type { FeatureFlags } from 'utils/feature-flags'

type Props = {
  /** The name of the flag */
  flag: FeatureFlags
  /** The path to redirect if the flag is not enabled. Defaults to the root path `/` */
  redirectTo?: string
  children: JSX.Element
}

/**
 * Guard route by the feature flag. If feature flag is disabled, it will redirect to the root `/`
 * or to the `redirectTo` path if it is set.
 *
 * Example:
 * ```jsx
 * import { FeatureFlagRoute, FEATURE_FLAGS } from 'utils/feature-flags'
 * ...
 * <FeatureFlagRoute flag={FEATURE_FLAGS.SELF_SERVE} redirectTo={PATH_REQUEST_INVITE}>
 *  <SignUpTrialPage />
 * </FeatureFlagRoute>
 * ```
 *
 * TODO: make an option to render 404 error instead of redirect.
 */
export const FeatureFlagRoute = ({ flag, children, redirectTo = '/' }: Props) => {
  const isFlagEnabled = useFeatureFlag(flag)

  if (isFlagEnabled === undefined) {
    return null
  }

  if (isFlagEnabled) {
    return children
  }

  return <Navigate to={redirectTo} state={{ from: location }} />
}
