import { PsChartStore } from 'components/ps-chart/PsChartStore'
import React, { useCallback, useState } from 'react'
import { PerfMeasurerType } from './PerfMeasurerType'
import { PerfStaticMeasurer } from './PerfStaticMeasurer'
import { PerfDynamicMeasurer } from './PerfDynamicMeasurer'
import { MeasureButton } from './MeasureButton'

export const PerfMeasurer = ({ psChartStore }: { psChartStore: PsChartStore }) => {
  const [perfMeasurerType, setPerfMeasurerType] = useState<PerfMeasurerType>(
    PerfMeasurerType.DYNAMIC,
  )

  const isStatic = perfMeasurerType === PerfMeasurerType.STATIC

  const switchMeasurerType = useCallback(() => {
    if (isStatic) {
      setPerfMeasurerType(PerfMeasurerType.DYNAMIC)
    } else {
      setPerfMeasurerType(PerfMeasurerType.STATIC)
    }
  }, [isStatic])

  return (
    <div className="text-small pl-3">
      {isStatic && <PerfStaticMeasurer psChartStore={psChartStore} />}
      {!isStatic && <PerfDynamicMeasurer psChartStore={psChartStore} />}
      <MeasureButton text={isStatic ? 'Dynamic' : 'Static'} onClick={switchMeasurerType} />
    </div>
  )
}
