import { ListItem } from 'components/traces/copyTrace/models'
import type { TeamDto, TeamProjectsSummaryDto, FlowDto } from 'api/models'

export function getTeamListItems(teamsData: TeamDto[] | undefined): ListItem[] | [] {
  if (!teamsData) {
    return []
  }
  return teamsData.map((team) => ({
    label: team.name,
    value: team.urlName,
  }))
}

export function getProjectMenuOptions(
  projectsData: TeamProjectsSummaryDto | undefined,
): ListItem[] {
  if (!projectsData?.projects) {
    return []
  }
  return projectsData.projects.map(({ project }) => ({
    label: project.name,
    value: project.urlName,
  }))
}

export function getFlowMenuOptions(flowsData: FlowDto[] | undefined): ListItem[] {
  if (!flowsData) {
    return []
  }
  return flowsData.map((flow, i) => {
    const flowName = flow.name ?? `unnamed flow - ${i}`
    return {
      label: flowName,
      value: String(flow.projectLocalId),
    }
  })
}
