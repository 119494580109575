import React, { useCallback, useEffect, useState } from 'react'
import { Modal } from 'components/Modal'
import { ButtonTextColorVariantEnum } from 'components/Button'
import { Input } from 'components/Input'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'
import { useToaster } from 'hooks/useToaster'
import { queryKeys } from 'hooks/useApiQuery'
import { Trace, TraceReq, Traces } from 'api/models'
import { useApi } from 'contexts/di-context'

type Props = TraceReq & {
  title?: string
  flowProjectLocalId?: string
  visible: boolean
  onClose: () => void
}

const maxTraceNameLength = 255
const isValidTraceName = (name: string) =>
  Boolean(name && name.length >= 3 && name.length <= maxTraceNameLength)

export const TraceEditModal = (props: Props) => {
  const { title, flowProjectLocalId, traceProjectLocalId, projectUrlName, visible, onClose } = props
  const { t } = useTranslation()
  const api = useApi()
  const queryClient = useQueryClient()

  const [value, setValue] = useState(title || '')
  const toaster = useToaster()
  const invalid = !isValidTraceName(value)

  useEffect(() => {
    setValue(title || '')
  }, [title, visible])

  const changeTraceNameInTraceCacheAndReturnOldName = useCallback(
    (traceName: string): string | null => {
      const oldTraceData = queryClient.getQueryData(
        queryKeys.trace({ projectUrlName, traceProjectLocalId }),
      ) as Trace
      if (oldTraceData) {
        queryClient.setQueryData(queryKeys.trace({ projectUrlName, traceProjectLocalId }), {
          ...oldTraceData,
          name: traceName,
        })
        return oldTraceData.name
      }
      return null
    },
    [projectUrlName, queryClient, traceProjectLocalId],
  )

  const changeTraceNameInFlowTracesCacheAndReturnOldName = useCallback(
    (traceName: string): string | null => {
      let oldTraceName: string | null = null
      if (flowProjectLocalId) {
        queryClient.setQueryData<Traces | undefined>(
          queryKeys.flowTraces({ projectUrlName, flowProjectLocalId }),
          (oldData) => {
            if (oldData) {
              return oldData.map((trace: Trace) => {
                if (String(trace.projectLocalId) === traceProjectLocalId) {
                  oldTraceName = trace.name
                  return String(trace.projectLocalId) === traceProjectLocalId
                    ? { ...trace, name: traceName }
                    : trace
                } else {
                  return trace
                }
              })
            }
          },
        )
      }
      return oldTraceName
    },
    [flowProjectLocalId, projectUrlName, queryClient, traceProjectLocalId],
  )

  const updateTraceInCache = useCallback(
    (updatedTrace: Trace) => {
      queryClient.setQueryData(
        queryKeys.trace({ projectUrlName, traceProjectLocalId }),
        updatedTrace,
      )
      queryClient.setQueryData<Traces | undefined>(
        queryKeys.flowTraces({ projectUrlName, flowProjectLocalId }),
        (oldData) => {
          if (oldData) {
            return oldData.map((trace: Trace) =>
              String(trace.projectLocalId) === traceProjectLocalId ? updatedTrace : trace,
            )
          }
        },
      )
    },
    [flowProjectLocalId, projectUrlName, queryClient, traceProjectLocalId],
  )

  const handleSave = useCallback(() => {
    const newTraceName = value.trim()

    if (traceProjectLocalId && projectUrlName && newTraceName && isValidTraceName(newTraceName)) {
      const oldTraceName1 = changeTraceNameInTraceCacheAndReturnOldName(newTraceName)
      const oldTraceName2 = changeTraceNameInFlowTracesCacheAndReturnOldName(newTraceName)
      const oldTraceName = oldTraceName1 || oldTraceName2
      api
        .updateTraceTitle({ traceProjectLocalId, projectUrlName }, newTraceName)
        .then((updatedTrace) => {
          updateTraceInCache(updatedTrace)
          toaster.success('traces.renameTraceModal.changeNameSuccess')
        })
        .catch((reason) => {
          if (oldTraceName) {
            changeTraceNameInFlowTracesCacheAndReturnOldName(oldTraceName)
          }
          toaster.error(reason, 'traces.renameTraceModal.changeNameFailed')
        })
    }
    onClose()
  }, [
    value,
    traceProjectLocalId,
    projectUrlName,
    onClose,
    changeTraceNameInTraceCacheAndReturnOldName,
    changeTraceNameInFlowTracesCacheAndReturnOldName,
    api,
    updateTraceInCache,
    toaster,
  ])

  const handleChange = useCallback(function (event) {
    setValue(event.target.value || '')
  }, [])

  return (
    <Modal
      title={t('traces.renameTraceModal.title')}
      isOpen={visible}
      onClose={onClose}
      actionButton={{
        children: t('Done'),
        textColorVariant: ButtonTextColorVariantEnum.Primary,
        disabled: invalid || value === title,
        onClick: handleSave,
      }}
    >
      <Input
        maxLength={maxTraceNameLength}
        inModal={true}
        value={value}
        error={invalid ? t('traces.renameTraceModal.titleInvalid') : undefined}
        onChange={handleChange}
        onKeyDown={(e) => {
          e.stopPropagation()
        }}
      />
    </Modal>
  )
}
