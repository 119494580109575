import React from 'react'

import { Breadcrumbs } from './Breadcrumbs'
import { BreadcrumbsTeamSection } from './BreadcrumbsTeamSection'
import { BreadcrumbsProjectSection } from './BreadcrumbsProjectSection'
import { BreadcrumbsFlowSection } from './BreadcrumbsFlowSection'

export const FlowTracesPageBreadcrumbs = () => (
  <Breadcrumbs>
    <BreadcrumbsTeamSection />
    <BreadcrumbsProjectSection />
    <BreadcrumbsFlowSection />
  </Breadcrumbs>
)
