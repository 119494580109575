import { ActionTooltip } from 'components/ActionTooltip'
import { IconColored } from 'components/common/IconColored'
import { observer } from 'mobx-react-lite'
import { reaction } from 'mobx'
import { useAnalytics } from 'contexts/di-context'
import { useEffect } from 'react'
import { PsChartStore } from '../PsChartStore'

interface MeasureActionProps {
  psChartStore: PsChartStore
}

export const MeasureAction = observer(function MeasureAction({ psChartStore }: MeasureActionProps) {
  const analytics = useAnalytics()

  useEffect(() => {
    reaction(
      () => [psChartStore.isMeasurementModeActive],
      () => {
        if (psChartStore.isMeasurementModeActive) {
          analytics.track('arrow-measurement')
        }
      },
      { name: 'reaction @ MeasureAction & isMeasurementModeActive -> analytics.track' },
    )
  }, [psChartStore, analytics])

  return (
    <ActionTooltip place="top" tooltipId="measurementTool">
      <div className="flex items-center justify-center align-center w-[64px] h-[32px] bg-dark-dark3">
        <IconColored
          className="p-[8px]"
          icon="measurement-icon"
          size={13}
          isDisabled
          isActive={psChartStore.isMeasurementModeActive}
        />
      </div>
    </ActionTooltip>
  )
})
