import { Trans, useTranslation } from 'react-i18next'
import { Button, ButtonVariantEnum } from 'components/Button'
import { Icon } from 'components/Icon'
import { Markdown } from 'components/guide/markdown/Markdown'
import { MarkdownConfig, MD_STYLES_DEFAULT } from 'components/guide/markdown/MarkdownConfig'
import { ReactNode, useEffect, useMemo } from 'react'
import classNames from 'classnames'
import { useAnalytics } from 'contexts/di-context'

const templateValues = new Map<string, ReactNode>()

export interface StepHintProps {
  part: number
  step: number
  title?: ReactNode
  description: string
  onCollapseClick?: () => void
  onLeftClick?: () => void
  onRightClick?: () => void
  nextStepButtonLabel?: string
}

export const StepHint = (props: StepHintProps) => {
  const {
    part,
    step,
    title,
    description,
    onCollapseClick,
    onLeftClick,
    onRightClick,
    nextStepButtonLabel,
  } = props
  const { t } = useTranslation()
  const analytics = useAnalytics()

  const configBase = useMemo(() => {
    return {
      headerLevelsToCollapse: [],
      styles: {
        ...MD_STYLES_DEFAULT,
        para: {
          className: 'text-small text-gray-normal',
        },
        list: {
          className: `pl-[35px] [&>li]:relative [&>li]:list-none [&>li]:before:absolute [&>li]:before:block [&>li]:before:top-[8px] [&>li]:before:left-[-10px] [&>li]:before:w-[3px] [&>li]:before:h-[3px] [&>li]:before:rounded-[1.5px] [&>li]:before:bg-gray-normal`,
        },
        listItem: {
          className: 'text-small marker:text-gray-normal marker:text-small',
        },
      },
    } as MarkdownConfig
  }, [])

  useEffect(() => {
    analytics?.track(`live-demo-part-${part}-step-${step}`)
  }, [analytics, part, step])

  return (
    <div className="rounded-sm bg-sky w-[280px]" data-testid="liveDemoStepHint">
      <div className="px-[16px] pt-[16px] pb-[24px] border-b-[1px] border-solid border-[rgba(255,255,255,.1)]">
        <div
          className="text-white opacity-50 text-[10px] mb-[16px] uppercase"
          data-testid="liveDemoStepHintPartStepTitle"
        >
          <Trans
            i18nKey="components.hint.header"
            values={{ step, part }}
            components={{
              wordSpaces: <span className="[word-spacing:4px]" />,
            }}
          />
        </div>
        <div className="text-small text-gray-normal mb-[4px] font-bold">{title}</div>
        <div className="whitespace-normal">
          <Markdown markdown={description} templateValues={templateValues} config={configBase} />
        </div>
      </div>
      <div className="flex justify-end">
        {onCollapseClick && (
          <div className="flex-grow">
            <Button
              data-testid="liveDemoStepHintCollapseButton"
              onClick={onCollapseClick}
              variant={ButtonVariantEnum.Text}
              className="px-[14px] mx-[2px] h-[48px]"
            >
              <span className="text-gray-normal hover:text-white transition-colors">
                {t('components.hint.collapse')}
              </span>
            </Button>
          </div>
        )}
        <div className="flex">
          {onLeftClick && (
            <Icon
              data-testid="liveDemoStepHintLeftButton"
              onClick={onLeftClick}
              icon="arrow-drop-l"
              as="button"
              className={classNames(
                'w-[48px] h-[48px] text-[32px] border-solid border-[rgba(255,255,255,.1)] text-gray-normal hover:text-white transition-colors',
                onRightClick && 'border-r-[1px]',
              )}
            />
          )}
          {onRightClick &&
            (nextStepButtonLabel ? (
              <Button
                variant={ButtonVariantEnum.Text}
                onClick={onRightClick}
                className="px-[10px]"
                data-testid="liveDemoStepHintRightButton"
              >
                <span className="text-gray-normal hover:text-white transition-colors">
                  {nextStepButtonLabel}
                </span>
              </Button>
            ) : (
              <Icon
                data-testid="liveDemoStepHintRightButton"
                onClick={onRightClick}
                icon="arrow-drop-r"
                as="button"
                className="w-[48px] h-[48px] text-[32px] text-gray-normal hover:text-white transition-colors"
              />
            ))}
        </div>
      </div>
    </div>
  )
}
