import { PinType } from 'components/ps-chart/stores/AnnotationsStore'
import { useSliceSuggestions } from 'hooks/useApiQuery'
import { useTranslation } from 'react-i18next'
import { Icon } from 'components/Icon'
import { PsChartStore } from 'components/ps-chart/PsChartStore'
import { Slice } from 'components/ps-chart/models/Slice'
import { SliceBox } from 'components/ps-chart/details-view/annotations/SliceBox'

interface SuitableSlicesProps {
  selectedSliceId?: number
  assignedSliceId?: number | null
  psChartStore: PsChartStore
  annotationId: number
  type: PinType
  connectSlice: (slice: Slice, type: PinType) => void
}

export const SuitableSlices = (props: SuitableSlicesProps) => {
  const { annotationId, type, psChartStore, connectSlice, selectedSliceId, assignedSliceId } = props
  const { data } = useSliceSuggestions({
    projectUrlName: psChartStore.chartPageParams.projectUrlName,
    flowProjectLocalId: psChartStore.chartPageParams.flowProjectLocalId,
    traceProjectLocalId: psChartStore.chartPageParams.traceProjectLocalId,
  })
  const { t } = useTranslation()

  if (data && data.length > 0) {
    const annotationSlices = data.find((item) => item.annotationId === annotationId)?.[
      type === PinType.ACTION ? 'actionSliceIds' : 'reactionSliceIds'
    ]

    if (annotationSlices) {
      const suggestedSlices: Slice[] = []
      annotationSlices.forEach((item) => {
        const slice = psChartStore.sliceById.get(item.sliceId)
        if (slice) {
          suggestedSlices.push(slice)
        }
      })

      if (suggestedSlices.length > 0) {
        return (
          <div className="mb-[16px]">
            <div className="flex">
              <div className="flex-1 text-small text-gray-normal mb-[16px]">
                {t('psChart.details.suitableSlicesToAssign')}
              </div>
              <Icon
                icon="suitableSlice"
                className="text-[16px] text-gray-normal mr-[4px] ml-[20px]"
              />
            </div>

            {suggestedSlices
              .filter((item) => item.id !== selectedSliceId && item.id !== assignedSliceId)
              .map((item) => (
                <SliceBox
                  key={String(item.id)}
                  onClick={() => psChartStore.setSelectedSlice(item)}
                  psChartStore={psChartStore}
                  slice={item}
                  full={false}
                  onConnect={() => {
                    psChartStore.setSelectedSlice(item)
                    connectSlice(item, type)
                  }}
                />
              ))}
          </div>
        )
      }
    }
  }

  return null
}
