import { AuthenticatedUserDto, ProjectDto } from 'api/__generated__/api-types'
import { ProjectRoleApi, TeamRoleApi } from 'api/__generated__/api-constants'

export const canUserInviteToProject = (user: AuthenticatedUserDto, project: ProjectDto) => {
  if (user.roles.isSuperAdmin) {
    return true
  }
  const userTeamRole = user.roles.teams[project.team.id]
  if (userTeamRole === TeamRoleApi.ADMIN) {
    return true
  }
  const userProjectRole = user.roles.projects[project.id]
  return userProjectRole === ProjectRoleApi.ADMIN
}
