import { useEffect } from 'react'
import { generatePath, useNavigate } from 'react-router-dom'

import { GUIDE_IO_PROJECT_PATH } from 'components/guide-io/constants'
import { AuthenticatedUserDto, ProjectDto } from 'api/__generated__/api-types'
import { FEATURE_FLAGS, useFeatureFlag } from 'utils/feature-flags'

interface IoGuidesRedirectProps {
  user?: AuthenticatedUserDto
  project?: ProjectDto
}

export const useIoGuidesRedirect = ({ user, project }: IoGuidesRedirectProps) => {
  const navigate = useNavigate()
  const isInteractiveOnboardingEnabled = useFeatureFlag(FEATURE_FLAGS.INTERACTIVE_ONBOARDING)
  useEffect(() => {
    if (
      isInteractiveOnboardingEnabled &&
      project?.interactiveOnboarding &&
      !user?.roles.isSuperAdmin
    ) {
      navigate(
        generatePath(GUIDE_IO_PROJECT_PATH, {
          projectUrlName: project.urlName,
        }),
      )
    }
  }, [isInteractiveOnboardingEnabled, project, navigate, user])
}
