import { useEffect } from 'react'

declare global {
  interface Window {
    hbspt?: {
      forms: {
        create: (options: {
          portalId: string
          formId: string
          region?: string
          target?: string
        }) => void
      }
    }
  }
}

export const HubSpotForm = ({ portalId, formId }: { portalId: string; formId: string }) => {
  useEffect(() => {
    const script = document.createElement('script')
    script.src = 'https://js.hsforms.net/forms/shell.js'
    document.body.appendChild(script)

    script.addEventListener('load', () => {
      if (window.hbspt) {
        window.hbspt.forms.create({
          region: 'na1',
          portalId,
          formId,
          target: '#hubspotForm',
        })
      }
    })

    return () => {
      document.body.removeChild(script)
    }
  }, [formId, portalId])

  return <div id="hubspotForm"></div>
}
