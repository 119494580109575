import { createContext, useContext, useEffect, useRef, useState } from 'react'
import { PostHogProvider as PostHogProviderOriginal, usePostHog } from 'posthog-js/react'
import { useUserQuery, useTeamsQuery } from 'hooks/useApiQuery'
import { useAuth } from 'contexts/auth-context'
import { FEATURE_FLAGS, useFeatureFlag } from 'utils/feature-flags'
import { isHostTypeProd } from 'utils/getHostType'
import { useAnalytics } from 'contexts/di-context'
import { useLocation } from 'react-router-dom'

const postHogOptions = {
  api_host: process.env.REACT_APP_PUBLIC_POSTHOG_HOST,
  disable_session_recording: true,
}

type Props = {
  children: React.ReactNode
}

/**
 * When user is logged in, add it to PostHog to to target this user in feature flags.
 *
 * PostHog has multiple ids per user, and this will link our internal user id with the PostHog autogenerated
 * user id. After this association, each following requests for the feature flags will be associated
 * with this user. PostHog API usually works faster than our user api, so in most cases will have feature
 * flag ready before user API returns.
 *
 * More info:
 * - https://posthog.com/docs/getting-started/identify-users
 * - https://posthog.com/docs/libraries/js#identifying-users
 */
const PostHogIdentify = () => {
  const { isSignedIn } = useAuth()
  const { data, isLoading } = useUserQuery(isSignedIn)
  const { data: teamsData, isLoading: teamsIsLoading } = useTeamsQuery(isSignedIn)
  const posthog = usePostHog()
  const prevIsSignedIn = useRef<boolean>()

  const { id, email, name, lastName } = data || {}
  const isLite = !!teamsData?.[0]?.psLite

  useEffect(() => {
    if (!isLoading && !teamsIsLoading && id) {
      posthog?.identify(id.toString(), {
        id,
        email,
        name,
        lastName,
        isLite,
      })
    }
  }, [posthog, isLoading, teamsIsLoading, id, email, name, lastName, isLite])

  // Reset posthog user on logout.
  // https://posthog.com/docs/libraries/js#reset-after-logout
  useEffect(() => {
    if (prevIsSignedIn.current && !isSignedIn) {
      posthog?.reset(true)
      posthog?.reloadFeatureFlags()
    }
    prevIsSignedIn.current = isSignedIn
  }, [posthog, isSignedIn])

  return null
}

/**
 * Track pageviews on each location change. It will not track the changes of hash.
 */
const PostHogTrackPageviews = () => {
  const posthog = usePostHog()
  const location = useLocation()

  useEffect(() => {
    posthog?.capture('$pageview')
  }, [location.pathname, location.search, posthog])

  return null
}

/**
 * Connect PostHog to global analytics object. When connected it will send custom events to the PostHog
 * along with other analytics providers.
 */
const PostHogAnalytics = () => {
  const posthog = usePostHog()
  const analytics = useAnalytics()

  useEffect(() => {
    if (posthog) {
      analytics.initPostHog(posthog)
    }
  }, [posthog, analytics])

  return null
}

/**
 * Start PostHog session recording if `session-recording` feature flag is on.
 */
const PostHogSessionReplay = () => {
  const isFlagEnabled = useFeatureFlag(FEATURE_FLAGS.SESSION_RECORDING)
  const posthog = usePostHog()

  useEffect(() => {
    if (posthog && isFlagEnabled) {
      posthog.startSessionRecording()
    }
  }, [isFlagEnabled, posthog])

  return null
}

export const postHogApiKey = () => {
  if (isHostTypeProd() && process.env.REACT_APP_PUBLIC_POSTHOG_KEY_PROD != null) {
    return process.env.REACT_APP_PUBLIC_POSTHOG_KEY_PROD
  }

  return process.env.REACT_APP_PUBLIC_POSTHOG_KEY
}

export const PostHogContext = createContext<{ isError: boolean }>({ isError: false })

export const usePostHogContext = () => useContext(PostHogContext)

/**
 * Connect PostHog for feature flags and associate logged in user with it.
 * You can use `useFeatureFlagEnabled` hook to access feature flags as described here:
 * https://posthog.com/docs/libraries/react#feature-flags
 */
export const PostHogProvider = ({ children }: Props) => {
  const [isError, setIsError] = useState(false)
  const options = {
    ...postHogOptions,
    on_xhr_error: (req: XMLHttpRequest) => {
      setIsError(true)
      throw new Error(`PostHog XHR error ${req?.responseText}`)
    },
  }

  return (
    <PostHogContext.Provider value={{ isError }}>
      <PostHogProviderOriginal apiKey={postHogApiKey()} options={options}>
        <PostHogIdentify />
        <PostHogAnalytics />
        <PostHogSessionReplay />
        <PostHogTrackPageviews />
        {children}
      </PostHogProviderOriginal>
    </PostHogContext.Provider>
  )
}
