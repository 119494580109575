import { SliceLink } from 'components/ps-chart/models/SliceLink'
import { ReadonlySliceById } from 'components/ps-chart/stores/TraceDataStore'
import { ConnectionDirection } from 'components/ps-chart/models/ConnectionDirection'
import { isRangeInside, SliceRange } from 'components/ps-chart/models/Slice'

/*
removeSameStackChains searches for one stack / in-between-level functions which has their own links,
to avoid direct links from high level functions into in-between-level's children function with links.
So if there's A / B - E / C - F - it will keep link between A - B.
But it's needed to add B into getLinksTree
 */
export const removeSameStackChains =
  (
    currentStepSliceId: number,
    sliceById: ReadonlySliceById,
    linkDirection = ConnectionDirection.BACKWARD,
  ) =>
  (links: SliceLink[]) => {
    if (linkDirection === ConnectionDirection.BACKWARD) {
      return links
    }

    const filteredLinks = new Set<SliceLink>()
    const occupiedTimeSlices = new Set<SliceRange>()
    //console.log('tst youngTargetFirstChainsRoots', youngTargetFirstChainsRoots)
    for (const currentLink of links) {
      const { toSliceId: curTargetId } = currentLink
      const { start, end } = sliceById.get(curTargetId)!
      if (start !== undefined && end !== undefined) {
        const range = { start, end }
        let hasParentLink = false
        for (const withinRange of occupiedTimeSlices) {
          if (hasParentLink) {
            break
          }
          hasParentLink = isRangeInside(range, withinRange)
        }
        if (!hasParentLink) {
          if (curTargetId !== currentStepSliceId) {
            occupiedTimeSlices.add(range)
          }

          filteredLinks.add(currentLink)
        }
      }
    }
    return links.filter((link) => filteredLinks.has(link))
  }
