import { useTranslation } from 'react-i18next'

import { useContentfulMarkdownDocument } from 'hooks/contentful/useContentfulMarkdownDocument'
import { PSTOOL_LITE } from 'api/contentful'
import { Markdown } from 'components/guide/markdown/Markdown'
import { MarkdownConfig } from 'components/guide/markdown/MarkdownConfig'
import { MD_STYLES_LITE } from 'components/lite/config'
import { useApi } from 'contexts/di-context'
import { useQuery } from 'react-query'
import { ONE_DAY } from 'utils/oneDay'
import { queryKeys } from 'hooks/useApiQuery'

type Props = {
  projectUrlName: string
}

const queryConfig = {
  retry: false,
  staleTime: ONE_DAY,
  cacheTime: ONE_DAY,
}

function useBuildProperties(projectUrlName: string) {
  const api = useApi()
  return useQuery(
    queryKeys.buildProperties(projectUrlName),
    () => api.postBuildProperties(projectUrlName),
    queryConfig,
  )
}

function usePluginVersion() {
  const api = useApi()
  return useQuery(
    queryKeys.androidPluginVersions,
    () => api.getAndroidPluginVersions(),
    queryConfig,
  )
}

export function LiteBuildInstructions({ projectUrlName }: Props) {
  const { t } = useTranslation()
  const { entry } = useContentfulMarkdownDocument(PSTOOL_LITE.buildInstructions)

  const { data: buildPropertiesData } = useBuildProperties(projectUrlName)
  const { data: pluginVersion } = usePluginVersion()

  if (!entry || !buildPropertiesData || !pluginVersion) {
    return null
  }

  const downloadFileLink = URL.createObjectURL(
    new Blob([buildPropertiesData], { type: 'text/plain;charset=UTF-8' }),
  )

  const buildPropertiesTemplateValues = new Map()
  buildPropertiesTemplateValues.set('readToken', buildPropertiesData)
  buildPropertiesTemplateValues.set('downloadFileLink', downloadFileLink)
  buildPropertiesTemplateValues.set('pluginVersion', pluginVersion.recommended)

  const config: MarkdownConfig = {
    headerLevelsToCollapse: [1],
    styles: MD_STYLES_LITE,
  }

  return (
    <div className="mb-16">
      <h2 className="text-header-big font-semibold mb-4 z-20 relative">{t('lite.steps.step1')}</h2>
      <Markdown
        markdown={entry.fields.markdown}
        templateValues={buildPropertiesTemplateValues}
        config={config}
      />
      <div className="md:grid md:grid-cols-3 gap-5 pt-3">
        <div className="relative gap-3">
          <h2 className="text-header-normal font-semibold mb-4 z-20 relative">
            Instrumenting Your App
          </h2>
          <div className="w-full pt-[56.25%] box-border relative">
            <iframe
              className="w-full h-full absolute top-0"
              width="560"
              height="315"
              src="https://www.youtube.com/embed/I26jyFE4ggc?si=lcbCJwc7iV6fKhzp"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen
            ></iframe>
          </div>
        </div>
        <div className="relative gap-3">
          <h2 className="text-header-normal font-semibold mb-4 z-20 relative">Recording a Trace</h2>
          <div className="w-full pt-[56.25%] box-border relative">
            <iframe
              className="w-full h-full absolute top-0"
              width="560"
              height="315"
              src="https://www.youtube.com/embed/q_uYS3xy7_A?si=cRam4wFSqkxl5_tA"
              title="CriticalPath Quickstart Guide: Recording a Trace"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  )
}
