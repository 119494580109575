import { observer } from 'mobx-react-lite'
import { LiveDemoController, Step } from 'components/live-demo/LiveDemoController'
import { HighlightingAreas, Area } from 'components/HighlightingAreas'
import { RefObject, useCallback, useLayoutEffect, useState } from 'react'
import {
  TypeLiveDemoHighlightingArea,
  TypeLiveDemoHighlightingAreaFields,
} from 'hooks/__generated__/contentful/TypeLiveDemoHighlightingArea'
import { PsChartStore } from 'components/ps-chart/PsChartStore'
import { LiveDemoStepHint } from 'components/live-demo/LiveDemoStepHint'

interface LiveDemoHighlightingPropsTypes {
  psChartStore: PsChartStore
  liveDemoController: LiveDemoController
  wrapperRef: RefObject<HTMLElement>
}

export const LiveDemoHighlighting = observer(function LiveDemoHighlighting({
  liveDemoController,
  wrapperRef,
  psChartStore,
}: LiveDemoHighlightingPropsTypes) {
  const { highlightingAreas } = liveDemoController.currentStep.data.fields
  const [areas, setAreas] = useState<Area[]>([])
  const { enabled, currentTab, currentStep } = liveDemoController

  const renderHint = useCallback(
    (element, data) => (
      <LiveDemoStepHint
        element={element}
        area={data}
        liveDemoController={liveDemoController}
        isSearchOpened={psChartStore.searchStore.isOpened}
        currentStep={currentStep}
      />
    ),
    [liveDemoController, psChartStore, currentStep],
  )

  useLayoutEffect(() => {
    const wrapperElement = wrapperRef.current
    if (wrapperElement && highlightingAreas && enabled && currentStep) {
      const preparedElements = prepareElements(highlightingAreas)
      const updateAreas = () => {
        setAreas(
          preparedElements.map((item) => {
            const shouldRenderHint = currentStep.data.fields.hintAreaIndex === item.number
            return {
              ...calculateArea(wrapperElement, item.element, item.doc, psChartStore),
              renderChildren: shouldRenderHint ? renderHint : undefined,
            }
          }),
        )
      }

      updateAreas()
      const resizeObserver = new ResizeObserver(updateAreas)
      preparedElements.forEach(({ element }) => {
        resizeObserver.observe(element)
      })

      return () => {
        preparedElements.forEach(({ element }) => {
          resizeObserver.unobserve(element)
        })
      }
    } else {
      setAreas([])
    }
  }, [highlightingAreas, wrapperRef, enabled, psChartStore, currentStep, currentTab, renderHint])

  if (!enabled) {
    return null
  }

  return (
    <>
      <HighlightingAreas
        highlightingAreas={areas}
        isTransparent={currentStep.data.fields.isBackgroundTransparent ?? false}
      />
      {areas.length === 0 && (
        <NoHighlightings
          liveDemoController={liveDemoController}
          psChartStore={psChartStore}
          currentStep={currentStep}
        />
      )}
    </>
  )
})

function calculateArea(
  parentElement: HTMLElement,
  childElement: HTMLElement,
  highlightingArea: TypeLiveDemoHighlightingAreaFields,
  psChartStore: PsChartStore,
): Area {
  const parentRect = parentElement.getBoundingClientRect()
  let left = childElement.getBoundingClientRect().left - parentRect.left
  let top = childElement.getBoundingClientRect().top - parentRect.top
  let width = childElement.clientWidth
  let height = childElement.clientHeight

  const {
    width: overrideWidth,
    height: overrideHeight,
    offsetX,
    offsetY,
    offsetHeight,
    offsetWidth,
    chartXStart,
    chartXEnd,
    sliceId,
  } = highlightingArea

  if (sliceId) {
    const slice = psChartStore.sliceById.get(sliceId)
    if (slice) {
      left += psChartStore.getXByTime(slice.start)
      width = psChartStore.getXByTime(slice.end) - psChartStore.getXByTime(slice.start)

      const sliceTopY = psChartStore.getSliceY(slice)
      const sliceBottomY = sliceTopY + psChartStore.chartSettings.renderEngine.threads.blockHeight

      top += sliceTopY
      height = sliceBottomY - sliceTopY
    }
  }

  if (chartXStart !== undefined) {
    left += psChartStore.getXByTime(chartXStart)
  }

  if (offsetX) {
    left += offsetX
  }

  if (offsetY) {
    top += offsetY
  }

  if (offsetHeight) {
    height += offsetHeight
  }

  if (chartXStart !== undefined && chartXEnd !== undefined) {
    const chartLeft = psChartStore.getXByTime(chartXStart)
    const chartRight = psChartStore.getXByTime(chartXEnd)
    width = chartRight - chartLeft
  }

  if (offsetWidth) {
    width += offsetWidth
  }

  if (overrideWidth !== undefined) {
    width = overrideWidth
  }

  if (overrideHeight !== undefined) {
    height = overrideHeight
  }

  if (top + height > parentRect.height) {
    height = parentRect.height - top
  }

  if (left + width > parentRect.width) {
    width = parentRect.width - left
  }

  return {
    x: left,
    y: top,
    width,
    height,
  }
}

interface PreparedHighlightingAreaWithElement {
  element: HTMLElement
  doc: TypeLiveDemoHighlightingAreaFields
  number: number
}
function prepareElements(
  highlightingAreas: TypeLiveDemoHighlightingArea[],
): PreparedHighlightingAreaWithElement[] {
  const prepared: PreparedHighlightingAreaWithElement[] = []

  highlightingAreas.forEach((item, index) => {
    const element = document.getElementById(item.fields.elementId)
    if (element) {
      prepared.push({
        element,
        doc: item.fields,
        number: index + 1,
      })
    }
  })
  return prepared
}

const NoHighlightings = ({
  liveDemoController,
  psChartStore,
  currentStep,
}: {
  liveDemoController: LiveDemoController
  psChartStore: PsChartStore
  currentStep: Step
}) => {
  const [element, setElement] = useState<HTMLElement | null>(null)

  return (
    <div ref={setElement} className={'absolute top-0 right-0 bottom-0 left-0'}>
      {element && (
        <LiveDemoStepHint
          element={element}
          liveDemoController={liveDemoController}
          isSearchOpened={psChartStore.searchStore.isOpened}
          currentStep={currentStep}
        />
      )}
    </div>
  )
}
