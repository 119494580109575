import { TreeLink, TreeNode } from 'components/ps-chart/stores/connections-store/LinksTree/TreeNode'
import { Slice } from 'components/ps-chart/models/Slice'
import { ConnectionType } from 'components/ps-chart/models/ConnectionType'
import { ReadonlySliceById } from 'components/ps-chart/stores/TraceDataStore'
import { getChildNetworkRequestsDuration } from 'components/ps-chart/utils/slice'
import { TraceAnalyzeStore } from 'components/ps-chart/stores/TraceAnalyzeStore'

const possibleRunnableChainCheck = (fromSlice: Slice, toSlice: Slice): boolean => {
  return (
    fromSlice.title.split(' ')[0] === toSlice.title.split(' ')[0] &&
    fromSlice.title.split(' ')[2] === toSlice.title.split(' ')[2]
  )
}

export const getMainChainFromTree = (
  treeRootNode: TreeNode | null,
  sliceById: ReadonlySliceById,
): Slice[] => {
  if (treeRootNode == null) {
    return []
  }
  const initialSlice = sliceById.get(treeRootNode.sliceId)!
  const rootSliceId = initialSlice.root ? initialSlice.root.id : initialSlice.id
  const visitedSlices = new Set<number>([treeRootNode.sliceId])
  const mainChain: Slice[] = [initialSlice]
  let curStackSrcNode = treeRootNode

  while (curStackSrcNode.fromLinks.length > 0) {
    /**
     * Find all links outgoing from the current stack,
     *  including links from the ancestors of the current slice.
     */
    const curStackLinksToCompare: TreeLink[] = []
    const curStackLinksSources = [curStackSrcNode]
    const curStackCheckedSlices = new Set<number>()
    while (curStackLinksSources.length > 0) {
      const curLinksSrc = curStackLinksSources.pop()
      if (curLinksSrc == null || curStackCheckedSlices.has(curLinksSrc.sliceId)) {
        continue
      }
      curStackCheckedSlices.add(curLinksSrc.sliceId)
      curStackLinksToCompare.push(
        ...curLinksSrc.fromLinks.filter((link) => link.connectionType != ConnectionType.TREE),
      )
      curStackLinksSources.push(
        ...curLinksSrc.fromLinks
          .filter((link) => link.connectionType == ConnectionType.TREE)
          .map((link) => link.toTreeNode),
      )
    }

    /**
     * Find a link which leads into a slice,
     *  with the smallest distance between it's x-start and the current slice's x-start.
     */
    const curSlice = sliceById.get(curStackSrcNode.sliceId)!
    const curSliceRoot = curSlice.root
    let closestTimeGap = +Infinity
    let closestLink = null
    let closestNextSlice = null

    const curSliceHasLinksExceptRoot =
      curSliceRoot !== null
        ? curStackLinksToCompare.filter(
            ({ fromTreeNode: { sliceId } }) => sliceId !== curSliceRoot.id,
          ).length > 0
        : false

    for (const curLink of curStackLinksToCompare) {
      if (
        curLink.connectionType === ConnectionType.CYCLE &&
        curLink.toTreeNode.sliceId === rootSliceId
      ) {
        continue
      }
      const curToNode = curLink.toTreeNode
      const curFromNode = curLink.fromTreeNode

      const curToSlice = sliceById.get(curToNode.sliceId)!
      const curFromSlice = sliceById.get(curFromNode.sliceId)!

      let runnableToNode
      let runnableToSlice

      if (
        curSliceHasLinksExceptRoot &&
        curSliceRoot !== null &&
        curFromSlice.id === curSliceRoot.id &&
        (curToSlice.threadId === curFromSlice.threadId ||
          possibleRunnableChainCheck(curFromSlice, curToSlice))
      ) {
        let initialRunnableChainThreadId = curToSlice.threadId

        let runnableChainNode = curToNode
        let runnableChainThreadId = curToSlice.threadId
        let runnableChainSlice
        let lastRunnableChainNode

        while (runnableChainThreadId === initialRunnableChainThreadId) {
          if (runnableChainNode.fromLinks.length === 1) {
            lastRunnableChainNode = runnableChainNode
            runnableChainNode = runnableChainNode.fromLinks[0].toTreeNode
            runnableChainSlice = sliceById.get(runnableChainNode.sliceId)!
            if (possibleRunnableChainCheck(curFromSlice, runnableChainSlice)) {
              initialRunnableChainThreadId = runnableChainSlice.threadId
            } else {
              runnableChainNode = lastRunnableChainNode
              runnableChainSlice = sliceById.get(runnableChainNode.sliceId)!
              break
            }
            if (TraceAnalyzeStore.isChoreographerSlice(runnableChainSlice)) {
              break
            }
            runnableChainThreadId = runnableChainSlice.threadId
          } else {
            break
          }
        }
        if (
          runnableChainSlice !== undefined &&
          runnableChainNode !== curToNode &&
          !TraceAnalyzeStore.isChoreographerSlice(runnableChainSlice)
        ) {
          runnableToNode =
            runnableChainNode.fromLinks.length !== 0
              ? runnableChainNode.fromLinks[0].toTreeNode
              : runnableChainNode
          runnableToSlice = sliceById.get(runnableToNode.sliceId)!
        }
      }

      const compareToSlice = runnableToSlice ?? curToSlice
      const curFromNetworkRequestOffset = getChildNetworkRequestsDuration(curFromSlice)
      const curToSliceDif = !compareToSlice.isNetworkRequest
        ? compareToSlice.start
        : compareToSlice.end
      //TODO: Don't understand why there was Math.abs in curTimeGap - probably for async case scenario??
      const curTimeGap = curSlice.start - curToSliceDif - curFromNetworkRequestOffset

      if (curTimeGap < closestTimeGap) {
        closestTimeGap = curTimeGap
        closestLink = curLink
        closestNextSlice = curToSlice
      }
    }

    if (closestLink == null || closestNextSlice == null) {
      if (curSlice.root !== null) {
        mainChain.push(curSlice.root)
      }
      break
    }

    if (visitedSlices.has(closestNextSlice.id)) {
      break
    }
    visitedSlices.add(closestNextSlice.id)

    if (closestLink.fromTreeNode.sliceId !== curStackSrcNode.sliceId) {
      mainChain.push(sliceById.get(closestLink.fromTreeNode.sliceId)!)
    }
    mainChain.push(closestNextSlice)
    curStackSrcNode = closestLink.toTreeNode
  }
  return mainChain
}
