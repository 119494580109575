import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { useMutation } from 'react-query'
import NProgress from 'nprogress'
import { toast } from 'react-hot-toast'

import { Input } from 'components/Input'
import { AuthLayout } from 'components/auth/AuthLayout'
import { Button } from 'components/Button'
import { AxiosError } from 'axios'
import { useApi } from 'contexts/di-context'
import { ApiError } from 'api/models'
import { PATH_SIGN_IN } from './SignInPage'

export const PATH_INVITE_WHITELIST = '/request-invite'

export const InviteWhitelistPage = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const api = useApi()
  const location = useLocation()

  const [email, setEmail] = useState(location.state?.email || '')

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    setEmail(event.target.value)

  const postInviteWhitelistMutation = useMutation(() => api.postInviteWhitelist({ email }), {
    onSuccess: () => {
      NProgress.done()
      toast.success(
        <span>
          <span className="mr-[16px]">{t('auth.inviteWhitelistPage.success', { email })}</span>
          <a
            href="https://www.productscience.ai/contact"
            target="_blank"
            rel="noopener noreferrer"
            className="font-medium"
          >
            {t('auth.common.contactUs')}
          </a>
        </span>,
      )
      navigate(PATH_SIGN_IN, { replace: true })
    },
    onError(error: AxiosError<ApiError>) {
      NProgress.done()
      toast.error(error.response?.data.message ?? t('errorMessage'))
    },
  })

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault()
    NProgress.start()
    postInviteWhitelistMutation.mutate()
  }

  return (
    <AuthLayout
      bottomInfo={[
        <Link to={PATH_SIGN_IN} state={{ email }}>
          {t('auth.common.haveAccount')}
        </Link>,
        <span>
          <span className="text-gray-normal">{t('auth.common.haveQuestions')}</span>{' '}
          <a href="https://www.productscience.ai/contact" target="_blank" rel="noopener noreferrer">
            {t('auth.common.contactUs')}
          </a>
        </span>,
      ]}
    >
      <h2 className="text-center text-normal tracking-wide">
        {t('auth.inviteWhitelistPage.header')}
      </h2>
      <form className="mt-6" onSubmit={handleSubmit}>
        <div>
          <label htmlFor="email-address" className="sr-only">
            {t('auth.common.email')}
          </label>
          <Input
            value={email}
            onChange={handleEmailChange}
            id="email-address"
            name="email"
            type="email"
            autoComplete="email"
            placeholder={t('auth.inviteWhitelistPage.emailPlaceholder')}
            required
          />
        </div>
        <Button className="w-full" disabled={postInviteWhitelistMutation.isLoading} isSmall>
          {t('auth.inviteWhitelistPage.button')}
        </Button>
      </form>
    </AuthLayout>
  )
}
