import styled, { css } from 'styled-components/macro'
import tw from 'twin.macro'
import { Icon } from 'components/Icon'

export enum ButtonVariantEnum {
  Text,
  Outlined,
  Contained,
  Dark,
  Disabled,
}

export enum ButtonTextColorVariantEnum {
  Muted,
  Bad,
  Look,
  Primary,
  Good,
}

export interface ButtonProps {
  variant?: ButtonVariantEnum
  textColorVariant?: ButtonTextColorVariantEnum
  isSmall?: boolean
  withIcon?: boolean
  setFontSize?: number
  borderColor?: string
}

export const Button = styled.button.withConfig({
  shouldForwardProp: (prop, defaultValidatorFn) => {
    return prop.includes('onPointer')
      ? true
      : !['variant', 'textColorVariant'].includes(prop) && defaultValidatorFn(prop)
  },
})<ButtonProps>`
  ${tw`transition tracking-wide font-medium rounded-sm`}
  font-size: ${({ setFontSize }) => setFontSize || 12}px;

  ${({ variant }) => {
    switch (variant) {
      case ButtonVariantEnum.Outlined:
        return outlinedStyles
      case ButtonVariantEnum.Text:
        return textStyles
      case ButtonVariantEnum.Dark:
        return darkStyles
      case ButtonVariantEnum.Disabled:
        return disabledStyles
      default:
        return defaultStyles
    }
  }}
`

const blockStyles = css<ButtonProps>`
  height: ${({ isSmall }) => (isSmall ? 32 : 40)}px;
  padding: 0 ${({ isSmall }) => (isSmall ? 17 : 25)}px;

  &[href] {
    line-height: ${({ isSmall }) => (isSmall ? 30 : 38)}px;
  }
`

const textStyles = css<ButtonProps>`
  ${({ textColorVariant }) => {
    switch (textColorVariant) {
      case ButtonTextColorVariantEnum.Muted:
        return tw`text-gray-normal`
      case ButtonTextColorVariantEnum.Bad:
        return tw`text-state-bad`
      case ButtonTextColorVariantEnum.Look:
        return tw`text-state-attention`
      case ButtonTextColorVariantEnum.Good:
        return tw`text-state-good`
      default:
        return tw`text-electro`
    }
  }}
  ${tw`disabled:text-gray-dark`}
  &:not(:disabled) {
    ${tw`hover:text-white`}
  }

  ${({ withIcon }) =>
    withIcon &&
    css`
      display: flex;
      align-items: center;
      padding-left: 6px;
      line-height: 1;
    `}
`

const outlinedStyles = css<ButtonProps>`
  ${blockStyles}
  ${tw`border border-electro disabled:border-gray-dark`}
  &:not(:disabled) {
    ${tw`hover:border-white`}
  }

  ${({ borderColor }) =>
    borderColor &&
    css`
      border: 1px solid ${borderColor};
    `}
`

const darkStyles = css<ButtonProps>`
  ${blockStyles}
  ${tw`text-electro bg-dark-dark1`}
`

const disabledStyles = css<ButtonProps>`
  ${blockStyles}
  ${tw`text-gray-dark border border-gray-dark pointer-events-none`}
`

const defaultStyles = css<ButtonProps>`
  ${blockStyles}
  ${tw`bg-electro disabled:bg-gray-dark`}
  &:not(:disabled) {
    ${tw`hover:bg-sky`}
  }
`

export const ButtonIcon = tw(Icon)`
  inline-block align-middle text-icon
`
