import { makeAutoObservable, observable } from 'mobx'
import { Toaster } from 'hooks/useToaster'

const VIDEO_PREVIEW_HEIGHT = 40

export class VideoTimelineStore {
  private readonly toaster: Toaster
  extractionProgress = 0
  isExtractionInProgress = false
  canvas: HTMLCanvasElement | null = null
  startXPx = 0
  width = 0
  private cancelExtraction = () => {}
  private lastExtractionPromise: Promise<void> = Promise.resolve()

  constructor(toaster: Toaster) {
    makeAutoObservable<
      VideoTimelineStore,
      'cancelExtraction' | 'toaster' | 'lastExtractionPromise'
    >(this, {
      canvas: observable.ref,
      cancelExtraction: false,
      lastExtractionPromise: false,
      toaster: false,
    })
    this.toaster = toaster
  }

  reset() {
    this.cancelExtraction()
    this.setExtractionFinished()
    this.canvas = null
  }

  private setExtractionProgress(val: number) {
    this.extractionProgress = val
  }

  private setExtractionStarted() {
    this.isExtractionInProgress = true
  }

  private setExtractionFinished() {
    this.isExtractionInProgress = false
    this.extractionProgress = 0
  }

  private setCanvas(startXPx: number, width: number, canvas: HTMLCanvasElement | null) {
    this.startXPx = startXPx
    this.width = width
    this.canvas = canvas
  }

  extractPreview(
    srcBlobUrl: string,
    startTimeSec: number,
    endTimeSec: number,
    starXPx: number,
    endXPx: number,
  ) {
    this.cancelExtraction()
    this.lastExtractionPromise = this.lastExtractionPromise.finally(async () => {
      try {
        let isCanceled = false
        this.cancelExtraction = () => (isCanceled = true)

        this.setExtractionStarted()

        const width = endXPx - starXPx

        if (width === 0) {
          return
        }
        const video = document.createElement('video')
        const source = document.createElement('source')
        source.type = 'video/mp4'
        source.src = srcBlobUrl
        video.appendChild(source)
        video.muted = true
        const canvas = document.createElement('canvas')
        canvas.width = width
        canvas.height = VIDEO_PREVIEW_HEIGHT
        const renderingContext = canvas.getContext('2d')!

        await new Promise((onLoadedData) => {
          video.addEventListener('loadeddata', onLoadedData)
        })

        /**
         * The "drawable piece" - the width of every image piece in the video preview collage
         */
        const drawPiecePx = 18
        const durationVideoSec = endTimeSec - startTimeSec

        /**
         *    drawPiecePx              drawPieceSec
         * -------------------  =   ------------------
         *       width               durationVideoSec
         *
         * drawPieceSec - how much the "drawable piece" (18 px) is in video duration seconds
         */
        const drawPieceSec = (durationVideoSec * drawPiecePx) / width

        let curDrawTimeSec = startTimeSec
        let curDrawPointerPx = 0

        while (curDrawPointerPx < width && !isCanceled) {
          video.currentTime = curDrawTimeSec
          this.setExtractionProgress(Math.floor((curDrawPointerPx * 100) / width))

          await new Promise((onSeeked) => {
            video.addEventListener('seeked', onSeeked, { once: true, passive: true })
          })

          renderingContext.drawImage(video, curDrawPointerPx, 0, drawPiecePx, VIDEO_PREVIEW_HEIGHT)
          curDrawPointerPx += drawPiecePx
          curDrawTimeSec += drawPieceSec
        }

        if (isCanceled) {
          return
        }

        this.setCanvas(starXPx, width, canvas)
      } catch (err) {
        console.error(err)
        this.toaster.error(err, 'psChart.error.video.overviewExtractionFailed')
      } finally {
        this.setExtractionFinished()
      }
    })
  }
}
