// TODO: remove this demo page after we integrate contentful in real pages

import { Markdown } from 'components/guide/markdown/Markdown'
import { MarkdownConfig, MD_STYLES_DEFAULT } from 'components/guide/markdown/MarkdownConfig'

import { useParams } from 'react-router-dom'
import { useContentfulMarkdownDocument } from 'hooks/contentful/useContentfulMarkdownDocument'

export const CONTENTFUL_DEMO_PATH = '/contentful-demo/:entryId'

export const ContentfulDemoPage = () => {
  const { entryId } = useParams() as { entryId: string }
  const { entry } = useContentfulMarkdownDocument(entryId)

  if (!entry) {
    return null
  }

  const templateValues = new Map()

  const config: MarkdownConfig = {
    headerLevelsToCollapse: [2],
    styles: MD_STYLES_DEFAULT,
  }
  return (
    <div style={{ margin: '3rem', maxWidth: '600px' }}>
      <Markdown markdown={entry.fields.markdown} templateValues={templateValues} config={config} />
    </div>
  )
}
