import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FlowDto, ProjectDto } from 'api/__generated__/api-types'
import { Button, ButtonVariantEnum } from 'components/Button'
import { SettingsField } from 'components/flows/ra/settings/SettingsField'
import { AutomationScript } from 'components/flows/ra/settings/AutomationScript'
import { SettingsModal } from 'components/flows/ra/settings/modal/SettingsModal'
import { RaMvpStore } from 'components/regression-analysis-mvp/RaMvpStore'
import { capitalizeFirstLetter } from 'utils/stringUtils'
import {
  InstrumentedRunCondition,
  prettifyRunCondition,
} from 'components/flows/ra/settings/settingUtils'
import { observer } from 'mobx-react-lite'
import { NotificationsModal } from 'components/flows/ra/settings/modal/NotificationsModal'

export interface SettingsTabProps {
  projectDto: ProjectDto
  flowDto: FlowDto
  raStore: RaMvpStore
  isAdmin: boolean
}

export const SettingsTab = observer(function SettingsTab(props: SettingsTabProps) {
  const { flowDto, projectDto, raStore, isAdmin } = props
  const { t } = useTranslation()

  const [showChangeModal, setShowChangeModal] = useState(false)
  const [showNotificationsModal, setShowNotificationsModal] = useState(false)

  const prepareText = useCallback((): PreparedSettings => {
    const notSet = t('ra.flow.settings.notSet')
    const config = flowDto.automationConfig!
    const devices =
      config.devices && config.devices.length > 0
        ? config.devices.map((device) => device.name).join(', ')
        : notSet
    return {
      userFlowId: config.raUserFlowId,
      devices: devices,
      priority: capitalizeFirstLetter(config.priority.toLowerCase()),
      runCount: config.runCount.toString(),
      runCountInstrumented: config.runCountInstrumented.toString(),
      instrumentedRunCondition: prettifyRunCondition(
        config.instrumentedRunCondition ?? InstrumentedRunCondition.ON_ANY_CHANGE,
      ),
      minDetectableDiffPct: config.minDetectableDiffPct,
      forceStop: config.forceStop ? t('enabled') : t('disabled'),
      clearData: config.clearData ? t('enabled') : t('disabled'),
      switchFreq: config.switchFrequency,
      instrumentedRunOnRegression: config.instrumentedRunOnRegression ? 'enabled' : 'disabled',
    }
  }, [flowDto, t])

  const data = prepareText()

  return (
    <div className="ml-flowLeftPadding mr-flowRightPadding px-2 pb-8">
      <div className="space-y-4 text-normal">
        <SettingsField label={t('ra.flow.settings.userFlowId.label')}>
          {data.userFlowId}
        </SettingsField>
        <SettingsField label={t('ra.flow.settings.devices.label')}>{data.devices}</SettingsField>
        <SettingsField label={t('ra.flow.settings.priority.label')}>{data.priority}</SettingsField>
        <SettingsField label={t('ra.flow.settings.runCount.label')}>{data.runCount}</SettingsField>
        <SettingsField label={t('ra.flow.settings.runCountInstrumented.label')}>
          {data.runCountInstrumented}
        </SettingsField>
        <SettingsField label={t('ra.flow.settings.switchFreq.label')}>
          {data.switchFreq}
        </SettingsField>
        <SettingsField label={t('ra.flow.settings.minDetectableDiffPct.label')}>
          {data.minDetectableDiffPct}
        </SettingsField>
        <SettingsField label={t('ra.flow.settings.instrumentedRunCondition.label')}>
          {data.instrumentedRunCondition}
        </SettingsField>
        <SettingsField label={t('ra.flow.settings.forceStop.label')}>
          {data.forceStop}
        </SettingsField>
        <SettingsField label={t('ra.flow.settings.clearData.label')}>
          {data.clearData}
        </SettingsField>
        <SettingsField label={t('ra.flow.settings.automationScript.label')}>
          <AutomationScript script={flowDto.automationConfig?.automationScript} />
        </SettingsField>
        <SettingsField label={t('ra.flow.settings.automationScript.labelSetup')}>
          <AutomationScript script={flowDto.automationConfig?.setupScript} />
        </SettingsField>
      </div>
      <Button
        className="mt-8"
        variant={ButtonVariantEnum.Outlined}
        isSmall
        onClick={() => setShowChangeModal(true)}
      >
        {t('ra.flow.settings.change')}
      </Button>
      <SettingsModal
        isOpen={showChangeModal}
        onClose={() => setShowChangeModal(false)}
        flowDto={flowDto}
        projectDto={projectDto}
        raStore={raStore}
        isAdmin={isAdmin}
      />
      <div className="mt-8 text-normal">
        <div className="space-x-1">
          <span className="text-gray-normal">{t('ra.flow.settings.notifications.title')}</span>
          <Button
            className="px-1"
            variant={ButtonVariantEnum.Text}
            onClick={() => setShowNotificationsModal(true)}
          >
            {t('ra.flow.settings.notifications.change')}
          </Button>
        </div>
        <div>
          <span className="text-white">
            {raStore.isSubscribedToNotifications
              ? t('ra.flow.settings.notifications.subscribed')
              : t('ra.flow.settings.notifications.unsubscribed')}
          </span>
        </div>
      </div>
      <NotificationsModal
        isOpen={showNotificationsModal}
        onClose={() => setShowNotificationsModal(false)}
        raStore={raStore}
      />
    </div>
  )
})

interface PreparedSettings {
  userFlowId: string
  devices: string
  priority: string
  runCount: string
  runCountInstrumented: string
  instrumentedRunCondition: string
  minDetectableDiffPct: number
  forceStop: string
  clearData: string
  switchFreq: number
  instrumentedRunOnRegression: string
}
