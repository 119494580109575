import { ReactNode, useLayoutEffect, useRef, useState } from 'react'
import { BodyPortal } from 'components/BodyPortal'
import { usePopper } from 'react-popper'

interface AreaSize {
  width: number
  height: number
}

export interface AreaData extends AreaSize {
  x: number
  y: number
}

export interface Area extends AreaData {
  renderChildren?: (element: HTMLElement, item: AreaData) => ReactNode
}

interface HighlightingAreasPropTypes {
  highlightingAreas: Area[]
  isTransparent: boolean
}

export const HighlightingAreas = ({
  highlightingAreas,
  isTransparent,
}: HighlightingAreasPropTypes) => {
  const wrapperRef = useRef<HTMLDivElement>(null)
  const canvasRef = useRef<HTMLCanvasElement>(null)

  useLayoutEffect(() => {
    const updateCanvas = (size: AreaSize, areas: Area[], canvasElement: HTMLCanvasElement) => {
      const ctx = canvasElement.getContext('2d')
      if (ctx && size) {
        ctx.canvas.width = size.width
        ctx.canvas.height = size.height
        ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height)

        ctx.fillStyle = isTransparent ? 'transparent' : 'rgba(0,0,0,.6)'
        ctx.fillRect(0, 0, ctx.canvas.width, ctx.canvas.height)

        areas.forEach((rect) => {
          ctx.clearRect(rect.x, rect.y, rect.width, rect.height)
        })
      }
    }

    const element = wrapperRef.current
    const canvasElement = canvasRef.current
    if (element && canvasElement) {
      updateCanvas(
        { width: element.clientWidth, height: element.clientHeight },
        highlightingAreas,
        canvasElement,
      )

      const resizeObserver = new ResizeObserver(([wrapperEntry]) => {
        const newCanvasSize = {
          width: wrapperEntry.target.clientWidth,
          height: wrapperEntry.target.clientHeight,
        }
        updateCanvas(newCanvasSize, highlightingAreas, canvasElement)
      })
      resizeObserver.observe(element)

      return () => {
        resizeObserver.unobserve(element)
      }
    }
  }, [highlightingAreas, isTransparent])

  return (
    <div ref={wrapperRef} className="absolute top-0 right-0 bottom-0 left-0">
      <canvas ref={canvasRef} className="absolute top-0 left-0" />
      {highlightingAreas.map((item, index, all) => (
        <HighlightingArea
          key={index}
          item={item}
          counter={index + 1}
          isCounterShown={all.length > 1}
        />
      ))}
    </div>
  )
}

interface HighlightingAreaPropTypes {
  item: Area
  counter: number
  isCounterShown: boolean
}
const HighlightingArea = ({ item, counter, isCounterShown }: HighlightingAreaPropTypes) => {
  const [areaElement, setAreaElement] = useState<HTMLDivElement | null>(null)
  const [counterElement, setCounterElement] = useState<HTMLDivElement | null>(null)

  const { styles, attributes, update } = usePopper(areaElement, counterElement, {
    placement: 'top-start',
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [0, 4],
        },
      },
      {
        name: 'preventOverflow',
        options: {
          mainAxis: false,
        },
      },
    ],
  })

  useLayoutEffect(() => {
    if (update) {
      update()
    }
  }, [update, item])

  const { x, y, width, height } = item
  return (
    <div
      ref={setAreaElement}
      className="absolute border-electro border-[2px] border-solid"
      style={{ top: y, left: x, width: width, height: height }}
    >
      <BodyPortal>
        <>
          {isCounterShown && (
            <div
              ref={setCounterElement}
              className="z-header text-mini h-[16px] bg-electro rounded-sm px-[8px]"
              style={styles.popper}
              {...attributes.popper}
            >
              {counter}
            </div>
          )}
          {item.renderChildren &&
            areaElement &&
            item.renderChildren(areaElement, { x, y, width, height })}
        </>
      </BodyPortal>
    </div>
  )
}
