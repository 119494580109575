import styled, { css } from 'styled-components/macro'
import { Icon } from 'components/Icon'
import { colors } from 'utils/styles/colors'

type Props = {
  isActive?: boolean
  isDisabled?: boolean
  size: number
  fontWeight?: string
  display?: string
  isHold?: boolean
}

export const IconColored = styled(Icon)<Props>`
  display: inline-block;
  color: ${colors.gray.normal};

  ${(props) => css`
    font-size: ${props.size}px;

    ${props.display &&
    css`
      display: ${props.display};
    `}

    ${props.fontWeight &&
    css`
      font-weight: ${props.fontWeight};
    `}

    ${props.isActive &&
    css`
      color: ${colors.electro};
    `}

    ${props.isDisabled &&
    css`
      opacity: 0.5;
      color: ${colors.gray.normal};
    `}

    &:hover {
      ${props.isActive &&
      css`
        color: ${colors.sky};
      `}

      ${!props.isDisabled &&
      css`
        cursor: pointer;
        color: ${props.isHold ? colors.electro : colors.white};
      `}

      ${props.isDisabled &&
      css`
        cursor: not-allowed;
        color: ${colors.gray.normal};
      `}
    }
  `}
`
