import { Link } from 'react-router-dom'
import { QUICKSTART_DOCS } from 'api/contentful'
import { Icon } from 'components/Icon'
import { Markdown } from 'components/guide/markdown/Markdown'
import { QuickstartPages } from 'components/quickstart/models'
import { QUICKSTART, QUICKSTART_MARKDOWN_CONFIG } from 'components/quickstart/constants'
import { useModal } from 'hooks/useModal'
import { useContentfulEntry } from 'hooks/contentful/useContentfulEntry'
import { isTypeQuickstartPage } from 'hooks/contentful/useContentfulEntriesByType'

export const Header = () => {
  const { paramValue } = useModal(QUICKSTART)
  const { entry } = useContentfulEntry(QUICKSTART_DOCS.intro)

  const isHeaderContentPreset = entry && isTypeQuickstartPage(entry)
  const templateValues = new Map()

  switch (paramValue) {
    case QuickstartPages.Contents:
      return (
        <>
          <div className="min-h-[120px]">
            {isHeaderContentPreset && (
              <Markdown
                config={QUICKSTART_MARKDOWN_CONFIG}
                markdown={entry.fields.body}
                templateValues={templateValues}
              />
            )}
          </div>
          <hr className={`mt-[40px] mb-[20px] border-gray-strokeLight w-full`} />
        </>
      )
    default:
      return (
        <>
          <div className="flex items-center">
            <Link
              className="mt-[40px] flex items-center h-[22px] text-gray-normal hover:text-white font-[500]"
              to={{
                pathname: location.pathname,
                search: `?quickstart=${QuickstartPages.Contents}`,
              }}
            >
              <Icon
                icon="auth-arrow-r"
                className="text-[28px] flex items-center rotate-180 h-[30px] w-[20px]"
              />
              <span className="text-[12px]">Steps overvew</span>
            </Link>
          </div>
          <hr className={`mt-[40px] mb-[20px] border-gray-strokeLight w-full`} />
        </>
      )
  }
}
