import { useApi } from 'contexts/di-context'
import { useCallback } from 'react'

export const useFreeTrialShareLink = (freeTrialTeamUrlName?: string) => {
  const api = useApi()

  const getShareLink = useCallback(async () => {
    if (freeTrialTeamUrlName == null) {
      throw new Error('The Free Trial Team is not found')
    }
    const urlDto = await api.getTeamShareUrl(freeTrialTeamUrlName)
    return urlDto.url
  }, [freeTrialTeamUrlName, api])

  const sendShareLink = useCallback(
    (emails: string[]) => {
      if (freeTrialTeamUrlName == null) {
        throw new Error('The Free Trial Team is not found')
      }
      return api.postTeamShareUrl(freeTrialTeamUrlName, emails)
    },
    [freeTrialTeamUrlName, api],
  )

  const isShareLinkReady = freeTrialTeamUrlName != null

  return {
    getShareLink,
    sendShareLink,
    isShareLinkReady,
  }
}
