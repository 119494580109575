import React from 'react'

import { Breadcrumbs } from './Breadcrumbs'
import { BreadcrumbsTeamSection } from './BreadcrumbsTeamSection'
import { BreadcrumbsProjectSection } from './BreadcrumbsProjectSection'
import { BreadcrumbsFlowSection } from './BreadcrumbsFlowSection'
import { BreadcrumbsTraceSection } from './BreadcrumbsTraceSection'

export const TracePageBreadcrumbs = () => (
  <Breadcrumbs>
    <BreadcrumbsTeamSection />
    <BreadcrumbsProjectSection />
    <BreadcrumbsFlowSection />
    <BreadcrumbsTraceSection />
  </Breadcrumbs>
)
