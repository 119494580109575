import { Dispatch, SetStateAction } from 'react'
import { useTranslation } from 'react-i18next'
import { Modal, ModalSizeEnum } from 'components/Modal'
import { ButtonTextColorVariantEnum } from 'components/Button'
import { PinType } from 'components/ps-chart/stores/AnnotationsStore'

interface Props {
  onDelete: () => void
  isOpen: boolean
  setIsOpen: Dispatch<SetStateAction<boolean>>
  pinType: PinType
}

export const DeleteAnnotationModal = ({ onDelete, isOpen, setIsOpen, pinType }: Props) => {
  const { t } = useTranslation()

  const firstPinType =
    PinType.ACTION === pinType
      ? t('annotations.delete.anAction')
      : t('annotations.delete.aReaction')
  const secondPinType =
    PinType.ACTION === pinType ? t('annotations.delete.reaction') : t('annotations.delete.action')

  return (
    <Modal
      title={t('annotations.delete.title')}
      isOpen={isOpen}
      size={ModalSizeEnum.NORMAL}
      onClose={() => setIsOpen(false)}
      actionButton={{
        children: t('delete'),
        textColorVariant: ButtonTextColorVariantEnum.Bad,
        onClick: onDelete,
      }}
      hiddenCloseButton={false}
    >
      <div className="flex min-h-[51px] items-center">
        <p className="text-small text-gray-normal">
          {t('annotations.delete.text', {
            firstPinType,
            secondPinType,
          })}
        </p>
      </div>
    </Modal>
  )
}
