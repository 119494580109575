import { useEffect, useState } from 'react'
import NProgress from 'nprogress'
import { useAuth } from 'contexts/auth-context'

export const usePreDefinedCompanyName = (teamToken?: string) => {
  const [preDefinedCompanyName, setPreDefinedCompanyName] = useState<string | null>(null)
  const [isTokenCheckFailed, setIsTokenCheckFailed] = useState(false)
  const { signUpCheckToken } = useAuth()

  useEffect(() => {
    if (teamToken == null) {
      return
    }
    NProgress.start()
    signUpCheckToken({ teamToken, allowFreeTrial: true, returnData: true })
      .then((data) => {
        if (data.companyName != null) {
          setPreDefinedCompanyName(data.companyName)
        }
      })
      .catch((error) => {
        console.error(error)
        setIsTokenCheckFailed(true)
      })
      .finally(() => {
        NProgress.done()
      })
  }, [teamToken, signUpCheckToken])

  return { preDefinedCompanyName, isTokenCheckFailed }
}
