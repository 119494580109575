import { Fragment, useLayoutEffect, useMemo, useRef } from 'react'
import { Dialog, Transition, Disclosure } from '@headlessui/react'
import { useModal } from 'hooks/useModal'
import { Markdown } from 'components/guide/markdown/Markdown'
import { QUICKSTART_MARKDOWN_CONFIG } from 'components/quickstart/constants'
import { Button, ButtonVariantEnum } from 'components/Button'
import { Icon } from 'components/Icon'
import classNames from 'classnames'
import { LiveDemoController } from 'components/live-demo/LiveDemoController'
import { observer } from 'mobx-react-lite'

export const LIVE_DEMO_GUIDE_MODAL = 'live-demo-guide'

interface LiveDemoStepsModalProps {
  liveDemoController: LiveDemoController
}

export const LiveDemoStepsModal = observer(function LiveDemoStepsModal({
  liveDemoController,
}: LiveDemoStepsModalProps) {
  const { closeModal, isModalOpen, paramValue } = useModal(LIVE_DEMO_GUIDE_MODAL)
  const partNumber = paramValue ? Number(paramValue) : 1

  const { liveDemoData, currentStep } = liveDemoController
  const { description, buttonLabel, listHeader, cover, parts } = liveDemoData.fields

  const templateValues = useMemo(() => new Map(), [])
  const currentPartRef = useRef<HTMLDivElement>(null)

  useLayoutEffect(() => {
    const { stepNumber } = currentStep
    const shouldScroll = stepNumber !== 1 || partNumber !== 1
    if (isModalOpen && shouldScroll) {
      const timeoutID = setTimeout(() => {
        if (currentPartRef.current) {
          currentPartRef.current.scrollIntoView()
        }
      }, 10)
      return () => clearTimeout(timeoutID)
    }
  }, [isModalOpen, currentPartRef, currentStep, partNumber])

  return (
    <Transition
      show={isModalOpen}
      as={Fragment}
      enter="transition-opacity duration-75"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="transition-opacity duration-150"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      <Dialog onClose={closeModal}>
        <div className="fixed inset-0 h-[100vh] bg-dark-dark4 opacity-70" />
        <div className="fixed z-[999] inset-0">
          <div
            className="flex items-center justify-center min-h-screen"
            data-testid="liveDemoModal"
          >
            <Dialog.Panel className="max-h-[88vh] w-[692px] rounded-md flex flex-col justify-between bg-dark-dark5 overflow-x-hidden">
              <img
                alt={cover.fields.title}
                src={cover.fields.file.url}
                className="w-full h-fit m-0 p-0 rounded-t-md min-h-[390px]"
              />
              <div className="flex flex-col px-[32px] pb-[48px]">
                {description && (
                  <div className="mb-[40px]">
                    <Markdown
                      config={QUICKSTART_MARKDOWN_CONFIG}
                      markdown={description}
                      templateValues={templateValues}
                    />
                  </div>
                )}
                {buttonLabel && (
                  <Button
                    data-testid="liveDemoModalEnableButton"
                    className="mb-[40px]"
                    variant={ButtonVariantEnum.Outlined}
                    onClick={() => {
                      liveDemoController.enable()
                      closeModal()
                    }}
                  >
                    {buttonLabel}
                  </Button>
                )}
                {listHeader && <div className="text-white font-medium mb-[24px]">{listHeader}</div>}
                <hr className={`mb-[20px] border-gray-strokeLight w-full`} />
                {parts?.map((part, partIndex) => (
                  <div
                    key={partIndex}
                    ref={currentStep.partNumber === partIndex + 1 ? currentPartRef : undefined}
                  >
                    <Disclosure defaultOpen={partNumber === partIndex + 1}>
                      {({ open }) => (
                        <>
                          <Disclosure.Button className="flex cursor-pointer">
                            <Icon
                              icon="arrow-collapse-d"
                              className={classNames(
                                'w-[16px] mr-[4px] translate-y-[-1px]',
                                !open && '-rotate-90',
                              )}
                            />
                            <span className="text-white font-medium text-normal">
                              {part.fields.title}
                            </span>
                          </Disclosure.Button>
                          <Disclosure.Panel className="pl-[20px] pt-[16px] pb-[24px]">
                            {part.fields.steps?.map((step, stepIndex) => (
                              <div
                                key={stepIndex}
                                className={
                                  'border-b-[1px] border-b-gray-strokeLight text-normal text-white pt-[9.23px] pb-[11.77px] cursor-pointer'
                                }
                                onClick={() => {
                                  liveDemoController.goToStepById(step.sys.id)
                                  closeModal()
                                }}
                              >
                                {step.fields.title || step.fields.description}
                              </div>
                            ))}
                          </Disclosure.Panel>
                        </>
                      )}
                    </Disclosure>
                  </div>
                ))}
              </div>
            </Dialog.Panel>
          </div>
        </div>
      </Dialog>
    </Transition>
  )
})
