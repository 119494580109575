import { StorageItem } from './StorageItem'

export class BooleanStorageItem extends StorageItem<boolean> {
  get value(): boolean {
    if (!this.localStorageValue) {
      return false
    }
    return this.localStorageValue.toLowerCase() == 'true' || this.localStorageValue == '1'
  }

  set value(newValue: boolean) {
    this.localStorageValue = String(newValue)
  }
}
