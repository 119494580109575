import { TypeLiveDemoData, TypeLiveDemoSteps } from 'hooks/__generated__/contentful'
import { makeAutoObservable } from 'mobx'
import { psLocalStorage } from 'utils/localStorage/PsLocalStorage'
import { TypeLiveDemoStepsFields } from 'hooks/__generated__/contentful/TypeLiveDemoSteps'

type ViewMode = TypeLiveDemoStepsFields['viewMode']

export const LIVE_DEMO_TABS: Partial<{ [Property in NonNullable<ViewMode>]: Property }> = {
  annotation: 'annotation',
  process: 'process',
  trace: 'trace',
}

export type LiveDemoTab = typeof LIVE_DEMO_TABS[NonNullable<ViewMode>]

export interface Step {
  data: TypeLiveDemoSteps
  stepNumber: number
  partNumber: number
  isLastStep: boolean
  isLastPart: boolean
}

export class LiveDemoController {
  enabled = true

  private readonly steps: Step[] = []
  readonly liveDemoData: TypeLiveDemoData
  private currentStepIndex = 0
  currentTab: LiveDemoTab
  withFooter = false

  constructor(liveDemoData: TypeLiveDemoData) {
    this.liveDemoData = liveDemoData

    liveDemoData.fields.parts.forEach((part, partIndex, allParts) =>
      part.fields.steps.forEach((step, stepIndex, allSteps) => {
        this.steps.push({
          data: step,
          partNumber: partIndex + 1,
          stepNumber: stepIndex + 1,
          isLastPart: partIndex === allParts.length - 1,
          isLastStep: stepIndex === allSteps.length - 1,
        })
      }),
    )

    const persistedStepIndex = this.steps.findIndex(
      (step) => step.data.sys.id === psLocalStorage.getLiveDemoCurrentStep(liveDemoData.sys.id),
    )
    if (persistedStepIndex !== -1) {
      this.setCurrentStep(persistedStepIndex)
    }

    makeAutoObservable(this)
  }

  get currentStep(): Step {
    return this.steps[this.currentStepIndex]
  }

  get showGoPrev() {
    return this.currentStepIndex !== 0
  }

  private setCurrentStep = (stepIndex: number) => {
    const lastStepIndex = this.steps.length - 1
    const newStepIndex = Math.max(0, Math.min(lastStepIndex, stepIndex))

    this.currentStepIndex = newStepIndex
    psLocalStorage.setLiveDemoCurrentStep(
      this.liveDemoData.sys.id,
      this.steps[newStepIndex].data.sys.id,
    )

    this.setCurrentTab(
      this.steps[newStepIndex].data.fields.viewMode !== undefined
        ? this.steps[newStepIndex].data.fields.viewMode
        : LIVE_DEMO_TABS.trace,
    )
  }

  setCurrentTab = (tab: LiveDemoTab) => {
    this.currentTab = tab
  }

  goToStepById = (stepId: string) => {
    this.setCurrentStep(this.steps.findIndex((step) => step.data.sys.id === stepId))
    this.enable()
  }

  goNextStep = () => {
    this.setCurrentStep(this.currentStepIndex + 1)
  }

  handleNextClick = () => {
    if (this.currentStep.isLastStep && this.currentStep.isLastPart) {
      this.disable()
    }
    this.goNextStep()
  }

  goPrevStep = () => {
    this.setCurrentStep(this.currentStepIndex - 1)
  }

  disable = () => {
    this.enabled = false
  }

  enable = () => {
    this.enabled = true
    this.setCurrentTab(
      this.steps[this.currentStepIndex].data.fields.viewMode !== undefined
        ? this.steps[this.currentStepIndex].data.fields.viewMode
        : LIVE_DEMO_TABS.trace,
    )
  }

  enableFooter = () => {
    this.withFooter = true
  }
}
