import { getServerHost } from 'utils/getServerHost'
import { getServerProtocol } from 'utils/getServerProtocol'
import { ChartPageParams, FlowReq, TracePageParams } from 'api/models'

export const VERSION_PREFIX = '/api/v1'

export const URL_PREFIX = `${getServerProtocol()}//${getServerHost()}${VERSION_PREFIX}`

export const getExtensionRequestUrl = (teamUrlName: string) =>
  `${URL_PREFIX}/teams/${teamUrlName}/free-trial/extension-request`

export const getTraceVideoMetadataUrl = (projectUrlName: string, traceProjectLocalId: string) =>
  `${URL_PREFIX}/projects/${projectUrlName}/traces/${traceProjectLocalId}/video`

export const getFlowTracesUrl = (projectUrlName: string, flowProjectLocalId: string) =>
  `${URL_PREFIX}/projects/${projectUrlName}/flows/${flowProjectLocalId}/traces`

export const getProjectsSummaryUrl = (teamUrlName: string) =>
  `${URL_PREFIX}/teams/${teamUrlName}/projects-summary`

export const getUserUrl = () => `${URL_PREFIX}/user`

export const getTeamsUrl = () => `${URL_PREFIX}/teams`

export const getOnboardingRequestsUrl = (teamUrlName: string) =>
  `${URL_PREFIX}/teams/${teamUrlName}/free-trial/onboarding-requests`

export const getShareUrlUrl = (teamUrlName: string) =>
  `${URL_PREFIX}/teams/${teamUrlName}/share-url`

export const getBuildPropertiesUrl = (projectUrlName: string) =>
  `${URL_PREFIX}/projects/${projectUrlName}/build-properties`

export const getAndroidPluginVersionsUrl = () => `${URL_PREFIX}/plugin-versions/android`

export const getInstructionsStateUrl = (projectUrlName: string) =>
  `${URL_PREFIX}/projects/${projectUrlName}/instructions-state`

export const getGlobalLiveDemoUrl = () => `${URL_PREFIX}/live-demo`

export const getTraceFileUrl = (projectUrlName: string, traceProjectLocalId: string) =>
  `${URL_PREFIX}/projects/${projectUrlName}/storage/traces/${traceProjectLocalId}/url`

export const getAnnotationsUrl = (chartPageParams: ChartPageParams) =>
  `${URL_PREFIX}/projects/${chartPageParams.projectUrlName}/flows/${chartPageParams.flowProjectLocalId}/traces/${chartPageParams.traceProjectLocalId}/annotations`

export const getFlagsUrl = (chartPageParams: ChartPageParams) =>
  `${URL_PREFIX}/projects/${chartPageParams.projectUrlName}/flows/${chartPageParams.flowProjectLocalId}/traces/${chartPageParams.traceProjectLocalId}/flags`

export const getNamedLinksUrl = (projectUrlName: string) =>
  `${URL_PREFIX}/projects/${projectUrlName}/named-links`

export const getTraceSettingsUrl = (params: TracePageParams) =>
  `${URL_PREFIX}/projects/${params.projectUrlName}/traces/${params.traceProjectLocalId}/settings`

export const getTraceConnectionsUrl = (params: TracePageParams) =>
  `${URL_PREFIX}/projects/${params.projectUrlName}/traces/${params.traceProjectLocalId}/connections`

export const getTraceEntityUrl = (params: TracePageParams) =>
  `${URL_PREFIX}/projects/${params.projectUrlName}/traces/${params.traceProjectLocalId}`

export const getProjectUrl = (projectUrlName: string) => `${URL_PREFIX}/projects/${projectUrlName}`

export const getFlowsUrl = (projectUrlNameName: string) =>
  `${URL_PREFIX}/projects/${projectUrlNameName}/flows`

export const getFlowUrl = (params: FlowReq) =>
  `${URL_PREFIX}/projects/${params.projectUrlName}/flows/${params.flowProjectLocalId}`

export const getMetricsUrl = () => `${URL_PREFIX}/metrics`

export const getAllProjectsUrl = () => `${URL_PREFIX}/projects`
