import React, { useRef, forwardRef } from 'react'
import { Input, InputProps } from 'components/Input'
import { Icon } from 'components/Icon'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'
import { twMerge } from 'tailwind-merge'

export type InputPasswordProps = {
  checked?: boolean
  visible?: boolean
  onBlur?: React.FocusEventHandler<Element>
  onMakeVisibleButtonClick?: () => void
} & InputProps

export const InputPassword = forwardRef<HTMLInputElement, InputPasswordProps>(
  (props, forwardInputRef) => {
    const { className, visible, onMakeVisibleButtonClick, checked, onBlur, ...otherProps } = props
    const fallbackInputRef = useRef(null)
    const makeVisibleButtonRef = useRef(null)
    const { t } = useTranslation()
    const inputRef = (forwardInputRef ||
      fallbackInputRef) as React.MutableRefObject<HTMLInputElement>

    return (
      <Input
        className={classNames('relative pr-[30px]', className)}
        autoComplete="new-password"
        name="new-password"
        required
        ref={inputRef}
        onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
          if (onBlur && e.relatedTarget !== makeVisibleButtonRef.current) {
            onBlur(e)
          }
        }}
        {...otherProps}
        type={visible ? 'text' : 'password'}
      >
        <button
          className="flex absolute top-0 right-0 bottom-0 pr-[9px]"
          type="button"
          name="toggle-password"
          aria-label={t('auth.common.showPasswordLabel')}
          ref={makeVisibleButtonRef}
          onClick={onMakeVisibleButtonClick}
          onBlur={(e: React.FocusEvent<HTMLButtonElement>) => {
            if (onBlur && e.relatedTarget !== inputRef.current) {
              onBlur(e)
            }
          }}
        >
          {checked && (
            <Icon icon="check" className="text-[32px] text-state-good translate-y-[-10px]" />
          )}
          <Icon
            icon="eye"
            className={twMerge(
              'block text-[20px] text-gray-normal cursor-pointer hover:text-white',
              visible && 'text-electro hover:text-sky',
            )}
          />
        </button>
      </Input>
    )
  },
)
