import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Button, ButtonVariantEnum } from 'components/Button'
import { Link } from 'react-router-dom'
import {
  INITIAL_MARKDOWN,
  INITIAL_MARKDOWN_ADOPTED,
  INITIAL_MARKDOWN_SIMPLE,
  INITIAL_MARKDOWN_WITH_REACT,
} from 'pages/MdToHtmlConstants'
import { Markdown } from 'components/guide/markdown/Markdown'
import {
  HeaderLevel,
  MarkdownConfig,
  MD_STYLES_DEFAULT,
} from 'components/guide/markdown/MarkdownConfig'
import { colors } from 'utils/styles/colors'

export const MD_TO_HTML_PATH = '/md-to-html'

enum StubType {
  SAMPLE,
  CI_CD,
  ANDROID_BUILD,
  REACT,
}

/**
 * Temporary
 * todo: delete when guides integrated
 */
export const MdToHtmlPage = () => {
  const textAreaRef = useRef<HTMLTextAreaElement>(null)

  const [md, setMd] = useState<string>('')
  const [levels, setLevels] = useState<HeaderLevel[]>([1, 2, 3, 4, 5, 6])

  const transform = useCallback(() => {
    setMd(textAreaRef.current?.value ?? '')
  }, [])

  useEffect(() => {
    if (textAreaRef.current !== null) {
      textAreaRef.current.value = INITIAL_MARKDOWN_SIMPLE
    }
  }, [textAreaRef])

  const SampleButton = () => {
    const [count, setCount] = useState<number>(0)
    return (
      <Button
        variant={ButtonVariantEnum.Outlined}
        borderColor={colors.state.good}
        className="mt-[32px]"
        onClick={() => setCount(count + 1)}
      >
        increment
        {count}
      </Button>
    )
  }

  const templateValues = useMemo(() => {
    const result = new Map()
    result.set('testComponent', <SampleButton />)
    result.set('androidRelease', '14.1.2')
    result.set('propertiesFileLink', 'https://product-science.github.io/android/gradle/')
    result.set('projectId', '75112492')
    result.set(
      'readToken',
      't02_FkigU72jyNnF3jgkI_afdrfUXbD8NrLQB1HZhH_Uh5zlpObRrNF-gCfRjnUmso8fAkBDn4BtvLpToNcMasxasxasSAX',
    )
    result.set('downloadFileLink', 'https://product-science.github.io/android/gradle/')
    return result
  }, [])

  const result = useMemo(() => {
    const config: MarkdownConfig = {
      headerLevelsToCollapse: levels,
      styles: MD_STYLES_DEFAULT,
    }
    return <Markdown markdown={md} templateValues={templateValues} config={config} />
  }, [levels, md, templateValues])

  const reload = useCallback((type: StubType) => {
    if (textAreaRef.current) {
      switch (type) {
        case StubType.SAMPLE:
          setLevels([1, 2, 3, 4, 5, 6])
          textAreaRef.current.value = INITIAL_MARKDOWN_SIMPLE
          break
        case StubType.CI_CD:
          setLevels([2])
          textAreaRef.current.value = INITIAL_MARKDOWN_ADOPTED
          break
        case StubType.ANDROID_BUILD:
          setLevels([2])
          textAreaRef.current.value = INITIAL_MARKDOWN
          break
        case StubType.REACT:
          setLevels([2])
          textAreaRef.current.value = INITIAL_MARKDOWN_WITH_REACT
          break
      }
    }
  }, [])

  return (
    <div className="mt-5 pb-20 mx-10 max-w-[66ch]">
      <h1 className="text-header-big mb-5">Markdown to HTML transformer</h1>
      <div className="space-y-4">
        <Link to="/">Main</Link>
        <div>
          <h2 className="mb-2">Markdown source:</h2>
          <textarea
            className="block w-full text-black focus:outline-1 text-small"
            rows={10}
            ref={textAreaRef}
          />
        </div>
        <div className="space-x-2">
          <Button variant={ButtonVariantEnum.Contained} isSmall onClick={transform}>
            Transform
          </Button>
          <Button variant={ButtonVariantEnum.Text} isSmall onClick={() => reload(StubType.SAMPLE)}>
            Sample
          </Button>
          <Button variant={ButtonVariantEnum.Text} isSmall onClick={() => reload(StubType.CI_CD)}>
            CI/CD
          </Button>
          <Button
            variant={ButtonVariantEnum.Text}
            isSmall
            onClick={() => reload(StubType.ANDROID_BUILD)}
          >
            Build.gradle
          </Button>
          <Button variant={ButtonVariantEnum.Text} isSmall onClick={() => reload(StubType.REACT)}>
            React
          </Button>
        </div>
        <h2>HTML result:</h2>
      </div>
      <div className="text-normal text-gray-normal">{result}</div>
    </div>
  )
}
