import { colors } from 'utils/styles/colors'

export class AnnotationsSettings {
  readonly pinHeight = 14
  readonly pinWidth = 16
  readonly strokeWidth = 1

  readonly dragPadding = 4
  readonly pinLegHeight = 2
  readonly pinTextWidth = 85
  readonly pinTextPadding = 5
  readonly pinTextVerticalMargin = 4
  readonly pinTextFrameCornerRadius = 2

  readonly fontSize = 10
  readonly fontFamily = 'Manrope'

  readonly pinColor = colors.gray.normal
  readonly pinHoveredColor = colors.white
  readonly pinSelectedBgColor = colors.gray.normal
  readonly pinLinkedToSliceColor = colors.gray.normal
  readonly textColor = colors.gray.normal
  readonly textHoveredColor = colors.white
  readonly textSelectedColor = colors.dark.dark5
  readonly frameColor = colors.gray.dark
  readonly delayColor = colors.state.bad
  readonly connectionLineColor = colors.gray.faded
  readonly bgColor = colors.dark.dark5

  readonly connectionDotSize = 2
  readonly connectionActiveDotSize = 2.5

  readonly titleTextLength = 35
  readonly descriptionTextLength = 160
}

export const annotationsSettings = new AnnotationsSettings()
