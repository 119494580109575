import React, { useEffect } from 'react'
import { RaMvpStore } from 'components/regression-analysis-mvp/RaMvpStore'
import { observer } from 'mobx-react-lite'
import { RunDetails } from 'components/flows/ra/runs/RunDetails'
import { RunSummary } from 'components/flows/ra/runs/RunSummary'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'
import { Run } from 'components/regression-analysis-mvp/Run'

export interface RunsTabProps {
  raStore: RaMvpStore
}

export const RunsTab = observer(function RunsTab(props: RunsTabProps) {
  const { t } = useTranslation()
  const { raStore } = props
  const [searchParams, setSearchParams] = useSearchParams()

  /**
   * runId is used to open a run details when the URL contains a runId query parameter
   * It's only a one-time action, so we remove the query parameter after opening the run
   */
  const openRunId = searchParams.get('runId')
  useEffect(() => {
    if (openRunId && raStore.isLoaded) {
      searchParams.delete('runId')
      setSearchParams(searchParams, { replace: true })
    }
  }, [openRunId, raStore.isLoaded, searchParams, setSearchParams])
  return (
    <div className="ml-flowLeftPadding mr-flowRightPadding mb-8 px-2 text-gray-normal space-y-4 w-[80ch]">
      {raStore.runs.length === 0 && <p className="text-normal">{t('ra.flow.emptyRuns')}</p>}
      {raStore.runs.map((run) => (
        <RunView key={run.id} raStore={raStore} run={run} open={run.id === openRunId} />
      ))}
    </div>
  )
})

interface RunProps {
  run: Run
  raStore: RaMvpStore
  open?: boolean
}

const RunView = ({ raStore, run, open }: RunProps) => {
  const detailsRef = React.useRef<HTMLDetailsElement>(null)
  useEffect(() => {
    if (open && detailsRef.current != null) {
      detailsRef.current.open = true
      detailsRef.current.scrollIntoView({ behavior: 'auto', block: 'start' })
    }
  }, [open])
  return (
    <details ref={detailsRef} className="text-normal leading-8">
      <summary>
        <RunSummary run={run} />
      </summary>
      <div className="ml-5 py-2 border rounded border-gray-faded">
        <RunDetails raStore={raStore} run={run} />
      </div>
    </details>
  )
}
