import {
  AnnotationIdAndType,
  AnnotationsStore,
  PinType,
} from 'components/ps-chart/stores/AnnotationsStore'
import { useCallback } from 'react'
import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'
import { VideoPlayerState } from 'components/ps-chart/stores/VideoPlayerStore'
import { AnnotationPin } from 'components/annotations/details-view/create/AnnotationPin'
import { useToaster } from 'hooks/useToaster'
import { AnnotationFormButtons } from 'components/annotations/details-view/AnnotationFormButtons'

interface Props {
  annotationId: number
  onSave: () => void
  onCancel: () => void
  annotationsStore: AnnotationsStore
  videoPlayerState: VideoPlayerState
}

/**
 * @description: Create new annotation
 */
export const CreateAnnotationForm = observer(function CreateAnnotationForm(props: Props) {
  const { annotationId, onSave, onCancel, annotationsStore, videoPlayerState } = props

  const { t } = useTranslation()
  const toaster = useToaster()

  const annotation = annotationsStore.getById(annotationId)!

  const updateTitleAndDescription = useCallback(
    (idAndType: AnnotationIdAndType, title: string, description: string) => {
      annotationsStore.updateTitleAndDescriptionLocally(idAndType, title, description)
    },
    [annotationsStore],
  )

  const placeOnTimeline = useCallback(
    (idAndType: AnnotationIdAndType) => {
      const hasTitle = AnnotationsStore.getPinTitle(annotation, idAndType.type).length > 0
      if (hasTitle) {
        annotationsStore.updateTimeLocally(videoPlayerState.traceVideoPointerTimeNanos, idAndType)
      } else {
        const error =
          idAndType.type === PinType.ACTION
            ? t('annotations.validation.specifyActionTitle')
            : t('annotations.validation.specifyReactionTitle')
        toaster.error(error, 'psChart.annotation.error.placeOnTimeline')
      }
    },
    [annotation, annotationsStore, t, toaster, videoPlayerState.traceVideoPointerTimeNanos],
  )

  const changeDelay = useCallback(
    (id: number, delay: boolean) =>
      annotationsStore.updateDelayLocally(id, AnnotationsStore.delay(delay)),
    [annotationsStore],
  )

  return (
    <>
      <AnnotationPin
        annotation={annotation}
        type={PinType.ACTION}
        onUpdateTitleAndDescription={updateTitleAndDescription}
        onPlaceOnTimeline={placeOnTimeline}
        onChangeDelay={changeDelay}
      />
      <AnnotationPin
        annotation={annotation}
        type={PinType.REACTION}
        onUpdateTitleAndDescription={updateTitleAndDescription}
        onPlaceOnTimeline={placeOnTimeline}
        onChangeDelay={changeDelay}
      />
      <AnnotationFormButtons onSave={onSave} onCancel={onCancel} />
    </>
  )
})
