import React from 'react'
import { ActionTooltip } from 'components/ActionTooltip'
import { observer } from 'mobx-react-lite'
import { PsChartStore } from 'components/ps-chart/PsChartStore'
import { Button, ButtonVariantEnum } from 'components/Button'

export const ACTION_CHANGE_CONNECTION_DIRECTION_TYPE = 'directionType'

interface ConnectionDirectionActionProps {
  psChartStore: PsChartStore
}

export const ConnectionDirectionAction = observer(function RenderTypeModeAction({
  psChartStore,
}: ConnectionDirectionActionProps) {
  return (
    <ActionTooltip place="top" tooltipId={ACTION_CHANGE_CONNECTION_DIRECTION_TYPE}>
      <div className="flex items-center justify-center align-center w-[64px] h-[32px] bg-dark-dark3">
        <Button
          variant={ButtonVariantEnum.Text}
          onClick={() => psChartStore.toggleConnectionDirection()}
        >
          {psChartStore.traceAnalyzeStore.isForwardConnectionsEnabled ? 'FRWD' : 'BKWD'}
        </Button>
      </div>
    </ActionTooltip>
  )
})
