import React, { useEffect, useMemo } from 'react'
import { Link, useParams } from 'react-router-dom'
import NProgress from 'nprogress'
import { Helmet } from 'react-helmet'
import { Layout } from 'components/Layout'
import { useFlowQuery, useFlowTracesQuery } from 'hooks/useApiQuery'
import { BaseHeader } from 'components/header/BaseHeader'
import { PageTitle } from 'components/header/PageTitle'

import { getCcoPath } from 'components/cco/useCcoParams'
import { prettifyDate } from 'components/flows/ra/runs/runUtils'

export const PATH_CCO_SNAPSHOTS = '/projects/:projectUrlName/cco/snapshots'

export const CCOSnapshotsPage = () => {
  const { projectUrlName } = useParams() as {
    projectUrlName: string
  }
  const flowProjectLocalId = '1'

  const { isSuccess: isFlowSuccess, data: flowData } = useFlowQuery({
    projectUrlName,
    flowProjectLocalId,
  })
  const { isSuccess: isFlowTracesSuccess, data: flowTracesData } = useFlowTracesQuery({
    projectUrlName,
    flowProjectLocalId,
  })

  const isLoaded = isFlowSuccess && isFlowTracesSuccess

  useEffect(() => {
    if (isLoaded) {
      NProgress.done()
    } else {
      NProgress.start()
    }
  }, [isLoaded])

  const header = useMemo(
    () => (
      <BaseHeader
        //leftSideContent={<FlowTracesPageBreadcrumbs />}
        centerContent={<PageTitle titleKey="snapshots" />}
      />
    ),
    [],
  )

  return (
    <Layout headerComponent={header}>
      {isLoaded && (
        <>
          {flowData && (
            <Helmet>
              <title>{flowData.name}</title>
            </Helmet>
          )}
          <table className="w-3/4 mx-auto mt-12 text-gray-normal">
            <tr className="bg-dark-dark3 text-small">
              <td className="p-3"></td>
              <td className="p-3">Date Created</td>
            </tr>
            {flowTracesData?.map(({ name, projectLocalId, dateCreated }) => (
              <tr key={projectLocalId}>
                <td className="p-3">
                  <Link to={getCcoPath(projectUrlName, projectLocalId)} className="hover:underline">
                    {name}
                  </Link>
                </td>
                <td className="p-3">{prettifyDate(dateCreated)}</td>
              </tr>
            ))}
          </table>
        </>
      )}
    </Layout>
  )
}
