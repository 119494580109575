import React, { useCallback, useContext, useMemo, useRef, useState } from 'react'
import styled, { css } from 'styled-components/macro'
import tw from 'twin.macro'
import { useTranslation } from 'react-i18next'
import { generatePath, Link, useLocation, useParams } from 'react-router-dom'
import NProgress from 'nprogress'
import dayjs from 'dayjs'
import { toast } from 'react-hot-toast'

import { Checkbox } from 'components/Checkbox'
import {
  Button,
  ButtonIcon,
  ButtonTextColorVariantEnum,
  ButtonVariantEnum,
} from 'components/Button'
import {
  AssignTraceModalContext,
  AssignTraceModalStateContext,
} from 'components/traces/AssignTraceModal'
import { ActionModal } from 'components/ActionModal'
import { TraceEditModal } from 'components/TraceEditModal'
import {
  ApiError,
  ChartPageParams,
  Trace as ITrace,
  TraceProcessingErrorCode,
  TraceProcessingErrorCodeValue,
  TraceProcessingState,
  TraceQualityValue,
  Traces,
  VideoProcessingStateDto,
} from 'api/models'
import { useMutation, useQueryClient } from 'react-query'
import { queryKeys, useUserQuery } from 'hooks/useApiQuery'
import { truncate } from 'utils/stringUtils'
import { AxiosError } from 'axios'
import { PATH_CHART_ROUTER } from 'pages/PsChartRouter'
import { useApi } from 'contexts/di-context'
import { OptionType } from 'components/dropdowns/models'
import { FEATURE_FLAGS, useFeatureFlag, useIsReadOnlyProject } from 'utils/feature-flags'
import { ContextMenu } from 'components/traces/ContextMenu'
import { useCustomerSupportModal } from 'contexts/customer-support-modal-context'
import { useTraceFocus } from 'components/traces/useTraceFocus'
import { CopyTrace } from 'components/traces/copyTrace/CopyTraceModal'
import { Popover } from 'components/dropdowns/Popover'
import { useClickOutside } from 'hooks/useClickOutside'
import { Icon } from 'components/Icon'
import { isUserProductScience } from 'utils/hiddenFlow'
import { getQualityActionKey, getQualityWarningKey } from 'components/traces/getQualityWording'

interface TraceProps extends ITrace {
  gridTemplateColumns?: string
  isSelect: boolean
  onCheckboxChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  withSelect?: boolean
  withAssign?: boolean
  hasSelectedTraces?: boolean
  getActiveOrder: () => string
  canWorkWithFlow?: boolean
  onLiveDemoAssignClick: (traceParams: ChartPageParams) => void
  inHiddenFlow?: boolean
}

const DISABLE_MENU_OPEN_CLASS = 'disable-menu-open'
const MAX_TRACE_NAME_LENGTH = 29
const MAX_TEXT_FIELD_LENGTH = 22
const CONTEXT_MENU_HEIGHT = 200
const NAVIGATION_HEIGHT = 60

export interface MenuState {
  isShow: boolean
  clientX: number
  clientY: number
}

export const Trace = ({
  projectLocalId,
  name,
  flows,
  videoState,
  videoErrorCode,
  createdBy,
  dateCreated,
  dateUpdated,
  pluginVersion,
  gitBranch,
  gitCommit,
  gitCommitAuthorName,
  gitCommitAuthorEmail,
  device,
  osVersion,
  appVersion,
  appBuildCode,
  resolution,
  screenRefreshRate,
  getActiveOrder,
  gridTemplateColumns,
  isSelect,
  onCheckboxChange,
  withSelect,
  withAssign,
  hasSelectedTraces,
  processingState,
  processingErrorCode,
  canWorkWithFlow,
  qualityReport,
  onLiveDemoAssignClick,
  inHiddenFlow,
}: TraceProps) => {
  const qualityLabel: TraceQualityValue | null = qualityReport.qualityLabels?.[0] ?? null
  const { t } = useTranslation()

  const queryClient = useQueryClient()
  const api = useApi()
  const { projectUrlName, flowProjectLocalId } = useParams() as {
    projectUrlName: string
    flowProjectLocalId: string
  }

  const { data: user } = useUserQuery()
  const isSuperAdmin = user?.roles.isSuperAdmin ?? false

  const [menu, setMenu] = useState<{ isShow: boolean; clientX: number; clientY: number }>({
    isShow: false,
    clientX: 0,
    clientY: 0,
  })

  const location = useLocation()

  const isUnassignedTracesTable = useMemo(
    () => !location.pathname.includes('/flows') || location.pathname.includes('/add'),
    [location],
  )

  const tableRowRef = useRef<HTMLAnchorElement>(null)
  const contextMenuRef = useRef<HTMLDivElement>(null)
  const isTableRowHovered = useTraceFocus(tableRowRef)

  const handleCloseMenu = () => setMenu((prevState) => ({ ...prevState, isShow: false }))
  const handleTraceClick = (event: React.MouseEvent) => {
    if (!(event.target as Element).closest(`.${DISABLE_MENU_OPEN_CLASS}`)) {
      event.preventDefault()
      if (!menu.isShow) {
        const lastClientY = window.innerHeight - CONTEXT_MENU_HEIGHT - NAVIGATION_HEIGHT - 15
        setMenu((prevState) => ({
          isShow: !prevState.isShow,
          clientX: Number(event.clientX) || 0,
          clientY: Number(event.clientY > lastClientY ? lastClientY : event.clientY) || 0,
        }))
      } else {
        handleCloseMenu()
      }
    } else {
      handleCloseMenu()
    }
  }
  useClickOutside(tableRowRef, handleCloseMenu)

  const isFailed = processingState === TraceProcessingState.FAILED
  const isLowQuality = qualityLabel != null
  const isProcessing = processingState === TraceProcessingState.IN_PROGRESS
  const isDisabled = isFailed || isProcessing || isUnassignedTracesTable

  const assignTraceModal = useContext<AssignTraceModalStateContext | null>(AssignTraceModalContext)

  const isEnabledLiveDemoPerFlow = useFeatureFlag(FEATURE_FLAGS.LIVE_DEMO_PER_FLOW)

  const isInternalUser = user ? isUserProductScience(user.email) : false
  const isLiveDemoAssignAvailable = isInternalUser && inHiddenFlow && isEnabledLiveDemoPerFlow

  const handleAssignClick = useCallback(
    (e) => {
      if (isUnassignedTracesTable) {
        e.preventDefault()
      }
      handleCloseMenu()
      const getFlows = flows.map((flow) => flow.flowProjectLocalId)
      assignTraceModal?.setState({
        isOpen: true,
        name,
        tracesIds: [projectLocalId],
        activeFlowsIds: getFlows,
        prevFlowsIds: getFlows,
      })
    },
    [assignTraceModal, flows, name, projectLocalId, isUnassignedTracesTable],
  )

  const handleLiveDemoClick = useCallback(() => {
    onLiveDemoAssignClick({
      traceProjectLocalId: projectLocalId,
      flowProjectLocalId,
      projectUrlName,
    })
  }, [projectLocalId, flowProjectLocalId, projectUrlName, onLiveDemoAssignClick])

  const isAssigned = flows.length > 0

  const getCurrentOrFlowName = !!flows?.find(
    (flow) => flow?.flowProjectLocalId === Number(flowProjectLocalId),
  )
    ? t('traces.trace.current')
    : flows[0]?.flowName

  const [deleteModalOpened, setDeleteModalOpened] = useState(false)

  const handleDeleteClick = useCallback(() => {
    handleCloseMenu()
    setDeleteModalOpened(true)
  }, [])

  const handleDeleteModalClose = () => setDeleteModalOpened(false)

  const deleteFlowTraceMutation = useMutation(
    () =>
      api.deleteFlowTrace({
        projectUrlName,
        flowProjectLocalId,
        traceProjectLocalId: String(projectLocalId),
      }),
    {
      onSuccess: () => {
        NProgress.done()
        queryClient.setQueryData<Traces | undefined>(
          queryKeys.flowTraces({ projectUrlName, flowProjectLocalId }),
          (oldData) => {
            if (oldData) {
              return oldData?.filter((e) => e?.projectLocalId !== projectLocalId)
            }
          },
        )
        toast.success(t('traces.trace.flowDeletedToast'))
      },
      onError: (err: AxiosError<ApiError>) => {
        NProgress.done()
        toast.error(err.response?.data.message ?? t('errorMessage'))
      },
    },
  )

  const deleteTraceMutation = useMutation(
    () =>
      api.deleteTrace({
        projectUrlName,
        traceProjectLocalId: String(projectLocalId),
      }),
    {
      onSuccess: () => {
        NProgress.done()
        queryClient.setQueryData<Traces | undefined>(
          queryKeys.unassignedTraces({ projectUrlName }),
          (oldData) => {
            if (oldData) {
              return oldData?.filter((e) => e?.projectLocalId !== projectLocalId)
            }
          },
        )
        toast.success(t('traces.trace.deletedToast'))
      },
      onError: (err: AxiosError<ApiError>) => {
        NProgress.done()
        toast.error(err.response?.data.message ?? t('errorMessage'))
      },
    },
  )

  const handleDeleteModalActionClick = () => {
    setDeleteModalOpened(false)
    NProgress.start()
    if (flowProjectLocalId && !withAssign) {
      deleteFlowTraceMutation.mutate()
    } else {
      deleteTraceMutation.mutate()
    }
  }

  const getTraceUrl = flowProjectLocalId
    ? generatePath(PATH_CHART_ROUTER, {
        projectUrlName,
        flowProjectLocalId,
        traceProjectLocalId: String(projectLocalId),
      })
    : ''

  const handleOpenAlternativeUiClick = useCallback(() => {
    const url = api.genAlternativeUiUrl({
      projectUrlName,
      traceProjectLocalId: String(projectLocalId),
    })
    window.open(url, '_blank', 'noopener,noreferrer')
  }, [api, projectLocalId, projectUrlName])

  const handleCopyUrlClick = useCallback(async () => {
    handleCloseMenu()
    const url = `${window.location.protocol}//${window.location.host}${getTraceUrl}`
    try {
      await navigator.clipboard.writeText(url)
      toast.success(t('traces.trace.urlCopied'), { duration: 3000 })
    } catch (error) {
      toast.error(`${t('traces.trace.urlNotCopied')}: ${error}`)
    }
  }, [getTraceUrl, t])

  const [renameModalVisible, setRenameModalVisible] = useState(false)
  const [isCopyTraceModalVisible, setIsCopyTraceModalVisible] = useState(false)

  const handleOpenRenameModal = useCallback(() => {
    handleCloseMenu()
    setRenameModalVisible(true)
  }, [])

  const handleCloseRenameModal = useCallback(() => {
    setRenameModalVisible(false)
  }, [])

  const handleClickCopyTrace = useCallback(() => {
    handleCloseMenu()
    setIsCopyTraceModalVisible(true)
  }, [])

  const isReadOnlyProject = !!useIsReadOnlyProject()

  const isMvpSupported = useFeatureFlag(FEATURE_FLAGS.OPEN_IN_MVP)
  const isCopyTraceSupported = useFeatureFlag(FEATURE_FLAGS.COPY_TRACE)

  const contextMenuOptions = useMemo(() => {
    const options: OptionType[] = [
      {
        name: t('traces.trace.copyURL'),
        isDisabled: !flowProjectLocalId || isDisabled,
        onClick: handleCopyUrlClick,
        dataTid: 'trace-copy-url',
      },
    ]
    options.push({
      name: t('traces.trace.rename'),
      isDisabled: isFailed || isProcessing || isReadOnlyProject,
      onClick: handleOpenRenameModal,
      dataTid: 'trace-rename',
    })

    if (isMvpSupported) {
      options.push({
        name: t('traces.trace.openAlternative'),
        isDisabled: !flowProjectLocalId || isProcessing,
        onClick: handleOpenAlternativeUiClick,
        dataTid: 'trace-open-in-mvp',
      })
    }

    if (canWorkWithFlow) {
      options.push(
        {
          name: t('traces.assign'),
          onClick: handleAssignClick,
          dataTid: 'trace-assign',
          isDisabled: isReadOnlyProject,
        },
        {
          name: t('traces.unassign'),
          onClick: handleDeleteClick,
          dataTid: 'trace-unassign',
          isDisabled: isReadOnlyProject,
        },
      )
    } else {
      options.push({
        name: t('traces.trace.delete'),
        onClick: handleDeleteClick,
        dataTid: 'trace-delete',
        isDisabled: isReadOnlyProject,
      })
    }

    if (isCopyTraceSupported && isSuperAdmin) {
      options.push({
        name: t('traces.trace.copyTrace'),
        isDisabled: !flowProjectLocalId || isProcessing,
        onClick: handleClickCopyTrace,
        dataTid: 'trace-copy-trace',
      })
    }

    if (handleLiveDemoClick !== undefined && isLiveDemoAssignAvailable) {
      options.push({
        name: t('traces.trace.assignLiveDemo'),
        isDisabled: !flowProjectLocalId || isProcessing,
        onClick: handleLiveDemoClick,
      })
    }

    return options
  }, [
    t,
    flowProjectLocalId,
    isFailed,
    isProcessing,
    handleCopyUrlClick,
    isReadOnlyProject,
    handleOpenRenameModal,
    isMvpSupported,
    canWorkWithFlow,
    isCopyTraceSupported,
    handleOpenAlternativeUiClick,
    handleAssignClick,
    handleDeleteClick,
    handleClickCopyTrace,
    isSuperAdmin,
    isDisabled,
    handleLiveDemoClick,
    isLiveDemoAssignAvailable,
  ])

  const getProcessingErrorMessage = (errorCode: TraceProcessingErrorCodeValue) => {
    switch (errorCode) {
      case TraceProcessingErrorCode.APP_ID_NOT_FOUND:
        return t('traces.processingError.appIdNotFound')
      case TraceProcessingErrorCode.UNSUPPORTED_FILE_FORMAT:
        return t('traces.processingError.unsupportedFileFormat')
      case TraceProcessingErrorCode.INTERNAL_SERVER_ERROR:
        return t('traces.processingError.internalServerError')
      default:
        return t('errorMessage')
    }
  }

  const { openModal } = useCustomerSupportModal()

  const isTraceQualityLabelSupported = useFeatureFlag(FEATURE_FLAGS.TRACE_QUALITY_LABEL)

  const getTraceName = (traceName: string) => {
    const isLongName = traceName.length >= MAX_TRACE_NAME_LENGTH

    const onSubmitTicket = (e: React.MouseEvent) => {
      e.stopPropagation()
      e.preventDefault()
      openModal()
    }

    return (
      <TraceName
        className="group"
        isFailed={isFailed}
        isProcessing={isProcessing}
        isLowQuality={isLowQuality && isTraceQualityLabelSupported}
      >
        {(isLongName || isFailed || isProcessing || isLowQuality) && (
          <LongFieldFullTextPopup>
            {isLongName && traceName}
            {processingErrorCode != null ? (
              <TraceStatusText isFailed>
                {getProcessingErrorMessage(processingErrorCode)} ({String(processingErrorCode)})
              </TraceStatusText>
            ) : isLowQuality && isTraceQualityLabelSupported ? (
              <div>
                <div className="text-state-bad">{t(getQualityWarningKey(qualityLabel))}</div>
                <div>{t(getQualityActionKey(qualityLabel))}</div>
              </div>
            ) : null}
            {isProcessing && <TraceStatusText>{t('traces.trace.processing')}</TraceStatusText>}
          </LongFieldFullTextPopup>
        )}
        <div>{truncate(traceName, MAX_TRACE_NAME_LENGTH)}</div>
        {qualityLabel != null && isTraceQualityLabelSupported && (
          <button
            type="button"
            onClick={onSubmitTicket}
            className="typography-small-accent text-gray-normal hover:text-white border rounded-[2px] border-gray-normal hover:border-white px-[5px] py-[1px]"
          >
            {t('flows.qualityLabel.submitTicket')}
          </button>
        )}
      </TraceName>
    )
  }

  const getVideoStatus = () => {
    if (videoState != null && videoState !== VideoProcessingStateDto.FAILED) {
      return <Icon icon="video-indicator" className="text-icon text-gray-faded" />
    }

    if (videoState != null && videoState === VideoProcessingStateDto.FAILED) {
      return (
        <div className="relative group">
          <LongFieldFullTextPopup>
            <TraceStatusText isFailed>
              {t(`psChart.error.video.serverSide.${videoErrorCode}`)}
            </TraceStatusText>
          </LongFieldFullTextPopup>
          <Icon icon="no-video-indicator" className="text-icon text-state-bad" />
        </div>
      )
    }

    if (videoState == null) {
      return <Icon icon="no-video-indicator" className="text-icon text-gray-faded" />
    }
  }

  return (
    <>
      <TableRow
        to={getTraceUrl}
        gridTemplateColumns={gridTemplateColumns}
        isShowMenu={isTableRowHovered}
        data-tid="flow-lib-trace-click"
        className="focus:outline-electro focus:outline focus:outline-1"
        tabIndex={0}
        ref={tableRowRef}
        onContextMenu={handleTraceClick}
        isDisabled={isDisabled}
        isProcessing={isProcessing}
        aria-disabled={isDisabled}
        onClick={(e) => isDisabled && e.preventDefault()}
        data-testid={name}
      >
        {withSelect && (
          <Column className={DISABLE_MENU_OPEN_CLASS}>
            <Checkbox checked={isSelect} onChange={onCheckboxChange} />
          </Column>
        )}
        {[
          { name: 'name', content: getTraceName(name) },
          {
            name: 'flows',
            content: hasSelectedTraces ? null : flows?.length > 0 && !withAssign ? (
              t('traces.trace.withoutAssignFlow_interval', {
                postProcess: 'interval',
                count: flows.length,
                length: flows.length - 1,
                name: getCurrentOrFlowName,
              })
            ) : (
              <Button
                className={DISABLE_MENU_OPEN_CLASS}
                variant={ButtonVariantEnum.Text}
                textColorVariant={
                  isAssigned ? ButtonTextColorVariantEnum.Good : ButtonTextColorVariantEnum.Look
                }
                onClick={handleAssignClick}
              >
                {isAssigned ? t('traces.assigned') : t('traces.assign')}
                <ButtonIcon icon="arrow-small-r" />
                {isAssigned && <Notice>{flows.map((flow) => flow.flowName).join(', ')}</Notice>}
              </Button>
            ),
          },
          {
            name: 'video',
            content: getVideoStatus(),
          },
          { name: 'recordedBy', content: createdBy },
          { name: 'dateCreated', content: dayjs(dateCreated).format('MM.DD.YY HH:mm:ss') },
          {
            name: 'dateUpdated',
            content: <>{dayjs(dateUpdated).format('MM.DD.YY HH:mm:ss')}</>,
          },
          { name: 'pluginVersion', content: pluginVersion },
          { name: 'gitBranch', content: gitBranch },
          { name: 'gitCommit', content: gitCommit },
          {
            name: 'gitCommitAuthorName',
            content: <LongTextField text={gitCommitAuthorName} maxLength={MAX_TEXT_FIELD_LENGTH} />,
          },
          {
            name: 'gitCommitAuthorEmail',
            content: (
              <LongTextField text={gitCommitAuthorEmail} maxLength={MAX_TEXT_FIELD_LENGTH} />
            ),
          },
          {
            name: 'device',
            content: <LongTextField text={device} maxLength={MAX_TEXT_FIELD_LENGTH} />,
          },
          { name: 'osVersion', content: osVersion },
          { name: 'appVersion', content: appVersion },
          { name: 'appBuildCode', content: appBuildCode },
          { name: 'resolution', content: resolution },
          {
            name: 'screenRefreshRate',
            content: screenRefreshRate ? Math.round(screenRefreshRate) : '',
          },
          ...(isTableRowHovered
            ? [
                {
                  name: 'more',
                  content: (
                    <ContextMenu
                      isTableRowHovered={isTableRowHovered}
                      contextMenuOptions={contextMenuOptions}
                      name={name}
                      isPopoverShowing={menu.isShow}
                      contextMenuRef={contextMenuRef}
                    />
                  ),
                },
              ]
            : []),
        ].map((item, idx) => (
          <Column isOrder={getActiveOrder() === item.name} key={String(idx)} name={item.name}>
            {item.content}
          </Column>
        ))}
      </TableRow>

      <PopoverContextMenu
        position={menu}
        menuSections={[
          {
            name: t('traces.trace.contextMenuTitle'),
            options: contextMenuOptions,
          },
        ]}
        show={() => menu.isShow}
      />
      <ActionModal
        isOpen={deleteModalOpened}
        title={
          location.pathname.includes('/flows')
            ? t('traces.trace.unassignModal.title')
            : t('traces.trace.deleteModal.title')
        }
        secondaryTitle={name}
        text={
          flowProjectLocalId
            ? t('traces.trace.unassignModal.text')
            : t('traces.trace.deleteModal.text')
        }
        onClose={handleDeleteModalClose}
        actionButton={{
          children: location.pathname.includes('/flows') ? t('traces.unassign') : t('delete'),
          onClick: handleDeleteModalActionClick,
          disabled:
            flowProjectLocalId && !withAssign
              ? deleteFlowTraceMutation.isLoading
              : deleteTraceMutation.isLoading,
        }}
      />
      <TraceEditModal
        visible={renameModalVisible}
        title={name}
        onClose={handleCloseRenameModal}
        flowProjectLocalId={String(flowProjectLocalId)}
        traceProjectLocalId={String(projectLocalId)}
        projectUrlName={projectUrlName}
      />
      <CopyTrace
        visible={isCopyTraceModalVisible}
        sourceTraceProjectLocalId={String(projectLocalId)}
        sourceProjectUrlName={projectUrlName}
        traceName={name}
        onClose={() => setIsCopyTraceModalVisible(false)}
        flowProjectLocalId={flowProjectLocalId}
      />
    </>
  )
}

const TableRow = styled(Link).withConfig({
  shouldForwardProp: (propName) =>
    !['gridTemplateColumns', 'isShowMenu', 'isDisabled', 'isProcessing'].includes(propName),
})<{
  gridTemplateColumns?: string
  isShowMenu: boolean
  isDisabled: boolean
  isProcessing: boolean
}>`
  ${tw`transition text-normal tracking-wide`}
  display: grid;
  grid-template-columns: ${({ gridTemplateColumns }) => gridTemplateColumns};
  grid-column: 1 / -1;
  padding: 7px 5px 6px 16px;
  cursor: pointer;
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray.strokeLight};
  border-radius: 2px;

  ${({ theme }) => theme.notTouchScreen} {
    &:hover {
      ${tw`bg-white/[.05]`}
    }
  }

  ${({ isShowMenu }) =>
    isShowMenu &&
    css`
      ${tw`bg-white/[.05]`}
    `}

  ${({ isDisabled }) =>
    isDisabled &&
    css`
      ${tw`cursor-not-allowed`}
    `}

  ${({ isProcessing }) =>
    isProcessing &&
    css`
      ${tw`cursor-wait`}
    `}
`

const Column = styled.div<{ isOrder?: boolean; name?: string }>`
  display: flex;
  align-items: center;
  min-height: 59px;
  color: ${({ theme, isOrder }) => {
    if (isOrder) {
      return theme.colors.white
    } else {
      return theme.colors.gray.normal
    }
  }};

  ${({ name }) => name === 'more' && tw`sticky right-[16px] w-[16px]`}
`

const Notice = styled.span`
  position: relative;
  display: inline-block;
  overflow: hidden;
  max-width: 142px;
  vertical-align: middle;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: ${({ theme }) => theme.colors.gray.normal};
`

const TraceName = styled.div<{ isFailed: boolean; isProcessing: boolean; isLowQuality?: boolean }>`
  position: relative;
  white-space: nowrap;

  ${({ isFailed, isLowQuality, theme }) =>
    (isLowQuality || isFailed) &&
    css`
      color: ${theme.colors.state.bad};
    `}

  ${({ isProcessing, theme }) =>
    isProcessing &&
    css`
      color: ${theme.colors.state.attention};
    `}
`
const LongFieldFullTextPopup = ({ children }: { children: React.ReactNode }) => {
  return (
    <div
      className="absolute whitespace-nowrap
        bottom-4 -left-1 px-2
        transition-opacity ease-in-out duration-250
        opacity-0 group-hover:opacity-100
        text-micro text-gray-normal rounded bg-dark-dark5"
    >
      {children}
    </div>
  )
}

const TraceStatusText = styled.div<{ isFailed?: boolean }>`
  color: ${({ theme, isFailed }) =>
    isFailed ? theme.colors.state.bad : theme.colors.state.attention};
`

const PopoverContextMenu = styled(Popover)<{ position: { clientX: number; clientY: number } }>`
  position: fixed;
  z-index: 100;
  top: ${({ position }) => position.clientY + 10}px;
  left: ${({ position }) => position.clientX + 10}px;
`

interface LongTextProps {
  text?: string | null
  maxLength: number
}

const LongTextField = ({ text, maxLength }: LongTextProps) => {
  if (!text) {
    return null
  }
  const isExceedsMaxLength = text.trim().length > maxLength
  return (
    <div className="group relative whitespace-nowrap">
      {isExceedsMaxLength && <LongFieldFullTextPopup>{text}</LongFieldFullTextPopup>}
      <div>{truncate(text, maxLength)}</div>
    </div>
  )
}
