interface TitleProps {
  text: string
  name?: string
}

export const Title = ({ text, name }: TitleProps) => {
  return (
    <p className="mb-1">
      <span>{text}</span>
      {name && <span className="text-white ml-1">{name}</span>}
    </p>
  )
}
