import { TraceQuality } from 'api/__generated__/api-constants'
import { TraceQualityValue } from 'api/__generated__/api-types'

export const getQualityWarningKey = (qv?: TraceQualityValue) => {
  switch (qv) {
    case TraceQuality.UNDERFILTERED:
      return 'flows.qualityLabel.title.underfiltered'
    case TraceQuality.OVERFILTERED:
      return 'flows.qualityLabel.title.overfiltered'
    default:
      return 'flows.qualityLabel.title.default'
  }
}

export const getQualityActionKey = (qv?: TraceQualityValue) => {
  switch (qv) {
    case TraceQuality.UNDERFILTERED:
    case TraceQuality.OVERFILTERED:
      return 'flows.qualityLabel.action.filtering'
    default:
      return 'flows.qualityLabel.action.default'
  }
}
