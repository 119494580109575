import React, { useState } from 'react'

export interface LayoutContextInterface {
  isNavbarShown?: boolean
  setIsNavbarShown: React.Dispatch<React.SetStateAction<boolean>>
  activeHints: Map<string, boolean>
  activateHint: (hint: string) => void
  deactivateHint: (hint: string) => void
  toggleHint: (hint: string) => void
  hintsIsOpened: boolean
  setHintsOpened: React.Dispatch<React.SetStateAction<boolean>>
}

interface LayoutContextProviderProps {
  children: React.ReactNode
}

export const LayoutContext = React.createContext<LayoutContextInterface>({
  isNavbarShown: false,
  setIsNavbarShown: () => {},
  activeHints: new Map<string, boolean>(),
  toggleHint: () => {},
  activateHint: () => {},
  deactivateHint: () => {},
  //TODO: looks like next 2 lines are pretty old and we can remove them from code completely
  hintsIsOpened: false,
  setHintsOpened: () => {},
})

export const LayoutContextProvider = ({ children }: LayoutContextProviderProps) => {
  const [hintsIsOpened, setHintsOpened] = useState(false)
  const [activeHints, setActiveHints] = useState(new Map())
  const [isNavbarShown, setIsNavbarShown] = useState(false)

  const toggleHint = (hint: string) => {
    setActiveHints((prev) => new Map(prev).set(hint, prev.has(hint) ? !prev.get(hint) : false))
  }

  const activateHint = (hint: string) => {
    setActiveHints((prev) => new Map(prev).set(hint, true))
  }

  const deactivateHint = (hint: string) => {
    setActiveHints((prev) => new Map(prev).set(hint, false))
  }

  return (
    <LayoutContext.Provider
      value={{
        isNavbarShown,
        setIsNavbarShown,
        activeHints,
        toggleHint,
        activateHint,
        deactivateHint,
        hintsIsOpened,
        setHintsOpened,
      }}
    >
      {children}
    </LayoutContext.Provider>
  )
}
