import { StorageItem } from './StorageItem'

export class ObjectStorageItem<T> extends StorageItem<T | null> {
  get value(): T | null {
    const item = this.localStorageValue
    if (!item) {
      return null
    }
    try {
      return JSON.parse(item!)
    } catch (err) {
      this.showParsingErrorAndResetData(err)
      return null
    }
  }

  set value(newValue: T | null) {
    if (!newValue) {
      this.remove()
    } else {
      this.localStorageValue = JSON.stringify(newValue)
    }
  }
}
