import { Segment } from 'components/common/models/Segment'
import { Slice } from '../models/Slice'

export const ERROR_FRAMES_ARRAY_IS_EMPTY = 'Slices array is empty'
export const ERROR_OUT_OF_THE_LOOP = 'Out of loop without a result'

export const SLICE_ARGUMENT_CONTAINTS_URL = /((https:|http:|www.)\S*)/
const SLICE_ARGUMENT_PREFIX = /^(&{2})?/

// TODO: I would prefer to have non-recursive breadth first traversal here
export function walkSlices(
  slices: ReadonlyArray<Slice>,
  callback: (slice: Slice, level: number, index: number) => void,
  level = 0,
) {
  for (let index = 0; index < slices.length; index++) {
    const slice = slices[index]
    callback(slice, level, index)
    if (slice.children) {
      walkSlices(slice.children, callback, level + 1)
    }
  }
}

// TODO: It would be nice to use walkSlices here
export function findSlice(
  slices: ReadonlyArray<Slice>,
  callback: (slice: Slice) => boolean,
): Slice | null {
  for (const slice of slices) {
    if (callback(slice)) {
      return slice
    } else {
      if (slice.children) {
        const result = findSlice(slice.children, callback)
        if (result) {
          return result
        }
      }
    }
  }
  return null
}

export function getSliceSize(slice: Slice): number {
  return slice.end - slice.start
}

export function getMaxSlice(slices: Slice[]): Slice | null {
  let maxSliceVal: Slice | null = null
  let maxSliceSize = 0
  slices.forEach((slice) => {
    const size = getSliceSize(slice)
    if (!maxSliceVal) {
      maxSliceVal = slice
      maxSliceSize = size
    }
    if (size > maxSliceSize) {
      maxSliceVal = slice
      maxSliceSize = size
    }
  })
  return maxSliceVal
}

export function getFullSize(slices: Slice[]): Segment {
  if (slices.length > 0) {
    const { start } = slices[0]
    const { end } = slices[slices.length - 1]
    return { start: start, end: end }
  } else {
    return { start: 0, end: 0 }
  }
}

/**
 * getClusterSize calculate slightly better cluster size to avoid thread slice edges but with cost
 * in case heavy traces
 **/
export function getClusterSize(slices: Slice[], minVisibleSliceSize: number): Segment {
  if (slices.length > 0) {
    const { start } = slices[0]
    const lastSlice = slices[slices.length - 1]
    let lastChildren: Slice | undefined = undefined
    for (let index = slices.length - 1; index >= 0; index--) {
      const { children } = slices[index]
      if (children !== undefined && children.length) {
        lastChildren = children[children.length - 1]
        break
      }
    }
    let end: number
    if (lastChildren) {
      end = Math.max(lastSlice.end, lastChildren.start + minVisibleSliceSize)
    } else {
      end = lastSlice.end
    }
    return { start: start, end: end }
  } else {
    return { start: 0, end: 0 }
  }
}

/**
 * TODO: this implementation is not idempotent, probably worth fixing it
 * Binary search to find left most close slice to the value.
 * Returns 0 if value out of slice range.
 * @param slices
 * @param value
 * @throws {@link ERROR_FRAMES_ARRAY_IS_EMPTY}, {@link ERROR_OUT_OF_THE_LOOP}
 */
export function findClosestLeftIndex(slices: ReadonlyArray<Slice>, value: number): number {
  if (!slices || slices.length === 0) {
    throw new Error(ERROR_FRAMES_ARRAY_IS_EMPTY)
  }

  let minIndex = 0
  let maxIndex = slices.length - 1

  if (value < slices[minIndex].start) {
    return 0
  }

  if (slices.length === 1) {
    return 0
  }

  while (minIndex <= maxIndex) {
    const guess = Math.floor((minIndex + maxIndex) / 2)

    if (guess === 0) {
      return slices[1].start <= value && value <= slices[1].end ? 1 : 0
    }

    if (guess === slices.length - 1) {
      return slices[guess].start <= value && value <= slices[guess].end ? guess : guess - 1
    }

    const guessValue = slices[guess].start
    const prevGuessValue = slices[guess - 1].start
    const nextGuessValue = slices[guess + 1].start
    if (guessValue < value) {
      if (value < nextGuessValue) {
        return guess
      }
      minIndex = guess + 1
    } else if (guessValue > value) {
      if (value >= prevGuessValue) {
        return guess - 1
      }
      maxIndex = guess - 1
    } else {
      return guess
    }
  }
  throw new Error(ERROR_OUT_OF_THE_LOOP)
}

/**
 * Binary search to find right most close slice to the value.
 * Returns `slices.length-1` if value out of slice range.
 * @param slices
 * @param value
 * @throws {@link ERROR_FRAMES_ARRAY_IS_EMPTY}, {@link ERROR_OUT_OF_THE_LOOP}
 */
export function findClosestRightIndex(slices: ReadonlyArray<Slice>, value: number): number {
  if (!slices || slices.length === 0) {
    throw new Error(ERROR_FRAMES_ARRAY_IS_EMPTY)
  }

  let minIndex = 0
  let maxIndex = slices.length - 1

  if (value > slices[maxIndex].end) {
    return maxIndex
  }

  if (slices.length === 1) {
    return 0
  }

  while (minIndex <= maxIndex) {
    const guess = Math.floor((minIndex + maxIndex) / 2)

    if (guess === 0) {
      return slices[0].start <= value && value <= slices[0].end ? 0 : 1
    }

    if (guess === slices.length - 1) {
      return slices[guess - 1].start <= value && value <= slices[guess - 1].end ? guess - 1 : guess
    }

    const guessValue = slices[guess].end
    const prevGuessValue = slices[guess - 1].end
    const nextGuessValue = slices[guess + 1].start
    if (guessValue < value) {
      if (value < nextGuessValue) {
        return guess + 1
      }
      minIndex = guess + 1
    } else if (guessValue > value) {
      if (value > prevGuessValue) {
        return guess
      }
      maxIndex = guess - 1
    } else {
      return guess
    }
  }
  throw new Error(ERROR_OUT_OF_THE_LOOP)
}

export const getChildNetworkRequests = (slice: Slice): Slice[] => {
  const isRoot = slice.level === 0
  if (isRoot) {
    return slice.stackNetworkRequests ?? []
  }
  const stackNetworkRequests = slice.root!.stackNetworkRequests ?? []
  return stackNetworkRequests.filter(
    (networkRequestSlice) =>
      networkRequestSlice.start >= slice.start && networkRequestSlice.end <= slice.end,
  )
}

export const getChildNetworkRequestsDuration = (slice: Slice): number => {
  const networkRequests = getChildNetworkRequests(slice)
  if (networkRequests.length) {
    let totalSum = 0
    let currentStart = networkRequests[0].start
    let currentEnd = networkRequests[0].end

    for (let i = 1; i < networkRequests.length; i++) {
      const nextObject = networkRequests[i]

      if (nextObject.start > currentEnd) {
        totalSum += currentEnd - currentStart
        currentStart = nextObject.start
        currentEnd = nextObject.end
      } else if (nextObject.end > currentEnd) {
        currentEnd = nextObject.end
      }
    }

    totalSum += currentEnd - currentStart

    return totalSum
  }
  return 0
}

export const getNetworkRequestURL = (slice: Slice): string => {
  return slice.args.find(({ value }) => SLICE_ARGUMENT_CONTAINTS_URL.test(value))!.value
}

export const getSliceTimelineTitle = (slice: Slice): string => {
  const { isNetworkRequest } = slice
  return isNetworkRequest
    ? getNetworkRequestURL(slice).replace(SLICE_ARGUMENT_PREFIX, '')
    : slice.title
}

export const getSliceDetailsTitle = (slice: Slice): string => {
  const { isNetworkRequest } = slice
  return isNetworkRequest ? slice.title.replace(SLICE_ARGUMENT_CONTAINTS_URL, '') : slice.title
}

export const getSliceTitleWithInvisibleSpaces = (title: string): string =>
  // eslint-disable-next-line no-irregular-whitespace
  title.replace(/([#$;])/g, `$1​`)
