import React, { useCallback } from 'react'
import { Popover, Transition } from '@headlessui/react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import { useUserQuery } from 'hooks/useApiQuery'
import { useAuth } from 'contexts/auth-context'

import { colors } from 'utils/styles/colors'

import { SignInModal } from 'components/lite/SignInModal'
import { PATH_LITE_TRACES } from 'pages/lite/LiteTracesPage'
import { PATH_LITE_PROMO } from 'pages/lite/LitePromoPage'

type Props = {
  isDark?: boolean
}

export const UserMenu = ({ isDark = false }: Props) => {
  const { t } = useTranslation()
  const auth = useAuth()

  const { data: user } = useUserQuery()

  const handleSignOutClick = useCallback(() => {
    auth.signOut(PATH_LITE_PROMO).catch((reason) => console.error('Sign out error: ', reason))
  }, [auth])

  if (!auth.isSignedIn && !user) {
    return (
      <div className="flex justify-end flex-wrap">
        <SignInModal
          buttonLabel={t('lite.logInButton')}
          buttonClassName="text-black bg-white border-white"
        />
        <SignInModal isSignUp />
      </div>
    )
  }

  return (
    <Popover className="relative mt-3">
      {({ open }) => (
        <>
          <Popover.Button
            className="flex items-center"
            data-testid="user-menu"
            aria-label={
              open
                ? t('components.userMenu.closeAriaLabel')
                : t('components.userMenu.openAriaLabel')
            }
          >
            <svg xmlns="http://www.w3.org/2000/svg" height="30" viewBox="0 -960 960 960" width="30">
              <path
                d="M234-276q51-39 114-61.5T480-360q69 0 132 22.5T726-276q35-41 54.5-93T800-480q0-133-93.5-226.5T480-800q-133 0-226.5 93.5T160-480q0 59 19.5 111t54.5 93Zm246-164q-59 0-99.5-40.5T340-580q0-59 40.5-99.5T480-720q59 0 99.5 40.5T620-580q0 59-40.5 99.5T480-440Zm0 360q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q53 0 100-15.5t86-44.5q-39-29-86-44.5T480-280q-53 0-100 15.5T294-220q39 29 86 44.5T480-160Zm0-360q26 0 43-17t17-43q0-26-17-43t-43-17q-26 0-43 17t-17 43q0 26 17 43t43 17Zm0-60Zm0 360Z"
                fill={isDark ? colors.gray.normal : 'black'}
              />
            </svg>
          </Popover.Button>
          <Transition
            as={React.Fragment}
            enter="transition ease-out duration-50"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-50"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            <Popover.Panel className="absolute right-0 z-50 text-black">
              <div className="overflow-hidden rounded-lg shadow-base ring-1 ring-black/5 p-5 bg-white flex flex-col justify-between">
                <div>{user?.email}</div>
                <Link to={PATH_LITE_TRACES} className="my-3 underline">
                  {t('lite.navigation.yourTraces')}
                </Link>
                <div className="self-end mt-3">
                  <button className="text-black" onClick={handleSignOutClick}>
                    {t('components.userMenu.signOut')}
                  </button>
                </div>
              </div>
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  )
}
