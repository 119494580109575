import { useCallback } from 'react'
import { useNavigate, useOutletContext } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react-lite'

import { GuidePageOutletContext } from 'pages/guide/GuidePageOutletContext'
import { useGuideStep } from 'components/guide/hooks/useGuideStep'
import { useGuidePlatform } from 'components/guide/hooks/useGuidePlatform'
import { GuideStore } from 'components/guide/GuideStore'
import { NextActionButton } from 'components/guide/NextActionButton'

export const NextButton = observer(function NextButton() {
  const { t } = useTranslation()
  const { guideStore } = useOutletContext<GuidePageOutletContext>()
  const step = useGuideStep()
  const platform = useGuidePlatform()
  const currentStepIndex = guideStore.steps.findIndex((item) => item.type === step)
  const nextStep = guideStore.steps[currentStepIndex + 1]?.type
  const navigate = useNavigate()

  const handleNext = useCallback(() => {
    if (step && nextStep && platform) {
      navigate(GuideStore.getPageUrl(nextStep, platform))
    }
  }, [navigate, step, nextStep, platform])

  return (
    <NextActionButton
      disabled={nextStep == null}
      onClick={handleNext}
      label={t('guidePage.nextStep')}
    />
  )
})
