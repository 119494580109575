import React from 'react'
import { generatePath, useParams } from 'react-router-dom'

import { useFlowQuery } from 'hooks/useApiQuery'
import { PATH_FLOW } from 'pages/FlowPage'

import { BreadcrumbSection } from './BreadcrumbSection'
import { BreadcrumbsLink } from './BreadcrumbsLink'
import { breadcrumbsSettings } from './breadcrumbsSettings'

export const BreadcrumbsFlowSection = () => {
  const { projectUrlName, flowProjectLocalId } = useParams()
  const { data: flowData } = useFlowQuery({
    projectUrlName,
    flowProjectLocalId,
  })

  return (
    <BreadcrumbSection isLoading={flowData == null}>
      {flowData != null && (
        <BreadcrumbsLink
          path={generatePath(PATH_FLOW, { projectUrlName, flowProjectLocalId })}
          name={flowData.name}
          className={`max-w-[${breadcrumbsSettings.cutSectionsMaxSize}]`}
        />
      )}
    </BreadcrumbSection>
  )
}
