/* eslint-disable no-console */
import React, { memo, useRef, useEffect, useMemo, useState, useCallback } from 'react'
import { twMerge } from 'tailwind-merge'
import { ArkanoidHeader } from './ArkanoidHeader'

export const PATH_ARKANOID_GAME = '/arkanoid'

/** Base screen height to calculate adjusted ball speed for big screens */
const BASE_HEIGHT = 1080

/** The number of rows in the bottom where bricks would not grow */
const MAX_ROWS_OFFSET = 3

const PADDLE_WIDTH = 400
const PADDLE_HEIGHT = 32

/** Default bricks rows count */
const BRICK_ROW_COUNT = 7

/** Default bricks columns count */
const BRICK_COLUMN_COUNT = 7

const BRICK_HEIGHT = 32
const BRICK_PADDING = 2

const BASE_BALL_SPEED = 5
/** Increase of the ball speed for each level in % */
const LEVEL_SPEED_INCREASE = 20
const BALL_RADIUS = 12

/** How many times you can hit a wrong block before the number of rows increases */
const MAX_MISS_COUNTER = 3

/** Ball Bounce Angle (Pi / ϴ) */
const BOUNCE_ANGLE_THETA = 4

const PILL_SIZE = 48

/** Pill Min Respawn Timeout */
const PILL_RESPAWN_TIMEOUT = 10000

/** How long bricks have color */
const SPECIAL_ABILITY_TIMEOUT = 2000

const INITIAL_LIVES = 3

/** Points user gets for clearing the line */
const REWARD_POINTS = 200

/** Points user gets for clearing the line */
const DEMOTE_POINTS = 50

interface BrickInfo {
  x: number
  status: number
  isSpecial: boolean
  width: number // Added width property for randomized bricks
}

type Props = {
  name: string
  bestScore: number
  onGameOver?: () => void
  onSaveScore?: (score: number) => void
}

type FlatBrick = {
  key: string
  x: number
  y: number
  width: number
  height: number
  isSpecial: boolean
}

export const ArkanoidGame = ({ onGameOver, onSaveScore, name, bestScore }: Props) => {
  const requestRef = useRef<number>()

  const [canvasWidth, setCanvasWidth] = useState(800)
  const [canvasHeight, setCanvasHeight] = useState(600)

  const [bricks, setBricks] = useState<BrickInfo[][]>([])

  useEffect(() => {
    const handleResize = () => {
      setCanvasWidth(window.innerWidth)
      setCanvasHeight(window.innerHeight)
      setGameStatus('NewGame')
    }

    handleResize()

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  const [gameStatus, setGameStatus] = useState<
    'NewGame' | 'Active' | 'NextLevel' | 'Respawn' | 'GameOver' | 'Preparing' | 'Paused'
  >('Preparing')

  const [initialLives] = useState(INITIAL_LIVES)
  const [lives, setLives] = useState(initialLives)
  const [level, setLevel] = useState(1)
  const [levelSpeedIncrease] = useState(LEVEL_SPEED_INCREASE)

  const [score, setScore] = useState(0)
  const [comboMultiplier, setComboMultiplier] = useState(1)
  const [rewardPoints] = useState(REWARD_POINTS)
  const [demotePoints] = useState(DEMOTE_POINTS)

  const [showLevel, setShowLevel] = useState(false)

  const updateScore = useCallback((points) => {
    setScore((prevScore) => Math.max(0, prevScore + points))
  }, [])

  const [isColorActive, setIsColorActive] = useState(false)
  const [specialAbilityTimeout] = useState(SPECIAL_ABILITY_TIMEOUT)
  const [isAiming, setIsAiming] = useState(true)

  const brickOffsetTop = 208
  const brickOffsetLeft = 0

  const [brickRowCount] = useState(BRICK_ROW_COUNT)
  const [brickColumnCount] = useState(BRICK_COLUMN_COUNT)
  const [brickHeight] = useState(BRICK_HEIGHT)
  const [brickPadding] = useState(BRICK_PADDING)

  const [paddleWidth] = useState(PADDLE_WIDTH)
  const [paddleHeight] = useState(PADDLE_HEIGHT)

  const [isBallActive, setIsBallActive] = useState(false)
  const [ballPosition, setBallPosition] = useState({
    x: canvasWidth / 2,
    y: canvasHeight - 100,
  })
  const ballDirection = useRef({ x: 2, y: 2 })
  const [ballRadius] = useState(BALL_RADIUS)
  const [baseBallSpeed] = useState(BASE_BALL_SPEED)
  const [bounceAngleTheta] = useState(BOUNCE_ANGLE_THETA)
  const [ballSpeed, setBallSpeed] = useState(baseBallSpeed)
  //const maxSpeedIncrease = 1.35

  const updateBallSpeedByLevel = useCallback(
    (currentLevel: number) => {
      setBallSpeed(baseBallSpeed + baseBallSpeed * (currentLevel - 1) * (levelSpeedIncrease / 100))
    },
    [baseBallSpeed, levelSpeedIncrease],
  )

  const [paddlePosition, setPaddlePosition] = useState(350)
  const lastPaddlePosition = useRef(350)
  const lastMouseTime = useRef(Date.now())

  const [blueHitCounter, setBlueHitCounter] = useState(0)
  const [maxBlueHitCounter] = useState(MAX_MISS_COUNTER)

  const [pill, setPill] = useState({ x: 0, y: 0, active: false })
  const [pillWidth] = useState(PILL_SIZE)

  const [pillsNum, setPillsNum] = useState(3)

  const [pillRespawnTimeout] = useState(PILL_RESPAWN_TIMEOUT)

  const [blinkSpace, setBlinkSpace] = useState(false)

  const pillTimer = useRef<NodeJS.Timeout | null>(null)
  const specialAbilityTimer = useRef<NodeJS.Timeout | null>(null)

  const handleClick = () => {
    if (isAiming) {
      schedulePillRespawn()
      updateBallSpeedByLevel(level)
      setIsBallActive(true)
      setIsAiming(false)
      setShowLevel(false)
      setBlinkSpace(true)
    }
  }

  const handleMouseMove = (event: React.MouseEvent<HTMLElement>) => {
    if (gameStatus !== 'Active') {
      return
    }
    const posX = event.clientX
    setPaddlePosition(posX - paddleWidth / 2)
    if (isAiming) {
      placeBallOnPaddle()
    }
  }

  const placeBallOnPaddle = useCallback(() => {
    setBallPosition({
      x: paddlePosition + paddleWidth / 2,
      y: canvasHeight - paddleHeight - ballRadius,
    })
  }, [ballRadius, paddleHeight, paddlePosition, paddleWidth, canvasHeight])

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'p') {
        setGameStatus((s) => (s === 'Paused' ? 'Active' : 'Paused'))
      }
      if (event.key === ' ') {
        if (pillsNum > 0 && !isColorActive) {
          // Activate special ability for 10 seconds
          setIsColorActive(true)
          if (specialAbilityTimer.current) {
            clearTimeout(specialAbilityTimer.current)
          }
          specialAbilityTimer.current = setTimeout(() => {
            setIsColorActive(false)
          }, specialAbilityTimeout)
          setPillsNum((n) => n - 1)
        }
        setBlinkSpace(false)
      }
    }
    document.addEventListener('keydown', handleKeyDown)
    return () => {
      document.removeEventListener('keydown', handleKeyDown)
    }
  }, [specialAbilityTimeout, isColorActive, pillsNum])

  const maxRows = useCallback(
    () =>
      Math.floor((canvasHeight - paddleHeight - brickOffsetTop) / (brickHeight + brickPadding)) -
      MAX_ROWS_OFFSET,
    [brickHeight, brickPadding, canvasHeight, paddleHeight],
  )

  const handleGameOver = useCallback(() => {
    setIsBallActive(false)
    if (lives > 1) {
      setLives(lives - 1)
      setGameStatus('Respawn')
    } else {
      setGameStatus('GameOver')
      if (onSaveScore) {
        onSaveScore(score)
      }
      if (onGameOver) {
        setTimeout(() => onGameOver(), 3000)
      }
    }
  }, [score, lives, onGameOver, onSaveScore])

  const createBricks = useCallback(
    (currentLevel) => {
      const initBricks: BrickInfo[][] = []
      const minBrickWidth = canvasWidth / brickColumnCount / 2
      const maxBrickWidth = (canvasWidth / brickColumnCount) * 4
      const totalRows = Math.min(brickRowCount + currentLevel - 1, maxRows())

      for (let r = 0; r < totalRows; r++) {
        initBricks[r] = []
        const rowWidths = []
        let totalRowWidth = 0

        // Randomize widths and find the largest brick
        for (let c = 0; c < brickColumnCount; c++) {
          const brickWidth = Math.random() * (maxBrickWidth - minBrickWidth) + minBrickWidth
          rowWidths.push(brickWidth)
          totalRowWidth += brickWidth
        }

        // Scale widths to fit the canvas width
        const scalingFactor = (canvasWidth - brickPadding * (brickColumnCount - 1)) / totalRowWidth
        let currentX = brickOffsetLeft
        let maxBrickIndex = 0

        for (let c = 0; c < brickColumnCount; c++) {
          rowWidths[c] *= scalingFactor
          if (rowWidths[c] > rowWidths[maxBrickIndex]) {
            maxBrickIndex = c
          }
        }

        // Assign bricks with adjusted widths
        for (let c = 0; c < brickColumnCount; c++) {
          const brickWidth = rowWidths[c]
          const brickX = currentX
          const isSpecialBrick = c === maxBrickIndex

          initBricks[r][c] = {
            x: brickX,
            status: 1,
            isSpecial: isSpecialBrick,
            width: brickWidth,
          }
          currentX += brickWidth + brickPadding
        }
      }
      setBricks(initBricks)
    },
    [brickColumnCount, brickPadding, brickRowCount, canvasWidth, maxRows],
  )

  const checkLevelCompletion = useCallback(() => {
    const allRowsDestroyed = bricks.length === 0
    return allRowsDestroyed
  }, [bricks])

  const schedulePillRespawn = useCallback(() => {
    if (pillTimer.current) {
      clearTimeout(pillTimer.current)
    }

    pillTimer.current = setTimeout(() => {
      setPill({
        x: Math.random() * (canvasWidth - pillWidth),
        y: bricks.length * (brickHeight + brickPadding) + brickOffsetTop,
        active: true,
      })
    }, pillRespawnTimeout)
  }, [bricks.length, pillRespawnTimeout, canvasWidth, pillWidth, brickHeight, brickPadding])

  const handlePillRespawn = useCallback(() => {
    setPill((prevPill) => {
      if (prevPill.active) {
        schedulePillRespawn() // Schedule a new pill only if the previous pill was active
      }
      return { x: 0, y: 0, active: false }
    })
  }, [schedulePillRespawn])

  const generateNewRowOfBricks = useCallback(() => {
    const newRow = []
    const minBrickWidth = canvasWidth / brickColumnCount / 2
    const maxBrickWidth = (canvasWidth / brickColumnCount) * 4
    const rowWidths = []
    let totalRowWidth = 0

    // Randomize widths
    for (let c = 0; c < brickColumnCount; c++) {
      const brickWidth = Math.random() * (maxBrickWidth - minBrickWidth) + minBrickWidth
      rowWidths.push(brickWidth)
      totalRowWidth += brickWidth
    }

    // Scale widths to fit the canvas width
    const scalingFactor = (canvasWidth - brickPadding * (brickColumnCount - 1)) / totalRowWidth
    let currentX = brickOffsetLeft
    let maxBrickIndex = 0

    for (let c = 0; c < brickColumnCount; c++) {
      rowWidths[c] *= scalingFactor
      if (rowWidths[c] > rowWidths[maxBrickIndex]) {
        maxBrickIndex = c
      }
    }

    // Assign bricks with adjusted widths
    for (let c = 0; c < brickColumnCount; c++) {
      const brickWidth = rowWidths[c]
      const brickX = currentX
      const isSpecialBrick = c === maxBrickIndex

      newRow.push({ x: brickX, status: 1, isSpecial: isSpecialBrick, width: brickWidth })
      currentX += brickWidth + brickPadding
    }

    return newRow
  }, [brickColumnCount, brickPadding, canvasWidth])

  const handleBrickHit = useCallback(
    (brick) => {
      if (brick.isSpecial) {
        // Red brick hit
        setBricks((prevBricks) => prevBricks.slice(0, -1)) // Remove the entire row
        setBlueHitCounter(0)
        updateScore(rewardPoints * comboMultiplier)
        setComboMultiplier((prev) => prev + 1) // Increase combo for consecutive red brick hits
      } else {
        // Blue brick hit
        setBlueHitCounter((c) => c + 1)
        setComboMultiplier(1) // Reset combo multiplier on blue brick hit

        if (blueHitCounter >= maxBlueHitCounter - 1) {
          updateScore(-demotePoints) // Penalty for adding a new row
          setBlueHitCounter(0)
          if (bricks.length < maxRows()) {
            setTimeout(() => {
              setBricks((b) => {
                const newRow = generateNewRowOfBricks()
                return [newRow, ...b]
              })
            }, 500)
          }
        }
      }
    },
    [
      bricks,
      maxBlueHitCounter,
      updateScore,
      rewardPoints,
      comboMultiplier,
      demotePoints,
      maxRows,
      generateNewRowOfBricks,
      blueHitCounter,
    ],
  )

  const flatBricks = useMemo(() => {
    return bricks.reduce<FlatBrick[]>((result, bricksRow, row) => {
      bricksRow.forEach(({ x, width, isSpecial }) => {
        const y = row * (brickHeight + brickPadding) + brickOffsetTop
        result.push({
          key: `${x}${y}${width}${brickHeight}${isSpecial}`,
          x,
          y,
          width,
          height: brickHeight,
          isSpecial,
        })
      })
      return result
    }, [])
  }, [bricks, brickHeight, brickPadding])

  const newBallPosition = useCallback(() => {
    // Normalize the ball direction
    const directionLength = Math.sqrt(
      ballDirection.current.x * ballDirection.current.x +
        ballDirection.current.y * ballDirection.current.y,
    )
    const normalizedDirectionX = ballDirection.current.x / directionLength
    const normalizedDirectionY = ballDirection.current.y / directionLength

    // Make the ball speed the same in different screen sizes.
    const ballSpeedCoeff = canvasHeight / BASE_HEIGHT

    const x = ballPosition.x + normalizedDirectionX * ballSpeed * ballSpeedCoeff
    const y = ballPosition.y + normalizedDirectionY * ballSpeed * ballSpeedCoeff
    return { x, y }
  }, [ballPosition.x, ballPosition.y, ballSpeed, canvasHeight])

  const collisionBricksDetection = useCallback(() => {
    const r = bricks.length - 1
    const brickY = r * (brickHeight + brickPadding) + brickOffsetTop
    const bricksRow = bricks[r]
    for (let c = 0; c < bricksRow.length; c++) {
      const b = bricksRow[c]
      if (b && b.status === 1) {
        // Extend collision detection area
        const brickX = b.x - brickPadding / 2
        const brickRightX = b.x + b.width + brickPadding / 2
        const brickBottomY = brickY + brickHeight

        const ballNextPosition = newBallPosition()
        const ballNextX = ballNextPosition.x
        const ballNextY = ballNextPosition.y - ballRadius

        // Check collisions with extended areas
        const hit = ballNextY <= brickBottomY && ballNextX >= brickX && ballNextX <= brickRightX

        if (hit) {
          ballDirection.current.y = -ballDirection.current.y

          // Normalize the ball direction
          const directionLength = Math.sqrt(
            ballDirection.current.x * ballDirection.current.x +
              ballDirection.current.y * ballDirection.current.y,
          )
          ballDirection.current.x /= directionLength
          ballDirection.current.y /= directionLength

          // Collision response logic
          handleBrickHit(b)
          return
        }
      }
    }
  }, [bricks, newBallPosition, ballRadius, brickHeight, brickPadding, handleBrickHit])

  const collisionPaddleDetection = useCallback(() => {
    const currentTime = Date.now()
    lastMouseTime.current = currentTime
    lastPaddlePosition.current = paddlePosition

    const maxBounceAngle = Math.PI / bounceAngleTheta // Maximum bounce angle
    const paddleTopY = canvasHeight - paddleHeight
    const paddleLeftX = paddlePosition
    const paddleRightX = paddlePosition + paddleWidth

    const ballNextX = ballPosition.x + ballDirection.current.x * ballSpeed
    const ballNextY = ballPosition.y + ballDirection.current.y * ballSpeed

    const withinPaddleHorizontalBounds = ballNextX > paddleLeftX && ballNextX < paddleRightX
    const approachingPaddleTop =
      ballNextY + ballRadius >= paddleTopY && ballNextY - ballRadius < canvasHeight

    const hitPaddleLeftCorner =
      ballNextX + ballRadius > paddleLeftX && ballNextX < paddleLeftX && ballNextY > paddleTopY

    const hitPaddleRightCorner =
      ballNextX - ballRadius < paddleRightX && ballNextX > paddleRightX && ballNextY > paddleTopY

    if (withinPaddleHorizontalBounds && approachingPaddleTop) {
      const relativePosition = (ballNextX - (paddlePosition + paddleWidth / 2)) / (paddleWidth / 2)
      const bounceAngle = relativePosition * maxBounceAngle

      // Calculate new direction
      const newDirectionX = Math.sin(bounceAngle)
      const newDirectionY = -Math.cos(bounceAngle)

      // Normalize the direction vector
      const length = Math.sqrt(newDirectionX * newDirectionX + newDirectionY * newDirectionY)
      ballDirection.current.x = newDirectionX / length
      ballDirection.current.y = newDirectionY / length

      // Reposition to prevent sticking
      const newY = paddleTopY - ballRadius
      setBallPosition({ x: ballPosition.x, y: newY })
    } else if (hitPaddleLeftCorner || hitPaddleRightCorner) {
      ballDirection.current.x = -ballDirection.current.x
      // Optional: Additional logic for Y direction if hitting the corner
    }
  }, [
    paddlePosition,
    bounceAngleTheta,
    paddleHeight,
    paddleWidth,
    ballPosition.x,
    ballPosition.y,
    ballSpeed,
    ballRadius,
    canvasHeight,
  ])

  const collisionBoundaryDetection = useCallback(() => {
    let newPositionX = ballPosition.x
    let newPositionY = ballPosition.y

    // Left Boundary
    if (newPositionX < ballRadius) {
      ballDirection.current.x = Math.abs(ballDirection.current.x) // Ensure positive direction
      newPositionX = ballRadius // Adjust position
    }

    // Right Boundary
    if (newPositionX + ballRadius > canvasWidth) {
      ballDirection.current.x = -Math.abs(ballDirection.current.x) // Ensure negative direction
      newPositionX = canvasWidth - ballRadius // Adjust position
    }

    // Top Boundary
    if (newPositionY < ballRadius) {
      ballDirection.current.y = Math.abs(ballDirection.current.y) // Ensure positive direction
      newPositionY = ballRadius // Adjust position
    }

    // Bottom Boundary - Game Over
    if (newPositionY + ballRadius > canvasHeight && isBallActive) {
      handleGameOver()
    } else {
      setBallPosition({ x: newPositionX, y: newPositionY }) // Update position
    }
  }, [ballPosition, ballRadius, handleGameOver, isBallActive, canvasHeight, canvasWidth])

  const catchPillDetection = useCallback(() => {
    if (
      pill.y + pillWidth >= canvasHeight - paddleHeight &&
      pill.x >= paddlePosition &&
      pill.x <= paddlePosition + paddleWidth &&
      pill.active
    ) {
      setPillsNum((n) => n + 1)
      handlePillRespawn()
    }
  }, [pill, pillWidth, paddleHeight, paddlePosition, paddleWidth, handlePillRespawn, canvasHeight])

  const moveBall = useCallback(() => {
    if (!isBallActive || isAiming) {
      return
    }
    setBallPosition(newBallPosition())
  }, [newBallPosition, isAiming, isBallActive])

  const movePill = useCallback(() => {
    if (pill.active) {
      // Calculate new Y position for the pill
      const newY = pill.y + 1

      // Update pill's position without directly affecting ball movement or collision detection
      setPill((prevPill) => ({ ...prevPill, y: newY }))

      // Check if the pill has moved off the canvas
      if (newY >= canvasHeight) {
        handlePillRespawn()
      }
    }
  }, [pill, canvasHeight, handlePillRespawn])

  const updateGame = useCallback(() => {
    if (
      gameStatus === 'GameOver' ||
      gameStatus === 'Preparing' ||
      gameStatus === 'Paused' ||
      gameStatus === 'Respawn'
    ) {
      return
    }

    if (checkLevelCompletion()) {
      setGameStatus('NextLevel')
    }

    requestRef.current = requestAnimationFrame(updateGame)

    if (!isAiming) {
      collisionBoundaryDetection()
      collisionBricksDetection()
      collisionPaddleDetection()
    }

    catchPillDetection()

    if (isBallActive) {
      moveBall()
    }
    if (pill.active) {
      movePill()
    }
  }, [
    isAiming,
    catchPillDetection,
    checkLevelCompletion,
    collisionBoundaryDetection,
    collisionBricksDetection,
    collisionPaddleDetection,
    gameStatus,
    isBallActive,
    moveBall,
    movePill,
    pill.active,
  ])

  useEffect(() => {
    if (gameStatus === 'GameOver') {
      // console.log('gameStatus Changed to GameOver - Click to start New Game')
    }
    if (gameStatus === 'Preparing') {
      setShowLevel(true)
    }
    if (gameStatus === 'NewGame') {
      // console.log('gameStatus Changed from NewGame to Respawn')
      setIsBallActive(false)
      createBricks(1) // Initialize the first level
      setLives(initialLives) // Reset lives if needed
      setLevel(1) // Start from level 1
      setGameStatus('Respawn') // Move to Respawn or Active as needed
    }
    if (gameStatus === 'Respawn') {
      // console.log('gameStatus Changed from NewGame to Respawn')
      setIsAiming(true)
      placeBallOnPaddle()
      setGameStatus('Active')
    }
    if (gameStatus === 'NextLevel') {
      // console.log('gameStatus Changed from NextLevel to Respawn')
      setShowLevel(true)
      setIsBallActive(false)
      setLevel((prevLevel) => {
        const nextLevel = prevLevel + 1
        updateScore(rewardPoints * nextLevel)
        createBricks(nextLevel)
        setGameStatus('Respawn')
        return nextLevel
      })
    }
  }, [createBricks, gameStatus, initialLives, rewardPoints, updateScore, placeBallOnPaddle])

  useEffect(() => {
    requestRef.current = requestAnimationFrame(updateGame)
    return () => {
      if (requestRef.current) {
        cancelAnimationFrame(requestRef.current)
      }
    }
  }, [updateGame])

  const handleCanvasClick = () => {
    if (gameStatus === 'GameOver') {
      setGameStatus('NewGame')
    }
  }

  return (
    <div
      className={twMerge(
        'flex-col w-full h-full m-auto bg-black font-turret-road overflow-hidden relative',
        gameStatus === 'Active' && 'cursor-none',
      )}
      onClick={handleClick}
      onMouseMove={handleMouseMove}
    >
      <ArkanoidHeader hasLogo />
      <div className="w-full pt-[80px] b-relative h-[208px]">
        {gameStatus === 'Paused' && (
          <div className="absolute text-[160px] text-center font-bold top-[30%] w-full z-10">
            Pause
          </div>
        )}
        {showLevel && (
          <div className="absolute text-[160px] text-center font-bold top-[30%] w-full z-10">
            Level {level}
            <p className="text-[30px]">
              Click to start.
              <br />
              Press Space to show delays.
              <br />
              Show delays cost one <PillSvg className="inline-block w-[30px] translate-y-[-3px]" />
              <br />
              Hit delays.
            </p>
          </div>
        )}
        {gameStatus === 'GameOver' && (
          <div className="absolute text-[160px] text-center font-bold top-[30%] w-full z-10">
            Game Over
            <p className="text-[30px]">
              Your score is {score}
              {level > 1 && (
                <>
                  <br />
                  You reached level {level}
                </>
              )}
            </p>
          </div>
        )}
        <div className="pl-[24px] absolute left-0 top-[80px]">
          <h3 className="text-[64px] leading-[64px] font-bold">{name}</h3>
          <p className="text-[22px] text-gray-service pt-2">
            Lives <span className="text-white pr-5">{lives}</span>
            Level <span className="text-white">{level}</span>
          </p>
        </div>

        <div className="w-full text-center">
          <h3 className="text-[64px] leading-[64px] font-bold">
            Score <span className="text-[#D4FF5B]">{score}</span>
          </h3>
          <p className="text-[22px] text-gray-service pt-2">
            Best score <span className="text-white">{bestScore}</span>
          </p>
        </div>

        <div className="text-right text-[#D4FF5B] pr-[24px] absolute top-[80px] right-0">
          <div className="flex items-right justify-end text-[64px] leading-[64px] font-bold">
            <PillSvg />
            <span className="pl-1">&times;{pillsNum}</span>
          </div>
          <p
            className={twMerge('text-[22px] text-gray-service pt-2', blinkSpace && 'animate-pulse')}
          >
            <span className="text-white">Press Space</span> to activate PS Tool
          </p>
        </div>
      </div>
      <div className="absolute rignt-0 top-0 w-full h-full" onClick={handleCanvasClick}>
        {flatBricks.map((brick) => (
          <Brick {...brick} key={`${brick.key}`} isColorActive={isColorActive} />
        ))}
        <Ball x={ballPosition.x} y={ballPosition.y} radius={ballRadius} />
        <Paddle x={paddlePosition} width={paddleWidth} height={paddleHeight} />
        <Pill width={pillWidth} x={pill.x} y={pill.y} isHidden={!pill.active} />
      </div>
    </div>
  )
}

const Paddle = memo(({ x, width, height }: { x: number; width: number; height: number }) => {
  return (
    <svg
      width="420"
      height="32"
      viewBox="0 0 420 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="absolute bottom-0 bg-[#472B98]"
      style={{
        width: `${width}px`,
        height: `${height}px`,
        transform: `translate(${x}px)`,
      }}
    >
      <rect width="420" height="32" fill="#472B98" />
      <path d="M184.8 0H235.2L420 32H0L184.8 0Z" fill="#6C4ACE" />
    </svg>
  )
})

const Ball = memo(({ x, y, radius }: { x: number; y: number; radius: number }) => {
  return (
    <div
      className="absolute bg-white rounded-full"
      style={{
        width: `${radius * 2}px`,
        height: `${radius * 2}px`,
        transform: `translate(${x - radius}px, ${y - radius}px)`,
      }}
    ></div>
  )
})

const Brick = memo(
  ({ x, y, width, height, isSpecial, isColorActive }: FlatBrick & { isColorActive: boolean }) => {
    return (
      <div
        className={twMerge(
          'absolute text-[20px] font-bold text-black text-center leading-[34px]',
          isColorActive ? (isSpecial ? 'bg-[#C3465C]' : 'bg-[#5F41B4]') : 'bg-gray-faded',
        )}
        style={{
          height,
          width,
          transform: `translate(${x}px, ${y}px)`,
        }}
      >
        {isSpecial && isColorActive && 'Delay'}
      </div>
    )
  },
)

const Pill = memo(
  ({
    width = 60,
    x,
    y,
    isHidden = false,
  }: {
    width?: number
    x: number
    y: number
    isHidden?: boolean
  }) => {
    if (isHidden) {
      return null
    }
    return (
      <div className="absolute top-0 left-0" style={{ transform: `translate(${x}px, ${y}px)` }}>
        <PillSvg width={width} />
      </div>
    )
  },
)

const PillSvg = memo(({ width = 60, className }: { width?: number; className?: string }) => {
  return (
    <svg
      width={width}
      height={width}
      className={className}
      viewBox="0 0 60 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="60" height="60" rx="15.8824" fill="#D4FF5B" />
      <path
        d="M26.6117 17.2058H17.5764V23.3247H15.8823V31.6687H17.5764V42.794H44.1176V34.4501H26.6117V31.6687H44.1176V23.3247H26.6117V17.2058Z"
        fill="#0F0F0F"
      />
    </svg>
  )
})
