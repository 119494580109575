import { useContext, useEffect, useReducer } from 'react'
import { autorun } from 'mobx'
import { chartDataStoreContext } from 'components/ps-chart/stores/ChartDataStore'

export enum PsChartPageEnum {
  ANNOTATIONS = 'annotations',
  PROCESS_X_ANNOTATIONS = 'processesAnnotations',
  TRACE_VIEWER = 'traceViewer',
}

export type AvailablePages = Set<PsChartPageEnum>

interface AvailablePagesResult {
  availablePages: AvailablePages | null
  hasVideo: boolean
  isDataLoaded: boolean
  allAnnotationsConnected: boolean
}

function isEqual(oldState: AvailablePagesResult, newState: Partial<AvailablePagesResult>): boolean {
  return Boolean(
    oldState.hasVideo === newState.hasVideo &&
      oldState.isDataLoaded === newState.isDataLoaded &&
      oldState.availablePages?.has(PsChartPageEnum.PROCESS_X_ANNOTATIONS) ===
        newState.availablePages?.has(PsChartPageEnum.PROCESS_X_ANNOTATIONS),
  )
}

const initialState: AvailablePagesResult = {
  availablePages: null,
  isDataLoaded: false,
  allAnnotationsConnected: false,
  hasVideo: false,
}

export const useAvailablePages = (): AvailablePagesResult => {
  const chartCommonStore = useContext(chartDataStoreContext)

  const [state, setState] = useReducer(
    (oldState: AvailablePagesResult, newState: Partial<AvailablePagesResult>) => {
      if (isEqual(oldState, newState)) {
        return oldState
      } else {
        return { ...oldState, ...newState }
      }
    },
    initialState,
  )

  useEffect(() => {
    if (chartCommonStore) {
      return autorun(
        () => {
          if (
            chartCommonStore.annotationsDataStore.isLoaded &&
            chartCommonStore.videoDataStore.isLoaded
          ) {
            setState({
              availablePages: getAvailablePagesByAnnotations(
                chartCommonStore.annotationsDataStore.hasRemoteMappedAnnotations,
              ),
              allAnnotationsConnected:
                chartCommonStore.annotationsDataStore.isAllAnnotationsConnected,
              hasVideo: chartCommonStore.videoDataStore.hasVideo,
              isDataLoaded: true,
            })
          } else {
            setState({
              isDataLoaded: false,
            })
          }
        },
        { name: 'autorun @ hook useAvailablePages -> setState' },
      )
    } else {
      setState(initialState)
    }
  }, [chartCommonStore, state.isDataLoaded])

  return state
}

function getAvailablePagesByAnnotations(hasRemoteMappedAnnotations: boolean): AvailablePages {
  const pages: AvailablePages = new Set([PsChartPageEnum.ANNOTATIONS, PsChartPageEnum.TRACE_VIEWER])

  if (hasRemoteMappedAnnotations) {
    pages.add(PsChartPageEnum.PROCESS_X_ANNOTATIONS)
  }

  return pages
}
