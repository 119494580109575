import { useTranslation } from 'react-i18next'
import { Button, ButtonTextColorVariantEnum, ButtonVariantEnum } from 'components/Button'

export interface AnnotationSaveAndCancelButtonsProps {
  onSave: () => void
  onCancel: () => void
}

export const AnnotationFormButtons = ({
  onSave,
  onCancel,
}: AnnotationSaveAndCancelButtonsProps) => {
  const { t } = useTranslation()
  return (
    <div className="border-t-[1px] border-t-gray-iconStatus h-[48px] flex justify-between bg-dark-default p-[16px]">
      <Button
        variant={ButtonVariantEnum.Text}
        textColorVariant={ButtonTextColorVariantEnum.Muted}
        isSmall={false}
        withIcon={false}
        setFontSize={12}
        onClick={onCancel}
        type="button"
        className="h-fit"
      >
        {t('annotations.create.cancel')}
      </Button>
      <Button
        variant={ButtonVariantEnum.Text}
        textColorVariant={ButtonTextColorVariantEnum.Primary}
        isSmall={false}
        withIcon={false}
        setFontSize={12}
        onClick={onSave}
        type="button"
        className="h-fit"
      >
        {t('annotations.create.save')}
      </Button>
    </div>
  )
}
