import { LiveDemoController, Step } from 'components/live-demo/LiveDemoController'
import { StepHint } from 'components/StepHint'
import { useCallback, useLayoutEffect, useMemo, useState } from 'react'
import { usePopper } from 'react-popper'
import { Modifier } from '@popperjs/core'
import { psLocalStorage } from 'utils/localStorage/PsLocalStorage'
import { showGotItToaster } from 'components/ToastGotIt'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'
import { TOASTER_CLASSNAME } from 'components/StyledToaster'
import { AreaData } from 'components/HighlightingAreas'

interface LiveDemoStepHintPropTypes {
  element: HTMLElement
  area?: AreaData
  liveDemoController: LiveDemoController
  isSearchOpened: boolean
  currentStep: Step
}

export const LIVE_DEMO_TOAST_ID = 'components.liveDemo.collapseToastMessage'

export const LiveDemoStepHint = ({
  liveDemoController,
  element,
  area,
  isSearchOpened,
  currentStep,
}: LiveDemoStepHintPropTypes) => {
  const { handleNextClick, goPrevStep, disable, showGoPrev, withFooter } = liveDemoController
  const [hintElement, setHintElement] = useState<HTMLElement | null>(null)
  const { hintPlacement, hintDistance, hintSkidding, highlightingAreas } = currentStep.data.fields
  const { t } = useTranslation()

  const placement = !highlightingAreas || highlightingAreas.length === 0 ? 'center' : hintPlacement

  const popperModifiers = []
  if (placement === 'center') {
    popperModifiers.push(centerPlacement)
  }

  if (hintDistance || hintSkidding) {
    popperModifiers.push({
      name: 'offset',
      options: {
        offset: [hintSkidding ?? 0, hintDistance ?? 0],
      },
    })
  }

  popperModifiers.push({
    name: 'flip',
    options: {
      fallbackPlacements: ['top', 'right', 'bottom', 'left'],
    },
  })

  const { styles, attributes, update } = usePopper(element, hintElement, {
    placement: placement !== 'center' ? placement : undefined,
    modifiers: popperModifiers,
  })

  const marginBottom = useCallback(() => {
    const extraMargins = []
    if (withFooter) {
      extraMargins.push('var(--bottomBarHeight)')
    }
    if (isSearchOpened) {
      extraMargins.push('var(--actionPanelHeight)')
    }
    return `calc(${extraMargins.join(' + ')} + 24px)`
  }, [withFooter, isSearchOpened])

  const toasterOptions = useMemo(
    () => ({
      id: LIVE_DEMO_TOAST_ID,
      className: classNames(
        TOASTER_CLASSNAME,
        'relative',
        'after:block after:absolute after:bottom-[-8px] after:left-[50%] after:ml-[5px] after:border-solid after:border-transparent after:border-[8px] after:w-0 after:h-0 after:border-b-[0] after:border-t-sky',
      ),
      style: { marginBottom: marginBottom() },
    }),
    [marginBottom],
  )

  useLayoutEffect(() => {
    if (currentStep && update && element && area) {
      update()
    }
  }, [area, element, currentStep, update])

  const handleCollapsePress = useCallback(() => {
    disable()

    const message = t(LIVE_DEMO_TOAST_ID)

    const alreadyGotIt = psLocalStorage.hasGotIt(LIVE_DEMO_TOAST_ID)

    if (!alreadyGotIt) {
      showGotItToaster(message, LIVE_DEMO_TOAST_ID, undefined, undefined, toasterOptions)
    }
  }, [disable, t, toasterOptions])

  const onRightClick = () => {
    handleNextClick()
  }

  return (
    <div ref={setHintElement} style={styles.popper} {...attributes.popper}>
      <StepHint
        part={currentStep.partNumber}
        step={currentStep.stepNumber}
        description={currentStep.data.fields.description}
        title={currentStep.data.fields.title}
        onRightClick={onRightClick}
        onLeftClick={showGoPrev ? goPrevStep : undefined}
        onCollapseClick={handleCollapsePress}
        nextStepButtonLabel={currentStep.data.fields.nextButtonLabel}
      />
    </div>
  )
}

const centerPlacement: Modifier<
  'centerPlacement',
  { modifiersData: { popperOffsets: { x: number; y: number } } }
> = {
  name: 'centerPlacement',
  enabled: true,
  phase: 'main',
  fn: ({ state }) => {
    const { popper, reference } = state.rects

    const top = reference.y + reference.height / 2 - popper.height / 2
    const left = reference.x + reference.width / 2 - popper.width / 2

    state.modifiersData = {
      popperOffsets: {
        x: left,
        y: top,
      },
    }
  },
}
