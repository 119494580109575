import { useTranslation } from 'react-i18next'
import { useCallback, useEffect, useState } from 'react'
import { FieldErrors, useController, UseFormReturn } from 'react-hook-form'
import * as yup from 'yup'
import { usePostHog } from 'posthog-js/react'

import { useAnalytics } from 'contexts/di-context'
import { LITE_EVENTS } from 'components/lite/config'

import { Form, getFormErrorsArrayFromObject, useForm, ValidationSchema } from 'components/Form/Form'
import { useToaster } from 'hooks/useToaster'
import { useAuth } from 'contexts/auth-context'
import { isPasswordStrong } from 'utils/validation'
import { TermsAndConditions } from 'components/free-trial/TermsAndConditions'
import { LinkExpired } from 'components/free-trial/LinkExpired'

export const LitePasswordForm = (props: { verificationToken: string }) => {
  const { verificationToken } = props

  const [tokenIsValid, setTokenIsValid] = useState<boolean | null>(null)
  const [visibleName, setVisibleName] = useState('')
  const [userEmail, setUserEmail] = useState('')

  const { t } = useTranslation()
  const toaster = useToaster()
  const auth = useAuth()
  const formContext = useForm<PasswordFormFields>({
    validationSchema: passwordFormSchema,
  })

  const analytics = useAnalytics()

  const posthog = usePostHog()

  const handleSubmit = useCallback(
    (values: PasswordFormFields) => {
      auth
        .signUpPsLite({
          data: {
            inviteToken: verificationToken,
            password: values.password,
          },
        })
        .then(() => analytics?.track(LITE_EVENTS.CREATE_PASSWORD))
        .catch((error) => toaster.error(error))
    },
    [verificationToken, auth, toaster, analytics],
  )

  useEffect(() => {
    auth
      .signUpCheckToken({ inviteToken: verificationToken, allowFreeTrial: true, returnData: true })
      .then(({ email, name, lastName }) => {
        setUserEmail(email ?? '')
        setVisibleName(name || email || '')
        setTokenIsValid(true)

        if (email) {
          posthog?.identify(email, {
            email,
            name,
            lastName,
          })
        }
      })
      .catch(() => setTokenIsValid(false))
  }, [verificationToken, auth, toaster, posthog])

  const handleSubmitInvalid = useCallback(
    (errors: FieldErrors<PasswordFormFields>) => {
      const errorMessages = getFormErrorsArrayFromObject(errors)
      errorMessages.forEach((errorMsg) => {
        toaster.error(errorMsg)
      })
    },
    [toaster],
  )

  if (tokenIsValid === null) {
    return null
  } else if (!tokenIsValid) {
    // TODO: use another link expired for PS Tool Lite
    return <LinkExpired inviteToken={verificationToken} />
  }

  return (
    <div>
      <div className="text-center mb-[48px]">
        <h2 className="text-header-small mb-[8px]">{t('auth.freeTrial.passwordForm.title')}</h2>
        {visibleName && <h3 className="text-normal">{visibleName}</h3>}
      </div>
      <div className="max-w-[320px] mx-auto">
        <Form<PasswordFormFields>
          formContext={formContext}
          onSubmit={handleSubmit}
          onSubmitInvalid={handleSubmitInvalid}
        >
          <PasswordInputsGroup
            visibleName={visibleName}
            userEmail={userEmail}
            formContext={formContext}
          />
        </Form>
        <TermsAndConditions
          linkClassName="text-black underline"
          className="text-black text-small"
        />
      </div>
    </div>
  )
}

interface PasswordInputsGroupProps {
  visibleName: string
  userEmail: string
  formContext: UseFormReturn<PasswordFormFields>
}

const PasswordInputsGroup = ({ visibleName, userEmail, formContext }: PasswordInputsGroupProps) => {
  const { t } = useTranslation()
  const passwordController = useController<PasswordFormFields>({ name: 'password' })
  const confirmPasswordController = useController<PasswordFormFields>({
    name: 'confirmPassword',
  })

  const isFormValid = confirmPasswordController.formState.isValid
  const isFormFilled = Boolean(
    passwordController.field.value && confirmPasswordController.field.value,
  )

  const passwordContext = formContext.register('password', { required: true })

  return (
    <>
      <input
        hidden={true}
        name="name"
        value={visibleName}
        type="text"
        readOnly={true}
        autoComplete="username"
      />
      <input
        hidden={true}
        name="email"
        value={userEmail}
        type="text"
        readOnly={true}
        autoComplete="user email"
      />
      <input
        {...passwordContext}
        className="rounded-full w-full px-5 mb-5"
        type="password"
        placeholder={t('auth.freeTrial.passwordForm.password')}
      />
      <input
        {...formContext.register('confirmPassword', { required: true })}
        className="rounded-full w-full px-5 mb-5"
        type="password"
        placeholder={t('auth.freeTrial.passwordForm.confirmPassword')}
      />

      <button
        type="submit"
        className="rounded-full bg-black text-white px-6 py-2 focus:outline-black hover:opacity-80 disabled:opacity-20"
        disabled={!isFormFilled && !isFormValid}
      >
        <span className="text-header-small-v2">{t('auth.freeTrial.passwordForm.button')}</span>
      </button>
    </>
  )
}

interface PasswordFormFields {
  password: string
  confirmPassword: string
}

export const passwordFormSchema = yup.object<ValidationSchema<PasswordFormFields>>({
  password: yup.string().required(),
  confirmPassword: yup
    .string()
    .required()
    .test('isPasswordTheSame', 'auth.common.isNotSamePasswords', function (value) {
      return this.parent.password === value
    })
    .test('isPasswordStrong', 'auth.common.strongPassword', isPasswordStrong),
})
