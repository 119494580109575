import { useTranslation } from 'react-i18next'
import { Modal, ModalSizeEnum } from 'components/Modal'
import { ButtonTextColorVariantEnum } from 'components/Button'

interface Props {
  onProceed: () => void
  onClose: () => void
  isOpen: boolean
}

export const UnsavedAnnotationModal = ({ onProceed, onClose, isOpen }: Props) => {
  const { t } = useTranslation()

  return (
    <Modal
      title={t('annotations.unsaved.title')}
      isOpen={isOpen}
      size={ModalSizeEnum.NORMAL}
      onClose={onClose}
      actionButton={{
        children: t('annotations.unsaved.button'),
        textColorVariant: ButtonTextColorVariantEnum.Bad,
        onClick: onProceed,
      }}
      hiddenCloseButton={false}
    >
      <div className="flex min-h-[51px] items-center">
        <p className="text-small text-gray-normal">{t('annotations.unsaved.text')}</p>
      </div>
    </Modal>
  )
}
