import { getServerHost } from 'utils/getServerHost'

export const ROOT_HOST = 'productscience.app'
export const ROOT_HOST_LITE = 'crpath.app'

export enum HostType {
  PROD = 'production',
  STAGING = 'staging',
  DEV = 'dev',
  UNKNOWN = 'unknown',
}

export const isHostTypeProd = (): boolean => {
  return getHostType() === HostType.PROD
}

export const getHostType = (): HostType => {
  const host = getServerHost()
  if (
    host === ROOT_HOST ||
    host === `prod.${ROOT_HOST}` ||
    host === `beta.${ROOT_HOST}` ||
    host === ROOT_HOST_LITE ||
    host === `beta.${ROOT_HOST_LITE}`
  ) {
    return HostType.PROD
  } else if (
    host.includes(`staging.${ROOT_HOST}`) ||
    host.includes(`staging.${ROOT_HOST_LITE}`) ||
    host === `test.${ROOT_HOST}`
  ) {
    return HostType.STAGING
  } else if (host === `dev.${ROOT_HOST}` || host === `dev-1.${ROOT_HOST}`) {
    return HostType.DEV
  } else {
    return HostType.UNKNOWN
  }
}
