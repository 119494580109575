import Konva from 'konva'
import { RefObject } from 'react'
import { round10 } from 'utils/round'
import { ActiveWindow, ActiveWindowSettings } from 'components/global-timeline/models/ActiveWindow'
import { createLine, createRect, createStage, createText } from 'utils/konva'
import { Limiter, LimiterSettings, LimiterType } from './models/Limiter'
import { GlobalTimelineSettings } from './models/GlobalTimelineSettings'

export type OnTimeWindowChange = (start: number, end: number) => void

export interface GlobalTimelineListener {
  onTimeWindowChange: OnTimeWindowChange
}

export class GlobalTimelineBaseRenderer {
  readonly settings: GlobalTimelineSettings
  private readonly listener: GlobalTimelineListener
  protected readonly height: number
  protected readonly scaleLayerHeight: number
  protected width: number
  protected timePerPx: number
  protected readonly min: number
  protected readonly max: number

  protected readonly stage: Konva.Stage
  protected readonly scaleLayer: Konva.Layer
  protected readonly timelineLayer: Konva.Layer
  protected readonly contentLayer: Konva.Layer
  protected readonly highlightsLayer: Konva.Layer

  protected readonly limiterSettings: LimiterSettings
  protected readonly leftLimiter: Limiter
  protected readonly rightLimiter: Limiter
  private readonly leftDarkRect: Konva.Rect
  protected readonly rightDarkRect: Konva.Rect

  protected readonly activeWindow: ActiveWindow

  private start: number
  private end: number

  constructor(
    containerId: string,
    globalTimelineContainerRef: RefObject<HTMLDivElement>,
    min: number,
    max: number,
    settings: GlobalTimelineSettings,
    listener: GlobalTimelineListener,
  ) {
    this.listener = listener
    this.settings = settings
    this.min = min
    this.max = max
    this.width = globalTimelineContainerRef.current?.getBoundingClientRect().width ?? 0
    this.height = globalTimelineContainerRef.current?.getBoundingClientRect().height ?? 0
    this.scaleLayerHeight = this.height - this.settings.topOffset
    this.timePerPx = (max - min) / this.width
    this.start = 0
    this.end = this.width

    this.stage = createStage(containerId, this.width, this.height)

    this.timelineLayer = new Konva.Layer()
    this.stage.add(this.timelineLayer)

    this.contentLayer = new Konva.Layer()
    this.stage.add(this.contentLayer)

    this.highlightsLayer = new Konva.Layer()
    this.stage.add(this.highlightsLayer)

    this.scaleLayer = new Konva.Layer({
      y: this.height - this.scaleLayerHeight,
    })
    this.stage.add(this.scaleLayer)

    this.renderTimeLines() // render scale before anything to achieve right z-ordering in the layer

    this.activeWindow = new ActiveWindow(this.initActiveWindowSettings())
    this.scaleLayer.add(this.activeWindow.rect)

    this.limiterSettings = this.initLimiterSettings()

    this.leftLimiter = new Limiter(LimiterType.LEFT, this.limiterSettings)
    this.leftDarkRect = this.createDarkRectOutOfSelectedTime(0, this.leftLimiter.group.width())

    this.rightLimiter = new Limiter(LimiterType.RIGHT, this.limiterSettings)
    this.rightLimiter.group.x(this.width - 1)
    this.rightDarkRect = this.createDarkRectOutOfSelectedTime(
      this.rightLimiter.group.x(),
      this.width - this.rightLimiter.group.x(),
    )

    this.scaleLayer.add(this.leftDarkRect)
    this.scaleLayer.add(this.rightDarkRect)
    this.scaleLayer.add(this.leftLimiter.group)
    this.scaleLayer.add(this.rightLimiter.group)
  }

  addEventListeners() {
    this.activeWindow.addEventListeners()
    this.leftLimiter.addEventListeners()
    this.rightLimiter.addEventListeners()
    this.activeWindow.rect.on('dragmove', this.onActiveWindowDrag)
    this.leftLimiter.group.on('dragmove', this.onLeftLimiterDrag)
    this.rightLimiter.group.on('dragmove', this.onRightLimiterDrag)
  }

  removeEventListeners() {
    this.leftLimiter.group.off('dragmove', this.onLeftLimiterDrag)
    this.rightLimiter.group.off('dragmove', this.onRightLimiterDrag)
    this.activeWindow.rect.off('dragmove', this.onActiveWindowDrag)
    this.leftLimiter.removeEventListeners()
    this.rightLimiter.removeEventListeners()
    this.activeWindow.removeEventListeners()
  }

  updateState(width: number, xStart: number, xEnd: number) {
    const widthChanged = this.width !== width
    if (widthChanged) {
      this.width = width
      this.timePerPx = (this.max - this.min) / this.width
      this.stage.width(width)
      this.renderTimeLines()
    }
    const xStartPx = Math.round((xStart - this.min) / this.timePerPx)
    const xEndPx = Math.round((xEnd - this.min) / this.timePerPx)
    this.updateElementsPos(xStartPx, xEndPx - xStartPx)
  }

  private createDarkRectOutOfSelectedTime(x: number, width: number): Konva.Rect {
    return createRect(
      x,
      0,
      width,
      this.scaleLayerHeight,
      this.settings.common.palette.bgOutOfSelectedTime,
    )
  }

  private onActiveWindowDrag = () => {
    let { startX } = this.activeWindow
    const { width } = this.activeWindow
    if (startX < 0) {
      startX = 0
    }
    if (startX + width > this.width - 1) {
      startX = this.width - 1 - this.activeWindow.width
    }

    this.updateElementsPos(startX, width)
    this.callOnTimeWindowChangeListener()
  }

  protected onLeftLimiterDrag = () => {
    let { startX } = this.leftLimiter
    if (startX < 0) {
      startX = 0
    }
    const maxX = this.rightLimiter.startX - this.limiterSettings.grabPadding + 1
    if (startX > maxX) {
      startX = maxX
    }
    const width = this.rightLimiter.startX - startX

    this.updateElementsPos(startX, width)
    this.callOnTimeWindowChangeListener()
  }

  protected onRightLimiterDrag = () => {
    const { startX } = this.leftLimiter
    let rightX = this.rightLimiter.startX
    if (rightX > this.width - 1) {
      rightX = this.width - 1
    }
    const minX = startX + this.limiterSettings.grabPadding - 1
    if (rightX < minX) {
      rightX = minX
    }
    const width = rightX - startX

    this.updateElementsPos(startX, width)
    this.callOnTimeWindowChangeListener()
  }

  protected updateElementsPos(startX: number, width: number) {
    let xEnd = startX + width
    if (xEnd > this.width - 1) {
      xEnd = this.width - 1
    }
    this.activeWindow.updatePos(startX, xEnd - startX)
    this.activeWindow.startY = 0

    this.leftLimiter.startX = startX
    this.leftLimiter.startY = 0

    this.rightLimiter.startX = xEnd
    this.rightLimiter.startY = 0

    this.leftDarkRect.width(startX)

    this.rightDarkRect.x(xEnd)
    this.rightDarkRect.width(this.width - xEnd)
  }

  private renderTimeLines() {
    const layer = this.timelineLayer
    layer.destroyChildren()
    const settings = this.settings.common
    const widthInSec = (this.max - this.min) / 1_000_000_000
    const segment = this.calcRoundedSegment(widthInSec)
    const scale = this.width / widthInSec
    let x = 0
    let currentTimeInSec = 0
    while (x < this.width) {
      const roundedX = Math.round(x)
      const linePoints = [roundedX, 0, roundedX, this.settings.scaleHeight]
      const time = new Date(currentTimeInSec * 1000).toISOString().slice(14, -5)
      layer.add(this.drawLine(linePoints))
      layer.add(
        this.drawText(roundedX + settings.scaleTextLeftPadding, settings.scaleTextTopPadding, time),
      )
      x += segment * scale
      currentTimeInSec += segment
    }
  }

  protected drawText(x: number, y: number, text: string): Konva.Text {
    const settings = this.settings.common
    return createText(x, y, text, settings.palette.text, settings.fontSize, settings.fontFamily)
  }

  protected drawLine(points: Array<number>): Konva.Line {
    const settings = this.settings.common
    return createLine(points, settings.palette.timeScaleLine, settings.scaleWidth)
  }

  /**
   * Calculates segments approximately rounded to nice numbers according to @{GlobalTimelineSettings.scalePoints} count
   * @param width total canvas width
   * @private
   */
  protected calcRoundedSegment(width: number): number {
    const segment = width / this.settings.scalePoints
    const power = Math.ceil(Math.log10(segment))
    if (power < 1) {
      return 1
    }
    const preResult = Math.round(segment * 10 ** power) / 10 ** power
    const multiplier = power < 2 ? 10 : 1
    return round10(preResult * multiplier) / multiplier
  }

  protected callOnTimeWindowChangeListener() {
    let newStart = this.leftLimiter.group.x()
    let newEnd = this.rightLimiter.group.x()
    if (newStart < 0) {
      newStart = 0
    }
    if (newEnd > this.width) {
      newEnd = this.width
    }
    if (newStart !== this.start || newEnd !== this.end) {
      this.listener.onTimeWindowChange(newStart, newEnd)
      this.start = newStart
      this.end = newEnd
    }
  }

  private initLimiterSettings(): LimiterSettings {
    return {
      limiterWidth: 1,
      limiterHeight: this.scaleLayerHeight,
      color: this.settings.common.palette.dragLine,
      grabPadding: 8,
      markerWidth: 8,
      canvasWidth: this.width,
      cursorDefault: 'default',
      cursorResize: 'ew-resize',
    }
  }

  private initActiveWindowSettings(): ActiveWindowSettings {
    return {
      initialWidth: this.width,
      height: this.scaleLayerHeight,
      cursorDefault: 'default',
      cursorDragging: 'grabbing',
      cursorMove: 'move',
    }
  }
}
