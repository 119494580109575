import React, { ReactNode, useContext, useEffect } from 'react'

import { BreadcrumbContext } from './BreadcrumbContext'

interface BreadcrumbSectionProps {
  isLoading?: boolean
  children: ReactNode
}

export const BreadcrumbSection = ({ isLoading = false, children }: BreadcrumbSectionProps) => {
  const breadcrumbContext = useContext(BreadcrumbContext)

  if (!breadcrumbContext) {
    throw new Error('BreadcrumbSection must be used within BreadcrumbsProvider')
  }

  const { registerLoading } = breadcrumbContext

  useEffect(() => {
    registerLoading(isLoading)
  }, [isLoading, registerLoading])

  return children as React.ReactElement
}
