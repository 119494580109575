import { useCallback } from 'react'
import { Toaster } from 'hooks/useToaster'
import { VideoPlayerStore } from 'components/ps-chart/stores/VideoPlayerStore'

export const useDeleteVideo = (videoStore: VideoPlayerStore, toaster: Toaster) => {
  return useCallback(() => {
    videoStore.deleteVideo().catch((err) => {
      toaster.error(err, 'psChart.error.video.cantDeleteVideo')
    })
  }, [videoStore, toaster])
}
