import React from 'react'
import { Select, SelectOption } from 'components/Select'

interface SelectInputProps<Value> {
  label: string
  options: SelectOption<Value>[]
  value: Value | Value[]
  onChange: (value: Value | Value[]) => void
  multiple?: boolean
  disabled?: boolean
}

export const SettingsSelect = <Value,>({
  label,
  options,
  value,
  onChange,
  multiple,
  disabled,
}: SelectInputProps<Value>) => {
  return (
    <div>
      <p className="mb-1 text-small text-gray-normal">{label}</p>
      <Select
        className="text-normal"
        options={options}
        value={value}
        onChange={(newValue) => onChange(newValue)}
        multiple={multiple}
        disabled={disabled}
      />
    </div>
  )
}
