import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation } from 'react-query'
import NProgress from 'nprogress'
import { useToaster } from 'hooks/useToaster'
import { generatePath, Link } from 'react-router-dom'
import { twMerge } from 'tailwind-merge'

import { useAuth } from 'contexts/auth-context'
import { SigninDto, TokenDest } from 'api/models'
import { GoogleButton } from 'components/auth/GoogleButton'
import { AxiosError } from 'axios'
import { useAnalytics } from 'contexts/di-context'

import { PATH_LITE_TRACES } from 'pages/lite/LiteTracesPage'
import { LoginViaEmail } from 'components/lite/LoginViaEmail'
import { RequestPsLiteForm } from 'components/lite/RequestPsLiteForm'
import { TermsAndConditions } from 'components/free-trial/TermsAndConditions'
import { LiteModal } from 'components/lite/LiteModal'
import { LITE_EVENTS } from 'components/lite/config'
import { usePostHog } from 'posthog-js/react'

type Props = {
  buttonLabel?: React.ReactNode
  buttonClassName?: string
  isSignUp?: boolean
}

export const SignInModal = ({ buttonLabel, buttonClassName, isSignUp: isSignUpProp }: Props) => {
  const { t } = useTranslation()
  const auth = useAuth()
  const toaster = useToaster()
  const [isSignInModalOpened, setIsSignInModalOpened] = useState(false)
  const [isSignUp, setIsSignUp] = useState(!!isSignUpProp)
  const analytics = useAnalytics()
  const posthog = usePostHog()

  const signInSSOMutation = useMutation(
    (variables: SigninDto) =>
      auth
        .signInSSO({
          data: variables,
          redirectPath: PATH_LITE_TRACES,
        })
        .then((res) => {
          if ('isNewUser' in res) {
            const {
              isNewUser,
              user: { email },
            } = res

            analytics.track(isNewUser ? LITE_EVENTS.SIGN_UP_SSO : LITE_EVENTS.LOG_IN_SSO, {
              provider: 'google',
            })
            posthog?.identify(email, {
              email: email,
              isLite: true,
            })
          }
        }),
    {
      onSuccess: () => {
        NProgress.done()
      },
      onError: (error: AxiosError) => {
        NProgress.done()
        toaster.error(error)
      },
    },
  )

  const handleSignInViaFirebase = useCallback(
    (ssoIdToken) => {
      signInSSOMutation.mutate({
        ssoData: { ssoIdToken, signUpType: 'LITE' },
        rememberMe: true,
        tokenDest: TokenDest.COOKIE,
      })
    },
    [signInSSOMutation],
  )

  if (auth.isSignedIn) {
    return (
      <>
        <Link
          to={generatePath(PATH_LITE_TRACES)}
          className={twMerge(
            'rounded-full m-1 bg-black text-white px-6 py-2 focus:outline-black hover:opacity-80',
            buttonClassName,
          )}
        >
          {t('lite.uploadTraceButton')}
        </Link>
      </>
    )
  }

  return (
    <>
      <button
        type="button"
        className={twMerge(
          'rounded-full m-1 bg-black text-header-small-v2 text-white px-6 py-2 focus:outline-black hover:opacity-80',
          'focus-visible:border-white focus-visible:outline-electro border',
          buttonClassName,
        )}
        onClick={() => setIsSignInModalOpened(true)}
      >
        <span className="whitespace-nowrap">{buttonLabel || t('lite.signUpButton')}</span>
      </button>
      <LiteModal isOpen={isSignInModalOpened} onClose={() => setIsSignInModalOpened(false)}>
        <div className="">
          {isSignUp ? (
            <>
              <h2 className="font-semibold mb-5">{t('lite.signUp.enterEmail')}</h2>
              <RequestPsLiteForm />
              <h2 className="font-semibold mt-10">{t('lite.signUp.viaGoogle')}</h2>
              <GoogleButton
                className="mt-5 rounded-full text-header-small-v2 py-1 h-auto focus-visible:border-electro hover:outline-electro hover:border-electro hover:outline"
                onSuccess={handleSignInViaFirebase}
              />
              <TermsAndConditions
                linkClassName="text-black underline"
                className="text-black text-small"
              />

              <div className="text-right">
                <button className="mt-4 underline" onClick={() => setIsSignUp(false)}>
                  {t('lite.signUp.alreadyHaveAccount')}
                </button>
              </div>
            </>
          ) : (
            <>
              <h2 className="font-semibold">{t('lite.logIn.logIn')}</h2>
              <GoogleButton
                className="mt-5 rounded-full text-header-small-v2 py-1 h-auto focus-visible:border-electro hover:outline-electro hover:border-electro hover:outline"
                onSuccess={handleSignInViaFirebase}
              />
              <h2 className="font-semibold mt-10">{t('lite.logIn.viaEmail')}</h2>
              <LoginViaEmail />
              <div className="text-right">
                <button className="mt-4 underline" onClick={() => setIsSignUp(true)}>
                  {t('lite.logIn.dontHavAccount')}
                </button>
              </div>
            </>
          )}
        </div>
      </LiteModal>
    </>
  )
}
