import React, { useCallback, useMemo, useState } from 'react'
import { MergedSlice } from 'components/ps-chart/stores/connections-store/LinksTree/getChainedSliceTree'
import { twMerge } from 'tailwind-merge'
import styled from 'styled-components/macro'
import tw from 'twin.macro'
import {
  sortUsingSummaryType,
  stopMouseDown,
  TableCardChild,
  TableCardChildButton,
  TableCardTreeChild,
  TableEndpoint,
  transformSummary,
} from 'components/cco/StatsView'
import { EndpointData, EndpointSessionData, MethodData, SummaryDto } from 'components/cco/types'
import { TableSummaryView } from 'components/cco/TableStateToggleButton'
import { useCcoParams } from 'components/cco/useCcoParams'
import { Link } from 'react-router-dom'
import { Icon } from 'components/Icon'

export const TableColumnText = styled.span<{ right: boolean }>`
  direction: ${({ right }) => (right ? 'rtl' : 'ltl')};
`

export const TableColumnContent = styled.div`
  ${tw`flex items-center h-[100%] hover:bg-dark-dark3`}
`

const TableRowContainer = ({
  data,
  containerClassName,
  firstTextClassName,
  otherTextClassName,
  onMouseDown = () => {},
  onMouseUp = () => {},
}: {
  data: string[]
  containerClassName: string
  firstTextClassName: string
  otherTextClassName: string
  onMouseUp: () => void
  onMouseDown: () => void
}) => {
  return (
    <div
      className={twMerge('px-[8px] mb-[2px] rounded-[4px]', 'grid grid-cols-8', containerClassName)}
    >
      {data.length &&
        data.map((item, index) => (
          <div
            key={index}
            className={`${index === 0 ? 'col-span-4 *:truncate' : 'col-span-1'} flex items-center`}
            onMouseDown={onMouseDown}
            onMouseUp={onMouseUp}
          >
            <TableColumnText
              className={twMerge('px-[8px]', index === 0 ? firstTextClassName : otherTextClassName)}
              right={index === 0}
            >
              {item}
            </TableColumnText>
          </div>
        ))}
    </div>
  )
}

export const TableEndpointMethodsElement = ({
  data,
  methods,
  sessions,
  summaryViewMode,
}: {
  data: string[]
  methods: MethodData[]
  sessions: EndpointSessionData[]
  summaryViewMode: TableSummaryView
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const [mouseDownTime, setMouseDownTime] = useState(0)

  const handleMouseDown = useCallback(() => {
    setMouseDownTime(Date.now())
  }, [])

  const handleClick = useCallback(() => {
    const duration = Date.now() - mouseDownTime

    // Threshold duration to differentiate between click and text selection
    const threshold = 200 // milliseconds

    if (duration < threshold) {
      setIsOpen((state) => !state)
    }
  }, [mouseDownTime, setIsOpen])

  return (
    <>
      {!isOpen ? (
        <TableRowContainer
          onMouseDown={handleMouseDown}
          onMouseUp={handleClick}
          data={data}
          containerClassName="bg-dark-dark1 hover:bg-dark-dark2 h-[56px]"
          firstTextClassName="text-[16px] leading-[22px] text-gray-service"
          otherTextClassName="text-[12px] leading-[17px] text-gray-service"
        />
      ) : (
        <TableEndpointMethodsCardContainer
          onMouseDown={handleMouseDown}
          onMouseUp={handleClick}
          data={data}
          methods={methods}
          sessions={sessions}
          summaryViewMode={summaryViewMode}
        />
      )}
    </>
  )
}

export const TableEndpointMethodsCardContainer = ({
  data,
  methods,
  sessions,
  summaryViewMode,
  onMouseDown = () => {},
  onMouseUp = () => {},
}: {
  data: string[]
  methods: MethodData[]
  sessions: EndpointSessionData[]
  summaryViewMode: TableSummaryView
  onMouseUp: () => void
  onMouseDown: () => void
}) => {
  const { ccoSessionPath } = useCcoParams()

  const [methodsExpander, setMethodsExpander] = useState(1)
  const [sessionsExpander, setSessionsExpander] = useState(1)

  const expandMethods = useCallback(
    (event: React.MouseEvent<HTMLDivElement>) => {
      event.stopPropagation()
      setMethodsExpander((prevExpander) => prevExpander + 1)
    },
    [setMethodsExpander],
  )

  const collapseMethods = useCallback(
    (event: React.MouseEvent<HTMLDivElement>) => {
      event.stopPropagation()
      setMethodsExpander(1)
    },
    [setMethodsExpander],
  )

  const expandSessions = useCallback(
    (event: React.MouseEvent<HTMLDivElement>) => {
      event.stopPropagation()
      setSessionsExpander((prevExpander) => prevExpander + 1)
    },
    [setSessionsExpander],
  )

  const collapseSessions = useCallback(
    (event: React.MouseEvent<HTMLDivElement>) => {
      event.stopPropagation()
      setSessionsExpander(1)
    },
    [setSessionsExpander],
  )

  return (
    <div className="px-[8px] pb-[16px] mb-[2px] rounded-[4px] bg-dark-dark2">
      <div className="h-[56px] border-b-[1px] border-b-[#383838] grid grid-cols-8">
        {data.length &&
          data.map((item, index) => (
            <div
              key={index}
              className={`${
                index === 0 ? 'col-span-4 *:truncate' : 'col-span-1'
              } flex items-center`}
              onMouseDown={onMouseDown}
              onMouseUp={onMouseUp}
            >
              <TableColumnText
                className={twMerge(
                  'px-[8px]',
                  index === 0
                    ? 'text-[16px] leading-[22px] font-semibold'
                    : 'text-[12px] leading-[17px]',
                )}
                right={index === 0}
              >
                {item}
              </TableColumnText>
            </div>
          ))}
      </div>
      <>
        {methods
          .slice(0, 10 * methodsExpander)
          .map(({ title, initialStart, cpuTime, storage, traffic, count, type }, index) => (
            <TableCardChild
              key={index}
              data={[
                title,
                ...transformSummary(
                  { initialStart, cpuTime, traffic, storage, count },
                  summaryViewMode,
                ),
              ]}
              methodType={type}
            >
              {methodsExpander > 1 &&
                index === Math.min(methods.length - 1, 10 * methodsExpander - 2) && (
                  <TableCardChildButton onClick={collapseMethods} onMouseDown={stopMouseDown}>
                    Collapse
                  </TableCardChildButton>
                )}
              {methods.length / 10 >= methodsExpander && index === 10 * methodsExpander - 1 && (
                <TableCardChildButton onClick={expandMethods} onMouseDown={stopMouseDown}>
                  Expand
                </TableCardChildButton>
              )}
            </TableCardChild>
          ))}
        {sessions
          .slice(0, 10 * sessionsExpander)
          .map(({ title, initialStart, cpuTime, traffic, count, storage }, index) => (
            <TableCardChild
              key={index}
              data={[
                <Link to={ccoSessionPath(title)}>{title}</Link>,
                ...transformSummary(
                  { initialStart, cpuTime, traffic, storage, count },
                  summaryViewMode,
                ),
              ]}
              lastItem={index === Math.min(sessions.length - 1, 10 * sessionsExpander - 1)}
            >
              {index === 0 && (
                <span className="text-[12px] leading-[17px] font-semibold text-white">
                  User Sessions
                </span>
              )}
              {sessionsExpander > 1 &&
                index === Math.min(methods.length - 1, 10 * methodsExpander - 2) && (
                  <TableCardChildButton onClick={collapseSessions} onMouseDown={stopMouseDown}>
                    Collapse
                  </TableCardChildButton>
                )}
              {sessions.length / 10 >= sessionsExpander && index === 10 * sessionsExpander - 1 && (
                <TableCardChildButton onClick={expandSessions} onMouseDown={stopMouseDown}>
                  Expand
                </TableCardChildButton>
              )}
            </TableCardChild>
          ))}
      </>
    </div>
  )
}

const TableEndpointTreeElement = ({
  endpoint,
  summaryViewMode,
  sortType,
  sortInvertDirection,
}: {
  endpoint: MergedSlice
  summaryViewMode: TableSummaryView
  sortType: keyof SummaryDto
  sortInvertDirection: boolean
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const [mouseDownTime, setMouseDownTime] = useState(0)

  const handleMouseDown = useCallback(() => {
    setMouseDownTime(Date.now())
  }, [])

  const handleClick = useCallback(() => {
    const duration = Date.now() - mouseDownTime
    const threshold = 200 // milliseconds
    if (duration < threshold) {
      setIsOpen((state) => !state)
    }
  }, [mouseDownTime])

  // Prepare data for the root node
  const data = [
    endpoint.title,
    ...transformSummary(
      {
        initialStart: endpoint.initialStart,
        cpuTime: endpoint.cpuTime,
        traffic: endpoint.traffic ?? 0,
        storage: endpoint.storage ?? 0,
        count: endpoint.count,
      },
      summaryViewMode,
    ),
  ]

  return (
    <>
      {!isOpen ? (
        <TableRowContainer
          onMouseDown={handleMouseDown}
          onMouseUp={handleClick}
          data={data}
          containerClassName="bg-dark-dark1 hover:bg-dark-dark2 h-[56px]"
          firstTextClassName="text-[16px] leading-[22px] text-gray-service"
          otherTextClassName="text-[12px] leading-[17px] text-gray-service"
        />
      ) : (
        <>
          {/* Render the root node as a card */}
          <div className="px-[8px] pb-[16px] mb-[2px] rounded-[4px] bg-dark-dark2">
            {/* Header */}
            <div
              className="h-[56px] border-b-[1px] border-b-[#383838] grid grid-cols-8 items-center"
              onMouseDown={handleMouseDown}
              onMouseUp={handleClick}
            >
              {/* Render the root node data */}
              {data.map((item, index) => (
                <div
                  key={index}
                  className={`${index === 0 ? 'col-span-4' : 'col-span-1'} flex items-center`}
                >
                  <TableColumnText
                    className={twMerge(
                      'px-[8px]',
                      index === 0
                        ? 'text-[16px] leading-[22px] font-semibold'
                        : 'text-[12px] leading-[17px]',
                    )}
                    right={index === 0}
                  >
                    {item}
                  </TableColumnText>
                </div>
              ))}
            </div>
            {/* Chain Tree */}
            {endpoint.links
              .sort((x: TableEndpoint, y: TableEndpoint) =>
                sortUsingSummaryType(x, y, sortType, sortInvertDirection),
              )
              .map((childNode, index) => (
                <ChainTreeView
                  key={index}
                  node={childNode}
                  level={1} // Start at level 1
                  summaryViewMode={summaryViewMode}
                  sortType={sortType}
                  sortInvertDirection={sortInvertDirection}
                />
              ))}
          </div>
        </>
      )}
    </>
  )
}

const ChainTreeView = ({
  node,
  level,
  summaryViewMode,
  sortType,
  sortInvertDirection,
}: {
  node: MergedSlice
  level: number
  summaryViewMode: TableSummaryView
  sortType: keyof SummaryDto
  sortInvertDirection: boolean
}) => {
  const [isExpanded, setIsExpanded] = useState(false)

  const handleToggle = (event: React.MouseEvent) => {
    event.stopPropagation()
    setIsExpanded((prev) => !prev)
  }

  const indentSize = 16 // Adjust as needed for indentation

  const hasChildren = node.links && node.links.length > 0

  // Prepare the title element with expand/collapse button and indentation
  const titleElement = (
    <div
      onClick={handleToggle}
      className="flex flex-row flex-row-reverse items-center"
      style={{ marginLeft: level * indentSize }}
    >
      {hasChildren && (
        <Icon className="mr-2 flex-shrink-0 flex-grow-0" icon={isExpanded ? 'minus' : 'plus'} />
      )}
      {node.variant && (
        <span className="mr-2 text-sm font-semibold text-gray-500 uppercase">{node.variant}</span>
      )}
      <span className="truncate" title={node.title}>
        {node.title}
      </span>
    </div>
  )

  // Prepare the data array for TableCardChild
  const data = [
    titleElement,
    ...transformSummary(
      {
        initialStart: node.initialStart,
        cpuTime: node.cpuTime,
        traffic: node.traffic ?? 0,
        storage: node.storage ?? 0,
        count: node.count,
      },
      summaryViewMode,
    ),
  ]

  // Memoize the sorted links to avoid unnecessary re-sorting
  const sortedLinks = useMemo(() => {
    return [...node.links].sort((a, b) => sortUsingSummaryType(a, b, sortType, sortInvertDirection))
  }, [node.links, sortType, sortInvertDirection])

  return (
    <>
      <TableCardTreeChild data={data}>
        {/* Optional child elements, such as Expand/Collapse buttons if needed */}
      </TableCardTreeChild>

      {/* Render child nodes if expanded */}
      {isExpanded &&
        sortedLinks.map((childNode, index) => (
          <ChainTreeView
            key={index}
            node={childNode}
            level={level + 1}
            summaryViewMode={summaryViewMode}
            sortType={sortType}
            sortInvertDirection={sortInvertDirection}
          />
        ))}
    </>
  )
}

function createUniqueKey(title: string, summary: SummaryDto) {
  const titlePart = title.slice(0, 5) + title.slice(-5)
  const summaryPart = Object.values(summary)
    .filter((value) => typeof value === 'number')
    .join('')

  return `${titlePart}-${summaryPart}`
}

export const TableEndpointMethodsList = ({
  endpoints,
  summaryViewMode,
  sortType,
  sortInvertDirection,
}: {
  endpoints: EndpointData[]
  summaryViewMode: TableSummaryView
  sortType: keyof SummaryDto
  sortInvertDirection: boolean
}) => {
  return (
    <>
      {endpoints.map(({ title, sessions, directMethods, indirectMethods, ...summary }) => {
        const methods = [...directMethods, ...indirectMethods].sort((x, y) =>
          sortUsingSummaryType(x, y, sortType, sortInvertDirection),
        )
        return (
          <TableEndpointMethodsElement
            key={createUniqueKey(title, summary)}
            data={[title, ...transformSummary({ ...summary }, summaryViewMode)]}
            methods={methods}
            sessions={sessions}
            summaryViewMode={summaryViewMode}
          />
        )
      })}
    </>
  )
}

export const TableEndpointTreeList = ({
  endpoints,
  summaryViewMode,
  sortType,
  sortInvertDirection,
}: {
  endpoints: MergedSlice[]
  summaryViewMode: TableSummaryView
  sortType: keyof SummaryDto
  sortInvertDirection: boolean
}) => {
  return (
    <>
      {endpoints.map((endpoint) => {
        const { title, links, ...summary } = endpoint

        if (!links.length) {
          return null
        }

        return (
          <TableEndpointTreeElement
            key={createUniqueKey(title, summary)}
            endpoint={endpoint}
            summaryViewMode={summaryViewMode}
            sortType={sortType}
            sortInvertDirection={sortInvertDirection}
          />
        )
      })}
    </>
  )
}
