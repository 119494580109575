import { makeAutoObservable, runInAction } from 'mobx'
import { FreeTrialOnboardingRequestDto } from 'api/models'
import { Api } from 'api/Api'
import {
  BuildSystemOption,
  FrameworkOption,
  LanguageOption,
  OsOption,
  SelectPlatformFormFields,
} from 'components/guide/models'
import { GuideStore } from 'components/guide/GuideStore'

interface InitialProps {
  api: Api
  teamUrlName: string
}

export class SubmitRequestStore {
  private readonly api: Api
  private readonly teamUrlName: string
  public isReady = false
  onboardingRequests: FreeTrialOnboardingRequestDto[] = []

  constructor(props: InitialProps) {
    this.api = props.api
    this.teamUrlName = props.teamUrlName
    makeAutoObservable(this, {
      fetchData: false,
      submitOnboardingRequest: false,
    })
  }

  private setIsReady(isReady: boolean) {
    this.isReady = isReady
  }

  fetchData(): Promise<FreeTrialOnboardingRequestDto[]> {
    return this.api.getFreTrialOnboardingRequests(this.teamUrlName).then((data) => {
      runInAction(() => {
        this.onboardingRequests = data.sort((a, b) => Number(b.dateCreated) - Number(a.dateCreated))
        this.setIsReady(true)
      })
      return data
    })
  }

  get selectPlatformValuesFromRequest(): SelectPlatformFormFields {
    const request = this.onboardingRequests[0]
    return {
      os: request ? GuideStore.getLocalValueByType(OsOption, request.os) : '',
      devPlatform: request?.devPlatform
        ? GuideStore.getLocalValueByType(FrameworkOption, request.devPlatform)
        : '',
      languages: request?.languages
        ? request?.languages.map((lang) => GuideStore.getLocalValueByType(LanguageOption, lang))
        : [],
      buildSystem: request?.buildSystem
        ? GuideStore.getLocalValueByType(BuildSystemOption, request.buildSystem)
        : '',
    }
  }

  submitOnboardingRequest(
    requestData: SelectPlatformFormFields,
  ): Promise<FreeTrialOnboardingRequestDto> {
    return this.api.submitFreeTrialOnboardingRequest(this.teamUrlName, requestData).then((data) => {
      runInAction(() => {
        this.onboardingRequests.push(data)
      })
      return data
    })
  }

  get hasRequests() {
    return this.onboardingRequests.length > 0
  }

  public static isRequestExist(
    requests: FreeTrialOnboardingRequestDto[],
    formData: SelectPlatformFormFields,
  ) {
    return (
      requests.findIndex((item) => {
        return !(
          item.os.toLowerCase() !== formData.os.toLowerCase() ||
          item.devPlatform?.toLowerCase() !== formData.devPlatform.toLowerCase()
        )
      }) !== -1
    )
  }
}
