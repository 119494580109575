import { makeAutoObservable, observable, runInAction } from 'mobx'

import {
  BuildDto,
  BuildDtoUploadStateValue,
  BuildType,
  DashboardRunV2Dto,
  FlowAutomationConfigDtoDeviceDto,
  FlowDto,
  FlowReq,
  RaUserNotificationConfigDto,
  RaUserNotificationConfigDtoTopicDtoValue,
  RegressionAnalysisDashboardDataV2Dto,
  UpdateFlowAutomationConfigDto,
  UploadBuildDto,
} from 'api/models'
import { Api } from 'api/Api'

import { generatePath } from 'react-router-dom'
import { PATH_CHART_ROUTER } from 'pages/PsChartRouter'
import { Run } from 'components/regression-analysis-mvp/Run'
import { isFinished } from 'components/flows/ra/runs/runUtils'

export class RaMvpStore {
  private readonly api: Api
  readonly projectId: number
  readonly projectUrlName: string
  readonly flowId: number
  readonly flowReq: FlowReq

  state = DataLoadingState.EMPTY

  devices: FlowAutomationConfigDtoDeviceDto[] = []
  builds: BuildDto[] = []
  data: RegressionAnalysisDashboardDataV2Dto | null = null
  notificationTopics: RaUserNotificationConfigDtoTopicDtoValue[] = []

  constructor(api: Api, projectId: number, projectUrlName: string, flowId: number) {
    makeAutoObservable<RaMvpStore, 'api'>(this, {
      api: false,
      projectId: false,
      projectUrlName: false,
      flowId: false,
      data: observable.ref,
    })
    this.api = api
    this.projectId = projectId
    this.projectUrlName = projectUrlName
    this.flowId = flowId
    this.flowReq = {
      projectUrlName: this.projectUrlName,
      flowProjectLocalId: String(this.flowId),
    }
  }

  loadInitial(): Promise<void> {
    this.state = DataLoadingState.LOADING
    return Promise.all([
      this.fetchDevices(),
      this.fetchBuilds(),
      this.fetchRuns(),
      this.fetchNotificationTopics(),
    ])
      .then(() => undefined)
      .finally(() => runInAction(() => (this.state = DataLoadingState.LOADED)))
  }

  fetchDevices(): Promise<FlowAutomationConfigDtoDeviceDto[]> {
    return this.api.getDevices(this.projectUrlName).then((devices) => {
      runInAction(() => {
        this.devices.length = 0
        this.devices.push(...devices.devices)
      })
      return this.devices
    })
  }

  fetchBuilds(): Promise<BuildDto[]> {
    return this.api.getProjectBuilds(this.projectUrlName).then((builds) => {
      runInAction(() => {
        this.builds.length = 0
        this.builds.push(...builds.sort((a, b) => b.id - a.id))
      })
      return this.builds
    })
  }

  fetchRuns(): Promise<void> {
    return this.api.getRegressionAnalysisData(this.projectUrlName, this.flowId).then((data) => {
      runInAction(() => {
        this.data = data
        const hasProcessing = data.runs.filter((run) => !isFinished(run)).length > 0
        if (hasProcessing) {
          setTimeout(() => this.fetchRuns(), 10000)
        }
      })
    })
  }

  updateRunConfig(runConfig: UpdateFlowAutomationConfigDto): Promise<FlowDto> {
    return this.api.putRunConfig(this.flowReq, runConfig)
  }

  get runsDto(): DashboardRunV2Dto[] {
    return (this.data?.runs ?? []).sort(
      (a, b) => new Date(b.dateCreated).getTime() - new Date(a.dateCreated).getTime(),
    )
  }

  get runs(): Run[] {
    return this.runsDto.map((run) => new Run(run))
  }

  uploadBuild(
    nonInstrumentedFile: File,
    nonInstrumentedUploadBuildDto: UploadBuildDto,
    instrumentedFile: File | null,
    instrumentedUploadBuildDto: UploadBuildDto | null,
  ): Promise<void> {
    return this.api.postBuildUploads(this.projectUrlName).then((buildUploadContextDto) => {
      nonInstrumentedUploadBuildDto.contextId = buildUploadContextDto.id
      if (instrumentedUploadBuildDto) {
        instrumentedUploadBuildDto.contextId = buildUploadContextDto.id
      }
      const nonInstrumentedPromise: Promise<void> = this.api
        .postProjectBuild(this.projectUrlName, nonInstrumentedUploadBuildDto)
        .then((resp) => {
          return this.api.uploadTraceToObjectStorage(nonInstrumentedFile, resp.uploadSpec)
        })
        .then(() => {})
      const instrumentedPromise: Promise<void> =
        instrumentedFile && instrumentedUploadBuildDto
          ? this.api
              .postProjectBuild(this.projectUrlName, instrumentedUploadBuildDto)
              .then((resp) => {
                return this.api.uploadTraceToObjectStorage(instrumentedFile, resp.uploadSpec)
              })
              .then(() => {})
          : Promise.resolve()
      return Promise.all([nonInstrumentedPromise, instrumentedPromise]).then(() => {})
    })
  }

  clean() {
    this.data = null
  }

  get buildsVm(): BuildViewModel[] {
    const buildVMs: BuildViewModel[] = []
    buildVMs.push(...RaMvpStore.getBuildsWithoutContextId(this.builds))
    buildVMs.push(...RaMvpStore.getBuildsWithContextId(this.builds))
    return buildVMs.sort(
      (a, b) => new Date(b.uploadDate).getTime() - new Date(a.uploadDate).getTime(),
    )
  }

  private static getBuildsWithoutContextId(builds: BuildDto[]): BuildViewModel[] {
    return builds
      .filter((buildDto) => !buildDto.contextId)
      .map((buildDto) => {
        return {
          buildId: buildDto.id,
          contextId: undefined,
          nonInstrumentedFileName:
            buildDto.buildType === BuildType.APK ? buildDto.buildFileName : '',
          instrumentedFileName:
            buildDto.buildType === BuildType.INSTRUMENTED_APK ? buildDto.buildFileName : '',
          name: buildDto.name,
          description: buildDto.description,
          gitCommit: buildDto.sourceControlId,
          gitTime: buildDto.sourceControlIsoTimestamp,
          uploadDate: buildDto.dateCreated,
          uploadState: buildDto.uploadState,
        } as BuildViewModel
      })
  }

  private static getBuildsWithContextId(builds: BuildDto[]) {
    const buildGroupsByContextId: Record<number, BuildGroup> = {}
    builds.forEach((buildDto) => {
      if (buildDto.contextId) {
        const key = buildDto.contextId
        let buildGroup = buildGroupsByContextId[key]
        if (!buildGroupsByContextId[key]) {
          buildGroup = {} as BuildGroup
          buildGroupsByContextId[key] = buildGroup
        }
        if (buildDto.buildType === BuildType.APK) {
          buildGroup.nonInstrumented = buildDto
        }
        if (buildDto.buildType === BuildType.INSTRUMENTED_APK) {
          buildGroup.instrumented = buildDto
        }
      }
    })
    const buildVMs: BuildViewModel[] = []
    for (const contextId in buildGroupsByContextId) {
      const { nonInstrumented, instrumented } = buildGroupsByContextId[contextId]
      buildVMs.push({
        buildId: undefined,
        contextId: nonInstrumented?.contextId,
        nonInstrumentedFileName: nonInstrumented?.buildFileName,
        instrumentedFileName: instrumented?.buildFileName,
        name: nonInstrumented?.name,
        description: nonInstrumented?.description,
        gitCommit: nonInstrumented?.sourceControlId,
        gitTime: nonInstrumented?.sourceControlIsoTimestamp,
        uploadDate: nonInstrumented?.dateCreated ?? '',
        uploadState: nonInstrumented?.uploadState,
      } as BuildViewModel)
    }
    return buildVMs
  }

  generateTracePath(traceId: number): string {
    return generatePath(PATH_CHART_ROUTER, {
      projectUrlName: this.projectUrlName,
      flowProjectLocalId: String(this.flowId),
      traceProjectLocalId: String(traceId),
    })
  }

  fetchNotificationTopics(): Promise<void> {
    return this.api.getRegressionNotifications(this.projectUrlName).then((config) => {
      runInAction(() => {
        this.notificationTopics = config.topics
      })
    })
  }

  get isSubscribedToNotifications(): boolean {
    return this.notificationTopics.length > 0
  }

  changeNotificationTopics(topics: RaUserNotificationConfigDtoTopicDtoValue[]): Promise<void> {
    const config: RaUserNotificationConfigDto = { topics: topics }
    return this.api.postRegressionNotifications(this.projectUrlName, config).then((respConfig) => {
      runInAction(() => {
        this.notificationTopics = respConfig.topics
      })
    })
  }

  get isLoaded(): boolean {
    return this.state === DataLoadingState.LOADED
  }
}

interface BuildGroup {
  instrumented?: BuildDto
  nonInstrumented?: BuildDto
}

export interface BuildViewModel {
  buildId?: number
  contextId?: number
  nonInstrumentedFileName?: string
  instrumentedFileName?: string
  name?: string
  description?: string
  gitCommit?: string
  gitTime?: string
  uploadDate: string
  uploadState: BuildDtoUploadStateValue
}

enum DataLoadingState {
  EMPTY,
  LOADING,
  LOADED,
}
