import { MdStyles } from 'components/guide/markdown/MarkdownConfig'

export const MD_STYLES_LITE: MdStyles = {
  header: {
    h1: {
      marginClassName: 'mt-8 mb-6',
      otherClassName: 'text-header-normal font-medium font-semibold text-black',
    },
    h2: {
      marginClassName: 'mt-6 mb-4',
      otherClassName: 'text-header-small font-medium font-semibold text-black',
    },
    h3: {
      marginClassName: 'mt-4 mb-4',
      otherClassName: 'text-header-tiny font-medium font-semibold text-black leading-[26px]',
    },
    h4: {
      marginClassName: 'my-4',
      otherClassName: 'text-big text-black',
    },
    h5: {
      marginClassName: 'my-4',
      otherClassName: 'text-normal text-black',
    },
    h6: {
      marginClassName: 'my-4',
      otherClassName: 'text-normal',
    },
  },
  thematicBreak: {
    className: 'my-8',
  },
  para: {
    className: 'mb-4 text-big leading-[150%] text-black my-[16px]',
  },
  anchor: {
    className: 'text-electro',
  },
  code: {
    wrapperClassName:
      'mb-4 p-4 bg-dark-dark1 rounded text-normal text-white w-full max-w-[900px] shadow-base relative',
    titleClassName: 'text-gray-normal mb-4',
    iconClassName: 'text-gray-normal hover:text-white text-icon-small hover:cursor-pointer',
    tabListClassName: '',
    tabClassName: 'text-black px-4 py-1 bg-gray-normal relative mb-[-5px] rounded-t-lg',
    selectedTabClassName: 'text-white px-4 py-1 bg-dark-dark1 rounded-t-lg relative mb-[-5px]',
  },
  inlineCode: {
    className: 'bg-dark-dark2 rounded text-white px-1',
  },
  list: {
    className: 'mb-4 pl-2',
  },
  listOrdered: {
    className: 'mb-4 pl-2',
  },
  listItem: {
    className: 'mb-2',
  },
  image: {
    className: 'my-4 m-auto',
    imageWrapper: {
      className: 'w-full max-w-[684px]',
    },
  },
} as const

export const LITE_EVENTS = {
  /**
   * Sign up via email: request welcome email.
   * Properties: { email }
   * Call posthog.identify(email, { email, isLite }) when we send this event.
   */
  REQUEST_LITE: 'lite_request_lite',

  /**
   * Sign up via email: Create password
   */
  CREATE_PASSWORD: 'lite_create_password',

  /**
   * Log in via email
   * Properties: { provider }
   * Call posthog.identify(email, { email, isLite }) when we send this event.
   */
  LOG_IN_EMAIL: 'lite_log_in_email',

  /**
   * Log in via SSO
   * Properties: { provider }
   * Call posthog.identify(email, { email, isLite }) when we send this event.
   */
  LOG_IN_SSO: 'lite_log_in_sso',

  /**
   * Create new account via SSO
   * Properties: { provider }
   * Call posthog.identify(email, { email, isLite }) when we send this event.
   */
  SIGN_UP_SSO: 'lite_sign_up_sso',

  /**
   * Upload trace
   */
  UPLOAD_TRACE: 'lite_upload_trace',

  /**
   * Upload video
   */
  UPLOAD_VIDEO: 'lite_upload_video',
} as const
