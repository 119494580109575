import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'
import NProgress from 'nprogress'
import { useToaster } from 'hooks/useToaster'
import { FEATURE_FLAGS, useFeatureFlag } from 'utils/feature-flags'
import { useFirebaseAuth, useOkta } from 'contexts/di-context'
import { OktaModal } from 'components/OktaModal'
import { IssuerDto } from 'api/__generated__/api-types'

export type OktaButtonSuccessHandler = (fbIdToken: string) => void

interface OktaButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  onSuccess: OktaButtonSuccessHandler
}

export const OktaButton = (props: OktaButtonProps) => {
  const { onSuccess, children, className, ...otherProps } = props
  const [isOpen, setIsOpen] = useState(false)
  const { t } = useTranslation()
  const toaster = useToaster()
  const firebaseAuth = useFirebaseAuth()
  const oktaAuth = useOkta()

  const showOktaButton = useFeatureFlag(FEATURE_FLAGS.OKTA_AUTH_BUTTON)

  const signInViaOkta = useCallback(
    async (issuer: IssuerDto) => {
      NProgress.start()
      try {
        oktaAuth.setIssuer(issuer)
        const oktaToken = await oktaAuth.signIn()
        if (oktaToken) {
          const fbIdToken = await firebaseAuth.signInWithOktaToken(oktaToken)
          onSuccess(fbIdToken)
        } else {
          toaster.error('auth.common.firebaseOktaError')
        }
      } catch (error) {
        oktaAuth.signOut()
      }
      NProgress.done()
    },
    [onSuccess, toaster, firebaseAuth, oktaAuth],
  )

  const handleButtonClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault()
    setIsOpen(true)
  }

  if (!showOktaButton) {
    return null
  }

  return (
    <>
      <button
        className={classNames(
          'h-[32px] flex justify-center items-center w-full mt-[14px] border-[1px] border-gray-strokeLight text-small font-medium transition-colors',
          !otherProps.disabled && 'hover:border-white',
          className,
        )}
        onClick={handleButtonClick}
        {...otherProps}
      >
        {children || t('auth.common.signInWithOkta')}
      </button>
      <OktaModal signInViaOkta={signInViaOkta} isOpen={isOpen} onClose={() => setIsOpen(false)} />
    </>
  )
}
