import React from 'react'

interface SliceBarProps {
  color: string
  title: string
  onClick?: () => void
}

export const CardTitleBar = (sliceProps: SliceBarProps) => (
  <>
    <div className="w-[48px] h-[6px] mb-[16px]" style={{ backgroundColor: sliceProps.color }} />
    <div
      className="mb-[13px] text-small leading-[140%] tracking-wide font-semibold text-white cursor-pointer break-all"
      onClick={sliceProps.onClick}
    >
      {sliceProps.title}
    </div>
  </>
)
