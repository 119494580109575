import { useState, useCallback } from 'react'
import { Modal } from 'components/Modal'
import { Input } from 'components/Input'
import { useTranslation } from 'react-i18next'
import * as yup from 'yup'
import { Form, useForm, ValidationSchema } from 'components/Form/Form'
import { useApi } from 'contexts/di-context'
import { useToaster } from 'hooks/useToaster'
import NProgress from 'nprogress'
import { IssuerDto } from 'api/__generated__/api-types'

interface OktaModalProps {
  isOpen: boolean
  onClose: () => void
  signInViaOkta: (issuer: IssuerDto) => Promise<void>
}
export const OktaModal = (props: OktaModalProps) => {
  const [pending, setPending] = useState(false)
  const { isOpen, onClose, signInViaOkta } = props
  const { t } = useTranslation()
  const formContext = useForm<CheckEmailFields>({
    validationSchema: checkEmailSchema,
    formProps: {
      defaultValues: {
        email: '',
      },
    },
  })
  const api = useApi()
  const toaster = useToaster()

  const handleSubmit = useCallback(
    async ({ email }: CheckEmailFields) => {
      setPending(true)
      NProgress.start()
      try {
        const issuer = await api.getOktaIssuer(email)
        onClose()
        formContext.reset()
        signInViaOkta(issuer)
      } catch (error) {
        toaster.error(error)
      } finally {
        NProgress.done()
        setPending(false)
      }
    },
    [api, toaster, signInViaOkta, onClose, formContext],
  )

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={t('components.oktaModal.title')}
      actionButton={{
        onClick: formContext.handleSubmit(handleSubmit),
        disabled: !formContext.formState.isValid || pending,
        children: t('components.oktaModal.button'),
      }}
    >
      <div>
        <Form formContext={formContext} onSubmit={handleSubmit}>
          <Input
            disabled={pending}
            placeholder={t('components.oktaModal.email')}
            {...formContext.register('email')}
          />
        </Form>
      </div>
    </Modal>
  )
}

interface CheckEmailFields {
  email: string
}
export const checkEmailSchema = yup.object<ValidationSchema<CheckEmailFields>>({
  email: yup.string().email().required(),
})
