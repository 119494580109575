import Konva from 'konva'
import { createLine, createText } from 'utils/konva'
import { LocalTimelineSettings } from 'components/ps-chart/local-timeline/LocalTimelineSettings'

export class Scale {
  private readonly settings: LocalTimelineSettings
  readonly group = new Konva.Group()
  private readonly line: Konva.Line
  private readonly timeLabel: Konva.Text

  constructor(settings: LocalTimelineSettings, x: number, text: string) {
    this.settings = settings
    this.line = this.drawLine([0, 0, 0, this.settings.scaleHeight])
    this.timeLabel = this.drawText(
      this.settings.common.scaleTextLeftPadding,
      this.settings.common.scaleTextTopPadding,
      text,
    )
    this.group.x(x)
    this.group.add(this.line, this.timeLabel)
  }

  public update(x: number, text: string) {
    this.timeLabel.text(text)
    this.group.x(x)
    return this
  }

  private drawText(x: number, y: number, text: string): Konva.Text {
    const settings = this.settings.common
    return createText(x, y, text, settings.palette.text, settings.fontSize, settings.fontFamily)
  }

  private drawLine(points: Array<number>): Konva.Line {
    const settings = this.settings.common
    return createLine(points, settings.palette.timeScaleLine, settings.scaleWidth)
  }
}
