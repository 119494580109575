import React from 'react'
import { GridHeader } from 'components/flows/ra/runs/details/utils/GridHeader'
import { useTranslation } from 'react-i18next'
import { Title } from 'components/flows/ra/runs/details/utils/Title'
import { Run } from 'components/regression-analysis-mvp/Run'
import { DataCol } from './utils/DataCol'

interface StatisticsProps {
  run: Run
}

export const Statistics = ({ run }: StatisticsProps) => {
  const { t } = useTranslation()
  return (
    <>
      {run.userFlowData.map((userFlowData, index) => {
        const current = userFlowData.current?.statistics
        if (!current) {
          return null
        }
        const prev = userFlowData.previous?.statistics
        return (
          <div key={index}>
            <Title text={t('ra.flow.stats.title')} name={userFlowData.flowId} />
            <GridHeader />
            <div className="grid grid-cols-3">
              <DataCol isDescription>{t('ra.flow.stats.processedSize')}</DataCol>
              <DataCol>{current.processedSize}</DataCol>
              <DataCol>{prev?.processedSize}</DataCol>
              <DataCol isDescription>{t('ra.flow.stats.cleanedSize')}</DataCol>
              <DataCol>{current.cleanedSize}</DataCol>
              <DataCol>{prev?.cleanedSize}</DataCol>
              <DataCol isDescription>{t('ra.flow.stats.mean')}</DataCol>
              <DataCol>{current.meanValue.toFixed(1)}</DataCol>
              <DataCol>{prev?.meanValue.toFixed(1)}</DataCol>
              <DataCol isDescription>{t('ra.flow.stats.median')}</DataCol>
              <DataCol>{current.median}</DataCol>
              <DataCol>{prev?.median}</DataCol>
              <DataCol isDescription>{t('ra.flow.stats.lowWhisker')}</DataCol>
              <DataCol>{current.lowWhisker}</DataCol>
              <DataCol>{prev?.lowWhisker}</DataCol>
              <DataCol isDescription>{t('ra.flow.stats.upWhisker')}</DataCol>
              <DataCol>{current.upWhisker}</DataCol>
              <DataCol>{prev?.upWhisker}</DataCol>
              <DataCol isDescription>{t('ra.flow.stats.lowConfInterval')}</DataCol>
              <DataCol>{current.lowConfidenceInterval}</DataCol>
              <DataCol>{prev?.lowConfidenceInterval}</DataCol>
              <DataCol isDescription>{t('ra.flow.stats.upConfInterval')}</DataCol>
              <DataCol>{current.upConfidenceInterval}</DataCol>
              <DataCol>{prev?.upConfidenceInterval}</DataCol>
            </div>
          </div>
        )
      })}
    </>
  )
}
