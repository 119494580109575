import { useNavigationRules } from 'hooks/useNavigationRules'

export const PATH_CHART_ROUTER =
  '/projects/:projectUrlName/flows/:flowProjectLocalId/traces/:traceProjectLocalId'

export const PATH_CHART_ROUTER_TRACEVIEWER = `${PATH_CHART_ROUTER}/traceviewer`

export const PsChartRouter = () => {
  useNavigationRules()
  return null
}
