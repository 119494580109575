import { useCallback, useState } from 'react'
import { Button, ButtonVariantEnum } from 'components/Button'
import { colors } from 'utils/styles/colors'
import { useTranslation } from 'react-i18next'
import { useToaster } from 'hooks/useToaster'
import NProgress from 'nprogress'
import { SelectPlatformFormFields } from 'components/guide/models'
import { SubmitRequestStore } from 'components/guide/SubmitRequestStore'
import { observer } from 'mobx-react-lite'

interface Props {
  submitRequestStore: SubmitRequestStore
  formData: SelectPlatformFormFields
}
export const InDevelopmentState = observer(function InDevelopmentState(props: Props) {
  const [pending, setPending] = useState(false)
  const { t } = useTranslation()
  const toaster = useToaster()
  const { formData, submitRequestStore } = props
  const submitted = SubmitRequestStore.isRequestExist(
    submitRequestStore.onboardingRequests,
    formData,
  )
  const handleSubmit = useCallback(() => {
    setPending(true)
    NProgress.start()
    submitRequestStore
      .submitOnboardingRequest(formData)
      .catch((error) => {
        toaster.error(error)
      })
      .finally(() => {
        NProgress.done()
        setPending(false)
      })
  }, [toaster, formData, setPending, submitRequestStore])

  return (
    <div className="py-[24px] px-[48px] bg-dark3 flex flex-col items-center">
      <h3 className="text-[14px] text-normal text-center">
        {t(
          submitted
            ? 'guidePage.platformSelect.inDevelopmentState.submittedTitle'
            : 'guidePage.platformSelect.inDevelopmentState.title',
          { title: formData.os, platform: formData.devPlatform },
        )}
      </h3>
      <p className="text-[14px] text-normal text-gray-normal text-center">
        {t(
          submitted
            ? 'guidePage.platformSelect.inDevelopmentState.submittedDescription'
            : 'guidePage.platformSelect.inDevelopmentState.description',
          { title: formData.os },
        )}
      </p>
      {!submitted && (
        <Button
          disabled={pending}
          variant={ButtonVariantEnum.Outlined}
          borderColor={colors.state.good}
          className="mt-[32px]"
          onClick={handleSubmit}
        >
          {t('guidePage.platformSelect.inDevelopmentState.button')}
        </Button>
      )}
    </div>
  )
})
