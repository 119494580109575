import { ChartPageParams, NamedLinkDto, AnnotationDto } from 'api/models'
import { useApi, useAuthStore } from 'contexts/di-context'
import { useToaster } from 'hooks/useToaster'
import { ChartDataStore } from 'components/ps-chart/stores/ChartDataStore'
import { useEffect, useMemo, useState } from 'react'
import { PsChartSettings } from 'components/ps-chart/models/settings'
import { PsChartStore } from 'components/ps-chart/PsChartStore'
import { autorun, when } from 'mobx'
import { useLocation, useNavigate } from 'react-router-dom'
import { FEATURE_FLAGS, useFeatureFlag } from 'utils/feature-flags'
import { connectionMetrics } from 'utils/ConnectionsMetrics'

interface UseChartStoresProps {
  isReadOnlyProject: boolean
  chartPageParams: ChartPageParams
  isRightHidden?: boolean
}

interface UseChartStoresReturn {
  chartDataStore: ChartDataStore
  psChartStore: PsChartStore
  error: Error | null
}

export const useChartStores = (props: UseChartStoresProps): UseChartStoresReturn => {
  const { isReadOnlyProject, chartPageParams, isRightHidden } = props
  const api = useApi()
  const { isSignedIn } = useAuthStore()

  const isBeConnectionsEnabled = useFeatureFlag(FEATURE_FLAGS.BE_CONNECTIONS)
  const isCycleVariantsFiltrationEnabled = useFeatureFlag(FEATURE_FLAGS.CYCLE_VARIANTS_FILTRATION)
  connectionMetrics.setIsBeConnectionsEnabled(isBeConnectionsEnabled)
  const isAddSliceConnectionsEnabled = useFeatureFlag(FEATURE_FLAGS.ADD_SLICE_CONNECTIONS)
  const shouldSortThreadsByID = useFeatureFlag(FEATURE_FLAGS.SORT_TRACE_THREADS_BY_ID)

  if (isReadOnlyProject) {
    api.overrideMethod('postFlag', (_, flag) => Promise.resolve(flag))
    api.overrideMethod('deleteFlag', () => Promise.resolve())
    api.overrideMethod('putFlag', (_, flag) => Promise.resolve(flag))
    api.overrideMethod('postAnnotation', (_, annotation) =>
      Promise.resolve(annotation as AnnotationDto),
    )
    api.overrideMethod('deleteAnnotation', () => Promise.resolve())
    api.overrideMethod('putAnnotation', (_, annotation) =>
      Promise.resolve(annotation as AnnotationDto),
    )
    api.overrideMethod('postNamedLink', (_, data) => Promise.resolve(data as NamedLinkDto))
    api.overrideMethod('deleteNamedLink', () => Promise.resolve())
  } else {
    api.resetOverrideMethod('postFlag')
    api.resetOverrideMethod('deleteFlag')
    api.resetOverrideMethod('putFlag')
    api.resetOverrideMethod('postAnnotation')
    api.resetOverrideMethod('deleteAnnotation')
    api.resetOverrideMethod('putAnnotation')
    api.resetOverrideMethod('postNamedLink')
    api.resetOverrideMethod('deleteNamedLink')
  }

  const toaster = useToaster()

  const chartDataStore: ChartDataStore = useMemo(() => {
    const settings = new PsChartSettings()
    if (shouldSortThreadsByID) {
      settings.toggleSortThreadsByID()
    }
    return new ChartDataStore(api, chartPageParams, settings)
  }, [api, chartPageParams, shouldSortThreadsByID])
  const location = useLocation()

  const psChartStore: PsChartStore = useMemo(() => {
    return new PsChartStore(
      chartDataStore,
      chartDataStore.settings,
      api,
      chartPageParams,
      toaster,
      chartDataStore.traceDataStore,
      chartDataStore.hStateStore,
      chartDataStore.videoDataStore,
      chartDataStore.annotationsDataStore,
      chartDataStore.flagsDataStore,
      false,
      false,
    )
  }, [api, chartDataStore, chartPageParams, toaster])

  useEffect(() => {
    if (isAddSliceConnectionsEnabled != null) {
      psChartStore.setIsAddSliceConnectionsEnabled(isAddSliceConnectionsEnabled)
    }
  }, [psChartStore, isAddSliceConnectionsEnabled])

  useEffect(
    () =>
      when(
        () => psChartStore.isLoaded,
        () => {
          const navState = location.state
          if (navState == null || navState.sliceId == null || navState.title == null) {
            return null
          }
          const sliceId = Number(navState.sliceId)
          const slice = psChartStore.sliceById.get(sliceId)
          if (slice == null) {
            console.warn(`The slice with ID ${sliceId} has not been found!`)
            return null
          }
          if (!navState.title.includes(slice.title)) {
            console.warn(
              `The slice with ID ${sliceId} has a wrong title. Expected to include: "${navState.title}" / Actual: "${slice.title}"`,
            )
            return null
          }
          psChartStore.setSelectedSlice(slice)
        },
      ),
    [psChartStore, location],
  )

  useEffect(() => {
    psChartStore.uiState.setRightHidden(!!isRightHidden)
  }, [psChartStore, isRightHidden])

  // Select the clide if url has #sliceId hash
  useEffect(() => {
    if (psChartStore.isLoaded) {
      const { hash } = location
      const currentSliceId = psChartStore.traceAnalyzeStore.selectedSlice?.id
      if (hash) {
        const sliceId = Number(hash.slice(1))
        if (!isNaN(sliceId)) {
          const slice = psChartStore.sliceById.get(sliceId)
          if (slice && psChartStore.traceAnalyzeStore.selectedSlice !== slice) {
            psChartStore.setSelectedSlice(slice)
          }
        }
      } else if (currentSliceId) {
        psChartStore.setSelectedSlice(null)
      }
    }
  }, [psChartStore, location, psChartStore.isLoaded])

  const navigate = useNavigate()

  // When you switch slice, it will add #sliceId to the url
  useEffect(() => {
    if (psChartStore.isLoaded) {
      const sliceId = psChartStore.traceAnalyzeStore.selectedSlice?.id
      const newHash = `#${sliceId}`
      if (sliceId && location.hash !== newHash) {
        navigate(newHash, { preventScrollReset: true })
      } else if (!sliceId && location.hash) {
        navigate(location.pathname, { preventScrollReset: true })
      }
    }
  }, [navigate, location, psChartStore.isLoaded, psChartStore.traceAnalyzeStore.selectedSlice])

  useEffect(() => {
    const disposeAutorun = autorun(
      () => {
        const url = psChartStore.videoPlayerStore.srcBlobUrl
        if (!url) {
          psChartStore.videoTimelineStore.reset()
          return
        }
        const { videoLeftLimitSec } = psChartStore.videoPlayerStore
        const { videoRightLimitSec } = psChartStore.videoPlayerStore
        const pxEnd = psChartStore.videoPlayerStore.videoRightLimitPx
        const pxStart = psChartStore.videoPlayerStore.videoLeftLimitPx
        const widthOrStartPxChanged =
          pxStart !== psChartStore.videoTimelineStore.startXPx ||
          pxEnd - pxStart !== psChartStore.videoTimelineStore.width
        if (
          url &&
          videoLeftLimitSec != null &&
          videoRightLimitSec != null &&
          (!psChartStore.videoTimelineStore.canvas || widthOrStartPxChanged)
        ) {
          psChartStore.videoTimelineStore.extractPreview(
            url,
            videoLeftLimitSec,
            videoRightLimitSec,
            psChartStore.videoPlayerStore.videoLeftLimitPx,
            psChartStore.videoPlayerStore.videoRightLimitPx,
          )
        }
      },
      {
        delay: 500, // to debounce changes when closing/opening details panel
        name: 'autorun @ ChartRootPage -> extractPreview',
      },
    )
    return () => {
      psChartStore.dispose()
      disposeAutorun()
    }
  }, [psChartStore])

  const [error, setError] = useState<Error | null>(null)

  useEffect(() => {
    chartDataStore
      .load()
      .then(() => {
        psChartStore.videoPlayerStore.load()
      })
      .catch((reason) => {
        setError(reason)
        return Promise.reject(reason)
      })
  }, [chartDataStore, chartPageParams, psChartStore, toaster])

  useEffect(() => {
    if (isBeConnectionsEnabled == null || isCycleVariantsFiltrationEnabled == null) {
      return
    }

    return when(
      () =>
        !chartDataStore.isLoading &&
        (!isBeConnectionsEnabled || chartDataStore.beConnectionsData != null),
      () => {
        psChartStore.traceAnalyzeStore.setIsBeConnectionsEnabled(isBeConnectionsEnabled)
        psChartStore.traceAnalyzeStore.setIsCycleFiltrationEnabled(isCycleVariantsFiltrationEnabled)
        psChartStore.loadConnections(
          isBeConnectionsEnabled,
          chartDataStore.namedLinks,
          chartDataStore.choreographerPaths,
          chartDataStore.beConnectionsData,
        )
        connectionMetrics.setConnectionsParsed()
      },
    )
  }, [isBeConnectionsEnabled, isCycleVariantsFiltrationEnabled, chartDataStore, psChartStore])

  useEffect(() => {
    return when(
      () => connectionMetrics.isAllCollected,
      () => {
        if (isSignedIn) {
          connectionMetrics.metricsObjects.forEach((metric) => {
            api
              .postMetrics(metric)
              .then(() => {
                console.debug('#CM Metrics posted successfully [metric]', metric)
              })
              .catch((reason) => {
                console.error('#CM Error posting metrics [reason]', reason)
              })
          })
        }
      },
    )
  }, [api, isSignedIn])

  useEffect(() => {
    return when(
      () => psChartStore.isLoaded && !chartDataStore.isLoading,
      () => {
        connectionMetrics.setTimeToInteractive(performance.now())
      },
    )
  }, [chartDataStore, psChartStore])

  return {
    psChartStore,
    chartDataStore,
    error,
  }
}
