import React, { useCallback, useState } from 'react'
import { useParams } from 'react-router-dom'

import { useIsReadOnlyProject } from 'utils/feature-flags'
import { useTraceQuery } from 'hooks/useApiQuery'
import { Icon } from 'components/Icon'
import { TraceEditModal } from 'components/TraceEditModal'

import { BreadcrumbSection } from './BreadcrumbSection'
import { breadcrumbsSettings } from './breadcrumbsSettings'

export const BreadcrumbsTraceSection = () => {
  const { projectUrlName, traceProjectLocalId } = useParams()
  const [modalVisible, setModalVisible] = useState(false)
  const isReadOnlyProject = useIsReadOnlyProject()
  const { data: traceData } = useTraceQuery({
    projectUrlName,
    traceProjectLocalId,
  })

  const handleOpenModal = useCallback(() => {
    setModalVisible(true)
  }, [])

  const handleCloseModal = useCallback(() => {
    setModalVisible(false)
  }, [])

  return (
    <BreadcrumbSection isLoading={traceData == null}>
      {traceData != null && (
        <div className="group flex-1 flex items-center text-gray-service cursor-default">
          <div
            className={`text-small relative line-clamp-1 mr-[6px] max-w-[${breadcrumbsSettings.cutSectionsMaxSize}]`}
          >
            {traceData.name}
          </div>
          {!isReadOnlyProject && (
            <Icon
              icon="small-edit"
              onClick={handleOpenModal}
              className="text-[16px] opacity-0 group-hover:opacity-100 hover:text-white cursor-pointer"
            />
          )}
          <TraceEditModal
            visible={modalVisible}
            title={traceData.name}
            onClose={handleCloseModal}
            traceProjectLocalId={traceProjectLocalId}
            projectUrlName={projectUrlName}
          />
        </div>
      )}
    </BreadcrumbSection>
  )
}
