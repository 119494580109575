import React from 'react'
import { useTranslation } from 'react-i18next'
import { Input } from 'components/Input'

import { FileField } from 'components/flows/ra/FileField'

interface SettingsAutomationInputProps {
  scriptLabel: string
  fileName: string
  onFileUpload: (file: File) => void
  appId: string
  onChangeAppId: (value: string) => void
  optionClass: string
  onChangeClass: (value: string) => void
}

export const SettingsAutomationInput = ({
  scriptLabel,
  fileName,
  onFileUpload,
  appId,
  onChangeAppId,
  optionClass,
  onChangeClass,
}: SettingsAutomationInputProps) => {
  const { t } = useTranslation()
  const fileNameOrEmpty = fileName.trim() === '' ? t('ra.flow.settings.notSet') : fileName
  return (
    <div className="text-small text-gray-normal space-y-1">
      <p>{scriptLabel}</p>
      <div className="flex items-center">
        <span className="basis-1/4">{t('ra.flow.settings.automationScript.file.label')}</span>
        <div className="basis-1/4">
          <FileField
            onUpload={(file) => onFileUpload(file)}
            acceptType="application/vnd.android.package-archive"
          />
        </div>
        <span className="basis-1/2 text-white pl-4 line-clamp-2 break-words">
          {fileNameOrEmpty}
        </span>
      </div>
      <div className="flex items-center">
        <span className="basis-1/4">{t('ra.flow.settings.automationScript.appId.label')}</span>
        <Input
          containerClassName="basis-3/4 mb-0"
          value={appId}
          onChange={(event) => onChangeAppId(event.target.value)}
        />
      </div>
      <div className="flex items-center">
        <span className="basis-1/4">{t('ra.flow.settings.automationScript.class.label')}</span>
        <Input
          containerClassName="basis-3/4 mb-0"
          value={optionClass}
          onChange={(event) => onChangeClass(event.target.value)}
        />
      </div>
    </div>
  )
}
