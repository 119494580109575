import React, { useCallback, useEffect, useLayoutEffect, useMemo, useState } from 'react'
import NProgress from 'nprogress'
import { generatePath, Link, useNavigate, useParams } from 'react-router-dom'
import classNames from 'classnames'
import { useMutation, useQueryClient } from 'react-query'
import { toast } from 'react-hot-toast'
import { Trans, useTranslation } from 'react-i18next'
import { AxiosError } from 'axios'

import { Layout } from 'components/Layout'
import {
  Button,
  ButtonIcon,
  ButtonTextColorVariantEnum,
  ButtonVariantEnum,
} from 'components/Button'
import { ProjectCard } from 'components/projects/ProjectCard'
import { queryKeys, useProjectsSummaryQuery, useTeamsQuery, useUserQuery } from 'hooks/useApiQuery'
import { Empty } from 'components/Empty'
import { ProjectModal } from 'components/projects/ProjectFormModal'
import { showTeamList, TeamsList } from 'components/teams/TeamsList'
import { openSecondModal } from 'utils/openSecondModal'
import { TeamFormModal } from 'components/teams/TeamFormModal'
import { ActionModal } from 'components/ActionModal'
import { ApiError, OsType, OsTypeValue, TeamRoleApi, TeamsArray, ProjectDtoType } from 'api/models'
import { getOsName } from 'utils/getOsName'
import { getTeamDomain } from 'utils/getTeamDomain'
import { psLocalStorage } from 'utils/localStorage/PsLocalStorage'
import { useApi } from 'contexts/di-context'
import { ListBox } from 'components/dropdowns/ListBox'
import { PATH_ROOT } from 'utils/links'
import { useIoSingleProjectRedirect } from 'components/guide-io/useIoSingleProjectRedirect'
import { BaseHeader } from 'components/header/BaseHeader'
import { PageTitle } from 'components/header/PageTitle'
import { TeamPageBreadcrumbs } from 'components/breadcrumbs/TeamPageBreadcrumbs'

import { PATH_TEAM_CONTRIBUTORS } from './TeamContributorsPage'

export const PATH_TEAM_START = '/teams/'
export const PATH_TEAM = `${PATH_TEAM_START}:teamUrlName`

export const TeamPage = () => {
  const { t } = useTranslation()
  const api = useApi()
  const { teamUrlName } = useParams() as { teamUrlName: string }
  const navigate = useNavigate()
  const queryClient = useQueryClient()

  const [teamSettingsModalOpened, setTeamSettingsModalOpened] = useState(false)
  const handleTeamSettingsClick = () => setTeamSettingsModalOpened(true)
  const handleTeamSettingsModalClose = () => setTeamSettingsModalOpened(false)

  const [teamSettingsOnlyDomainModalOpened, setTeamSettingsOnlyDomainModalOpened] = useState(false)
  const handleTeamSettingsOnlyDomainClick = () => setTeamSettingsOnlyDomainModalOpened(true)
  const handleTeamSettingsOnlyDomainModalClose = () => setTeamSettingsOnlyDomainModalOpened(false)

  const [deleteTeamModalOpened, setDeleteTeamModalOpened] = useState(false)
  const handleDeleteTeamClick = () =>
    openSecondModal(setTeamSettingsModalOpened, setDeleteTeamModalOpened)
  const handleDeleteTeamModalClose = () => setDeleteTeamModalOpened(false)

  const [addProjectModalOpened, setAddProjectModalOpened] = useState(false)
  const handleAddProjectClick = () => setAddProjectModalOpened(true)
  const handleAddProjectModalClose = () => setAddProjectModalOpened(false)

  const { isSuccess: isUserSuccess, data: user } = useUserQuery()
  const { isSuccess: isTeamsSuccess, data: teams } = useTeamsQuery()
  const { isSuccess: isProjectsSummarySuccess, data: projectsSummary } = useProjectsSummaryQuery({
    teamUrlName,
  })
  const { isLoaded: isIoSingleProjectLoaded, navigateComponent: navigateToIoGide } =
    useIoSingleProjectRedirect()

  const isLoaded =
    isProjectsSummarySuccess && isUserSuccess && isTeamsSuccess && isIoSingleProjectLoaded
  const isShowTeamList = showTeamList(isUserSuccess, isTeamsSuccess, user, teams)

  useEffect(() => {
    if (isLoaded) {
      NProgress.done()
    } else {
      NProgress.start()
    }
  }, [isLoaded])

  useLayoutEffect(() => {
    try {
      psLocalStorage.setLastTeam(teamUrlName)
    } catch {}
  }, [teamUrlName])

  const hasProjects = projectsSummary?.projects && projectsSummary?.projects?.length > 0

  const isSuperAdmin = user?.roles.isSuperAdmin
  const teamId = Number(projectsSummary?.team.id)
  const isTeamNone = user?.roles.teams[teamId] === TeamRoleApi.NONE
  const isTeamAdmin = user?.roles.teams[teamId] === TeamRoleApi.ADMIN
  const isSuperAdminOrTeamAdmin = isSuperAdmin || isTeamAdmin

  const [activePlatform, setActivePlatform] = useState<OsTypeValue | null>(null)

  const handlePlatformOptionClick = (platform: OsTypeValue | null) => () =>
    setActivePlatform(platform)
  const createPlatformOption = useCallback(
    (os: OsTypeValue) => ({
      name: getOsName(os),
      isSelect: activePlatform === os,
      onClick: handlePlatformOptionClick(os),
    }),
    [activePlatform],
  )
  const platformOptions = useMemo(() => {
    return [
      {
        name: t('teams.teamPage.allPlatforms'),
        isSelect: activePlatform === null,
        onClick: handlePlatformOptionClick(null),
      },
      createPlatformOption(OsType.IOS),
      createPlatformOption(OsType.ANDROID),
    ]
  }, [activePlatform, createPlatformOption, t])

  useEffect(() => {
    setActivePlatform(null)
  }, [projectsSummary?.projects])

  const projects = useMemo(() => {
    const mobileProjects = projectsSummary?.projects.filter(
      (p) => p.project.type !== ProjectDtoType.CLOUD,
    )
    if (activePlatform) {
      return mobileProjects?.filter((item) => item.project.os === activePlatform)
    }
    return mobileProjects
  }, [projectsSummary?.projects, activePlatform])

  const deleteTeamMutation = useMutation(() => api.deleteTeam({ teamUrlName }), {
    onSuccess: () => {
      NProgress.done()
      queryClient.setQueryData<TeamsArray | undefined>(queryKeys.teams, (oldData) => {
        if (oldData) {
          return oldData?.filter((item) => item?.id !== teamId)
        }
      })
      navigate(PATH_ROOT, { replace: true })
      toast.success(t('teams.deleteTeam.success', { name: projectsSummary?.team.name }))
    },
    onError: (err: AxiosError<ApiError>) => {
      NProgress.done()
      toast.error(err.response?.data.message ?? t('errorMessage'))
    },
  })

  const handleTeamDeleteClick = () => {
    NProgress.start()
    deleteTeamMutation.mutate()
  }

  const TeamSettingsInfoComponent = isSuperAdminOrTeamAdmin ? 'button' : 'span'
  const teamSettingsInfoProps = isSuperAdminOrTeamAdmin
    ? { onClick: handleTeamSettingsOnlyDomainClick }
    : {}

  const projectsWithFavorite = useMemo(() => {
    const dataWithFavorite = projects?.map((item) => ({
      ...item,
      isUserFavorite: Boolean(user?.settings.projects[item.project.id]?.favorite),
    }))
    const withFavoriteArray =
      dataWithFavorite
        ?.filter((item) => item.isUserFavorite)
        .sort((a, b) =>
          a.project.name.localeCompare(b.project.name, undefined, {
            numeric: true,
            sensitivity: 'base',
          }),
        ) || []
    const withoutFavoriteArray = dataWithFavorite?.filter((item) => !item.isUserFavorite) || []
    return [...withFavoriteArray, ...withoutFavoriteArray]
  }, [projects, user?.settings.projects])

  const isFreeTrialTeam = useMemo(() => {
    if (!teams || !projectsSummary) {
      return false
    }
    const current = teams.find((team) => team.id === teamId)
    return current && current.freeTrial !== undefined
  }, [teams, projectsSummary, teamId])

  const header = useMemo(
    () => (
      <BaseHeader
        leftSideContent={<TeamPageBreadcrumbs />}
        centerContent={<PageTitle titleKey="teams" />}
      />
    ),
    [],
  )

  if (navigateToIoGide) {
    return navigateToIoGide
  }

  return (
    <Layout headerComponent={header} pageConfig={{ withoutStyledContent: true }}>
      {isLoaded && (
        <main className="flex flex-1">
          <TeamsList />
          {isProjectsSummarySuccess && (
            <div className="flex flex-col flex-1 px-[24px] pt-[24px]">
              <div className="flex mb-[54px]">
                <div className="flex-1">
                  <h1 className="text-header-big tracking-tight font-medium">
                    {projectsSummary?.team.name}
                  </h1>
                  {projectsSummary?.team &&
                    ('domain' in projectsSummary?.team || 'doWhitelist' in projectsSummary?.team) &&
                    projectsSummary?.team.domain && (
                      <div className="text-small tracking-wide mt-[18px]">
                        {projectsSummary?.team.domain && (
                          <TeamSettingsInfoComponent
                            className="mr-[10px]"
                            {...teamSettingsInfoProps}
                          >
                            {getTeamDomain(projectsSummary?.team.domain)}
                          </TeamSettingsInfoComponent>
                        )}
                        <span className="text-gray-normal">
                          <Trans
                            i18nKey="domainJoin.text"
                            components={{
                              joinStateComponent: (
                                <TeamSettingsInfoComponent
                                  className="text-white"
                                  {...teamSettingsInfoProps}
                                />
                              ),
                            }}
                            values={{
                              joinState: t('domainJoin.doWhitelist', {
                                context: String(projectsSummary?.team.doWhitelist || false),
                              }),
                            }}
                          />
                        </span>
                      </div>
                    )}
                </div>
                {isSuperAdminOrTeamAdmin && (
                  <div className="shrink-0 pt-[4px] flex items-start">
                    <Button
                      onClick={handleTeamSettingsClick}
                      className="mr-[8px]"
                      variant={ButtonVariantEnum.Text}
                      textColorVariant={ButtonTextColorVariantEnum.Muted}
                      withIcon
                    >
                      {t('teams.teamPage.teamSettings')} <ButtonIcon icon="filters" />
                    </Button>
                    <Button
                      as={Link}
                      to={generatePath(PATH_TEAM_CONTRIBUTORS, { teamUrlName })}
                      variant={ButtonVariantEnum.Text}
                      textColorVariant={ButtonTextColorVariantEnum.Muted}
                      withIcon
                    >
                      {t('teams.teamPage.teamContributors')} <ButtonIcon icon="user" />
                    </Button>
                  </div>
                )}
              </div>
              {hasProjects ? (
                <>
                  <div className="flex justify-between items-center mb-[28px]">
                    <h2 className="text-normal tracking-wide font-medium">
                      {t('teams.teamPage.projects')}
                    </h2>
                    {!isFreeTrialTeam && (
                      <div className="relative flex items-center">
                        <ListBox
                          menuSections={[{ options: platformOptions }]}
                          menuClass="top-full right-[8px] py-[10px]"
                          onSelect={(index: number) => platformOptions[index].onClick()}
                          buttonChildren={() => (
                            <>
                              {platformOptions.find((option) => option.isSelect)?.name}
                              <ButtonIcon icon="arrow-drop-d" />
                            </>
                          )}
                          buttonClass={(open) =>
                            classNames(
                              'flex items-center pl-[8px] text-small tracking-wide font-medium transition',
                              open ? 'text-white' : 'text-gray-normal',
                            )
                          }
                          withSelect
                        />
                        {isSuperAdminOrTeamAdmin && (
                          <Button
                            className="ml-[16px]"
                            variant={ButtonVariantEnum.Outlined}
                            onClick={handleAddProjectClick}
                            isSmall
                          >
                            {t('teams.teamPage.addProject')}
                          </Button>
                        )}
                      </div>
                    )}
                  </div>
                  <ul>
                    {projectsWithFavorite?.map((item) => (
                      <li className="mb-[24px]" key={item.project.id}>
                        <ProjectCard
                          {...item}
                          isShowTeamList={isShowTeamList}
                          isSuperAdminOrTeamAdmin={isSuperAdminOrTeamAdmin}
                        />
                      </li>
                    ))}
                  </ul>
                </>
              ) : isSuperAdminOrTeamAdmin ? (
                <Empty
                  title={t('teams.teamPage.createProject.title')}
                  text={t('teams.teamPage.createProject.text')}
                  buttons={[
                    {
                      name: t('teams.teamPage.createProject.button'),
                      variant: ButtonVariantEnum.Outlined,
                      onClick: handleAddProjectClick,
                    },
                  ]}
                />
              ) : isTeamNone ? (
                <Empty
                  title={t('teams.teamPage.userTeamNone.title')}
                  text={
                    <Trans
                      i18nKey="teams.teamPage.userTeamNone.text"
                      components={{
                        emailLink: (
                          <a href="mailto:support@productscience.ai" className="text-electro" />
                        ),
                      }}
                      values={{ email: 'support@productscience.ai' }}
                    />
                  }
                  textClassName="max-w-[450px]"
                />
              ) : (
                <Empty
                  title={t('teams.teamPage.userWithoutProjects.title')}
                  text={t('teams.teamPage.userWithoutProjects.text')}
                />
              )}
            </div>
          )}
        </main>
      )}
      <TeamFormModal
        teamModel={projectsSummary?.team}
        isOpen={teamSettingsModalOpened}
        onClose={handleTeamSettingsModalClose}
        onDeleteTeamClick={handleDeleteTeamClick}
        isEdit
      />
      <TeamFormModal
        teamModel={projectsSummary?.team}
        isOpen={teamSettingsOnlyDomainModalOpened}
        onClose={handleTeamSettingsOnlyDomainModalClose}
        isEdit
        onlyDomainEdit
      />
      <ActionModal
        isOpen={deleteTeamModalOpened}
        title={t('teams.deleteTeam.title')}
        secondaryTitle={projectsSummary?.team.name}
        text={t('teams.deleteTeam.text')}
        onClose={handleDeleteTeamModalClose}
        actionButton={{ onClick: handleTeamDeleteClick, disabled: deleteTeamMutation.isLoading }}
      />
      {user && (
        <ProjectModal
          isOpen={addProjectModalOpened}
          onClose={handleAddProjectModalClose}
          user={user}
          teamDomain={projectsSummary?.team.domain}
        />
      )}
    </Layout>
  )
}
