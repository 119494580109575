import { makeAutoObservable } from 'mobx'
import { ConnectionType } from 'components/ps-chart/models/ConnectionType'
import { Point } from 'components/ps-chart/models/helper-types'
import { addConnectionCurve } from 'components/ps-chart/connections-render/addConnectionCurve'
import { ThreadsTopMap } from 'components/ps-chart/flame-chart/RenderEngine'
import { PsChartStore } from 'components/ps-chart/PsChartStore'
import { Slice } from 'components/ps-chart/models/Slice'
import { Thread } from 'components/ps-chart/models/Thread'
import { getBorderRect } from 'components/ps-chart/utils/getBorderRect'
import { getSliceVisibleRect } from 'components/ps-chart/utils/getSliceVisibleRect'
import {
  isConnectionVisible,
  isVisible,
  VISIBLE_FLAG,
} from 'components/ps-chart/connections-render/canvasPositions'
import {
  CanvasesCurvesMap,
  ConnectionPaths,
  getEmptyCanvasesCurvesMap,
  SliceBordersPaths,
} from 'components/ps-chart/connections-render/ConnectionCurves'
import {
  BorderType,
  LineType,
  NodeRenderData,
} from 'components/ps-chart/connections-render/NodeRenderData'
import { getConnectionError } from 'components/ps-chart/stores/connections-store/getConnectionError'
import { NamedLinkType } from 'api/models'
import { ConnectionDirection } from 'components/ps-chart/models/ConnectionDirection'

export interface ConnectionsRenderCursorParams {
  hoveredSlice: Slice | null
  cursorPoint: Point | null
  isCursorOnPinned: boolean
}

export class ConnectionsRender {
  private readonly psChartStore: PsChartStore

  constructor(psChartStore: PsChartStore) {
    makeAutoObservable(this)
    this.psChartStore = psChartStore
  }

  getCanvasCurves(
    mainThreadsTopMap: ThreadsTopMap,
    pinnedThreadsTopMap: ThreadsTopMap,
    getCursorParams: () => ConnectionsRenderCursorParams,
  ): Partial<CanvasesCurvesMap> {
    const { selectedSlice, chainExists } = this.psChartStore.traceAnalyzeStore
    const curves = getEmptyCanvasesCurvesMap()
    if (selectedSlice) {
      const nodesRenderDataMap = this.getNodesRenderDataMap()
      this.setOrphanLinkModeSliceRenderData(nodesRenderDataMap)
      this.fillSliceBorders(
        nodesRenderDataMap,
        curves.main.sliceBordersPaths,
        curves.pinned.sliceBordersPaths,
      )
      if (chainExists) {
        this.fillConnections(nodesRenderDataMap, curves)
        this.fillConnectingProcessCurves(nodesRenderDataMap, getCursorParams, curves)
      }
    }

    return curves
  }

  private setOrphanLinkModeSliceRenderData = (nodesRenderDataMap: Map<number, NodeRenderData>) => {
    const { linkModeSliceId } = this.psChartStore
    if (linkModeSliceId != null && !nodesRenderDataMap.has(linkModeSliceId)) {
      const slice = this.psChartStore.sliceById.get(linkModeSliceId)!
      const thread = this.psChartStore.traceDataState.threadsById.get(slice.threadId)!
      const threadActiveLevels =
        this.psChartStore.traceAnalyzeStore.activeLevelsFromChainByThreadId.get(thread.id) ?? []
      nodesRenderDataMap.set(
        linkModeSliceId,
        this.getNodeRenderData(slice, thread, threadActiveLevels, BorderType.SECONDARY),
      )
    }
  }

  private fillConnectingProcessCurves(
    nodesRenderDataMap: Map<number, NodeRenderData>,
    getCursorParams: () => ConnectionsRenderCursorParams,
    curves: CanvasesCurvesMap,
  ) {
    if (this.psChartStore.isLinkModeActive && this.psChartStore.linkModeSliceId != null) {
      const fromRD = nodesRenderDataMap.get(this.psChartStore.linkModeSliceId)!
      const { hoveredSlice, cursorPoint, isCursorOnPinned } = getCursorParams()
      if (hoveredSlice != null) {
        const { threadId } = hoveredSlice
        const thread = this.psChartStore.traceAnalyzeStore.threadsById.get(threadId)!
        const threadActiveLevels =
          this.psChartStore.traceAnalyzeStore.activeLevelsFromChainByThreadId.get(threadId) ?? []
        const toRD = this.getNodeRenderData(
          hoveredSlice,
          thread,
          threadActiveLevels,
          BorderType.ACTIVE,
        )
        const fromSlice = this.psChartStore.sliceById.get(this.psChartStore.linkModeSliceId)!
        const toSlice = this.psChartStore.sliceById.get(hoveredSlice.id)!
        const conError = getConnectionError(
          fromSlice,
          toSlice,
          this.psChartStore.sliceById,
          this.psChartStore.traceAnalyzeStore.sliceLinksBySliceId,
          NamedLinkType.SYNC,
        )
        const lineType = conError === null ? LineType.ACTIVE : LineType.DISABLED
        this.fillConnection(fromRD, toRD, ConnectionType.MANUAL, lineType, curves)
        if (conError === null) {
          this.fillSliceBorder(toRD, curves.main.sliceBordersPaths, curves.pinned.sliceBordersPaths)
        }
      } else if (cursorPoint != null) {
        const toRD: NodeRenderData = {
          rect: { ...cursorPoint, w: 0, h: 0, positionFlag: VISIBLE_FLAG },
          isPinned: isCursorOnPinned,
          borderType: BorderType.ACTIVE,
        }
        const isOut = cursorPoint.x > fromRD.rect.x + fromRD.rect.w
        const lineType = isOut ? LineType.DISABLED : LineType.ACTIVE
        this.fillConnection(fromRD, toRD, ConnectionType.MANUAL, lineType, curves)
      }
    }
  }

  private fillSliceBorders(
    nodesRenderDataMap: Map<number, NodeRenderData>,
    mainSlicesPaths: SliceBordersPaths,
    pinnedSlicesPaths: SliceBordersPaths,
  ) {
    nodesRenderDataMap.forEach((nodeRD) => {
      if (isVisible(nodeRD.rect.positionFlag)) {
        this.fillSliceBorder(nodeRD, mainSlicesPaths, pinnedSlicesPaths)
      }
    })
  }

  private fillConnections(
    nodesRenderDataMap: Map<number, NodeRenderData>,
    curves: CanvasesCurvesMap,
  ) {
    const connections = this.psChartStore.traceAnalyzeStore.chainConnections
    for (const { fromSliceId, toSliceId, lineType, connectionType } of connections) {
      const fromRD = nodesRenderDataMap.get(fromSliceId)!
      const toRD = nodesRenderDataMap.get(toSliceId)!
      if (!isConnectionVisible(fromRD.rect.positionFlag, toRD.rect.positionFlag)) {
        continue
      }
      this.fillConnection(fromRD, toRD, connectionType, lineType, curves)
    }
  }

  private fillConnection(
    fromRD: NodeRenderData,
    toRD: NodeRenderData,
    connectionType: ConnectionType,
    lineType: LineType,
    curves: CanvasesCurvesMap,
  ) {
    const connectionCurveOuterWidth =
      this.psChartStore.chartSettings.renderEngine.connectionCurveBackgroundWidth
    const connectionCurveInnerWidth =
      this.psChartStore.chartSettings.renderEngine.connectionCurveWidth
    if (fromRD.isPinned === toRD.isPinned) {
      ConnectionsRender.fillNonCrossConnection(
        fromRD,
        toRD,
        connectionType,
        lineType,
        curves.main.localCurvePaths,
        curves.pinned.localCurvePaths,
        connectionCurveOuterWidth,
        connectionCurveInnerWidth,
        this.psChartStore.traceAnalyzeStore.isForwardConnectionsEnabled
          ? ConnectionDirection.FORWARD
          : ConnectionDirection.BACKWARD,
      )
    } else {
      this.fillCrossBorderConnection(
        fromRD,
        toRD,
        connectionType,
        lineType,
        curves.main.crossCurvePaths,
        curves.pinned.crossCurvePaths,
      )
    }
  }

  private fillCrossBorderConnection(
    fromRD: NodeRenderData,
    toRD: NodeRenderData,
    connectionType: ConnectionType,
    lineType: LineType,
    mainToPinnedCurvePaths: ConnectionPaths,
    pinnedToMainCurvePaths: ConnectionPaths,
  ) {
    /**
     * headerHeight is used in CrossBorder because connection lines rendered infront of headers blocks
     */

    const { headerHeight } = this.psChartStore.chartSettings.renderEngine
    const { pinnedCanvasHeight } = this.psChartStore.vState
    const connectionCurveOuterWidth =
      this.psChartStore.chartSettings.renderEngine.connectionCurveBackgroundWidth
    const connectionCurveInnerWidth =
      this.psChartStore.chartSettings.renderEngine.connectionCurveWidth

    const mainToPinnedCurvePath = mainToPinnedCurvePaths[lineType]
    const pinnedToMainCurvePath = pinnedToMainCurvePaths[lineType]

    if (fromRD.isPinned) {
      // On pinned-canvas part
      const virtualParentRect = {
        ...toRD.rect,
        // 0 is the min Y for the virtual rect from the main canvas,
        //  because otherwise the connection curve on the pinned-canvas will end before the canvas border.
        y: (toRD.rect.y > 0 ? toRD.rect.y : 0) + pinnedCanvasHeight,
      }
      addConnectionCurve(
        fromRD.rect,
        virtualParentRect,
        connectionType,
        pinnedToMainCurvePath,
        connectionCurveOuterWidth,
        connectionCurveInnerWidth,
        this.psChartStore.traceAnalyzeStore.isForwardConnectionsEnabled
          ? ConnectionDirection.FORWARD
          : ConnectionDirection.BACKWARD,
      )

      // On main-canvas part
      const parentYCenter = toRD.rect.y + toRD.rect.h / 2
      // We draw a connection only when we can see the curve end and the slice-box border
      if (parentYCenter > 0) {
        const virtualChildRect = {
          ...fromRD.rect,
          y: fromRD.rect.y - pinnedCanvasHeight,
        }
        if (toRD.rect.y > headerHeight) {
          addConnectionCurve(
            virtualChildRect,
            toRD.rect,
            connectionType,
            mainToPinnedCurvePath,
            connectionCurveOuterWidth,
            connectionCurveInnerWidth,
            this.psChartStore.traceAnalyzeStore.isForwardConnectionsEnabled
              ? ConnectionDirection.FORWARD
              : ConnectionDirection.BACKWARD,
          )
        }
      }
    } else {
      // On main-canvas part
      const childYCenter = fromRD.rect.y + fromRD.rect.h / 2
      // We draw a connection only when we can see the curve end and the slice-box border
      if (childYCenter > 0) {
        const virtualParentRect = {
          ...toRD.rect,
          y: toRD.rect.y - pinnedCanvasHeight,
        }
        if (fromRD.rect.y > headerHeight) {
          addConnectionCurve(
            fromRD.rect,
            virtualParentRect,
            connectionType,
            mainToPinnedCurvePath,
            connectionCurveOuterWidth,
            connectionCurveInnerWidth,
            this.psChartStore.traceAnalyzeStore.isForwardConnectionsEnabled
              ? ConnectionDirection.FORWARD
              : ConnectionDirection.BACKWARD,
          )
        }
      }

      // On fav-canvas part
      const virtualChildRect = {
        ...fromRD.rect,
        // 0 is the min Y for the virtual rect from the main canvas,
        //  because otherwise the connection curve on the fav-canvas will end before the canvas border.
        y: (fromRD.rect.y > 0 ? fromRD.rect.y : 0) + pinnedCanvasHeight,
      }
      addConnectionCurve(
        virtualChildRect,
        toRD.rect,
        connectionType,
        pinnedToMainCurvePath,
        connectionCurveOuterWidth,
        connectionCurveInnerWidth,
        this.psChartStore.traceAnalyzeStore.isForwardConnectionsEnabled
          ? ConnectionDirection.FORWARD
          : ConnectionDirection.BACKWARD,
      )
    }
  }

  private static fillNonCrossConnection(
    fromRD: NodeRenderData,
    toRD: NodeRenderData,
    connectionType: ConnectionType,
    lineType: LineType,
    mainCurvePaths: ConnectionPaths,
    favCurvePaths: ConnectionPaths,
    connectionCurveOuterWidth: number,
    connectionCurveInnerWidth: number,
    connectionDirection: ConnectionDirection = ConnectionDirection.BACKWARD,
  ) {
    const curvePaths = fromRD.isPinned ? favCurvePaths : mainCurvePaths
    addConnectionCurve(
      fromRD.rect,
      toRD.rect,
      connectionType,
      curvePaths[lineType],
      connectionCurveOuterWidth,
      connectionCurveInnerWidth,
      connectionDirection,
    )
  }

  private fillSliceBorder(
    nodeRD: NodeRenderData,
    mainSlicesPaths: SliceBordersPaths,
    pinnedSlicesPaths: SliceBordersPaths,
  ) {
    const canvasPaths = nodeRD.isPinned ? pinnedSlicesPaths : mainSlicesPaths
    const borderPaths = canvasPaths[nodeRD.borderType]
    const { sliceBorderWidths } = this.psChartStore.chartSettings.renderEngine.threads

    borderPaths.forEach((layerPath, layerIndex) => {
      const borderWidth = sliceBorderWidths[layerIndex]
      const layerRect = getBorderRect(nodeRD.rect, borderWidth)
      layerPath.rect(layerRect.x, layerRect.y, layerRect.w, layerRect.h)
    })
  }

  private getNodesRenderDataMap(): Map<number, NodeRenderData> {
    const nodeRenderDataMap = new Map<number, NodeRenderData>()
    const { chainNodes } = this.psChartStore.traceAnalyzeStore
    for (const node of chainNodes) {
      const { slice, thread, threadActiveLevels, borderType } = node
      nodeRenderDataMap.set(
        slice.id,
        this.getNodeRenderData(slice, thread, threadActiveLevels, borderType),
      )
    }
    return nodeRenderDataMap
  }

  private getNodeRenderData(
    slice: Slice,
    thread: Thread,
    threadActiveLevels: number[],
    borderType: BorderType,
  ): NodeRenderData {
    const { minSliceBorderWidth } = this.psChartStore.chartSettings.renderEngine.threads
    return {
      rect: getSliceVisibleRect(
        thread,
        threadActiveLevels,
        slice,
        this.psChartStore,
        minSliceBorderWidth,
      ),
      isPinned: this.psChartStore.traceAnalyzeStore.pinnedIdsSet.has(slice.threadId),
      borderType,
    }
  }
}
