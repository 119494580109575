import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Modal } from 'components/Modal'
import { RaMvpStore } from 'components/regression-analysis-mvp/RaMvpStore'

import { FileField } from 'components/flows/ra/FileField'
import { SettingsInput } from 'components/flows/ra/settings/modal/SettingsInput'
import { BuildType, UploadBuildDto } from 'api/models'
import { extractFileName } from 'utils/stringUtils'
import classNames from 'classnames'
import NProgress from 'nprogress'
import { useToaster } from 'hooks/useToaster'

interface UploadBuildModalProps {
  isOpen: boolean
  onClose: () => void
  raStore: RaMvpStore
}

export const UploadBuildModal = (props: UploadBuildModalProps) => {
  const { t } = useTranslation()
  const toaster = useToaster()

  const { isOpen, onClose, raStore } = props

  // reset the form when the modal is closed
  useEffect(() => {
    if (isOpen) {
      setNonInstrumentedFile(null)
      setInstrumentedFile(null)
      setName('')
      setDescription('')
      setCommit('')
    }
  }, [isOpen])

  const [nonInstrumentedFile, setNonInstrumentedFile] = useState<File | null>(null)
  const [instrumentedFile, setInstrumentedFile] = useState<File | null>(null)
  const [name, setName] = useState('')
  const [description, setDescription] = useState('')
  const [commit, setCommit] = useState('')

  const isValid = useMemo((): boolean => {
    return nonInstrumentedFile != null
  }, [nonInstrumentedFile])

  const uploadBuild = useCallback(() => {
    if (!isValid) {
      return
    }
    NProgress.start()
    const nonInstrumentedDto: UploadBuildDto = {
      buildType: BuildType.APK,
      buildFileName: extractFileName(nonInstrumentedFile!.name),
      name: name,
      description: description,
      sourceControlId: commit,
    }

    const instrumentedDto: UploadBuildDto | null =
      instrumentedFile != null
        ? {
            buildType: BuildType.INSTRUMENTED_APK,
            buildFileName: extractFileName(instrumentedFile.name),
            name: name,
            description: description,
            sourceControlId: commit,
          }
        : null
    raStore
      .uploadBuild(nonInstrumentedFile!, nonInstrumentedDto, instrumentedFile, instrumentedDto)
      .then(() => raStore.fetchBuilds())
      .then(() => {
        setTimeout(() => raStore.fetchRuns(), 3000)
        onClose()
      })
      .catch((reason) => toaster.error(reason, 'ra.flow.build.modalErrorTitle'))
      .finally(() => NProgress.done())
  }, [
    isValid,
    nonInstrumentedFile,
    name,
    description,
    commit,
    instrumentedFile,
    raStore,
    onClose,
    toaster,
  ])

  return (
    <Modal
      title={t('ra.flow.build.upload.label')}
      isOpen={isOpen}
      onClose={() => onClose()}
      actionButton={{
        children: t('ra.flow.build.upload.button'),
        onClick: () => uploadBuild(),
        disabled: !isValid,
      }}
    >
      <div className="w-full text-small text-gray-normal space-y-8">
        <div className="space-y-4">
          <div>
            <p className="mb-1">{t('ra.flow.build.nonInstrumentedBuild.label')}</p>
            <div className="flex items-center">
              <div className="basis-2/5">
                <FileField
                  onUpload={(file) => setNonInstrumentedFile(file)}
                  acceptType="application/vnd.android.package-archive"
                />
              </div>
              <span
                className={classNames(
                  'basis-3/5 pl-4 line-clamp-2 break-words',
                  nonInstrumentedFile != null ? 'text-white' : '',
                )}
              >
                {extractFileName(nonInstrumentedFile?.name || t('ra.flow.settings.notSet'))}
              </span>
            </div>
          </div>
          <div>
            <p className="mb-1">{t('ra.flow.build.instrumentedBuild.label')}</p>
            <div className="flex items-center">
              <div className="basis-2/5">
                <FileField
                  onUpload={(file) => setInstrumentedFile(file)}
                  acceptType="application/vnd.android.package-archive"
                />
              </div>
              <span
                className={classNames(
                  'basis-3/5 pl-4 line-clamp-2 break-words',
                  instrumentedFile && 'text-white',
                )}
              >
                {extractFileName(instrumentedFile?.name || t('ra.flow.settings.notSet'))}
              </span>
            </div>
          </div>
          <SettingsInput
            label={t('ra.flow.build.name.label')}
            value={name}
            onChange={(value) => setName(value)}
          />
          <SettingsInput
            label={t('ra.flow.build.description.label')}
            value={description}
            onChange={(value) => setDescription(value)}
          />
          <SettingsInput
            label={t('ra.flow.build.sourceControlId.label')}
            value={commit}
            onChange={(value) => setCommit(value)}
          />
        </div>
      </div>
    </Modal>
  )
}
