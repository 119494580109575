import { QUICKSTART_ASSETS, CONTENTFUL_TYPES } from 'api/contentful'
import {
  QUICKSTART_PAGES,
  QUICKSTART_PAGE_TO_CONTENTFUL_ENTRIES,
} from 'components/quickstart/constants'
import {
  PageToContentMap,
  QuickstartContent,
  QuickstartContentWithoutVideo,
  QuickstartPageLink,
} from 'components/quickstart/models'
import { TypeQuickstartPage } from 'hooks/__generated__/contentful'
import { useContentfulAsset } from 'hooks/contentful/useContentfulAsset'
import { useContentfulEntriesByType } from 'hooks/contentful/useContentfulEntriesByType'

export const useQuickstartContent = (): QuickstartContent => {
  const { entries } = useContentfulEntriesByType<TypeQuickstartPage>(
    CONTENTFUL_TYPES.quickstartPage,
  )
  const { pageToContentMap, linksToPages } = mapEntriesToQuickstartPages(entries)
  const video = useContentfulAsset(QUICKSTART_ASSETS.video)
  const videoThumbnail = useContentfulAsset(QUICKSTART_ASSETS.videoThumnail)
  return {
    pageToContentMap,
    linksToPages,
    video,
    videoThumbnail,
  }
}

export const mapEntriesToQuickstartPages = (
  entries: TypeQuickstartPage[] | undefined,
): QuickstartContentWithoutVideo => {
  const linksToPages: QuickstartPageLink[] = []

  const pageToContentMap: PageToContentMap = new Map()

  if (!entries || entries.length === 0) {
    return { pageToContentMap, linksToPages }
  }

  for (const page of QUICKSTART_PAGES) {
    const entryId = QUICKSTART_PAGE_TO_CONTENTFUL_ENTRIES[page]
    const entry = entries.find((localEntry) => localEntry?.sys?.id === entryId)

    if (entry) {
      pageToContentMap.set(page, entry)
      linksToPages.push({
        urlQueryParam: page,
        linkText: entry.fields.title || '',
      })
    }
  }

  // remove the first title, which is the title of the index page
  linksToPages.shift()

  return { pageToContentMap, linksToPages }
}
