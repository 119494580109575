import { StorageItem } from './StorageItem'

export class StringStorageItem extends StorageItem<string> {
  get value(): string {
    return this.localStorageValue || ''
  }

  set value(newValue: string) {
    this.localStorageValue = newValue
  }
}
