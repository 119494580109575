import { psLocalStorage } from 'utils/localStorage/PsLocalStorage'
import { makeAutoObservable } from 'mobx'

export class AuthStore {
  isSignedIn: boolean

  constructor() {
    makeAutoObservable(this)
    this.isSignedIn = psLocalStorage.getSignedIn()
  }

  setIsSignedIn = (value: boolean) => {
    psLocalStorage.setSignedIn(value)
    this.isSignedIn = value
  }
}
