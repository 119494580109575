import styled from 'styled-components/macro'
import tw from 'twin.macro'

export const ProgressBar = styled.div<{ setWidth: number }>`
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 3px;
  background-color: ${({ theme }) => theme.colors.dark.dark5};

  &:before {
    ${tw`transition-[width]`}
    position: absolute;
    top: 0;
    left: 0;
    width: ${({ setWidth }) => setWidth || 0}%;
    height: 3px;
    content: '';
    background-color: ${({ theme }) => theme.colors.electro};
  }
`
