import Konva from 'konva'

export enum LimiterType {
  LEFT,
  RIGHT,
}

export interface LimiterSettings {
  color: string
  canvasWidth: number
  limiterWidth: number
  limiterHeight: number
  grabPadding: number
  markerWidth: number
  cursorDefault: string
  cursorResize: string
}

export class Limiter {
  readonly type: LimiterType

  private readonly settings: LimiterSettings

  readonly group = new Konva.Group({ draggable: true })

  private isDragging = false

  constructor(type: LimiterType, settings: LimiterSettings) {
    this.type = type
    this.settings = settings
    this.initGroup()
  }

  private initGroup() {
    const mw = this.settings.markerWidth

    const rect = new Konva.Rect({
      x: 0,
      y: 0,
      width: this.settings.limiterWidth,
      height: this.settings.limiterHeight,
      fill: this.settings.color,
    })

    const rectWrapper = new Konva.Rect({
      x: -this.settings.grabPadding,
      y: 0,
      width: this.settings.grabPadding * 2 + 1,
      height: this.settings.limiterHeight,
    })

    const triangle = new Konva.Line({
      points: this.isLeft ? [0, 0, mw, 0, 0, mw] : [-mw + 1, 0, 1, 0, 1, mw],
      fill: this.settings.color,
      closed: true,
    })

    this.group.add(rectWrapper, rect, triangle)
  }

  addEventListeners() {
    this.group.on('mouseover', this.setCursorColResize)
    this.group.on('mouseout', this.setDefaultCursor)
    this.group.on('dragstart', this.setCursorColResize)
    this.group.on('dragend', this.updateCursor)
  }

  removeEventListeners() {
    this.group.off('mouseover')
    this.group.off('mouseout')
    this.group.off('dragstart')
    this.group.off('dragend')
  }

  private setDefaultCursor = () => (document.body.style.cursor = this.settings.cursorDefault)

  private setCursorColResize = () => {
    if (!this.isDragging) {
      document.body.style.cursor = this.settings.cursorResize
    }
  }

  private updateCursor = () => {
    this.isDragging = false
    document.body.style.cursor = this.isPointerOutOfDragRegion
      ? this.settings.cursorDefault
      : this.settings.cursorResize
  }

  get isPointerOutOfDragRegion(): boolean {
    const pos = this.group.getRelativePointerPosition()
    const padding = this.settings.grabPadding
    const isOutOfHeight = pos.y > this.settings.limiterHeight || pos.y < 0
    const isLeftAndOutOfPadding = this.isLeft && (pos.x < -padding || pos.x > padding + 1)
    const isRightAndOutOfPadding = this.isRight && (pos.x < -padding || pos.x > padding + 1)
    return isOutOfHeight || isLeftAndOutOfPadding || isRightAndOutOfPadding
  }

  get startX(): number {
    return this.group.x()
  }

  set startX(value: number) {
    this.group.x(value)
  }

  set startY(value: number) {
    this.group.y(value)
  }

  get isLeft(): boolean {
    return this.type === LimiterType.LEFT
  }

  get isRight(): boolean {
    return this.type === LimiterType.RIGHT
  }
}
