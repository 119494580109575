import type { Asset, Entry, EntryFields } from 'contentful'
import type { TypeLiveDemoPartsFields } from './TypeLiveDemoParts'
import type { WithContentTypeLink } from './WithContentTypeLink'

export interface TypeLiveDemoDataFields {
  title: EntryFields.Symbol
  cover: Asset
  video?: Asset
  videoThumbnail?: Asset
  description?: EntryFields.Text
  buttonLabel?: EntryFields.Symbol
  listHeader?: EntryFields.Symbol
  parts: Entry<TypeLiveDemoPartsFields>[]
}

export type TypeLiveDemoData = Entry<TypeLiveDemoDataFields>

export function isTypeLiveDemoData(entry: WithContentTypeLink): entry is TypeLiveDemoData {
  return entry.sys.contentType.sys.id === 'liveDemoData'
}
